type MergeRoutesDialogOldAction =
  | { type: "add_route" }
  | { type: "remove_route"; fieldIndex: number }
  | { type: "merge_from_route_field"; routeId: string; fieldIndex: number }
  | { type: "merge_to_route_field"; routeId: number | null }
  | { type: "assign_door_id"; doorId: number | null }
  | { type: "set_start_time"; startTime: string | null }
  | { type: "set_tractor_id"; tractorEquipmentId: number | null }
  | { type: "set_trailer_id"; trailerEquipmentId: number | null }
  | { type: "set_straight_truck_id"; straightTruckEquipmentId: number | null }
  | { type: "load_routes"; routeIds?: string[] }
  | { type: "clear" };

interface MergeRoutesDialogOldState {
  fromRouteIds: Array<string>;
  toRouteId: number | null;
  doorId: number | null;
  startTime: string | null;
  tractorEquipmentId: number | null;
  trailerEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
}

export const initialState: MergeRoutesDialogOldState = {
  fromRouteIds: ["", ""],
  toRouteId: null,
  doorId: null,
  startTime: null,
  tractorEquipmentId: null,
  trailerEquipmentId: null,
  straightTruckEquipmentId: null,
};

export function MergeRoutesDialogOldReducer(
  state: MergeRoutesDialogOldState,
  action: MergeRoutesDialogOldAction
): MergeRoutesDialogOldState {
  const { type } = action;
  switch (type) {
    case "merge_from_route_field":
      return {
        ...state,
        fromRouteIds: state.fromRouteIds.map((routeId, index) =>
          index === action.fieldIndex ? action.routeId : routeId
        ),
      };
    case "load_routes":
      return {
        ...state,
        fromRouteIds: action.routeIds ?? state.fromRouteIds,
      };
    case "add_route":
      return {
        ...state,
        fromRouteIds: [...state.fromRouteIds, ""],
      };
    case "remove_route":
      return {
        ...state,
        fromRouteIds: state.fromRouteIds.filter(
          (_, index) => index !== action.fieldIndex
        ),
      };
    case "merge_to_route_field":
      return {
        ...state,
        toRouteId: action.routeId,
      };
    case "assign_door_id":
      return {
        ...state,
        doorId: action.doorId,
      };
    case "set_start_time":
      return {
        ...state,
        startTime: action.startTime,
      };
    case "set_tractor_id":
      return {
        ...state,
        tractorEquipmentId: action.tractorEquipmentId,
      };
    case "set_trailer_id":
      return {
        ...state,
        trailerEquipmentId: action.trailerEquipmentId,
      };
    case "set_straight_truck_id":
      return {
        ...state,
        straightTruckEquipmentId: action.straightTruckEquipmentId,
      };
    case "clear":
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

// CSpell:ignore CLDV DISP
import { Alert, Box } from "@mui/material";
import { LightTooltip } from "../../../components/shared/LightTooltip";

const ClosedStatusWarning = () => (
  <Box sx={{ padding: "0px 24px 16px" }}>
    <Alert severity="warning">
      Moving shipments to or from any route/trap in a{" "}
      <LightTooltip title="CLDV, DISP, TRAP">
        <Box component="a">Closed</Box>
      </LightTooltip>{" "}
      status will change to a{" "}
      <LightTooltip title="CLDK">
        <Box component="a">Loading</Box>
      </LightTooltip>{" "}
      status.
    </Alert>
  </Box>
);

export default ClosedStatusWarning;

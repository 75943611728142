import { Stack, Typography, useTheme } from "@mui/material";
import { toastMessage } from "../constants/strings";

export const ErrorBoundary = () => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
      gap={1}
    >
      <Typography
        variant="h4"
        component="h1"
        color={theme.palette.primary.main}
      >
        {toastMessage.generics.failedToLoadData}
      </Typography>
      <Typography variant="h6" component="h2" color={theme.palette.grey[600]}>
        {toastMessage.generics.failedToLoadDataSubtext}
      </Typography>
    </Stack>
  );
};

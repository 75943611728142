import { useQuery, useQueryClient } from "@tanstack/react-query";
import { planSummaryResponseSchema } from "../../types/planning/plan.type";
import { apiUrls } from "../../utils/apiUrls";
import { http } from "../../utils/httpCommon";

export const plannedRoutesQueryKey = (
  date: number,
  sicId: number,
  searchTerm = ""
) => ["plannedRoutes", date, sicId, searchTerm];

const getRouteAssignments = async (
  date: number,
  sicId: number,
  searchTerm?: string
) => {
  const url = apiUrls.getRouteAssignments(date, sicId);
  const { data, status } = await http.get(url, {
    params: {
      searchTerm,
    },
  });
  if (status === 204) return [];
  return planSummaryResponseSchema.parse(data);
};

export const usePlannedRoutes = (
  date: number,
  sicId: number,
  searchTerm?: string
) =>
  useQuery({
    queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
    queryFn: () => getRouteAssignments(date, sicId, searchTerm),
  });

export function useInvalidateQueryPlannedRoutes() {
  const queryClient = useQueryClient();
  const invalidateQueryPlannedRoutes = (date: number, sicId: number) => {
    queryClient.invalidateQueries({
      queryKey: plannedRoutesQueryKey(date, sicId),
    });
  };
  return invalidateQueryPlannedRoutes;
}

import { useMemo } from "react";
import {
  filterPlanSummaryBySearchTerm,
  getFormattedPlannedRoutes,
  oldGetFormattedPlannedRoutes,
} from "../../pages/inbound/inboundPlanning/utils";

import { useShipmentDetailSearch } from "./preplanning/useShipmentDetails";
import { usePlannedRoutes } from "./usePlannedRoutes";
import { useRoutesAndTraps } from "./useRoutes";

export const useFormattedPlannedRoutesAndTraps = (
  dateInt: number,
  serviceCenterId: number,
  lockedRouteIds: number[],
  searchTerm?: string,
  timeZoneId?: string,
  isFetchStrategyV2Enabled = false
) => {
  const { data: routes = [], isLoading: isRoutesLoading } =
    useRoutesAndTraps(serviceCenterId);
  const {
    data: plannedRoutes = [],
    refetch: refetchPlannedRoutes,
    isLoading: isPlannedRoutesLoading,
  } = usePlannedRoutes(
    dateInt,
    serviceCenterId,
    isFetchStrategyV2Enabled ? undefined : searchTerm
  );

  const formattedSearchTerm = searchTerm?.toLowerCase();

  const {
    data: shipments = [],
    refetch: refetchShipments,
    isLoading: isShipmentsDetailsLoading,
  } = useShipmentDetailSearch({
    search: {
      shipmentIds: [
        ...new Set(plannedRoutes.flatMap(({ shipmentIds }) => shipmentIds)),
      ],
      searchTerm: isFetchStrategyV2Enabled ? undefined : searchTerm,
      sicId: serviceCenterId,
      planDate: dateInt,
      addPlanningInfo: true,
    },
    enabled: true,
    onlyIncludeHighlightedRows: isFetchStrategyV2Enabled,
  });

  const filteredPlannedRoutesAndTraps =
    isFetchStrategyV2Enabled && formattedSearchTerm
      ? plannedRoutes.map((route) =>
          filterPlanSummaryBySearchTerm(route, timeZoneId, formattedSearchTerm)
        )
      : plannedRoutes;

  const isLoading =
    isRoutesLoading || isPlannedRoutesLoading || isShipmentsDetailsLoading;

  const data = useMemo(
    () =>
      isFetchStrategyV2Enabled
        ? getFormattedPlannedRoutes(
            serviceCenterId,
            dateInt,
            filteredPlannedRoutesAndTraps,
            routes,
            shipments,
            formattedSearchTerm,
            lockedRouteIds
          )
        : oldGetFormattedPlannedRoutes(
            serviceCenterId,
            dateInt,
            filteredPlannedRoutesAndTraps,
            lockedRouteIds,
            routes,
            shipments,
            searchTerm
          ),
    [
      dateInt,
      lockedRouteIds,
      filteredPlannedRoutesAndTraps,
      routes,
      serviceCenterId,
      shipments,
      formattedSearchTerm,
      isFetchStrategyV2Enabled,
      searchTerm,
    ]
  );

  return {
    data,
    isLoading,
    refetchPlannedRoutes,
    refetchShipments,
  };
};

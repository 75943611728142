import { Equipment } from "../../types/equipment.type";

export const mockEquipmentData = {
  default: [
    {
      id: 12274,
      typeName: "Straight Truck",
      typeId: 3,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Straight Truck # 12274",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12275,
      typeName: "Straight Truck",
      typeId: 3,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DISP",
      refTrailerNumber: "Straight Truck # 12275",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12276,
      typeName: "Tractor",
      typeId: 1,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Tractor # 12276",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12277,
      typeName: "Tractor",
      typeId: 1,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Tractor # 12277",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12278,
      typeName: "Tractor",
      typeId: 1,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Tractor # 12278",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12279,
      typeName: "Tractor",
      typeId: 1,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Tractor # 12279",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12280,
      typeName: "Tractor",
      typeId: 1,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Tractor # 12280",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12281,
      typeName: "Straight Truck",
      typeId: 1,
      active: true,
      capacityLbs: 26000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Straight Truck # 12281",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 12282,
      typeName: "Straight Truck",
      typeId: 3,
      active: true,
      capacityLbs: 25000,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Straight Truck # 12282",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 1413,
      typeName: "Straight Truck",
      typeId: 3,
      active: true,
      capacityLbs: 25995,
      lengthFt: 0,
      maxHU: 0,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Straight Truck # HTX201A",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 884,
      typeName: "Trailer",
      typeId: 2,
      active: true,
      capacityLbs: 25000,
      lengthFt: 0,
      maxHU: 107,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Trailer # DYLT161619",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 1,
      typeName: "Trailer",
      typeId: 2,
      active: false,
      capacityLbs: 25000,
      lengthFt: 0,
      maxHU: 107,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Trailer # DYLT516581",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 2,
      typeName: "Tractor",
      typeId: 1,
      active: false,
      capacityLbs: 25000,
      lengthFt: 0,
      maxHU: 107,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Tractor # DYLT154984",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
  ],
  getById: [
    {
      id: 884,
      typeName: "Trailer",
      typeId: 2,
      active: true,
      capacityLbs: 25000,
      lengthFt: 0,
      maxHU: 107,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Trailer # DYLT161619",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 1413,
      typeName: "Straight Truck",
      typeId: 3,
      active: true,
      capacityLbs: 25995,
      lengthFt: 0,
      maxHU: 103,
      hasLiftGate: false,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Straight Truck # HTX201A",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
    {
      id: 1651,
      typeName: "Tractor",
      typeId: 1,
      active: true,
      capacityLbs: 26000,
      lengthFt: 50,
      maxHU: 110,
      hasLiftGate: true,
      maintenanceStatus: "DEPTERM",
      refTrailerNumber: "Tractor # HTX200A",
      driverId: 0,
      inspectionChecklistId: "0",
      forkliftType: "",
      jboxSerial: "",
      tabletIpAddress: "",
      homeSicId: 111,
    },
  ],
} as const satisfies Record<string, Equipment[]>;

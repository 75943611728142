import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../../utils/httpCommon";
import { apiUrls } from "../../../../utils/apiUrls";
import { groupSchema } from "./useGroups";
import { userGroupsKeys } from "./useUserGroups";

export type IncludeRelatedData = "group";

const userKeys = {
  getUser: (userId: string, include: Readonly<string[]>) => [
    "users",
    userId,
    { include: [...include] },
  ],
  getCurrentUser: () => ["users", "current"],
  getUsers: (include: Readonly<string[]>) => [
    "users",
    { include: [...include] },
  ],
};

const userBaseSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email().nullable(),
  isActive: z.boolean(),
  sicId: z.number().nullable(),
  loginId: z.string().nullable(),
});

const userSchema = userBaseSchema.extend({
  groups: groupSchema.array().optional().default([]),
});

export type User = z.infer<typeof userSchema>;

type UseUserArgs = {
  userId: string;
  include?: Readonly<IncludeRelatedData[]>;
};

export const useUser = ({ userId, include = [] }: UseUserArgs) =>
  useQuery({
    queryKey: userKeys.getUser(userId, include),
    queryFn: async () =>
      userSchema.parse((await http.get(apiUrls.getUser(userId, include))).data),
  });

export const useCurrentUser = () =>
  useQuery({
    queryKey: userKeys.getCurrentUser(),
    queryFn: async () =>
      userBaseSchema.parse((await http.get(apiUrls.getCurrentUser)).data),
  });

export const useUsers = (include: Readonly<IncludeRelatedData[]> = []) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: userKeys.getUsers(include),
    queryFn: async () => {
      const users = userSchema
        .array()
        .parse((await http.get(apiUrls.getUsers(include))).data);
      // Setting individual user details in React-query cache to speed loading of each user's retrieval.
      // Since the list query returns the same properties as the useUser query, we can "prefill" the details cache using the list data.
      users.forEach((user) =>
        queryClient.setQueryData<User>(userKeys.getUser(user.id, include), user)
      );
      return users;
    },
  });
};

const updateUserSchema = userBaseSchema.extend({
  groups: z.number().array(),
});

type UpdateUser = z.infer<typeof updateUserSchema>;

type UpdateUserArgs = {
  user: User;
  onSuccess?: (user: User) => void;
  onError?: (user: User) => void;
  onSettled?: (user: User) => void;
};

export const useUpdateUser = (include: Readonly<IncludeRelatedData[]>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      user,
      onSuccess,
      onError,
      onSettled,
    }: UpdateUserArgs) => {
      const updateUserReq: UpdateUser = {
        ...user,
        groups: user.groups.map((group) => group.id),
      };

      await http.put(apiUrls.updateUser, updateUserReq);
      return { user, onSuccess, onError, onSettled };
    },
    onSuccess: ({ user, onSuccess }) => {
      queryClient.invalidateQueries({
        queryKey: userKeys.getUsers(include),
      });
      queryClient.invalidateQueries({
        queryKey: userGroupsKeys.getUserGroups(user.id),
      });
      queryClient.setQueryData(userKeys.getUser(user.id, include), user);
      onSuccess?.(user);
    },
    onError: (_error, { user, onError }) => {
      onError?.(user);
    },
    onSettled: (data) => {
      data?.onSettled?.(data.user);
    },
  });
};

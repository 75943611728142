import { TableRow, useTheme } from "@mui/material";
import { Dispatch, MouseEvent, PropsWithChildren, SetStateAction } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "@hello-pangea/dnd";
import { Shipment } from "../../../services/prePlanningService.types";
import { getDraggableStyleRow } from "../../../utils/dragging";
import { DraggableSelectionCounter } from "./DraggableSelectionCounter";

type Props = {
  /** id for draggableId prop */
  id: string;
  /** number for draggable index prop */
  index: number;
  shipment?: Shipment;
  selectedShipments?: Shipment[];
  setSelectedShipments?: Dispatch<SetStateAction<Shipment[]>>;
  selectionCount?: number;
  isSelected?: boolean;
};

const DraggableTableRow = ({
  id,
  index,
  shipment,
  selectedShipments = [],
  setSelectedShipments = () => {},
  selectionCount,
  isSelected = false,
  children,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const handleSelect = (event: MouseEvent<HTMLTableRowElement>) => {
    event.stopPropagation();
    if (shipment) {
      const { target } = event;
      // Do nothing if the selection is through the checkboxes
      if ("type" in target && target.type === "checkbox") {
        return;
      }
      if (event.ctrlKey || event.metaKey) {
        setSelectedShipments((prev: Shipment[]) => [...prev, shipment]);
      } else {
        if (selectedShipments.some((s) => s.id === shipment.id)) {
          setSelectedShipments(
            selectedShipments.filter((s) => s.id !== shipment.id)
          );
        } else {
          setSelectedShipments([shipment]);
        }
      }
    }
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot
      ) => (
        <TableRow
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          sx={{
            ...getDraggableStyleRow(
              snapshot.isDragging,
              draggableProvided.draggableProps.style,
              theme,
              isSelected
            ),
            position: "relative",
          }}
          onClick={handleSelect}
        >
          {children}
          {selectionCount
            ? snapshot.isDragging &&
              selectionCount > 1 && (
                <DraggableSelectionCounter>
                  {selectionCount}
                </DraggableSelectionCounter>
              )
            : ""}
        </TableRow>
      )}
    </Draggable>
  );
};

export default DraggableTableRow;

import { z } from "zod";
import { assignmentSchema, planningAssignmentsSchema } from "./assignment.type";
import { equipmentTypeIds } from "./equipment.type";

export const deliveryEquipmentSchema = z.object({
  id: z.number().min(0),
  name: z.string(),
  equipmentType: z.number(),
});

export const deliveryEquipmentUsageSchema = z.object({
  id: z.number().min(0),
  name: z.string(),
  typeId: z
    .number()
    .min(equipmentTypeIds[0])
    .max(equipmentTypeIds[equipmentTypeIds.length - 1]),
  inUseByInfo: assignmentSchema.nullable(),
  planningAssignmentsInfo: planningAssignmentsSchema,
});

export type DeliveryEquipment = z.infer<typeof deliveryEquipmentSchema>;
export type DeliveryEquipmentUsage = z.infer<
  typeof deliveryEquipmentUsageSchema
>;

import { CellContext, Row } from "@tanstack/react-table";
import { useState, useCallback } from "react";
import { ShipmentWithSequence } from "../../../../types/planning/shipmentSeq.type";
import {
  AssignShipmentType,
  ReorderShipmentsFn,
  UnassignShipmentFn,
} from "../../../../types/assignShipment.type";
import { OldDragMultipleShipments } from "../../../../components/shared/OldDragMultipleShipments";
import {
  ShipmentsTableBase,
  TableShipment,
} from "../../../../components/shared/old-table/ShipmentsTableBase";
import { UnassignShipmentConfirmDialog } from "../UnassignShipmentConfirmDialog";
import { SummaryContext } from "../../shared/ActionsMenuContext";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useTableSelectionContext } from "../../../../components/shared/old-table/TableSelectionContext";
import { ContextMenuAction } from "../../../../types/contextMenuAction";
import oldPlanShipmentColumns from "./oldPlanShipmentColumnsDef";

type OldPlanShipmentsTableProps<TData> = {
  shipments: ShipmentWithSequence[];
  parentRow: Row<TData>;
  parentId: number;
  parentType: AssignShipmentType;
  isParentSelected: boolean;
  onUnassignShipments: UnassignShipmentFn;
  selectedShipments: SummaryContext[];
  refetch: () => void;
  onReorderShipments: ReorderShipmentsFn;
  contextMenuActions?: () => ContextMenuAction[];
  numberOfBills: number;
};

const OldPlanShipmentsTable = <TData,>({
  shipments,
  parentRow,
  parentId,
  parentType,
  isParentSelected,
  onUnassignShipments,
  onReorderShipments,
  contextMenuActions = () => [],
  numberOfBills,
}: OldPlanShipmentsTableProps<TData>) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [shipmentId, setShipmentId] = useState<number>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [proNumber, setProNumber] = useState<string>();

  const handleUnassignShipment = (shipment: TableShipment) => {
    setShipmentId(shipment.id);
    setConfirmOpen(true);
    setProNumber(shipment.proNumber);
  };

  const handleDeleteClick = () => {
    if (!shipmentId) return;
    onUnassignShipments({
      shipmentIds: [shipmentId],
      sourceId: parentId.toString(),
      sourceType: parentType,
    });
  };

  const { selectedShipmentsCount } = useTableSelectionContext();

  const draggableColumn = {
    id: "drag",
    header: "",
    cell: useCallback(
      ({ row }: CellContext<TableShipment, any>) => (
        <OldDragMultipleShipments
          mainConsigneeName={row.original.consignee?.name}
          mainProNumber={row.original.proNumber}
          draggableId={row.id}
          index={row.index}
          selectedCount={selectedShipmentsCount}
        />
      ),
      [selectedShipmentsCount]
    ),
  };

  const columns = [
    ...[draggableColumn],
    ...oldPlanShipmentColumns({
      isEditable: true,
      routeId: parentId,
      timeZone: serviceCenter.timeZone,
      onUnassignShipment: handleUnassignShipment,
      parentType,
      allShipments: shipments,
      onReorderShipments,
    }),
  ];

  return (
    <>
      <ShipmentsTableBase
        parentRow={parentRow}
        columns={columns}
        data={shipments}
        rowSelectionMode="checkbox"
        defaultSort={[
          {
            id: parentType === "route" ? "stopSeq" : "consigneeName",
            desc: false,
          },
        ]}
        isParentSelected={isParentSelected}
        getRowId={(row) => `${row.id}-${row.proNumber}`}
        contextMenuActions={contextMenuActions}
        numberOfBills={numberOfBills}
      />
      {confirmOpen && shipmentId && proNumber && (
        <UnassignShipmentConfirmDialog
          shipmentId={shipmentId}
          proNumber={proNumber}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDeleteClick}
        />
      )}
    </>
  );
};

export default OldPlanShipmentsTable;

import { useActionsMenuContext } from "./ActionsMenuContext";
import { MergeRoutesDialogOld } from "./MergeRoutesDialogOld";

const MergeRoutesDefaultDialogOld = () => {
  const { closeDialog, shipments, clearSelection } = useActionsMenuContext();

  const selectedRouteIds = shipments
    .filter((shipment) => shipment.type === "route")
    .map((shipment) => shipment.id.toString());

  return (
    <MergeRoutesDialogOld
      clearSelection={clearSelection}
      onCancel={closeDialog}
      selectedRouteIds={selectedRouteIds}
    />
  );
};

export default MergeRoutesDefaultDialogOld;

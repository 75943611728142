import { Button } from "@mui/material";
import { FallbackProps } from "react-error-boundary";

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  return (
    <div role="alert">
      <h2>Something went wrong.</h2>
      <div>
        Error: <pre>{error.message}</pre>
      </div>
      <Button variant="contained" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
}

import { Shipment } from "../../../services/prePlanningService.types";
import { defaultMockServiceCenter } from "../../serviceCenter/serviceCenters.mocks";

export const mockShipmentIds: number[] = [
  131365190, 131367485, 131364476, 131363799, 131364100, 131364941, 131365260,
  131356018, 131363858, 131363911, 131364465,
];

export const mockUnaasignedShipmentIds: number[] = [131364943];

export const mockPlannedShipmentsDetails = {
  default: [
    {
      id: 131367485,
      tripNumber: "1353104",
      proNumber: "137844650",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      location: "42943",
      sic: defaultMockServiceCenter.code,
      serviceDueDate: new Date(),
      destinationSicCode: defaultMockServiceCenter.code,
      status: "ARIV",
      shipper: {
        id: 1383322,
        name: "MAXLINER USA",
      },
      consignee: {
        id: 1301611,
        name: "T3 LINING SUPPLY",
        address: "17605 FABRICA WAY STE B",
        city: "CERRITOS",
        state: "CA",
        zipCode: "90703",
      },
      weight: 490,
      isPlanned: true,
      futurePlanDates: [],
      tags: [],
      highlightedRow: true,
      stopSeq: 1,
      etaDate: new Date(),
      lat: 33.87113,
      lng: -118.03215,
      assignment: "CERRITOS-1",
      isManualAssign: false,
      presetRouteName: "CERRITOS",
      presetRouteId: 169,
      isAvailableToReconcile: true,
    },
    {
      id: 131365190,
      tripNumber: "1353104",
      proNumber: "137844361",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      location: "42943",
      sic: defaultMockServiceCenter.code,
      serviceDueDate: new Date(),
      destinationSicCode: defaultMockServiceCenter.code,
      status: "ARIV",
      shipper: {
        id: 957992,
        name: "AMERICAN BOTANICALS",
      },
      consignee: {
        id: 184745,
        name: "ESMOND NATURAL",
        address: "5316 IRWINDALE AVE",
        city: "IRWINDALE",
        state: "CA",
        zipCode: "91706",
      },
      weight: 970,
      isPlanned: true,
      futurePlanDates: [],
      tags: [],
      highlightedRow: true,
      stopSeq: 1,
      etaDate: new Date(),
      lat: 34.10957,
      lng: -117.93137,
      assignment: "AZUSA-1",
      isManualAssign: false,
      presetRouteName: "AZUSA",
      presetRouteId: 177,
      isAvailableToReconcile: true,
    },
    {
      id: 131364476,
      tripNumber: "1353104",
      proNumber: "137844452",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      location: "42943",
      sic: defaultMockServiceCenter.code,
      serviceDueDate: new Date(),
      destinationSicCode: defaultMockServiceCenter.code,
      status: "ARIV",
      shipper: {
        id: 32766,
        name: "MARINE OIL SVCS",
      },
      consignee: {
        id: 370649,
        name: "BAE SYSTEMS SAN DIEGO",
        address: "1650 INDUSTRIAL BLVD",
        city: "CHULA VISTA",
        state: "CA",
        zipCode: "91911",
      },
      weight: 625,
      isPlanned: true,
      futurePlanDates: [],
      tags: [],
      highlightedRow: true,
      stopSeq: 1,
      etaDate: new Date(),
      lat: 32.59705,
      lng: -117.085,
      assignment: "CHULA VISTA-1",
      isManualAssign: false,
      presetRouteName: "CHULA VISTA",
      presetRouteId: 190,
      isAvailableToReconcile: true,
    },
    {
      id: 131364100,
      tripNumber: "1353104",
      proNumber: "137844825",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: "SFO",
      location: "42943",
      sic: "SFO",
      status: "ARIV",
      shipper: {
        id: 884237,
        name: "AGI",
      },
      consignee: {
        id: 642426,
        name: "TRACY SIGNS ",
        address: "3771 W 11TH ST ",
        city: "TRACY ",
        state: "CA",
        zipCode: "95304",
      },
      weight: 246,
      isPlanned: true,
      futurePlanDates: [],
      tags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "SFO",
      isManualAssign: false,
      presetRouteName: "SFO",
      presetRouteId: 342,
      isAvailableToReconcile: true,
    },
    {
      id: 131363858,
      tripNumber: "1353104",
      proNumber: "137844601",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: defaultMockServiceCenter.code,
      location: "42943",
      sic: defaultMockServiceCenter.code,
      status: "ARIV",
      shipper: {
        id: 835460,
        name: "INTERSURGICAL",
      },
      consignee: {
        id: 596319,
        name: "MEDLINE INDUSTRIES ",
        address: "1960 W MIRO WAY",
        city: "RIALTO",
        state: "CA",
        zipCode: "92376",
      },
      weight: 366,
      isPlanned: true,
      futurePlanDates: [],
      tags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "Trap-APPT-APTR",
      isManualAssign: false,
      presetRouteName: "FONTANA",
      presetRouteId: 160,
      isAvailableToReconcile: true,
    },
    {
      id: 131356018,
      tripNumber: "1353104",
      proNumber: "137844932",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: defaultMockServiceCenter.code,
      location: "42943",
      sic: defaultMockServiceCenter.code,
      status: "ARIV",
      shipper: {
        id: 815873,
        name: "NAVIEN INC",
      },
      consignee: {
        id: 542891,
        name: "HIRSCH PIPE & SUPPLY CORP",
        address: "910 E SANDHILL AVE",
        city: "CARSON",
        state: "CA",
        zipCode: "90746",
      },
      weight: 844,
      isPlanned: true,
      futurePlanDates: [],
      tags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "Trap-APPT-APTR",
      isManualAssign: false,
      presetRouteName: "CARSON",
      presetRouteId: 192,
      isAvailableToReconcile: true,
    },
    {
      id: 131363911,
      tripNumber: "1353104",
      proNumber: "137844684",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: defaultMockServiceCenter.code,
      location: "42943",
      sic: defaultMockServiceCenter.code,
      status: "ARIV",
      shipper: {
        id: 2292688,
        name: "HIGH TOWER GROUP",
      },
      consignee: {
        id: 1215023,
        name: "ENVIRONMENTS PLUS",
        address: "13707 MARQUARDT AVE",
        city: "SANTA FE SPGS",
        state: "CA",
        zipCode: "90670",
      },
      weight: 253,
      isPlanned: true,
      futurePlanDates: [],
      tags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "Trap-APPT-APTR",
      isManualAssign: false,
      presetRouteName: "SANTA FE SPRINGS",
      presetRouteId: 209,
      isAvailableToReconcile: true,
    },
    {
      id: 131363799,
      tripNumber: "1353104",
      proNumber: "137844692",
      routeId: null,
      pallets: 2,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: "SFO",
      location: "42943",
      sic: "SFO",
      status: "ARIV",
      shipper: {
        id: 2292688,
        name: "HIGH TOWER GROUP",
      },
      consignee: {
        id: 3717779,
        name: "TURNER CONSTRUCTION COMPANY",
        address: "100 BUSH ST STE 501",
        city: "SAN FRANCISCO",
        state: "CA",
        zipCode: "94104",
      },
      weight: 300,
      isPlanned: true,
      futurePlanDates: [],
      tags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "SFO",
      isManualAssign: false,
      presetRouteName: "SFO",
      presetRouteId: 342,
      isAvailableToReconcile: true,
    },
    {
      id: 131365260,
      tripNumber: "1353104",
      proNumber: "137844387",
      routeId: null,
      pallets: 2,
      pieces: 2,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: "SFO",
      location: "42943",
      sic: "SFO",
      status: "ARIV",
      shipper: {
        id: 86690,
        name: "ROSENBURG USA",
      },
      consignee: {
        id: 166715,
        name: "CELLAR PRO",
        address: "1445 N MCDOWELL BLVD",
        city: "PETALUMA",
        state: "CA",
        zipCode: "94954",
      },
      weight: 1110,
      isPlanned: true,
      futurePlanDates: [],
      tags: [],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "SFO",
      isManualAssign: false,
      presetRouteName: "SFO",
      presetRouteId: 342,
      isAvailableToReconcile: true,
    },
    {
      id: 131364465,
      tripNumber: "1353104",
      proNumber: "137844916",
      routeId: null,
      pallets: 1,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: "VIS",
      location: "42943",
      sic: "VIS",
      status: "ARIV",
      shipper: {
        id: 884237,
        name: "AGI",
      },
      consignee: {
        id: 197243,
        name: "PROLITE SIGNS",
        address: "12645 HANFORD-ARMONA RD",
        city: "HANFORD",
        state: "CA",
        zipCode: "93230",
      },
      weight: 242,
      isPlanned: true,
      futurePlanDates: [],
      tags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "VIS",
      isManualAssign: false,
      presetRouteName: "VIS",
      presetRouteId: 341,
      isAvailableToReconcile: true,
    },
    {
      id: 131364941,
      tripNumber: "1353104",
      proNumber: "137844619",
      routeId: null,
      pallets: 2,
      pieces: 0,
      weightUnit: "lbs",
      appointmentDate: null,
      appointmentEndDate: null,
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      destinationSicCode: defaultMockServiceCenter.code,
      location: "42943",
      sic: defaultMockServiceCenter.code,
      status: "ARIV",
      shipper: {
        id: 1210869,
        name: "BOSCH REXROTH",
      },
      consignee: {
        id: 190452,
        name: "VALLEY FABRICATION",
        address: "1056 PELLET AVE",
        city: "SALINAS",
        state: "CA",
        zipCode: "93901",
      },
      weight: 2280,
      isPlanned: true,
      futurePlanDates: [],
      tags: [
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      highlightedRow: true,
      stopSeq: null,
      etaDate: new Date(),
      lat: null,
      lng: null,
      assignment: "SFO",
      isManualAssign: false,
      presetRouteName: "SFO",
      presetRouteId: 342,
      isAvailableToReconcile: true,
    },
  ],
} as const satisfies Record<string, Shipment[]>;

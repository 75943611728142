import { List, ListItem } from "@mui/material";
import {
  RouteTrapStatusConstraint,
  ErrorDetailData,
  shipmentsNotReadyToLoadSchema,
} from "../../../types/inbound/errorDetail.type";

const inTransitStatuses: string[] = ["ENRT", "DLAY"];

interface ErrorListProps {
  code: RouteTrapStatusConstraint;
  errorDetail: ErrorDetailData;
  serviceCenterCode: string;
}

const ErrorList = ({
  code,
  errorDetail,
  serviceCenterCode,
}: ErrorListProps) => {
  if (code !== "SHIPMENTS_NOT_READY_TO_LOAD") {
    return null;
  }

  const parsedData = shipmentsNotReadyToLoadSchema.parse(errorDetail);

  return (
    <List sx={{ padding: 0 }}>
      {parsedData.map((shipment) => (
        <ListItem key={shipment.proNumber}>
          {`PRO: ${shipment.proNumber} - ${
            inTransitStatuses.includes(shipment.statusName)
              ? `ENRT ${shipment.sicCode} - ${serviceCenterCode}`
              : shipment.sicCode
                ? `Current SIC: ${shipment.sicCode}`
                : shipment.statusName
          }`}
        </ListItem>
      ))}
    </List>
  );
};

export default ErrorList;

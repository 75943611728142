import { z } from "zod";

const assignmentType = z.enum(["Trap", "Route"]);
const inUseByInfoSchema = z.object({
  id: z.number().positive(),
  type: assignmentType,
  name: z.string(),
});

const deliveryEquipmentSchemaOld = z.object({
  id: z.number().min(0),
  name: z.string(),
  equipmentType: z.number(),
  inUseByInfo: inUseByInfoSchema.nullish(),
});

export type DeliveryEquipment = z.infer<typeof deliveryEquipmentSchemaOld>;

export const deliveryEquipmentSchemaByTypeOld = z.object({
  trailers: z.array(
    deliveryEquipmentSchemaOld.transform((data) => ({
      ...data,
      name: data.name || `Trailer # ${data.id}`,
    }))
  ),
  straightTrucks: z.array(
    deliveryEquipmentSchemaOld.transform((data) => ({
      ...data,
      name: data.name || `Straight Truck # ${data.id}`,
    }))
  ),
  tractors: z.array(
    deliveryEquipmentSchemaOld.transform((data) => ({
      ...data,
      name: data.name || `Tractor # ${data.id}`,
    }))
  ),
});

import { AutoAwesome } from "@mui/icons-material";
import type { Dispatch, SetStateAction } from "react";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { buttonStyle } from "../prePlanning/actionbar/StyledMenuButton";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import { plannedRoutesQueryKey } from "../../../hooks/react-query/usePlannedRoutes";
import { useSearchTermParam } from "../../../hooks/useSearchTermParam";
import { queryClient } from "../../../queryClientProvider";
import { useAutoSequenceRoutes } from "../../../hooks/react-query/useAutoSequenceRoute";

type AutoSequenceButtonProps = {
  routesAndTraps: PlanTableFormat[];
  selectedRoutesAndTraps: PlanTableFormat[];
  clearSelection: () => void;
  setAutoSequenceTimestamp: Dispatch<SetStateAction<number>>;
};

export const AutoSequenceButton = ({
  selectedRoutesAndTraps,
  setAutoSequenceTimestamp,
}: AutoSequenceButtonProps) => {
  const [searchTerm] = useSearchTermParam();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const routes = selectedRoutesAndTraps.filter(
    (record) => record.type === "delivery_route" && Boolean(!record.isCommitted)
  );

  const isAutoSequenceRunning = routes.some(
    (route) => route.sequenceStatus === "processing"
  );

  const { mutateAsync: autoSequenceRoutes } = useAutoSequenceRoutes();

  const handleAutoSequenceRoutesClick = () => {
    if (routes.length === 0) {
      toast(toastMessage.inbound.autoSequencing.selectAtLeastOneRoute, {
        variant: "warning",
      });
    } else {
      handleAutoSequenceRoutes(routes);
      setAutoSequenceTimestamp(new Date().getTime());
    }
  };

  const handleAutoSequenceRoutes = async (routes: { id: number }[]) => {
    queryClient.setQueryData(
      plannedRoutesQueryKey(planDate, serviceCenter.id, searchTerm),
      (prev: PlanTableFormat[]) =>
        prev.map((route) => {
          const routeStatus = routes.find((r) => route.id === r.id);
          if (!routeStatus) return route;

          return {
            ...route,
            sequenceStatus: "processing",
          };
        })
    );
    await autoSequenceRoutes(routes.map((route) => route.id));
  };

  return (
    <LoadingButton
      sx={{
        width: isAutoSequenceRunning ? 200 : 185,
        ...buttonStyle,
        padding: 0,
      }}
      startIcon={<AutoAwesome />}
      variant="outlined"
      onClick={handleAutoSequenceRoutesClick}
      isLoading={isAutoSequenceRunning}
      loadingLabel="Auto-Sequencing..."
    >
      Auto-Sequence
    </LoadingButton>
  );
};

export const zIndexes = {
  summaryPanel: 999,
  routeDetails: 1000,
  stopDetails: 1001,
  shipmentDetails: 1001,
  stopPopover: 2510,
  dialog: 2511,
  tooltip: 2512,
  stopMarkerTooltipAnchor: 3000,
  draggingSummaryPanel: 10000,
  mapButtons: 1000,
  mapLoader: 1000,
  shipmentNotes: 6000,
  notificationsPanel: 2000,
};

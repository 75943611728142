import { z } from "zod";

const environmentNameSchema = z.enum([
  "local",
  "dev",
  "qa",
  "tst",
  "uat",
  "prod",
]);

const envSchema = z.object({
  VITE_ENVIRONMENT: environmentNameSchema,
  VITE_API_URL: z.string().min(1),
  VITE_HERE_MAPS_KEY: z.string().min(1),
  VITE_ENABLE_DEV_TOOLS: z.union([z.literal("TRUE"), z.literal("FALSE")]),
  VITE_TEST: z
    .union([z.literal("TRUE"), z.literal("FALSE")])
    .optional()
    .default("FALSE"),
  VITE_COGNITO_USER_POOL_ID: z.string().min(1),
  VITE_COGNITO_USER_POOL_CLIENT_ID: z.string().min(1),
  VITE_COGNITO_DOMAIN: z.string().min(1),
  VITE_COGNITO_SIGN_IN_URL: z.string().url(),
  VITE_COGNITO_SIGN_OUT_URL: z.string().url(),
  VITE_ENABLE_HOTJAR: z
    .union([z.literal("TRUE"), z.literal("FALSE")])
    .transform((value) => value === "TRUE"),
  VITE_SPLIT_CLIENT_API_KEY: z.string().min(1),
});

// eslint-disable-next-line no-restricted-properties
export const env = envSchema.parse(import.meta.env);

import { LinehaulTrailerShipments } from "../../hooks/react-query/outbound/linehaulTrailers/useLinehaulTrailerShipments";

export const linehaulTrailerShipmentsResponse = {
  default: {
    summary: {
      tripNumber: 3001249,
      trailerNumber: "DYLT161615",
      tripStatus: "CPLN",
      bills: 11,
      pieces: 46,
      pallets: 16,
      weight: 8949,
    },
    shipments: [
      {
        proNumber: "151706355",
        destinationCity: "KEARNY",
        destinationZipCode: "07032",
        serviceDue: new Date("2024-03-01T00:00:00"),
        appointmentDate: new Date("2024-03-01"),
        appointmentTime: "00:00",
        consignee: "MARBLE ONLINE",
        consigneeAddress: "",
        shipper: "SEFL",
        shipmentId: 10101010,
        pieces: 0,
        pallets: 1,
        weight: 163,
        shipmentTags: [
          {
            id: 9,
            name: "DTCL",
            color: "#5E9FE0",
            description: "Delivery to Connecting Line",
          },
        ],
        status: "CLDV",
        destinationSic: "EWR",
        etaDate: new Date("2024-03-18"),
        etaTime: "00:00",
        assignment: "",
        trailerNumber: "DYLT161615",
        isShipmentOnCurrentSic: false,
      },
    ],
  },
} as const satisfies Record<string, LinehaulTrailerShipments>;

import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { useMemo } from "react";
import { isDeepEqual } from "remeda";
import { Add } from "@mui/icons-material";
import type { Appointment } from "../../../services/prePlanningService.types";
import type { Trap } from "../../../types/planning/trap.type";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useAddToPlan } from "../../../hooks/react-query/useAddToPlan";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { labelButtonStyle } from "../../../components/shared/PopupMenu";
import type { SummaryContext } from "./ActionsMenuContext";

interface AddToPlanMenuItemProps {
  selectedTrapOrBucket: Array<Trap | Appointment>;
  selectedRowsContext: SummaryContext[];
  clearSelection?: () => void;
}

const AddToPlanMenuItem = ({
  selectedTrapOrBucket,
  selectedRowsContext,
  clearSelection,
}: AddToPlanMenuItemProps) => {
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter] = useSelectedServiceCenter();

  const selectedShipmentIds = selectedRowsContext.flatMap(
    (trapOrBucket) => trapOrBucket.shipmentIds
  );

  const isEnabledWhileSearching = useMemo(
    function enableWhileSearching() {
      return isDeepEqual(
        selectedRowsContext.flatMap((row) => row.shipmentIds),
        selectedShipmentIds
      );
    },
    [selectedRowsContext, selectedShipmentIds]
  );

  const addToPlan = useAddToPlan({
    date: dateToInt(selectedDate),
    sicId: serviceCenter.id,
    shipmentIds: selectedShipmentIds,
    onSuccess: (affectedShipmentsCount: number) => {
      clearSelection?.();
      toast(
        toastMessage.inbound.addToPlan.successAdding(affectedShipmentsCount),
        {
          variant: "success",
        }
      );
    },
  });

  const handleAddToPlan = () => {
    if (
      selectedRowsContext.every((r) => r.type === "bucket") ||
      selectedRowsContext.every((r) => r.type === "shipment")
    ) {
      return addToPlan.mutate();
    }

    if (selectedTrapOrBucket.length === 0 || !isEnabledWhileSearching) {
      toast(toastMessage.inbound.addToPlan.selectOne, {
        variant: "warning",
      });
    } else if (
      selectedTrapOrBucket.every(
        (trapOrBucket) => trapOrBucket.shipmentsCount === 0
      )
    ) {
      toast(toastMessage.inbound.addToPlan.emptyTrapSelected, {
        variant: "warning",
      });
    } else if (
      selectedTrapOrBucket.every(
        (trapOrBucket) => trapOrBucket.allShipmentsPlanned === true
      )
    ) {
      toast(toastMessage.inbound.addToPlan.plannedTrapSelected, {
        variant: "warning",
      });
    } else {
      addToPlan.mutate();
    }
  };

  return (
    <MenuItem onClick={handleAddToPlan}>
      <ListItemIcon>
        <Add />
      </ListItemIcon>
      <Button
        disabled={!isEnabledWhileSearching}
        sx={labelButtonStyle}
        disableRipple
      >
        {addToPlan.isPending ? "Adding..." : "Add to Plan"}
      </Button>
    </MenuItem>
  );
};

export default AddToPlanMenuItem;

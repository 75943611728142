import { useState, useRef, useEffect } from "react";
import { Warning } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
  Typography,
  colors,
} from "@mui/material";

type TextFieldWithWarningProps = {
  /** Warning displayed next to the warning icon whenever there is an error. Only shown when helperText is set. */
  warning?: string;
} & TextFieldProps;

export const TextField = ({
  helperText,
  InputProps,
  warning,
  ...props
}: TextFieldProps & TextFieldWithWarningProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [adornmentWidth, setAdornmentWidth] = useState(0);
  const adornmentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (adornmentRef.current) {
      setAdornmentWidth(adornmentRef.current.offsetWidth);
    }
  }, [adornmentRef.current?.offsetWidth]);

  return (
    <MuiTextField
      {...props}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      InputLabelProps={{
        sx: !isFocused
          ? {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: `calc(100% - ${adornmentWidth + 20}px)`,
            }
          : {},
      }}
      InputProps={{
        ...InputProps,
        endAdornment: helperText ? (
          <div ref={adornmentRef}>
            <InputAdornment position="start">
              <IconButton
                edge="end"
                disableRipple
                sx={{ cursor: "default", marginRight: 0 }}
              >
                <Warning color="warning" />
              </IconButton>
              <Typography sx={{ color: colors.grey[500] }}>
                {warning}
              </Typography>
            </InputAdornment>
            {InputProps?.endAdornment}
          </div>
        ) : (
          <div ref={adornmentRef}>{InputProps?.endAdornment}</div>
        ),
      }}
      error={!!helperText}
      helperText={helperText}
    />
  );
};

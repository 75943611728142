import { Box, Typography } from "@mui/material";

import noDataIcon from "../../assets/no_data_icon.svg";
import { displayMessage } from "../../constants/strings";

interface IndicatorProps {
  message?: string;
  icon?: string;
}

const FeedbackIndicator = ({
  message = displayMessage.default.noResultsAndSearchAgain,
  icon,
}: IndicatorProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 8,
      textAlign: "center",
    }}
  >
    {/* Leaving alt empty since decorative image */}
    <img alt="" src={icon ? icon : noDataIcon} width={120} height={120} />
    <Typography
      sx={{ maxWidth: 400 }}
      marginTop={2}
      fontWeight="bold"
      fontSize="1em"
      color="#aaa"
    >
      {message}
    </Typography>
  </Box>
);

export default FeedbackIndicator;

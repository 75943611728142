import { useState } from "react";
import { Button, Menu, MenuItem, useTheme } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { Filter } from "../../../../types/filter";

interface AddFilterMenuProps {
  filters: Filter[];
  onAddFilter: (filter: Filter) => void;
  filterSectionId?: string;
}

export const AddFilterMenu = ({
  filters,
  onAddFilter,
  filterSectionId,
}: AddFilterMenuProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        aria-label={`${filterSectionId} Add Filter`}
        sx={{
          height: 40,
          color: theme.palette.grey[900],
          fontSize: 14,
          lineHeight: 22,
          fontWeight: 600,
          border: `1px solid ${theme.palette.grey[100]}`,
          borderRadius: "4px",
          padding: "8px",
        }}
        aria-haspopup="menu"
        color="primary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={
          <FilterAlt sx={{ color: theme.palette.grey[600] }} fontSize="small" />
        }
        disabled={filters.length === 0}
      >
        Filter
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {filters.map((filter) => (
          <MenuItem
            key={filter.label}
            onClick={() => {
              onAddFilter(filter);
              setAnchorEl(null);
            }}
            disableRipple
          >
            {filter.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

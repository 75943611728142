import TableCell from "@mui/material/TableCell";
import { flexRender, Row } from "@tanstack/react-table";

type Props<TData> = {
  row: Row<TData>;
};

const BaseTableData = <TData,>({ row }: Props<TData>) => (
  <>
    {row.getVisibleCells().map((cell) => (
      <TableCell key={cell.id} style={{ userSelect: "text" }}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    ))}
  </>
);

export default BaseTableData;

import styled from "@emotion/styled";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  overflow-y: hidden;
  flex: 1;
`;

export const GappedFlexColumn = styled(FlexColumn)`
  gap: ${(props: { gap: number }) => `${props.gap}rem`};
`;

export const GappedFlexRow = styled(FlexRow)`
  gap: ${(props: { gap: number }) => `${props.gap}rem`};
`;

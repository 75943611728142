import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Dialog,
  Box,
  Typography,
  colors,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useReducer, useState } from "react";
import {
  MergeTrapRequest,
  useMergeTrap,
  useGetUnplannedTrapsSummary,
} from "../../../hooks/react-query/preplanning/useTraps";
import { Trap } from "../../../types/planning/trap.type";
import { dateToInt } from "../../../utils/dateTimeHelper";
import Loading from "../../../components/shared/layout/Loading";
import { toast } from "../../../utils/snackbarHelper";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { toastMessage } from "../../../constants/strings";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { SummaryContext } from "./ActionsMenuContext";
import { MergeRoutesTrapsStepOne } from "./MergeRoutesTrapsDialog/MergeRoutesTrapsStepOne";
import {
  MergeRoutesTrapsStepTwo,
  ValidationErrors,
} from "./MergeRoutesTrapsDialog/MergeRoutesTrapsStepTwo";
import {
  initialState,
  mergeRoutesTrapsDialogReducer,
} from "./MergeRoutesTrapsDialog/MergeRoutesTrapsDialogReducer";

type MergeTrapsDialogProps = {
  handleClose: () => void;
  selectedTraps: SummaryContext[];
  clearSelection?: () => void;
};

export const MergeTrapsDialog = ({
  handleClose,
  selectedTraps,
  clearSelection,
}: MergeTrapsDialogProps) => {
  const [serviceCenter] = useSelectedServiceCenter();

  const [bottomErrorMessage, setBottomErrorMessage] = useState<string>("");
  const [formValidationErrors, setFormValidationErrors] =
    useState<ValidationErrors>({});

  const [dialogState, dispatch] = useReducer(mergeRoutesTrapsDialogReducer, {
    ...initialState,
    selectedTrapIds: selectedTraps.map((trap) => +trap.id),
    mergeIntoType: "trap",
  });

  const {
    selectedTrapIds: trapIdsToMerge,
    mergeDestination,
    currentStep,
  } = dialogState;

  const [date] = useDateSearchParamOrFallbackToToday();

  const trapHeaderQuery = useGetUnplannedTrapsSummary(serviceCenter.id);

  const handleSuccess = (_: Trap, variables: MergeTrapRequest) => {
    clearSelection?.();
    handleClose();
    const targetTrap = trapHeaderQuery.data?.find(
      (trap) => trap.id === variables.mergeIntoTrapId
    );
    toast(
      toastMessage.inbound.mergeTraps.success(
        variables.trapIdsToMerge.length,
        targetTrap?.name ?? "",
        targetTrap?.title ?? ""
      ),
      {
        variant: "success",
      }
    );
  };

  const mergeTrap = useMergeTrap(handleSuccess);

  const handleMergeTrap = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const targetTrap = trapHeaderQuery.data?.find(
      (trap) => trap.id === mergeDestination.id
    );

    if (!targetTrap) {
      throw new Error("Selected trap id not found in trapHeaderQuery");
    }

    mergeTrap.mutate({
      doorId: mergeDestination.doorId,
      trailerEquipmentId: mergeDestination.trailerId,
      straightTruckEquipmentId: mergeDestination.straightTruckId,
      mergeIntoTrapId: mergeDestination.id ?? 0,
      name: mergeDestination.originalName,
      trapIdsToMerge,
      sicId: serviceCenter.id,
      date: dateToInt(date),
      customName:
        mergeDestination.customName !== mergeDestination.originalName
          ? mergeDestination.customName
          : null,
    });
  };

  if (!trapHeaderQuery.data) {
    return (
      <Dialog open={true}>
        <DialogTitle>Loading...</DialogTitle>
        <DialogContent>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }

  const availableTraps = trapHeaderQuery.data.filter((trap) =>
    trapIdsToMerge.includes(+trap.id)
  );

  const handleBack = () => {
    setFormValidationErrors({});
    dispatch({
      type: "go_to_previous_step",
    });
  };

  const handleNext = (shouldBypassValidation: boolean) => {
    setBottomErrorMessage("");
    if (shouldBypassValidation || handleTrapSelectionValidation()) {
      dispatch({
        type: "go_to_next_step",
      });
    }
  };

  const handleTrapSelectionValidation = () => {
    if (currentStep === 1 && mergeDestination.id === null) {
      setBottomErrorMessage("Please select a trap.");
      return false;
    }

    return true;
  };

  return (
    <Dialog open={true}>
      <form onSubmit={handleMergeTrap}>
        <DialogTitle sx={{ fontWeight: "bold" }}>Merge Traps</DialogTitle>
        <DialogContent sx={{ minWidth: "500px", padding: "0px" }}>
          <Box position="absolute" right={10} top={10}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          {currentStep === 1 ? (
            <MergeRoutesTrapsStepOne
              dialogState={dialogState}
              dispatch={dispatch}
              selectedTraps={availableTraps.map((trap) => ({
                id: trap.id,
                name: trap.name,
                status: trap.status,
                type: "trap",
                driver: null,
                tractor: null,
                trailer: trap.trailer,
              }))}
              selectedRoutes={[]}
              mergeDestinationOptions={availableTraps.map((trap) => ({
                id: trap.id,
                doorId: trap.doorId,
                trailerId: trap.trailer?.id ?? null,
                straightTruckId: trap.straightTruck?.id ?? null,
                driverId: null,
                status: trap.status,
                tractorId: null,
                startTime: null,
                planDate: dateToInt(date),
                originalName: trap.name,
                customName: trap.name,
              }))}
              handleNext={handleNext}
            />
          ) : (
            <MergeRoutesTrapsStepTwo
              validationErrors={formValidationErrors}
              dialogState={dialogState}
              dispatch={dispatch}
              mergeDestinationName={mergeDestination.originalName}
              showRouteEquipmentSelectors={false}
            />
          )}
          <Divider sx={{ marginTop: "10px" }} />
          <Box ml={3} mt={1} height={20}>
            {bottomErrorMessage && (
              <Typography
                role="alert"
                fontSize="small"
                color={(theme) => theme.palette.error.main}
              >
                {bottomErrorMessage}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
          <Button
            variant="outlined"
            onClick={currentStep === 1 ? handleClose : handleBack}
            color="inherit"
            sx={{
              border: "none",
              color: colors.blue[700],
              textTransform: "none",
            }}
          >
            {currentStep === 1 ? "Cancel" : "Back"}
          </Button>
          {currentStep === 2 ? (
            <LoadingButton
              isLoading={mergeTrap.isPending}
              loadingLabel="Merging..."
            >
              Merge
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleNext(false)}
              aria-disabled={bottomErrorMessage.length > 0}
              sx={{
                border: "none",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

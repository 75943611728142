import { ReactNode } from "react";
import { Route, ViewModule } from "@mui/icons-material";
import { Box, ListItem, Typography } from "@mui/material";
import { ReadOnlyStatusTag } from "../../pages/inbound/inboundPlanning/ReadOnlyStatusTag";
import { AssignmentDetails } from "../../types/assignment.type";

export type AutocompleteAvailabilityOptionProps = {
  listItemProps?: React.HTMLAttributes<HTMLLIElement>;
  name: string;
  assignment: AssignmentDetails | null;
  leftIcon?: ReactNode;
};

const greyedOutOpacityStyle = { opacity: 0.54 };

export const AutocompleteAvailabilityOption = ({
  listItemProps,
  name,
  assignment,
  leftIcon,
}: AutocompleteAvailabilityOptionProps) => (
  <ListItem {...listItemProps} key={name}>
    <Box width="auto" display="flex" flexDirection="row">
      {leftIcon && (
        <Box
          display="flex"
          flexDirection="row"
          alignContent="center"
          sx={greyedOutOpacityStyle}
          mr="5px"
        >
          {leftIcon}
        </Box>
      )}
      <Typography mt="2px" mr="10px">
        {name}
      </Typography>
      {assignment?.name && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={2}
            mr="5px"
            sx={{ backgroundColor: "#F5F5F5" }}
          >
            {assignment.type === "Route" ? (
              <Route sx={{ margin: "2px", ...greyedOutOpacityStyle }} />
            ) : (
              <ViewModule sx={{ margin: "2px", ...greyedOutOpacityStyle }} />
            )}
          </Box>
          <Typography mt="2px" sx={greyedOutOpacityStyle}>
            {assignment.name}
          </Typography>
          <Box ml={1}>
            <ReadOnlyStatusTag status={assignment.status} />
          </Box>
          {assignment.extraAssignmentsCount > 0 && (
            <Typography
              ml={1}
              sx={greyedOutOpacityStyle}
            >{`+${assignment.extraAssignmentsCount} more`}</Typography>
          )}
        </>
      )}
    </Box>
  </ListItem>
);

import { DeliveryEquipment } from "../../types/deliveryEquipment.type";
import { EquipmentType } from "../../types/equipment.type";

type EquipmentBySicIdAndType = Record<EquipmentType, DeliveryEquipment[]>;

export const mockEquipmentsBySicIdAndTypeData = {
  default: {
    tractor: [
      {
        id: 1651,
        name: "HTX200A",
        equipmentType: 1,
      },
      {
        id: 16893,
        name: "EWR408A",
        equipmentType: 1,
      },
      {
        id: 1855,
        name: "EWR505B",
        equipmentType: 1,
      },
    ],
    trailer: [
      {
        id: 1022,
        name: "480-1005",
        equipmentType: 2,
      },
      {
        id: 884,
        name: "DYLT161619",
        equipmentType: 2,
      },
      {
        id: 1061,
        name: "DYLT161625",
        equipmentType: 2,
      },
      {
        id: 880,
        name: "530-1044",
        equipmentType: 2,
      },
      {
        id: 1183,
        name: "530-1045",
        equipmentType: 2,
      },
    ],
    straightTruck: [
      {
        id: 1413,
        name: "HTX201A",
        equipmentType: 3,
      },
      {
        id: 2220,
        name: "EWR405A",
        equipmentType: 3,
      },
      {
        id: 2371,
        name: "EWR406A",
        equipmentType: 3,
      },
    ],
  },
} satisfies Record<string, EquipmentBySicIdAndType>;

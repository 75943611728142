import { GetDestinationShipmentsResponse } from "../../../../hooks/react-query/outbound/freightDetails/useDestinationShipments";

export const laneShipments = {
  default: [
    {
      shipmentId: 132371481,
      proNumber: "103399044",
      currentSicId: 111,
      originSicId: 111,
      originSic: "ONT",
      loadToSicId: 84,
      loadToSic: "AFW",
      finalDestinationSicId: 84,
      finalDestinationSic: "AFW",
      doorNumber: 9,
      cutTime: "00:00:00",
      pallets: 2,
      pieces: 0,
      weight: 1910,
      serviceDue: new Date(),
      tags: [],
      consignee: "ROBINSON ENTERPRISES",
      status: "ENRT",
      hasServiceDueValidationIssue: false,
    },
    {
      shipmentId: 132372376,
      proNumber: "144846862",
      currentSicId: 111,
      originSicId: 111,
      originSic: "ONT",
      loadToSicId: 84,
      loadToSic: "AFW",
      finalDestinationSicId: 84,
      finalDestinationSic: "AFW",
      doorNumber: 9,
      cutTime: "00:00:00",
      pallets: 3,
      pieces: 0,
      weight: 1670,
      serviceDue: new Date(),
      tags: [],
      consignee: "STAR SERVICE VALERO ADMIN",
      status: "ENRT",
      hasServiceDueValidationIssue: false,
    },
  ],
} as const satisfies Record<string, GetDestinationShipmentsResponse>;

export const dfwLaneShipments = {
  default: [
    {
      shipmentId: 132371555,
      proNumber: "103399555",
      currentSicId: 111,
      originSicId: 111,
      originSic: "ONT",
      loadToSicId: 84,
      loadToSic: "DAL",
      finalDestinationSicId: 84,
      finalDestinationSic: "AFW",
      doorNumber: 9,
      cutTime: "00:00:00",
      pallets: 2,
      pieces: 0,
      weight: 1910,
      serviceDue: new Date(),
      tags: [],
      consignee: "ROBINSON ENTERPRISES",
      status: "ENRT",
      hasServiceDueValidationIssue: false,
    },
    {
      shipmentId: 132372666,
      proNumber: "144846666",
      currentSicId: 111,
      originSicId: 111,
      originSic: "ONT",
      loadToSicId: 84,
      loadToSic: "DAL",
      finalDestinationSicId: 84,
      finalDestinationSic: "AFW",
      doorNumber: 9,
      cutTime: "00:00:00",
      pallets: 3,
      pieces: 0,
      weight: 1670,
      serviceDue: new Date(),
      tags: [],
      consignee: "STAR SERVICE VALERO ADMIN",
      status: "ENRT",
      hasServiceDueValidationIssue: false,
    },
  ],
} as const satisfies Record<string, GetDestinationShipmentsResponse>;

export const dalLaneShipments = {
  default: [
    {
      shipmentId: 132371999,
      proNumber: "103399999",
      currentSicId: 111,
      originSicId: 111,
      originSic: "ONT",
      loadToSicId: 84,
      loadToSic: "DAL",
      finalDestinationSicId: 84,
      finalDestinationSic: "AFW",
      doorNumber: 9,
      cutTime: "00:00:00",
      pallets: 2,
      pieces: 0,
      weight: 1910,
      serviceDue: new Date(),
      tags: [],
      consignee: "ROBINSON ENTERPRISES",
      status: "ENRT",
      hasServiceDueValidationIssue: false,
    },
    {
      shipmentId: 132372000,
      proNumber: "1448460000",
      currentSicId: 111,
      originSicId: 111,
      originSic: "ONT",
      loadToSicId: 84,
      loadToSic: "DAL",
      finalDestinationSicId: 84,
      finalDestinationSic: "AFW",
      doorNumber: 9,
      cutTime: "00:00:00",
      pallets: 3,
      pieces: 0,
      weight: 1670,
      serviceDue: new Date(),
      tags: [],
      consignee: "STAR SERVICE VALERO ADMIN",
      status: "ENRT",
      hasServiceDueValidationIssue: false,
    },
  ],
} as const satisfies Record<string, GetDestinationShipmentsResponse>;

import { Box } from "@mui/material";
import { useSelectionContext } from "../../../context/SelectionContext";
import { Button } from "../../../components/shared/Button";
import { useClearSelectionWhenServiceCenterChanges } from "../../../hooks/useClearSelectionWhenServiceCenterChanges";

export const ClearSelectionButton = () => {
  const { selected, clearSelection } = useSelectionContext();
  useClearSelectionWhenServiceCenterChanges(clearSelection);

  // Check if any item is selected
  if (
    !Object.values(selected).some((values) => values.shipmentIds.length > 0)
  ) {
    return null;
  }

  return (
    <Box>
      <Button
        round={100}
        variant="contained"
        textColor="white"
        onClick={() => {
          clearSelection();
        }}
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "3rem",
          whiteSpace: "nowrap",
        }}
      >
        Clear selection
      </Button>
    </Box>
  );
};

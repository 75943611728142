import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { appointmentResponseSchema } from "../../../services/prePlanningService.types";
import { http } from "../../../utils/httpCommon";
import { apiUrls } from "../../../utils/apiUrls";

const APPOINTMENTS_SEARCH_TERM_KEY = "apptSearchTerm";

const appointmentsQueryKey = (
  sicId: number,
  apptSearchTerm: string | null = ""
) => ["pre-planning", sicId, "appointments", apptSearchTerm];

export function useGetAppointmentBucketData(
  sicId: number,
  apptSearchTerm = "",
  isNewTableEnabled = false
) {
  return useQuery({
    refetchOnMount: isNewTableEnabled ? false : true,
    queryKey: appointmentsQueryKey(sicId, apptSearchTerm),
    queryFn: async () => {
      const url = apiUrls.getAppointmentBucket(sicId, apptSearchTerm);
      const { data } = await http.get(url);
      return appointmentResponseSchema.parse(data);
    },
  });
}

export function useInvalidateQueryAppointmentBucketData() {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const invalidateQueryAppointmentBucketData = (sicId: number) => {
    queryClient.invalidateQueries({
      queryKey: appointmentsQueryKey(
        sicId,
        searchParams.get(APPOINTMENTS_SEARCH_TERM_KEY) ?? ""
      ),
    });
  };

  return { invalidateQueryAppointmentBucketData };
}

import { Construction } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export const WorkInProgress = () => (
  <Stack alignItems="center" justifyContent="center" height="100%" gap={4}>
    <Construction sx={{ width: 48, height: 48 }} />
    <Typography variant="h3">Work in Progress</Typography>
    <Typography variant="h4">Check back later</Typography>
  </Stack>
);

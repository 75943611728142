import { z } from "zod";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { handleSearchTermParam, Prefix } from "../constants/filters/utils";

const schema = z.string();

export const SEARCH_TERM_KEY = "searchTerm";

export const useSearchTermParam = (prefix?: Prefix) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const param = handleSearchTermParam(prefix);
  const validation = schema.safeParse(param);
  if (!validation.success) {
    throw new Error("Invalid search term:", validation.error);
  }

  const searchTerm = searchParams.get(param) || "";

  const setSearchTerm = useCallback(
    (term: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      if (term) {
        newSearchParams.set(param, term);
      } else {
        newSearchParams.delete(param);
      }

      setSearchParams(newSearchParams);
    },
    [param, searchParams, setSearchParams]
  );

  return [searchTerm, setSearchTerm] as const;
};

import React from "react";
import { visuallyHidden } from "@mui/utils";

type TableCaptionProps = {
  children: React.ReactNode;
};

export default function TableCaption({ children }: TableCaptionProps) {
  return <caption style={visuallyHidden}>{children}</caption>;
}

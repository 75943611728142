import { OptionsWithExtraProps, SnackbarMessage, VariantType } from "notistack";
import { sanitizeLog } from "./logSanitizer";

export const toast = async (
  message: SnackbarMessage,
  options?: OptionsWithExtraProps<VariantType>
) => {
  if (options?.variant === "error") {
    // We will be doing something with the error messages here
    // Sanitize the message before logging - Veracode CWE-117 Recommendation
    // eslint-disable-next-line no-console
    console.error(sanitizeLog(message));
  }

  const mergedOptions = {
    ...options,
    SnackbarProps: {
      ...(options?.SnackbarProps || {}),
      "aria-label": message?.toString(),
      role: "alert",
    },
  };

  const { enqueueSnackbar } = await import("notistack");

  return enqueueSnackbar(message, mergedOptions);
};

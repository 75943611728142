import { Collapse, TableCell, TableRow, useTheme } from "@mui/material";
import { Row, Table } from "@tanstack/react-table";
import { MouseEvent, ReactNode, useRef } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { tableDraggingColor } from "../../../constants/DragDropColors";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import ContextMenuDropdown, { ContextMenuRef } from "../ContextMenuDropdown";
import { getDroppableStyleRow } from "../../../utils/dragging";
import { useSelectionContext } from "../../../context/SelectionContext";
import { RowError as RowErrorType } from "../table/table.types";
import DraggableTableRow from "./DraggableTableRow";
import BaseTableData from "./BaseTableData";
import { DraggableSelectionCounter } from "./DraggableSelectionCounter";
import {
  RowWithIdAndShipmentIds,
  SelectedRowIdMap,
  SelectedTableRows,
  ShipmentWithId,
  useTableSelectionContext,
} from "./TableSelectionContext";
import { TableShipment } from "./ShipmentsTableBase";
import RowError from "./RowError";
import { RowSelectionMode } from "./Selectable.types";
import { TableType } from "./BaseTableBody";

type Props<TData> = {
  /** table from where the row belongs to */
  table: Table<TData>;
  /** row to be rendered */
  row: Row<TData>;
  /** index of the row */
  index: number;
  /** is row draggable? create a draggable row if true */
  isDraggable?: boolean;
  /** droppable id */
  droppableId?: (row: Row<TData>) => string;
  /** collapsible child table row */
  collapsibleChild?: (row: Row<TData>) => ReactNode;
  /** defines the selection mode of the table */
  rowSelectionMode?: RowSelectionMode;
  /** context menu actions items */
  contextMenuActions?: (row?: SelectedTableRows<TData>) => ContextMenuAction[];
  /** contains information of errors in this row */
  rowError?: RowErrorType;
} & TableType;

const BaseTableRowDefinition = <TData,>({
  table,
  tableType,
  parentId,
  row,
  index = 0,
  rowSelectionMode = "unselectable",
  isDraggable = false,
  droppableId,
  collapsibleChild,
  contextMenuActions,
  rowError,
}: Props<TData>) => {
  const theme = useTheme();
  const { handleSelect } = useTableSelectionContext();
  const { draggingSource } = useSelectionContext();
  const contextMenuRef = useRef<ContextMenuRef<TData>>(null);

  const contextActions = contextMenuActions ? contextMenuActions() : [];

  const onContextActionHandler = () => {
    if (
      (rowSelectionMode === "unselectable" ||
        (!table.getIsSomeRowsSelected() &&
          !table.getIsAllPageRowsSelected())) &&
      contextMenuActions
    ) {
      if (tableType === "trip") {
        return contextMenuActions({
          rows: [{ rowId: row.id, selected: false, ...row.original }],
          shipments: [],
        });
      } else {
        return contextMenuActions({
          rows: [],
          shipments: [
            {
              rowId: row.id,
              selected: false,
              parentId,
              ...(row.original as TableShipment),
            },
          ],
        });
      }
    }
  };

  const setRowContextSelection = (selection: SelectedRowIdMap) => {
    if (tableType === "trip") {
      const selectedRows: RowWithIdAndShipmentIds<TData>[] = [];
      Object.keys(selection).forEach((rowId) => {
        const rowModel = table
          .getRowModel()
          .rows.find((row) => row.id === rowId)?.original;
        if (rowModel) {
          selectedRows.push({ ...rowModel, selected: false, rowId });
        }
      });
      handleSelect({
        selectedRows,
        type: "trip",
        filter: true,
      });
    } else {
      const rowIds = [];
      const tableRowIds = table.getRowModel().rowsById;
      for (const row in tableRowIds) {
        rowIds.push(row);
      }
      const selectedRows: ShipmentWithId[] = [];
      Object.keys(selection).forEach((rowId) => {
        const rowModel = table
          .getRowModel()
          .rows.find((row) => row.id === rowId)?.original as
          | TableShipment
          | undefined;
        if (rowModel) {
          selectedRows.push({
            ...rowModel,
            selected: false,
            rowId,
            parentId,
          });
        }
      });
      handleSelect({
        selectedRows,
        type: "shipment",
        rowIds,
      });
    }
  };

  const handleRowSelection = (
    event: MouseEvent<HTMLTableRowElement | HTMLInputElement>,
    row: Row<TData>
  ) => {
    event.stopPropagation();
    // Do nothing if the selection is through the checkboxes
    const target = event.target;
    if ("type" in target && target.type === "checkbox") {
      return;
    } else if (
      rowSelectionMode === "row-click" ||
      rowSelectionMode === "checkbox-and-row-click"
    ) {
      if (event.ctrlKey || event.metaKey) {
        // User clicked the row, with ctrl or cmd pressed will add the row to the selection
        row.toggleSelected();
      } else if (table.getIsSomeRowsSelected() && event.shiftKey) {
        // User clicked the row, with shift pressed will add the rows on the interval to the selection
        // Ex: check row 1, press shift and check row 4, will select from 1 to 4.
        const rows = table.getRowModel().rows;
        const firstSelectedRow = table.getSelectedRowModel().rows[0];
        if (typeof firstSelectedRow === "undefined") {
          throw new Error(
            "Error while selecting a row in a table without rows."
          );
        }
        const selectedRowIdMap: SelectedRowIdMap = {};
        // From top to bottom
        if (firstSelectedRow.index < index) {
          rows.slice(firstSelectedRow.index, index + 1).forEach((row) => {
            selectedRowIdMap[row.id] = true;
          });
        }
        // From bottom to top
        else {
          rows.slice(index, firstSelectedRow.index + 1).forEach((row) => {
            selectedRowIdMap[row.id] = true;
          });
        }
        setRowContextSelection(selectedRowIdMap);
      } else {
        // User clicked the row, but didn't click shift, so select the single row
        const selectedRowIdMap: SelectedRowIdMap = {};
        selectedRowIdMap[row.id] = true;
        setRowContextSelection(selectedRowIdMap);
      }
    }
  };

  const selectionStyle = {
    backgroundColor:
      draggingSource === "mainTable" && row.getIsSelected()
        ? "lightgray"
        : row.getIsSelected()
          ? theme.palette.action.selected
          : "unset",
    userSelect: "none",
  };

  const selectionCount = table.getSelectedRowModel().rows.length || 0;

  const menuDropdown = () => (
    <ContextMenuDropdown
      ref={contextMenuRef}
      actions={() => onContextActionHandler() ?? contextActions}
    />
  );
  const handleContextMenuOpen = (event: MouseEvent) => {
    event.stopPropagation();
    contextMenuRef.current?.openContextMenu(event, row.original);
  };

  const collapsibleChildTableRow = row.getIsExpanded() && (
    <TableRow>
      <TableCell
        sx={{
          borderLeft: "8px solid #0A42CC",
        }}
        colSpan={row.getVisibleCells().length}
      >
        <Collapse in timeout="auto">
          {rowError && <RowError rowId={row.id} {...rowError} />}
          {collapsibleChild?.(row)}
        </Collapse>
      </TableCell>
    </TableRow>
  );

  // All features go here
  if (
    rowSelectionMode !== "unselectable" &&
    isDraggable &&
    contextActions.length > 0
  ) {
    return (
      <>
        <Draggable draggableId={row.id} index={index}>
          {(
            draggableProvided: DraggableProvided,
            snapshot: DraggableStateSnapshot
          ) => (
            <TableRow
              onContextMenu={handleContextMenuOpen}
              onClick={(event) => handleRowSelection(event, row)}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              sx={{
                backgroundColor: snapshot.isDragging
                  ? tableDraggingColor
                  : selectionStyle,
              }}
            >
              <BaseTableData row={row} />
              {snapshot.isDragging && selectionCount > 1 && (
                <DraggableSelectionCounter>
                  {selectionCount}
                </DraggableSelectionCounter>
              )}
            </TableRow>
          )}
        </Draggable>
        {menuDropdown()}
      </>
    );
  }
  // Selectable and draggable
  else if (rowSelectionMode !== "unselectable" && isDraggable) {
    return (
      <Draggable draggableId={row.id} index={index}>
        {(
          draggableProvided: DraggableProvided,
          snapshot: DraggableStateSnapshot
        ) => (
          <TableRow
            onClick={(event) => handleRowSelection(event, row)}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            sx={{
              backgroundColor: snapshot.isDragging
                ? tableDraggingColor
                : selectionStyle,
            }}
          >
            <BaseTableData row={row} />
            {snapshot.isDragging && selectionCount > 1 && (
              <DraggableSelectionCounter>
                {selectionCount}
              </DraggableSelectionCounter>
            )}
          </TableRow>
        )}
      </Draggable>
    );
  }
  // Selectable and context menu
  else if (rowSelectionMode !== "unselectable" && contextActions.length > 0) {
    return droppableId ? (
      <Droppable droppableId={droppableId(row)}>
        {(
          droppableProvided: DroppableProvided,
          snapshot: DroppableStateSnapshot
        ) => (
          <>
            <TableRow
              key={row.id}
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              sx={
                snapshot.isDraggingOver
                  ? getDroppableStyleRow(snapshot.isDraggingOver)
                  : selectionStyle
              }
              onClick={(event) => handleRowSelection(event, row)}
              onContextMenu={handleContextMenuOpen}
            >
              <BaseTableData row={row} />
            </TableRow>
            {collapsibleChildTableRow}
            {menuDropdown()}
          </>
        )}
      </Droppable>
    ) : (
      <>
        <TableRow
          key={row.id}
          sx={selectionStyle}
          onClick={(event) => handleRowSelection(event, row)}
          onContextMenu={handleContextMenuOpen}
        >
          <BaseTableData row={row} />
        </TableRow>
        {menuDropdown()}
      </>
    );
  }

  // Draggable and context menu
  else if (isDraggable && contextActions.length > 0) {
    return (
      <>
        <Draggable draggableId={row.id} index={index}>
          {(
            draggableProvided: DraggableProvided,
            snapshot: DraggableStateSnapshot
          ) => (
            <TableRow
              onContextMenu={handleContextMenuOpen}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              sx={{
                backgroundColor: snapshot.isDragging
                  ? tableDraggingColor
                  : selectionStyle,
              }}
            >
              <BaseTableData row={row} />
              {snapshot.isDragging && selectionCount > 1 && (
                <DraggableSelectionCounter>
                  {selectionCount}
                </DraggableSelectionCounter>
              )}
            </TableRow>
          )}
        </Draggable>
        {menuDropdown()}
      </>
    );
  }

  // Only selectable function
  else if (rowSelectionMode !== "unselectable") {
    return droppableId ? (
      <Droppable droppableId={droppableId(row)}>
        {(
          droppableProvided: DroppableProvided,
          snapshot: DroppableStateSnapshot
        ) => (
          <>
            <TableRow
              key={row.id}
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              sx={
                snapshot.isDraggingOver
                  ? getDroppableStyleRow(snapshot.isDraggingOver)
                  : selectionStyle
              }
              onClick={(event) => handleRowSelection(event, row)}
            >
              <BaseTableData row={row} />
            </TableRow>
            {collapsibleChildTableRow}
          </>
        )}
      </Droppable>
    ) : (
      <>
        <TableRow
          key={row.id}
          sx={selectionStyle}
          onClick={(event) => handleRowSelection(event, row)}
        >
          <BaseTableData row={row} />
        </TableRow>
        {collapsibleChildTableRow}
      </>
    );
  }

  // Only draggable function
  else if (isDraggable) {
    return (
      <DraggableTableRow
        id={row.id}
        index={index}
        selectionCount={selectionCount}
      />
    );
  }

  // Only context menu
  else if (contextActions.length > 0) {
    return (
      <>
        <TableRow onContextMenu={handleContextMenuOpen}>
          <BaseTableData row={row} />
        </TableRow>
        {menuDropdown()}
      </>
    );
  }

  // Return a basic row
  return (
    <>
      <TableRow key={row.id}>
        <BaseTableData row={row} />
      </TableRow>
      {collapsibleChildTableRow}
    </>
  );
};

export default BaseTableRowDefinition;

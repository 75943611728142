import {
  BaseSelectProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  colors,
  styled,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { ReorderShipmentsFn } from "../../../types/assignShipment.type";
import { useReorderShipments } from "../../../hooks/react-query/useReorderShipments";
import { ShipmentWithSequence } from "../../../types/planning/shipmentSeq.type";
import { toastMessage } from "../../../constants/strings";
import { toast } from "../../../utils/snackbarHelper";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import Loading from "../../../components/shared/layout/Loading";
import { queryClient } from "../../../queryClientProvider";
import { plannedRoutesQueryKey } from "../../../hooks/react-query/usePlannedRoutes";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSearchTermParam } from "../../../hooks/useSearchTermParam";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { InboundPlanningOutletContext } from "./InboundPlanningLayout";

type SelectOption = { stopSeq: number; id: number };

interface StopSequenceSelectProps extends BaseSelectProps {
  /** The id of the route the shipments are in */
  routeOrTrap: PlanTableFormat;
  /** The stop sequence value of the shipment */
  stopSeq: number;
  /** Array of stop sequence options */
  options: SelectOption[];
}

const StyledSelect = styled(Select)(() => ({
  minWidth: "60px",
  backgroundColor: colors.grey[100],
  ":hover": {
    backgroundColor: colors.grey[200],
  },
  "&.Mui-focused": {
    backgroundColor: colors.grey[300],
  },
  fieldset: {
    border: "none",
  },
}));

const StopSequenceSelect = ({
  routeOrTrap,
  stopSeq,
  options,
}: StopSequenceSelectProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);
  const [searchTerm] = useSearchTermParam();

  const { shipmentActionDialogRef } =
    useOutletContext<InboundPlanningOutletContext>();

  const { mutate: reOrderShipment, isPending: isReorderPending } =
    useReorderShipments({
      onSuccess: async (routeId: number) => {
        shipmentActionDialogRef.current?.close();
        await queryClient.invalidateQueries();
        queryClient.setQueryData<PlanTableFormat[]>(
          plannedRoutesQueryKey(planDate, serviceCenter.id, searchTerm),
          (previousStateOfPlans) => {
            if (!previousStateOfPlans) return [];
            return previousStateOfPlans.map((plan) => {
              if (plan.id === routeId) {
                return { ...plan, error: null };
              }
              return plan;
            });
          }
        );
      },
    });

  const getAfterShipmentId = (
    shipments: ShipmentWithSequence[],
    sourceStopSeq: number,
    destinationStopSeq: number
  ) => {
    // To get the correct afterShipmentId position, we need to know if the destination if higher up or down
    // from the source stop sequence. If the destination is stop sequence 1, we use "0" to indicate
    // that it should be put in the first position
    if (destinationStopSeq === 1) {
      return 0;
    } else {
      const targetStopSeq =
        sourceStopSeq > destinationStopSeq
          ? destinationStopSeq - 1
          : destinationStopSeq;
      const shipment = shipments.find(
        (shipment) => shipment.stopSeq === targetStopSeq
      );

      if (shipment) {
        return shipment.id;
      } else {
        throw new Error("The shipment id destination cannot be null");
      }
    }
  };

  const handleReorderShipments: ReorderShipmentsFn = ({
    destinationStopSeq,
    sourceStopSeq,
    shipmentIds,
  }) => {
    if (routeOrTrap.type === "delivery_trap") {
      toast(toastMessage.inbound.autoSequencing.notAllowedOnTraps, {
        variant: "error",
      });
      return;
    }

    if (routeOrTrap.shipments.length > 0) {
      const afterShipmentId = getAfterShipmentId(
        routeOrTrap.shipments,
        sourceStopSeq,
        destinationStopSeq
      );
      shipmentActionDialogRef.current?.open("loading", "reorder");
      reOrderShipment({
        routeId: routeOrTrap.id,
        data: {
          shipmentIds,
          afterShipmentId,
        },
      });
    }
  };

  const handleClickSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    // Select all shipments that have the origin stop sequence and
    // put them into the selected stop sequence
    handleReorderShipments({
      shipmentIds: options
        .filter((option) => option.stopSeq === stopSeq)
        .map((option) => option.id),
      destinationStopSeq: Number(e.target.value),
      sourceStopSeq: stopSeq,
      routeId: routeOrTrap.id,
    });
  };

  // Shipments with the same address have the same stop sequence
  // So populate the stop sequence dropdown with a unique list of stop sequences
  const uniqueStopSeqOptions: SelectOption[] = options.filter(
    (option, index, options) =>
      index === options.findIndex((x) => x.stopSeq === option.stopSeq)
  );

  if (isReorderPending) {
    return <Loading containerWidth={24} size={24} />;
  }

  return (
    <StyledSelect
      size="small"
      onClick={handleClickSelect}
      value={stopSeq}
      onChange={handleChange}
    >
      {uniqueStopSeqOptions.map((option) => (
        <MenuItem
          disabled={option.stopSeq === stopSeq}
          key={option.stopSeq}
          value={option.stopSeq}
          style={{ display: option.stopSeq === stopSeq ? "none" : "block" }}
        >
          {option.stopSeq}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default StopSequenceSelect;

import { Typography } from "@mui/material";

interface AssignmentTextProps {
  text: string;
}

const AssignmentText = ({ text }: AssignmentTextProps) => (
  <Typography
    sx={{
      color: text === "Unassigned" ? "#949494" : "#000",
    }}
  >
    {text === "none" ? "Unassigned" : text}
  </Typography>
);

export default AssignmentText;

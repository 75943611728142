import dayjs, { Dayjs } from "dayjs";
import { DaylightDateFormat } from "../constants/DaylightDateFormat";

export const tConvert = (time: string | null) => {
  if (!time) return;

  const hour = parseInt(time.slice(0, 2));

  const minutes = parseInt(time.slice(3, 5));

  const suffix = hour >= 12 ? "PM" : "AM";

  const formatHour = hour % 12 || 12;

  return (
    formatHour.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    ":" +
    minutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    " " +
    suffix
  );
};

const convertToYYYYMMDDFormat = (dateTime: Dayjs) =>
  dateTime.format(DaylightDateFormat.ISO_DATE);

export const getDeliveryDate = (
  selectedDate: string | undefined,
  dateSearchParam: string
) => {
  const deliveryDate =
    selectedDate || dateSearchParam || convertToYYYYMMDDFormat(dayjs());

  if (!dayjs(deliveryDate).isValid()) {
    throw new Error("Invalid date format for selected date.");
  }

  return deliveryDate;
};

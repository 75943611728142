import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Row } from "@tanstack/react-table";

type Props<TData> = {
  row: Row<TData>;
  onCollapse: (expanded: boolean, data: TData) => void;
};

const ExpanderCell = <TData,>({ row, onCollapse }: Props<TData>) => (
  <IconButton
    aria-label={`${row.getIsExpanded() ? "Collapse" : "Expand"} ${
      row.id
    } route`}
    size="small"
    onClick={(event) => {
      event.stopPropagation();
      onCollapse(!row.getIsExpanded(), row.original);
      const expandFn = row.getToggleExpandedHandler();
      expandFn();
    }}
  >
    {row.getIsExpanded() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  </IconButton>
);

export default ExpanderCell;

import { useQuery } from "@tanstack/react-query";
import { AuthUser, fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

export interface AuthUserWithEmail extends AuthUser {
  email: string;
}

export const useAuthenticatedUser = () =>
  useQuery({
    queryKey: ["user"],
    queryFn: async (): Promise<AuthUserWithEmail | null> => {
      try {
        const user = await getCurrentUser();
        const authSession = await fetchAuthSession();

        // HACK: Falling back to an empty string if email isn't available. What should happen if email is missing?
        const email =
          authSession.tokens?.idToken?.payload.email?.toString() ?? "";

        const userWithEmail: AuthUserWithEmail = { ...user, email };

        return userWithEmail;
      } catch (error) {
        return null;
      }
    },
  });

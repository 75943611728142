import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { useActionsMenuContext } from "./ActionsMenuContext";
import AddRouteDialog from "./AddRouteDialog";
import AddRouteDialogOld from "./AddRouteDialogOld";

const AddRouteDefaultDialog = () => {
  const editGuardrailsPhase2Enabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-2-client"
  );

  const { closeDialog, shipments, clearSelection } = useActionsMenuContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const formatDate = (date: string) => Number(date.replaceAll("-", ""));

  return editGuardrailsPhase2Enabled ? (
    <AddRouteDialog
      clearTableSelection={clearSelection}
      selectedShipments={shipments}
      title="Create New Manual Delivery Route"
      routeIntDate={formatDate(selectedDate)}
      onCancel={closeDialog}
      trailerSelected={null}
      tractorSelected={null}
      straightTruckSelected={null}
    />
  ) : (
    <AddRouteDialogOld
      clearTableSelection={clearSelection}
      selectedShipments={shipments}
      title="Create New Manual Delivery Route"
      routeIntDate={formatDate(selectedDate)}
      onCancel={closeDialog}
      trailerSelected={null}
      tractorSelected={null}
      straightTruckSelected={null}
    />
  );
};

export default AddRouteDefaultDialog;

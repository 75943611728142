import { DASH_PLACEHOLDER } from "./dashPlaceholder";

export const dashIfEmpty = (
  value: string | number | null | undefined | Date
): string => {
  if (value === null || value === undefined || value === "") {
    return DASH_PLACEHOLDER;
  }

  return value.toString();
};

export const booleanToYesNo = (value: boolean): string =>
  value ? "Yes" : "No";

export const formatNumber = (num?: number): string => {
  if (!num) return "0";
  return num.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};

import { ReactNode } from "react";
import { z } from "zod";
import { Route, ViewModule } from "@mui/icons-material";
import { Box, ListItem, Typography } from "@mui/material";

const assignmentType = z.enum(["Trap", "Route"]);
type AssignmentType = z.infer<typeof assignmentType>;

type AutocompleteAvailabilityOptionOldProps = {
  listItemProps?: React.HTMLAttributes<HTMLLIElement>;
  name: string;
  inUseByName?: string;
  inUseByType?: AssignmentType;
  leftIcon?: ReactNode;
};

export const AutocompleteAvailabilityOptionOld = ({
  listItemProps,
  name,
  inUseByName,
  inUseByType,
  leftIcon,
}: AutocompleteAvailabilityOptionOldProps) => (
  <ListItem {...listItemProps} key={name}>
    <Box width="auto" display="flex" flexDirection="row">
      {leftIcon && (
        <Box
          display="flex"
          flexDirection="row"
          alignContent="center"
          sx={{ opacity: 0.54 }}
          mr="5px"
        >
          {leftIcon}
        </Box>
      )}
      <Typography mt="2px" mr="10px">
        {name}
      </Typography>
      {inUseByName && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={2}
            mr="5px"
            sx={{ backgroundColor: "#F5F5F5" }}
          >
            {inUseByType === "Route" ? (
              <Route sx={{ margin: "2px", opacity: 0.54 }} />
            ) : (
              <ViewModule sx={{ margin: "2px", opacity: 0.54 }} />
            )}
          </Box>
          <Typography mt="2px" sx={{ opacity: 0.54 }}>
            {inUseByName}
          </Typography>
        </>
      )}
    </Box>
  </ListItem>
);

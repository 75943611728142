import { z } from "zod";

export const baseServiceCenterSchema = z.object({
  id: z.number().min(1),
  code: z.string(),
  name: z.string().nullish(),
});

export type BaseServiceCenter = z.infer<typeof baseServiceCenterSchema>;

const serviceCenterSchema = baseServiceCenterSchema.extend({
  name: z.string().nullish(),
  ownership: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().nullish(),
  vendorCode: z.string().optional(),
  serviceCenterCode: z.string().optional(),
  lhBoardRegion: z.any().optional(),
  timeZone: z.string(),
});

export type ServiceCenter = z.infer<typeof serviceCenterSchema>;
export const serviceCenterResponseSchema = z.array(serviceCenterSchema);

export const ONTServiceCenter: ServiceCenter = {
  code: "ONT",
  id: 111,
  name: "ONTARIO",
  timeZone: "America/Los_Angeles",
};

const serviceCenterGeocodeSchema = z.object({
  sicId: z.number(),
  lat: z.number(),
  lng: z.number(),
});

export const serviceCenterGeocodeResponseSchema = z.array(
  serviceCenterGeocodeSchema
);

export type ServiceCenterGeocodeResponse = z.infer<
  typeof serviceCenterGeocodeResponseSchema
>;

import { Box, Chip, styled, Typography } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import { formatNumber } from "../../constants/utils";
import { useIsFeatureFlagEnabled } from "../../featureFlags/useIsFeatureFlagEnabled";
import { iconFillColors } from "./icons/Icon.types";
import PalletsIcon from "./icons/PalletsIcon";
import PiecesIcon from "./icons/PiecesIcon";

type ShipmentDetails = {
  bills: number;
  pallets: number;
  pieces: number;
  weight: number;
  unit?: string;
};

export interface DropdownStatusItemProps {
  label: string;
  status: string;
  shipmentDetails?: ShipmentDetails;
  bgColor?: string;
  color?: string;
}

const SectionBadgeRow = styled(Box)({
  display: "flex",
  gap: "7px",
});

const DropdownStatusItem = ({
  label,
  shipmentDetails,
  bgColor: backgroundColor,
  color,
  status,
}: DropdownStatusItemProps) => {
  const isShipmentDetailEnable = useIsFeatureFlagEnabled(
    "inbound-planning-assign-dialog-show-shipment-detail"
  );

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        {label}
        {isShipmentDetailEnable && shipmentDetails && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="15"
          >
            <SectionBadgeRow>
              <ReceiptIcon
                sx={{
                  color: iconFillColors.gray,
                  fontSize: 16,
                }}
              />
              <Typography sx={{ fontSize: 12 }}>
                {formatNumber(shipmentDetails.bills)}
              </Typography>
            </SectionBadgeRow>

            <SectionBadgeRow>
              <PalletsIcon fill={iconFillColors.gray} size={16} />
              <Typography sx={{ fontSize: 12 }}>
                {formatNumber(shipmentDetails.pallets)}
              </Typography>
            </SectionBadgeRow>
            <SectionBadgeRow>
              <PiecesIcon fill={iconFillColors.gray} size={16} />
              <Typography sx={{ fontSize: 12 }}>
                {formatNumber(shipmentDetails.pieces)}
              </Typography>
            </SectionBadgeRow>
            <SectionBadgeRow>
              <MonitorWeightIcon
                sx={{
                  color: iconFillColors.gray,
                  fontSize: 16,
                }}
              />
              <Typography sx={{ fontSize: 12 }}>
                {formatNumber(shipmentDetails.weight)}{" "}
                {shipmentDetails.unit ? shipmentDetails.unit : "lbs"}
              </Typography>
            </SectionBadgeRow>
          </Box>
        )}
      </Box>

      <Chip
        sx={{
          width: "min-content",
          height: "24px",
          backgroundColor,
          color,
        }}
        label={status}
      />
    </Box>
  );
};
export default DropdownStatusItem;

/**
 * Filters and keeps only the specified parameters from the provided URLSearchParams.
 *
 * @param {URLSearchParams} search - The original URLSearchParams object to filter.
 * @param {string[]} paramsToKeep - The list of parameter names to retain in the resulting URLSearchParams.
 * @returns {URLSearchParams} A new URLSearchParams object containing only the specified parameters.
 */
export const keepSpecificParams = (
  search: URLSearchParams,
  paramsToKeep: string[]
) => {
  const currentSearchParams = new URLSearchParams(search);
  const newSearchParams = new URLSearchParams();
  paramsToKeep.forEach((param) => {
    if (currentSearchParams.has(param)) {
      newSearchParams.set(param, currentSearchParams.get(param) || "");
    }
  });
  return newSearchParams;
};

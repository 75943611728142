import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";
import { useCallback } from "react";
import { http } from "../../utils/httpCommon";
import {
  Equipment,
  equipmentSchema,
  equipmentTypeMap,
  equipmentsSchema,
  equipmentTypeIds,
  EquipmentTypeName,
} from "../../types/equipment.type";
import { apiUrls } from "../../utils/apiUrls";
import {
  DeliveryEquipmentUsage,
  deliveryEquipmentSchema,
  deliveryEquipmentUsageSchema,
} from "../../types/deliveryEquipment.type";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";
import { deliveryEquipmentSchemaByTypeOld } from "../../types/deliveryEquipmentOld.type";

const deliveryEquipmentUsageSchemaByType = z.object({
  trailers: z.array(
    deliveryEquipmentUsageSchema.transform((data) => ({
      ...data,
      name: data.name || `Trailer # ${data.id}`,
    }))
  ),
  straightTrucks: z.array(
    deliveryEquipmentUsageSchema.transform((data) => ({
      ...data,
      name: data.name || `Straight Truck # ${data.id}`,
    }))
  ),
  tractors: z.array(
    deliveryEquipmentUsageSchema.transform((data) => ({
      ...data,
      name: data.name || `Tractor # ${data.id}`,
    }))
  ),
});

type DeliveryRouteEquipmentUsageSet = z.infer<
  typeof deliveryEquipmentUsageSchemaByType
>;

const deliveryEquipmentSchemaByType = z.object({
  trailers: z.array(
    deliveryEquipmentSchema.transform((data) => ({
      ...data,
      name: data.name || `Trailer # ${data.id}`,
    }))
  ),
  straightTrucks: z.array(
    deliveryEquipmentSchema.transform((data) => ({
      ...data,
      name: data.name || `Straight Truck # ${data.id}`,
    }))
  ),
  tractors: z.array(
    deliveryEquipmentSchema.transform((data) => ({
      ...data,
      name: data.name || `Tractor # ${data.id}`,
    }))
  ),
});

type DeliveryRouteEquipmentSet = z.infer<typeof deliveryEquipmentSchemaByType>;

const keys = {
  getEquipments: ["equipments"],
  getEquipmentById: (id: number) => ["equipments", id],
  getEquipmentDetailsBySicId: (sicId: number, types: string[]) => [
    "equipments",
    "sic",
    sicId,
    types,
  ],
  getEquipmentBySicId: (sicId: number) => ["equipments", "sic", sicId],
  getEquipmentUsageBySicId: (sicId: number, planDate: number) => [
    "equipments",
    "sic",
    sicId,
    planDate,
  ],
};

const fetchEquipmentDetails = async (
  sicId: number,
  types: EquipmentTypeName[]
) => {
  const { data } = await http.get(apiUrls.getEquipmentDetailsBySicId(sicId), {
    params: {
      equipmentType: types.join(","),
    },
  });
  return equipmentsSchema.parse(data);
};

export const useEquipmentDetailsBySicId = (
  sicId: number,
  types: EquipmentTypeName[],
  enabled = true
) =>
  useQuery({
    enabled,
    queryKey: keys.getEquipmentDetailsBySicId(sicId, types),
    queryFn: () => fetchEquipmentDetails(sicId, types),
  });

const useEquipmentDetailsBySicIdGroupedByTypeSelect = (
  types: EquipmentTypeName[]
) =>
  useCallback(
    (data: Equipment[]) =>
      // Return data grouped by equipment type
      types.reduce(
        (
          acc: { [key in EquipmentTypeName]: Equipment[] },
          typeName: EquipmentTypeName
        ) => {
          acc[typeName] = data.filter(
            (equipment) => equipment.typeName === typeName
          );
          return acc;
        },
        {
          "Straight Truck": [],
          Tractor: [],
          Trailer: [],
        } satisfies { [key in EquipmentTypeName]: Equipment[] }
      ),
    [types]
  );

export const useEquipmentDetailsBySicIdGroupedByType = (
  sicId: number,
  types: EquipmentTypeName[],
  enabled = true
) =>
  useQuery({
    enabled,
    queryKey: keys.getEquipmentDetailsBySicId(sicId, types),
    queryFn: () => fetchEquipmentDetails(sicId, types),
    select: useEquipmentDetailsBySicIdGroupedByTypeSelect(types),
  });

export const useEquipmentById = (id: number | null) =>
  useQuery({
    queryKey: keys.getEquipmentById(id ?? 0),
    queryFn: async () => {
      if (!id) {
        return;
      }
      const { data } = await http.get(apiUrls.getEquipmentById(id));
      return equipmentSchema.parse(data);
    },
    enabled: Boolean(id),
  });

export const useEquipmentBySicIdAndPlanDateOld = (
  sicId: number,
  planDate: number,
  enabled = true
) =>
  useQuery({
    enabled,
    queryKey: keys.getEquipmentBySicId(sicId),
    queryFn: async () => {
      // Current GetEquipment API doesn't allow us to pass params with an array of equipment types to make one call.
      // For now, we loop through all equipment types, call the API to get the list individually. Once the API is fixed,
      // we will make one call to get all the equipment list.
      const equipmentPromises = equipmentTypeIds.map((type) =>
        http.get(apiUrls.getEquipmentsBySicIdAndPlanDateOld(sicId, type), {
          params: { planDate },
        })
      );

      const [trucks, tractors, trailers] = await Promise.all(equipmentPromises);

      const equipments: DeliveryRouteEquipmentSet = {
        tractors: tractors.data,
        trailers: trailers.data,
        straightTrucks: trucks.data,
      };
      return deliveryEquipmentSchemaByTypeOld.parse(equipments);
    },
  });

export const useEquipmentBySicId = (sicId: number, enabled = true) =>
  useQuery({
    enabled,
    queryKey: keys.getEquipmentBySicId(sicId),
    queryFn: async () => {
      // Current GetEquipment API doesn't allow us to pass params with an array of equipment types to make one call.
      // For now, we loop through all equipment types, call the API to get the list individually. Once the API is fixed,
      // we will make one call to get all the equipment list.
      const equipmentPromises = equipmentTypeIds.map((type) =>
        http.get(apiUrls.getEquipmentsBySicIdAndTypeId(sicId, type))
      );

      const [tractors, trailers, trucks] = await Promise.all(equipmentPromises);

      const equipments: DeliveryRouteEquipmentSet = {
        tractors: tractors.data,
        trailers: trailers.data,
        straightTrucks: trucks.data,
      };
      return deliveryEquipmentSchemaByType.parse(equipments);
    },
  });

export const useEquipmentUsageBySicId = (
  sicId: number,
  planDate: number,
  enabled = true
) =>
  useQuery({
    enabled,
    queryKey: keys.getEquipmentUsageBySicId(sicId, planDate),
    queryFn: async (): Promise<DeliveryRouteEquipmentUsageSet> => {
      const queryString = equipmentTypeIds
        .map((type) => `types=${type}`)
        .join("&");
      const { data } = await http.get<DeliveryEquipmentUsage[]>(
        `${apiUrls.getEquipmentsUsageBySicId(sicId)}?planDate=${planDate}&${queryString}`
      );

      if (data.length === 0) {
        return {
          tractors: [],
          trailers: [],
          straightTrucks: [],
        };
      }

      // TODO: Remove FE grouping by type once the BE returns it with that structure https://dylt.atlassian.net/browse/IODD-2964
      const tractors = data.filter(
        (e) => e.typeId === equipmentTypeMap.tractor
      );
      const trailers = data.filter(
        (e) => e.typeId === equipmentTypeMap.trailer
      );
      const straightTrucks = data.filter(
        (e) => e.typeId === equipmentTypeMap.straightTruck
      );

      return deliveryEquipmentUsageSchemaByType.parse({
        tractors,
        trailers,
        straightTrucks,
      });
    },
  });

export function useDeleteEquipment() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (equipmentId: number) => {
      const url = apiUrls.deleteEquipment(equipmentId);
      const { status } = await http.delete(url);
      return status;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.getEquipments });
    },
  });
}
const equipmentKeys = {
  getEquipment: (equipmentId: number) => ["equipments", equipmentId],
  getEquipments: () => ["equipments"],
};

export function useUpdateEquipment() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (equipment: Equipment) => {
      const url = apiUrls.putEquipment(equipment.id);
      await http.put<Equipment>(url, equipment);
      return { equipment };
    },
    onSuccess: ({ equipment }) => {
      queryClient.invalidateQueries({
        queryKey: equipmentKeys.getEquipments(),
      });
      queryClient.setQueryData(
        equipmentKeys.getEquipment(equipment.id),
        equipment
      );
      toast(toastMessage.settings.equipmentUpdated.success, {
        variant: "success",
      });
    },
    onError: () => {
      toast(toastMessage.settings.equipmentUpdated.failed, {
        variant: "error",
      });
    },
  });
}

export function useCreateEquipment() {
  return useMutation({
    mutationFn: async (equipment: Equipment) => {
      const url = apiUrls.postEquipments;
      const { data } = await http.post<Equipment[]>(url, [equipment]);
      return data;
    },
    onError: (e) => {
      if (!axios.isAxiosError(e)) {
        return toast(toastMessage.generics.error, { variant: "error" });
      }

      if (e.response?.data.includes("REF_TRAILER_NUMBER_ALREADY_EXISTS")) {
        return toast(
          toastMessage.settings.equipments.create.warning
            .duplicatedTrailerNumber,
          { variant: "warning" }
        );
      }
    },
  });
}

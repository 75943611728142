import { ToggleButtonGroup, ToggleButton, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export type ToggleViewGroupOption = {
  tab:
    | "list"
    | "grid"
    | "map"
    | "routes"
    | "sections"
    | "traps"
    | "appointments";
  icon: React.JSX.Element;
};

export type ToggleViewGroupProps<T> = {
  tab: T;
  onViewChange: (newTab: T) => void;
  options: ToggleViewGroupOption[];
};

type ToggleViewGroupWithStateProps<T> = Pick<
  ToggleViewGroupProps<T>,
  "options"
>;

export const StyledToggleButton = styled(ToggleButton)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  height: "40px",
  fontSize: 15,
  fontWeight: 500,
  lineHeight: "26px",
  padding: "0.5rem 1rem",
  "& .MuiSvgIcon-root": {
    fontSize: 24,
  },
}));

export const ToggleViewGroup = <T,>({
  tab,
  onViewChange,
  options,
}: ToggleViewGroupProps<T>) => (
  <ToggleButtonGroup
    value={tab}
    exclusive
    aria-label="view display"
    onChange={(event, value) => {
      if (value) onViewChange(value);
    }}
    color="primary"
  >
    {options.map((toggleOption) => (
      <StyledToggleButton
        aria-label={"View " + toggleOption.tab}
        value={toggleOption.tab}
        key={toggleOption.tab}
      >
        {toggleOption.icon}
        {toggleOption.tab}
      </StyledToggleButton>
    ))}
  </ToggleButtonGroup>
);

export const ToggleViewGroupWithState = <T,>({
  options,
}: ToggleViewGroupWithStateProps<T>) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getRouteFragment = (): string => {
    const routeArr = location.pathname.split("/");
    return routeArr[routeArr.length - 1];
  };

  const tab = getRouteFragment();

  const handleViewChange = (newView: string) => {
    navigate({
      pathname: newView,
      search: location.search,
    });
  };

  return (
    <ToggleViewGroup
      tab={tab}
      onViewChange={handleViewChange}
      options={options}
    />
  );
};

import { LinehaulCarrier } from "../../hooks/react-query/linehaul/useLinehaulCarriers";

export const mockLinehaulCarriers = {
  empty: [],
  default: [
    {
      id: 1,
      name: "AVILENO TRUCKING LLC",
      code: "M163",
    },
    {
      id: 217153,
      name: "AM EXPRESS LINE, INC",
      code: "M110",
    },
    {
      id: 217159,
      name: "COVENANT TRANSPORT INC",
      code: "L004",
    },
    {
      id: 217356,
      name: "GTO TRANSPORTATION",
      code: "SH138",
    },
  ],
} as const satisfies Record<string, LinehaulCarrier[]>;

import {
  forwardRef,
  useImperativeHandle,
  useState,
  type Ref,
  type RefObject,
} from "react";
import dayjs from "dayjs";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { useSearchTermParam } from "../../../hooks/useSearchTermParam";
import { toast } from "../../../utils/snackbarHelper";
import { useRouteAssignment } from "../../../hooks/react-query/useRouteAssignment";
import type { EditedRoute } from "../../../hooks/react-query/useDeliveryRoutes.types";
import { planningStatus } from "../../../types/status.type";
import { toastMessage } from "../../../constants/strings";
import type {
  RouteAssignmentResponse,
  TrapAssignmentResponse,
} from "../../../types/planning/routeAssignmentResponse.type";
import type { PlanTableFormat } from "../../../types/planning/plan.type";
import {
  actionsMap,
  type ShipmentActionDialogRef,
  type ShipmentActionTypeId,
} from "./ShipmentActionDialog";
import {
  EditRouteConfirmDialog,
  type EditedPlan,
} from "./EditRouteConfirmDialog";
import { EditRouteConfirmDialogOld } from "./EditRouteConfirmDialogOld";

type EditRouteOrTrapDialog = {
  routeOrTrap: PlanTableFormat;
  onClose?: () => void;
  onSuccess?: (
    actionType: ShipmentActionTypeId,
    response?: RouteAssignmentResponse | TrapAssignmentResponse
  ) => void;
  shipmentActionDialogRef?: RefObject<ShipmentActionDialogRef>;
};

type EquipmentRestrictionParams = {
  type: "delivery_trap" | "delivery_route";
  status: string;
  doorId?: number | null;
  trailerId?: number | null;
  straightTruckId?: number | null;
};

const isEquipmentRestricted = ({
  type,
  status,
  doorId,
  trailerId,
  straightTruckId,
}: EquipmentRestrictionParams): {
  restricted: boolean;
  missingEquipment?: string;
} => {
  const isRestrictedStatus = ["CLDK", "CLDV", "DISP", "TRAP"].includes(status);

  const equipment =
    type === "delivery_trap"
      ? [
          { key: "Door", value: !doorId },
          {
            key: "Trailer / Straight Truck",
            value: !trailerId && !straightTruckId,
          },
        ]
      : [{ key: "Door", value: !doorId }];

  const missingEquipment = equipment.find((equip) => equip.value)?.key;

  if (isRestrictedStatus && missingEquipment) {
    return { restricted: true, missingEquipment };
  }

  return { restricted: false };
};

export type EditRouteOrTrapDialogRef = {
  handleOpen: () => void;
};

const EditRouteOrTrapDialog = forwardRef(
  (
    {
      routeOrTrap,
      onClose,
      onSuccess,
      shipmentActionDialogRef,
    }: EditRouteOrTrapDialog,
    ref?: Ref<EditRouteOrTrapDialogRef>
  ) => {
    const editGuardrailsPhase2Enabled = useIsFeatureFlagEnabled(
      "inbound-edit-route-trap-guardrails-phase-2-client"
    );
    const isV2FetchStrategyEnabled = useIsFeatureFlagEnabled(
      "inbound-planning-table-v2-fetch-strategy-client"
    );
    const isRemoveDoorNotAllowed = useIsFeatureFlagEnabled(
      "inbound-remove-door-not-allowed-client"
    );

    const [isEditPlanOpen, setIsEditPlanOpen] = useState(false);

    const [serviceCenter] = useSelectedServiceCenter();
    const [selectedDate] = useDateSearchParamOrFallbackToToday();
    const planDate = dateToInt(selectedDate);
    const [searchTerm] = useSearchTermParam();

    useImperativeHandle(ref, () => ({
      handleOpen: () => {
        if (routeOrTrap.status in planningStatus) {
          setIsEditPlanOpen(true);
        } else {
          toast(toastMessage.inbound.editNotAllowedStatus, {
            variant: "warning",
          });
        }
      },
    }));

    const defaultOnSuccessfulAssignment = (
      actionType: ShipmentActionTypeId
    ) => {
      const { description } = actionsMap[actionType];

      toast(description, {
        variant: "success",
      });

      onClose?.();
    };

    const { mutate: editRouteOrTrap } = useRouteAssignment({
      date: planDate,
      sicId: serviceCenter.id,
      searchTerm: isV2FetchStrategyEnabled ? "" : searchTerm,
      onSuccess: (response, request) =>
        onSuccess
          ? onSuccess(
              request?.route.type === "delivery_route"
                ? "editRoute"
                : "editTrap",
              response
            )
          : defaultOnSuccessfulAssignment(
              request?.route.type === "delivery_route"
                ? "editRoute"
                : "editTrap"
            ),
      onSettled: () => {
        shipmentActionDialogRef?.current?.close();
      },
    });

    const handleEditRoute = (route: EditedRoute) => {
      shipmentActionDialogRef?.current?.open(
        "loading",
        route.type === "delivery_route" ? "editRoute" : "editTrap"
      );
      editRouteOrTrap({
        planId: route.id,
        route,
      });
    };

    const editHandler = (editedPlan: EditedPlan) => {
      const editedRoute: EditedRoute = {
        id: editedPlan.id,
        date: routeOrTrap.date,
        sicId: routeOrTrap.sicId,
        name: editedPlan.name,
        presetRouteId: routeOrTrap.presetRouteId,
        driverId: editedPlan.driverId,
        isCommitted: Boolean(routeOrTrap.isCommitted),
        endPoint: editedPlan.routeEnd ?? "",
        type: routeOrTrap.type,
        trailerEquipmentId: editedPlan.trailerEquipmentId,
        tractorEquipmentId: editedPlan.tractorEquipmentId,
        straightTruckEquipmentId: editedPlan.straightTruckEquipmentId,
      };

      if (editedPlan.startTime) {
        editedRoute.startTime = dayjs(editedPlan.startTime, {
          utc: false,
        }).toISOString();
      }

      if (editedPlan.endTime) {
        editedRoute.endTime = dayjs(editedPlan.endTime).utc().toISOString();
      }
      if (editedPlan.doorId) {
        editedRoute.doorId = editedPlan.doorId;
      }
      if (isRemoveDoorNotAllowed) {
        const { restricted, missingEquipment } = isEquipmentRestricted({
          type: routeOrTrap.type,
          status: routeOrTrap.status,
          doorId: editedRoute.doorId,
          trailerId: editedRoute.trailerEquipmentId,
          straightTruckId: editedRoute.straightTruckEquipmentId,
        });

        if (restricted && missingEquipment) {
          const errorMessage =
            toastMessage.inbound.removeEquipmentNotAllowedFromTrapOrRoute(
              missingEquipment,
              routeOrTrap.type === "delivery_trap" ? "Trap" : "Route"
            );
          toast(errorMessage, { variant: "error" });
        }
      }

      handleEditRoute(editedRoute);
      cancelHandler();
    };

    const cancelHandler = () => {
      setIsEditPlanOpen(false);
    };

    return editGuardrailsPhase2Enabled ? (
      <EditRouteConfirmDialog
        plan={routeOrTrap}
        open={isEditPlanOpen}
        savePlan={editHandler}
        cancelHandler={cancelHandler}
      />
    ) : (
      <EditRouteConfirmDialogOld
        plan={routeOrTrap}
        open={isEditPlanOpen}
        savePlan={editHandler}
        cancelHandler={cancelHandler}
      />
    );
  }
);

EditRouteOrTrapDialog.displayName = "EditRouteOrTrapDialog";

export default EditRouteOrTrapDialog;

import { SxProps, Theme } from "@mui/material";
import { z } from "zod";
import { tableDraggingColor } from "../constants/DragDropColors";
import {
  AssignShipmentType,
  assignShipmentTypeSchema,
} from "../types/assignShipment.type";

// CSpell:ignore skyblue
export function getDroppableStyleRow(isDraggingOver?: boolean): SxProps {
  return {
    background: isDraggingOver ? "skyblue" : "unset",
    border: 1,
    borderRadius: 1,
    margin: 1,
    padding: 2,
    transition: "background-color 0.5s ease",
  };
}

export function getDroppableStyleCard(isDraggingOver: boolean): SxProps {
  return {
    background: isDraggingOver ? "skyblue" : "",
    margin: isDraggingOver ? 1 : undefined,
    padding: isDraggingOver ? 2 : undefined,
    transition: "background-color 0.5s ease",
  };
}

export function getDraggableStyleCard(
  isDragging: boolean,
  draggableStyle: any,
  theme: Theme,
  draggingBackgroundColor?: string,
  isSelected?: boolean
): React.CSSProperties {
  return {
    userSelect: "none",
    backgroundColor: isDragging
      ? draggingBackgroundColor
      : isSelected
        ? theme.palette.action.selected
        : "white",
    ...draggableStyle,
  };
}

export function getDraggableStyleRow(
  isDragging: boolean,
  draggableStyle: any,
  theme: Theme,
  isSelected?: boolean
): React.CSSProperties {
  return {
    userSelect: "none",
    position: !isDragging ? "absolute !important" : undefined,
    backgroundColor: isDragging
      ? tableDraggingColor
      : isSelected
        ? theme.palette.action.selected
        : "white",
    ...draggableStyle,
  };
}

const deliveryDataType = z.enum(["delivery_trap", "delivery_route"]);
type DeliveryDataType = z.infer<typeof deliveryDataType>;

export const getDroppableId = (
  source: DragSourceOrDestination,
  dataType: AssignShipmentType | DeliveryDataType,
  id: string | number,
  suffix?: string
) => {
  if (dataType === "delivery_route") {
    dataType = "route";
  } else if (dataType === "delivery_trap") {
    dataType = "trap";
  }

  return suffix
    ? `${source}-${dataType}-${id}-${suffix}`
    : `${source}-${dataType}-${id}`;
};

const dragSourceOrDestinationSchema = z.enum([
  "mainTable",
  "shipmentTable",
  "sidebar",
  "mainGrid",
  "unassigned",
]);
export type DragSourceOrDestination = z.infer<
  typeof dragSourceOrDestinationSchema
>;

const droppableIdSchema = z.tuple([
  dragSourceOrDestinationSchema,
  assignShipmentTypeSchema,
  z.string().min(1),
]);

type Droppable = {
  source: DragSourceOrDestination;
  type: AssignShipmentType;
  id: string;
};

/** Accepts a droppableId and returns a structured object.
 * @param droppableId - The droppableId to parse using the droppableIdSchema
 */
export function parseDroppableId(droppableId: string): Droppable {
  const [source, type, id] = droppableIdSchema.parse(droppableId.split("-"));
  const droppable: Droppable = { source, type, id };
  return droppable;
}

import { DeliveryEquipmentUsage } from "../../types/deliveryEquipment.type";

export const mockEquipmentsUsageBySicIdData = {
  empty: [],
  default: [
    {
      id: 1651,
      name: "HTX200A",
      typeId: 1,
      inUseByInfo: null,
      planningAssignmentsInfo: [
        {
          id: 168,
          type: "Trap",
          name: "Trap-33",
          status: "TPLN",
        },
      ],
    },
    {
      id: 16893,
      name: "EWR408A",
      typeId: 1,
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
    {
      id: 1855,
      name: "EWR505B",
      typeId: 1,
      inUseByInfo: {
        id: 55,
        type: "Trap",
        name: "ONT-523",
        status: "CLDK",
      },
      planningAssignmentsInfo: [],
    },
    {
      id: 1022,
      name: "480-1005",
      typeId: 2,
      inUseByInfo: null,
      planningAssignmentsInfo: [
        {
          id: 155,
          type: "Trap",
          name: "FRESNO",
          status: "CPLN",
        },
      ],
    },
    {
      id: 884,
      name: "DYLT161619",
      typeId: 2,
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
    {
      id: 880,
      name: "530-1044",
      typeId: 2,
      inUseByInfo: {
        id: 65,
        type: "Route",
        name: "ANAHEIM",
        status: "CLDK",
      },
      planningAssignmentsInfo: [],
    },
    {
      id: 1183,
      name: "530-1045",
      typeId: 2,
      inUseByInfo: {
        id: 55,
        type: "Trap",
        name: "ONT-523",
        status: "CLDK",
      },
      planningAssignmentsInfo: [],
    },
    {
      id: 1413,
      name: "HTX201A",
      typeId: 3,
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
    {
      id: 2220,
      name: "EWR405A",
      typeId: 3,
      inUseByInfo: null,
      planningAssignmentsInfo: [
        {
          id: 155,
          type: "Trap",
          name: "Trap-22",
          status: "TPLN",
        },
      ],
    },
    {
      id: 2371,
      name: "EWR406A",
      typeId: 3,
      inUseByInfo: {
        id: 55,
        type: "Trap",
        name: "ONT-523",
        status: "CLDK",
      },
      planningAssignmentsInfo: [],
    },
  ],
} satisfies Record<string, DeliveryEquipmentUsage[]>;

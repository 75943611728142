import { HttpResponse } from "msw";
import { FileManifest } from "../hooks/react-query/outbound/linehaulTrailers/useTrailerHubPdf";

const mockReport = {
  default: {
    content: "contents",
    contentType: "application/pdf",
    fileName: "fileName",
  },
} as const satisfies Record<string, FileManifest>;

export const getMockReport = () =>
  HttpResponse.json(mockReport.default, {
    headers: {
      "Content-Type": "applcation/pdf",
    },
  });

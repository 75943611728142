import {
  Box,
  Typography,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";

export function TextField({ label, placeholder, ...rest }: TextFieldProps) {
  return (
    <Box>
      <Typography fontSize={14} fontWeight={600} mb={1}>
        {label}
      </Typography>
      <MuiTextField
        {...rest}
        label={placeholder}
        variant="outlined"
        size="small"
      />
    </Box>
  );
}

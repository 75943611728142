import { Box } from "@mui/system";
import pluralize from "pluralize";
import { SummaryContext } from "../../pages/inbound/shared/ActionsMenuContext";

type UniqueShipmentIdCountMessageProps = {
  shipments: SummaryContext[];
};

export default function UniqueShipmentIdCountMessage({
  shipments,
}: UniqueShipmentIdCountMessageProps) {
  const uniqueShipmentIds = new Set(
    shipments.flatMap((shipment) => shipment.shipmentIds)
  );

  return (
    <Box mb={2}>
      This will affect {pluralize("shipment", uniqueShipmentIds.size, true)}
    </Box>
  );
}

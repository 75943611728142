import {
  Box,
  Tooltip as MUITooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import { zIndexes } from "../../utils/zIndexes";

type TooltipLocation =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start";

type TooltipComponentProps = {
  content: ReactNode | string;
  location?: TooltipLocation;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.dark,
    width: "max-content",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.dark,
  },
}));

export const Tooltip = ({
  content,
  location = "bottom",
  children,
}: PropsWithChildren & TooltipComponentProps) => (
  <StyledTooltip
    title={content}
    placement={location}
    arrow
    slotProps={{
      popper: {
        sx: {
          zIndex: zIndexes.tooltip,
        },
      },
    }}
  >
    <Box sx={{ display: "inline-flex" }} overflow="hidden">
      {children}
    </Box>
  </StyledTooltip>
);

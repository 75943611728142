import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../../utils/apiUrls";
import { PlanType } from "../../../types/planning/plan.type";
import { http } from "../../../utils/httpCommon";
import { shipmentsSchema } from "../../../services/prePlanningService.types";

type UseShipmentsByRouteOrTrapArgs = {
  trailerId: number;
  type: PlanType;
  enabled?: boolean;
};

const keys = {
  shipmentsByRouteOrTrap: (trailerId: number, type: PlanType) =>
    ["planning", trailerId, type, "trailer-shipments"] as const,
};

export function useShipmentsByRouteOrTrap({
  trailerId,
  type,
  enabled = true,
}: UseShipmentsByRouteOrTrapArgs) {
  return useQuery({
    queryKey: keys.shipmentsByRouteOrTrap(trailerId, type),
    queryFn: async () => {
      const url =
        type === "delivery_route"
          ? apiUrls.getShipmentsByRoute(trailerId)
          : apiUrls.getShipmentsByTrap(trailerId);

      const { data } = await http.get(url, { params: { trailerId } });
      return shipmentsSchema.parse(data);
    },
    enabled,
  });
}

import { AutoAwesome } from "@mui/icons-material";
import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import Loading from "../../../../components/shared/layout/Loading";
import { useAutoSequenceRoutes } from "../../../../hooks/react-query/useAutoSequenceRoute";
import { queryClient } from "../../../../queryClientProvider";
import { plannedRoutesQueryKey } from "../../../../hooks/react-query/usePlannedRoutes";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { useSearchTermParam } from "../../../../hooks/useSearchTermParam";

type AutoSequenceMenuItem = {
  routesOrTraps: PlanTableFormat[];
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
};

export const AutoSequenceMenuItem = ({
  routesOrTraps,
  handleClose,
}: AutoSequenceMenuItem) => {
  const [searchTerm] = useSearchTermParam();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const routes = routesOrTraps.filter(
    (record) => record.type === "delivery_route" && Boolean(!record.isCommitted)
  );

  const { mutateAsync: autoSequenceRoutes, isPending: isAutoSequenceRunning } =
    useAutoSequenceRoutes();

  const handleAutoSequenceRoutesClick = () => {
    if (routes.length === 0) {
      toast(toastMessage.inbound.autoSequencing.selectAtLeastOneRoute, {
        variant: "warning",
      });
    } else {
      queryClient.setQueryData(
        plannedRoutesQueryKey(planDate, serviceCenter.id, searchTerm),
        (prev: PlanTableFormat[]) =>
          prev.map((route) => {
            const routeStatus = routes.find((r) => route.id === r.id);
            if (!routeStatus) return route;

            return {
              ...route,
              sequenceStatus: "processing",
            };
          })
      );
      autoSequenceRoutes(routes.map((route) => route.id));
      handleClose?.();
    }
  };

  return (
    <MenuItem onClick={handleAutoSequenceRoutesClick}>
      <ListItemIcon>
        {isAutoSequenceRunning ? (
          <Loading size={16} containerWidth={16} />
        ) : (
          <AutoAwesome />
        )}
      </ListItemIcon>
      <Button sx={labelButtonStyle} disableRipple>
        {isAutoSequenceRunning ? "Auto-Sequencing..." : "Auto-Sequence"}
      </Button>
    </MenuItem>
  );
};

import { Filter } from "../../../types/filter";

import { dateToFormat } from "../../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../../DaylightDateFormat";
import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  sortByDate,
  createNoDateOption,
  sortNoDateFirst,
} from "../utils";

export type ServiceDueDateQueryParam = "serviceDueDate";
type SomethingWithServiceDue = Record<
  ServiceDueDateQueryParam,
  Date | null | undefined
>;

export const getServiceDueFilter = (
  data: SomethingWithServiceDue[],
  prefix?: Prefix
): Filter => {
  const options = getNonEmptyUniqueSortedOptions(
    data.map((d) =>
      createNoDateOption(
        dateToFormat(d.serviceDueDate, DaylightDateFormat.DATE)
      )
    ),
    sortByDate
  );

  const sortedOptions = sortNoDateFirst(options);

  return {
    label: "Service Due Date",
    queryParam: handlePrefix("serviceDueDate", prefix),
    options: sortedOptions,
  };
};

export const filterServiceDue = <T extends SomethingWithServiceDue>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ serviceDueDate }) => {
        if (filters.includes("No Date")) {
          if (serviceDueDate === null || serviceDueDate === undefined) {
            return true;
          }
        }

        const formattedServiceDue = dateToFormat(
          serviceDueDate,
          DaylightDateFormat.DATE
        );

        return filters.includes(formattedServiceDue);
      })
    : data;

import { useQuery } from "@tanstack/react-query";
import { http } from "../../../utils/httpCommon";
import {
  Section,
  sectionsSchema,
} from "../../../services/prePlanningService.types";
import { apiUrls } from "../../../utils/apiUrls";

const sectionsQueryKey = (sicId: number) => ["pre-planning", sicId, "sections"];

type GetSectionsResponse = Omit<Section, "shipmentIds"> &
  {
    shipmentsIds: number[];
  }[];

const getSections = async (destinationSicId: number): Promise<Section[]> => {
  const url = apiUrls.getSections(destinationSicId);
  const { status, data } = await http.get<GetSectionsResponse>(url);

  if (status === 204) return [];

  return sectionsSchema.parse(data);
};

export function useSections(destinationSicId: number) {
  return useQuery({
    queryKey: sectionsQueryKey(destinationSicId),
    queryFn: () => getSections(destinationSicId),
  });
}

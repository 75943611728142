import { Filter } from "../../../types/filter";

import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
} from "../utils";

export type ConsigneeCityQueryParam = "consigneeCity";
export type SomethingWithConsigneeCity = {
  consignee?: {
    city?: string;
  };
};

export const getConsigneeCityFilter = (
  data: SomethingWithConsigneeCity[],
  prefix?: Prefix
): Filter => ({
  label: "Consignee City",
  queryParam: handlePrefix("consigneeCity", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.consignee?.city))
  ),
});

export const filterConsigneeCity = <T extends SomethingWithConsigneeCity>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ consignee }) => filters.includes(consignee?.city || ""))
    : data;

import { CarriersResponse } from "../../hooks/react-query/outbound/freightDetails/useGetCarriers";

export const carriersList = {
  empty: [],
  default: [
    {
      id: 4008,
      name: "ABF",
      scac: "ABFS",
    },
    {
      id: 15963,
      name: "ABERDEEN EXPRESS, INC",
      scac: "ABNE",
    },
    {
      id: 4169,
      name: "ACCURATE CARGO DELIVERY SYSTEMS INC",
      scac: "ACDO",
    },
    {
      id: 108,
      name: "ALL FREIGHT TRUCKING, INC. ",
      scac: "AKFI",
    },
    {
      id: 4681,
      name: "ALOHA FREIGHT FORWARDERS, INC",
      scac: "ALHF",
    },
    {
      id: 150,
      name: "ALMARAZ FREIGHTLINE",
      scac: "ALMA",
    },
    {
      id: 105,
      name: "A-TECH WAREHOUSING & DIST INC",
      scac: "APKI",
    },
    {
      id: 9697,
      name: "ARTUR EXPRESS INC",
      scac: "ATUR",
    },
    {
      id: 9254,
      name: "AVERITT EXPRESS, INC",
      scac: "AVRT",
    },
    {
      id: 4609,
      name: "BLUE GRACE",
      scac: "BGLF",
    },
    {
      id: 8842,
      name: "BULLET TRANSPORTATION SERVICES",
      scac: "BULL",
    },
  ],
} as const satisfies Record<string, CarriersResponse>;

import axios from "axios";
import { z } from "zod";

export const UNKNOWN_ERROR = "Unknown error.";

const errorResponseSchema = z.object({
  response: z.object({
    data: z.object({
      response: z.object({
        errorMessage: z.string().optional(),
        errors: z
          .array(
            z.object({
              propertyName: z.string(),
              errorMessage: z.string(),
            })
          )
          .optional(),
      }),
    }),
  }),
});

export function errorHandler(error: unknown) {
  const parsedError = errorResponseSchema.safeParse(error);

  if (parsedError.success) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      if (typeof status === "number" && status > 500) {
        return UNKNOWN_ERROR;
      }
    }

    if (parsedError.data.response.data.response.errorMessage) {
      return parsedError.data.response.data.response.errorMessage;
    }

    if (parsedError.data.response.data.response.errors) {
      return parsedError.data.response.data.response.errors
        .map((err) => `${err.propertyName}: ${err.errorMessage}`)
        .join(", ");
    }
  }

  return UNKNOWN_ERROR;
}

import Paper from "@mui/material/Paper";
import RemoveIcon from "@mui/icons-material/Remove";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import {
  CellContext,
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChangeEvent,
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { SxProps, TablePagination } from "@mui/material";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import FeedbackIndicator from "../FeedbackIndicator";
import Loading from "../layout/Loading";
import { Checkbox } from "../Checkbox";
import { FlexColumn } from "../layout/Flex";
import { pageSizes } from "../../../constants/table-page-size";
import { useClearSelectionWhenServiceCenterChanges } from "../../../hooks/useClearSelectionWhenServiceCenterChanges";
import TableCaption from "../TableCaption";
import { Button } from "../Button";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { ErrorMap } from "../table/table.types";
import ExpanderCell from "./ExpanderCell";
import BaseTableHeader from "./BaseTableHeader";
import { RowSelectionMode, SelectableProps } from "./Selectable.types";
import {
  RowWithIdAndShipmentIds,
  SelectedRowIdMap,
  SelectedTableRows,
  useTableSelectionContext,
} from "./TableSelectionContext";
import BaseTableBody from "./BaseTableBody";

type CommonProps<TData> = {
  ref?: Ref<TableBaseRef>;
  data?: TData[];
  columns: ColumnDef<TData, any>[];
  caption: string;
  isLoading?: boolean;
  expandAllRows?: boolean;
  rowErrors?: ErrorMap;
  styles?: SxProps;
};

type CollapsibleProps<TData> =
  | {
      isCollapsible?: false;
      collapsibleChild?: never;
      onCollapse?: never;
    }
  | {
      isCollapsible: true;
      collapsibleChild: (row: Row<TData>) => ReactNode;
      onCollapse?: (expanded: boolean, data: TData) => void;
    };

type DroppableProps<TData> = {
  droppableId?: (row: Row<TData>) => string;
};

type ContextMenuProps<TData> = {
  contextMenuActions?: (row?: SelectedTableRows<TData>) => ContextMenuAction[];
};

type SortableProps = {
  defaultSort?: SortingState;
};

type Props<TData> = CommonProps<TData> &
  CollapsibleProps<TData> &
  ContextMenuProps<TData> &
  DroppableProps<TData> &
  SelectableProps<TData> &
  SortableProps & { onContainerScroll?: (value: number) => void };

export type TableBaseRef = {
  clearSelection: () => void;
  setContainerScrollTop: (value: number) => void;
  collapseAll: () => void;
};

/** Abstracts all table business logic. */
const BaseTable = <TData,>(
  {
    /** table data */
    data,
    /** columns array */
    columns,
    /** Table caption for a11y and testing purposes. The caption is visually hidden. */
    caption,
    /** custom styles for Table Container */
    styles,
    /** Change this bool to expand or collapse all rows at any time. */
    expandAllRows = false,
    /** child component to render after an expand */
    collapsibleChild,
    /** id of the droppable area */
    droppableId,
    /** if true, allow expanding rows */
    isCollapsible = false,
    /** define the mode of selection */
    rowSelectionMode = "unselectable",
    /** display a loading indicator instead of the table when true */
    isLoading = false,
    /** function that is called after a collapse */
    onCollapse,
    /** context menu actions */
    contextMenuActions,
    /** called after a selection, returns SelectedTableRows<TData> */
    onRowSelection,
    /** function to get the row id */
    getRowId,
    /** default sort array */
    defaultSort = [],
    /** function to get the scroll position of TableContainer */
    onContainerScroll,
    /** Rows with errors. */
    rowErrors,
  }: Props<TData>,
  ref?: Ref<TableBaseRef>
) => {
  const {
    selectedRows,
    handleSelect,
    clearSelection: clearContextSelection,
    deselectAllParentShipments,
    someShipmentsSelected,
  } = useTableSelectionContext<TData>();
  const [sortBy, setSortBy] = useState<SortingState>(defaultSort);
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = useRef(selectedDate);
  const containerRef = useRef<HTMLDivElement>(null);

  const ExpanderCellHelper = useCallback(
    ({ row }: CellContext<TData, any>) => (
      <ExpanderCell
        row={row}
        onCollapse={(expanded, data) => onCollapse?.(expanded, data)}
      />
    ),
    [onCollapse]
  );

  const assembleColumns = useCallback(
    (
      columns: ColumnDef<TData, any>[],
      rowSelectionMode: RowSelectionMode = "unselectable",
      isCollapsible = false
    ) => {
      let newColumns: ColumnDef<TData, any>[] = [...columns];

      if (isCollapsible) {
        newColumns = [
          {
            id: "expander",
            // eslint-disable-next-line react/no-unstable-nested-components
            header: ({ table }) => {
              const { toggleAllRowsExpanded } = table;

              return (
                <Button
                  startIcon={<RemoveIcon fontSize="large" />}
                  onClick={() => toggleAllRowsExpanded(false)}
                />
              );
            },
            cell: ExpanderCellHelper,
          },
          ...newColumns,
        ];
      }
      if (
        rowSelectionMode === "checkbox" ||
        rowSelectionMode === "checkbox-and-row-click"
      ) {
        newColumns = [
          {
            id: "select",
            header: ({ table }) =>
              (() => {
                const {
                  getIsAllRowsSelected,
                  getIsSomeRowsSelected,
                  getToggleAllRowsSelectedHandler,
                } = table;

                return Checkbox({
                  name: "selectAllRows",
                  ariaLabel: "Select all rows",
                  checked: getIsAllRowsSelected(),
                  disabled: false,
                  indeterminate: getIsSomeRowsSelected(),
                  onChange: getToggleAllRowsSelectedHandler(),
                });
              })(),
            cell: ({ row }) =>
              (() => {
                const { getIsSelected, getCanSelect } = row;

                return Checkbox({
                  name: "selectRow",
                  ariaLabel: `Trailer ${row.id}`,
                  checked: getIsSelected(),
                  disabled: !getCanSelect(),
                  indeterminate:
                    !getIsSelected() && someShipmentsSelected(row.id),
                  onChange: (_, checked) => {
                    if (!checked) {
                      deselectAllParentShipments(row.id);
                    }
                    row.toggleSelected(checked);
                  },
                });
              })(),
          },
          ...newColumns,
        ];
      }

      return newColumns;
    },
    [ExpanderCellHelper, someShipmentsSelected, deselectAllParentShipments]
  );

  const rowSelection: SelectedRowIdMap = {};
  for (const row of selectedRows.rows) {
    if (row.selected) {
      rowSelection[row.rowId] = true;
    }
  }

  const table = useReactTable<TData>({
    data: data ?? [],
    columns: useMemo(
      () => assembleColumns(columns, rowSelectionMode, isCollapsible),
      [assembleColumns, columns, rowSelectionMode, isCollapsible]
    ),
    getRowCanExpand: () => isCollapsible,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: sortBy,
      rowSelection,
    },
    onSortingChange: setSortBy,
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: rowSelectionMode !== "unselectable",
    enableMultiRowSelection: rowSelectionMode !== "unselectable",
    onRowSelectionChange: (updater) => {
      // This updater prop can be a value or a function, in this case we will use it as a function to return the new set of selected rows based on the old state.
      // Known troubleshoot https://github.com/TanStack/table/issues/4364#issuecomment-1240257555
      if (typeof updater === "function") {
        handleRowSelection(updater(rowSelection));
      }
    },
    getRowId,
  });

  const handleRowSelection = useCallback(
    (selection: RowSelectionState) => {
      const selectedRows: RowWithIdAndShipmentIds<TData>[] = [];
      Object.keys(selection).forEach((rowId) => {
        const selectedRow = table
          .getRowModel()
          .rows.find((row) => row.id === rowId)?.original;
        if (selectedRow) {
          selectedRows.push({ ...selectedRow, rowId, selected: true });
        }
      });
      handleSelect({
        selectedRows,
        type: "trip",
        filter: true,
      });
    },
    [handleSelect, table]
  );

  const handleChangePage = (_: unknown, newPage: number) =>
    newPage > table.getState().pagination.pageIndex
      ? table.nextPage()
      : table.previousPage();

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    table.setPageSize(Number(event.target.value));
    table.setPageIndex(0);
  };

  useClearSelectionWhenServiceCenterChanges(clearContextSelection);

  useEffect(
    function clearSelectionWhenPlanDateChanges() {
      if (selectedDate === planDate.current) return;
      planDate.current = selectedDate;
      clearContextSelection();
    },
    [clearContextSelection, selectedDate]
  );

  useImperativeHandle(ref, () => ({
    collapseAll() {
      table.toggleAllRowsExpanded(false);
    },
    clearSelection: clearContextSelection,
    setContainerScrollTop(value) {
      containerRef.current?.scrollTo({
        top: value,
      });
    },
  }));

  useEffect(
    function notifyParentOnSelectionChange() {
      onRowSelection && onRowSelection(selectedRows);
    },
    [selectedRows, onRowSelection]
  );

  useEffect(
    function defaultPaginationToAllRows() {
      if (data && data.length > 0) table.setPageSize(data.length);
    },
    [data, table]
  );

  useEffect(() => {
    table.toggleAllRowsExpanded(expandAllRows);
  }, [expandAllRows, table]);

  if (isLoading) {
    return <Loading label={caption} />;
  }

  if (data && data.length === 0) {
    return (
      <>
        <TableContainer sx={styles} component={Paper}>
          <Table stickyHeader size="small">
            <TableCaption>{caption}</TableCaption>
            <BaseTableHeader table={table} />
          </Table>
        </TableContainer>
        <FeedbackIndicator />
      </>
    );
  }

  return (
    <FlexColumn>
      <TableContainer
        ref={containerRef}
        component={Paper}
        sx={{ ...styles, flexGrow: 1 }}
        onScroll={(e) => {
          if (e.target instanceof HTMLDivElement) {
            onContainerScroll?.(e.target.scrollTop);
          }
        }}
      >
        <Table stickyHeader size="small">
          <TableCaption>{caption}</TableCaption>
          <BaseTableHeader table={table} />
          <BaseTableBody
            table={table}
            tableType="trip"
            rowSelectionMode={rowSelectionMode}
            collapsibleChild={collapsibleChild}
            droppableId={droppableId}
            contextMenuActions={contextMenuActions}
            rowErrors={rowErrors}
          />
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ width: "100%", overflow: "visible" }}
        rowsPerPageOptions={[
          ...pageSizes,
          { value: data?.length ?? 0, label: "All" },
        ]}
        component="div"
        count={data?.length ?? 0}
        rowsPerPage={table.getState().pagination.pageSize}
        page={table.getState().pagination.pageIndex}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </FlexColumn>
  );
};

BaseTable.displayName = "BaseTable";

// eslint-disable-next-line no-type-assertion/no-type-assertion
export default forwardRef(BaseTable) as <TData>(
  props: Props<TData>,
  ref: Ref<TableBaseRef>
) => React.JSX.Element;

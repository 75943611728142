import { LoadTrailerShipments } from "../../hooks/react-query/outbound/linehaulTrailers/useLoadTrailerShipments";

export const loadTrailerShipmentsResponse = {
  default: {
    summary: {
      tripNumber: 3100369,
      trailerNumber: "DALGHOST04",
      status: "PLAN",
      bills: 0,
      pieces: 0,
      pallets: 0,
      weight: 0,
    },
    shipments: [
      {
        shipmentId: 132449296,
        proNumber: "110950987",
        destinationCity: "RIDGEWOOD",
        destinationZipCode: "11385",
        serviceDue: new Date("2024-09-03T00:00:00"),
        appointmentDate: null,
        appointmentTime: "",
        consignee: "ENGELBERTS MOVING",
        consigneeAddress: "",
        shipper: "AMAZING CUSTOM UPHOLSTERY",
        pieces: 0,
        pallets: 1,
        weight: 195,
        shipmentTags: [],
        status: "ENRT",
        destinationSic: "EWR",
        etaDate: new Date("2024-09-03"),
        etaTime: "07:50",
        makeService: 3,
        assignment: "",
        isShipmentOnCurrentSic: false,
      },
      {
        shipmentId: 132449472,
        proNumber: "110951084",
        destinationCity: "NEW YORK",
        destinationZipCode: "11208",
        serviceDue: new Date("2024-09-03T00:00:00"),
        appointmentDate: null,
        appointmentTime: "",
        consignee: "ALFREDO VEGA",
        consigneeAddress: "",
        shipper: "CASH PLUS PAWN",
        pieces: 0,
        pallets: 1,
        weight: 250,
        shipmentTags: [
          {
            id: 6,
            name: "LG",
            color: "#679CB2",
            description: "Liftgate",
          },
          {
            id: 74,
            name: "RESI",
            color: "#6B8DEB",
            description: "Residential",
          },
          {
            id: 4,
            name: "APTR",
            color: "#777DE9",
            description: "Appointment Required ",
          },
        ],
        status: "ENRT",
        destinationSic: "EWR",
        etaDate: new Date("2024-09-02"),
        etaTime: "09:48",
        makeService: 3,
        assignment: "",
        isShipmentOnCurrentSic: false,
      },
    ],
  },
} as const satisfies Record<string, LoadTrailerShipments>;

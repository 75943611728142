import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosResponse } from "axios";
import { sessionStorageKeys } from "../hooks/useSelectedServiceCenter";
import { env } from "./env";

function createInstance(baseUrl: string) {
  const baseConfig = { baseURL: baseUrl };
  const instance = axios.create(baseConfig);

  instance.interceptors.request.use(async (request) => {
    // add auth header with jwt if account is logged in and request is to the api url
    const session = await fetchAuthSession();

    request.headers.Authorization = `Bearer ${session.tokens?.accessToken.toString()}`;
    request.headers["Content-type"] = "application/json";

    const serviceCenter = JSON.parse(
      sessionStorage.getItem(sessionStorageKeys.currentTabServiceCenter) ?? "{}"
    );
    request.headers["X-Service-Center-ID"] = serviceCenter.id;
    request.headers["TimeZone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    return request;
  });

  instance.interceptors.response.use((response: AxiosResponse) => {
    handleDates(response.data);
    return response;
  });

  return instance;
}

const isoDateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const dateOnlyFormat =
  /^[1-9][0-9][0-9]{2}-([0][1-9]|[1][0-2])-([1-2][0-9]|[0][1-9]|[3][0-1])/gm;

const isIsoDateString = (value: string): boolean => isoDateFormat.test(value);

const isDateOnlyString = (value: string): boolean => dateOnlyFormat.test(value);

/*
  Dates in the format yyyy-MM-dd needs to change to yyyy/MM/dd. It's how js works with dates:
  https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off/31732581#31732581
*/
const fixDateOnlyString = (value: string) => {
  if (!isIsoDateString(value)) value = value.replace(/-/g, "/");
  return value;
};

const handleDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== "object") {
    return body;
  }

  for (const key of Object.keys(body)) {
    let value = body[key];
    if (typeof value === "string") {
      if (isIsoDateString(value) || isDateOnlyString(value)) {
        value = fixDateOnlyString(value);
        body[key] = new Date(value);
      }
    } else if (typeof value === "object") handleDates(value);
  }
};

export const http = createInstance(env.VITE_API_URL);

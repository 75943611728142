import dayjs from "dayjs";
import { UcccNotification } from "../../hooks/react-query/linehaul/useUcccNotifications";

export const notifications: UcccNotification[] = [
  {
    id: 1,
    proNumber: "9848347",
    date: dayjs(),
    message: "has not yet been assigned to a trip.",
    sicCode: "EWR",
  },
  {
    id: 2,
    proNumber: "9848348",
    date: dayjs(),
    message: "has not yet been assigned to a trip.",
    sicCode: "HTX",
  },
  {
    id: 3,
    proNumber: "9848349",
    date: dayjs(),
    message: "has not yet been assigned to a trip.",
    sicCode: "ONTR",
  },
];

import { URLSearchParams } from "url";
import { capitalize } from "@mui/material";
import { Filter } from "../types/filter";

/**
 * Returns a list of active filters based on the provided URL search parameters.
 *
 * @param {Filter[]} [filters=[]] - An array of filter objects to be matched against the search parameters.
 * @param {URLSearchParams} searchParams - The URLSearchParams object containing the search parameters.
 * @param {string} [prefix=""] - An optional prefix to prepend to each filter's query parameter when matching.
 * @returns {Filter[]} An array of filters that are active based on the search parameters.
 */
export const getActiveFilters = (
  filters: Filter[] = [],
  searchParams: URLSearchParams,
  prefix = ""
): Filter[] => {
  const activeFilters: Filter[] = [];
  const filterMap = new Map<string, Filter>();

  filters.forEach((filter) => {
    const queryParam = prefix
      ? prefix + capitalize(filter.queryParam)
      : filter.queryParam;
    filterMap.set(queryParam, filter);
  });

  Array.from(searchParams.keys()).forEach((searchParam) => {
    const activeFilter = filterMap.get(searchParam);
    if (activeFilter && !activeFilters.includes(activeFilter)) {
      activeFilters.push(activeFilter);
    }
  });

  return activeFilters;
};

type ActiveAndInactiveFilters = {
  inactiveFilters: Filter[];
  activeFilters: Filter[];
};

/**
 * Returns an object containing lists of active and inactive filters based on the provided URL search parameters.
 *
 * @param {Filter[]} [filters=[]] - An array of filter objects to be matched against the search parameters.
 * @param {URLSearchParams} searchParams - The URLSearchParams object containing the search parameters.
 * @param {string} [prefix=""] - An optional prefix to prepend to each filter's query parameter when matching.
 * @returns {ActiveAndInactiveFilters} An object containing arrays of active and inactive filters.
 */
export const getActiveAndInactiveFilters = (
  filters: Filter[] = [],
  searchParams: URLSearchParams,
  prefix = ""
): ActiveAndInactiveFilters => {
  const activeFilters: Filter[] = getActiveFilters(
    filters,
    searchParams,
    prefix
  );

  Array.from(searchParams.keys()).forEach((searchParam) => {
    const activeFilter = filters.find(
      (filter) =>
        (prefix
          ? prefix + capitalize(filter.queryParam)
          : filter.queryParam) === searchParam
    );

    if (
      activeFilter &&
      !activeFilters.some((f) => f.queryParam === activeFilter.queryParam)
    ) {
      activeFilters.push(activeFilter);
    }
  });

  const inactiveFilters = filters.filter(
    (filter) => !activeFilters.includes(filter)
  );

  return {
    inactiveFilters,
    activeFilters,
  };
};

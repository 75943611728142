import { Button, ButtonProps, CircularProgress, Stack } from "@mui/material";

type LoadingButtonProps = Omit<ButtonProps, "disabled"> & {
  /** Setting this to true does the following:
   * - Display a loading spinner
   * - Set aria-disabled to true
   * - Ignore clicks */
  isLoading: boolean;
  /** Deliberately omitted for accessibility and simplicity reasons. Using aria-disabled instead, and clicks are ignored when isLoading is true. */
  disabled?: never;
  /** Label to display instead of children when isLoading is true. By convention, we end this message with "ing..." to signify that we're waiting. For example, if the children prop is "Save", set this to "Saving..." */
  loadingLabel: string;
  /** Automatically true when `isLoading` is true. Setting this to true does the following:
   * - Display the button a gray color
   * - Set aria-disabled to true
   * - Ignore clicks */
  isDisabled?: boolean;
};

export const LoadingButton = ({
  isDisabled,
  isLoading,
  loadingLabel,
  type = "submit",
  color = "primary",
  variant = "contained",
  ...buttonProps
}: LoadingButtonProps) => (
  <Button
    type={type}
    color={color}
    variant={variant}
    {...buttonProps}
    aria-disabled={isLoading || isDisabled}
    onClick={(e) => {
      if (isLoading) return;
      buttonProps.onClick?.(e);
    }}
  >
    {isLoading ? (
      <Stack direction="row" gap={1} alignItems="center">
        <CircularProgress color="inherit" size={16} />
        {loadingLabel}
      </Stack>
    ) : (
      buttonProps.children
    )}
  </Button>
);

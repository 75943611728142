import { MouseEvent, RefObject, memo } from "react";
import { TableRow as MuiTableRow, SxProps, useTheme } from "@mui/material";
import { Row, RowSelectionState, Table } from "@tanstack/react-table";
import { DroppableProvided, DroppableStateSnapshot } from "@hello-pangea/dnd";
import { ContextMenuRef } from "../ContextMenuDropdown";
import { getDroppableStyleRow } from "../../../utils/dragging";
import TableCell from "./TableCell";
import { RowSelectionMode } from "./Selectable.types";

type Props<TData> = {
  table: Table<TData>;
  row: Row<TData>;
  isSelected: boolean;
  contextMenuRef: RefObject<ContextMenuRef<TData>>;
  selectionCount: number;
  rowSelectionMode?: RowSelectionMode;
  droppableProvided?: DroppableProvided;
  snapshot?: DroppableStateSnapshot;
};

const TableRow = <TData,>({
  table,
  row,
  isSelected,
  contextMenuRef,
  rowSelectionMode = "unselectable",
  droppableProvided,
  snapshot,
}: Props<TData>) => {
  const theme = useTheme();

  const handleRowSelection = (
    event: MouseEvent<HTMLTableRowElement | HTMLInputElement>
  ) => {
    if (rowSelectionMode === "unselectable") return;
    // Do nothing if the selection is through the checkboxes
    const target = event.target;
    if ("type" in target && target.type === "checkbox") {
      return;
    } else if (
      rowSelectionMode === "row-click" ||
      rowSelectionMode === "checkbox-and-row-click"
    ) {
      if (event.ctrlKey || event.metaKey) {
        // User clicked the row, with ctrl or cmd pressed will add the row to the selection
        row.toggleSelected();
      } else if (table.getIsSomeRowsSelected() && event.shiftKey) {
        // User clicked the row, with shift pressed will add the rows on the interval to the selection
        // Ex: check row 1, press shift and check row 4, will select from 1 to 4.
        const rows = table.getRowModel().rows;
        const firstSelectedRow = table.getSelectedRowModel().rows[0];
        if (typeof firstSelectedRow === "undefined") {
          throw new Error(
            "Error while selecting a row in a table without rows."
          );
        }
        const selectedRowIdMap: RowSelectionState = {};
        // From top to bottom
        if (firstSelectedRow.index < row.index) {
          rows.slice(firstSelectedRow.index, row.index + 1).forEach((row) => {
            selectedRowIdMap[row.id] = true;
          });
        }
        // From bottom to top
        else {
          rows.slice(row.index, firstSelectedRow.index + 1).forEach((row) => {
            selectedRowIdMap[row.id] = true;
          });
        }
        table.setRowSelection(selectedRowIdMap);
      } else {
        // User clicked the row, but didn't click shift, so select the single row
        const selectedRowIdMap: RowSelectionState = {};
        selectedRowIdMap[row.id] = true;
        table.setRowSelection(selectedRowIdMap);
      }
    }
  };

  const selectRowOnContext = async () => {
    const selectedRowIdMap: RowSelectionState = {};
    selectedRowIdMap[row.id] = true;
    await table.setRowSelection(selectedRowIdMap);
  };

  const styles: SxProps = {
    height: "51px",
    backgroundColor: isSelected
      ? `${theme.palette.action.selected} !important`
      : "unset",
    ...getDroppableStyleRow(snapshot?.isDraggingOver),
    userSelect: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  };

  return droppableProvided ? (
    <MuiTableRow
      ref={droppableProvided.innerRef}
      {...droppableProvided.droppableProps}
      sx={styles}
      onClick={handleRowSelection}
      onContextMenu={async (event) => {
        if (!row.getIsSelected()) {
          await selectRowOnContext();
        }
        contextMenuRef.current?.openContextMenu(event, row.original);
      }}
    >
      {row.getVisibleCells().map((cell) => (
        <TableCell key={cell.id} cell={cell} />
      ))}
    </MuiTableRow>
  ) : (
    <MuiTableRow
      sx={styles}
      onClick={handleRowSelection}
      onContextMenu={async (event) => {
        if (!row.getIsSelected()) {
          await selectRowOnContext();
        }
        contextMenuRef.current?.openContextMenu(event, row.original);
      }}
    >
      {row.getVisibleCells().map((cell) => (
        <TableCell key={cell.id} cell={cell} />
      ))}
    </MuiTableRow>
  );
};

export default memo(TableRow) as <TData>(
  props: Props<TData>
) => React.JSX.Element;

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Typography,
  Box,
  Theme,
  SxProps,
  ListSubheader,
  FormHelperText,
} from "@mui/material";
import { DropdownOption } from "../../../types/dropdownOption.type";

interface CreateRouteProps {
  enable: boolean;
  defaultText: string;
  allSelectOptions: DropdownOption[];
  relevantSelectOptions?: DropdownOption[];
  onChange: (event: SelectChangeEvent) => void;
  value?: string;
  title?: string;
  required?: boolean;
  error?: boolean;
  sx?: SxProps<Theme>;
  helperText?: string;
}

const CreateRouteSelect = ({
  enable,
  defaultText,
  relevantSelectOptions,
  allSelectOptions,
  onChange,
  value,
  title,
  required,
  error,
  sx,
  helperText,
}: CreateRouteProps) => (
  <Box sx={sx}>
    {title && (
      <Typography fontSize={14} fontWeight={600} mb={1}>
        {title}
      </Typography>
    )}

    <FormControl
      sx={{ width: "100%" }}
      size="small"
      disabled={!enable}
      required={required}
    >
      <InputLabel id={`select-${defaultText}-label`}>{defaultText}</InputLabel>
      <Select
        labelId={`select-${defaultText}-label`}
        label={enable ? defaultText : "Select Preset Route"}
        title={title}
        value={value}
        onChange={onChange}
        size="small"
        disabled={!enable}
        required={required}
        error={error}
      >
        <MenuItem disabled={required} value={undefined}>
          <em>None</em>
        </MenuItem>
        {relevantSelectOptions && relevantSelectOptions.length > 0
          ? [
              <ListSubheader key="fromSelected">From selected</ListSubheader>,
              relevantSelectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              )),
              <ListSubheader key="allPresetRoutes">
                All Preset Routes
              </ListSubheader>,
              allSelectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              )),
            ]
          : allSelectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
      </Select>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  </Box>
);

export default CreateRouteSelect;

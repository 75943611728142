import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { FlexRow } from "../../../components/shared/layout/Flex";
import { StatusTagChip } from "../../../components/shared/StatusTag/StatusTagChip";

interface ReadOnlyStatusTagProps {
  /** Status displayed in the tag. */
  status: string;
  isPlanned?: boolean;
}

export const ReadOnlyStatusTag = ({
  status,
  isPlanned = true,
}: ReadOnlyStatusTagProps) => {
  const handleOnClick = () => {
    toast(toastMessage.inbound.editNotAllowedForPastDates, {
      variant: "warning",
    });
  };

  return (
    <FlexRow style={{ alignItems: "center", gap: "6px" }}>
      <StatusTagChip
        isPlanned={isPlanned}
        status={status}
        onClick={handleOnClick}
      />
    </FlexRow>
  );
};

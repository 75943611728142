import { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import { DaylightDateFormat } from "../constants/DaylightDateFormat";
import { getTodayAsISODate } from "../utils/dateTimeHelper";

export const SELECTED_DATE_PARAM_KEY = "date";

export function useDateSearchParamOrFallbackToToday(): [
  string,
  (date: Dayjs | string) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryDate = searchParams.get(SELECTED_DATE_PARAM_KEY);
  const date = queryDate ?? getTodayAsISODate();

  const setDate = (date: Dayjs | string) => {
    searchParams.set(
      SELECTED_DATE_PARAM_KEY,
      typeof date === "string" ? date : date.format(DaylightDateFormat.ISO_DATE)
    );
    setSearchParams(searchParams);
  };

  return [date, setDate];
}

import { Region } from "../../hooks/react-query/linehaul/useRegions";

export const mockRegions = {
  default: [
    {
      id: 1,
      name: "EAST",
      serviceCenters: [],
    },
    {
      id: 2,
      name: "WEST",
      serviceCenters: [],
    },
    {
      id: 3,
      name: "MIDWEST",
      serviceCenters: [
        {
          code: "ATM",
        },
        {
          code: "CHT",
        },
        {
          code: "IND-DAFG",
        },
        {
          code: "IND-PITD",
        },
        {
          code: "LRD",
        },
        {
          code: "MDW-CXIT",
        },
        {
          code: "MIS",
        },
        {
          code: "OSH",
        },
      ],
    },
    {
      id: 4,
      name: "NORTHEAST",
      serviceCenters: [
        {
          code: "BOS",
        },
        {
          code: "CLE-PITD",
        },
        {
          code: "EWR",
        },
        {
          code: "WSH-PITD",
        },
      ],
    },
    {
      id: 5,
      name: "LAX/SFO/VIS",
      serviceCenters: [
        {
          code: "LAX",
        },
        {
          code: "SFO",
        },
        {
          code: "VIS",
        },
      ],
    },
    {
      id: 6,
      name: "ONT",
      serviceCenters: [
        {
          code: "ONT",
        },
      ],
    },
    {
      id: 135,
      name: "PM MIDWEST",
      serviceCenters: [
        {
          code: "DAL",
        },
        {
          code: "HTX",
        },
      ],
    },
    {
      id: 0,
      name: "Unassigned",
      serviceCenters: [
        {
          code: "AFW",
        },
        {
          code: "ATB-SEFL",
        },
        {
          code: "ATL",
        },
        {
          code: "AUB",
        },
        {
          code: "BDL-RXIC",
        },
        {
          code: "BWI",
        },
        {
          code: "BWI-PDXE",
        },
        {
          code: "CHT-AVRT",
        },
        {
          code: "CLE-USCO",
        },
        {
          code: "COL-USCO",
        },
        {
          code: "CVG-ABNE",
        },
        {
          code: "CVG-USCO",
        },
        {
          code: "DAL-AGCE",
        },
        {
          code: "DEN-DLDS",
        },
        {
          code: "DFW",
        },
        {
          code: "DFW-AGCE",
        },
        {
          code: "DTW-DAFG",
        },
        {
          code: "HVN",
        },
        {
          code: "JAX",
        },
        {
          code: "LIT-DHRN",
        },
        {
          code: "LOU-DAFG",
        },
        {
          code: "MIA-AVRT",
        },
        {
          code: "NAS",
        },
        {
          code: "NAS-AVRT",
        },
        {
          code: "NJC-PYLE",
        },
        {
          code: "ORH-RXIC",
        },
        {
          code: "ORL",
        },
        {
          code: "PDZ-DLDS",
        },
        {
          code: "PER-DHRN",
        },
        {
          code: "PGH-PYLE",
        },
        {
          code: "PHX",
        },
        {
          code: "PHX-ACDO",
        },
        {
          code: "PHX-DDPP",
        },
        {
          code: "PME",
        },
        {
          code: "RIC-AVRT",
        },
        {
          code: "SAT",
        },
        {
          code: "SEA-DLDS",
        },
        {
          code: "SFA-AFKI",
        },
        {
          code: "SLC-DLDS",
        },
        {
          code: "SYR-PYLE",
        },
        {
          code: "TOR-SZTG",
        },
        {
          code: "TUL-AGCE",
        },
        {
          code: "WCH-PYLE",
        },
        {
          code: "YRK-PYLE",
        },
      ],
    },
  ],
} as const satisfies Record<string, Region[]>;

import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement={props.placement ?? "bottom"}
    arrow
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    border: "2px solid",
    borderColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.dark,
  },
}));

import { Tag } from "./freight/tag.type";

const shipmentTagMapping = {
  MOCK: {
    id: 1,
    name: "MOCK",
    description: "Mock tag",
    colorHex: "#CCCCCC",
    position: 121,
  },
  WC: {
    id: 1,
    name: "WC",
    description: "Will Call",
    colorHex: "#C0CA33",
    position: 120,
  },
  UC: {
    id: 2,
    name: "UC",
    description: "Urgent care shipment",
    colorHex: "#FBC02D",
    position: 20,
  },
  CC: {
    id: 3,
    name: "CC",
    description: "Critical care shipment",
    colorHex: "#EA5E5A",
    position: 10,
  },
  APTR: {
    id: 4,
    name: "APTR",
    description: "Appointment Required",
    colorHex: "#777DE9",
    position: 110,
  },
  APTM: {
    id: 5,
    name: "APTM",
    description: "Appointment Made",
    colorHex: "#69ADA8",
    position: 100,
  },
  LG: {
    id: 6,
    name: "LG",
    description: "Liftgate",
    colorHex: "#679CB2",
    position: 40,
  },
  OSAD: {
    id: 7,
    name: "OSAD",
    description: "Overage, Shortage or Damaged",
    colorHex: "#FF8F7A",
    position: 140,
  },
  LM: {
    id: 8,
    name: "LM",
    description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
    colorHex: "#E280B4",
    position: 50,
  },
  DTCL: {
    id: 9,
    name: "DTCL",
    description: "Delivery to Connecting Line",
    colorHex: "#5E9FE0",
    position: 170,
  },
  PFCL: {
    id: 41,
    name: "PFCL",
    description: "Pickup From Connecting Line",
    colorHex: "#7DD696",
    position: 180,
  },
  RESI: {
    id: 74,
    name: "RESI",
    description: "Residential",
    colorHex: "#6B8DEB",
    position: 60,
  },
  OVL: {
    id: 75,
    name: "OVL",
    description: "Overlength",
    colorHex: "#C77563",
    position: 70,
  },
  LACC: {
    id: 76,
    name: "LACC",
    description: "Limited Access",
    colorHex: "#DDB272",
    position: 80,
  },
  INDL: {
    id: 77,
    name: "INDL",
    description: "Inside Delivery",
    colorHex: "#9CA8E5",
    position: 90,
  },
  $$$: {
    id: 78,
    name: "$$$",
    description: "Driver Collect",
    colorHex: "#AA7EA0",
    position: 130,
  },
  VOL: {
    id: 79,
    name: "VOL",
    description: "Volume Shipment",
    colorHex: "#B39DDB",
    position: 150,
  },
  TL: {
    id: 80,
    name: "TL",
    description: "Truckload Shipment",
    colorHex: "#80CAE2",
    position: 160,
  },
  VC: {
    id: 81,
    name: "VC",
    description: "Vendor Compliance",
    colorHex: "#AA7EA0",
    position: 30,
  },
  RSHP: {
    id: 82,
    name: "RSHP",
    description: "Reship",
    colorHex: "#3664A2",
    position: 190,
  },
} satisfies Record<string, Tag>;

type TagName = keyof typeof shipmentTagMapping;

export const tagNameToShipmentTagMap = (tags: TagName[]) =>
  tags.map((tag) => shipmentTagMapping[tag]);

import { Box, MenuItem } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useState } from "react";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import { ActionBarStyledMenu } from "../prePlanning/actionbar/ActionBarStyledMenu";
import StyledMenuButton, {
  ButtonVariant,
} from "../prePlanning/actionbar/StyledMenuButton";

interface CreateActionButtonProps {
  createActions: ContextMenuAction[];
  variant?: ButtonVariant;
}

const CreateActionMenuButton = ({
  createActions,
  variant = "outlined",
}: CreateActionButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex">
      <StyledMenuButton
        variant={variant}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={<AddOutlinedIcon />}
        showExpandIcon
      >
        Create
      </StyledMenuButton>
      <ActionBarStyledMenu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
      >
        {createActions.map((action) => (
          <MenuItem
            key={action.barLabel}
            onClick={(e) => {
              action.handleClick?.(e);
              setAnchorEl(null); // Close the menu when an item is clicked
            }}
          >
            {action.icon} {action.barLabel}
          </MenuItem>
        ))}
      </ActionBarStyledMenu>
    </Box>
  );
};

export default CreateActionMenuButton;

import { DeliveryEquipment } from "../../../types/deliveryEquipmentOld.type";

export const trailerAvailability = {
  ASSIGNED: "Assigned Trailers",
  AVAILABLE: "Available Trailers",
} as const;

export const tractorAvailability = {
  ASSIGNED: "Assigned Tractors",
  AVAILABLE: "Available Tractors",
} as const;

export const straightTruckAvailability = {
  ASSIGNED: "Assigned STs",
  AVAILABLE: "Available STs",
} as const;

export const getTrailerOptions = (trailers: DeliveryEquipment[]) =>
  trailers
    .map((trailer) => ({
      name: trailer.name,
      value: trailer.id,
      group: trailer.inUseByInfo
        ? trailerAvailability.ASSIGNED
        : trailerAvailability.AVAILABLE,
      inUseBy: trailer.inUseByInfo,
      equipmentType: trailer.equipmentType,
    }))
    .sort((a, b) => b.group.localeCompare(a.group));

export const getTractorOptions = (tractors: DeliveryEquipment[]) =>
  tractors
    .map((tractor) => ({
      name: tractor.name,
      value: tractor.id,
      group: tractor.inUseByInfo
        ? tractorAvailability.ASSIGNED
        : tractorAvailability.AVAILABLE,
      inUseBy: tractor.inUseByInfo,
      equipmentType: tractor.equipmentType,
    }))
    .sort((a, b) => b.group.localeCompare(a.group));

export const getStraightTruckOptions = (straightTrucks: DeliveryEquipment[]) =>
  straightTrucks
    .map((straightTruck) => ({
      name: straightTruck.name,
      value: straightTruck.id,
      group: straightTruck.inUseByInfo
        ? straightTruckAvailability.ASSIGNED
        : straightTruckAvailability.AVAILABLE,
      inUseBy: straightTruck.inUseByInfo,
      equipmentType: straightTruck.equipmentType,
    }))
    .sort((a, b) => b.group.localeCompare(a.group));

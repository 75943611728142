import { AssignmentReturn } from "@mui/icons-material";
import { Button, ListItemIcon, MenuItem } from "@mui/material";
import React from "react";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import {
  useActionsMenuContext,
  type SummaryContext,
} from "../../shared/ActionsMenuContext";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";

type AssignToMenuItemProps = {
  routeOrTrap: PlanTableFormat;
  shipmentContext: SummaryContext[];
};

export const AssignToMenuItem = ({
  routeOrTrap,
  shipmentContext,
}: AssignToMenuItemProps) => {
  const { openDialog } = useActionsMenuContext();
  const handleAssignTo = () => {
    openDialog({
      name: "assign-to",
      planSummaries: [routeOrTrap],
      shipmentContext,
    });
  };
  return (
    <MenuItem onClick={handleAssignTo}>
      <ListItemIcon>
        <AssignmentReturn />
      </ListItemIcon>
      <Button sx={labelButtonStyle} disableRipple>
        Assign To
      </Button>
    </MenuItem>
  );
};

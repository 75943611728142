import {
  Box,
  Button,
  DialogTitle,
  DialogActions,
  IconButton,
  FormControl,
  SelectChangeEvent,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  FormHelperText,
  Autocomplete,
  DialogContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { ChangeEvent, useState } from "react";
import { Close, DoorSliding } from "@mui/icons-material";
import { TimePicker } from "../../../components/shared/TimePicker";
import {
  useDrivers,
  useDriversUsage,
} from "../../../hooks/react-query/useDrivers";
import { useEquipmentUsageBySicId } from "../../../hooks/react-query/useEquipments";
import { useDoorsBySicIdAndPlanDate } from "../../../hooks/react-query/useDoors";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { SelectedEquipmentType } from "../../../types/equipment.type";
import { routeTrapType } from "../../../constants/RouteTrapTypes";
import { usePlanStartDate } from "../../../hooks/usePlanStartDate";
import {
  doorAvailability,
  getDoorOptionsFromDoors,
} from "../shared/doorNumberUtils";
import { AutocompleteAvailabilityOption } from "../../../components/shared/AutocompleteAvailabilityOption";
import {
  getDriverOptions,
  getDriverOptionsOld,
  driverAvailability,
} from "../shared/driverUtils";
import {
  getStraightTruckOptions,
  getTractorOptions,
  getTrailerOptions,
  tractorAvailability,
  trailerAvailability,
  straightTruckAvailability,
} from "../shared/equipmentUtils";
import Loading from "../../../components/shared/layout/Loading";
import { planningStatus } from "../../../types/status.type";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { displayMessage } from "../../../constants/strings";
import { TextField } from "../../../components/shared/layout/TextFieldWithWarning";
import { PlanTableFormat } from "../../../types/planning/plan.type";

type EditRouteValidationErrors = {
  presetRoute?: string;
  routeName?: string;
  startTime?: string;
  driver?: string;
  trailerOrStraightTruckEquipment?: string;
  tractorEquipment?: string;
};

export type EditedPlan = Pick<
  PlanTableFormat,
  "id" | "routeEnd" | "name" | "startTime" | "endTime"
> & {
  driverId: number | null;
  doorId: number | null;
  trailerEquipmentId: number | null;
  tractorEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
};

type FormHelperText = Record<keyof EditedPlan, string>;

interface EditRouteConfirmDialogProps {
  plan: PlanTableFormat;
  open: boolean;
  savePlan: (editedPlan: EditedPlan) => void;
  cancelHandler: () => void;
}

function mapEditedPlan(plan: PlanTableFormat): EditedPlan {
  return {
    driverId: plan.driver?.id ?? 0,
    endTime: plan.endTime,
    id: plan.id,
    name: plan.name,
    routeEnd: plan.routeEnd ?? "",
    startTime: plan.startTime,
    doorId: plan.door,
    trailerEquipmentId: plan.trailer?.id ?? null,
    tractorEquipmentId: plan.tractor?.id ?? null,
    straightTruckEquipmentId: plan.straightTruck?.id ?? null,
  };
}
interface ValidatePossiblyInactiveItemArgs {
  /** Original item that was present in the plan */
  originalItem: {
    id: number;
    isAvailable?: boolean;
    isActive?: boolean;
  } | null;
  /** Edited item id selected on the dropdown */
  selectedItemId: number | null;
  /** Name of the item */
  itemName: string;
}
/**
 * Item validation function for items that can be inactive.
 * The validation is only performed over the initial values, as inactive or deleted items are excluded from dropdown options.
 */
function validatePossiblyInactiveItem({
  originalItem,
  selectedItemId,
  itemName,
}: ValidatePossiblyInactiveItemArgs): string {
  // Omit validation if item was not present originally, no record is selected, or the original id is different from the selected item's id
  if (
    !originalItem ||
    selectedItemId === null ||
    originalItem.id !== selectedItemId
  ) {
    return "";
  }

  if (originalItem.isAvailable === false || originalItem.isActive === false) {
    return displayMessage.default.recordInactive(itemName);
  }

  return "";
}

function getValidationErrors(
  plan: PlanTableFormat,
  editedPlan: EditedPlan
): EditRouteValidationErrors {
  return {
    presetRoute: "",
    routeName: !editedPlan.name ? "Route Name is required" : "",
    startTime:
      !editedPlan.startTime && plan.type === "delivery_route"
        ? "Start Time is required"
        : "",
    driver: validatePossiblyInactiveItem({
      originalItem: plan.driver,
      selectedItemId: editedPlan.driverId,
      itemName: "driver",
    }),
    trailerOrStraightTruckEquipment: validatePossiblyInactiveItem({
      originalItem: plan.trailer ?? plan.straightTruck,
      selectedItemId:
        editedPlan.trailerEquipmentId ?? editedPlan.straightTruckEquipmentId,
      itemName: "equipment",
    }),
    tractorEquipment: validatePossiblyInactiveItem({
      originalItem: plan.tractor,
      selectedItemId: editedPlan.tractorEquipmentId,
      itemName: "equipment",
    }),
  };
}

type PlanningStatusArray = Array<keyof typeof planningStatus>;

const blockSelectingAssignedTrailerOrStraightTruckStatuses: string[] = [
  planningStatus.CLDK,
  planningStatus.CLDV,
  planningStatus.TRAP,
  planningStatus.DISP,
] satisfies PlanningStatusArray;

const blockSelectingAssignedTractorStatuses: string[] = [
  planningStatus.CLDK,
  planningStatus.CLDV,
  planningStatus.DISP,
] satisfies PlanningStatusArray;

const blockSelectingAssignedDoorStatuses: string[] = [
  planningStatus.CLDK,
  planningStatus.CLDV,
  planningStatus.DISP,
] satisfies PlanningStatusArray;

const blockSelectingAssignedDriverStatuses: string[] = [
  planningStatus.DISP,
] satisfies PlanningStatusArray;

export const EditRouteConfirmDialog = ({
  plan,
  open,
  savePlan,
  cancelHandler,
}: EditRouteConfirmDialogProps) => {
  const driverGuardrailsEnabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-3-client"
  );

  const initialSelectedEquipmentType = (): SelectedEquipmentType | null => {
    if (plan.tractor || plan.trailer) {
      return "Trailer";
    } else if (plan.straightTruck) {
      return "Truck";
    } else {
      return null;
    }
  };
  const [selectedEquipmentType, setSelectedEquipmentType] =
    useState<SelectedEquipmentType | null>(initialSelectedEquipmentType);
  const [formHelperText, setFormHelperText] = useState<Partial<FormHelperText>>(
    {}
  );

  const [serviceCenter] = useSelectedServiceCenter();
  const { data: doors = [], isLoading: isLoadingDoors } =
    useDoorsBySicIdAndPlanDate(serviceCenter.id, plan.date);
  const doorOptions = getDoorOptionsFromDoors(doors);

  const { data: availableDriversOld = [], isLoading: isLoadingDriversOld } =
    useDrivers(
      {
        isAvailable: true,
        serviceCenterId: serviceCenter.id,
      },
      !driverGuardrailsEnabled
    );
  const driverOptionsOld = getDriverOptionsOld(availableDriversOld);

  const { data: drivers = [], isLoading: isLoadingDrivers } = useDriversUsage(
    serviceCenter.id,
    plan.date,
    driverGuardrailsEnabled
  );
  const driverOptions = getDriverOptions(drivers, plan.driver);

  const [editedPlan, setEditedPlan] = useState<EditedPlan>(mapEditedPlan(plan));
  const originalPlan: EditedPlan = mapEditedPlan(plan);

  const {
    data: equipments = { tractors: [], trailers: [], straightTrucks: [] },
    isLoading: isLoadingEquipments,
  } = useEquipmentUsageBySicId(serviceCenter.id, plan.date);

  const handleHelperText = (
    value: string | number,
    fieldName: keyof EditedPlan
  ) => {
    const updateHelperText = () =>
      setFormHelperText((prev) => ({
        ...prev,
        [fieldName]: `Editing this ${routeTrapType[plan.type]} will change ${plan.name} status from ${plan.status} to CLDK`,
      }));

    const clearHelperText = () =>
      setFormHelperText((prev) => ({
        ...prev,
        [fieldName]: "",
      }));

    const fieldNotAvailableForMassageChanged =
      !plan.isAvailableForMassage &&
      value.toString() !== originalPlan[fieldName]?.toString() &&
      fieldName !== "name";

    /**
     * Validate if the field name is different from name this field won't change the route/trap status.
     */
    if (fieldNotAvailableForMassageChanged) {
      updateHelperText();
    } else {
      clearHelperText();
    }
  };

  function handleChange(
    e:
      | SelectChangeEvent<string>
      | SelectChangeEvent<unknown>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { value, name } = e.target;

    setEditedPlan((prev) => ({
      ...prev,
      [name]: value,
    }));

    handleHelperText(value as string, name as keyof EditedPlan);
  }

  // If the user selects the active toggle button again, the active toggle button will be deselected
  const handleSelectEquipmentType = (type: SelectedEquipmentType | null) => {
    setSelectedEquipmentType(type === selectedEquipmentType ? null : type);
  };

  const validateForm = () => {
    const validationErrors = getValidationErrors(plan, editedPlan);
    return Object.keys(validationErrors).every(
      (key) => !validationErrors[key as keyof EditRouteValidationErrors]
    );
  };

  const planStartDate = usePlanStartDate({
    startTime: editedPlan.startTime?.toISOString() || null,
    planDate: plan.date,
    timeZone: serviceCenter.timeZone,
    // Need to display the same value regardless of the timezone
    // ex. Sic: DAL Time: 8:00-5:00. We want to show it 8AM even if the user is in LA (in LA 1pm UTC is 6am)
    keepLocalTime: false,
  });

  if (
    open &&
    (isLoadingEquipments ||
      isLoadingDriversOld ||
      isLoadingDoors ||
      isLoadingDrivers)
  ) {
    return (
      <Dialog open={open}>
        <DialogContent>
          <DialogTitle>Loading...</DialogTitle>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }

  const trailerOptions = getTrailerOptions(equipments.trailers, plan.trailer);
  const tractorOptions = getTractorOptions(equipments.tractors, plan.tractor);
  const straightTruckOptions = getStraightTruckOptions(
    equipments.straightTrucks,
    plan.straightTruck
  );

  const validationErrors = getValidationErrors(plan, editedPlan);

  return (
    <Dialog open={open} onClose={cancelHandler} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", padding: "20px 0px 10px 20px" }}>
        {`Edit "${plan.name}" ${
          plan.type === "delivery_route" ? "Route" : "Trap"
        }`}
      </DialogTitle>

      <Box position="absolute" top={10} right={10}>
        <IconButton onClick={cancelHandler}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ margin: "1px 1px 1px 20px" }}>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          {`${plan.type === "delivery_route" ? "Route" : "Trap"} Information`}
        </Typography>
      </Box>
      <Box sx={{ margin: "10px 20px 20px 20px" }}>
        <TextField
          id="outlined-basic"
          name="name"
          value={editedPlan.name}
          variant="outlined"
          onChange={handleChange}
          sx={{ width: "100%" }}
          label={
            plan.type === "delivery_route" ? "Route Name *" : "Trap Name *"
          }
          helperText={validationErrors.routeName}
        />
      </Box>
      <Box sx={{ margin: "1px 1px 1px 20px" }}>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          {`${plan.type === "delivery_route" ? "Route" : "Trap"} Details`}
        </Typography>
      </Box>
      {plan.type === "delivery_route" && (
        <Box
          sx={{ margin: "1px 20px 20px 20px" }}
          display="flex"
          flexDirection="row"
        >
          <TimePicker
            label="Start Time *"
            value={editedPlan.startTime ? planStartDate : null}
            onChange={(val) => {
              if (!plan.canEditStartTime) {
                handleHelperText(val?.toDate().toString() ?? "", "startTime");
              }
              setEditedPlan((prev) => ({
                ...prev,
                startTime: val?.toDate() ?? null,
              }));
            }}
            slotProps={{
              textField: {
                sx: { width: "100%" },
                helperText: validationErrors.startTime,
                error: !!validationErrors.startTime,
              },
            }}
          />
        </Box>
      )}
      <Box sx={{ margin: "1px 20px 20px 20px" }}>
        <FormControl fullWidth>
          <Autocomplete
            value={
              doorOptions.find((d) => Number(d.value) === editedPlan.doorId) ??
              null
            }
            options={doorOptions}
            onChange={(_, newValue) => {
              setEditedPlan((prev) => ({
                ...prev,
                doorId: newValue?.value ?? null,
              }));
            }}
            getOptionLabel={(door) => door.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Door"
                label="Door Assignment"
              />
            )}
            groupBy={(option) => option.group}
            getOptionDisabled={(option) =>
              blockSelectingAssignedDoorStatuses.includes(plan.status) &&
              option.group === doorAvailability.ASSIGNED
            }
            renderOption={(props, option) => (
              <AutocompleteAvailabilityOption
                key={option.name}
                listItemProps={props}
                name={option.name}
                assignment={option.assignment}
                leftIcon={<DoorSliding />}
              />
            )}
          />
          <FormHelperText>{formHelperText.doorId}</FormHelperText>
        </FormControl>
      </Box>
      {plan.type === "delivery_route" && (
        <Box sx={{ margin: "1px 20px 20px 20px" }}>
          <FormControl fullWidth>
            {driverGuardrailsEnabled ? (
              <Autocomplete
                value={
                  driverOptions.find(
                    (d) => Number(d.value) === editedPlan.driverId
                  ) ?? null
                }
                options={driverOptions}
                onChange={(_, newValue) => {
                  setEditedPlan((prev) => ({
                    ...prev,
                    driverId: newValue?.value ?? null,
                  }));
                }}
                getOptionLabel={(driver) => driver.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Driver"
                    label="Driver"
                    helperText={validationErrors.driver}
                    warning="Unavailable"
                  />
                )}
                groupBy={(option) => option.group}
                getOptionDisabled={(option) =>
                  (blockSelectingAssignedDriverStatuses.includes(plan.status) &&
                    option.group === driverAvailability.ASSIGNED) ||
                  option.group === driverAvailability.INACTIVE
                }
                renderOption={(props, option) => (
                  <AutocompleteAvailabilityOption
                    key={`${option.name}-${option.value}`}
                    listItemProps={props}
                    assignment={option.assignment}
                    name={option.name}
                  />
                )}
              />
            ) : (
              <Autocomplete
                value={
                  driverOptionsOld.find(
                    (d) => Number(d.value) === editedPlan.driverId
                  ) ?? null
                }
                options={driverOptionsOld}
                onChange={(_, newValue) => {
                  setEditedPlan((prev) => ({
                    ...prev,
                    driverId: newValue?.value ?? null,
                  }));
                }}
                getOptionLabel={(driver) => driver.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Driver"
                    label="Driver"
                    warning="Unavailable"
                  />
                )}
                groupBy={(option) => option.group}
                getOptionDisabled={(option) =>
                  blockSelectingAssignedDriverStatuses.includes(plan.status) &&
                  option.group === driverAvailability.ASSIGNED
                }
                renderOption={(props, option) => (
                  <AutocompleteAvailabilityOption
                    key={`${option.name}-${option.value}`}
                    listItemProps={props}
                    assignment={null}
                    name={option.name}
                  />
                )}
              />
            )}
            <FormHelperText>{formHelperText.driverId}</FormHelperText>
          </FormControl>
        </Box>
      )}
      <Box sx={{ margin: "1px 10px 1px 20px" }}>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          Select an equipment type
        </Typography>
      </Box>
      <Box sx={{ margin: "10px 20px 20px 20px" }}>
        <ToggleButtonGroup
          color="primary"
          value={selectedEquipmentType}
          exclusive
          aria-label="Platform"
          size="small"
        >
          <ToggleButton
            value="Trailer"
            onChange={() => handleSelectEquipmentType("Trailer")}
          >
            Trailer
          </ToggleButton>
          <ToggleButton
            value="Truck"
            onChange={() => handleSelectEquipmentType("Truck")}
          >
            Straight Truck
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <div role="tabpanel" hidden={selectedEquipmentType !== "Trailer"}>
        <Box sx={{ margin: "10px 20px 20px 20px" }}>
          <FormControl sx={{ width: "100%" }} size="small">
            <Autocomplete
              value={
                trailerOptions.find(
                  (d) => Number(d.value) === editedPlan.trailerEquipmentId
                ) ?? null
              }
              options={trailerOptions}
              onChange={(_, newValue) => {
                setEditedPlan((prev) => ({
                  ...prev,
                  trailerEquipmentId: newValue?.value ?? null,
                }));
              }}
              getOptionLabel={(trailer) => trailer.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Trailer #"
                  helperText={validationErrors.trailerOrStraightTruckEquipment}
                  warning="Unavailable"
                />
              )}
              groupBy={(option) => option.group}
              getOptionDisabled={(option) =>
                (blockSelectingAssignedTrailerOrStraightTruckStatuses.includes(
                  plan.status
                ) &&
                  option.group === trailerAvailability.ASSIGNED) ||
                option.group === trailerAvailability.INACTIVE
              }
              renderOption={(props, option) => (
                <AutocompleteAvailabilityOption
                  key={option.name}
                  listItemProps={props}
                  name={option.name}
                  assignment={option.assignment}
                />
              )}
            />
            <FormHelperText>{formHelperText.trailerEquipmentId}</FormHelperText>
          </FormControl>
          &nbsp;
          {plan.type === "delivery_route" && (
            <FormControl sx={{ width: "100%" }} size="small">
              <Autocomplete
                value={
                  tractorOptions.find(
                    (d) => Number(d.value) === editedPlan.tractorEquipmentId
                  ) ?? null
                }
                options={tractorOptions}
                onChange={(_, newValue) => {
                  setEditedPlan((prev) => ({
                    ...prev,
                    tractorEquipmentId: newValue?.value ?? null,
                  }));
                }}
                getOptionLabel={(tractor) => tractor.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tractor #"
                    helperText={validationErrors.tractorEquipment}
                    warning="Unavailable"
                  />
                )}
                groupBy={(option) => option.group}
                getOptionDisabled={(option) =>
                  (blockSelectingAssignedTractorStatuses.includes(
                    plan.status
                  ) &&
                    option.group === tractorAvailability.ASSIGNED) ||
                  option.group === tractorAvailability.INACTIVE
                }
                renderOption={(props, option) => (
                  <AutocompleteAvailabilityOption
                    key={option.name}
                    listItemProps={props}
                    name={option.name}
                    assignment={option.assignment}
                  />
                )}
              />
              <FormHelperText>
                {formHelperText.tractorEquipmentId}
              </FormHelperText>
            </FormControl>
          )}
        </Box>
      </div>
      <div role="tabpanel" hidden={selectedEquipmentType !== "Truck"}>
        <Box sx={{ margin: "10px 20px 20px 20px" }}>
          <FormControl sx={{ width: "100%" }} size="small">
            <Autocomplete
              value={
                straightTruckOptions.find(
                  (d) => Number(d.value) === editedPlan.straightTruckEquipmentId
                ) ?? null
              }
              options={straightTruckOptions}
              onChange={(_, newValue) => {
                setEditedPlan((prev) => ({
                  ...prev,
                  straightTruckEquipmentId: newValue?.value ?? null,
                }));
              }}
              getOptionLabel={(straightTruck) => straightTruck.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ST #"
                  helperText={validationErrors.trailerOrStraightTruckEquipment}
                  warning="Unavailable"
                />
              )}
              groupBy={(option) => option.group}
              getOptionDisabled={(option) =>
                (blockSelectingAssignedTrailerOrStraightTruckStatuses.includes(
                  plan.status
                ) &&
                  option.group === straightTruckAvailability.ASSIGNED) ||
                option.group === straightTruckAvailability.INACTIVE
              }
              renderOption={(props, option) => (
                <AutocompleteAvailabilityOption
                  key={option.name}
                  listItemProps={props}
                  name={option.name}
                  assignment={option.assignment}
                />
              )}
            />
            <FormHelperText>
              {formHelperText.straightTruckEquipmentId}
            </FormHelperText>
          </FormControl>
        </Box>
      </div>
      <DialogActions sx={{ margin: "5px 20px 20px 20px" }}>
        <Button onClick={cancelHandler} variant="text">
          Cancel
        </Button>
        <Button
          onClick={() => {
            // Per our business rules, when Trailer and Truck toggle button are not selected, we will send trailerEquipmentId = null,
            // straightTruckEquipmentId = null, tractorEquipmentId = null to backend API to clear them in DB.
            if (validateForm()) {
              if (selectedEquipmentType === null) {
                savePlan({
                  ...editedPlan,
                  straightTruckEquipmentId: null,
                  tractorEquipmentId: null,
                  trailerEquipmentId: null,
                });
              } else if (selectedEquipmentType === "Truck") {
                savePlan({
                  ...editedPlan,
                  tractorEquipmentId: null,
                  trailerEquipmentId: null,
                });
              } else {
                savePlan({
                  ...editedPlan,
                  straightTruckEquipmentId: null,
                });
              }
            }
          }}
          color="secondary"
          variant="contained"
          sx={{ width: "80px" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import dayjs from "dayjs";
import { UcccPlanningShipment } from "../../hooks/react-query/linehaul/useLoadBoard";

export const mockUcccPlanningShipments: UcccPlanningShipment[] = [
  {
    shipmentId: 72929,
    proNumber: "123",
    tripNumber: 123,
    weight: 100,
    revenue: 200,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    currentSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    destinationSic: {
      code: "LAX",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 10,
    pieces: 100,
    serviceDue: dayjs(new Date()),
    tags: ["UC"],
    pickupDate: dayjs(new Date()),
    shipperName: "Shipper",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: {
      tripNumber: 123321123321,
      pickupDate: dayjs(new Date()),
      stops: [],
      carrier: {
        code: "CAR",
        id: 1,
        isActive: true,
        name: "Carrier Name",
      },
      bookingNumber: "BOOK123",
      employeeDrivers: [],
      modifiedBy: "userModify",
      modifiedOn: dayjs(new Date("2024-01-01")),
    },
  },
  {
    shipmentId: 20000292902,
    proNumber: "456",
    tripNumber: 456,
    weight: 200,
    revenue: 400,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "LAX",
      id: 1,
    },
    currentSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "LAX",
      id: 1,
    },
    destinationSic: {
      code: "ONT",
      id: 1,
      ownership: "C",
      lhRegionId: 1,
    },
    pallets: 20,
    pieces: 200,
    serviceDue: dayjs(new Date()),
    tags: ["CC"],
    pickupDate: dayjs(new Date()),
    shipperName: "John Test",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "LAX",
    loadedTrip: null,
  },
  {
    shipmentId: 32292929,
    proNumber: "789",
    tripNumber: 789,
    weight: 300,
    revenue: 600,
    originSic: {
      ownership: "C",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    currentSic: {
      ownership: "C",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    destinationSic: {
      code: "LAX",
      id: 1,
      ownership: "C",
      lhRegionId: 1,
    },
    pallets: 30,
    pieces: 300,
    serviceDue: dayjs(new Date()),
    tags: ["VOL", "APPT"],
    pickupDate: dayjs(new Date()),
    shipperName: "Daylight Shipping",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "DAL",
    loadedTrip: null,
  },
  {
    shipmentId: 4292837476,
    proNumber: "101",
    tripNumber: 101,
    weight: 400,
    revenue: 800,
    originSic: {
      ownership: "C",
      lhRegionId: 1,
      code: "LAX",
      id: 1,
    },
    currentSic: {
      code: "DAL",
      id: 1,
      ownership: "C",
      lhRegionId: 1,
    },
    destinationSic: {
      code: "DAL",
      id: 1,
      ownership: "C",
      lhRegionId: 1,
    },
    pallets: 40,
    pieces: 400,
    serviceDue: dayjs(new Date()),
    tags: ["APPT", "LG"],
    pickupDate: dayjs(new Date()),
    shipperName: "Jeremiah Lee",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "DAL",
    loadedTrip: null,
  },
  {
    shipmentId: 2222255555,
    proNumber: "121",
    tripNumber: 121,
    weight: 500,
    revenue: 1000,
    originSic: {
      ownership: "C",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    currentSic: {
      ownership: "C",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    destinationSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 50,
    pieces: 500,
    serviceDue: dayjs(new Date()),
    tags: ["LG", "UC"],
    pickupDate: dayjs(new Date()),
    shipperName: "Stephen Wolf",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 6567898654,
    proNumber: "141",
    tripNumber: 141,
    weight: 600,
    revenue: 1200,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    currentSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    destinationSic: {
      code: "ONT",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 60,
    pieces: 600,
    serviceDue: dayjs(new Date()),
    tags: ["APPT", "LG"],
    pickupDate: dayjs(new Date()),
    shipperName: "Daylight Shipping",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "DAL",
    loadedTrip: null,
  },
  {
    shipmentId: 7181888,
    proNumber: "161",
    tripNumber: 161,
    weight: 700,
    revenue: 1400,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    currentSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    destinationSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 70,
    pieces: 700,
    serviceDue: dayjs(new Date()),
    tags: ["CC", "LG"],
    pickupDate: dayjs(new Date()),
    shipperName: "Daylight Shipping",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 812121,
    proNumber: "181",
    tripNumber: 181,
    weight: 800,
    revenue: 1600,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    currentSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    destinationSic: {
      code: "ONT",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 80,
    pieces: 800,
    serviceDue: dayjs(new Date()),
    tags: ["LG", "APPT"],
    pickupDate: dayjs(new Date()),
    shipperName: "Shipper Test",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 923377,
    proNumber: "201",
    tripNumber: 201,
    weight: 900,
    revenue: 1800,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    currentSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    destinationSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 90,
    pieces: 900,
    serviceDue: dayjs(new Date()),
    tags: ["LG", "VOLUME"],
    pickupDate: dayjs(new Date()),
    shipperName: "Daylight Shipping",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 1012145,
    proNumber: "221",
    tripNumber: 221,
    weight: 1000,
    revenue: 2000,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    currentSic: {
      code: "ONT",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    destinationSic: {
      code: "ONT",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 100,
    pieces: 1000,
    serviceDue: dayjs(new Date()),
    tags: ["VOLUME", "VOL"],
    pickupDate: dayjs(new Date()),
    shipperName: "Shipper #3",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 1188544,
    proNumber: "241",
    tripNumber: 241,
    weight: 1100,
    revenue: 2200,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    currentSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    destinationSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 110,
    pieces: 1100,
    serviceDue: dayjs(new Date()),
    tags: ["VOLUME", "PFCL"],
    pickupDate: dayjs(new Date()),
    shipperName: "Tow Shipping",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 1229340,
    proNumber: "261",
    tripNumber: 261,
    weight: 1200,
    revenue: 2400,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    currentSic: {
      code: "ATM",
      id: 3,
      ownership: "V",
      lhRegionId: 1,
    },
    destinationSic: {
      code: "ONT",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 120,
    pieces: 1200,
    serviceDue: dayjs(new Date()),
    tags: ["PFCL", "APPT"],
    pickupDate: dayjs(new Date()),
    shipperName: "#1 Shipper",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "ATM",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 13320101,
    proNumber: "281",
    tripNumber: 281,
    weight: 1300,
    revenue: 2600,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    currentSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    destinationSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 130,
    pieces: 1300,
    serviceDue: dayjs(new Date()),
    tags: ["DTCL", "PFCL"],
    pickupDate: dayjs(new Date()),
    shipperName: "Covenant",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 1412121266,
    proNumber: "301",
    tripNumber: 301,
    weight: 1400,
    revenue: 2800,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "DAL",
      id: 1,
    },
    currentSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    destinationSic: {
      code: "ONT",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 140,
    pieces: 1400,
    serviceDue: dayjs(new Date()),
    tags: ["UC", "DTCL"],
    pickupDate: dayjs(new Date()),
    shipperName: "Daylight Shipping",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
  {
    shipmentId: 15157909764,
    proNumber: "321",
    tripNumber: 321,
    weight: 1500,
    revenue: 3000,
    originSic: {
      ownership: "V",
      lhRegionId: 1,
      code: "ONT",
      id: 1,
    },
    currentSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    destinationSic: {
      code: "DAL",
      id: 1,
      ownership: "V",
      lhRegionId: 1,
    },
    pallets: 150,
    pieces: 1500,
    serviceDue: dayjs(new Date()),
    tags: ["CC", "UC"],
    pickupDate: dayjs(new Date()),
    shipperName: "Daylight Shipping",
    shipperZipCode: "33809",
    consigneeName: "Daylight Inc",
    consigneeZipCode: "32819",
    originSicCode: "LAX",
    destinationSicCode: "ONT",
    timeOnDock: 1,
    currentSicCode: "ONT",
    loadedTrip: null,
  },
];

import { PrintTableColumn } from "./PrintTable";
import { RowData } from "./types";

const routeColumns: PrintTableColumn[] = [
  {
    header: "Inbound Load Cards",
    renderAs: "checkbox",
    dataIndex: "isInboundLoadCard",
  },

  {
    header: "Delivery Receipts",
    renderAs: "checkbox",
    dataIndex: "isDeliveryReceipts",
  },
  {
    header: "Delivery Manifest",
    renderAs: "checkbox",
    dataIndex: "isDeliveryManifest",
  },
] as const;

export function getColumns(rows: RowData[]): PrintTableColumn[] {
  const hasRouteRows = rows.some(({ type }) => type === "delivery_route");
  const hasTrapRows = rows.some(({ type }) => type === "delivery_trap");
  const columns: PrintTableColumn[] = [
    { header: "Type", renderAs: "text", dataIndex: "type" },
    { header: "Route", renderAs: "text", dataIndex: "route" },
    { header: "Bills", renderAs: "number", dataIndex: "bills" },
    { header: "Status", renderAs: "tag", dataIndex: "status" },
  ];

  if (hasRouteRows) columns.push(...routeColumns);

  if (hasTrapRows) {
    columns.push({
      header: "Trap load Cards",
      renderAs: "checkbox",
      dataIndex: "isTrapLoadCard",
    });
  }

  return columns;
}

import { create } from "zustand";

type UnauthorizedErrorDialogState = {
  isUnauthorizedErrorDialogOpen: boolean;
  setIsUnauthorizedErrorDialogOpen: (isOpen: boolean) => void;
};

// Using Zustand to declare state outside React
export const useUnauthorizedErrorDialogStore =
  create<UnauthorizedErrorDialogState>()((set) => ({
    isUnauthorizedErrorDialogOpen: false,
    setIsUnauthorizedErrorDialogOpen: (isOpen) =>
      set({ isUnauthorizedErrorDialogOpen: isOpen }),
  }));

import type { ReactNode } from "react";
import { ContextMenuAction } from "../../../../types/contextMenuAction";
import { SummaryContext } from "../../shared/ActionsMenuContext";
import { ValidateMergeRequestResult } from "../../../../hooks/useValidateMergeRequest";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import ContextMenuSelectionCount from "../../../../components/shared/ContextMenuSelectionCount";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import { AutoSequenceMenuItem } from "./AutoSequenceMenuItem";
import MergeMenuItem from "./MergeMenuItem";
import { DeletePlanMenuItem } from "./DeletePlanMenuItem";
import { EditMenuItem } from "./EditMenuItem";
import { PrintMenuItem } from "./PrintMenuItem";
import { CopyLinkMenuItem } from "./CopyLinkMenuItem";
import { ChangeStatusMenuItem } from "./ChangeStatusMenuItem";
import { UnlockRoutesMenuItem } from "./UnlockRoutesMenuItem";
import { LockRoutesMenuItem } from "./LockRoutesMenuItem";
import { CreateRouteTrapMenuItem } from "./CreateRouteTrapMenuItem";
import { AssignToMenuItem } from "./AssignToMenuItem";
import { UnassignMenuItem } from "./UnassignMenuItem";

type PlanningContextMenuActionsArgs =
  | {
      plannedRoutesOrTraps: PlanTableFormat[];
      routeOrTrap?: never;
      shipmentContext: SummaryContext[];
      section: "planning";
      clearSelection: () => void;
      validateMergeRequest: () => ValidateMergeRequestResult;
    }
  | {
      plannedRoutesOrTraps?: never;
      routeOrTrap: PlanTableFormat | null;
      shipmentContext: SummaryContext[];
      section: "shipments";
      clearSelection: () => void;
      validateMergeRequest?: never;
    };

export const planningContextMenuActions = ({
  plannedRoutesOrTraps = [],
  routeOrTrap,
  shipmentContext,
  section,
  clearSelection,
  validateMergeRequest,
}: PlanningContextMenuActionsArgs): ContextMenuAction[] => {
  const unifiedContext = unifyShipmentContext(shipmentContext);

  const selectedRoutesAndTraps = plannedRoutesOrTraps.filter((plan) =>
    unifiedContext.some((ctx) => String(ctx.id) === String(plan.id))
  );

  const lockedRoutes = selectedRoutesAndTraps.filter(
    (routeOrTrap) =>
      routeOrTrap.isCommitted && routeOrTrap.type === "delivery_route"
  );
  const unlockedRoutes = selectedRoutesAndTraps.filter(
    (routeOrTrap) =>
      Boolean(!routeOrTrap.isCommitted) && routeOrTrap.type === "delivery_route"
  );

  const actionItem = (
    label: string,
    itemComponent: ReactNode,
    keepOpenAfterClick = false
  ): ContextMenuAction => ({
    label,
    itemComponent,
    keepOpenAfterClick,
  });

  const actions: ContextMenuAction[] = [
    actionItem(
      "Selection count",
      <ContextMenuSelectionCount
        count={
          section === "planning"
            ? shipmentContext.length
            : shipmentContext.flatMap((shipment) => shipment.shipmentIds).length
        }
      />,
      true
    ),
  ];
  if (section === "planning") {
    if (
      selectedRoutesAndTraps.some(
        (routeOrTrap) => routeOrTrap.type === "delivery_route"
      )
    ) {
      actions.push(
        actionItem(
          "Auto-Sequence",
          <AutoSequenceMenuItem routesOrTraps={selectedRoutesAndTraps} />
        )
      );
    }
    actions.push(
      actionItem(
        "Print",
        <PrintMenuItem
          routesOrTraps={selectedRoutesAndTraps}
          clearSelection={clearSelection}
        />,
        true
      )
    );
    if (
      selectedRoutesAndTraps.some(
        (routeOrTrap) =>
          routeOrTrap.isCommitted && routeOrTrap.type === "delivery_route"
      )
    ) {
      actions.push(
        actionItem(
          "Unlock route",
          <UnlockRoutesMenuItem
            routes={lockedRoutes}
            clearSelection={clearSelection}
          />
        )
      );
    }
    if (
      selectedRoutesAndTraps.some(
        (routeOrTrap) =>
          !routeOrTrap.isCommitted && routeOrTrap.type === "delivery_route"
      )
    ) {
      actions.push(
        actionItem(
          "Lock route",
          <LockRoutesMenuItem
            routes={unlockedRoutes}
            clearSelection={clearSelection}
          />
        )
      );
    }
    if (selectedRoutesAndTraps.length > 1) {
      actions.push(
        actionItem(
          "Merge",
          <MergeMenuItem
            planSummaries={selectedRoutesAndTraps}
            shipmentContext={shipmentContext}
            validateMergeRequest={validateMergeRequest}
            clearSelection={clearSelection}
          />,
          true
        )
      );
    }
    actions.push(
      actionItem(
        "Change Status",
        <ChangeStatusMenuItem
          routesOrTraps={selectedRoutesAndTraps}
          isParentOpen={true}
          clearSelection={clearSelection}
        />
      ),
      actionItem(
        "Copy link",
        <CopyLinkMenuItem routesOrTraps={selectedRoutesAndTraps} />
      )
    );
    if (selectedRoutesAndTraps.length === 1) {
      actions.push(
        actionItem(
          "Edit",
          <EditMenuItem routeOrTrap={selectedRoutesAndTraps[0]} />,
          true
        )
      );
    }
    actions.push(
      actionItem(
        "Delete",
        <DeletePlanMenuItem
          routesOrTraps={selectedRoutesAndTraps}
          clearSelection={clearSelection}
        />,
        true
      )
    );
  } else if (routeOrTrap) {
    actions.push(
      actionItem(
        "Create Route or Trap",
        <CreateRouteTrapMenuItem
          isParentOpen={true}
          routeOrTrap={routeOrTrap}
          shipmentContext={shipmentContext}
        />,
        true
      ),
      actionItem(
        "Assign To",
        <AssignToMenuItem
          routeOrTrap={routeOrTrap}
          shipmentContext={shipmentContext}
        />,
        true
      ),
      actionItem(
        "Unassign",
        <UnassignMenuItem
          routeOrTrap={routeOrTrap}
          shipmentContext={shipmentContext}
        />
      )
    );
  }
  return actions;
};

import { useMutation } from "@tanstack/react-query";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";

type Variables = {
  routeId: number;
  data: {
    shipmentIds: number[];
    afterShipmentId: number;
  };
};

const reorderShipments = async ({ routeId, data }: Variables) => {
  const url = apiUrls.reorderShipments(routeId);
  const { status } = await http.patch(url, data);
  return status;
};

export const useReorderShipments = ({
  onSuccess,
}: {
  onSuccess?: (routeId: number) => Promise<void>;
}) =>
  useMutation({
    mutationFn: reorderShipments,
    onSuccess: (_, { routeId }) => onSuccess?.(routeId),
  });

export const useOldReorderShipments = ({
  onSuccess,
}: {
  onSuccess?: (routeId: number) => Promise<void>;
}) =>
  useMutation({
    mutationFn: reorderShipments,
    onSuccess: (_, { routeId }) => onSuccess?.(routeId),
  });

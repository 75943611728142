import { Autocomplete } from "@mui/material";
import { AutocompleteAvailabilityOption } from "../../../../components/shared/AutocompleteAvailabilityOption";
import { AssignmentDetails } from "../../../../types/assignment.type";
import { TextField as TextFieldDriver } from "../../../../components/shared/layout/TextFieldWithWarning";

type EquipmentOption = {
  name: string;
  value: number;
  group: string;
  assignment: AssignmentDetails | null;
};

interface TractorTrailerAutocompleteProps {
  options: EquipmentOption[];
  selectedEquipmentId: number | null;
  label: string;
  onSelect: (id: number | null) => void;
  isDisabled?: (option: EquipmentOption) => boolean;
}

export const TractorTrailerAutocomplete = ({
  options,
  selectedEquipmentId,
  onSelect,
  label,
  isDisabled,
}: TractorTrailerAutocompleteProps) => {
  const selectedOption =
    options.find((option) => Number(option.value) === selectedEquipmentId) ||
    null;

  return (
    <Autocomplete
      value={selectedOption}
      sx={{ mb: "10px" }}
      options={options}
      onChange={(_, newValue) => {
        onSelect(newValue?.value ? Number(newValue.value) : null);
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => <TextFieldDriver {...params} label={label} />}
      renderOption={(props, option) => (
        <AutocompleteAvailabilityOption
          key={option.name}
          listItemProps={props}
          name={option.name}
          assignment={option.assignment}
        />
      )}
      getOptionDisabled={isDisabled}
    />
  );
};

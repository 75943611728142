import { ShipmentDetails } from "../../hooks/react-query/dispatch/useShipments";
import { Shipment } from "../../hooks/react-query/dispatch/useStops";
import { mockDispatchMiscellaneous, mockDispatchStops } from "./stops.mock";

const defaultData = {
  attemptLocation: {
    latitude: 34.2122,
    longitude: -117.6437,
  },
  receivedBy: {
    name: "John Doe",
    email: "john_doe@gmail.com",
    signatureUrl: "/empty-truck.png",
  },
  shipper: {
    name: "Landmark Ceramic",
    address: "1427 N Main ST TN 38647",
    city: "Mount Pleasant",
    state: "TN",
    zipCode: "38474",
    phone: "(123) 456-7890",
  },
  consignee: {
    name: "Specstones Import INC",
    address: "L500 Burke Avenue Suite B",
    city: "San Francisco",
    state: "CA",
    zipCode: "94124",
    phone: "(555) 111-2312",
  },
  descriptionOfArticles: [
    "182570-0 Tile; Clay; Ceramic; or Earthenware; CL 60",
    "Actual Class: 60 Rate Class:60",
    "California Compliance surcharge",
    "Fuel Surcharge",
    "Pieces must be verified at TI me of delivery",
    "Do not breakdown pallet",
    "Do not double stack",
  ],
  amount: {
    subtotal: 16452,
    discountAmount: 536,
    other: 34,
    totalDriverCollectDue: 24,
    total: 17046,
  },
};

const mockPickups = mockDispatchStops.default
  .map((stop) => stop.pickups as Shipment[])
  .flat();
const mockPickupDetails = mockPickups.reduce(
  (acc, mockPickup) => {
    acc[mockPickup.id] = {
      proNumber: mockPickup.proNumber,
      statusName: mockPickup.statusName,
      pallets: mockPickup.pallets,
      pieces: mockPickup.pieces,
      weight: mockPickup.weight,
      type: "PICKUP",
      miscellaneous: mockDispatchMiscellaneous,
      ...defaultData,
    };
    return acc;
  },
  {} as Record<string, ShipmentDetails>
);

const mockDeliveries = mockDispatchStops.default
  .map((stop) => stop.deliveries as Shipment[])
  .flat();
const mockDeliveryDetails = mockDeliveries.reduce(
  (acc, mockPickup) => {
    acc[mockPickup.id] = {
      proNumber: mockPickup.proNumber,
      statusName: mockPickup.statusName,
      pallets: mockPickup.pallets,
      pieces: mockPickup.pieces,
      weight: mockPickup.weight,
      type: "DELIVERY",
      miscellaneous: [],
      ...defaultData,
    };
    return acc;
  },
  {} as Record<string, ShipmentDetails>
);

export const mockShipmentDetails = {
  ...mockPickupDetails,
  ...mockDeliveryDetails,
} as Record<string, ShipmentDetails>;

import { z } from "zod";
import { shipmentSchema } from "../../services/prePlanningService.types";

const shipmentWithGeocodeSchema = shipmentSchema.and(
  z.object({
    stopSeq: z.number(),
    lat: z.number(),
    lng: z.number(),
  })
);

export const shipmentsWithGeocodeSchema = z.array(shipmentWithGeocodeSchema);

export const shipmentWithSequenceSchema = shipmentSchema.and(
  z.object({
    eta: z.string(),
    shipmentId: z.number(),
    stopSeq: z.number(),
  })
);

export type ShipmentWithSequence = z.infer<typeof shipmentWithSequenceSchema>;

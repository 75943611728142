import { Typography, TypographyProps } from "@mui/material";

type TitleProps = TypographyProps & {
  children: string;
  weight?: string;
  padding?: string;
};

export const Title = ({ children, ...props }: TitleProps) => (
  <Typography sx={{ fontWeight: props.weight || "500" }} {...props}>
    {children}
  </Typography>
);

import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { http } from "../../../../utils/httpCommon";
import { apiUrls } from "../../../../utils/apiUrls";

export const userGroupsKeys = {
  getUserGroups: (userId: string) => ["userGroups", userId],
};

const userGroupSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullish(),
  dateAssigned: z.date().nullish(),
  updatedBy: z.string().nullish(),
});
export type UserGroup = z.infer<typeof userGroupSchema>;

export const useGetUserGroups = (userId: string, enabled = true) =>
  useQuery({
    enabled,
    queryKey: userGroupsKeys.getUserGroups(userId),
    queryFn: async () =>
      userGroupSchema
        .array()
        .parse((await http.get(apiUrls.getUserGroups(userId))).data),
  });

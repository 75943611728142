import {
  Box,
  IconButton,
  Typography,
  capitalize,
  styled,
  useTheme,
} from "@mui/material";
import { PropsWithChildren, ReactNode, memo } from "react";
import {
  Business,
  Close,
  InfoOutlined,
  MenuBook,
  Receipt,
  Route,
  ViewModule,
} from "@mui/icons-material";
import MonitorWeight from "@mui/icons-material/MonitorWeight";
import { z } from "zod";
import pluralize from "pluralize";
import { hexToRgba } from "../../../theme";
import { formatNumber } from "../../../constants/utils";
import PalletsIcon from "../../../components/shared/icons/PalletsIcon";
import { iconFillColors } from "../../../components/shared/icons/Icon.types";
import PiecesIcon from "../../../components/shared/icons/PiecesIcon";
import { PlanType } from "../../../types/planning/plan.type";

export const tableSectionSchema = z.enum([
  "route",
  "section",
  "trap",
  "appointment",
  "planning",
]);

export type TableSection = z.infer<typeof tableSectionSchema>;

type TableCommonProps = {
  id: string | number;
  type?: PlanType;
  bills: number;
  pallets: number;
  pieces: number;
  weight: number;
};

type TableActionBarProps<T> = {
  selectedRows: T[];
  section: TableSection;
  actions: ReactNode;
  isShipmentTable?: boolean;
  clearSelection?: () => void;
};

export const tableSectionToIconMap: Record<TableSection, ReactNode> = {
  appointment: <MenuBook />,
  route: <Route />,
  section: <Business />,
  trap: <ViewModule />,
  planning: <InfoOutlined />,
};

const Totalizer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  fontWeight: "400",
  fontSize: "1rem",
  color: theme.palette.grey[700],
  "&:not(:last-child)::after": {
    content: '""',
    display: "block",
    width: "1px",
    height: "100%",
    backgroundColor: theme.palette.grey[100],
    position: "absolute",
    right: "-0.5rem",
    top: 0,
    bottom: 0,
  },
}));

const TableActionBar = <T extends TableCommonProps>({
  selectedRows,
  section,
  isShipmentTable = false,
  actions,
  clearSelection = () => {},
  children,
}: PropsWithChildren<TableActionBarProps<T>>) => {
  const theme = useTheme();

  if (selectedRows.length > 0) {
    return (
      <menu
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5rem",
          margin: 0,
          height: "2.5rem",
          borderRadius: "0.25rem",
          backgroundColor: hexToRgba(theme.palette.action.selected, 0.4),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Box display="flex" alignItems="center">
            <IconButton onClick={clearSelection} aria-label="Clear selection">
              <Close sx={{ color: theme.palette.grey[600] }} />
            </IconButton>
            {section !== "planning" && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 16,
                }}
              >
                Selected:
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <Totalizer
              sx={{
                color:
                  section === "planning" ? theme.palette.primary.main : "unset",
              }}
            >
              {tableSectionToIconMap[section]}
              <Typography>
                {section === "planning"
                  ? "Selected:"
                  : `${selectedRows.length} ${capitalize(pluralize(section, selectedRows.length))}`}
              </Typography>
            </Totalizer>
            {section !== "planning" ? (
              <CommonTotalizers
                selectedRows={selectedRows}
                isShipmentTable={isShipmentTable}
              />
            ) : (
              <PlanningTotalizers
                selectedRows={selectedRows}
                isShipmentTable={isShipmentTable}
              />
            )}
          </Box>
        </Box>
        {actions}
      </menu>
    );
  } else {
    return <Box sx={{ display: "flex", alignItems: "center" }}>{children}</Box>;
  }
};

type CommonTotalizersProps<T> = { selectedRows: T[]; isShipmentTable: boolean };

const CommonTotalizers = <T extends TableCommonProps>({
  selectedRows,
  isShipmentTable = false,
}: CommonTotalizersProps<T>) => {
  const totals = selectedRows.reduce(
    (acc, row) => {
      if (row.bills) {
        acc.bills += row.bills;
      } else {
        acc.bills += isShipmentTable ? 1 : 0;
      }
      acc.pallets += row.pallets;
      acc.pieces += row.pieces;
      acc.weight += row.weight;
      return acc;
    },
    { bills: 0, pallets: 0, pieces: 0, weight: 0 }
  );
  return (
    <>
      <Totalizer>
        <Receipt />
        <Typography>
          {`${formatNumber(totals.bills)} ${pluralize("Bill", totals.bills)}`}
        </Typography>
      </Totalizer>
      <Totalizer>
        <PalletsIcon size={24} fill={iconFillColors.darkGray} />
        <Typography>
          {`${formatNumber(totals.pallets)} ${pluralize("Plt", totals.pallets)}`}
        </Typography>
      </Totalizer>
      <Totalizer>
        <PiecesIcon size={24} fill={iconFillColors.darkGray} />
        <Typography>
          {`${formatNumber(totals.pieces)} ${pluralize("Pc", totals.pieces)}`}
        </Typography>
      </Totalizer>
      <Totalizer>
        <MonitorWeight />
        <Typography>
          {`${formatNumber(totals.weight)} ${pluralize("lb", totals.weight)}`}
        </Typography>
      </Totalizer>
    </>
  );
};

type PlanningTotalizersProps<T> = {
  selectedRows: T[];
  isShipmentTable: boolean;
};

const PlanningTotalizers = <T extends TableCommonProps>({
  selectedRows,
  isShipmentTable,
}: PlanningTotalizersProps<T>) => {
  const totals = selectedRows.reduce(
    (acc, row) => {
      if (row.bills) {
        acc.bills += row.bills;
      } else {
        acc.bills += isShipmentTable ? 1 : 0;
      }
      acc.pallets += row.pallets;
      acc.pieces += row.pieces;
      acc.weight += row.weight;
      if (row.type === "delivery_route") {
        acc.routes += 1;
      } else if (row.type === "delivery_trap") {
        acc.traps += 1;
      }
      return acc;
    },
    { routes: 0, traps: 0, bills: 0, pallets: 0, pieces: 0, weight: 0 }
  );

  return (
    <>
      {totals.routes > 0 && (
        <Totalizer>
          <Route />
          <Typography>
            {`${formatNumber(totals.routes)} ${pluralize("Route", totals.bills)}`}
          </Typography>
        </Totalizer>
      )}
      {totals.traps > 0 && (
        <Totalizer>
          <ViewModule />
          <Typography>
            {`${formatNumber(totals.traps)} ${pluralize("Trap", totals.bills)}`}
          </Typography>
        </Totalizer>
      )}
      <Totalizer>
        <Receipt />
        <Typography>
          {`${formatNumber(totals.bills)} ${pluralize("Bill", totals.bills)}`}
        </Typography>
      </Totalizer>
      <Totalizer>
        <PalletsIcon size={24} fill={iconFillColors.darkGray} />
        <Typography>
          {`${formatNumber(totals.pallets)} ${pluralize("Pallets", totals.pallets)}`}
        </Typography>
      </Totalizer>
      <Totalizer>
        <PiecesIcon size={24} fill={iconFillColors.darkGray} />
        <Typography>
          {`${formatNumber(totals.pieces)} ${pluralize("Pieces", totals.pieces)}`}
        </Typography>
      </Totalizer>
      <Totalizer>
        <MonitorWeight />
        <Typography>
          {`${formatNumber(totals.weight)} ${pluralize("lbs", totals.weight)}`}
        </Typography>
      </Totalizer>
    </>
  );
};

export default memo(TableActionBar) as <T>(
  props: PropsWithChildren<TableActionBarProps<T>>
) => React.JSX.Element;

import { Box, useTheme } from "@mui/material";

const ContextMenuSelectionCount = ({ count }: { count: number }) => {
  const theme = useTheme();

  if (count <= 1) {
    return;
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: 32,
        paddingX: "1rem",
        paddingBottom: "0.5rem",
        borderBottom: `1px solid ${theme.palette.divider}`,
        fontWeight: "700",
      }}
    >
      {count} selected
    </Box>
  );
};

export default ContextMenuSelectionCount;

import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";
import {
  assignmentSchema,
  planningAssignmentsSchema,
} from "../../types/assignment.type";

const keys = {
  doorTypes: ["outbound", "doorTypes"],
  doorsLayout: (sicCode: string) => ["doors", "layout", sicCode],
  doorNumbersBySicIdOld: (sicId: number) => ["doorNumbersBySicId", sicId],
  doorNumbersBySicId: (sicId: number, planDate: number) => [
    "doors",
    sicId,
    planDate,
  ],
};

const doorNumbersSchemaOld = z.array(z.number().positive());
const doorsSchema = z.array(
  z.object({
    id: z.number().positive(),
    inUseByInfo: assignmentSchema.nullable(),
    planningAssignmentsInfo: planningAssignmentsSchema,
  })
);

export type Doors = z.infer<typeof doorsSchema>;

export const useDoorNumbersBySicIdOld = (sicId: number, enabled = true) =>
  useQuery({
    queryKey: keys.doorNumbersBySicIdOld(sicId),
    queryFn: async () => {
      const url = apiUrls.getDoorNumbersBySicIdOld(sicId);
      const { data } = await http.get(url);
      return doorNumbersSchemaOld.parse(data);
    },
    enabled,
  });

type DoorSettingsRequest = {
  sicId: number;
  doorAssignment?: number | null;
};
export const useDoorSettings = (
  { sicId, doorAssignment }: DoorSettingsRequest,
  enabled = true
) =>
  useQuery({
    queryKey: keys.doorNumbersBySicIdOld(sicId),
    queryFn: async () => {
      const url = apiUrls.getDoorNumbersBySicIdOld(sicId);
      const { data } = await http.get(url);
      return doorNumbersSchemaOld.parse(data);
    },
    select: (doors) => {
      if (!doorAssignment || doorAssignment === 0) return doors;

      if (!doors.some((door) => door === doorAssignment)) {
        return [doorAssignment, ...doors];
      }

      return doors;
    },
    enabled,
  });

export const useDoorsBySicIdAndPlanDate = (sicId: number, planDate: number) =>
  useQuery({
    queryKey: keys.doorNumbersBySicId(sicId, planDate),
    queryFn: async () => {
      const url = apiUrls.getDoorNumbersBySicId(sicId);
      const { data } = await http.get(url, { params: { planDate } });
      return doorsSchema.parse(data);
    },
  });

const doorTypeSchema = z.object({
  id: z.number().nonnegative(),
  name: z.string().min(1),
});

const doorTypesResponseSchema = z.array(doorTypeSchema);

export type GetDoorTypesResponse = z.infer<typeof doorTypesResponseSchema>;

export function useDoorTypes() {
  return useQuery({
    queryKey: keys.doorTypes,
    queryFn: async () => {
      const { data } = await http.get<GetDoorTypesResponse>(
        apiUrls.getDoorTypes
      );
      return doorTypesResponseSchema.parse(data);
    },
    select: (data) => [
      ...data
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .sort((a, b) => a.name.localeCompare(b.name)),
    ],
    staleTime: Infinity,
  });
}

const doorLayoutSchema = z.object({
  doorNumber: z.number(),
  sector: z.string().optional(),
});

export type DoorLayout = z.infer<typeof doorLayoutSchema>;

const doorLayoutResponseSchema = z.array(doorLayoutSchema);

type GetDoorsLayoutResponse = z.infer<typeof doorLayoutResponseSchema>;

export function useDoorsLayout(sicCode: string) {
  return useQuery({
    queryKey: keys.doorsLayout(sicCode),
    queryFn: async () => {
      const { data } = await http.get<GetDoorsLayoutResponse>(
        apiUrls.getDoorLayouts(sicCode)
      );
      return doorLayoutResponseSchema.parse(data);
    },
    select: (data) => [
      ...data.sort((a, b) => (a.doorNumber > b.doorNumber ? 1 : -1)),
    ],
    staleTime: Infinity,
  });
}

import { memo } from "react";
import PlannedStatusTag from "./PlannedStatusTag";

type PlannedShipmentStatusTagProps = {
  /** Label displayed in the tag. */
  label: string;

  isPlannedForToday?: boolean;

  futurePlanDates?: number[];

  selectedDate: string;
};

const PlannedShipmentStatusTag = ({
  label,
  isPlannedForToday = false,
  futurePlanDates = [],
  selectedDate,
}: PlannedShipmentStatusTagProps) => (
  <PlannedStatusTag
    label={label}
    isPlannedFor={
      isPlannedForToday
        ? "today"
        : futurePlanDates.length > 0
          ? "future"
          : undefined
    }
    futurePlanDates={futurePlanDates}
    selectedDate={selectedDate}
  />
);
export default memo(PlannedShipmentStatusTag);

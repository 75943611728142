import { PropsWithChildren } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "./LoadingButton";

export type ConfirmDialogProps = {
  /** The confirmation dialog message */
  message: string;

  /** Controls the open/close state of the dialog */
  open: boolean;

  /** Called when the dialog is closed by clicking the close button or cancel button */
  onClose: () => void;

  /** The confirmation dialog title */
  title?: string | React.ReactNode;

  /** The confirmation dialog size */
  size?: DialogProps["maxWidth"];

  /** The confirmation dialog variant (controls the confirm button's color) */
  variant?: Exclude<ButtonProps["color"], "inherit">;

  /** The cancel button text */
  cancelButtonText?: string;

  /** The confirm button label text */
  confirmButtonLabel: string;

  /** The confirm button loading label text */
  confirmButtonLoadingLabel: string;

  /** Set to true to display a loading indicator on the confirm button while an async call is in progress */
  isConfirmPending?: boolean;

  /** Called when the user clicks the confirm button */
  onConfirm: () => void | Promise<void>;

  /** Additional styles to apply to the dialog content container */
  sx?: DialogProps["sx"];
};

/** A generic confirmation dialog with customizable title, message, and buttons. */
export default function ConfirmDialog({
  open,
  onClose,
  title = "Are you sure?",
  message,
  size = "xs",
  variant = "primary",
  cancelButtonText = "Cancel",
  confirmButtonLabel,
  confirmButtonLoadingLabel,
  isConfirmPending = false,
  onConfirm,
  children,
  sx,
}: Readonly<PropsWithChildren<ConfirmDialogProps>>) {
  const onCloseDefaultPrevented = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    onClose();
  };

  return (
    <Dialog
      sx={sx}
      aria-label="confirm-dialog"
      open={open}
      maxWidth={size}
      fullWidth
      onClose={onCloseDefaultPrevented}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <DialogTitle variant="h5">
        {title}
        <Box position="absolute" top={10} right={10}>
          <IconButton onClick={onCloseDefaultPrevented}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight={500} color={colors.grey[800]}>
          {message}
        </Typography>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
        <Button
          variant="outlined"
          onClick={onCloseDefaultPrevented}
          color="inherit"
          sx={{
            border: "none",
            color: colors.blue[700],
            textTransform: "none",
          }}
        >
          {cancelButtonText}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            onConfirm();
          }}
          color={variant}
          sx={{
            ml: 1,
            textTransform: "none",
          }}
          isLoading={isConfirmPending}
          loadingLabel={confirmButtonLoadingLabel}
        >
          {confirmButtonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

import { Filter, Option } from "../../../types/filter";
import {
  Prefix,
  getUniqueOptions,
  handlePrefix,
  isActiveFilter,
} from "../utils";

export type WeightQueryParam = "weight";
export type SomethingWithWeight = {
  weight: number;
  weightUnit?: string;
};

const getWeightOptions = (data: SomethingWithWeight[]): Option[] =>
  getUniqueOptions(
    data
      .map(({ weight, weightUnit }) => ({
        label: weight.toLocaleString() + " " + (weightUnit ?? "lbs"),
        value: weight,
      }))
      .sort((a, b) => a.value - b.value)
  );

export const getWeightFilter = (
  data: SomethingWithWeight[],
  prefix?: Prefix
): Filter => ({
  label: "Weight",
  queryParam: handlePrefix("weight", prefix),
  options: getWeightOptions(data),
});

export const filterWeight = <T extends SomethingWithWeight>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter((entry) => filters.includes(entry.weight.toString()))
    : data;

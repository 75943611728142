import { Template } from "../../hooks/react-query/linehaul/useTemplate";

export const mockMasterScheduleTemplates = {
  empty: [],
  default: [
    {
      id: 1,
      name: "Default 2021 Schedule",
      createdOn: new Date("2023-06-06T16:00:00"),
      modifiedOn: new Date("2023-06-07T16:00:00"),
      createdBy: "John Doe",
      modifiedBy: "Jane Doe",
    },
    {
      id: 2,
      name: "Default 2022 Schedule",
      createdOn: new Date("2022-04-11T16:00:00"),
      modifiedOn: new Date("2021-03-01T16:00:00"),
      createdBy: "John Doe",
      modifiedBy: "Jane Doe",
    },
    {
      id: 3,
      name: "Monday Holiday Schedule",
      createdOn: new Date("2021-01-01T16:00:00"),
      modifiedOn: new Date("2021-04-01T16:00:00"),
      createdBy: "John Doe",
      modifiedBy: "Jane Doe",
    },
    {
      id: 4,
      name: "Thanksgiving Week Schedule",
      createdOn: new Date("2023-10-14T16:00:00"),
      modifiedOn: new Date("2021-11-01T16:00:00"),
      createdBy: "John Doe",
      modifiedBy: "Jane Doe",
    },
  ],
} as const satisfies Record<string, Template[]>;

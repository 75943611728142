import {
  Button,
  // eslint-disable-next-line no-restricted-imports
  CircularProgress,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { FlexRow } from "../layout/Flex";
import { RowError as RowErrorType } from "../table/table.types";

interface RowErrorProps extends RowErrorType {
  rowId: string;
}

const RowError = ({
  rowId,
  message,
  onRetry,
  isRetryLoading,
}: RowErrorProps) => {
  const handleOnRetry = () => {
    if (!isRetryLoading) {
      onRetry();
    }
  };

  return (
    <FlexRow>
      <FlexRow
        style={{
          flex: "none",
          backgroundColor: colors.red[50],
          borderRadius: "3px",
          marginBottom: "5px",
          alignItems: "flex-start",
          paddingRight: "10px",
          paddingLeft: "10px",
          justifyContent: "center",
        }}
      >
        <Typography mt={0.7} color={colors.red[700]} role="alert">
          {message}
        </Typography>
        <Button
          aria-label={`Retry auto-sequence for ${rowId}`}
          sx={{
            marginLeft: "5px",
            alignItems: "center",
            justifyContent: "center",
            width: "70px",
          }}
          onClick={handleOnRetry}
        >
          <Stack direction="row" gap={1} alignItems="center">
            {isRetryLoading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <Refresh color="primary" fontSize="small" component={Refresh} />
            )}
            <Typography color="primary.main">Retry auto-sequence</Typography>
          </Stack>
        </Button>
      </FlexRow>
    </FlexRow>
  );
};

export default RowError;

import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import Dialog from "@mui/material/Dialog";
import { Close } from "@mui/icons-material";

interface UnassignShipmentConfirmDialogProps {
  shipmentId: number;
  proNumber: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}

export const UnassignShipmentConfirmDialog = ({
  proNumber,
  open,
  setOpen,
  onConfirm,
}: UnassignShipmentConfirmDialogProps) => (
  <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
    <DialogTitle
      sx={{ fontWeight: "600", padding: "20px 0px 10px 20px" }}
    >{`Remove Pro # ${proNumber}?`}</DialogTitle>
    <Box position="absolute" top={4} right={4}>
      <IconButton onClick={() => setOpen(false)}>
        <Close />
      </IconButton>
    </Box>
    <DialogContent
      sx={{
        padding: "10px 0px 0px 20px",
        minHeight: "31px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <WarningIcon color="inherit" fontSize="large" />
      <Typography paddingLeft={2}>
        {`Are you sure you want to remove Pro # ${proNumber} from the route? It will be moved to Unassigned Shipments area.`}
      </Typography>
    </DialogContent>
    <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
      <Button
        onClick={() => setOpen(false)}
        color="secondary"
        variant="outlined"
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          setOpen(false);
          onConfirm();
        }}
        sx={{ backgroundColor: "#B80D0D" }}
        color="error"
        variant="contained"
      >
        Remove
      </Button>
    </DialogActions>
  </Dialog>
);

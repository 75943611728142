import { Edit } from "@mui/icons-material";
import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { RefObject, useRef } from "react";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import { ShipmentActionDialogRef } from "../ShipmentActionDialog";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import EditRouteOrTrapDialog, {
  type EditRouteOrTrapDialogRef,
} from "../EditRouteOrTrapDialog";

type EditMenuItemProps = {
  routeOrTrap: PlanTableFormat;
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
  shipmentActionDialogRef?: RefObject<ShipmentActionDialogRef>;
};

export const EditMenuItem = ({
  routeOrTrap,
  handleClose,
  shipmentActionDialogRef,
}: EditMenuItemProps) => {
  const editRouteOrTrapRef = useRef<EditRouteOrTrapDialogRef>(null);
  return (
    <>
      <MenuItem onClick={() => editRouteOrTrapRef.current?.handleOpen()}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <Button sx={labelButtonStyle} disableRipple>
          Edit
        </Button>
      </MenuItem>
      <EditRouteOrTrapDialog
        routeOrTrap={routeOrTrap}
        ref={editRouteOrTrapRef}
        onClose={handleClose}
        shipmentActionDialogRef={shipmentActionDialogRef}
      />
    </>
  );
};

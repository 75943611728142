import {
  Autocomplete,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TagPill } from "../../../../components/shared/Pill";
import { TagWithShipmentCount } from "./SpecialShipments";

type ShipmentTagsFilterProps = {
  tags: TagWithShipmentCount[];
  onChange: (tagsApplied: TagWithShipmentCount[]) => void;
  width?: number;
  showCount?: boolean;
  value?: TagWithShipmentCount[];
  description?: string;
};

const defaultTagColor = "#616161";

export const ShipmentTagsFilter = ({
  tags,
  onChange,
  width = 300,
  showCount = true,
  value,
  description,
}: ShipmentTagsFilterProps) => (
  <Stack sx={{ px: 2, py: 3 }}>
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={4}
      noOptionsText="No results found"
      options={tags}
      onChange={(_, value) => onChange(value)}
      value={value}
      getOptionLabel={(option) => option.tag.name}
      isOptionEqualToValue={(option, value) =>
        option.tag.name === value.tag.name
      }
      renderTags={(selectedTags, getTagProps) =>
        selectedTags.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.tag.name}
            label={option.tag.name}
            sx={{
              backgroundColor: option.tag.colorHex || defaultTagColor,
              color: "whitesmoke",
            }}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox size="small" sx={{ mr: 1 }} checked={selected} />
          <TagPill color={option.tag.colorHex || defaultTagColor}>
            {option.tag.name}
          </TagPill>
          {showCount && `(${option.count})`}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ width }}
          label="Shipment Tags"
          placeholder={
            (value?.length ?? 0) === 0 ? "No Shipment Tags restrictions" : ""
          }
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
    {description && <Typography fontSize={12}>{description}</Typography>}
  </Stack>
);

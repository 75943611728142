import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { WorkInProgress } from "../components/shared/WorkInProgress";
import { ErrorBoundary } from "../components/ErrorBoundary";

export const settingsRoutes: RouteObject[] = [
  {
    path: "settings",
    lazy: () => import("../pages/ServiceCentersHandler"),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        lazy: () => import("../pages/ServiceCentersHandler"),
      },
      {
        path: ":serviceCenterCode",
        lazy: () => import("../pages/settings/SettingsLayout"),
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <Navigate to="general/sic-manager" replace />,
          },
          {
            path: "general",
            element: <Outlet />,
            errorElement: <ErrorBoundary />,
            children: [
              { index: true, element: <Navigate to="sic-manager" replace /> },
              {
                path: "sic-manager",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/settings/general/SIC-manager/SICS"),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import("../pages/settings/general/SIC-manager/SIC"),
                  },
                ],
              },
              {
                path: "sics",
                errorElement: <ErrorBoundary />,
                lazy: () => import("../pages/settings/general/ServiceCenter"),
              },
              {
                path: "roles",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () => import("../pages/settings/Roles"),
                  },
                  {
                    path: "role",
                    errorElement: <ErrorBoundary />,
                    children: [
                      {
                        index: true,
                        lazy: () => import("../pages/settings/RoleDetailsEdit"),
                      },
                      {
                        path: ":id",
                        lazy: () => import("../pages/settings/RoleDetailsEdit"),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "inbound",
            errorElement: <ErrorBoundary />,
            children: [
              {
                path: "routes-and-traps",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/settings/inbound/routes/TruckRoutes"),
                  },
                  {
                    path: "add",
                    lazy: () =>
                      import(
                        "../pages/settings/inbound/routes/ViewAndEditRouteDetails"
                      ),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import(
                        "../pages/settings/inbound/routes/ViewAndEditRouteDetails"
                      ),
                  },
                ],
              },
              {
                path: "zones",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    path: "add",
                    lazy: () =>
                      import("../pages/settings/inbound/zones/ZoneDetailsEdit"),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import("../pages/settings/inbound/zones/ZoneDetailsEdit"),
                  },
                  {
                    lazy: () =>
                      import("../pages/settings/inbound/zones/ZonesHeader"),
                    children: [
                      {
                        path: "list",
                        lazy: () =>
                          import(
                            "../pages/settings/inbound/zones/list/ZoneList"
                          ),
                      },
                      {
                        path: "map",
                        lazy: () =>
                          import("../pages/settings/inbound/zones/map/ZoneMap"),
                      },
                    ],
                  },
                ],
              },
              {
                path: "drivers",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/settings/inbound/drivers/Drivers"),
                  },
                  {
                    path: "add",
                    element: <WorkInProgress />,
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import(
                        "../pages/settings/inbound/drivers/ViewAndEditDriverDetails"
                      ),
                  },
                ],
              },
              {
                path: "equipment",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/settings/inbound/equipment/Equipment"),
                  },
                  {
                    path: "add",
                    lazy: () =>
                      import(
                        "../pages/settings/inbound/equipment/ViewAndEditEquipmentDetails"
                      ),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import(
                        "../pages/settings/inbound/equipment/ViewAndEditEquipmentDetails"
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "outbound",
            errorElement: <ErrorBoundary />,
            children: [
              {
                path: "preset-lanes",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/presetLanes/PresetLanes"
                      ),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/presetLanes/PresetLane"
                      ),
                  },
                  {
                    path: "create",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/presetLanes/PresetLane"
                      ),
                  },
                ],
              },
              {
                path: "static-load-templates",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/staticLoadTemplates/StaticLoadTemplates"
                      ),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/staticLoadTemplates/StaticLoadTemplate"
                      ),
                  },
                  {
                    path: "create",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/staticLoadTemplates/StaticLoadTemplate"
                      ),
                  },
                ],
              },
              {
                path: "static-load-templates-dock-view",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    path: ":id",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/staticLoadTemplates/StaticLoadTemplateDockView"
                      ),
                  },
                  {
                    path: "create",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/staticLoadTemplates/StaticLoadTemplateDockView"
                      ),
                  },
                ],
              },
              {
                path: "default-load-templates",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/defaultLoadTemplate/DefaultLoadTemplate"
                      ),
                  },
                ],
              },
              {
                path: "load-templates",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/templates/LoadTemplates"
                      ),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/templates/LoadTemplate"
                      ),
                  },
                  {
                    path: "create",
                    lazy: () =>
                      import(
                        "../pages/settings/outbound/templates/CreateLoadTemplate"
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "linehaul",
            errorElement: <ErrorBoundary />,
            children: [
              {
                index: true,
                lazy: () => import("../pages/settings/inbound/drivers/Drivers"),
              },
              {
                path: "regions",
                lazy: () => import("../pages/settings/linehaul/Regions"),
              },
              {
                path: "sics",
                children: [
                  {
                    index: true,
                    lazy: () => import("../pages/settings/linehaul/Sics"),
                  },
                  {
                    path: ":code",
                    lazy: () => import("../pages/settings/linehaul/SicDetails"),
                  },
                ],
              },
            ],
          },
          {
            path: "security",
            errorElement: <ErrorBoundary />,
            children: [
              { index: true, element: <Navigate to="users" replace /> },
              {
                path: "users",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/settings/security/users/Users"),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import("../pages/settings/security/users/user/User"),
                  },
                ],
              },
              {
                path: "groups",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/settings/security/groups/Groups"),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import("../pages/settings/security/groups/Group"),
                  },
                ],
              },
              {
                path: "modules",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/settings/security/modules/Modules"),
                  },
                  {
                    path: ":id",
                    lazy: () =>
                      import("../pages/settings/security/modules/Module"),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import useKeyPress from "react-use-keypress";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ErrorFallback from "../components/shared/ErrorFallback";
import { requestHandlers } from "../mocks/handlers";
import { env } from "../utils/env";
import { useDevToolsState } from "./useDevToolsState";
import { useWorker } from "./useWorker";
import { CustomResponse, HttpSettings } from "./types";

interface DevToolsProps {
  /** The app to render */
  appSlot: React.ReactNode;
}

const filesWeWontMock = [
  "/manifest.json",
  "/window/testing-library.js",
  "/main.",
];

const fileExtensionsWeWontMockWithMsw = [
  ".css",
  ".js",
  ".png",
  ".jpg",
  ".jpeg",
  ".gif",
  ".woff",
  ".woff2",
  ".ttf",
  ".eot",
  ".otf",
  ".mp4",
  ".ogg",
  ".mp3",
  ".wav",
  ".aac",
  ".wma",
  ".m4a",
  ".flv",
  ".avi",
  ".mov",
  ".wmv",
  ".mpg",
  ".mpeg",
  ".svg",
  ".ico",
  ".ts",
  ".tsx",
];

export function DevTools({ appSlot }: DevToolsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [delay, setDelay, delayChanged] = useDevToolsState("delay", 0);
  // TODO: use setCustomResponses which is also returned
  const [customResponses] = useDevToolsState<CustomResponse[]>(
    "customResponses",
    []
  );

  useKeyPress("Escape", () => setIsOpen(false));

  function removeDevtoolsSettingsFromLocalStorage() {
    localStorage.removeItem("delay");
  }

  function logUnhandledRequests({ url }: Request) {
    // Suppress warnings for calls we're not going to mock
    if (fileExtensionsWeWontMockWithMsw.some((ext) => url.includes(ext))) {
      return; // If the request URL ends with a file extension we won't mock, ignore it.
    }
    if (url.startsWith("https://sdk.split.io/api/mySegments")) return; // If this call is mocked Split throws errors, so just ignore it.
    if (url.startsWith("https://vector.hereapi.com")) return; //  Called by Google maps, and we're not mocking maps at this time.
    if (url.startsWith("https://assets.vector.hereapi.com")) return; //  Called by Google maps, and we're not mocking maps at this time.
    if (url.includes("us.sentry.io")) return; //  Called by Sentry, and we're not mocking Sentry at this time.
    if (url.startsWith(env.VITE_COGNITO_SIGN_IN_URL + "/node_modules/")) return; // If the request is to node_modules, ignore it.
    if (url.startsWith("http://localhost:3000/src/assets/")) return; // If the request is to src/assets, ignore it.
    if (filesWeWontMock.includes(url)) return;
    // eslint-disable-next-line no-console
    console.warn("Un-mocked call: " + url);
  }

  const httpSettings: HttpSettings = {
    requestHandlers,
    startOptions: {
      quiet: true,
      onUnhandledRequest: logUnhandledRequests,
    },
  };

  const isReady = useWorker(httpSettings, {
    delay,
    customResponses,
  });

  if (!isReady) return <p>Initializing...</p>;

  return (
    <>
      {/* Wrap app in ErrorBoundary so DevTools continue to display upon error */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/* TODO: Consider passing a key to force the app to completely reinitialize when some settings change. */}
        {appSlot}
      </ErrorBoundary>

      <section
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          backgroundColor: "white",
          border: "solid 1px gray",
          padding: isOpen ? 16 : 0,
          /** This value was set due to maps library because it was hiding devtools component */
          zIndex: 400,
        }}
      >
        {isOpen ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Button
              variant="contained"
              style={{ position: "fixed", right: 16 }}
              onClick={() => setIsOpen(false)}
            >
              X
            </Button>

            <TextField
              style={{ marginTop: 50 }}
              label="Mock API Delay (ms)"
              type="number"
              value={delay}
              error={delayChanged}
              onChange={(e) => setDelay(parseInt(e.target.value))}
            />

            <Button
              style={{ display: "block" }}
              onClick={() => {
                removeDevtoolsSettingsFromLocalStorage();
                window.location.reload();
              }}
            >
              Clear Settings
            </Button>
          </form>
        ) : (
          <Button onClick={() => setIsOpen(true)}>
            <HomeRepairServiceIcon />
          </Button>
        )}
      </section>
    </>
  );
}

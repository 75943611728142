import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, useState } from "react";
import { debounce } from "remeda";
import { useTheme } from "@mui/material";

type Props = {
  searchTerm: string;
  onValueChange: (value: string) => void;
  placeholder: string;
};

export const SearchBox = ({
  searchTerm,
  onValueChange,
  placeholder,
}: Props) => {
  const theme = useTheme();
  const [search, setSearch] = useState(searchTerm);
  const debouncedSearch = debounce(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onValueChange(event.target.value),
    { waitMs: 500 }
  );
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      sx={{ flex: "0 35%" }}
      type="search"
      placeholder={placeholder}
      value={search}
      onChange={(event) => {
        setSearch(event.target.value);
        debouncedSearch.call(event);
      }}
      InputProps={{
        sx: { backgroundColor: theme.palette.background.paper },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "@hello-pangea/dnd";
import { Box, Typography } from "@mui/material";
import { Cell, Row } from "@tanstack/react-table";
import { DraggableSelectionCounter } from "./old-table/DraggableSelectionCounter";
import DragIcon from "./icons/DragIcon";
import { TableShipment } from "./old-table/ShipmentsTableBase";

interface DragMultipleShipmentsProps {
  row: Row<TableShipment>;
  cell: Cell<TableShipment, any>;
}

export const DragMultipleShipments = ({
  row,
  cell,
}: DragMultipleShipmentsProps) => {
  const selectedCount = Object.keys(
    cell.getContext().table.getSelectedRowModel().rowsById
  ).length;
  return (
    <Draggable key={row.id} draggableId={row.id} index={row.index}>
      {(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot
      ) => (
        <Box
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          sx={draggableProvided.draggableProps.style}
          height="25px"
        >
          {!snapshot.isDragging && <DragIcon />}
          {snapshot.isDragging && selectedCount > 1 && (
            <DraggableSelectionCounter>
              {selectedCount}
            </DraggableSelectionCounter>
          )}
          {snapshot.isDragging && (
            <Box
              width="400px"
              display="flex"
              alignItems="center"
              bgcolor="white"
              color="black"
              border={5}
              borderColor="lightblue"
              padding={1}
              boxShadow={
                selectedCount > 1
                  ? "2px 2px 0px 0px white, 5px 5px 0px 0px lightblue"
                  : ""
              }
            >
              <DragIcon />
              <Typography>{row.original.consignee?.name}</Typography>
              <Typography marginLeft="auto">
                {row.original.proNumber}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Draggable>
  );
};

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl as FormControlMUI,
  IconButton,
  MenuItem,
  styled,
  TextField,
  Dialog,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FlexRow } from "../../../components/shared/layout/Flex";
import { Title } from "../../../components/shared/Title";
import { useDoorNumbersBySicIdOld } from "../../../hooks/react-query/useDoors";
import {
  MergeTrapRequest,
  useMergeTrap,
  useGetUnplannedTrapsSummary,
} from "../../../hooks/react-query/preplanning/useTraps";
import { Trap } from "../../../types/planning/trap.type";
import { dateToInt } from "../../../utils/dateTimeHelper";
import Loading from "../../../components/shared/layout/Loading";
import { toast } from "../../../utils/snackbarHelper";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { toastMessage } from "../../../constants/strings";
import UniqueShipmentIdCountMessage from "../../../components/shared/UniqueShipmentIdCountMessage";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { SummaryContext } from "./ActionsMenuContext";
import { EquipmentSelection } from "./EquipmentSelection";
import { EquipmentSelectionOld } from "./EquipmentSelectionOld";

const FormControl = styled(FormControlMUI)(() => ({
  width: "100%",
  marginTop: "1rem",
}));

type MergeTrapsDialogOldProps = {
  handleClose: () => void;
  visible: boolean;
  selectedTraps: SummaryContext[];
  clearSelection?: () => void;
};

type MergeTrapsDialogOldState = {
  /** Trap ids to be merged */
  trapIdsToMerge: (number | null)[];
  /** Trap id of the `existing` trap */
  mergeIntoTrapId: number | null;
  /** Trap title */
  title: string;
  /** Trap trailer equipment id */
  trailerEquipmentId: number | null;
  /** Trap straight truck equipment id */
  straightTruckEquipmentId: number | null;
  /** Trap door id */
  doorId: number | null;
};

export const MergeTrapsDialogOld = ({
  handleClose,
  visible,
  selectedTraps,
  clearSelection,
}: MergeTrapsDialogOldProps) => {
  const editGuardrailsPhase2Enabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-2-client"
  );
  const [serviceCenter] = useSelectedServiceCenter();

  const formatTrapIdsToMerge = useCallback((): (number | null)[] => {
    const trapIds = selectedTraps
      .filter((trap) => trap.type === "trap")
      .map((trap) => Number(trap.id.toString().replace("TRAP-", "")));

    if (trapIds.length === 0) return [null, null];

    if (trapIds.length === 1) return [...trapIds, null];

    return trapIds;
  }, [selectedTraps]);

  const initialForm: MergeTrapsDialogOldState = useMemo(
    () => ({
      trapIdsToMerge: formatTrapIdsToMerge(),
      mergeIntoTrapId: null,
      title: "",
      name: "",
      trailerEquipmentId: null,
      straightTruckEquipmentId: null,
      doorId: null,
    }),
    [formatTrapIdsToMerge]
  );

  const [formData, setFormData] =
    useState<MergeTrapsDialogOldState>(initialForm);

  const [date] = useDateSearchParamOrFallbackToToday();

  useEffect(
    function refreshFormWhenPropsChange() {
      setFormData(initialForm);
    },
    [initialForm]
  );

  // Queries
  const doorNumbersQuery = useDoorNumbersBySicIdOld(serviceCenter.id);
  const trapHeaderQuery = useGetUnplannedTrapsSummary(serviceCenter.id);

  const handleSuccess = (_: Trap, variables: MergeTrapRequest) => {
    clearSelection?.();
    handleClose();
    setFormData(initialForm);
    const targetTrap = trapHeaderQuery.data?.find(
      (trap) => trap.id === variables.mergeIntoTrapId
    );
    toast(
      toastMessage.inbound.mergeTraps.success(
        variables.trapIdsToMerge.length,
        targetTrap?.name ?? "",
        targetTrap?.title ?? ""
      ),
      {
        variant: "success",
      }
    );
  };

  const mergeTrap = useMergeTrap(handleSuccess);

  const handleAddTrap = () => {
    setFormData((prev) => {
      const clone = { ...prev };
      clone.trapIdsToMerge.push(null);
      return clone;
    });
  };

  const handleDeleteTrap = (idx: number) => {
    setFormData((prev) => {
      const clone = { ...prev };
      clone.trapIdsToMerge.splice(idx, 1);
      return clone;
    });
  };

  const handleMergeTrap = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const targetTrap = trapHeaderQuery.data?.find(
      (trap) => trap.id === formData.mergeIntoTrapId
    );

    if (!targetTrap) {
      throw new Error("Selected trap id not found in trapHeaderQuery");
    }

    mergeTrap.mutate({
      doorId: formData.doorId,
      trailerEquipmentId: formData.trailerEquipmentId,
      straightTruckEquipmentId: formData.straightTruckEquipmentId,
      mergeIntoTrapId: formData.mergeIntoTrapId,
      name: targetTrap.name,
      trapIdsToMerge: formData.trapIdsToMerge,
      sicId: serviceCenter.id,
      date: dateToInt(date),
      customName: null,
    });
  };

  if (!trapHeaderQuery.data || !doorNumbersQuery.data) {
    return (
      <Dialog open={visible}>
        <DialogTitle>Loading...</DialogTitle>
        <DialogContent>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }

  const availableTraps = trapHeaderQuery.data.filter(
    (trap) => !formData.trapIdsToMerge.includes(+trap.id)
  );

  const mergeIntoTraps = trapHeaderQuery.data.filter((trap) =>
    formData.trapIdsToMerge.includes(+trap.id)
  );

  return (
    <Dialog open={visible}>
      <form onSubmit={handleMergeTrap}>
        <DialogTitle sx={{ fontWeight: "bold" }}> Merge Traps</DialogTitle>
        <DialogContent sx={{ width: "60vw", maxWidth: "500px" }}>
          <UniqueShipmentIdCountMessage shipments={selectedTraps} />
          <Title weight="bold">Select Traps to Merge</Title>
          {formData.trapIdsToMerge.map((trap, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <FlexRow key={idx}>
              <FormControl>
                <TextField
                  select
                  value={trap || ""}
                  required
                  onChange={(e) => {
                    setFormData((prev) => {
                      const clone = { ...prev };
                      if (clone.trapIdsToMerge[idx] === prev.mergeIntoTrapId) {
                        clone.mergeIntoTrapId = null;
                      }
                      clone.trapIdsToMerge[idx] = +e.target.value;
                      return clone;
                    });
                  }}
                  label="Trap"
                >
                  {trapHeaderQuery.data.map((trap) => (
                    <MenuItem
                      disabled={!availableTraps.includes(trap)}
                      key={trap.id}
                      value={trap.id}
                    >
                      {`${trap.title} (${trap.name})`}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              {formData.trapIdsToMerge.length > 2 && (
                <IconButton
                  sx={{
                    width: "36px",
                    height: "36px",
                    alignSelf: "end",
                    marginLeft: "1rem",
                  }}
                  aria-label="Delete trap"
                  color="primary"
                  component="label"
                  onClick={() => {
                    handleDeleteTrap(idx);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </FlexRow>
          ))}
          {availableTraps.length > 0 && (
            <Button onClick={handleAddTrap}>+ Add a Trap</Button>
          )}
          <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
          <Title weight="bold">Merge Traps Into</Title>
          <FlexRow>
            <FormControl>
              <TextField
                required
                select
                value={formData.mergeIntoTrapId || ""}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    mergeIntoTrapId: +e.target.value,
                  }));
                }}
                label="Trap"
              >
                {mergeIntoTraps.map((trap) => (
                  <MenuItem key={`${trap.id}_existing`} value={trap.id}>
                    {`${trap.title} (${trap.name})`}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </FlexRow>
          <FlexRow>
            <FormControl>
              <TextField
                select
                value={formData.doorId || ""}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    doorId: +e.target.value,
                  }));
                }}
                label="Door Assignment"
              >
                <MenuItem>Unassigned</MenuItem>
                {doorNumbersQuery.data.map((door) => (
                  <MenuItem key={door} value={door}>
                    {door}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </FlexRow>
          <FlexRow>
            {editGuardrailsPhase2Enabled ? (
              <EquipmentSelection
                trailerId={formData.trailerEquipmentId}
                tractorId={null}
                straightTruckId={formData.straightTruckEquipmentId}
                setTrailerValue={(newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    trailerEquipmentId: newValue?.id || null,
                  }))
                }
                setStraightTruckValue={(newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    straightTruckEquipmentId: newValue?.id || null,
                  }))
                }
                setTractorValue={(newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    tractorEquipmentId: newValue?.id || null,
                  }))
                }
              />
            ) : (
              <EquipmentSelectionOld
                trailerId={formData.trailerEquipmentId}
                tractorId={null}
                straightTruckId={formData.straightTruckEquipmentId}
                setTrailerValue={(newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    trailerEquipmentId: newValue?.id || null,
                  }))
                }
                setStraightTruckValue={(newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    straightTruckEquipmentId: newValue?.id || null,
                  }))
                }
                setTractorValue={(newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    tractorEquipmentId: newValue?.id || null,
                  }))
                }
              />
            )}
          </FlexRow>
        </DialogContent>
        <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
          <Button
            variant="text"
            onClick={() => {
              setFormData(initialForm);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            isLoading={mergeTrap.isPending}
            loadingLabel="Merging..."
          >
            Merge
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

import { Box } from "@mui/material";
import { PropsWithChildren, createContext } from "react";

interface TotalizerContextProps<T> {
  data: T[];
}

export const TotalizerContext =
  createContext<TotalizerContextProps<any> | null>(null);

type TotalizerRootProps<T> = {
  data: T[];
};

export const TotalizerRoot = <T,>({
  children,
  data,
}: PropsWithChildren<TotalizerRootProps<T>>) => (
  <TotalizerContext.Provider value={{ data }}>
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: "24px",
        marginBottom: "1rem",
      }}
    >
      {children}
    </Box>
  </TotalizerContext.Provider>
);

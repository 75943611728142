import dayjs from "dayjs";
import { Notification } from "../../hooks/react-query/dispatch/useNotifications";

export const mockNotifications = {
  default: [
    {
      id: 1,
      sentAt: dayjs().subtract(15, "minutes"),
      body: null,
      routeId: 1,
      stopId: 1,
      type: "DRIVER_LUNCH_REQUIRED",
      action: { text: "Sent a message", url: "" },
      read: false,
    },
    {
      id: 2,
      sentAt: dayjs().subtract(30, "minutes"),
      body: null,
      routeId: 7,
      stopId: null,
      type: "DRIVER_OVERTIME",
      action: { text: "Sent a message", url: "" },
      read: false,
    },
    {
      id: 3,
      sentAt: dayjs().subtract(45, "minutes"),
      body: null,
      routeId: 5,
      stopId: 11,
      type: "DRIVER_RESCHEDULED_STOP",
      action: { text: "Follow up", url: "" },
      read: true,
    },
    {
      id: 4,
      sentAt: dayjs().subtract(60, "minutes"),
      body: null,
      routeId: 6,
      stopId: 8,
      type: "DRIVER_STOP_INCOMPLETE",
      action: { text: "Sent a message", url: "" },
      read: true,
    },
    {
      id: 5,
      sentAt: dayjs().subtract(75, "minutes"),
      body: "<a href=''>New Stop</a> added to the board.",
      routeId: null,
      stopId: 4,
      type: "CUSTOM",
      action: { text: "Assign stop", url: "" },
      read: true,
    },
  ],
  empty: [],
} as const satisfies Record<string, Notification[]>;

import { Fragment, useState } from "react";
import { Business } from "@mui/icons-material";
import CollapsibleSectionItem from "../../shared/AccordionShipmentItem/CollapsibleSectionItem";
import { AccordionWithContentAndActionBar } from "../AccordionWithContentAndActionBar";
import { useSections } from "../../../../hooks/react-query/preplanning/useSections";
import Loading from "../../../../components/shared/layout/Loading";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";

export const Sections = () => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const { data: sections = [], isLoading } = useSections(serviceCenter.id);

  if (isLoading) return <Loading label="sidebar sections" />;

  return (
    <AccordionWithContentAndActionBar
      onChange={(value: boolean) => setIsExpanded(value)}
      title={`Sections (${sections.length})`}
      icon={<Business />}
      actionButtons={[]}
    >
      {sections.map((item) => {
        const {
          id,
          title,
          shipmentIds,
          shipmentsCount,
          shipmentsPallets,
          shipmentsPieces,
          shipmentsWeight,
          shipmentsWeightUnit,
        } = item;
        return (
          <Fragment key={id}>
            <CollapsibleSectionItem
              id={id}
              title={title}
              name={null}
              shipmentIds={shipmentIds}
              shipmentsCount={shipmentsCount}
              shipmentsPallets={shipmentsPallets}
              shipmentsPieces={shipmentsPieces}
              shipmentsWeight={shipmentsWeight}
              shipmentsWeightUnit={shipmentsWeightUnit || "lbs"}
              section="sections"
              isDropDisabled={!isExpanded}
              isAvailableForMassage={true}
              sortBy="presetRoute"
              serviceCenterId={serviceCenter.id}
              planDate={selectedDate}
              isEditingAllowed={isEditingAllowed}
            />
          </Fragment>
        );
      })}
    </AccordionWithContentAndActionBar>
  );
};

import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Typography,
  Menu,
  Fade,
  ListItem,
  MenuItem,
  ListItemIcon,
  Link,
} from "@mui/material";
import { alpha, Stack } from "@mui/system";
import { useState } from "react";
import { Link as RouterLink, generatePath, useMatch } from "react-router-dom";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import {
  Department,
  DepartmentChildRoute,
  HOME_URL_KEY,
  useDepartments,
} from "./useDepartments";

type DepartmentSelectProps = {
  value: Department;
};

type DepartmentMenuItemProps = {
  isSelected: boolean;
  department: Department;
  onChange: () => void;
};

const DepartmentMenuItem = ({
  isSelected,
  department,
  onChange,
}: DepartmentMenuItemProps) => {
  const [serviceCenter] = useSelectedServiceCenter();

  return (
    <MenuItem
      key={department.urlKey}
      disableRipple
      component={RouterLink}
      to={
        // If the department is the home department, the URL should be the root.
        department.urlKey === HOME_URL_KEY
          ? HOME_URL_KEY
          : // If the department shows the service center select, the URL should include the service center code.
            `${HOME_URL_KEY}${department.urlKey}/${department.showServiceCenterSelect ? serviceCenter.code : ""}`
      }
      sx={{
        px: 2,
        py: 1.5,
        gap: 0.5,
        bgcolor: isSelected
          ? (theme) => alpha(theme.palette.primary.light, 0.08)
          : undefined,
        ":hover": {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.08),
        },
      }}
      onClick={() => onChange()}
    >
      <ListItemIcon>
        <Stack
          sx={{
            p: 0.5,
            bgcolor: department.accentColor,
            borderRadius: "50%",
          }}
        >
          {department.icon}
        </Stack>
      </ListItemIcon>
      <Typography
        fontSize={20}
        fontWeight={700}
        letterSpacing={0.8}
        textTransform="uppercase"
        lineHeight="normal"
        pr={4}
        sx={{ color: department.accentColor }}
      >
        {department.label}
      </Typography>
    </MenuItem>
  );
};

export function DepartmentSelect({ value }: DepartmentSelectProps) {
  const departments = useDepartments();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonId = "department-select-button";
  const menuId = "department-select-menu";

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="Select a department"
        onClick={handleClick}
        disableRipple
        sx={{
          color: (theme) => alpha(theme.palette.primary.contrastText, 0.56),
          ":hover": {
            color: (theme) => theme.palette.primary.contrastText,
          },
          textTransform: "uppercase",
          height: 64,
          p: 2,
        }}
        startIcon={
          <Stack
            sx={{
              p: 0.5,
              bgcolor: value.accentColor,
              borderRadius: "50%",
            }}
          >
            {value.icon}
          </Stack>
        }
        endIcon={
          <ArrowDropDown
            sx={{
              color: (theme) => theme.palette.action.active,
            }}
          />
        }
      >
        <Typography
          fontSize={20}
          fontWeight={700}
          letterSpacing={0.8}
          color={value.accentColor}
        >
          {value.label}
        </Typography>
      </Button>
      <Menu
        id={menuId}
        MenuListProps={{ "aria-labelledby": buttonId }}
        anchorEl={anchorEl}
        open={open}
        TransitionComponent={Fade}
        onClose={() => setAnchorEl(null)}
      >
        <ListItem>Switch to</ListItem>

        {departments
          .filter((department) => department.enabled)
          .map((department) => (
            <DepartmentMenuItem
              key={department.urlKey}
              isSelected={value.urlKey === department.urlKey}
              department={department}
              onChange={() => setAnchorEl(null)}
            />
          ))}
      </Menu>
    </>
  );
}

export function DepartmentChildLink({ tab }: { tab: DepartmentChildRoute }) {
  const match = useMatch(`${tab.route}/*`);
  const [serviceCenter] = useSelectedServiceCenter();

  return (
    <Link
      key={tab.route}
      component={RouterLink}
      to={generatePath(tab.route, {
        serviceCenterCode: serviceCenter.code,
      })}
      underline="none"
      sx={{
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "150%",
        letterSpacing: 0.15,
        color: (theme) =>
          match ? theme.palette.primary.main : theme.palette.text.secondary,
        ":hover": {
          color: (theme) => theme.palette.primary.main,
        },
      }}
    >
      {tab.label}
    </Link>
  );
}

// eslint-disable-next-line no-restricted-imports
import { memo, useState } from "react";

import {
  PrintResult,
  usePrintBulkReport,
} from "../../hooks/react-query/usePrint";
import { Trap } from "../../types/planning/trap.type";
import { toastMessage } from "../../constants/strings";
import { toast } from "../../utils/snackbarHelper";
import { buttonStyle } from "../../pages/inbound/prePlanning/actionbar/StyledMenuButton";
import { LoadingButton } from "./LoadingButton";
import PrintIcon from "./icons/PrintIcon";

interface BulkPrintButtonProps {
  selectedTraps: Trap[];
  serviceCenterId: number;
  planDate: string;
  loadingTrapIds: number[];
  setLoadingTrapIds?: (ids: number[]) => void;
}

const BulkPrintButton = memo(
  ({
    selectedTraps,
    serviceCenterId,
    loadingTrapIds,
    setLoadingTrapIds,
    planDate,
  }: BulkPrintButtonProps) => {
    const [bulkPrintingIds, setBulkPrintingIds] = useState<number[]>([]);

    const onUnloadTrapReportSuccess = (result: PrintResult) => {
      if (result.state === "Processed") {
        window.open(result.url, "_blank");
      }
    };

    const onUnloadTrapReportSettled = (trapIds: number[]) => {
      setBulkPrintingIds(bulkPrintingIds.filter((id) => !trapIds.includes(id)));
      if (setLoadingTrapIds) {
        setLoadingTrapIds(loadingTrapIds.filter((id) => !trapIds.includes(id)));
      }
    };

    const { mutate: printBulkReport } = usePrintBulkReport(
      onUnloadTrapReportSuccess,
      onUnloadTrapReportSettled
    );

    const handleBulkPrint = () => {
      const selectedTrapIds = selectedTraps
        .filter((trap) => trap.bills > 0)
        .map((trap) => trap.id);

      if (selectedTrapIds.length === 0) {
        toast(toastMessage.inbound.print.selectAtLeastOneTrap, {
          variant: "warning",
        });
        return;
      }

      printBulkReport({
        trapUnload: { trapIds: selectedTrapIds },
        sicId: serviceCenterId,
        planDate,
      });
      setBulkPrintingIds(selectedTrapIds);

      if (setLoadingTrapIds) {
        setLoadingTrapIds(selectedTrapIds);
      }
    };

    return (
      <LoadingButton
        sx={buttonStyle}
        startIcon={<PrintIcon />}
        isLoading={bulkPrintingIds.length > 0}
        variant="outlined"
        loadingLabel="Generating..."
        onClick={handleBulkPrint}
      >
        Print
      </LoadingButton>
    );
  }
);

BulkPrintButton.displayName = "BulkPrintButton";

export default BulkPrintButton;

import { z } from "zod";
import { tagSchema, tagsSchema } from "../freight/tag.type";
import {
  driverSchema,
  equipmentSchema,
} from "../../services/prePlanningService.types";
import { routeSchema } from "../route.type";
import { rowErrorSchema } from "../../components/shared/table/table.types";
import { shipmentWithSequenceSchema } from "./shipmentSeq.type";

export const planSummaryTypeSchema = z.enum([
  "delivery_route",
  "delivery_trap",
]);

const filterPlanTypeSchema = z.enum(["delivery_route", "delivery_trap", "All"]);
export const filterTypeSchema = z.array(filterPlanTypeSchema);

export type FilterPlanType = z.infer<typeof filterTypeSchema>;
export type PlanType = z.infer<typeof planSummaryTypeSchema>;

const autoSequenceStatusSchema = z
  .enum(["manual", "processing", "processed", "error"])
  .nullable();

const planSummarySchema = z.object({
  type: planSummaryTypeSchema,
  id: z.number(),
  sic: z.string(),
  door: z.number(),
  routeType: z.enum(["Static", "Manual"]),
  name: z.string(),
  title: z.string(),
  status: z.string(),
  shipmentsPieces: z.number(),
  shipmentsCount: z.number(),
  shipmentsPallets: z.number(),
  shipmentsWeight: z.number(),
  shipmentsWeightUnit: z.enum(["lbs"]),
  zone: z.string(),
  date: z.date(),
  endPoint: z.string().nullable(),
  startTime: z.date().nullable(),
  endTime: z.date().nullable(),
  tractor: equipmentSchema.nullable(),
  trailer: equipmentSchema.nullable(),
  straightTruck: equipmentSchema.nullable(),
  driver: driverSchema.nullable(),
  shipmentIds: z.array(z.number()),
  shipmentsTags: tagsSchema,
  hasSearchTerm: z.boolean(),
  presetRouteId: z.number(),
  isCommitted: z.boolean().nullable(),
  location: z.string(),
  isManuallySequenced: z.boolean(),
  isAvailableForMassage: z.boolean(),
  sequenceStatus: autoSequenceStatusSchema.nullish(),
  canEditStartTime: z.boolean(),
  isBlockedFromTransition: z.boolean(),
});

export const planSummaryResponseSchema = z.array(planSummarySchema);
export type PlanSummary = z.infer<typeof planSummarySchema>;

export const deliveryRouteSequenceStatusSchema = z.object({
  id: z.number(),
  sequenceStatus: autoSequenceStatusSchema,
});

const planTableFormatSchema = z.object({
  type: planSummaryTypeSchema,
  id: z.number(),
  date: z.number(),
  door: z.number(),
  sicId: z.number(),
  serviceCenterCode: z.string(),
  name: z.string(),
  startTime: z.date().nullable(),
  endTime: z.date().nullable(),
  routeEnd: z.string().nullable(),
  isCommitted: z.boolean().nullable(),
  routeName: z.string(),
  presetRouteId: z.number(),
  location: z.string(),
  zones: z.array(z.string()),
  driver: driverSchema.nullable(),
  tractor: equipmentSchema.nullable(),
  trailer: equipmentSchema.nullable(),
  straightTruck: equipmentSchema.nullable(),
  bills: z.number(),
  pallets: z.number(),
  pieces: z.number(),
  weight: z.number(),
  weightUnit: z.string(),
  tags: z.array(tagSchema),
  shipmentIds: z.array(z.number()),
  shipments: z.array(shipmentWithSequenceSchema),
  route: routeSchema,
  status: z.string(),
  isManuallySequenced: z.boolean(),
  isAvailableForMassage: z.boolean(),
  sequenceStatus: autoSequenceStatusSchema.optional(),
  error: rowErrorSchema.optional().nullable(),
  canEditStartTime: z.boolean(),
  isBlockedFromTransition: z.boolean(),
  hasSearchTerm: z.boolean(),
});

export type PlanTableFormat = z.infer<typeof planTableFormatSchema>;

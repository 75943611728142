import { PublishResponse } from "../../hooks/react-query/linehaul/usePublishMasterSchedule";
import { mockLinehaulCarriers } from "./linehaulCarriers.mocks";

export const publishResponseMocks = {
  empty: { entriesAffected: 0, carriers: [] },
  default: {
    entriesAffected: 10,
    carriers: mockLinehaulCarriers.default,
  },
} as const satisfies Record<string, PublishResponse>;

import { useEffect, useRef, useState } from "react";
import { setupWorker } from "msw/browser";
import { HandlerConfig, HttpSettings } from "./types";

/** Start Mock Service Worker with the provided config and return true when ready. */
export const useWorker = (
  { startOptions, requestHandlers }: HttpSettings,
  config: HandlerConfig
) => {
  const isStarted = useRef(false);
  const [isReady, setIsReady] = useState(false);

  config.scenario = new URLSearchParams(window.location.search).get("scenario");

  const workerRef = useRef(setupWorker(...requestHandlers(config)));
  // Store the previous config in a ref so we can compare it to the current config
  // This way, we know if the config has changed.
  const prevConfig = useRef<HandlerConfig | undefined>(undefined);

  if (!isStarted.current) {
    isStarted.current = true;
    workerRef.current.start(startOptions).then(() => setIsReady(true));
  }

  useEffect(() => {
    // This only needs to re-run if the config has changed so the new settings apply.
    if (prevConfig.current === config) return;
    prevConfig.current === config;
    workerRef.current.use(...requestHandlers(config));
  }, [config, requestHandlers, startOptions]);

  return isReady;
};

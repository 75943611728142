import { IconButton, Tooltip } from "@mui/material";
import { Link } from "@mui/icons-material";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";

export const CopyLinkIconButton = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const copyRoutesOrTrapsLink = () => {
    searchParams.delete("selection");
    const newSearch = new URLSearchParams(searchParams);
    const search = newSearch.toString();
    const fullUrl = `${window.location.protocol}//${window.location.host}${location.pathname}${search ? `?${search}` : ""}`;

    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        toast(toastMessage.inbound.copyLink.success, {
          variant: "success",
        });
      })
      .catch(() => {
        toast(toastMessage.inbound.copyLink.error, {
          variant: "error",
        });
      });
  };

  return (
    <Tooltip title="Copy to clipboard">
      <IconButton onClick={copyRoutesOrTrapsLink}>
        <Link />
      </IconButton>
    </Tooltip>
  );
};

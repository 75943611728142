import { Box, IconButton, Tooltip, Typography, colors } from "@mui/material";
import { Edit, Error, Print, SwapVert, Warning } from "@mui/icons-material";
import { CellContext, ColumnDef, Getter, Row } from "@tanstack/react-table";
import { shipmentTagsColumn } from "../../../utils/tableColumns/commons";
import { routeTrapType } from "../../../constants/RouteTrapTypes";
import { sortNumbersAsc, sortTimesAsc } from "../../../utils/sortComparator";
import { formatRouteStartEndTimeTableField } from "../../../utils/tableFormat";
import Loading from "../../../components/shared/layout/Loading";
import { FlexRow } from "../../../components/shared/layout/Flex";
import { DASH_PLACEHOLDER } from "../../../constants/dashPlaceholder";
import { LightTooltip } from "../../../components/shared/LightTooltip";

import {
  ErrorMap,
  RowError,
} from "../../../components/shared/table/table.types";
import {
  getPossiblyInactiveCellStatus,
  PossiblyInactiveCell,
} from "../../../components/shared/old-table/PossiblyInactiveCell";
import { dashIfEmpty } from "../../../constants/utils";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import { ChangeableStatusTag } from "./ChangeableStatusTag";
import { ReadOnlyStatusTag } from "./ReadOnlyStatusTag";

type RowNameProps = {
  row: Row<PlanTableFormat>;
  autoSequencingRouteIds: number[];
  isLoadingPrintIds: number[];
  getValue: Getter<unknown>;
  rowError?: RowError;
};

type ColumnsProps = {
  onPrintRow?: (row: PlanTableFormat) => void;
  onEditRow?: (row: PlanTableFormat) => void;
  timeZone: string;
  autoSequencingRouteIds: number[];
  rowErrors: ErrorMap;
  isEditable: boolean;
  isLoadingPrintIds: number[];
  printCityCardLoadEnabled: boolean;
};

type RouteNameProps = {
  name: string;
  isManuallySequenced: boolean;
  hasShipmentsWithInvalidGeocode: boolean;
  isLoading: boolean;
  loadingText: string;
};

const RouteName = ({
  name,
  isManuallySequenced,
  hasShipmentsWithInvalidGeocode,
  isLoading,
  loadingText,
}: RouteNameProps) => {
  const RouteNameBox = (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      gap="4px"
      width={90}
    >
      {isLoading && (
        <Box>
          <Loading size={24} />
        </Box>
      )}
      {hasShipmentsWithInvalidGeocode && <Warning color="warning" />}
      {`${loadingText} ${name}`}
      {isManuallySequenced && (
        <LightTooltip title="Manually Sequenced">
          <SwapVert sx={{ marginLeft: "4px", color: colors.grey[600] }} />
        </LightTooltip>
      )}
    </Box>
  );
  return hasShipmentsWithInvalidGeocode ? (
    <Tooltip
      title="Some shipments have an invalid address"
      aria-label={`Route ${name} has shipments with an invalid address`}
    >
      {RouteNameBox}
    </Tooltip>
  ) : (
    RouteNameBox
  );
};

const FirstColumn = ({
  row,
  autoSequencingRouteIds,
  isLoadingPrintIds,
  getValue,
  rowError,
}: RowNameProps) => {
  const hasShipmentsWithInvalidGeocode =
    row.original.type === "delivery_route" &&
    row.original.shipments.some((shipment) => !shipment.lat || !shipment.lng);

  const isAutoSequencing = autoSequencingRouteIds.includes(row.original.id);
  const isGeneratingPrint = isLoadingPrintIds.includes(row.original.id);

  return rowError ? (
    <Tooltip title={rowError.message}>
      <FlexRow style={{ alignItems: "center", gap: "2px" }}>
        <Error color="error" fontSize="small" />
        <Typography color={colors.red[700]}>{getValue() as string}</Typography>
      </FlexRow>
    </Tooltip>
  ) : (
    <RouteName
      name={getValue() as string}
      isManuallySequenced={row.original.isManuallySequenced}
      hasShipmentsWithInvalidGeocode={hasShipmentsWithInvalidGeocode}
      isLoading={isAutoSequencing || isGeneratingPrint}
      loadingText={
        isAutoSequencing
          ? "Auto Sequencing... "
          : isGeneratingPrint
            ? "Generating..."
            : ""
      }
    />
  );
};

const oldPlanColumns = ({
  onEditRow,
  onPrintRow,
  timeZone,
  autoSequencingRouteIds,
  rowErrors,
  isEditable,
  isLoadingPrintIds,
  printCityCardLoadEnabled = false,
}: ColumnsProps): ColumnDef<PlanTableFormat>[] => {
  const columns: ColumnDef<PlanTableFormat>[] = [
    {
      accessorKey: "isCommitted",
      header: "",
      cell: ({ row }: CellContext<PlanTableFormat, any>) =>
        row.original.isCommitted ? (
          <Box
            sx={{
              width: 0,
              height: "34px",
              borderWidth: "4px",
              borderStyle: "solid",
              borderColor: "#EB8800",
            }}
          />
        ) : null,
    },
    {
      accessorKey: "name",
      header: "Route",
      cell: ({ getValue, row }) => (
        <FirstColumn
          row={row}
          getValue={getValue}
          autoSequencingRouteIds={autoSequencingRouteIds}
          isLoadingPrintIds={isLoadingPrintIds}
          rowError={rowErrors.get(Number(row.id))}
        />
      ),
    },
    {
      accessorKey: "type",
      accessorFn: ({ type }) => routeTrapType[type],
      header: "Type",
    },
    {
      accessorKey: "zones",
      accessorFn: ({ zones }) => (zones.length > 0 ? zones : DASH_PLACEHOLDER),
      header: "Zone",
    },
    {
      accessorKey: "door",
      header: "Door",
      accessorFn: ({ door }) => (door === 0 ? DASH_PLACEHOLDER : door),
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.door, rowB.original.door),
      sortDescFirst: false,
    },
    {
      accessorKey: "startTime",
      accessorFn: ({ startTime }) =>
        formatRouteStartEndTimeTableField(startTime, timeZone),
      header: "Start Time",
      sortingFn: (rowA, rowB) =>
        sortTimesAsc(rowA.original.startTime, rowB.original.startTime),
      sortDescFirst: false,
    },
    {
      accessorKey: "bills",
      header: "Bills",
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.bills, rowB.original.bills),
      sortDescFirst: false,
    },
    {
      accessorKey: "ptsPcs",
      accessorFn: ({ pallets, pieces }) => `${pallets} | ${pieces}`,
      header: "Plts | Pcs",
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.pallets, rowB.original.pallets),
      sortDescFirst: false,
    },
    {
      accessorKey: "weight",
      header: "Weight",
      accessorFn: ({ weight, weightUnit }) =>
        `${weight.toLocaleString()} ${weightUnit}`,
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.weight, rowB.original.weight),
    },
    {
      accessorKey: "driver",
      accessorFn: ({ driver }) => dashIfEmpty(driver?.name),
      header: "Driver",
      cell: ({ getValue, row }) => {
        const driver = row.original.driver;
        return (
          <PossiblyInactiveCell
            label={getValue<string>()}
            isEditable={isEditable}
            status={getPossiblyInactiveCellStatus({
              isActive: driver?.isAvailable,
              idPresentInParent: !!driver,
            })}
            recordName="driver"
            remediation={() => onEditRow?.(row.original)}
          />
        );
      },
    },
    {
      accessorKey: "tractor",
      accessorFn: ({ tractor }) => dashIfEmpty(tractor?.name),
      header: "Tractor #",
      cell: ({ getValue, row }) => {
        const equipment = row.original.tractor;

        return (
          <PossiblyInactiveCell
            label={getValue<string>()}
            isEditable={isEditable}
            status={getPossiblyInactiveCellStatus({
              isActive: equipment?.isActive,
              idPresentInParent: !!equipment,
            })}
            recordName="equipment"
            remediation={() => onEditRow?.(row.original)}
          />
        );
      },
    },
    {
      accessorKey: "equipment",
      accessorFn: ({ trailer, straightTruck }) =>
        dashIfEmpty(trailer?.name || straightTruck?.name),
      header: "Trailer/ST #",
      cell: ({ getValue, row }) => {
        const equipment = row.original.trailer || row.original.straightTruck;

        return (
          <PossiblyInactiveCell
            label={getValue<string>()}
            isEditable={isEditable}
            status={getPossiblyInactiveCellStatus({
              isActive: equipment?.isActive,
              idPresentInParent: !!equipment,
            })}
            recordName="equipment"
            remediation={() => onEditRow?.(row.original)}
          />
        );
      },
    },
    shipmentTagsColumn("tags"),
  ];
  if (isEditable) {
    columns.push({
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue, row }: CellContext<PlanTableFormat, unknown>) => {
        const status = getValue() as string;

        return (
          status && (
            <ChangeableStatusTag
              type={row.original.type}
              status={status}
              deliveryRouteId={Number(row.id)}
              deliveryRouteName={row.original.routeName || row.original.name}
              isAvailableForTransition={!row.original.isBlockedFromTransition}
            />
          )
        );
      },
    });
    if (printCityCardLoadEnabled) {
      columns.push({
        accessorKey: "print",
        header: "",
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }: CellContext<PlanTableFormat, unknown>) =>
          isLoadingPrintIds.includes(row.original.id) ? (
            <Box ml="-7px" width={18}>
              <Loading size={24} />
            </Box>
          ) : (
            <Tooltip title={`Print ${row.original.name}`}>
              <IconButton
                onClick={() => onPrintRow && onPrintRow(row.original)}
              >
                <Print />
              </IconButton>
            </Tooltip>
          ),
      });
    }
    columns.push({
      accessorKey: "menu",
      header: "",
      enableResizing: false,
      enableSorting: false,
      cell: ({ row }) => (
        <Tooltip title={`Edit ${row.original.name}`}>
          <IconButton onClick={() => onEditRow?.(row.original)}>
            <Edit />
          </IconButton>
        </Tooltip>
      ),
    });
  } else {
    columns.push({
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }: CellContext<PlanTableFormat, unknown>) => (
        <ReadOnlyStatusTag status={getValue() as string} />
      ),
    });
    if (printCityCardLoadEnabled) {
      columns.push({
        accessorKey: "print",
        header: "",
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }: CellContext<PlanTableFormat, unknown>) =>
          row.original.type === "delivery_route" &&
          (isLoadingPrintIds.includes(row.original.id) ? (
            <Box ml="-7px" width={18}>
              <Loading size={24} />
            </Box>
          ) : (
            <Tooltip title={`Print ${row.original.name}`}>
              <IconButton
                onClick={() => onPrintRow && onPrintRow(row.original)}
              >
                <Print />
              </IconButton>
            </Tooltip>
          )),
      });
    }
  }
  return columns;
};

export default oldPlanColumns;

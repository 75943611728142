import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { apiUrls } from "../../utils/apiUrls";
import { http } from "../../utils/httpCommon";
import { queryClient } from "../../queryClientProvider";
import { deliveryRouteSequenceStatusSchema } from "../../types/planning/plan.type";

const deliveryRouteSequenceStatusResponseSchema = z.array(
  deliveryRouteSequenceStatusSchema
);

export type DeliveryRouteSequenceStatus = z.infer<
  typeof deliveryRouteSequenceStatusSchema
>;

const checkDeliveryRouteSequenceStatusRefetchInterval = 4000; // 4s

type UseDeliveryRoutesSequenceStatusProps = {
  routeIds: number[];
  timestamp: number | null;
  onAutoSequenceRoutesFinished: (result: DeliveryRouteSequenceStatus[]) => void;
};

const keys = {
  getDeliveryRoutesSequenceStatus: (
    routeIds: number[],
    processId: number | null,
    _: number
  ) => ["delivery-routes", "sequence-status", routeIds, processId],
};

export const useDeliveryRoutesSequenceStatus = ({
  onAutoSequenceRoutesFinished,
  timestamp: processId,
  routeIds,
}: UseDeliveryRoutesSequenceStatusProps) => {
  const countRef = useRef(0);

  const { data: sequenceStatus = [] } = useQuery({
    enabled: routeIds.length > 0,
    queryKey: keys.getDeliveryRoutesSequenceStatus(
      routeIds,
      processId,
      countRef.current
    ),
    queryFn: async () => {
      const { data } = await http.get(
        apiUrls.getDeliveryRoutesSequenceStatus(routeIds)
      );
      countRef.current += 1;
      return deliveryRouteSequenceStatusResponseSchema.parse(data);
    },
    refetchInterval: () =>
      countRef.current < 75 // 5 mins timeout (75 times x 4s = 300s = 5mins)
        ? checkDeliveryRouteSequenceStatusRefetchInterval
        : false,
  });

  const isAutoSequenceFinished =
    sequenceStatus.length > 0 &&
    sequenceStatus.every((route) => route.sequenceStatus !== "processing");

  if (isAutoSequenceFinished) {
    onAutoSequenceRoutesFinished(sequenceStatus);
    queryClient.setQueryData(
      keys.getDeliveryRoutesSequenceStatus(
        routeIds,
        processId,
        countRef.current
      ),
      []
    );
  }

  return {
    isAutoSequenceRunning: routeIds.length > 0,
  };
};

import * as React from "react";
import { Drawer } from "../Drawer";
import { FlexColumn } from "../layout/Flex";
import { SidebarHeader, SidebarHeaderProps } from "./SidebarHeader";

type SidebarProps = SidebarHeaderProps & {
  width?: number;
  isOpen: boolean;
  position?: "left" | "right";
  children?: React.ReactNode;
  closeIconTitle?: string;
  closeIconAriaLabel?: string;
};

/** A Collapsible Sidebar component that's usually used as a helper of the main content, holding filters and sometimes items that can dragged. */
export const CollapsibleSidebar = ({
  isOpen,
  onClose,
  width,
  position = "left",
  title,
  fixed = false,
  children,
  closeIconTitle,
  closeButtonAriaLabel,
}: React.PropsWithChildren<SidebarProps>) => (
  <Drawer anchor={position} open={isOpen} width={width}>
    <SidebarHeader
      fixed={fixed}
      title={title}
      onClose={onClose}
      closeIconTitle={closeIconTitle}
      closeButtonAriaLabel={closeButtonAriaLabel}
    />
    <FlexColumn>{children}</FlexColumn>
  </Drawer>
);

export const FixedSidebar = (props: Omit<SidebarProps, "fixed" | "isOpen">) => (
  <CollapsibleSidebar {...props} fixed isOpen={true} />
);

import { Typography, Link, colors } from "@mui/material";
import { Warning } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../Tooltip";
import { FlexRow } from "../layout/Flex";
import { getLowerAndCapitalizedCase } from "../../../constants/strings";

const LightBlueLink = styled(Link)({
  color: colors.lightBlue[500],
  cursor: "pointer",
});

export const WarningTooltip = ({
  content,
  label,
}: {
  content: React.ReactNode;
  label: string;
}) => (
  <Tooltip content={content}>
    <FlexRow
      style={{
        gap: "4px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {label}
      <Warning color="warning" />
    </FlexRow>
  </Tooltip>
);

export const RecordRemoved = ({
  remediation: onRemediationLinkClick,
  recordName,
}: {
  remediation: (() => void) | string;
  recordName: string;
}) => {
  const { lowerCase, capitalized } = getLowerAndCapitalizedCase(recordName);
  const navigate = useNavigate();
  const onClick =
    typeof onRemediationLinkClick === "function"
      ? onRemediationLinkClick
      : () => {
          navigate(onRemediationLinkClick);
        };
  return (
    <Typography>
      {capitalized} is unavailable.{" "}
      <LightBlueLink onClick={onClick}>Select new</LightBlueLink> {lowerCase}.
    </Typography>
  );
};

export const RecordUnavailable = ({
  remediation,
  recordName,
}: {
  remediation: (() => void) | string;
  recordName: string;
}) => {
  const { lowerCase, capitalized } = getLowerAndCapitalizedCase(recordName);
  const navigate = useNavigate();
  const onClick =
    typeof remediation === "function"
      ? remediation
      : () => {
          navigate(remediation);
        };
  return (
    <Typography>
      {capitalized} is inactive. Contact Safety Dept. or{" "}
      <LightBlueLink onClick={onClick}>select new</LightBlueLink> {lowerCase}.
    </Typography>
  );
};

export type PossiblyInactiveCellStatus =
  | "unset"
  | "active"
  | "inactive"
  | "removed";

interface GetPossiblyInactiveCellStatusArgs {
  /** Item is active system-wise. Drivers or pieces of equipment may become de-activated */
  isActive: boolean | undefined;
  /** Parent record has an id set that should allow us to get details for this record */
  idPresentInParent: boolean;
  /** A detail record has been found in the database. This is necessary because some 1-to-N relationships are not enforced with FKs */
  detailRecordFound?: boolean;
}

export const getPossiblyInactiveCellStatus = ({
  isActive,
  idPresentInParent,
  detailRecordFound,
}: GetPossiblyInactiveCellStatusArgs): PossiblyInactiveCellStatus => {
  if (!idPresentInParent) {
    return "unset";
  }
  if (detailRecordFound === false) {
    return "removed";
  }
  return isActive ? "active" : "inactive";
};

interface PossiblyInactiveCellProps {
  /** The label to display in the cell. */
  label: string;
  /** Whether the cell is editable. */
  isEditable: boolean;
  /** The name of the record. */
  recordName: string;
  /** The status of the cell. */
  status: PossiblyInactiveCellStatus;
  /** The remediation function or link. */
  remediation: (() => void) | string;
}

/**
 * PossiblyInactiveCell is a cell that can be inactive, removed, or active.
 * If the cell is not editable, it will return the label.
 * If the cell is editable, it will return a WarningTooltip with the appropriate content.
 * @returns The PossiblyInactiveCell component.
 */
export const PossiblyInactiveCell = ({
  label,
  isEditable,
  recordName,
  status,
  remediation,
}: PossiblyInactiveCellProps) => {
  if (!isEditable) {
    return label;
  }
  switch (status) {
    case "removed":
      return (
        <WarningTooltip
          content={
            <RecordRemoved remediation={remediation} recordName={recordName} />
          }
          label={label}
        />
      );
    case "inactive":
      return (
        <WarningTooltip
          content={
            <RecordUnavailable
              remediation={remediation}
              recordName={recordName}
            />
          }
          label={label}
        />
      );
    case "active":
    case "unset":
      return (
        <FlexRow
          style={{
            gap: "4px",
          }}
        >
          {label}
        </FlexRow>
      );
  }
};

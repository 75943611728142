type Filters = Record<string, string>;

const unplannedFreightDefaultFilters: Filters = {
  linehaulServiceDueDate: "All",
  linehaulArrivalDate: "All",
  trapServiceDueDate: "All",
  freightOnDockServiceDueDate: "All",
  shuttleServiceDueDate: "All",
  shuttleArrivalDate: "All",
};

export const getDefaultFilterParams = (filters: Filters) =>
  Object.entries(filters)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join("&");

export const inboundDefaultFilters = {
  "unplanned-freight": unplannedFreightDefaultFilters,
} as const satisfies Record<string, Filters>;

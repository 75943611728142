import { DeliveryRouteTrapStatus } from "../../../hooks/react-query/useDeliveryRoutesTrapsStatus";

export const mockPlannedRouteTrapStatuses = {
  empty: [],
  default: [
    {
      id: 29,
      name: "CLDK",
      isAvailableForTransition: true,
      transitionConstraints: [],
      errorDetails: [],
    },
    {
      id: 30,
      name: "CLDV",
      isAvailableForTransition: true,
      transitionConstraints: [
        "NO_TRAILER_ASSIGNED",
        "NO_DRIVER_ASSIGNED",
        "NO_TRACTOR_ASSIGNED",
      ],
      errorDetails: [],
    },
    {
      id: 32,
      name: "DISP",
      isAvailableForTransition: false,
      transitionConstraints: [],
      errorDetails: [
        {
          subject: "Tractor",
          message: "Tractor must be assigned",
          code: "NO_TRACTOR_ASSIGNED",
          data: null,
        },
        {
          subject: "Trailer",
          message: "Trailer must be assigned",
          code: "NO_TRAILER_ASSIGNED",
          data: null,
        },
        {
          subject: "Driver",
          message: "Driver must be assigned",
          code: "NO_DRIVER_ASSIGNED",
          data: null,
        },
      ],
    },
  ],
} as const satisfies Record<string, DeliveryRouteTrapStatus[]>;

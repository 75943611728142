import { LocationOn, ArrowDropDown } from "@mui/icons-material";
import {
  // eslint-disable-next-line no-restricted-imports
  CircularProgress,
  Button,
  Typography,
  Menu,
  Fade,
  MenuItem,
} from "@mui/material";
import { Box, alpha } from "@mui/system";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePageUrls } from "../../../hooks/usePageUrls";
import { useServiceCenters } from "../../../hooks/react-query/useServiceCenters";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { toast } from "../../../utils/snackbarHelper";
import { parseUrl } from "./parseUrl";

export function ServiceCenterSelect() {
  const location = useLocation();
  const navigate = useNavigate();
  const pageUrls = usePageUrls();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter, setServiceCenter] = useSelectedServiceCenter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { serviceCenterCode } = useParams();
  const serviceCenters = useServiceCenters("CompanyOwned");

  if (!serviceCenters.data) return <CircularProgress size={20} />;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const updateServiceCenter = (newServiceCenterCode: string) => {
    setAnchorEl(null);

    const newServiceCenter = serviceCenters.data.find(
      (sc) => sc.code === newServiceCenterCode
    );

    if (!newServiceCenter) {
      toast("Service center not found", { variant: "error" });
      return;
    }

    setServiceCenter(newServiceCenter);

    const { department, section } = parseUrl(location.pathname);

    // NOTE: We redirect to these URLs to avoid a 404 if the user changes the Sic on a detail record that may not exist on the new Sic
    switch (department) {
      case "settings":
        return navigate(pageUrls.settings(newServiceCenterCode));

      case "operations":
        if (section?.type === "operations") {
          switch (section.section) {
            case "outbound":
              return navigate(
                pageUrls.outboundLaneDetails(newServiceCenterCode)
              );
            case "inbound":
              return navigate(
                pageUrls.inboundPlanning({
                  planDate: selectedDate,
                  sicCode: newServiceCenterCode,
                })
              );
            case "dispatch":
              return navigate(pageUrls.dispatch(newServiceCenterCode));
            default:
              throw new Error("Unhandled operations section: " + section.type);
          }
        } else if (section?.type === "inbound") {
          switch (section.section) {
            case "planning":
              return navigate(
                pageUrls.inboundPlanning({
                  planDate: selectedDate,
                  sicCode: newServiceCenterCode,
                })
              );
            case "pre-planning":
              return navigate(
                pageUrls.inboundPrePlanning({
                  planDate: selectedDate,
                  sicCode: newServiceCenterCode,
                })
              );
            case "unplanned-freight":
              return navigate(
                pageUrls.inboundUnplannedFreight({
                  planDate: selectedDate,
                  sicCode: newServiceCenterCode,
                })
              );
            case "dock-manager":
              return navigate(
                `/operations/${newServiceCenterCode}/inbound/dock-manager`
              );
            default:
              throw new Error(`Unhandled inbound section: ${section.section}`);
          }
        }
        break;
      default:
        navigate(
          location.pathname.replace(
            `/${serviceCenterCode}/`,
            `/${newServiceCenterCode}/`
          )
        );
    }
  };

  const buttonId = "sic-select-button";
  const menuId = "sic-select-menu";

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="Select a service center"
        onClick={handleClick}
        disableRipple
        sx={{
          color: (theme) => theme.palette.text.primary,
          width: 131,
          height: 64,
        }}
      >
        <Box display="flex" gap={1}>
          <LocationOn
            sx={{
              color: (theme) => theme.palette.action.active,
            }}
          />
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            {serviceCenter.code}
          </Typography>
          <ArrowDropDown
            sx={{
              color: (theme) => theme.palette.action.active,
            }}
          />
        </Box>
      </Button>
      <Menu
        id={menuId}
        MenuListProps={{ "aria-labelledby": buttonId }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        {serviceCenters.data.map((sc) => (
          <MenuItem
            key={sc.code}
            disableRipple
            onClick={() => updateServiceCenter(sc.code)}
            sx={{
              minWidth: 124,
              bgcolor: (theme) =>
                sc.code === serviceCenter.code
                  ? alpha(theme.palette.primary.light, 0.08)
                  : "undefined",
              ":hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.light, 0.08),
              },
            }}
          >
            {sc.code}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

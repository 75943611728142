import { TrailersResponse } from "../../hooks/react-query/linehaul/useEquipments";

export const mockEquipment = [
  {
    id: 23872,
    trailerNumber: "HTXGHOST30",
  },
  {
    id: 23904,
    trailerNumber: "ONTGHOST16",
  },
  {
    id: 23958,
    trailerNumber: "IB TRAP 2",
  },
] satisfies TrailersResponse[];

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  colors,
} from "@mui/material";
import { useReducer, useState } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import {
  MergeRoutesMutation,
  useAllRoutesTable,
  useMergeRoutes,
} from "../../../hooks/react-query/useDeliveryRoutes";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import Loading from "../../../components/shared/layout/Loading";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { DaylightDateFormat } from "../../../constants/DaylightDateFormat";
import { formatDateTimeTableField } from "../../../utils/tableFormat";
import { SummaryContext } from "./ActionsMenuContext";
import {
  initialState,
  mergeRoutesTrapsDialogReducer,
} from "./MergeRoutesTrapsDialog/MergeRoutesTrapsDialogReducer";
import {
  MergeRoutesTrapsStepTwo,
  ValidationErrors,
} from "./MergeRoutesTrapsDialog/MergeRoutesTrapsStepTwo";
import { MergeRoutesTrapsStepOne } from "./MergeRoutesTrapsDialog/MergeRoutesTrapsStepOne";

interface MergeRoutesDialogProps {
  handleClose: () => void;
  selectedRoutes: SummaryContext[];
  clearSelection?: () => void;
}

export const MergeRoutesDialog = ({
  handleClose,
  selectedRoutes,
  clearSelection,
}: MergeRoutesDialogProps) => {
  const [searchParams] = useSearchParams();

  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const [serviceCenter] = useSelectedServiceCenter();

  const [bottomErrorMessage, setBottomErrorMessage] = useState<string>("");
  const [formValidationErrors, setFormValidationErrors] =
    useState<ValidationErrors>({});

  const [dialogState, dispatch] = useReducer(mergeRoutesTrapsDialogReducer, {
    ...initialState,
    selectedRouteIds: selectedRoutes.map((route) => +route.id),
    mergeIntoType: "route",
  });

  const {
    selectedRouteIds: routeIdsToMerge,
    mergeDestination,
    currentStep,
  } = dialogState;

  const deliveryRoutes = useAllRoutesTable({
    date: planDate,
    sicId: serviceCenter.id,
  });

  const handleSuccess = (
    _: number,
    mergeRoutesMutation: MergeRoutesMutation
  ) => {
    clearSelection?.();
    handleClose();
    const targetRoute = deliveryRoutes.data?.find(
      (route) => route.id === mergeRoutesMutation.routeId
    );
    toast(
      toastMessage.inbound.mergeRoutes.success(
        mergeRoutesMutation.request.routes.length,
        targetRoute?.name ?? ""
      ),
      {
        variant: "success",
      }
    );
  };

  const mergeRoutes = useMergeRoutes(
    handleSuccess,
    searchParams.get("searchTerm") ?? undefined
  );

  const handleMergeRoutes = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!handleRouteSelectionValidation()) {
      return;
    }

    const targetRoute = deliveryRoutes.data?.find(
      (route) => route.id === mergeDestination.id
    );

    if (!targetRoute) {
      throw new Error("Selected route id not found in deliveryRoutes");
    }

    mergeRoutes.mutate({
      routeId: mergeDestination.id ?? 0,
      request: {
        doorId: mergeDestination.doorId,
        startTime: mergeDestination.startTime
          ? dayjs(mergeDestination.startTime, { utc: false })
              .tz(serviceCenter.timeZone, true)
              .toISOString()
          : dayjs().utc().toISOString(),
        date: planDate,
        sicId: serviceCenter.id,
        routes: routeIdsToMerge,
        trailerEquipmentId: mergeDestination.trailerId,
        straightTruckEquipmentId: mergeDestination.straightTruckId,
        tractorEquipmentId: mergeDestination.tractorId,
        customName:
          mergeDestination.customName !== mergeDestination.originalName
            ? mergeDestination.customName
            : null,
      },
    });
  };

  const handleRouteSelectionValidation = () => {
    if (currentStep === 1 && mergeDestination.id === null) {
      setBottomErrorMessage("Please select a route.");
      return false;
    } else if (currentStep === 2) {
      const validationErrors: ValidationErrors = {};
      if (!mergeDestination.customName) {
        validationErrors.name = "Please add a Route name.";
      }
      if (!mergeDestination.startTime) {
        validationErrors.startTime = "Please add a start time.";
      }

      if (Object.keys(validationErrors).length !== 0) {
        setFormValidationErrors(validationErrors);
        return false;
      }
    }

    return true;
  };

  const handleBack = () => {
    setFormValidationErrors({});
    dispatch({
      type: "go_to_previous_step",
    });
  };

  const handleNext = (shouldBypassValidation: boolean) => {
    setBottomErrorMessage("");
    if (shouldBypassValidation || handleRouteSelectionValidation()) {
      dispatch({
        type: "go_to_next_step",
      });
    }
  };

  if (deliveryRoutes.isLoading) {
    return (
      <Dialog open>
        <DialogTitle>Loading...</DialogTitle>
        <DialogContent>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }

  const availableRoutes =
    deliveryRoutes.data?.filter((route) =>
      routeIdsToMerge.includes(+route.id)
    ) ?? [];

  return (
    <Dialog open={true}>
      <form onSubmit={handleMergeRoutes}>
        <DialogTitle sx={{ fontWeight: "bold" }}>Merge Routes</DialogTitle>
        <DialogContent sx={{ minWidth: "500px", padding: "0px" }}>
          <Box position="absolute" right={10} top={10}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          {currentStep === 1 ? (
            <MergeRoutesTrapsStepOne
              dialogState={dialogState}
              dispatch={dispatch}
              selectedRoutes={availableRoutes.map((route) => ({
                id: route.id,
                name: route.name ?? "",
                status: route.status,
                type: "route",
              }))}
              selectedTraps={[]}
              mergeDestinationOptions={availableRoutes.map((route) => ({
                id: route.id,
                doorId: route.doorId ?? null,
                trailerId: route.trailer?.id ?? null,
                straightTruckId: route.straightTruck?.id ?? null,
                tractorId: route.tractor?.id ?? null,
                driverId: route.driver?.id ?? null,
                status: route.status,
                planDate,
                startTime: route.startTime
                  ? dayjs(
                      formatDateTimeTableField(
                        route.startTime,
                        DaylightDateFormat.DATE_TIME_12,
                        serviceCenter.timeZone
                      )
                    ).toISOString()
                  : null,
                originalName: route.name ?? "",
                customName: route.name ?? "",
              }))}
              handleNext={handleNext}
            />
          ) : (
            <MergeRoutesTrapsStepTwo
              validationErrors={formValidationErrors}
              dialogState={dialogState}
              dispatch={dispatch}
              mergeDestinationName={mergeDestination.originalName}
              showRouteEquipmentSelectors={true}
            />
          )}
          <Divider sx={{ marginTop: "10px" }} />
          <Box ml={3} mt={1} height={20}>
            {bottomErrorMessage && (
              <Typography
                role="alert"
                fontSize="small"
                color={(theme) => theme.palette.error.main}
              >
                {bottomErrorMessage}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
          <Button
            variant="outlined"
            onClick={currentStep === 1 ? handleClose : handleBack}
            color="inherit"
            sx={{
              border: "none",
              color: colors.blue[700],
              textTransform: "none",
            }}
          >
            {currentStep === 1 ? "Cancel" : "Back"}
          </Button>
          {currentStep === 2 ? (
            <LoadingButton
              isLoading={mergeRoutes.isPending}
              loadingLabel="Merging..."
            >
              Merge
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleNext(false)}
              aria-disabled={bottomErrorMessage.length > 0}
              sx={{
                border: "none",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

import { Navigate, RouteObject } from "react-router-dom";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { useIsFeatureFlagEnabled } from "../featureFlags/useIsFeatureFlagEnabled";

export const useOutboundRoutes = (): RouteObject[] => {
  const isOutboundTrailershubEnabled = useIsFeatureFlagEnabled(
    "outbound-trailershub"
  );

  return [
    {
      path: "outbound",
      lazy: () => import("../pages/outbound/OutboundLayout"),
      errorElement: <ErrorBoundary />,
      children: [
        { index: true, element: <Navigate to="lane-details" replace /> },
        {
          path: "dock-manager",
          errorElement: <ErrorBoundary />,
          lazy: () =>
            import("../pages/outbound/outboundDockManager/OutboundDockManager"),
        },
        ...(isOutboundTrailershubEnabled
          ? [
              {
                path: "trailer-hub",
                errorElement: <ErrorBoundary />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import("../pages/outbound/linehaulTrailers/TrailersHub"),
                  },
                  {
                    path: "shipments",
                    errorElement: <ErrorBoundary />,
                    lazy: () =>
                      import(
                        "../pages/outbound/linehaulTrailers/TrailerShipments"
                      ),
                  },
                ],
              },
            ]
          : []),
        {
          path: "uc-cc-planning",
          errorElement: <ErrorBoundary />,
          lazy: () =>
            import(
              "../pages/outbound/urgentAndCriticalCare/UrgentAndCriticalCare"
            ),
        },
        {
          path: "lane-details",
          errorElement: <ErrorBoundary />,
          children: [
            {
              index: true,
              lazy: () =>
                import("../pages/outbound/freightDetails/FreightDetails"),
            },
            {
              path: "shipment-details",
              errorElement: <ErrorBoundary />,
              lazy: () =>
                import(
                  "../pages/outbound/freightDetails/ShipmentDetails/ShipmentDetails"
                ),
            },
          ],
        },
      ],
    },
  ];
};

import { useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { SelectedEquipmentType } from "../../../types/equipment.type";
import Loading from "../../../components/shared/layout/Loading";
import { useEquipmentUsageBySicId } from "../../../hooks/react-query/useEquipments";
import { FlexRow } from "../../../components/shared/layout/Flex";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { DeliveryEquipment } from "../../../types/deliveryEquipment.type";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { AutocompleteAvailabilityOption } from "../../../components/shared/AutocompleteAvailabilityOption";
import {
  getStraightTruckOptions,
  getTractorOptions,
  getTrailerOptions,
} from "./equipmentUtils";

type EquipmentSelectionProps = {
  trailerId: number | null;
  tractorId: number | null;
  straightTruckId: number | null;
  setTrailerValue: (value: DeliveryEquipment | null) => void;
  setStraightTruckValue: (value: DeliveryEquipment | null) => void;
  setTractorValue?: (value: DeliveryEquipment | null) => void;
};

export const EquipmentSelection = ({
  trailerId,
  tractorId,
  straightTruckId,
  setTrailerValue,
  setStraightTruckValue,
  setTractorValue,
}: EquipmentSelectionProps) => {
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const [selectedEquipmentType, setSelectedEquipmentType] =
    useState<SelectedEquipmentType | null>(null);

  const [serviceCenter] = useSelectedServiceCenter();

  const {
    data: equipments = {
      trailers: [],
      straightTrucks: [],
      tractors: [],
    },
    isLoading: equipmentIsLoading,
  } = useEquipmentUsageBySicId(serviceCenter.id, planDate);
  const trailerOptions = getTrailerOptions(equipments.trailers);
  const tractorOptions = getTractorOptions(equipments.tractors);
  const straightTruckOptions = getStraightTruckOptions(
    equipments.straightTrucks
  );

  const handleSelectEquipmentType = (type: SelectedEquipmentType) => {
    setTrailerValue(null);
    setStraightTruckValue(null);
    setTractorValue?.(null);

    setSelectedEquipmentType(type === selectedEquipmentType ? null : type);
  };

  if (equipmentIsLoading) {
    return <Loading />;
  }

  return (
    <FlexRow>
      <FormControl fullWidth={true}>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          Select an equipment type
        </Typography>
        <Box sx={{ margin: "1px 1px 1px 1px" }}>
          <ToggleButtonGroup
            color="primary"
            value={selectedEquipmentType}
            exclusive
            aria-label="Platform"
            size="small"
          >
            <ToggleButton
              value="Trailer"
              onChange={() => handleSelectEquipmentType("Trailer")}
            >
              Trailer
            </ToggleButton>
            <ToggleButton
              value="Truck"
              onChange={() => handleSelectEquipmentType("Truck")}
            >
              Straight Truck
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {selectedEquipmentType === "Trailer" && (
          <Box
            mt={1}
            display="flex"
            flexDirection="column"
            gap={1}
            role="tabpanel"
          >
            <Autocomplete
              value={
                trailerOptions.find((d) => Number(d.value) === trailerId) ??
                null
              }
              options={trailerOptions}
              onChange={(_, newValue) => {
                if (newValue) {
                  setTrailerValue({
                    id: newValue.value,
                    name: newValue.name,
                    equipmentType: newValue.equipmentType,
                  });
                } else {
                  setTrailerValue(null);
                }
              }}
              getOptionLabel={(trailer) => trailer.name}
              renderInput={(params) => (
                <TextField {...params} label="Trailer #" />
              )}
              groupBy={(option) => option.group}
              renderOption={(props, option) => (
                <AutocompleteAvailabilityOption
                  key={option.name}
                  listItemProps={props}
                  name={option.name}
                  assignment={option.assignment}
                />
              )}
            />
            {setTractorValue && (
              <Autocomplete
                value={
                  tractorOptions.find((d) => Number(d.value) === tractorId) ??
                  null
                }
                options={tractorOptions}
                onChange={(_, newValue) => {
                  if (newValue) {
                    setTractorValue({
                      id: newValue.value,
                      name: newValue.name,
                      equipmentType: newValue.equipmentType,
                    });
                  } else {
                    setTractorValue(null);
                  }
                }}
                getOptionLabel={(tractor) => tractor.name}
                renderInput={(params) => (
                  <TextField {...params} label="Tractor #" />
                )}
                groupBy={(option) => option.group}
                renderOption={(props, option) => (
                  <AutocompleteAvailabilityOption
                    key={option.name}
                    listItemProps={props}
                    name={option.name}
                    assignment={option.assignment}
                  />
                )}
              />
            )}
          </Box>
        )}
        {selectedEquipmentType === "Truck" && (
          <Box mt={1} role="tabpanel">
            <Autocomplete
              value={
                straightTruckOptions.find(
                  (d) => Number(d.value) === straightTruckId
                ) ?? null
              }
              options={straightTruckOptions}
              // TODO: when removing feature flag, the line bellow can be simplified to setTrailerValue(newValue)
              onChange={(_, newValue) => {
                if (newValue) {
                  setStraightTruckValue({
                    id: newValue.value,
                    name: newValue.name,
                    equipmentType: newValue.equipmentType,
                  });
                } else {
                  setStraightTruckValue(null);
                }
              }}
              getOptionLabel={(straightTruck) => straightTruck.name}
              renderInput={(params) => <TextField {...params} label="ST #" />}
              groupBy={(option) => option.group}
              renderOption={(props, option) => (
                <AutocompleteAvailabilityOption
                  key={option.name}
                  listItemProps={props}
                  name={option.name}
                  assignment={option.assignment}
                />
              )}
            />
          </Box>
        )}
      </FormControl>
    </FlexRow>
  );
};

import { GetFreightDetailsResponse } from "../../hooks/react-query/outbound/freightDetails/useFreightDetails";

export const freightDetails = {
  default: {
    lanes: [
      {
        destinationType: 1,
        loadToSicId: 84,
        loadTo: "AFW",
        bills: 46,
        doorCount: 1,
        weight: 34698,
        pieces: 9072,
        pallets: 47,
        loadedWeight: 0,
        loadedBills: 0,
        laneType: 0,
        destinations: [
          {
            sicId: 84,
            sic: "AFW",
            bills: 46,
            loadedBills: 0,
            weight: 34698,
            loadedWeight: 0,
            pieces: 9072,
            pallets: 47,
            hasServiceDueValidationIssue: false,
            tags: [],
          },
        ],
        weightByTags: [
          {
            tagId: 2,
            tag: "UC",
            color: "#FBC02D",
            weight: 235,
            bills: 1,
          },
          {
            tagId: 4,
            tag: "APTR",
            color: "#777DE9",
            weight: 4430,
            bills: 10,
          },
          {
            tagId: 81,
            tag: "VC",
            color: "#AA7EA0",
            weight: 846,
            bills: 1,
          },
        ],
        hasServiceDueValidationIssue: false,
        doors: [9],
      },
      {
        destinationType: 1,
        loadToSicId: 18,
        loadTo: "ATL",
        bills: 14,
        doorCount: 1,
        weight: 21439,
        pieces: 670,
        pallets: 34,
        loadedWeight: 0,
        loadedBills: 0,
        laneType: 0,
        destinations: [
          {
            sicId: 18,
            sic: "ATL",
            bills: 14,
            loadedBills: 0,
            weight: 21439,
            loadedWeight: 0,
            pieces: 670,
            pallets: 34,
            hasServiceDueValidationIssue: false,
            tags: [],
          },
        ],
        weightByTags: [
          {
            tagId: 4,
            tag: "APTR",
            color: "#777DE9",
            weight: 262,
            bills: 2,
          },
          {
            tagId: 81,
            tag: "VC",
            color: "#AA7EA0",
            weight: 574,
            bills: 1,
          },
        ],
        hasServiceDueValidationIssue: false,
        doors: [91],
      },
      {
        destinationType: 1,
        loadToSicId: 164,
        loadTo: "ATM",
        bills: 53,
        doorCount: 2,
        weight: 56614.75,
        pieces: 471,
        pallets: 60,
        loadedWeight: 0,
        loadedBills: 0,
        laneType: 0,
        destinations: [
          {
            sicId: 164,
            sic: "ATM",
            bills: 53,
            loadedBills: 0,
            weight: 56614.75,
            loadedWeight: 0,
            pieces: 471,
            pallets: 60,
            hasServiceDueValidationIssue: false,
            tags: [],
          },
        ],
        weightByTags: [
          {
            tagId: 4,
            tag: "APTR",
            color: "#777DE9",
            weight: 3393,
            bills: 14,
          },
          {
            tagId: 81,
            tag: "VC",
            color: "#AA7EA0",
            weight: 192,
            bills: 2,
          },
        ],
        hasServiceDueValidationIssue: false,
        doors: [89, 90],
      },
      {
        destinationType: 1,
        loadToSicId: 4,
        loadTo: "BOS",
        bills: 169,
        doorCount: 1,
        weight: 118164.72,
        pieces: 2225,
        pallets: 194,
        loadedWeight: 0,
        loadedBills: 0,
        laneType: 0,
        destinations: [
          {
            sicId: 4,
            sic: "BOS",
            bills: 169,
            loadedBills: 0,
            weight: 118164.72,
            loadedWeight: 0,
            pieces: 2225,
            pallets: 194,
            hasServiceDueValidationIssue: false,
            tags: [],
          },
        ],
        weightByTags: [
          {
            tagId: 2,
            tag: "UC",
            color: "#FBC02D",
            weight: 2897,
            bills: 5,
          },
          {
            tagId: 4,
            tag: "APTR",
            color: "#777DE9",
            weight: 9169,
            bills: 24,
          },
          {
            tagId: 81,
            tag: "VC",
            color: "#AA7EA0",
            weight: 9306,
            bills: 3,
          },
        ],
        hasServiceDueValidationIssue: false,
        doors: [74],
      },
      {
        destinationType: 1,
        loadToSicId: 10,
        loadTo: "BWI",
        bills: 84,
        doorCount: 1,
        weight: 68449,
        pieces: 10738,
        pallets: 106,
        loadedWeight: 0,
        loadedBills: 0,
        laneType: 0,
        destinations: [
          {
            sicId: 10,
            sic: "BWI",
            bills: 7,
            loadedBills: 0,
            weight: 8863,
            loadedWeight: 0,
            pieces: 10095,
            pallets: 7,
            hasServiceDueValidationIssue: false,
            tags: [],
          },
          {
            sicId: 118,
            sic: "BWI-PDXE",
            bills: 77,
            loadedBills: 0,
            weight: 59586,
            loadedWeight: 0,
            pieces: 643,
            pallets: 99,
            hasServiceDueValidationIssue: false,
            tags: [],
          },
        ],
        weightByTags: [
          {
            tagId: 4,
            tag: "APTR",
            color: "#777DE9",
            weight: 14487,
            bills: 33,
          },
          {
            tagId: 2,
            tag: "UC",
            color: "#FBC02D",
            weight: 3360,
            bills: 3,
          },
          {
            tagId: 81,
            tag: "VC",
            color: "#AA7EA0",
            weight: 745,
            bills: 3,
          },
        ],
        hasServiceDueValidationIssue: false,
        doors: [69],
      },
      {
        destinationType: 1,
        loadToSicId: 20,
        loadTo: "CHT",
        bills: 63,
        doorCount: 1,
        weight: 25251,
        pieces: 300,
        pallets: 58,
        loadedWeight: 0,
        loadedBills: 0,
        laneType: 0,
        destinations: [
          {
            sicId: 20,
            sic: "CHT",
            bills: 63,
            loadedBills: 0,
            weight: 25251,
            loadedWeight: 0,
            pieces: 300,
            pallets: 58,
            hasServiceDueValidationIssue: false,
            tags: [],
          },
        ],
        weightByTags: [
          {
            tagId: 4,
            tag: "APTR",
            color: "#777DE9",
            weight: 6085,
            bills: 19,
          },
          {
            tagId: 81,
            tag: "VC",
            color: "#AA7EA0",
            weight: 2023,
            bills: 1,
          },
        ],
        hasServiceDueValidationIssue: false,
        doors: [87],
      },
      {
        destinationType: 1,
        loadToSicId: 114,
        loadTo: "DAL",
        bills: 71,
        doorCount: 0,
        weight: 91571,
        pieces: 3069,
        pallets: 148,
        loadedWeight: 0,
        loadedBills: 0,
        laneType: 0,
        destinations: [
          {
            sicId: 7,
            sic: "DFW",
            bills: 13,
            loadedBills: 0,
            weight: 3400,
            loadedWeight: 0,
            pieces: 46,
            pallets: 16,
            tags: [
              {
                tagId: 4,
                tag: "APTR",
                color: "#777DE9",
                bills: 3,
              },
            ],
            hasServiceDueValidationIssue: false,
          },
          {
            sicId: 114,
            sic: "DAL",
            bills: 58,
            loadedBills: 0,
            weight: 88171,
            loadedWeight: 0,
            pieces: 3023,
            pallets: 132,
            tags: [
              {
                tagId: 4,
                tag: "APTR",
                color: "#777DE9",
                bills: 9,
              },
              {
                tagId: 2,
                tag: "UC",
                color: "#FBC02D",
                bills: 3,
              },
            ],
            hasServiceDueValidationIssue: false,
          },
        ],
        weightByTags: [
          {
            tagId: 4,
            tag: "APTR",
            color: "#777DE9",
            weight: 10311,
            bills: 12,
          },
          {
            tagId: 2,
            tag: "UC",
            color: "#FBC02D",
            weight: 3343,
            bills: 3,
          },
        ],
        hasServiceDueValidationIssue: false,
        doors: [],
      },
    ],
    summary: {
      bills: 429,
      loadedBills: 0,
      weight: 324616.47,
      doorCount: 7,
      loadedWeight: 0,
      pieces: 23476,
      pallets: 499,
      tonnageByTags: [
        {
          tag: "Urgent care shipment",
          color: "#FBC02D",
          weight: 6492,
        },
        {
          tag: "Appointment Required ",
          color: "#777DE9",
          weight: 37826,
        },
        {
          tag: "Vendor Compliance",
          color: "#AA7EA0",
          weight: 13686,
        },
      ],
    },
  },
} as const satisfies Record<string, GetFreightDetailsResponse>;

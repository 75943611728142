import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { http } from "../../../utils/httpCommon";
import { shipmentsSchema } from "../../../services/prePlanningService.types";
import { apiUrls } from "../../../utils/apiUrls";
import {
  sortShipmentsByPresetRoute,
  sortShipmentsByServiceDue,
  sortShipmentsByConsigneeName,
} from "../../../utils/sortComparator";

export const keys = {
  allShipmentDetails: ["planning", "shipment-details"] as const,
  shipmentDetailSearch: (search: ShipmentDetailsSearch) => [
    "planning",
    "shipment-details",
    search.shipmentIds,
    search.searchTerm ?? "",
    search.orderBy ?? "",
    search.addPlanningInfo ?? false,
    search.sicId,
    search.planDate,
  ],
};

export type SortShipmentsBy = "presetRoute" | "serviceDue" | "consigneeName";

type ShipmentDetailsSearch = {
  shipmentIds: number[];
  searchTerm?: string;
  orderBy?: string;
  sicId?: number;
  addPlanningInfo?: boolean;
  planDate?: number;
};

type UseShipmentDetailsArgs = {
  search: ShipmentDetailsSearch;
  enabled?: boolean;
  matchingSearchTermsOnParent?: string[];
  onlyIncludeHighlightedRows?: boolean;
  /* Used for optional sorting on the client side. This allows us to have a single React Query cache for shipments. */
  sortBy?: SortShipmentsBy;
};

const sortShipments = (
  sortBy: SortShipmentsBy,
  shipments: z.infer<typeof shipmentsSchema>
) => {
  switch (sortBy) {
    case "presetRoute":
      return shipments.sort(sortShipmentsByPresetRoute);
    case "serviceDue": {
      return shipments.sort(sortShipmentsByServiceDue);
    }
    case "consigneeName": {
      return shipments.sort(sortShipmentsByConsigneeName);
    }
  }
};

export function useShipmentDetailSearch({
  enabled = true,
  sortBy,
  search,
  matchingSearchTermsOnParent,
  onlyIncludeHighlightedRows = true,
}: UseShipmentDetailsArgs) {
  const { searchTerm } = search;

  return useQuery({
    queryKey: keys.shipmentDetailSearch(search),
    select: (shipments) => {
      let result = shipments;

      if (searchTerm && onlyIncludeHighlightedRows) {
        result = searchTerm
          ? shipments.filter(
              (s) =>
                matchingSearchTermsOnParent?.some((p) =>
                  p.toLowerCase().includes(searchTerm.toLowerCase())
                ) || s.highlightedRow
            )
          : shipments.filter((s) => s.highlightedRow);
      }

      return sortBy ? sortShipments(sortBy, result) : result;
    },
    queryFn: async () => {
      const {
        searchTerm,
        orderBy,
        sicId,
        addPlanningInfo,
        planDate,
        shipmentIds,
      } = search;
      if (shipmentIds.length === 0) return [];

      const url = apiUrls.getShipmentDetails;

      // Using POST endpoint because of the URL length limit.
      const { data, status } = await http.post(url, shipmentIds, {
        params: {
          searchTerm,
          orderBy,
          sicId,
          addPlanningInfo,
          planDate,
        },
      });
      if (status === 204) return [];
      return shipmentsSchema.parse(data);
    },
    enabled,
  });
}

export function useInvalidateAllQueryShipmentDetails() {
  const queryClient = useQueryClient();
  const invalidateAllQueryShipmentDetails = () => {
    queryClient.invalidateQueries({
      queryKey: keys.allShipmentDetails,
    });
  };

  return { invalidateAllQueryShipmentDetails };
}

import { BookingStatus } from "../../hooks/react-query/linehaul/useLoadBoard";
import { SELECTED_DATE_PARAM_KEY } from "../../hooks/useDateSearchParamOrFallbackToToday";
import { SEARCH_TERM_KEY } from "../../hooks/useSearchTermParam";
import { UNPLANNED_SELECTED_DATE } from "../../hooks/useUnplannedDatetimeSearchParam";
import { UnplannedSection } from "../../pages/inbound/unplannedFreight/types";
import {
  BaseQueryParams,
  baseQueryParamsSchema,
  Option,
  QueryParams,
} from "../../types/filter";
import { capitalize } from "../../utils/capitalize";
import { dateToFormat } from "../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../DaylightDateFormat";
import { SomethingWithConsigneeAddress } from "./commonFilters/consigneeAddressFilter";
import { SomethingWithConsigneeCity } from "./commonFilters/consigneeCityFilter";
import { SomethingWithConsigneeName } from "./commonFilters/consigneeNameFilter";
import { SomethingWithConsigneeZip } from "./commonFilters/consigneeZipFilter";
import { SomethingWithDriverName } from "./commonFilters/driverNameFilter";
import { SomethingWithShipper } from "./commonFilters/shipperNameFilter";
import type { SomethingWithTags } from "./commonFilters/tagsFilter";

export const ALL = "All";

export const stringOptionFactory = (
  value: string | undefined | null
): Option => ({
  label: value ?? "",
  value: value ?? "",
});

export type Prefix = UnplannedSection;

export const createNoDateOption = (
  value: string | undefined | null
): Option => ({
  label: value ? value : "No Date",
  value: value ? value : "No Date",
});
export const createDestinationSicOption = (
  value: string | undefined | null
): Option => ({
  label: value ? value : "None",
  value: value ? value : "None",
});

export const handlePrefix = (param: BaseQueryParams, prefix?: Prefix) =>
  prefix ? ((prefix + capitalize(param)) as QueryParams) : param;

export const handleSearchTermParam = (prefix?: Prefix) =>
  prefix ? prefix + capitalize(SEARCH_TERM_KEY) : SEARCH_TERM_KEY;

export const prefixedSearchTermParams = () => {
  const prefixes: Prefix[] = ["trap", "shuttle", "linehaul", "freightOnDock"];
  return prefixes.map((prefix) => prefix + capitalize(SEARCH_TERM_KEY));
};

export const getUniqueOptions = (options: Option[]): Option[] => {
  const map = new Map();
  options.forEach((option) => map.set(option.label, option));
  return [...map.values()];
};

export const isValidDate = (date: Date | null | undefined) =>
  date && date.getFullYear() > 1;

const sortAlphaNumeric = (options: Option[]) => {
  if (options.length === 0) return options;

  const isNumeric = (label: string) => {
    label = label.replace(".", "").replace(",", "");
    return /^-?\d+$/.test(label);
  };

  const stringArray = options
    .filter((x) => !isNumeric(x.label))
    .sort((a, b) => a.label.localeCompare(b.label));

  const numericArray = options
    .filter((x) => isNumeric(x.label))
    .sort(
      (a, b) =>
        Number(a.label.replace(",", "")) - Number(b.label.replace(",", ""))
    );

  return numericArray.concat(stringArray);
};

export const sortByTime = (options: Option[]) => {
  if (options.length === 0) return options;

  options.forEach((option) => {
    const [timeBeforeColon, timeAfterColon] = option.label.split(":");
    const hours = timeBeforeColon;
    const minutes = timeAfterColon.split(" ")[0];
    option.value = parseFloat(hours + "." + minutes);
  });

  const amArray = options
    .filter((x) => x.label.includes("AM"))
    .sort((a, b) => Number(a.value) - Number(b.value));

  const pmArray = options
    .filter((x) => x.label.includes("PM"))
    .sort((a, b) => Number(a.value) - Number(b.value));

  options.forEach((option) => (option.value = option.label));

  return amArray.concat(pmArray);
};

export const sortByDate = (options: Option[]) => {
  if (options.length === 0) return options;

  options.forEach((option) => {
    const [month, day, year] = option.label.split("/");
    option.value = parseFloat(`${year}${month}${day}`);
  });

  options.sort((a, b) => Number(a.value) - Number(b.value));

  options.forEach((option) => (option.value = option.label));

  return options;
};

export const sortEtaDate = (options: Option[]) => {
  if (options.length === 0) return options;

  options.forEach((option) => {
    const [month, day, yearTime] = option.label.split("/");
    const [year, time] = yearTime.split(" ");
    option.value = parseFloat(`${year}${month}${day}${time}`);
  });

  options.sort((a, b) => Number(a.value) - Number(b.value));

  options.forEach((option) => (option.value = option.label));

  return options;
};

export const getNonEmptyUniqueSortedOptions = (
  data: Option[],
  sortMethod?: (options: Option[]) => Option[]
): Option[] => {
  const nonEmptyOptions = data.filter((d) => d.label);

  return getUniqueOptions(
    sortMethod ? sortMethod(nonEmptyOptions) : sortAlphaNumeric(nonEmptyOptions)
  );
};

/**
 * Filters an array of objects based on search terms within specified properties.
 * @template T - The type of objects in the array.
 * @param {T[]} array - The array of objects to filter.
 * @param {Array<keyof T>} properties - The properties to search within.
 * @param {string} searchTerm - The search term to match against the properties.
 * @returns {T[]} - An array of objects that match the search term in at least one of the specified properties.
 */
type ObjectWithProperties = {
  [key: string]: any;
};
export const filterArrayBySearchTerm = <T extends ObjectWithProperties>(
  array: T[],
  properties: Array<keyof T>,
  searchTerm: string
): T[] =>
  array.filter((item) =>
    properties.some(
      (prop) =>
        item[prop] &&
        item[prop]
          .toString()
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
    )
  );

const cancelledStatus = {
  carrier: "CarrierCancelled",
  daylight: "DyltCancelled",
} as const;

type Schedule = {
  bookingStatus: BookingStatus;
};

export function filterCancelledSchedules<T extends Schedule>(schedules?: T[]) {
  return (
    schedules?.filter((i) =>
      Object.values(cancelledStatus)
        .map((i) => i.toString())
        .includes(i.bookingStatus)
    ) ?? []
  );
}

export function filterActiveSchedules<T extends Schedule>(schedules?: T[]) {
  return (
    schedules?.filter(
      (i) =>
        !Object.values(cancelledStatus)
          .map((i) => i.toString())
          .includes(i.bookingStatus)
    ) ?? []
  );
}

export function isActiveFilter(filters?: string[]): filters is string[] {
  return Boolean(filters && filters[0] !== ALL && filters.length > 0);
}

type FilterParams = Record<string, string[]>;

export function getInboundFilterParamsOrder(params: URLSearchParams) {
  const filters: FilterParams = Object.create(null);
  params.forEach((_, key) => {
    const filterValidation = baseQueryParamsSchema.safeParse(key);
    if (
      filterValidation.success &&
      key !== UNPLANNED_SELECTED_DATE &&
      key !== SELECTED_DATE_PARAM_KEY &&
      !prefixedSearchTermParams().includes(key)
    ) {
      filters[key] = params.getAll(key);
    }
  });
  return filters;
}

export const removePrefix = (inputString: string, prefix?: Prefix) => {
  if (prefix && inputString.startsWith(prefix)) {
    const result = inputString.slice(prefix.length);
    return result.charAt(0).toLowerCase() + result.slice(1);
  }
  return inputString;
};

type NonStringProperties = SomethingWithShipper &
  SomethingWithConsigneeName &
  SomethingWithConsigneeAddress &
  SomethingWithDriverName &
  SomethingWithConsigneeCity &
  SomethingWithConsigneeZip &
  Partial<SomethingWithTags>;

export const filterNestedProperties = <T>(
  item: T & NonStringProperties,
  filter: [string, string[]],
  prefix?: Prefix
): boolean => {
  const value = item[removePrefix(filter[0], prefix) as keyof T];
  if (typeof value === "string" || typeof value === "number") {
    return filter[1].includes(String(value));
  } else if (value instanceof Date) {
    const formattedServiceDue = dateToFormat(value, DaylightDateFormat.DATE);
    return filter[1].includes(formattedServiceDue);
  } else {
    switch (removePrefix(filter[0], prefix)) {
      case "tags": {
        return Boolean(
          item.tags?.some((tag) => filter[1].includes(tag.name || ""))
        );
      }
      case "shipperName": {
        return filter[1].includes(item.shipper?.name || "");
      }
      case "consigneeName": {
        return filter[1].includes(item.consignee?.name || "");
      }
      case "consigneeAddress": {
        return filter[1].includes(item.consignee?.address || "");
      }
      case "consigneeCity": {
        return filter[1].includes(item.consignee?.city || "");
      }
      case "consigneeZip": {
        return filter[1].includes(item.consignee?.zipCode || "");
      }
      case "driverName": {
        return filter[1].includes(item.driver?.name || "");
      }
    }
  }
  return false;
};

export function filterDataByInboundFilterURLParams<T>(
  params: URLSearchParams,
  param: BaseQueryParams,
  data: (T & NonStringProperties)[],
  prefix?: Prefix
) {
  const filters = Object.entries(getInboundFilterParamsOrder(params));
  let result = data;

  if (filters.length > 0 && filters[0][0] === handlePrefix(param, prefix)) {
    return result;
  }

  filters.forEach((filter) => {
    if (filter[0] !== handlePrefix(param, prefix)) {
      result = result.filter((item) => {
        if (isActiveFilter(filter[1])) {
          return filterNestedProperties(item, filter, prefix);
        } else {
          return true;
        }
      });
    }
  });

  return result;
}

export const checkIncludes = (
  values: (string | number | null | undefined)[],
  search: string | number
) =>
  values.find((value) =>
    (value ?? "")
      .toString()
      .toLowerCase()
      .includes(search.toString().toLowerCase())
  );

export const sortNoneFirst = (options: Option[]): Option[] => {
  const noneOption = options.find((option) => option.value === "None");
  const otherOptions = options.filter((option) => option.value !== "None");

  if (noneOption) {
    return [noneOption, ...otherOptions];
  }

  return otherOptions;
};

export const sortNoDateFirst = (options: Option[]): Option[] => {
  const noDateOption = options.find((option) => option.value === "No Date");
  const filteredOptions = options.filter(
    (option) => option.value !== "No Date"
  );

  if (noDateOption) {
    filteredOptions.unshift(noDateOption);
  }

  return filteredOptions;
};

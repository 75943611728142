import { ScacCode } from "../../hooks/react-query/linehaul/useLinehaulCarriers";

export const mockScacCodes = {
  default: [
    {
      carrierCode: "LAX275",
      scacCode: "ALMA",
    },
    {
      carrierCode: "L338",
      scacCode: "ATUR",
    },
  ],
} as const satisfies Record<string, ScacCode[]>;

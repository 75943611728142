import { MouseEvent } from "react";
import { Shipment } from "../services/prePlanningService.types";
import { toastMessage } from "../constants/strings";
import { SummaryContext } from "../pages/inbound/shared/ActionsMenuContext";
import { unifyShipmentContext } from "./unifyShipmentContext";
import { toast } from "./snackbarHelper";

const addItem = (
  selectedItem: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState((prev) => [...prev, { ...selectedItem }]);
};

const addGroup = (
  selectedItems: SummaryContext[],
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState(() => [...selectedItems]);
};

const replaceItem = (
  selectedItem: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState(() => [{ ...selectedItem }]);
};

const removeFromParent = (
  selectedItem: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState((prev) =>
    prev
      .map((shipment) => {
        const filteredShipmentIds = shipment.shipmentIds.filter(
          (id) => !selectedItem.shipmentIds.includes(id)
        );
        return { ...shipment, shipmentIds: filteredShipmentIds };
      })
      .filter((shipment) => shipment.shipmentIds.length > 0)
  );
};

const replaceParent = (
  selectedItem: SummaryContext,
  parent: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState((prev) =>
    prev.map((item) => {
      if (item.id === parent.id) {
        return {
          ...item,
          shipmentIds: [...selectedItem.shipmentIds],
        };
      }
      return item;
    })
  );
};

const addToParent = (
  selectedItem: SummaryContext,
  parent: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState((prev) =>
    prev.map((item) => {
      if (item.id === parent.id) {
        return {
          ...item,
          shipmentIds: [...item.shipmentIds, ...selectedItem.shipmentIds],
        };
      }
      return item;
    })
  );
};

export const shipmentSelectionHandler = (
  event: MouseEvent<HTMLElement>,
  selectedShipments: SummaryContext[],
  selectedItem: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void,
  shipments?: Shipment[]
) => {
  event.stopPropagation();
  if (selectedItem.shipmentIds.length <= 0) {
    toast(toastMessage.inbound.assign.noShipments, {
      variant: "warning",
    });
    return;
  }
  const parent = selectedShipments.find((item) => item.id === selectedItem.id);
  // Multi-Selection by Ctrl/Meta + Left-Clicking on the items
  if (selectedShipments.length > 0 && (event.ctrlKey || event.metaKey)) {
    if (parent) {
      const shipmentId = parent.shipmentIds.find((id) =>
        selectedItem.shipmentIds.includes(id)
      );
      if (shipmentId) {
        removeFromParent(selectedItem, updateState);
      } else {
        addToParent(selectedItem, parent, updateState);
      }
    } else {
      addItem(selectedItem, updateState);
    }
  }
  // Group selection
  else if (shipments && selectedShipments.length > 0 && event.shiftKey) {
    const firstIndex =
      shipments.findIndex(
        (item) =>
          Number(item.id) ===
            selectedShipments.find(
              (shipment) => selectedItem.id === shipment.id
            )?.shipmentIds[0] ||
          selectedShipments.find((shipment) => shipment.id === item.id)?.id
      ) || 0;
    const selectedIndex =
      shipments.findIndex((item) =>
        selectedItem.shipmentIds.includes(Number(item.id))
      ) || 0;
    const toBeInserted: SummaryContext[] = [];
    // Select from top to bottom
    if (firstIndex < selectedIndex) {
      for (let index = firstIndex; index <= selectedIndex; index++) {
        toBeInserted.push({
          id: selectedItem.id,
          type: selectedItem.type,
          shipmentIds: [Number(shipments[index].id)],
        });
      }
      addGroup(unifyShipmentContext(toBeInserted), updateState);
    }
    // Select from bottom to top
    else {
      for (let index = firstIndex; index >= selectedIndex; index--) {
        toBeInserted.push({
          id: selectedItem.id,
          type: selectedItem.type,
          shipmentIds: [Number(shipments[index].id)],
        });
      }
      addGroup(unifyShipmentContext(toBeInserted), updateState);
    }
    return;
  }
  // Selection by simply Left-Clicking on the items
  else {
    if (parent) {
      const shipmentId = parent.shipmentIds.find((id) =>
        selectedItem.shipmentIds.includes(id)
      );
      if (shipmentId) {
        removeFromParent(selectedItem, updateState);
      } else {
        replaceParent(selectedItem, parent, updateState);
      }
    } else {
      replaceItem(selectedItem, updateState);
    }
  }
};

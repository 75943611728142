import {
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
  QueryKey,
} from "@tanstack/react-query";
import axios from "axios";
import { toast } from "./utils/snackbarHelper";
import { env } from "./utils/env";
import { toastMessage } from "./constants/strings";
import { useUnauthorizedErrorDialogStore } from "./stores/unauthorizedErrorDialog";
import { errorHandler, UNKNOWN_ERROR } from "./utils/outbound/errorHandler";

const isUnauthorizedError = (error: unknown) => {
  const responseStatus = axios.isAxiosError(error) && error.response?.status;
  return responseStatus === 403;
};

const generateErrorMessage = (
  err: Error,
  query: Query<unknown, unknown, unknown, QueryKey>
) => {
  if (typeof query.meta?.errorMessage === "string") {
    // Support specifying a custom error message via meta.errorMessage. More: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
    return query.meta.errorMessage;
  }

  const errorMessage = errorHandler(err);

  if (errorMessage === UNKNOWN_ERROR) {
    return toastMessage.generics.error;
  }

  return errorMessage;
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // This callback is only invoked once per Query per https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
    onError: (err: Error, query) => {
      // Log errors to the console in non-production environments to aid debugging
      if (env.VITE_ENVIRONMENT !== "prod") {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      // Display an error toast except when we're running tests.
      if (env.VITE_TEST !== "TRUE") {
        toast(generateErrorMessage(err, query), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: Error) => {
      const { setIsUnauthorizedErrorDialogOpen } =
        useUnauthorizedErrorDialogStore.getState();

      if (isUnauthorizedError(error)) {
        setIsUnauthorizedErrorDialogOpen(true);
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
          return false;
        }

        return failureCount < 3;
      },
    },
    mutations: {
      // Show an error toast when a mutation fails if the useQuery/useMutation doesn't define a custom onError.
      // Unlike the mutationCache declaration above, this is only invoked on error if the useQuery/useMutation doesn't define a custom onError.
      // More here: https://github.com/TanStack/query/issues/1927#issuecomment-1135517016
      onError: (error) => {
        toast(
          axios.isAxiosError(error)
            ? error.message
            : toastMessage.generics.error,
          {
            variant: "error",
          }
        );
      },
    },
  },
});

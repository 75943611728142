// eslint-disable-next-line no-restricted-imports
import { DragIndicator } from "@mui/icons-material";

type DragIconProps = {
  color?: string;
};

export default function DragIcon({ color = "text.secondary" }: DragIconProps) {
  return (
    <DragIndicator
      sx={{
        color,
        "&:hover": {
          cursor: "grab",
        },
      }}
    />
  );
}

import { PrintResult } from "../../../hooks/react-query/usePrint";

export const mockPrintResult = {
  default: {
    id: "123",
    state: "Processed",
    type: "CityLoadCardManifest",
    url: "",
  },
} as const satisfies Record<string, PrintResult>;

import { EquipmentTypeId } from "../../../../types/equipment.type";
import { planningStatus } from "../../../../types/status.type";

export type MergeDestination = {
  id?: number | null;
  doorId: number | null;
  startTime: string | null;
  trailerId: number | null;
  straightTruckId: number | null;
  tractorId: number | null;
  customName: string;
  originalName: string;
  driverId: number | null;
  planDate: number;
  status: string;
};

export type MergeRoutesTrapsDialogAction =
  | {
      type: "set_mergeDestination";
      mergeDestination: MergeDestination;
      equipmentTypeId: EquipmentTypeId | null;
    }
  | { type: "set_mergeIntoType"; mergeIntoType: MergeIntoType }
  | {
      type: "set_equipmentTypeId";
      equipmentTypeId: EquipmentTypeId | null;
    }
  | { type: "set_doorId"; doorId: number }
  | { type: "set_startTime"; startTime: string | null }
  | { type: "set_trailerId"; trailerId: number | null }
  | { type: "set_driverId"; driverId: number | null }
  | { type: "set_straightTruckId"; straightTruckId: number | null }
  | { type: "set_tractorId"; tractorId: number | null }
  | { type: "set_customName"; customName: string }
  | { type: "go_to_previous_step" }
  | { type: "go_to_next_step" }
  | { type: "clear" };

export type MergeIntoType = "route" | "trap";

type StepNumber = 1 | 2 | 3;

export interface MergeRoutesTrapsDialogState {
  selectedRouteIds: Array<number>;
  selectedTrapIds: Array<number>;
  mergeDestination: MergeDestination;
  mergeIntoType: MergeIntoType;
  currentStep: StepNumber;
  equipmentTypeId: EquipmentTypeId | null;
}

const emptyMergeDestination = {
  id: null,
  doorId: null,
  startTime: null,
  trailerId: null,
  straightTruckId: null,
  tractorId: null,
  driverId: null,
  customName: "",
  originalName: "",
  planDate: 0,
  status: planningStatus.CPLN,
};

export const initialState: MergeRoutesTrapsDialogState = {
  selectedRouteIds: [],
  selectedTrapIds: [],
  mergeDestination: emptyMergeDestination,
  equipmentTypeId: null,
  mergeIntoType: "route",
  currentStep: 1,
};

const goToNextStep = (
  state: MergeRoutesTrapsDialogState
): MergeRoutesTrapsDialogState => {
  const newStep = state.currentStep + 1;

  if (newStep > 2) throw Error("Step out of range");

  return {
    ...state,
    currentStep: newStep as StepNumber,
  };
};

const goToPreviousStep = (
  state: MergeRoutesTrapsDialogState
): MergeRoutesTrapsDialogState => {
  const newStep = state.currentStep - 1;

  if (newStep <= 0) throw Error("Step out of range");

  return {
    ...state,
    currentStep: newStep as StepNumber,
    mergeDestination:
      state.currentStep === 2 ? emptyMergeDestination : state.mergeDestination,
  };
};

export function mergeRoutesTrapsDialogReducer(
  state: MergeRoutesTrapsDialogState,
  action: MergeRoutesTrapsDialogAction
): MergeRoutesTrapsDialogState {
  const { type } = action;
  switch (type) {
    case "set_mergeDestination":
      return {
        ...state,
        mergeDestination: action.mergeDestination,
        equipmentTypeId: action.equipmentTypeId,
      };
    case "set_doorId":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          doorId: action.doorId,
        },
      };
    case "set_startTime":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          startTime: action.startTime,
        },
      };
    case "set_driverId":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          driverId: action.driverId,
        },
      };
    case "set_trailerId":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          trailerId: action.trailerId,
        },
      };
    case "set_straightTruckId":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          straightTruckId: action.straightTruckId,
        },
      };
    case "set_tractorId":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          tractorId: action.tractorId,
        },
      };
    case "set_customName":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          customName: action.customName,
        },
      };
    case "set_mergeIntoType":
      return {
        ...state,
        mergeIntoType: action.mergeIntoType,
      };
    case "set_equipmentTypeId":
      return {
        ...state,
        mergeDestination: {
          ...state.mergeDestination,
          tractorId: null,
          trailerId: null,
          straightTruckId: null,
        },
        equipmentTypeId: action.equipmentTypeId,
      };
    case "go_to_previous_step":
      return goToPreviousStep(state);
    case "go_to_next_step":
      return goToNextStep(state);
    case "clear":
      return {
        ...initialState,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

// CSpell:ignore resequenced Unassigning
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { forwardRef, Ref, useImperativeHandle, useState } from "react";
import Loading from "../../../components/shared/layout/Loading";
import { toastMessage } from "../../../constants/strings";

type ShipmentActionDialogStatus = "default" | "success" | "error" | "loading";

export const actionsMap = {
  unassign: {
    title: "Unassigning shipment...",
    description: "The shipment has been successfully unassigned",
  },
  assign: {
    title: "Assigning shipment...",
    description: "The shipment has been successfully assigned",
  },
  reorder: {
    title: "Reordering shipment...",
    description: "The shipment has been successfully resequenced",
  },
  editRoute: {
    title: "Editing route...",
    description: "The route has been successfully edited",
  },
  editTrap: {
    title: "Editing trap...",
    description: "The trap has been successfully edited",
  },
  deleteRoute: {
    title: "Deleting route...",
    description: "The route has been successfully deleted",
  },
  deleteRoutes: {
    title: "Deleting routes...",
    description: "The routes has been successfully deleted",
  },
  deleteDeliveryTraps: {
    title: "Deleting trap...",
    description: "The delivery trap has been successfully deleted",
  },
};

export type ShipmentActionTypeId = keyof typeof actionsMap;

export type ShipmentActionDialogRef = {
  open: (
    status: ShipmentActionDialogStatus,
    action: ShipmentActionTypeId
  ) => void;
  close: () => void;
};

type ShipmentActionDialogConfig = {
  status: ShipmentActionDialogStatus;
  action: ShipmentActionTypeId;
};

export const ShipmentActionDialog = forwardRef(
  (_, ref?: Ref<ShipmentActionDialogRef>) => {
    const defaultConfig: ShipmentActionDialogConfig = {
      status: "default",
      action: "assign",
    };
    const [config, setConfig] = useState<ShipmentActionDialogConfig | null>(
      defaultConfig
    );

    const { status, action } = config || defaultConfig;
    const { title } = actionsMap[action];

    useImperativeHandle(ref, () => ({
      open: (status, action) => {
        setConfig({ status, action });
      },
      close: () => {
        setConfig(defaultConfig);
      },
    }));

    return (
      <Dialog open={status !== "default"}>
        <DialogTitle sx={{ fontWeight: "bold" }}>{title}</DialogTitle>
        {status !== "error" && (
          <DialogContent
            sx={{ mt: 3, display: "flex", justifyContent: "center" }}
          >
            <Loading />
          </DialogContent>
        )}

        {status === "error" && (
          <>
            <DialogTitle sx={{ fontWeight: "bold" }}>{title}</DialogTitle>
            <DialogContent>
              <Box display="flex" justifyContent="center">
                <ErrorIcon sx={{ fontSize: "50px", color: "red" }} />
              </Box>
              <Typography mt={3} pr={4} pl={4}>
                {toastMessage.generics.error}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
              <Button
                onClick={() => setConfig(defaultConfig)}
                color="secondary"
                variant="outlined"
                sx={{ color: "secondary" }}
              >
                Continue
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
);

ShipmentActionDialog.displayName = "ShipmentActionDialog";

import { useMutation } from "@tanstack/react-query";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";

export const useAutoSequenceRoutes = () =>
  useMutation({
    mutationFn: async (routeIds: number[]) => {
      await http.post(apiUrls.autoSequenceRoutes(routeIds));
      return routeIds;
    },
  });

import { Table, TableContainer } from "@mui/material";
import PrintTableHead from "./PrintTableHead";
import PrintTableBody from "./PrintTableBody";
import { RenderType, RowData } from "./types";

export interface PrintTableColumn {
  header: string;
  renderAs: RenderType;
  dataIndex: keyof RowData;
}

interface PrintTableProps {
  columns: ReadonlyArray<PrintTableColumn>;
  isBulkPrinting: boolean;
}

const PrintTable = ({ columns, isBulkPrinting }: PrintTableProps) => (
  <TableContainer
    sx={{
      borderRadius: 2,
      border: "1px solid #0000001F",
      overflow: "hidden",
    }}
  >
    <Table>
      <PrintTableHead columns={columns} isBulkPrinting={isBulkPrinting} />
      <PrintTableBody columns={columns} isBulkPrinting={isBulkPrinting} />
    </Table>
  </TableContainer>
);

export default PrintTable;

import { Filter } from "../../../types/filter";

import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
} from "../utils";

export type ConsigneeZipQueryParam = "consigneeZip";
export type SomethingWithConsigneeZip = {
  consignee?: {
    zipCode?: string;
  };
};

export const getConsigneeZipFilter = (
  data: SomethingWithConsigneeZip[],
  prefix?: Prefix
): Filter => ({
  label: "Consignee Zip",
  queryParam: handlePrefix("consigneeZip", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.consignee?.zipCode))
  ),
});

export const filterConsigneeZip = <T extends SomethingWithConsigneeZip>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ consignee }) => filters.includes(consignee?.zipCode || ""))
    : data;

import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";
import { dateToInt } from "../../utils/dateTimeHelper";

const stateSchema = z.enum(["Unprocessed", "Processed"]);
const documentTypeSchema = z.enum([
  "LineHaulUnloadManifest",
  "CityLoadCardManifest",
  "Bulk",
  "TrapUnloadManifest",
]);

const printResultSchema = z.object({
  id: z.string(),
  state: stateSchema,
  type: documentTypeSchema,
  url: z.string(),
});

export type PrintResult = z.infer<typeof printResultSchema>;

type RouteIds = {
  routes: number[];
};

type TrapIds = {
  trapIds: number[];
};

type TripIds = {
  tripNumbers: number[];
};

type UsePrintBulkParams = {
  sicId: number;
  planDate: string;
  cityLoadCard?: RouteIds;
  trapLoadCard?: TrapIds;
  trapUnload?: TrapIds;
  lineHaulUnload?: TripIds;
  deliveryManifest?: RouteIds;
  deliveryReceipt?: RouteIds;
};

export const usePrintBulkReport = (
  onSuccess: (result: PrintResult) => void,
  onSettled?: (ids: number[]) => void
) =>
  useMutation({
    mutationFn: async ({
      sicId,
      cityLoadCard,
      trapUnload,
      trapLoadCard,
      lineHaulUnload,
      deliveryManifest,
      deliveryReceipt,
      planDate,
    }: UsePrintBulkParams): Promise<PrintResult> => {
      const { data } = await http.post(apiUrls.getBulkReport, {
        sicId,
        planDate: dateToInt(planDate),
        // TODO: IODD-3110 Remove sicId from inner objects and simply send ids once the BE is ready
        cityLoadCard: cityLoadCard && {
          ...cityLoadCard,
          sicId,
        },
        trapUnload: trapUnload && {
          ...trapUnload,
          sicId,
        },
        trapLoadCard: trapLoadCard && {
          ...trapLoadCard,
          sicId,
        },
        lineHaulUnload: lineHaulUnload && {
          ...lineHaulUnload,
          sicId,
        },
        deliveryManifest: deliveryManifest && {
          ...deliveryManifest,
          sicId,
        },
        deliveryReceipt: deliveryReceipt && {
          ...deliveryReceipt,
          sicId,
        },
      });
      return printResultSchema.parse(data);
    },
    onSuccess,
    onSettled: (_, _error, variables: UsePrintBulkParams) => {
      const ids: number[] = [];
      const addIds = (newIds: number[] | undefined) => {
        if (newIds) ids.push(...newIds);
      };

      addIds(variables.cityLoadCard?.routes);
      addIds(variables.trapLoadCard?.trapIds);
      addIds(variables.trapUnload?.trapIds);
      addIds(variables.lineHaulUnload?.tripNumbers);
      addIds(variables.deliveryManifest?.routes);
      addIds(variables.deliveryReceipt?.routes);
      onSettled && onSettled(ids);
    },
  });

import { useActionsMenuContext } from "./ActionsMenuContext";
import { MergeTrapsDialogOld } from "./MergeTrapDialogOld";

const MergeTrapsDefaultDialogOld = () => {
  const {
    closeDialog,
    shipments: selectedTraps,
    clearSelection,
  } = useActionsMenuContext();

  return (
    <MergeTrapsDialogOld
      visible
      clearSelection={clearSelection}
      handleClose={closeDialog}
      selectedTraps={selectedTraps}
    />
  );
};

export default MergeTrapsDefaultDialogOld;

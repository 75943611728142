import { Filter } from "../../../types/filter";
import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
} from "../utils";

export type ShipperNameQueryParam = "shipperName";
export type SomethingWithShipper = {
  shipper?: {
    name?: string;
  };
};

export const getShipperFilter = (
  data: SomethingWithShipper[],
  prefix?: Prefix
): Filter => ({
  label: "Shipper Name",
  queryParam: handlePrefix("shipperName", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.shipper?.name))
  ),
});

export const filterShipper = <T extends SomethingWithShipper>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ shipper }) => filters.includes(shipper?.name || ""))
    : data;

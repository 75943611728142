import { DeliveryEquipmentUsage } from "../../../types/deliveryEquipment.type";
import {
  AssignmentDetails,
  getAssignment,
} from "../../../types/assignment.type";
import { Equipment } from "../../../services/prePlanningService.types";
import { EquipmentType, equipmentTypeMap } from "../../../types/equipment.type";

export const trailerAvailability = {
  ASSIGNED: "Assigned Trailers",
  AVAILABLE: "Available Trailers",
  INACTIVE: "Inactive Trailers",
} as const;

export const tractorAvailability = {
  ASSIGNED: "Assigned Tractors",
  AVAILABLE: "Available Tractors",
  INACTIVE: "Inactive Tractors",
} as const;

export const straightTruckAvailability = {
  ASSIGNED: "Assigned STs",
  AVAILABLE: "Available STs",
  INACTIVE: "Inactive STs",
} as const;

const typeAvailabilityMap: Record<
  EquipmentType,
  | typeof tractorAvailability
  | typeof trailerAvailability
  | typeof straightTruckAvailability
> = {
  trailer: trailerAvailability,
  tractor: tractorAvailability,
  straightTruck: straightTruckAvailability,
};

type EquipmentOption = {
  name: string;
  value: number;
  group: string;
  assignment: AssignmentDetails | null;
  equipmentType: number;
};

const getEquipmentOptions = (
  equipments: DeliveryEquipmentUsage[],
  initialEquipment: Equipment | null = null,
  equipmentType: EquipmentType
) => {
  const equipmentOptions: EquipmentOption[] = equipments.map((equipment) => ({
    name: equipment.name,
    value: equipment.id,
    group: equipment.inUseByInfo
      ? typeAvailabilityMap[equipmentType].ASSIGNED
      : typeAvailabilityMap[equipmentType].AVAILABLE,
    assignment: getAssignment(
      equipment.planningAssignmentsInfo,
      equipment.inUseByInfo
    ),
    equipmentType: equipment.typeId,
  }));
  if (
    initialEquipment &&
    !equipmentOptions.some(
      (equipment) => equipment.value === initialEquipment.id
    )
  ) {
    equipmentOptions.push({
      name: initialEquipment.name ?? "",
      value: initialEquipment.id,
      group: typeAvailabilityMap[equipmentType].INACTIVE,
      assignment: null,
      equipmentType: equipmentTypeMap[equipmentType],
    });
  }
  return equipmentOptions.sort((a, b) => b.group.localeCompare(a.group));
};

export const getTrailerOptions = (
  trailers: DeliveryEquipmentUsage[],
  initialTrailer: Equipment | null = null
) => getEquipmentOptions(trailers, initialTrailer, "trailer");

export const getTractorOptions = (
  tractors: DeliveryEquipmentUsage[],
  initialTractor: Equipment | null = null
) => getEquipmentOptions(tractors, initialTractor, "tractor");

export const getStraightTruckOptions = (
  straightTrucks: DeliveryEquipmentUsage[],
  initialStraightTruck: Equipment | null = null
) => getEquipmentOptions(straightTrucks, initialStraightTruck, "straightTruck");

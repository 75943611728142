import dayjs from "dayjs";
import { ChangeRequestLog } from "../../hooks/react-query/linehaul/useChangeRequestLog";
import { companyOwnedServiceCenters } from "../serviceCenter/serviceCenters.mocks";

const date = dayjs("2023-11-27T00:00:00.000Z");

export const mockChangeRequestLogs = {
  empty: [],
  default: [
    {
      id: 1,
      status: "ORIGINAL",
      createdBy: "John Doe",
      createdOn: date.subtract(1, "day"),
      modifiedBy: "Jane Doe",
      modifiedOn: date,
      pickupDate: date.set("hour", 12),
      originSic: companyOwnedServiceCenters[0],
      destinationSic: companyOwnedServiceCenters[1],
    },
    {
      id: 2,
      status: "APPROVED",
      createdBy: "John Doe",
      createdOn: date.subtract(1, "day"),
      modifiedBy: "Jane Doe",
      modifiedOn: date,
      pickupDate: date.add(1, "day"),
    },
    {
      id: 3,
      status: "PENDING",
      createdBy: "John Doe",
      createdOn: date.subtract(2, "day"),
      modifiedBy: "Jane Doe",
      modifiedOn: date,
      originSic: companyOwnedServiceCenters[4],
    },
    {
      id: 4,
      status: "DENIED",
      createdBy: "John Doe",
      createdOn: date.subtract(2, "day"),
      modifiedBy: "Jane Doe",
      modifiedOn: date,
      destinationSic: companyOwnedServiceCenters[5],
    },
    {
      id: 5,
      status: "APPROVED",
      createdBy: "John Doe",
      createdOn: date.subtract(2, "day"),
      modifiedBy: "Jane Doe",
      modifiedOn: date,
      pickupDate: date.set("hour", 14).set("minute", 45),
    },
    {
      id: 6,
      status: "PENDING",
      createdBy: "John Doe",
      createdOn: date.subtract(2, "day"),
      modifiedBy: "Jane Doe",
      modifiedOn: date,
      stopSic: companyOwnedServiceCenters[2],
    },
  ],
} as const satisfies Record<string, ChangeRequestLog[]>;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrls } from "../../utils/apiUrls";
import { http } from "../../utils/httpCommon";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";
import { PlanTableFormat } from "../../types/planning/plan.type";
import { plannedRoutesQueryKey } from "./usePlannedRoutes";
import { deliveryRoutesKeys } from "./useDeliveryRoutes";

type RouteAssignmentProps = {
  date: number;
  sicId: number;
  searchTerm: string;
  onSuccess: () => Promise<void> | void;
};

interface RouteAssignmentDeleteProps extends RouteAssignmentProps {
  onSettled?: () => void;
}

type DeleteDeliveryTrapsAssignmentRequest = {
  trapIds: number[];
};

export const useDeleteDeliveryTrapsAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
  onSettled,
}: RouteAssignmentDeleteProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ trapIds }: DeleteDeliveryTrapsAssignmentRequest) => {
      const url = apiUrls.deleteDeliveryTraps(trapIds);
      const { status } = await http.delete(url);
      return status;
    },
    onSuccess: async (_, { trapIds }) => {
      queryClient.setQueryData<PlanTableFormat[]>(
        plannedRoutesQueryKey(date, sicId, searchTerm),
        (previousStateOfPlans) => {
          if (!previousStateOfPlans) return [];
          return previousStateOfPlans.filter(
            (previousPlan) => !trapIds.includes(previousPlan.id)
          );
        }
      );
      await queryClient.invalidateQueries({
        queryKey: deliveryRoutesKeys.deliveryRoutesSummary(sicId, date),
      });
      onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
    onSettled: () => {
      onSettled?.();
    },
  });
};

export const useOldDeleteDeliveryTrapsAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
  onSettled,
}: RouteAssignmentDeleteProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ trapIds }: DeleteDeliveryTrapsAssignmentRequest) => {
      const url = apiUrls.deleteDeliveryTraps(trapIds);
      const { status } = await http.delete(url);
      return status;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
      await queryClient.invalidateQueries({
        queryKey: deliveryRoutesKeys.deliveryRoutesSummary(sicId, date),
      });
      onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
    onSettled: () => {
      onSettled?.();
    },
  });
};

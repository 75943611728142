// cspell:ignore toggleable

import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { z } from "zod";
import {
  DepartmentUrlKey,
  DOCK_AUTOMATION_URL_KEY,
  HOME_URL_KEY,
  LINEHAUL_URL_KEY,
  OPERATIONS_URL_KEY,
  SETTINGS_URL_KEY,
} from "./../components/shared/nav/useDepartments";

const splitResponseSchema = z.object({
  "operations-client": z.object({ treatment: z.enum(["on", "off"]) }),
  "linehaul-client": z.object({ treatment: z.enum(["on", "off"]) }),
  "dock-automation-client": z.object({ treatment: z.enum(["on", "off"]) }),
});

type ToggleableDepartment = Exclude<
  DepartmentUrlKey,
  typeof HOME_URL_KEY | typeof SETTINGS_URL_KEY
>;

const departments: ToggleableDepartment[] = [
  OPERATIONS_URL_KEY,
  LINEHAUL_URL_KEY,
  DOCK_AUTOMATION_URL_KEY,
] as const;

type GetEnabledDepartmentsResponse = {
  isLoading: boolean;
  departmentIsEnabledMap: Record<ToggleableDepartment, boolean>;
};

export const useGetEnabledDepartments = (): GetEnabledDepartmentsResponse => {
  const { treatments, isReady } = useSplitTreatments({
    names: departments.map((key) => `${key}-client`),
  });

  function getDepartmentEnabled() {
    const departments = splitResponseSchema.parse(treatments);

    return {
      operations: departments["operations-client"].treatment === "on",
      linehaul: departments["linehaul-client"].treatment === "on",
      "dock-automation":
        departments["dock-automation-client"].treatment === "on",
    };
  }

  return {
    isLoading: !isReady,
    departmentIsEnabledMap: isReady
      ? getDepartmentEnabled()
      : // Default to these initial values if not ready yet.
        {
          operations: false,
          linehaul: false,
          "dock-automation": false,
        },
  };
};

import { MouseEvent, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Droppable } from "@hello-pangea/dnd";
import ShipmentItem from "../AccordionShipmentItem/ShipmentItem";
import {
  getDroppableId,
  getDroppableStyleCard,
} from "../../../../utils/dragging";
import { useSelectionContext } from "../../../../context/SelectionContext";
import ContextMenuDropdown, {
  ContextMenuRef,
} from "../../../../components/shared/ContextMenuDropdown";
import { SummaryContext, useActionsMenuContext } from "../ActionsMenuContext";
import { contextMenuActions } from "../../prePlanning/tables/contextMenuActions";
import { useIsSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { ShipmentWithRouteId } from "./SpecialShipments";

const section = "specialShipments";
const type = "route";

type ShipmentsSectionProps = {
  planDate: string;
  shipments: ShipmentWithRouteId[];
};

export const ShipmentsSection = ({
  planDate,
  shipments,
}: ShipmentsSectionProps) => {
  const isEditingAllowed = useIsSelectedDateTodayOrInTheFutureForCurrentSic();
  const { selected, handleSelect, isSelected, clearSelection } =
    useSelectionContext();

  const { openDialog } = useActionsMenuContext();
  const contextMenuRef = useRef<ContextMenuRef<ShipmentWithRouteId>>(null);
  const [contextMenuShipment, setContextMenuShipment] =
    useState<SummaryContext | null>();

  const handleSelection = (
    event: MouseEvent<HTMLElement>,
    ids: number[],
    routeId: number
  ) => {
    if (isEditingAllowed) {
      if (contextMenuShipment) {
        setContextMenuShipment(null);
      }
      handleSelect(
        event,
        {
          id: routeId,
          type,
          shipmentIds: ids.map(Number),
        },
        shipments
      );
    }
  };

  const handleRightClick = (
    ev: MouseEvent<HTMLElement>,
    shipmentIds: number[],
    routeId: number
  ) => {
    if (!isEditingAllowed) return;
    ev.stopPropagation();
    contextMenuRef.current?.openContextMenu(ev);
    if (selected.length === 0) {
      setContextMenuShipment({
        id: routeId,
        type,
        shipmentIds,
      });
    }
  };

  const contextMenuShipments = contextMenuShipment
    ? [contextMenuShipment]
    : selected.length > 0
      ? selected
      : [];

  return (
    <>
      <Droppable
        isDropDisabled={!isEditingAllowed}
        // We pass 0 as a parameter because the resulting string needs to be formatted using some kind of ID.
        // This ID isn't necessary in this case to make the drag and drop functionality work.
        droppableId={getDroppableId("sidebar", type, 0)}
      >
        {(droppableProvided, snapshot) => (
          <Box
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            sx={{
              ...getDroppableStyleCard(snapshot.isDraggingOver),
            }}
          >
            {shipments.map((shipment, index) => (
              <Box key={shipment.id}>
                <ShipmentItem
                  index={index}
                  section={section}
                  item={shipment}
                  isDraggable={isEditingAllowed}
                  isSelected={isSelected(Number(shipment.id), type)}
                  assignedRoute={shipment.assignment}
                  onSelected={(e, ids) => {
                    handleSelection(e, ids, shipment.routeId);
                  }}
                  onRightClick={(ev, shipmentId) =>
                    handleRightClick(ev, shipmentId, shipment.routeId)
                  }
                  planDate={planDate}
                />
                {droppableProvided.placeholder}
              </Box>
            ))}
          </Box>
        )}
      </Droppable>
      {isEditingAllowed && (
        <ContextMenuDropdown
          ref={contextMenuRef}
          actions={() =>
            contextMenuActions({
              shipmentContext: contextMenuShipments,
              section,
              openDialog,
              clearSelection,
            })
          }
        />
      )}
    </>
  );
};

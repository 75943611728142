import { Filter } from "../../../types/filter";
import { dateToFormat } from "../../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../../DaylightDateFormat";

import {
  Prefix,
  createNoDateOption,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  sortByDate,
  sortNoDateFirst,
} from "../utils";

export type AppointmentDateQueryParam = "appointmentDate";

type SomethingWithAppointmentDate = Record<
  AppointmentDateQueryParam,
  Date | null
>;

export const getAppointmentDateFilter = (
  data: SomethingWithAppointmentDate[],
  timeZone: string,
  prefix?: Prefix
): Filter => {
  const options = getNonEmptyUniqueSortedOptions(
    data.map(({ appointmentDate }) =>
      createNoDateOption(
        dateToFormat(appointmentDate, DaylightDateFormat.DATE, timeZone)
      )
    ),
    sortByDate
  );

  const sortedOptions = sortNoDateFirst(options);

  return {
    label: "Appointment Date",
    queryParam: handlePrefix("appointmentDate", prefix),
    options: sortedOptions,
  };
};

export const filterAppointmentDate = <T extends SomethingWithAppointmentDate>(
  data: T[],
  timeZone: string,
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ appointmentDate }) => {
        if (filters.includes("No Date")) {
          if (appointmentDate === null) {
            return true;
          }
        }
        const formattedAppointmentDate = dateToFormat(
          appointmentDate,
          DaylightDateFormat.DATE,
          timeZone
        );
        return filters.includes(formattedAppointmentDate);
      })
    : data;

import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ToggleViewGroup,
  ToggleViewGroupProps,
} from "../../../../components/shared/ToggleViewGroup";
import { PrePlanningSection } from "../../../../types/inbound/prePlanning/sections.type";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { SELECTED_DATE_PARAM_KEY } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useUnplannedDatetimeSearchParam } from "../../../../hooks/useUnplannedDatetimeSearchParam";

type PrePlanningToggleViewGroupProps<T> = Pick<
  ToggleViewGroupProps<T>,
  "options"
>;

export const PrePlanningToggleViewGroup = <T,>({
  options,
}: PrePlanningToggleViewGroupProps<T>) => {
  const { unplannedDateTime } = useUnplannedDatetimeSearchParam();
  const [serviceCenter] = useSelectedServiceCenter();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const selectedDate = searchParams.get(SELECTED_DATE_PARAM_KEY) || "";

  const getRouteFragment = (): string => {
    const routeArr = pathname.split("/");
    return routeArr[routeArr.length - 1];
  };

  const tab = getRouteFragment();

  const handleViewChange = (newTab: PrePlanningSection) => {
    navigate({
      pathname: `/operations/${serviceCenter.code}/inbound/pre-planning/${newTab}`,
      search: createSearchParams({
        date: selectedDate,
        unplannedDateTime,
      }).toString(),
    });
  };

  return (
    <ToggleViewGroup
      tab={tab}
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      onViewChange={(tab) => handleViewChange(tab as PrePlanningSection)}
      options={options}
    />
  );
};

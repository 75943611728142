import { Filter } from "../../../types/filter";

import { dateToFormat } from "../../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../../DaylightDateFormat";
import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  sortEtaDate,
  stringOptionFactory,
} from "../utils";

export type ETAQueryParam = "eta";

type SomethingWithEta = {
  eta?: Date;
  etaDate?: Date | null;
};

export const getETAFilter = (
  data: SomethingWithEta[],
  timeZone: string,
  prefix?: Prefix
): Filter => ({
  label: "ETA",
  queryParam: handlePrefix("eta", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map(({ eta, etaDate }) =>
      stringOptionFactory(
        dateToFormat(eta ?? etaDate, DaylightDateFormat.DATE, timeZone)
      )
    ),
    sortEtaDate
  ),
});

export const filterETA = <T extends SomethingWithEta>(
  data: T[],
  timeZone: string,
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ eta, etaDate }) => {
        const formattedETA = dateToFormat(
          eta ?? etaDate,
          DaylightDateFormat.DATE,
          timeZone
        );
        return filters.includes(formattedETA);
      })
    : data;

import { Button } from "@mui/material";
import { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AddOutlined, ViewModule } from "@mui/icons-material";
import CollapsibleSectionItem from "../../shared/AccordionShipmentItem/CollapsibleSectionItem";
import { AccordionWithContentAndActionBar } from "../AccordionWithContentAndActionBar";
import Loading from "../../../../components/shared/layout/Loading";
import { Trap } from "../../../../types/planning/trap.type";
import { useActionsMenuContext } from "../../shared/ActionsMenuContext";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { useGetUnplannedTrapsSummary } from "../../../../hooks/react-query/preplanning/useTraps";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { InboundTab } from "../../InboundLayout";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { MergeButton } from "../MergeButton";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";

type TrapProps = {
  tab: InboundTab;
};

type TrapSectionItemProps = {
  tab: InboundTab;
  traps?: Trap[];
  trap: Trap;
  sectionExpanded: boolean;
  isEditingEnabled: boolean;
  selectedDate: string;
  serviceCenterId: number;
};

const TrapSectionItem = memo(
  ({
    tab,
    traps,
    trap,
    sectionExpanded,
    isEditingEnabled,
    selectedDate,
    serviceCenterId,
  }: TrapSectionItemProps) => {
    const {
      id,
      title,
      name,
      shipmentsCount,
      shipmentsPallets,
      shipmentsPieces,
      shipmentsWeight,
      shipmentsWeightUnit = "lbs",
      shipmentIds,
    } = trap;
    const [searchParams] = useSearchParams();

    const isNew = id === parseInt(searchParams.get("highlightedTrapId") ?? "0");

    return (
      <CollapsibleSectionItem
        id={id}
        title={title}
        name={name}
        shipmentIds={shipmentIds}
        shipmentsCount={shipmentsCount}
        shipmentsPallets={shipmentsPallets}
        shipmentsPieces={shipmentsPieces}
        shipmentsWeight={shipmentsWeight}
        shipmentsWeightUnit={shipmentsWeightUnit}
        serviceCenterId={serviceCenterId}
        planDate={selectedDate}
        isEditingAllowed={isEditingEnabled}
        isNew={isNew}
        section="traps"
        isDropDisabled={!sectionExpanded}
        parentRecords={traps}
        isAvailableForMassage={true}
        sortBy={tab === "pre-planning" ? "presetRoute" : "serviceDue"}
      />
    );
  }
);
TrapSectionItem.displayName = "TrapSectionItem";

export const Traps = ({ tab }: TrapProps) => {
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter] = useSelectedServiceCenter();
  const { openDialog } = useActionsMenuContext();
  const { selected, clearSelection } = useSelectionContext();
  const planDate = dateToInt(selectedDate);
  const [isExpanded, setIsExpanded] = useState(false);

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const { data: traps } = useGetUnplannedTrapsSummary(
    serviceCenter.id,
    planDate
  );

  if (!traps) return <Loading label="sidebar traps" />;
  const selectedTraps = selected.filter((s) => s.type === "trap");

  return (
    <AccordionWithContentAndActionBar
      onChange={(value: boolean) => setIsExpanded(value)}
      title={`Traps (${traps.length})`}
      icon={<ViewModule />}
      actionButtons={
        isEditingAllowed
          ? [
              <Button
                variant="outlined"
                startIcon={<AddOutlined />}
                sx={{ color: "#581FE4", borderColor: "#581FE4" }}
                key={1}
                onClick={() => {
                  openDialog({
                    name: "create-new-trap",
                    shipmentContext: [],
                  });
                }}
              >
                Add Trap
              </Button>,
              <MergeButton
                key={2}
                selected={selectedTraps}
                parentRecords={traps}
                openDialog={() => {
                  openDialog({
                    name: "merge-traps",
                    shipmentContext: selectedTraps,
                    clearSelection: () => clearSelection("trap"),
                  });
                }}
              >
                Merge Traps
              </MergeButton>,
            ]
          : []
      }
    >
      {traps.map((trap) => (
        <TrapSectionItem
          key={trap.id}
          tab={tab}
          traps={traps}
          trap={trap}
          sectionExpanded={isExpanded}
          isEditingEnabled={isEditingAllowed}
          selectedDate={selectedDate}
          serviceCenterId={serviceCenter.id}
        />
      ))}
    </AccordionWithContentAndActionBar>
  );
};

import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Close } from "@mui/icons-material";
import { AppTheme } from "../../../theme";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import {
  planningStatus,
  type NonDeliveredStatuses,
} from "../../../types/status.type";

interface DeleteRouteAssignmentsConfirmDialogProps {
  plans: PlanTableFormat[];
  open: boolean;
  loading: boolean;
  onConfirm: (transferShipmentPlanId?: number) => void;
  onCancel: () => void;
}
export default function DeleteRouteAssignmentConfirmDialog({
  plans,
  open,
  loading,
  onConfirm,
  onCancel,
}: DeleteRouteAssignmentsConfirmDialogProps) {
  const hasNoPlansSelected = plans.length === 0;
  const hasAnyRouteWithZeroBills = plans.some((p) => p.bills > 0);
  const hasAllRoutesNonDepartedStatus = plans.every(
    (p) =>
      p.type === "delivery_trap" ||
      [
        planningStatus.CPLN,
        planningStatus.CLDK,
        planningStatus.CLDV,
        planningStatus.DISP,
      ].includes(p.status as NonDeliveredStatuses)
  );
  const hasError =
    hasAnyRouteWithZeroBills ||
    !hasAllRoutesNonDepartedStatus ||
    hasNoPlansSelected;

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "600", padding: "20px 0px 10px 20px" }}>
        {hasError
          ? "Delete Requirement"
          : `Delete "${plans.map((p) => p.name).join(", ")}"?`}
      </DialogTitle>
      <Box position="absolute" top={4} right={4}>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>

      {hasError ? (
        <>
          <DialogContent sx={{ padding: "10px 20px" }}>
            {hasNoPlansSelected
              ? "Please select at least one row to delete."
              : hasAnyRouteWithZeroBills
                ? "A route or trap must have zero bills before deleting. Please remove any associated bills."
                : "Please select only routes with CPLN, CLDK, CLDV or DISP status to delete."}
          </DialogContent>
          <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
            <Button
              onClick={onCancel}
              sx={{ backgroundColor: AppTheme.palette.primary.main }}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent
            sx={{ padding: "10px 0px 0px 20px", minHeight: "31px" }}
          >{`Are you sure you want to delete: "${plans
            .map((p) => p.name)
            .join(", ")}"? This action cannot be undone.`}</DialogContent>

          <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
            <Button
              onClick={onCancel}
              sx={{ color: "black" }}
              variant="outlined"
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={() => onConfirm()}
              sx={{ backgroundColor: AppTheme.palette.error.main }}
              color="error"
              variant="contained"
              loadingLabel="Deleting..."
              isLoading={loading}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

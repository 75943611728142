import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrls } from "../../utils/apiUrls";
import { http } from "../../utils/httpCommon";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";
import {
  RouteAssignmentResponse,
  TrapAssignmentResponse,
  routeAssignmentResponseSchema,
  trapAssignmentResponseSchema,
} from "../../types/planning/routeAssignmentResponse.type";
import { PlanTableFormat } from "../../types/planning/plan.type";
import { EditedRoute } from "./useDeliveryRoutes.types";
import { plannedRoutesQueryKey } from "./usePlannedRoutes";
import { deliveryRoutesKeys } from "./useDeliveryRoutes";

type RouteAssignmentProps = {
  date: number;
  sicId: number;
  searchTerm: string;
  onSuccess: (
    response?: RouteAssignmentResponse | TrapAssignmentResponse,
    request?: RouteAssignmentRequest
  ) => Promise<void> | void;
  onSettled?: () => void;
};

type RouteAssignmentRequest = {
  planId: number;
  route: EditedRoute;
};

type DeleteRouteAssignmentRequest = {
  planIds: number[];
};

export const useRouteAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
  onSettled,
}: RouteAssignmentProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ planId, route }: RouteAssignmentRequest) => {
      if (route.type === "delivery_route") {
        const { data } = await http.put<RouteAssignmentResponse>(
          apiUrls.putRouteAssignment(planId),
          route
        );
        return routeAssignmentResponseSchema.parse(data);
      } else {
        const { data } = await http.put<TrapAssignmentResponse>(
          apiUrls.putTrapAssignment(planId),
          route
        );
        return trapAssignmentResponseSchema.parse(data);
      }
    },
    onSuccess: async (
      response: RouteAssignmentResponse | TrapAssignmentResponse,
      variables
    ) => {
      await queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
      await onSuccess(response, variables);
    },
    onSettled,
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};

export const useOldRouteAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
  onSettled,
}: RouteAssignmentProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ planId, route }: RouteAssignmentRequest) => {
      if (route.type === "delivery_route") {
        const { data } = await http.put<RouteAssignmentResponse>(
          apiUrls.putRouteAssignment(planId),
          route
        );
        return routeAssignmentResponseSchema.parse(data);
      } else {
        const { data } = await http.put<TrapAssignmentResponse>(
          apiUrls.putTrapAssignment(planId),
          route
        );
        return trapAssignmentResponseSchema.parse(data);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
      await onSuccess();
    },
    onSettled,
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};

export const useDeleteRouteAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
  onSettled,
}: RouteAssignmentProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ planIds }: DeleteRouteAssignmentRequest) => {
      const { status } = await http.delete(
        apiUrls.deleteRouteAssignments(planIds.join(","))
      );
      return status;
    },
    onSuccess: async (_, { planIds }) => {
      queryClient.setQueryData<PlanTableFormat[]>(
        plannedRoutesQueryKey(date, sicId, searchTerm),
        (previousStateOfPlans) => {
          if (!previousStateOfPlans) return [];
          return previousStateOfPlans.filter(
            (previousPlan) => !planIds.includes(previousPlan.id)
          );
        }
      );
      await queryClient.invalidateQueries({
        queryKey: deliveryRoutesKeys.deliveryRoutesSummary(sicId, date),
      });
      await onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
    onSettled: () => {
      onSettled?.();
    },
  });
};

export const useOldDeleteRouteAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
  onSettled,
}: RouteAssignmentProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ planIds }: DeleteRouteAssignmentRequest) => {
      const { status } = await http.delete(
        apiUrls.deleteRouteAssignments(planIds.join(","))
      );
      return status;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
      await queryClient.invalidateQueries({
        queryKey: deliveryRoutesKeys.deliveryRoutesSummary(sicId, date),
      });
      await onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
    onSettled: () => {
      onSettled?.();
    },
  });
};

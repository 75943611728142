import { Filter, Option } from "../../../types/filter";
import {
  Prefix,
  getUniqueOptions,
  handlePrefix,
  isActiveFilter,
} from "../utils";

export type PalletsQueryParam = "pallets";
export type SomethingWithPallets = Record<PalletsQueryParam, number>;

const getPalletsOptions = (data: SomethingWithPallets[]): Option[] =>
  getUniqueOptions(
    data
      .map(({ pallets }) => ({
        label: pallets.toLocaleString(),
        value: pallets,
      }))
      .sort((a, b) => a.value - b.value)
  );

export const getPalletsFilter = (
  data: SomethingWithPallets[],
  prefix?: Prefix
): Filter => ({
  label: "Pallets",
  queryParam: handlePrefix("pallets", prefix),
  options: getPalletsOptions(data),
});

export const filterPallets = <T extends SomethingWithPallets>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter((entry) => filters.includes(entry.pallets.toString()))
    : data;

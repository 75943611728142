import dayjs from "dayjs";
import { ClosedTripDetails } from "../../hooks/react-query/linehaul/useTrip";

export const mockedClosedTripDetails = {
  default: {
    tripNumber: 1000,
    closedDate: dayjs(),
    cubage: 12,
    trailerNumber: "654987",
    sealNumber: "123345",
    isDyltTrailer: false,
    scacCode: "ALMA",
  },
} satisfies Record<string, ClosedTripDetails>;

import { GetStaticLoadTemplatesResponse } from "../../hooks/react-query/outbound/useStaticLoadTemplate";

export const mockStaticLoadTemplatesList = {
  empty: [],
  default: [
    {
      templateId: 2,
      name: "Test Template 1",
      isActive: false,
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      updatedBy: "Daylight Supervisor",
      updatedAt: new Date("2023-09-09T20:19:46.847179"),
      createdBy: "Daylight Supervisor",
      createdAt: new Date("2023-09-08T20:19:46.847179"),
    },
    {
      templateId: 1,
      name: "Test Template 2",
      isActive: false,
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      updatedBy: "Daylight Supervisor",
      updatedAt: new Date("2023-09-09T20:19:46.847179"),
      createdBy: "Daylight Supervisor",
      createdAt: new Date("2023-09-08T20:19:46.847179"),
    },
  ],
} as const satisfies Record<string, GetStaticLoadTemplatesResponse>;

import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  SummaryContext,
  useActionsMenuContext,
} from "../shared/ActionsMenuContext";
import { unifyShipmentContext } from "../../../utils/unifyShipmentContext";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { Route } from "../../../services/prePlanningService.types";
import { Trap } from "../../../types/planning/trap.type";
import { PrePlanningSectionCanDoDelete } from "../../../types/inbound/prePlanning/sections.type";
import { buttonStyle } from "../prePlanning/actionbar/StyledMenuButton";
import DeleteDialog from "./DeleteDialog";

type DeleteButtonProps = {
  activeTab: PrePlanningSectionCanDoDelete;
  selectedTraps: Trap[];
  selectedRoutes: Route[];
  clearSelection: () => void;
};

const DeleteButton = ({
  activeTab,
  selectedTraps,
  selectedRoutes,
  clearSelection,
}: DeleteButtonProps) => {
  const { activeDialog, openDialog } = useActionsMenuContext();
  const addTrapsToShipmentContext = () => {
    const context: SummaryContext[] = selectedTraps.map((trap) => ({
      id: trap.id,
      shipmentIds: trap.shipmentIds,
      type: "trap",
    }));
    return unifyShipmentContext(context);
  };

  const addRoutesToShipmentContext = () => {
    const context: SummaryContext[] = selectedRoutes.map((route) => ({
      id: route.id,
      shipmentIds: route.shipmentIds,
      type: "route",
    }));
    return unifyShipmentContext(context);
  };

  const handleDelete = () => {
    switch (activeTab) {
      case "routes": {
        if (selectedRoutes.length === 0) {
          toast(toastMessage.inbound.selectOneRoute, {
            variant: "warning",
          });
        } else if (
          selectedRoutes.every((route) => route.shipmentsCount === 0)
        ) {
          openDialog({
            name: "delete-route",
            shipmentContext: addRoutesToShipmentContext(),
          });
        } else {
          toast(toastMessage.inbound.selectEmptyRoutes, {
            variant: "warning",
          });
        }
        break;
      }
      case "traps": {
        if (selectedTraps.length === 0) {
          toast(toastMessage.inbound.selectOneTrap, {
            variant: "warning",
          });
        } else if (selectedTraps.every((trap) => trap.shipmentsCount === 0)) {
          openDialog({
            name: "delete-trap",
            shipmentContext: addTrapsToShipmentContext(),
          });
        } else {
          toast(toastMessage.inbound.selectEmptyTraps, {
            variant: "warning",
          });
        }
      }
    }
  };

  return (
    <>
      <Button
        sx={buttonStyle}
        variant="outlined"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
      >
        Delete
      </Button>
      {(activeDialog === "delete-trap" || activeDialog === "delete-route") && (
        <DeleteDialog
          activeTab={activeTab}
          selectedTraps={selectedTraps}
          selectedRoutes={selectedRoutes}
          clearSelection={clearSelection}
        />
      )}
    </>
  );
};

export default DeleteButton;

import styled from "@emotion/styled";
import { JSX, PropsWithChildren, ReactNode } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Title } from "../../../components/shared/Title";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../../components/shared/layout/Accordion";

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

const ActionButtonsBox = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
`;

interface Props {
  title: string;
  icon?: JSX.Element;
  accordionSummaryHeight?: string | number;
  actionButtons?: ReactNode[];
  onChange: (isExpanded: boolean) => void;
  isExpanded?: boolean;
}

/** Shipment item preview with basic information */
export const AccordionWithContentAndActionBar = ({
  children,
  onChange,
  actionButtons = [],
  isExpanded,
  ...props
}: PropsWithChildren<Props>) => (
  <Accordion
    onChange={(_, isExpanded) => onChange(isExpanded)}
    expanded={isExpanded}
  >
    <StyledAccordionSummary
      sx={{ height: props.accordionSummaryHeight || 20 }}
      expandIcon={<ArrowDropDown sx={{ fontSize: "2rem", color: "white" }} />}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography height="24px" color="white">
          {props.icon}
        </Typography>
        <Title
          padding="0 12px"
          weight="bold"
          variant="h4"
          fontSize={16}
          color="white"
        >
          {props.title}
        </Title>
      </Box>
    </StyledAccordionSummary>
    <AccordionDetails sx={{ padding: 0 }}>
      {actionButtons.length > 0 && (
        <ActionButtonsBox>{actionButtons}</ActionButtonsBox>
      )}
      {children}
    </AccordionDetails>
  </Accordion>
);

export const ErrorContainer = ({ title }: { title: string }) => (
  <div style={{ backgroundColor: "red", height: "100%" }}>
    <p>{title}</p>
  </div>
);

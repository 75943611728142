import dayjs from "dayjs";
import { PndTrailers } from "../../hooks/react-query/outbound/linehaulTrailers/usePndTrailers";

export const pndTrailersResponse = {
  default: {
    summary: {
      bills: 523,
      pieces: 1488,
      pallets: 852,
      weight: 619912,
      trailers: 64,
    },
    trailers: [
      {
        tripNumber: 3100973,
        trailerNumber: "280-1002",
        etaDate: null,
        etaTime: "",
        etaTimeStamp: null,
        serviceDue: dayjs("2024-09-26T00:00:00"),
        originSic: "DAL",
        destinationSic: "DAL",
        bills: 1,
        pieces: 0,
        pallets: 1,
        weight: 550,
        shipmentTags: [
          {
            id: 6,
            name: "LG",
            color: "#679CB2",
            description: "Liftgate",
          },
          {
            id: 74,
            name: "RESI",
            color: "#6B8DEB",
            description: "Residential",
          },
          {
            id: 4,
            name: "APTR",
            color: "#777DE9",
            description: "Appointment Required ",
          },
        ],
        status: "CPLN",
        doorNumber: null,
        workerName: "",
        driverName: "",
        routeName: "",
        sics: ["IND-PITD"],
      },
      {
        tripNumber: 3025958,
        trailerNumber: "",
        etaDate: null,
        etaTime: "02:06 AM",
        etaTimeStamp: null,
        serviceDue: null,
        originSic: "DAL",
        destinationSic: "DAL",
        bills: 2,
        pieces: 0,
        pallets: 4,
        weight: 2250,
        shipmentTags: [
          {
            id: 6,
            name: "LG",
            color: "#679CB2",
            description: "Liftgate",
          },
        ],
        status: "CLDK",
        doorNumber: null,
        workerName: "",
        driverName: "",
        routeName: "FORT WORTH NORTH",
        sics: ["DAL"],
      },
    ],
  },
} as const satisfies Record<string, PndTrailers>;

import { Filter } from "../../../types/filter";
import { Tag } from "../../../types/freight/tag.type";
import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
} from "../utils";

export type TagsQueryParam = "tags";
export type SomethingWithTags = Record<TagsQueryParam, Tag[]>;

export const getTagsFilter = (
  data: SomethingWithTags[],
  prefix?: Prefix
): Filter => ({
  label: "Shipment Tags",
  queryParam: handlePrefix("tags", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.flatMap(({ tags }) => tags.map((t) => stringOptionFactory(t.name)))
  ),
});

export const filterTags = <T extends SomethingWithTags>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ tags }) =>
        filters.some((entry) => tags.find((tag) => tag.name === entry))
      )
    : data;

import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton, List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import { PlanTableFormat } from "../../../types/planning/plan.type";

interface PrintDialogTitleProps {
  onCancel: () => void;
  isBulkPrinting: boolean;
  planTableRowData: PlanTableFormat[];
}

type StatusCounts = {
  [status in PlanTableFormat["status"]]: number;
};

const PrintDialogTitle = ({
  onCancel,
  isBulkPrinting,
  planTableRowData,
}: PrintDialogTitleProps) => {
  if (isBulkPrinting) {
    const statusCounts = planTableRowData.reduce<StatusCounts>((acc, row) => {
      acc[row.status] = (acc[row.status] || 0) + 1;

      return acc;
    }, {});
    return (
      <>
        <DialogTitle sx={{ fontWeight: "500", padding: "16px 0px 16px 24px" }}>
          {`Print ${planTableRowData.length} routes/traps`}
        </DialogTitle>
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Box>

        <Box
          sx={{
            padding: "16px 0px 16px 24px",
            fontWeight: "400",
            fontSize: "16px",
          }}
        >
          You have selected the following:
          <List sx={{ listStyleType: "disc", ml: 4 }}>
            {Object.entries(statusCounts).map(([status, count]) => (
              <ListItem key={status} sx={{ display: "list-item", p: 0 }}>
                {`${count} ${status}`}
              </ListItem>
            ))}
          </List>
        </Box>
      </>
    );
  }

  return (
    <>
      <DialogTitle sx={{ fontWeight: "500", padding: "16px 0px 16px 24px" }}>
        {`Print ${planTableRowData[0]?.name} route`}
      </DialogTitle>
      <Box position="absolute" top={4} right={4}>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>
    </>
  );
};

export default PrintDialogTitle;

import { useMemo } from "react";
import dayjs from "dayjs";
import { ServiceCenter } from "../types/serviceCenter.type";
import { useSelectedServiceCenter } from "./useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "./useDateSearchParamOrFallbackToToday";

export const useIsSelectedDateTodayOrInTheFutureForCurrentSic = () => {
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [activeServiceCenter] = useSelectedServiceCenter();

  return useMemo(
    () =>
      isSelectedDateTodayOrInTheFutureForCurrentSic(
        selectedDate,
        activeServiceCenter
      ),
    [selectedDate, activeServiceCenter]
  );
};

export const isSelectedDateTodayOrInTheFutureForCurrentSic = (
  selectedDate: dayjs.ConfigType,
  activeServiceCenter: ServiceCenter
) => {
  const startOfCurrentDate = dayjs()
    .tz(activeServiceCenter.timeZone)
    .startOf("day");
  const startOfSelectedDate = dayjs
    .tz(selectedDate, activeServiceCenter.timeZone)
    .startOf("day");

  return (
    startOfSelectedDate.isSame(startOfCurrentDate) ||
    startOfSelectedDate.isAfter(startOfCurrentDate)
  );
};

import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { displayMessage } from "../constants/strings";
import { HOME_URL_KEY } from "../components/shared/nav/useDepartments";

export function PageNotFound() {
  const theme = useTheme();
  return (
    <Stack alignItems="center" pt={5} pb={10} sx={{ overflowY: "scroll" }}>
      <Typography
        variant="h1"
        fontSize={248}
        fontWeight={800}
        lineHeight="116%"
        color={theme.palette.primary.main}
      >
        404
      </Typography>
      <Box
        component="img"
        src="/empty-truck.webp"
        alt="Empty Daylight Truck"
        aria-hidden
        mt="-160px"
        mb="-80px"
        width={587}
      />
      <Stack alignItems="center" gap={5}>
        <Typography
          variant="h5"
          component="p"
          color="text.disabled"
          textAlign="center"
          fontSize="24px"
        >
          {displayMessage.default.pageNotFound}
        </Typography>
        <Stack
          justifyContent="center"
          alignItems="center"
          gap={1}
          direction="row"
        >
          <Button component={Link} to={HOME_URL_KEY} variant="contained">
            Home
          </Button>
          <Button
            component={Link}
            to=".."
            onClick={(event) => {
              event.preventDefault();
              window.history.back();
            }}
            variant="outlined"
          >
            Go Back
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { Box, BoxProps } from "@mui/material";

type PillProps = {
  color: string;
} & BoxProps;

export const TagPill = ({ color, ...props }: PillProps) => (
  <Box
    sx={{
      display: "flex",
      color: "white",
      height: "24px",
      backgroundColor: color,
      minWidth: "40px",
      borderRadius: "4px",
      fontWeight: "700",
      fontSize: "0.8em",
      justifyContent: "center",
      flexDirection: "column",
      padding: "1px 4px 1px 4px",
      margin: "2px",
      textAlign: "center",
      verticalAlign: "center",
    }}
    {...props}
  />
);

import * as Sentry from "@sentry/react";
import { env } from "./utils/env";

export function initSentry() {
  Sentry.init({
    dsn: "https://f22077affe69c49fcf3509535f5cd5d5@o4507425552924672.ingest.us.sentry.io/4507465295659008",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
    // Error sampling rate
    sampleRate: 1.0, // Capture 100% of errors
    // Enable debug mode in non-production environments
    debug: !["prod", "local"].includes(env.VITE_ENVIRONMENT),
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      new RegExp("^https://operations.dev.dylt.com/"),
      new RegExp("^https://operations.qa.dylt.com/"),
      new RegExp("^https://operations.tst.dylt.com/"),
      new RegExp("^https://operations.uat.dylt.com/"),
      new RegExp("^https://apex.dylt.com/"),
    ],
    // Session Replay
    // TODO- Temporarily disable session replays
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: env.VITE_ENVIRONMENT,
  });
}

import { useEffect, useRef } from "react";
import { useSelectedServiceCenter } from "./useSelectedServiceCenter";

export const useClearSelectionWhenServiceCenterChanges = (
  clearSelection: () => void
) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const serviceCenterId = useRef(serviceCenter.id);

  useEffect(
    function clearSelectionWhenServiceCenterChanges() {
      if (serviceCenter.id === serviceCenterId.current) return;
      serviceCenterId.current = serviceCenter.id;
      clearSelection();
    },
    [clearSelection, serviceCenter.id]
  );
};

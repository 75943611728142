import { DoorAssetsType } from "../../../../hooks/react-query/outbound/freightDetails/useGetDoorAssets";

export const doorAssets = {
  default: {
    destinationSicId: 0,
    destinationSic: "",
    laneType: 0,
    summary: {
      bills: 0,
      pieces: 0,
      pallets: 0,
      weight: 0,
      loadedWeight: 0,
      pulls: 0,
      publishedTrips: 0,
      unassignedPublishedTrips: 0,
    },
    indicators: {
      unassignedBillsCount: 2,
      missServiceBillsCount: 3,
      pullsWithNoTrailersCount: 4,
      pullsWithNoTripCount: 5,
      lanesWithNoDoorsCount: 6,
    },
    doors: [
      {
        doorNumber: 7,
        priority: 1,
        laneType: 0,
        doorSics: ["ONT", "DAL"],
        doorTags: [
          {
            color: "#777DE9",
            id: 4,
            name: "APTR",
          },
          {
            id: 2,
            name: "UC",
            color: "#FBC02D",
          },
        ],
        doorExclusiveForSicsAssigned: true,
        doorExclusiveForTagsAssigned: false,
        baySics: ["ONT", "DAL"],
        bayTags: [
          {
            color: "#777DE9",
            id: 4,
            name: "APTR",
          },
          {
            id: 2,
            name: "UC",
            color: "#FBC02D",
          },
        ],
        bayExclusiveForSicsAssigned: false,
        bayExclusiveForTagsAssigned: true,
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        pulls: [
          {
            plannedPullId: 25457,
            trailerNumber: "DYLT170409",
            trailerEquipmentId: 1224,
            tripNumber: 3099732,
            gateTime: "10:30 AM",
            carrierName: "MARSA TRANSPORTATION INC",
            status: "EMTY",
            trapName: "",
            isLoadAndHold: false,
            stopSicCode: "",
          },
          {
            plannedPullId: 25571,
            trailerNumber: "C244-2222",
            trailerEquipmentId: 1223,
            tripNumber: 3099732,
            gateTime: "10:30 AM",
            carrierName: "MARSA TRANSPORTATION INC",
            status: "PLAN",
            trapName: "",
            isLoadAndHold: false,
            stopSicCode: "",
          },
        ],
      },
      {
        doorNumber: 5,
        priority: null,
        laneType: 0,
        doorSics: [],
        doorTags: [
          {
            color: "#777DE9",
            id: 4,
            name: "APTR",
          },
        ],
        doorExclusiveForSicsAssigned: true,
        doorExclusiveForTagsAssigned: false,
        baySics: ["ONT", "DAL"],
        bayTags: [
          {
            color: "#777DE9",
            id: 4,
            name: "APTR",
          },
          {
            id: 2,
            name: "UC",
            color: "#FBC02D",
          },
        ],
        bayExclusiveForSicsAssigned: false,
        bayExclusiveForTagsAssigned: true,
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        pulls: [
          {
            plannedPullId: 25572,
            trailerNumber: "DAL010",
            trailerEquipmentId: 1225,
            tripNumber: null,
            gateTime: "10:30 AM",
            carrierName: "MARSA TRANSPORTATION INC",
            status: "EMTY",
            trapName: "",
            isLoadAndHold: false,
            stopSicCode: "EMTY",
          },
          {
            plannedPullId: 25573,
            trailerNumber: "DALGHOST03",
            trailerEquipmentId: 1222,
            tripNumber: 3099732,
            gateTime: "10:30 AM",
            carrierName: "MARSA TRANSPORTATION INC",
            status: "EMTY",
            trapName: "",
            isLoadAndHold: false,
            stopSicCode: "EMTY",
          },
        ],
      },
      {
        doorNumber: 9,
        priority: 1,
        laneType: 0,
        doorSics: [],
        doorTags: [],
        doorExclusiveForSicsAssigned: false,
        doorExclusiveForTagsAssigned: false,
        baySics: [],
        bayTags: [],
        bayExclusiveForSicsAssigned: false,
        bayExclusiveForTagsAssigned: false,
        bills: 50,
        pieces: 40,
        pallets: 30,
        weight: 10,
        pulls: [],
      },
      {
        doorNumber: 11,
        priority: 1,
        laneType: 4,
        doorSics: [],
        doorTags: [],
        doorExclusiveForSicsAssigned: false,
        doorExclusiveForTagsAssigned: false,
        baySics: [],
        bayTags: [],
        bayExclusiveForSicsAssigned: false,
        bayExclusiveForTagsAssigned: false,
        bills: 50,
        pieces: 40,
        pallets: 30,
        weight: 10,
        pulls: [],
      },
    ],
  },
} as const satisfies Record<string, DoorAssetsType>;

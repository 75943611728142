import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useSelectedServiceCenter } from "./useSelectedServiceCenter";

export const UNPLANNED_SELECTED_DATE = "unplannedDateTime";

export const useUnplannedDatetimeSearchParam = () => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [searchParams, setSearchParams] = useSearchParams();
  const unplannedDateTime =
    searchParams.get(UNPLANNED_SELECTED_DATE) ??
    dayjs({ hour: 9, minute: 0, second: 0 }, { utc: false })
      .tz(serviceCenter.timeZone, true)
      .format();

  const setUnplannedDateTime = (selectedDateTime: string) => {
    searchParams.set(UNPLANNED_SELECTED_DATE, selectedDateTime);
    setSearchParams(searchParams);
  };

  return {
    unplannedDateTime,
    setUnplannedDateTime,
  };
};

import { Delete } from "@mui/icons-material";
import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { useState } from "react";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { actionsMap, ShipmentActionTypeId } from "../ShipmentActionDialog";
import { toast } from "../../../../utils/snackbarHelper";
import { useDeleteRouteAssignment } from "../../../../hooks/react-query/useRouteAssignment";
import { useDeleteDeliveryTrapsAssignment } from "../../../../hooks/react-query/useDeliveryTrapAssignment";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { useSearchTermParam } from "../../../../hooks/useSearchTermParam";
import DeleteRouteAssignmentConfirmDialog from "../DeleteRouteAssignmentConfirmDialog";

type DeletePlanMenuItemProps = {
  routesOrTraps: PlanTableFormat[];
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
  clearSelection?: () => void;
};

export const DeletePlanMenuItem = ({
  routesOrTraps,
  handleClose,
  clearSelection = () => {},
}: DeletePlanMenuItemProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const dateInt = dateToInt(selectedDate);
  const [searchTerm] = useSearchTermParam();

  const routeIds = routesOrTraps
    .filter((x) => x.type === "delivery_route")
    .map((x) => x.id);

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const onSuccess = (actionType: ShipmentActionTypeId) => {
    const { description } = actionsMap[actionType];
    toast(description, {
      variant: "success",
    });
  };

  const { mutate: deleteRoutes, isPending: isRouteDeletePending } =
    useDeleteRouteAssignment({
      date: dateInt,
      sicId: serviceCenter.id,
      searchTerm,
      onSuccess: () =>
        onSuccess(routeIds.length > 1 ? "deleteRoutes" : "deleteRoute"),
      onSettled: () => {
        setIsConfirmDeleteDialogOpen(false);
        handleClose?.();
        clearSelection();
      },
    });

  const { mutate: deleteTraps, isPending: isTrapDeletePending } =
    useDeleteDeliveryTrapsAssignment({
      date: dateInt,
      sicId: serviceCenter.id,
      searchTerm,
      onSuccess: () => onSuccess("deleteDeliveryTraps"),
      onSettled: () => {
        setIsConfirmDeleteDialogOpen(false);
        handleClose?.();
        clearSelection();
      },
    });

  const handleDeleteConfirm = () => {
    if (routeIds.length > 0) deleteRoutes({ planIds: routeIds });

    const trapIds = routesOrTraps
      .filter((x) => x.type === "delivery_trap")
      .map((x) => x.id);

    if (trapIds.length > 0) deleteTraps({ trapIds });
  };

  return (
    <>
      <MenuItem onClick={() => setIsConfirmDeleteDialogOpen(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <Button sx={labelButtonStyle} disableRipple>
          Delete
        </Button>
      </MenuItem>
      <DeleteRouteAssignmentConfirmDialog
        plans={routesOrTraps}
        open={isConfirmDeleteDialogOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => {
          handleClose?.();
          setIsConfirmDeleteDialogOpen(false);
        }}
        loading={isRouteDeletePending || isTrapDeletePending}
      />
    </>
  );
};

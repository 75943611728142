import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { Lock } from "@mui/icons-material";
import pluralize from "pluralize";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import Loading from "../../../../components/shared/layout/Loading";
import { toastMessage } from "../../../../constants/strings";
import { toast } from "../../../../utils/snackbarHelper";
import { useLockRoutes } from "../../../../hooks/react-query/useDeliveryRoutes";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";

type LockRoutesMenuItemProps = {
  routes: PlanTableFormat[];
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
  clearSelection?: () => void;
};

export const LockRoutesMenuItem = ({
  routes,
  handleClose,
  clearSelection = () => {},
}: LockRoutesMenuItemProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const dateInt = dateToInt(selectedDate);

  const lockRouteMutation = useLockRoutes({
    date: dateInt,
    sicId: serviceCenter.id,
    onSuccess: () => {
      handleClose?.();
      toast(toastMessage.shared.lockRoutes.success(routes.length, false), {
        variant: "success",
      });
      clearSelection();
    },
  });

  if (lockRouteMutation.isPending) {
    return <Loading label="unlock" containerWidth={32} size={32} />;
  }

  return (
    <MenuItem
      onClick={() =>
        lockRouteMutation.mutate(
          routes.map((route) => ({
            id: route.id,
            isLocked: !route.isCommitted || false,
          }))
        )
      }
    >
      <ListItemIcon>
        <Lock />
      </ListItemIcon>
      <Button sx={labelButtonStyle} disableRipple>
        Lock {pluralize("Route", routes.length)}
      </Button>
    </MenuItem>
  );
};

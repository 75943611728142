/** Used to control selection and DnD from tables, cards, etc. */
import {
  MouseEvent,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import { shipmentSelectionHandler } from "../utils/shipmentSelectionHandler";
import { Shipment } from "../services/prePlanningService.types";
import {
  SummaryContext,
  SummaryContextType,
} from "../pages/inbound/shared/ActionsMenuContext";

type DraggingSource = "notDragging" | "sidebar" | "mainTable";

type SelectionContextValue = {
  selected: SummaryContext[];
  handleSelect: (
    event: MouseEvent<HTMLElement>,
    selectedItem: SummaryContext,
    shipments: Shipment[]
  ) => void;
  clearSelection: (type?: SummaryContextType) => void;
  isSelected: (id: number, type: SummaryContextType) => boolean;
  isAllSelected: (shipmentIds: number[], type: SummaryContextType) => boolean;
  draggingSource: DraggingSource;
  setDraggingSource: (draggingSource: DraggingSource) => void;
};

const SelectionContext = createContext<SelectionContextValue | null>(null);

type SelectionContextProviderProps = {
  children: ReactNode;
};

export function SelectionContextProvider({
  children,
}: SelectionContextProviderProps) {
  const [selected, setSelected] = useState<SummaryContext[]>([]);
  const [draggingSource, setDraggingSource] =
    useState<DraggingSource>("notDragging");

  const handleSelect = (
    event: MouseEvent<HTMLElement>,
    selectedItem: SummaryContext,
    shipments: Shipment[]
  ) => {
    shipmentSelectionHandler(
      event,
      selected,
      selectedItem,
      setSelected,
      shipments
    );
  };

  const clearSelection = (type?: SummaryContextType) => {
    if (type) {
      setSelected(selected.filter((s) => s.type !== type));
    } else {
      setSelected([]);
    }
  };

  const isAllSelected = (
    shipmentIds: number[],
    type: SummaryContextType
  ): boolean =>
    shipmentIds.length > 0 &&
    shipmentIds.every((id) =>
      selected.some(
        (item) => item.shipmentIds.includes(id) && item.type === type
      )
    );

  const isSelected = (id: number, type: SummaryContextType): boolean =>
    Object.values(selected).some(
      (item) => item.shipmentIds.includes(id) && item.type === type
    );

  return (
    <SelectionContext.Provider
      value={{
        selected,
        handleSelect,
        clearSelection,
        isSelected,
        isAllSelected,
        draggingSource,
        setDraggingSource,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
}

export function useSelectionContext(): SelectionContextValue {
  const selectionContext = useContext(SelectionContext);
  if (!selectionContext) {
    throw new Error(
      "useSelectionContext must be used within SelectionContextProvider"
    );
  }
  return selectionContext;
}

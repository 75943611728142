import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useRef } from "react";
import {
  PopUpMenu,
  PopupMenuRef,
} from "../../../../components/shared/PopupMenu";
import { ContextMenuAction } from "../../../../types/contextMenuAction";

interface PrePlanningContextActionsProps {
  options: ContextMenuAction[];
  tooltipAriaLabel: string;
  isDisabled?: boolean;
}

export const ActionsMenu = ({
  options = [],
  tooltipAriaLabel,
  isDisabled = false,
}: PrePlanningContextActionsProps) => {
  const popupRef = useRef<PopupMenuRef>(null);

  return (
    <>
      <IconButton
        disabled={isDisabled}
        onClick={(event) => {
          event.stopPropagation();
          popupRef.current?.handleClick(event);
        }}
        title={tooltipAriaLabel}
      >
        {/* Setting `open` to false because Todd has requested that we not show the tooltip because it's redundant. But we need to keep the aria label for a11y and as an automated testing target. */}
        <Tooltip title={tooltipAriaLabel} open={false}>
          <MoreHoriz />
        </Tooltip>
      </IconButton>
      <PopUpMenu ref={popupRef} contextMenuActions={options} />
    </>
  );
};

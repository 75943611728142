import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  colors,
  TextField as MuiTextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDoorNumbersBySicIdOld } from "../../../../hooks/react-query/useDoors";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { DropdownOption } from "../../../../types/dropdownOption.type";
import { TextField } from "../../../../components/shared/layout/TextInput";
import { MAX_TRAP_NAME_LENGTH } from "../../../../constants/fieldNameLengthConstants";
import { GappedFlexColumn } from "../../../../components/shared/layout/Flex";
import Loading from "../../../../components/shared/layout/Loading";
import { MergeIntoType } from "../../../../hooks/react-query/preplanning/useRouteTraps";
import { TimePicker } from "../../../../components/shared/TimePicker";
import {
  useEquipmentBySicId,
  useEquipmentUsageBySicId,
} from "../../../../hooks/react-query/useEquipments";
import {
  equipmentTypeMap,
  SelectedEquipmentType,
} from "../../../../types/equipment.type";
import {
  doorAvailability,
  getDoorOptionsFromDoorNumbersOld,
} from "../doorNumberUtils";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { useDriversUsage } from "../../../../hooks/react-query/useDrivers";
import { getDriverOptions } from "../driverUtils";
import { AutocompleteAvailabilityOption } from "../../../../components/shared/AutocompleteAvailabilityOption";
import {
  getStraightTruckOptions,
  getTractorOptions,
  getTrailerOptions,
  straightTruckAvailability,
  tractorAvailability,
  trailerAvailability,
} from "../equipmentUtils";
import { planningStatus } from "../../../../types/status.type";
import {
  MergeDestination,
  MergeRoutesTrapsDialogAction,
  MergeRoutesTrapsDialogState,
} from "./MergeRoutesTrapsDialogReducer";
import { TractorTrailerAutocomplete } from "./TractorTrailerAutocomplete";

type MergeRoutesTrapsStepTwoProps = {
  validationErrors: ValidationErrors;
  mergeDestinationName: string;
  dialogState: MergeRoutesTrapsDialogState;
  dispatch: (value: MergeRoutesTrapsDialogAction) => void;
  showRouteEquipmentSelectors: boolean;
};

export type ValidationErrors = {
  name?: string;
  startTime?: string;
};

type MergeInfoNameAndDoorProps = {
  type: MergeIntoType;
  mergeDestination: MergeDestination;
  doorOptions: DropdownOption[];
  dispatch: (value: MergeRoutesTrapsDialogAction) => void;
  validationErrors: ValidationErrors;
};

type PlanningStatusArray = Array<keyof typeof planningStatus>;

const statusesBlockedFromSelection: string[] = [
  planningStatus.CLDK,
  planningStatus.CLDV,
  planningStatus.TRAP,
  planningStatus.DISP,
  planningStatus.CPLN,
  planningStatus.TPLN,
] as const satisfies PlanningStatusArray;

const MergeInfoNameAndDoor = ({
  type,
  dispatch,
  mergeDestination,
  doorOptions,
  validationErrors,
}: MergeInfoNameAndDoorProps) => (
  <>
    <TextField
      sx={{ width: "100%" }}
      value={mergeDestination.customName}
      onChange={(event) =>
        dispatch({
          type: "set_customName",
          customName: event.target.value,
        })
      }
      id={`${type}-name`}
      placeholder={type === "trap" ? "Trap Name" : "Route Name"}
      inputProps={{ maxLength: MAX_TRAP_NAME_LENGTH }}
      error={validationErrors.name ? validationErrors.name.length > 0 : false}
      helperText={validationErrors.name}
    />
    <Autocomplete
      value={
        doorOptions.find((d) => Number(d.value) === mergeDestination.doorId) ??
        null
      }
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={doorOptions}
      onChange={(_, option) => {
        dispatch({
          type: "set_doorId",
          doorId: Number(option?.value),
        });
      }}
      getOptionLabel={(door) => door.name}
      renderInput={(params) => (
        <MuiTextField {...params} label="Door Assignment" />
      )}
    />
  </>
);

export const MergeRoutesTrapsStepTwo = ({
  validationErrors,
  mergeDestinationName,
  dialogState,
  dispatch,
  showRouteEquipmentSelectors,
}: MergeRoutesTrapsStepTwoProps) => {
  const driverGuardrailsEnabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-3-client"
  );
  const isDriverAndEquipmentEnabled = useIsFeatureFlagEnabled(
    "inbound-assign-driver-on-merge-client"
  );

  const [serviceCenter] = useSelectedServiceCenter();
  const { data: doorNumbers = [], isLoading: isLoadingDoors } =
    useDoorNumbersBySicIdOld(serviceCenter.id, true);
  const doorOptions = getDoorOptionsFromDoorNumbersOld(doorNumbers);

  const {
    data: equipments = {
      tractors: [],
      trailers: [],
      straightTrucks: [],
    },
    isLoading: isLoadingEquipments,
  } = useEquipmentBySicId(serviceCenter.id);

  const {
    data: equipmentsUsage = { tractors: [], trailers: [], straightTrucks: [] },
  } = useEquipmentUsageBySicId(
    serviceCenter.id,
    dialogState.mergeDestination.planDate
  );
  const { mergeIntoType, mergeDestination, equipmentTypeId } = dialogState;

  const [selectedEquipmentType, setSelectedEquipmentType] =
    useState<SelectedEquipmentType | null>("Trailer");

  const { trailers, straightTrucks, tractors } = equipments;

  const { data: drivers = [] } = useDriversUsage(
    serviceCenter.id,
    mergeDestination.planDate,
    driverGuardrailsEnabled
  );

  const driverOptions = getDriverOptions(drivers);

  const trailerOptions = getTrailerOptions(equipmentsUsage.trailers);

  const tractorOptions = getTractorOptions(equipmentsUsage.tractors);
  const straightTruckOptions = getStraightTruckOptions(
    equipmentsUsage.straightTrucks
  );

  const hasTrailers = trailers.length > 0;
  const hasStraightTrucks = straightTrucks.length > 0;

  useEffect(() => {
    if (equipmentTypeId === null && !isLoadingEquipments) {
      dispatch({
        type: "set_equipmentTypeId",
        equipmentTypeId: hasTrailers
          ? equipmentTypeMap.trailer
          : equipmentTypeMap.straightTruck,
      });
    }
  }, [dispatch, equipmentTypeId, hasTrailers, isLoadingEquipments]);

  const getEquipmentSelectorStyle = (selected: boolean) => ({
    color: colors.blue[700],
    borderColor: colors.blue[300],
    backgroundColor: selected ? colors.blue[50] : "initial",
    pointerEvents: selected ? "none" : "all",
  });
  const handleSelectEquipmentType = (type: SelectedEquipmentType | null) => {
    setSelectedEquipmentType(type === selectedEquipmentType ? null : type);
  };

  const getSelectedTrailer = () => {
    if (mergeDestination.trailerId || mergeDestination.straightTruckId) {
      const toFindId =
        equipmentTypeId === equipmentTypeMap.trailer
          ? mergeDestination.trailerId
          : mergeDestination.straightTruckId;

      const found = (
        equipmentTypeId === equipmentTypeMap.trailer ? trailers : straightTrucks
      ).find((e) => Number(e.id) === toFindId);

      if (found) {
        return { name: found.name, value: found.id.toString() };
      }

      throw new Error(`Equipment with id ${toFindId} not found.`);
    }

    return null;
  };

  const getSelectedTractor = () => {
    if (mergeDestination.tractorId) {
      const tractorAtMergeDestination = tractors.find(
        (e) => Number(e.id) === mergeDestination.tractorId
      );

      if (tractorAtMergeDestination) {
        return {
          name: tractorAtMergeDestination.name,
          value: String(tractorAtMergeDestination.id),
        };
      }

      throw new Error(
        `Tractor with id ${mergeDestination.tractorId} not found.`
      );
    }

    return null;
  };

  if (isLoadingDoors || isLoadingEquipments) {
    return <Loading />;
  }

  return (
    <Box px="24px" height={380} sx={{ overflow: "auto" }}>
      <GappedFlexColumn gap={0.7}>
        {mergeIntoType === "route" ? (
          <>
            Merging into {mergeDestinationName} route
            <MergeInfoNameAndDoor
              validationErrors={validationErrors}
              type={mergeIntoType}
              doorOptions={doorOptions}
              dispatch={dispatch}
              mergeDestination={mergeDestination}
            />
            <TimePicker
              label="Select Time"
              value={
                mergeDestination.startTime
                  ? dayjs(mergeDestination.startTime)
                  : null
              }
              onChange={(date) => {
                const isValidDate = date && date.isValid();
                dispatch({
                  type: "set_startTime",
                  startTime: isValidDate ? date.toISOString() : null,
                });
              }}
              slotProps={{
                textField: {
                  sx: { width: "100%" },
                  helperText: validationErrors.startTime,
                  error: validationErrors.startTime
                    ? validationErrors.startTime.length > 0
                    : false,
                },
              }}
            />
            {isDriverAndEquipmentEnabled && (
              <>
                <Autocomplete
                  value={
                    driverOptions.find(
                      (d) => d.value === mergeDestination.driverId
                    ) ?? null
                  }
                  options={driverOptions}
                  getOptionLabel={(driver) => driver.name}
                  onChange={(_, newValue) => {
                    dispatch({
                      type: "set_driverId",
                      driverId: newValue?.value ?? null,
                    });
                  }}
                  renderInput={(params) => (
                    <MuiTextField {...params} label="Driver" />
                  )}
                  groupBy={(option) => option.group}
                  renderOption={(props, option) => (
                    <AutocompleteAvailabilityOption
                      key={`${option.name}-${option.value}`}
                      listItemProps={props}
                      assignment={option.assignment}
                      name={option.name}
                    />
                  )}
                  getOptionDisabled={(option) =>
                    statusesBlockedFromSelection.includes(
                      mergeDestination.status
                    ) && option.group === doorAvailability.ASSIGNED
                  }
                />
                <Box sx={{ marginTop: "16px" }}>
                  <Typography fontSize={14} fontWeight={600} mb={1}>
                    Select an equipment type
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "16px" }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={selectedEquipmentType}
                    exclusive
                    aria-label="Platform"
                    size="small"
                  >
                    <ToggleButton
                      value="Trailer"
                      onChange={() => handleSelectEquipmentType("Trailer")}
                    >
                      Trailer
                    </ToggleButton>
                    <ToggleButton
                      value="Truck"
                      onChange={() => handleSelectEquipmentType("Truck")}
                    >
                      Straight Truck
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box
                  role="tabpanel"
                  hidden={selectedEquipmentType !== "Trailer"}
                >
                  <Box sx={{ marginTop: "16px" }}>
                    <TractorTrailerAutocomplete
                      options={trailerOptions}
                      selectedEquipmentId={mergeDestination.trailerId}
                      onSelect={(id) =>
                        dispatch({ type: "set_trailerId", trailerId: id })
                      }
                      label="Trailer #"
                      isDisabled={(option) =>
                        (statusesBlockedFromSelection.includes(
                          mergeDestination.status
                        ) &&
                          option.group === trailerAvailability.ASSIGNED) ||
                        option.group === trailerAvailability.INACTIVE
                      }
                    />
                    <TractorTrailerAutocomplete
                      options={tractorOptions}
                      selectedEquipmentId={mergeDestination.tractorId}
                      onSelect={(id) =>
                        dispatch({ type: "set_tractorId", tractorId: id })
                      }
                      label="Tractor #"
                      isDisabled={(option) =>
                        (statusesBlockedFromSelection.includes(
                          mergeDestination.status
                        ) &&
                          option.group === tractorAvailability.ASSIGNED) ||
                        option.group === tractorAvailability.INACTIVE
                      }
                    />
                  </Box>
                </Box>
                <Box role="tabpanel" hidden={selectedEquipmentType !== "Truck"}>
                  <Box sx={{ marginTop: "16px" }}>
                    <Autocomplete
                      value={
                        straightTruckOptions.find(
                          (d) =>
                            Number(d.value) === mergeDestination.straightTruckId
                        ) ?? null
                      }
                      options={straightTruckOptions}
                      onChange={(_, newValue) => {
                        dispatch({
                          type: "set_straightTruckId",
                          straightTruckId: newValue?.value ?? null,
                        });
                      }}
                      getOptionLabel={(trailer) => trailer.name}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="ST #" />
                      )}
                      groupBy={(option) => option.group}
                      getOptionDisabled={(option) =>
                        (statusesBlockedFromSelection.includes(
                          mergeDestination.status
                        ) &&
                          option.group ===
                            straightTruckAvailability.ASSIGNED) ||
                        option.group === straightTruckAvailability.INACTIVE
                      }
                      renderOption={(props, option) => (
                        <AutocompleteAvailabilityOption
                          key={option.name}
                          listItemProps={props}
                          name={option.name}
                          assignment={option.assignment}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            Merging into {mergeDestinationName} trap
            <MergeInfoNameAndDoor
              validationErrors={validationErrors}
              type={mergeIntoType}
              doorOptions={doorOptions}
              dispatch={dispatch}
              mergeDestination={mergeDestination}
            />
          </>
        )}
        {(mergeIntoType === "trap" || showRouteEquipmentSelectors) && (
          <>
            {hasTrailers && hasStraightTrucks && (
              <>
                Select equipment type
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      dispatch({
                        type: "set_equipmentTypeId",
                        equipmentTypeId: equipmentTypeMap.trailer,
                      });
                    }}
                    color="inherit"
                    sx={getEquipmentSelectorStyle(
                      equipmentTypeMap.trailer === equipmentTypeId
                    )}
                  >
                    Trailer
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      dispatch({
                        type: "set_equipmentTypeId",
                        equipmentTypeId: equipmentTypeMap.straightTruck,
                      });
                    }}
                    color="inherit"
                    sx={{
                      ...getEquipmentSelectorStyle(
                        equipmentTypeMap.straightTruck === equipmentTypeId
                      ),
                      marginLeft: 1,
                    }}
                  >
                    Straight Truck
                  </Button>
                </Box>
              </>
            )}
            {equipmentTypeId !== null && (
              <>
                <Autocomplete
                  value={getSelectedTrailer()}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  options={(equipmentTypeId === equipmentTypeMap.trailer
                    ? trailers
                    : straightTrucks
                  ).map(({ name, equipmentType, id }) => ({
                    name: name || `${equipmentType}-${id}`,
                    value: id.toString(),
                  }))}
                  onChange={(_, option) => {
                    if (equipmentTypeId === equipmentTypeMap.trailer) {
                      dispatch({
                        type: "set_trailerId",
                        trailerId: Number(option?.value),
                      });
                    } else {
                      dispatch({
                        type: "set_straightTruckId",
                        straightTruckId: Number(option?.value),
                      });
                    }
                  }}
                  getOptionLabel={(equipment) => equipment.name}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={
                        equipmentTypeMap.trailer === equipmentTypeId
                          ? "Trailer"
                          : "Straight Truck"
                      }
                    />
                  )}
                />
                {showRouteEquipmentSelectors &&
                  equipmentTypeId === equipmentTypeMap.trailer && (
                    <Autocomplete
                      value={getSelectedTractor()}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      options={tractors.map(({ name, equipmentType, id }) => ({
                        name: name || `${equipmentType}-${id}`,
                        value: id.toString(),
                      }))}
                      onChange={(_, option) => {
                        dispatch({
                          type: "set_tractorId",
                          tractorId: Number(option?.value),
                        });
                      }}
                      getOptionLabel={(equipment) => equipment.name}
                      renderInput={(params) => (
                        <MuiTextField {...params} label="Tractor" />
                      )}
                    />
                  )}
              </>
            )}
            {!hasTrailers && !hasStraightTrucks && (
              <Typography>No equipment available yet</Typography>
            )}
          </>
        )}
      </GappedFlexColumn>
    </Box>
  );
};

import { Button as MuiButton, SxProps, Theme, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";

interface ButtonProps {
  startIcon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: "outlined" | "text" | "contained";
  type?: "primary" | "secondary";
  round?: number | string;
  textColor?: "black" | "white" | "theme";
  sx?: SxProps<Theme>;
}

/** Opinionated button that wraps MUI's Button. */
export const Button = ({
  startIcon,
  onClick,
  children,
  variant = "outlined",
  type = "primary",
  round,
  textColor = "theme",
  sx,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const theme = useTheme();
  const color = theme.palette[type].main;

  return (
    <MuiButton
      variant={variant}
      startIcon={startIcon}
      sx={{
        ...sx,
        color: textColor === "theme" ? color : textColor,
        borderColor: color,
        borderRadius: round,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

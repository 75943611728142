import axios from "axios";
import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";
import { PlanType } from "../../types/planning/plan.type";
import {
  errorDetailSchema,
  transitionErrorCodesSchema,
} from "../../types/inbound/errorDetail.type";
import { plannedRoutesQueryKey } from "./usePlannedRoutes";
import { keys as shipmentKeys } from "./preplanning/useShipmentDetails";

// TODO Remove all variables and types marked as "Old" when the BE has a centralized return type:
// https://dylt.atlassian.net/browse/IODD-2892
// Unfortunately until then we need to have this as a fallback for one of the calls
const deliveryRouteTrapStatusSchemaOld = z.object({
  id: z.number(),
  name: z.string(),
  isAvailableForTransition: z.boolean(),
  transitionConstraints: z.array(transitionErrorCodesSchema),
});

const deliveryRouteTrapStatusSchema = z.object({
  id: z.number(),
  name: z.string(),
  isAvailableForTransition: z.boolean(),
  transitionConstraints: z.array(transitionErrorCodesSchema),
  errorDetails: z
    .array(errorDetailSchema)
    .nullable()
    .transform((array) => array ?? []),
});

const deliveryRoutesTrapsStatusSchemaOld = z.array(
  deliveryRouteTrapStatusSchemaOld
);
const deliveryRoutesTrapsStatusSchema = z.array(deliveryRouteTrapStatusSchema);

export type DeliveryRouteTrapStatusOld = z.infer<
  typeof deliveryRouteTrapStatusSchemaOld
>;

export type DeliveryRouteTrapStatus = z.infer<
  typeof deliveryRouteTrapStatusSchema
>;

const keys = {
  allDeliveryRouteTrapStatuses: ["delivery-route-trap-status"],
  deliveryRouteTrapStatus: (type: PlanType, id: number) => [
    "delivery-route-trap-status",
    type,
    id,
  ],
  deliveryRoutesTrapsStatus: (routeIds: number[], trapIds: number[]) => [
    "delivery-routes-traps-status",
    routeIds,
    trapIds,
  ],
};

export const useDeliveryRouteTrapStatus = (
  type: PlanType,
  id: number,
  enabled: boolean
) =>
  useQuery({
    enabled,
    queryKey: keys.deliveryRouteTrapStatus(type, id),
    queryFn: async () => {
      const url =
        type === "delivery_trap"
          ? apiUrls.getDeliveryTrapStatus(id)
          : apiUrls.getDeliveryRouteStatus(id);

      const { data } = await http.get(url);
      return deliveryRoutesTrapsStatusSchema.parse(data);
    },
  });

export const useDeliveryRoutesTrapsStatusOld = (
  routeIds: number[],
  trapIds: number[]
) =>
  useQuery({
    enabled: routeIds.length + trapIds.length > 0,
    queryKey: keys.deliveryRoutesTrapsStatus(routeIds, trapIds),
    queryFn: async () => {
      const { data } = await http.get(
        apiUrls.getDeliveryRoutesTrapsStatusOld(routeIds, trapIds)
      );
      return deliveryRoutesTrapsStatusSchemaOld.parse(data);
    },
  });

export const useDeliveryRoutesTrapsStatus = (
  routeIds: number[],
  trapIds: number[]
) =>
  useQuery({
    enabled: routeIds.length + trapIds.length > 0,
    queryKey: keys.deliveryRoutesTrapsStatus(routeIds, trapIds),
    queryFn: async () => {
      const { data } = await http.get(
        apiUrls.getDeliveryRoutesTrapsStatus(routeIds, trapIds)
      );
      return deliveryRoutesTrapsStatusSchema.parse(data);
    },
  });

type DeliveryRouteTrapStatusChange = {
  sicId: number;
  date: number;
  type: PlanType;
  routeId: number;
  onSuccess?: (newStatus: DeliveryRouteTrapStatus) => void;
};

export const useDeliveryRouteTrapStatusChange = ({
  sicId,
  date,
  type,
  routeId,
  onSuccess,
}: DeliveryRouteTrapStatusChange) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newStatus: DeliveryRouteTrapStatus) => {
      const url =
        type === "delivery_trap"
          ? apiUrls.putDeliveryTrapStatus(routeId)
          : apiUrls.putDeliveryRouteStatus(routeId);

      await http.put(url, {
        statusId: newStatus.id,
      });
      return newStatus;
    },
    onSuccess: (newStatus: DeliveryRouteTrapStatus) => {
      onSuccess?.(newStatus);
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        toast(error.response.data, {
          variant: "warning",
        });
      } else {
        toast(toastMessage.generics.error, {
          variant: "error",
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: keys.allDeliveryRouteTrapStatuses,
      });
      queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId),
      });
      queryClient.invalidateQueries({
        queryKey: shipmentKeys.allShipmentDetails,
      });
    },
  });
};

type DeliveryRoutesTrapsStatusChange = {
  sicId: number;
  date: number;
  searchTerm: string;
  routeIds: number[];
  trapIds: number[];
  onSuccess?: (newStatus: DeliveryRouteTrapStatus) => void;
};

export const useDeliveryRoutesTrapsStatusChange = ({
  sicId,
  date,
  searchTerm,
  routeIds,
  trapIds,
  onSuccess,
}: DeliveryRoutesTrapsStatusChange) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newStatus: DeliveryRouteTrapStatus) => {
      const url = apiUrls.putDeliveryRoutesTrapsStatus;
      await http.put(url, {
        statusId: newStatus.id,
        routeIds,
        trapIds,
      });
      return newStatus;
    },
    onSuccess: (newStatus: DeliveryRouteTrapStatus) => {
      onSuccess?.(newStatus);
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        return toast(error.response.data, {
          variant: "warning",
        });
      }

      toast(toastMessage.generics.error, {
        variant: "error",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: keys.allDeliveryRouteTrapStatuses,
      });
      queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
    },
  });
};

type DeliveryRoutesTrapsStatusChangeOld = {
  sicId: number;
  date: number;
  routeIds: number[];
  trapIds: number[];
  onSuccess?: (newStatus: DeliveryRouteTrapStatusOld) => void;
};

export const useDeliveryRoutesTrapsStatusChangeOld = ({
  sicId,
  date,
  routeIds,
  trapIds,
  onSuccess,
}: DeliveryRoutesTrapsStatusChangeOld) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newStatus: DeliveryRouteTrapStatusOld) => {
      const url = apiUrls.putDeliveryRoutesTrapsStatus;
      await http.put(url, {
        statusId: newStatus.id,
        routeIds,
        trapIds,
      });
      return newStatus;
    },
    onSuccess: (newStatus: DeliveryRouteTrapStatusOld) => {
      onSuccess?.(newStatus);
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        return toast(error.response.data, {
          variant: "warning",
        });
      }

      toast(toastMessage.generics.error, {
        variant: "error",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: keys.allDeliveryRouteTrapStatuses,
      });
      queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId),
      });
    },
  });
};

import { LoadTrailers } from "../../hooks/react-query/outbound/linehaulTrailers/useLoadTrailers";

export const loadTrailersResponse = {
  empty: {
    summary: {
      bills: 0,
      pieces: 0,
      pallets: 0,
      weight: 0,
      trailers: 0,
    },
    trailers: [],
  },
  default: {
    summary: {
      bills: 7,
      pieces: 89,
      pallets: 14,
      weight: 8001,
      trailers: 1,
    },
    trailers: [
      {
        plannedPullId: 10592,
        doorNumber: 7,
        tripNumber: 88888888,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "RYAN/VICTOR",
        originSic: "DAL",
        destinationSic: "ATM",
        ect: "05:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-01T00:00:00"),
        sealNumber: "10101",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10593,
        doorNumber: 10,
        tripNumber: null,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "BENJAMIN",
        originSic: "DAL",
        destinationSic: "HTX",
        ect: "22:00",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-12T00:00:00"),
        sealNumber: "20203",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "HTX tomorrow",
        trailerType: 2,
        trailerTypeName: "Trap",
        containsNotes: true,
      },
      {
        plannedPullId: 10596,
        doorNumber: 17,
        tripNumber: 30853698,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "RAIMUNDO/WILLIAM",
        originSic: "DAL",
        destinationSic: "MIS",
        ect: "23:00",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-12T00:00:00"),
        sealNumber: "56473",
        cube: 0,
        isActDelayed: true,
        isLoadAndHold: true,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: true,
      },
      {
        plannedPullId: 10597,
        doorNumber: 20,
        tripNumber: 3085397,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "SIKANDER/MANNY",
        originSic: "DAL",
        destinationSic: "PER-DHRN",
        ect: "22:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-11T00:00:00"),
        sealNumber: "384575",
        cube: 0,
        isActDelayed: true,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10600,
        doorNumber: 78,
        tripNumber: 3085391,
        trailerNumber: "",
        status: "LDDK",
        shipmentTags: [],
        bookingNumber: "TEAM I",
        originSic: "DAL",
        destinationSic: "EWR",
        ect: "05:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-10T00:00:00"),
        sealNumber: "827363",
        cube: 0,
        isActDelayed: true,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085396,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-09T00:00:00"),
        sealNumber: "",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 30853967,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-08T00:00:00"),
        sealNumber: "",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085398,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-07T00:00:00"),
        sealNumber: "",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085399,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-06T00:00:00"),
        sealNumber: "",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085400,
        trailerNumber: "",
        status: "CLOS",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-05T00:00:00"),
        sealNumber: "",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085401,
        trailerNumber: "",
        status: "ENRT",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-04T00:00:00"),
        sealNumber: "",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085402,
        trailerNumber: "",
        status: "CLOS",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-03T00:00:00"),
        sealNumber: "36457",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085403,
        trailerNumber: "",
        status: "LTRP",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-02T00:00:00"),
        sealNumber: "85767",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10606,
        doorNumber: 91,
        tripNumber: 3085404,
        trailerNumber: "",
        status: "CLOS",
        shipmentTags: [],
        bookingNumber: "ISRAEL",
        originSic: "DAL",
        destinationSic: "LRD",
        ect: "21:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-01T00:00:00"),
        sealNumber: "",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
    ],
  },
  trailersForNotes: {
    summary: {
      bills: 7,
      pieces: 89,
      pallets: 14,
      weight: 8001,
      trailers: 1,
    },
    trailers: [
      {
        plannedPullId: 10592,
        doorNumber: 7,
        tripNumber: 88888888,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "RYAN/VICTOR",
        originSic: "DAL",
        destinationSic: "ATM",
        ect: "05:30",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-01T00:00:00"),
        sealNumber: "10101",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: true,
      },
      {
        plannedPullId: 10593,
        doorNumber: 10,
        tripNumber: null,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "BENJAMIN",
        originSic: "DAL",
        destinationSic: "HTX",
        ect: "22:00",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-12T00:00:00"),
        sealNumber: "20203",
        cube: 0,
        isActDelayed: false,
        isLoadAndHold: false,
        trapName: "HTX tomorrow",
        trailerType: 1,
        trailerTypeName: "Linehaul",
        containsNotes: false,
      },
      {
        plannedPullId: 10596,
        doorNumber: 17,
        tripNumber: 30853698,
        trailerNumber: "",
        status: "PLAN",
        shipmentTags: [],
        bookingNumber: "RAIMUNDO/WILLIAM",
        originSic: "DAL",
        destinationSic: "MIS",
        ect: "23:00",
        act: "",
        departureTime: "",
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        serviceDue: new Date("2024-03-12T00:00:00"),
        sealNumber: "56473",
        cube: 0,
        isActDelayed: true,
        isLoadAndHold: true,
        trapName: "",
        trailerType: 2,
        trailerTypeName: "Trap",
        containsNotes: false,
      },
    ],
  },
} as const satisfies Record<string, LoadTrailers>;

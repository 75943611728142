import { Box, Chip, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Filter } from "../../../../types/filter";

interface QuickFilterProps {
  filter: Filter;
}
type Options = {
  label: string;
  value: string;
};

const chipOptions: Options[] = [
  { label: "All", value: "All" },
  { label: "Routes", value: "delivery_route" },
  { label: "Traps", value: "delivery_trap" },
];

const QuickFilter = ({ filter }: QuickFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const defaultChipStyle = {
    fontWeight: "400",
    height: "40px",
    borderRadius: "100px",
    border: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
      backgroundColor: `${theme.palette.grey[400]} !important`,
    },
  };

  const activeChipStyle = {
    ...defaultChipStyle,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
    },
  };

  const activeChip = searchParams.get(filter.queryParam) || "All";

  const handleChipClick = (param?: string) => {
    const queryParam = filter.queryParam;

    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set(queryParam, param ? param : "All");
      return newParams;
    });
  };

  return (
    <Box sx={{ display: "flex", gap: "8px", marginLeft: "15px" }}>
      {chipOptions.map(({ label, value }) => (
        <Chip
          key={value}
          label={label}
          size="medium"
          variant={activeChip === value ? "filled" : "outlined"}
          sx={activeChip === value ? activeChipStyle : defaultChipStyle}
          onClick={() => handleChipClick(value)}
        />
      ))}
    </Box>
  );
};

export default QuickFilter;

import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import StatusTag from "../../../../components/shared/StatusTag/StatusTag";
import { equipmentTypeMap } from "../../../../types/equipment.type";
import {
  MergeDestination,
  MergeIntoType,
  MergeRoutesTrapsDialogAction,
  MergeRoutesTrapsDialogState,
} from "./MergeRoutesTrapsDialogReducer";

type MergeRoutesTrapsStepOneProps = {
  dialogState: MergeRoutesTrapsDialogState;
  dispatch: (value: MergeRoutesTrapsDialogAction) => void;
  selectedTraps: RouteOrTrap[];
  selectedRoutes: RouteOrTrap[];
  mergeDestinationOptions: MergeDestination[];
  handleNext: (shouldBypassValidation: boolean) => void;
};

export type RouteOrTrap = {
  id: number;
  name: string;
  status: string;
  type: MergeIntoType;
};

export const MergeRoutesTrapsStepOne = ({
  dialogState,
  dispatch,
  selectedTraps,
  selectedRoutes,
  mergeDestinationOptions,
  handleNext,
}: MergeRoutesTrapsStepOneProps) => {
  const { mergeIntoType } = dialogState;

  const mergeIntoOptions =
    mergeIntoType === "trap" ? selectedTraps : selectedRoutes;

  const handleSelectRouteOrTrap = (id: number) => {
    const mergeDestination = mergeDestinationOptions.find((o) => o.id === id);

    if (!mergeDestination) throw new Error("Destination id not found: " + id);

    dispatch({
      type: "set_mergeDestination",
      mergeDestination,
      equipmentTypeId: mergeDestination.trailerId
        ? equipmentTypeMap.trailer
        : mergeDestination.straightTruckId
          ? equipmentTypeMap.straightTruck
          : null,
    });
  };

  const isMergingBothRoutesAndTraps =
    selectedRoutes.length > 0 && selectedTraps.length > 0;

  return (
    <Box>
      <Typography ml="24px" mb="4px">
        Choose where to merge the selected routes/traps
      </Typography>
      {!isMergingBothRoutesAndTraps ? (
        <Box sx={{ borderTop: 1, borderColor: "divider" }}>
          <Typography
            mt="4px"
            ml="24px"
            fontSize="small"
            color={(theme) => theme.palette.grey[600]}
          >
            {selectedTraps.length > 0 && selectedRoutes.length === 0
              ? "Traps"
              : "Routes"}
          </Typography>
        </Box>
      ) : (
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={mergeIntoType}
          onChange={(_, value) =>
            dispatch({ type: "set_mergeIntoType", mergeIntoType: value })
          }
        >
          <Tab label="Routes" value="route" sx={{ width: "40%" }} />
          <Tab label="Traps" value="trap" sx={{ width: "40%" }} />
        </Tabs>
      )}
      <List
        component="nav"
        sx={{
          overflowY: "auto",
          height: isMergingBothRoutesAndTraps ? 208 : 236,
        }}
      >
        {mergeIntoOptions.map((option) => (
          <Box
            display="flex"
            flexDirection="row"
            key={option.id}
            sx={{
              ".arrow-icon-button": {
                visibility: "hidden",
              },
              ":hover": {
                ".arrow-icon-button": {
                  visibility: "visible",
                },
              },
            }}
          >
            <ListItemButton
              selected={option.id === dialogState.mergeDestination.id}
              onClick={(_) => {
                handleSelectRouteOrTrap(option.id);
              }}
            >
              <ListItemText
                sx={{
                  marginLeft: isMergingBothRoutesAndTraps ? "16px" : "24px",
                }}
                primary={option.name}
              />
              <Box mr={8}>
                <StatusTag label={option.status} isPlannedFor="today" />
              </Box>
            </ListItemButton>
            <Box position="absolute" right="15px" mt={1}>
              <IconButton
                className="arrow-icon-button"
                aria-label={`Merge into ${option.name} ${option.type}`}
                onClick={() => {
                  handleSelectRouteOrTrap(option.id);
                  handleNext(true);
                }}
              >
                <ArrowForwardIos fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </List>
    </Box>
  );
};

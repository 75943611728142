import { Suspense, lazy } from "react";
import { env } from "../utils/env";
import { DevTools } from "../devtools/DevTools";
import { AuthUserWithEmail } from "../hooks/react-query/useAuthenticatedUser";
import { ProtectedRoutesProvider } from "./ProtectedRoutesProvider";

const SplitProvider = lazy(() => import("../context/SplitProvider"));
const MockSplitProvider = lazy(() => import("../context/MockSplitProvider"));

type ProtectedRoutesProps = {
  user: AuthUserWithEmail;
};

export default function ProtectedRoutes({ user }: ProtectedRoutesProps) {
  return (
    <Suspense fallback={null}>
      {env.VITE_ENABLE_DEV_TOOLS === "TRUE" ? (
        <MockSplitProvider userId={user.userId}>
          <DevTools appSlot={<ProtectedRoutesProvider user={user} />} />
        </MockSplitProvider>
      ) : (
        <SplitProvider userId={user.userId}>
          <ProtectedRoutesProvider user={user} />
        </SplitProvider>
      )}
    </Suspense>
  );
}

import {
  ListItemIcon,
  MenuItem,
  MenuList,
  Menu,
  Button,
  type SxProps,
} from "@mui/material";
import { forwardRef, MouseEvent, useImperativeHandle, useState } from "react";
import { ContextMenuAction } from "../../types/contextMenuAction";

interface PopupMenuProps {
  contextMenuActions: ContextMenuAction[];
}

export const labelButtonStyle: SxProps = {
  backgroundColor: "transparent",
  border: "none",
  color: "#000",
  paddingY: "6px",
  "&:hover": {
    backgroundColor: "transparent",
  },
  justifyContent: "left",
  alignItems: "start",
};

export type PopupMenuRef = {
  handleClick: (event: MouseEvent<HTMLElement | HTMLButtonElement>) => void;
};

/** A popup menu displays brief content in an overlay or list of actions or options to a user. It can be used with any component as long as it is displayed via an event like "onClick" */
export const PopUpMenu = forwardRef<any, PopupMenuProps>(
  ({ contextMenuActions }: PopupMenuProps, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      handleClick(event: MouseEvent<HTMLElement | HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
      },
    }));

    return (
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList>
          {contextMenuActions.map((option) => (
            <MenuItem
              key={option.label}
              onClick={(event) => {
                event.stopPropagation();
                if (option.handleClick) option.handleClick(event);
              }}
            >
              {option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : ""}
              <Button sx={labelButtonStyle} disableRipple>
                {option.label}
              </Button>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }
);

PopUpMenu.displayName = "PopUpMenu";

import { ErrorBoundary } from "react-error-boundary";
import { ErrorContainer } from "../AccordionWithContentAndActionBar";
import { ClearSelectionButton } from "../ClearSelectionButton";
import { AssignToDialog } from "../../shared/AssignToDialog";
import { useActionsMenuContext } from "../../shared/ActionsMenuContext";
import { InboundTab } from "../../InboundLayout";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { AppointmentBuckets } from "./AppointmentBuckets";
import { Traps } from "./Traps";
import { SpecialShipments } from "./SpecialShipments";
import { DeliveryRoutes } from "./DeliveryRoutes";
import { Sections } from "./Sections";

type SidebarContentProps = {
  tab: InboundTab;
};

const ErrorFallback = () => <ErrorContainer title="Error when fetching" />;

export const SidebarContent = ({ tab }: SidebarContentProps) => {
  const isRoutesAndSectionsEnabled = useIsFeatureFlagEnabled(
    "inbound-routes-and-section-tabs"
  );
  const { activeDialog } = useActionsMenuContext();
  return (
    <>
      {tab === "planning" && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SpecialShipments />
        </ErrorBoundary>
      )}
      {isRoutesAndSectionsEnabled && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <DeliveryRoutes />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Sections />
          </ErrorBoundary>
        </>
      )}
      {tab === "pre-planning" && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Traps tab={tab} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AppointmentBuckets />
          </ErrorBoundary>
          <ClearSelectionButton />
        </>
      )}
      {activeDialog === "assign-to" && <AssignToDialog />}
    </>
  );
};

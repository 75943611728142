import { Box, Grid, Typography } from "@mui/material";
import { TagPill } from "../../../../components/shared/Pill";
import { tagDefaultColor } from "../../../../constants/TagDefaultColor";
import { Tag } from "../../../../types/freight/tag.type";

export const TagsGrid = ({ tags }: { tags: Tag[] }) => (
  <Box sx={{ width: "auto" }}>
    <Grid container spacing="3px" alignItems="center" alignContent="center">
      {tags.map((tag: Tag) => (
        <Grid
          item
          key={tag.name}
          alignContent="center"
          width="auto"
          alignItems="center"
          sx={{ margin: "1px 1px" }}
        >
          <TagPill color={tag.colorHex || tagDefaultColor}>
            <Typography sx={{ fontSize: "9px" }}>{tag.name}</Typography>
          </TagPill>
        </Grid>
      ))}
    </Grid>
  </Box>
);

// CSpell:ignore cbll
import { Link } from "react-router-dom";
import { Tooltip, styled } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { shipmentTagsColumn } from "../../../../utils/tableColumns/commons";
import {
  sortServiceDue,
  sortDatesAsc,
  sortNumbersAsc,
  sortTimesAsc,
} from "../../../../utils/sortComparator";
import {
  formatAppointmentDateTableField,
  formatAppointmentTimeTableField,
  formatEtaTableField,
  formatServiceDueTableField,
} from "../../../../utils/tableFormat";
import StatusTag from "../../../../components/shared/StatusTag/StatusTag";
import { dashIfEmpty, formatNumber } from "../../../../constants/utils";
import { TableShipment } from "../../../../components/shared/old-table/ShipmentsTableBase";
import StopSequenceSelect from "../StopSequenceSelect";
import { AssignShipmentType } from "../../../../types/assignShipment.type";
import { ShipmentWithSequence } from "../../../../types/planning/shipmentSeq.type";
import { FlexRow } from "../../../../components/shared/layout/Flex";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { PlanShipmentsMenuIconButton } from "../PlanningContextMenu/PlanShipmentsMenuIconButton";

const CustomLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const noDistinctStopSeq = (shipments: ShipmentWithSequence[]) => {
  const firstStopSeq = shipments[0].stopSeq;
  return shipments.every((shipment) => shipment.stopSeq === firstStopSeq);
};

const getGoogleMapsUrl = (
  shipment: TableShipment,
  parentType?: AssignShipmentType
) => {
  const { consignee } = shipment;
  if (!consignee?.address) return dashIfEmpty(undefined);
  const address = `${consignee.address}, ${consignee.city}, ${consignee.zipCode}, ${consignee.state}`;

  // t=k for Satellite view
  const googleMapsUrl = `http://maps.google.com/maps?q=${address}&t=k`;
  const hasLatAndLng = shipment.lat && shipment.lng;
  const showWarning = parentType === "route" && !hasLatAndLng;
  const tooltip = showWarning ? "Invalid address" : "Google Maps Url";
  const ariaLabel = showWarning
    ? "Invalid geocode. Verify address."
    : `Google Maps Url for address: ${address}`;

  return (
    <FlexRow style={{ alignItems: "center", gap: "4px" }}>
      <Tooltip title={tooltip} aria-label={ariaLabel}>
        <CustomLink
          to={googleMapsUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: (theme) =>
              showWarning ? theme.palette.warning.main : undefined,
          }}
        >
          {showWarning && <Warning color="warning" />}
          {consignee.address}
        </CustomLink>
      </Tooltip>
    </FlexRow>
  );
};

type PlanShipmentColumnsProps = {
  isEditable: boolean;
  isLoading: boolean;
  timeZone: string;
  routeOrTrap?: PlanTableFormat;
  parentType?: AssignShipmentType;
};

const planShipmentColumns = ({
  isEditable,
  isLoading,
  routeOrTrap,
  timeZone,
  parentType,
}: PlanShipmentColumnsProps): ColumnDef<TableShipment>[] => {
  const planShipmentColumns: ColumnDef<TableShipment>[] = [
    {
      accessorKey: "proNumber",
      header: "PRO",
      accessorFn: ({ proNumber }) => dashIfEmpty(proNumber),
    },
    {
      accessorKey: "serviceDue",
      header: "Service Due",
      accessorFn: ({ serviceDue }) => formatServiceDueTableField(serviceDue),
      sortingFn: sortServiceDue,
    },
    {
      accessorKey: "shipperName",
      accessorFn: ({ shipper }) => dashIfEmpty(shipper?.name),
      header: "Shipper Name",
    },
    {
      accessorKey: "consigneeName",
      header: "Consignee Name",
      accessorFn: ({ consignee }) => dashIfEmpty(consignee?.name),
    },
    {
      accessorKey: "consigneeAddress",
      header: "Consignee Address",
      cell: ({ row }) => getGoogleMapsUrl(row.original, parentType),
    },
    {
      accessorKey: "consigneeCity",
      header: "Consignee City",
      accessorFn: ({ consignee }) => dashIfEmpty(consignee?.city),
    },
    {
      accessorKey: "consigneeZip",
      header: "Consignee Zip",
      accessorFn: ({ consignee }) => dashIfEmpty(consignee?.zipCode),
    },
    {
      accessorKey: "destinationSicCode",
      header: "Dest SIC",
      accessorFn: ({ destinationSicCode }) => dashIfEmpty(destinationSicCode),
      sortDescFirst: false,
    },
    {
      accessorKey: "appointmentDate",
      header: "Appt Date",
      accessorFn: ({ appointmentDate }) =>
        formatAppointmentDateTableField(appointmentDate, timeZone),
      sortingFn: (rowA, rowB) =>
        sortDatesAsc(
          rowA.original.appointmentDate,
          rowB.original.appointmentDate
        ),
    },
    {
      accessorKey: "appointmentTime",
      header: "Appt Time",
      accessorFn: ({
        appointmentDate: appointmentStartDate,
        appointmentEndDate,
      }) =>
        formatAppointmentTimeTableField(
          appointmentStartDate,
          appointmentEndDate,
          timeZone
        ),
      sortingFn: (rowA, rowB) =>
        sortTimesAsc(
          rowA.original.appointmentDate,
          rowB.original.appointmentDate
        ),
    },
    {
      accessorKey: "pltsPcs",
      header: "Plts | Pcs",
      accessorFn: ({ pallets, pieces }) =>
        `${formatNumber(pallets)} | ${formatNumber(pieces)}`,
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.pallets, rowB.original.pallets),
    },
    {
      accessorKey: "weight",
      header: "Weight",
      accessorFn: ({ weight, weightUnit }) =>
        `${formatNumber(weight)} ${weightUnit}`,
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.weight, rowB.original.weight),
    },
    shipmentTagsColumn("tags"),
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }: CellContext<TableShipment, unknown>) => (
        <StatusTag label={getValue<string>()} />
      ),
    },
    {
      accessorKey: "location",
      header: "Trailer #",
      accessorFn: ({ location }) => dashIfEmpty(location),
    },
    {
      accessorKey: "eta",
      header: "ETA",
      accessorFn: ({ etaDate }) => formatEtaTableField(etaDate, timeZone),
      sortingFn: (rowA, rowB) =>
        sortDatesAsc(rowA.original.etaDate, rowB.original.etaDate),
    },
  ];

  if (isEditable && !isLoading) {
    if (!routeOrTrap) {
      throw new Error("routeOrTrap is required when table is editable");
    }
    const editableStartColumns: ColumnDef<TableShipment>[] =
      routeOrTrap.type === "delivery_route"
        ? [
            {
              accessorKey: "stopSeq",
              header: "Stop Sequence",
              cell: ({ getValue, row }) =>
                // Display a read only stop sequence number if any of the conditionals below are true
                parentType === "trap" ||
                routeOrTrap.shipments.length <= 1 ||
                noDistinctStopSeq(routeOrTrap.shipments) ? (
                  getValue()
                ) : (
                  // TODO Refactor to use the reorder by drag and drop
                  <StopSequenceSelect
                    routeOrTrap={routeOrTrap}
                    stopSeq={row.original.stopSeq ?? 0}
                    options={routeOrTrap.shipments
                      .sort((a, b) => a.stopSeq - b.stopSeq)
                      .map((shipment) => ({
                        stopSeq: shipment.stopSeq,
                        id: shipment.id,
                      }))}
                  />
                ),
            },
          ]
        : [];
    const editableEndColumns: ColumnDef<TableShipment>[] = [
      {
        accessorKey: "menu",
        header: "",
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => (
          <PlanShipmentsMenuIconButton
            routeOrTrap={routeOrTrap}
            shipment={row.original}
          />
        ),
      },
    ];
    return [
      ...editableStartColumns,
      ...planShipmentColumns,
      ...editableEndColumns,
    ];
  } else {
    const readOnlyStartColumns: ColumnDef<TableShipment>[] = [
      {
        accessorKey: "stopSeq",
        header: "Stop Sequence",
        cell: ({ getValue }) => getValue(),
      },
    ];
    return [...readOnlyStartColumns, ...planShipmentColumns];
  }
};

export default planShipmentColumns;

import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import {
  MouseEvent,
  Ref,
  forwardRef,
  memo,
  useImperativeHandle,
  useState,
} from "react";
import { ContextMenuAction } from "../../types/contextMenuAction";
import { ContextMenuState } from "../../types/contextMenuState.type";
import { labelButtonStyle } from "./PopupMenu";

/**
 * Constant that anchors the context menu away from the cursor using its size
 */
const contextMenuMouseCursorOffset = {
  x: 2,
  y: -6,
};

type Props<T> = {
  actions: (row?: T) => ContextMenuAction[];
};

export type ContextMenuRef<T> = {
  openContextMenu: (event: MouseEvent, tableRow?: T) => void;
  closeContextMenu: () => void;
};

/** A context menu used in tables and sidebars with shipments. */
const ContextMenuDropdown = <T,>(
  { actions }: Props<T>,
  ref?: Ref<ContextMenuRef<T>>
) => {
  const theme = useTheme();
  const [contextActions, setContextActions] = useState<ContextMenuAction[]>([]);
  const [menuPosition, setMenuPosition] = useState<ContextMenuState>(null);

  const handleContextMenu = (event: MouseEvent, tableRow?: T) => {
    event.preventDefault();
    setMenuPosition(
      menuPosition === null
        ? {
            mouseX: event.clientX + contextMenuMouseCursorOffset.x,
            mouseY: event.clientY + contextMenuMouseCursorOffset.y,
          }
        : null
    );
    setContextActions(actions(tableRow || undefined));
  };

  const handleClose = () => {
    setMenuPosition(null);
  };

  useImperativeHandle(ref, () => ({
    openContextMenu: handleContextMenu,
    closeContextMenu: handleClose,
  }));

  return (
    <Menu
      open={menuPosition !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        menuPosition !== null
          ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
          : undefined
      }
    >
      {contextActions.map((option) =>
        option.itemComponent ? (
          <Box
            key={option.label}
            onClick={(event) => {
              option.handleClick?.(event);
              if (!option.keepOpenAfterClick) {
                handleClose();
              }
            }}
          >
            {option.itemComponent}
          </Box>
        ) : (
          <MenuItem
            disabled={option.disabled}
            key={option.label}
            onClick={(event) => {
              option.handleClick?.(event);
              if (!option.keepOpenAfterClick) {
                handleClose();
              }
            }}
            sx={{
              "&:hover": {
                background: `${theme.palette.grey[50]} !important`,
              },
              "&:focus": {
                background: "inherit",
              },
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <Button sx={labelButtonStyle} disableRipple>
              {option.label}
            </Button>
          </MenuItem>
        )
      )}
    </Menu>
  );
};

ContextMenuDropdown.displayName = "ContextMenuDropdown";
export default memo(forwardRef(ContextMenuDropdown)) as <T>(
  props: Props<T> & { ref: Ref<ContextMenuRef<T>> }
) => React.JSX.Element;

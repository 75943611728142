import { RequestHandler, HttpResponse, delay, http } from "msw";
import dayjs from "dayjs";
import { z } from "zod";
import { apiUrls } from "../utils/apiUrls";
import { Trap, trapSchema } from "../types/planning/trap.type";
import { HandlerConfig } from "../devtools/types";
import { DaylightDateFormat } from "../constants/DaylightDateFormat";
import { User } from "../hooks/react-query/settings/security/useUsers";
import { Note } from "../hooks/react-query/outbound/linehaulTrailers/useLoadTrailerNotes";
import { filterArrayBySearchTerm } from "../constants/filters/utils";
import {
  EquipmentType,
  EquipmentTypeId,
  equipmentTypeMap,
} from "../types/equipment.type";
import { AddToPlanResponse } from "../hooks/react-query/useAddToPlan";
import type { DeliveryRouteSequenceStatus } from "../hooks/react-query/useDeliveryRoutesSequenceStatus";
import { RouteStatus } from "../hooks/react-query/dispatch/useRoutes";
import {
  mockTrapSummaryData,
  mockAppointmentBucketTableData,
  mockSectionsData,
  mockShipmentData,
  mockRoutesSummaryData,
  mockPlannedTraps,
} from "./inbound/prePlanning/prePlanning.mocks";

import { mockMasterScheduleList } from "./linehaul/masterSchedule.mocks";
import { mockEntryDetails } from "./linehaul/entry.mocks";
import { mockLinehaulCarriers } from "./linehaul/linehaulCarriers.mocks";
import { mockServiceTypes } from "./linehaul/serviceTypes.mocks";
import {
  companyOwnedServiceCenters,
  mockDrivers,
  serviceCenterDoorNumbers,
  mockServiceCenterDoors,
  mockDriversUsage,
} from "./serviceCenter/serviceCenters.mocks";
import {
  formatUrl,
  getNextAutoIncValue,
  getTrapNameFromId,
  getUrlParam,
} from "./mockUtils";
import { mockEquipmentData } from "./equipment/equipments.mocks";
import { publishResponseMocks } from "./linehaul/publishResponses.mock";
import { mockMasterScheduleTemplates } from "./linehaul/masterScheduleTemplates.mocks";
import { mockLanesList } from "./outbound/PresetLanes.mocks";
import { linehaulTrailersResponse } from "./outbound/LinehaulTrailers.mocks";
import { linehaulTripsResponse } from "./outbound/LinehaulTrips.mocks";
import { shipmentSearchResponse } from "./outbound/ShipmentsSearch.mocks";
import { loadTrailerNotesResponse } from "./outbound/LoadTrailerNotes.mocks";
import { loadTrailersResponse } from "./outbound/LoadTrailers.mocks";
import { pndTrailersResponse } from "./outbound/PndTrailers.mocks";
import { trapTrailersResponse } from "./outbound/TrapTrailers.mocks";
import {
  laneShipments,
  dalLaneShipments,
  dfwLaneShipments,
} from "./outbound/LaneDetails/ShipmentDetails/LaneShipments.mocks";
import { shipmentDetails } from "./outbound/LaneDetails/ShipmentDetails/ShipmentDetails.mocks";
import { doorAssets } from "./outbound/LaneDetails/DoorAssets/DoorAssets.mocks";
import { linehaulTrailerShipmentsResponse } from "./outbound/LinehaulTrailerShipments.mocks";
import { loadTrailerShipmentsResponse } from "./outbound/LoadsTrailerShipments.mocks";

import { mockStaticLoadTemplatesList } from "./outbound/StaticLoadTemplates.mocks";
import { doorLayoutList } from "./outbound/DoorsLayout.mocks";
import { carriersList } from "./outbound/Carriers.mocks";
import { equipmentList } from "./outbound/Equipment.mocks";
import { laneDetails } from "./outbound/LaneDetails/LaneDetails.mocks";
import { mockLoadBoardSchedulePair } from "./linehaul/loadBoardSchedulePair.mocks";
import { mockChangeRequestLogs } from "./linehaul/changeRequestLog.mocks";
import {
  mockPlannedRoutesTraps,
  mockRouteAssignment,
  mockRouteStatuses,
  mockTrapStatuses,
} from "./inbound/planning/planning.mock";
import { mockPresetRoutesAndTraps } from "./inbound/presetRoutesAndTraps.mock";
import {
  mockPlannedShipmentsDetails,
  mockShipmentIds,
  mockUnaasignedShipmentIds,
} from "./inbound/planning/shipments.mock";
import {
  mockFodShipments,
  mockIncompleteShipmentIds,
  mockIncompleteShipmentsDetails,
  mockLinehaulTrips,
  mockShuttleTrips,
} from "./inbound/unplannedFreight/unplannedFreight.mocks";
import { mockPlannedRouteTrapStatuses } from "./inbound/planning/plannedRouteTrapStatus.mock";
import { mockStops } from "./inbound/unplannedFreight/linehaul-stops.mocks";
import { mockEquipmentsBySicIdAndTypeData } from "./equipment/equipmentsBySicIdAndType.mocks";
import { mockEquipmentsUsageBySicIdData } from "./equipment/equipmentsUsageBySicId.mocks";
import { getMockReport } from "./report";
import {
  mockDispatchAvailableRouteStatuses,
  mockDispatchRouteDetail,
  mockDispatchRoutes,
  mockDispatchSuggestedRoutes,
} from "./dispatch/routes.mock";
import {
  mockDispatchStops,
  mockDispatchStopShipmentNotes,
  mockDispatchStopSuggestedSics,
} from "./dispatch/stops.mock";
import { mockSummary } from "./dispatch/summary.mock";
import { mockDriverLatestLocations } from "./dispatch/drivers.mock";
import { mockPrintResult } from "./inbound/planning/printResult.mock";
import { freightDetails } from "./outbound/FreightDetails.mocks";
import { loadTrips, trailers } from "./linehaul/loadTrips.mocks";
import { mockDaySummary } from "./linehaul/daySummary.mocks";
import {
  mockDaySummaryV2,
  mockTripDetails,
} from "./linehaul/daySummaryV2.mocks";
import { mockServiceCenterGeocode } from "./serviceCenter/geocode.mocks";
import { mockRegions } from "./linehaul/regions.mock";
import { mockEmployeeDrivers } from "./linehaul/employeeDrivers.mock";
import { mockTripsForReassign } from "./linehaul/tripsForReassign.mocks";
import { mockedClosedTripDetails } from "./linehaul/closedTripDetails.mocks";
import { mockEquipment } from "./linehaul/equipment.mock";
import { notifications } from "./linehaul/ucccNotifications.mocks";
import { mockIncompleteTrailers } from "./inbound/unplannedFreight/incomplete-trailer-mocks";
import { mockScacCodes } from "./linehaul/scacCodes.mocks";
import { mockUcccPlanningShipments } from "./linehaul/ucCcPlanningShipments.mocks";
import { mockDelayReasons } from "./linehaul/delayReasons.mocks";
import { mockShipmentDetails } from "./dispatch/shipments.mock";
import { mockNotifications } from "./dispatch/notifications.mock";
import { mockSicContact, mockSicDetails } from "./settings/linehaul-sics.mocks";
import { doorShipmentsResponse } from "./outbound/DoorShipments.mocks";

// the endpoints for these handlers are deployed and active. More: https://mswjs.io/docs/basics/intercepting-requests#request-pathname
export function requestHandlers(config: HandlerConfig): RequestHandler[] {
  return [
    /** Master Schedule */
    http.get(
      formatUrl(apiUrls.masterSchedule.get("*")),
      async ({ request }) => {
        await delay(config.delay);
        const url = new URL(request.url);
        const date = dayjs(url.searchParams.get("weekOf") ?? "");
        const key = date.isSame(dayjs(), "week")
          ? "default"
          : date.isSame(dayjs().add({ week: 1 }), "week")
            ? "deletable"
            : "empty";
        return HttpResponse.json(mockMasterScheduleList[key]);
      }
    ),

    http.post(
      formatUrl(apiUrls.masterSchedule.createTemplateWithClone),
      async () => {
        await delay(config.delay);
        return HttpResponse.text(null, { status: 200 });
      }
    ),

    http.get(
      formatUrl(apiUrls.masterSchedule.getSchedulePair(":id")),
      async ({ request }) => {
        await delay(config.delay);
        const url = new URL(request.url);
        const id = url.searchParams.get("id");
        if (!id) throw new Error("Missing id parameter");
        return HttpResponse.json(mockEntryDetails.default);
      }
    ),

    http.post(
      formatUrl(
        apiUrls.masterSchedule.publish(
          dayjs().endOf("week").format(DaylightDateFormat.ISO_DATE)
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(publishResponseMocks.default);
      }
    ),

    http.get(formatUrl(apiUrls.masterSchedule.getCarriers), async () => {
      await delay(config.delay);
      return HttpResponse.json(mockLinehaulCarriers.default);
    }),

    http.get(formatUrl(apiUrls.masterSchedule.getServiceTypes), async () => {
      await delay(config.delay);
      return HttpResponse.json(mockServiceTypes.default);
    }),

    http.get(formatUrl(apiUrls.masterSchedule.getTemplates), async () => {
      await delay(config.delay);
      return HttpResponse.json(mockMasterScheduleTemplates.default);
    }),

    /** Inbound Pre-Planning */

    // * Get routes
    http.get(
      formatUrl(
        apiUrls.getDeliveryRoutesSummary(
          ":sicId" as unknown as number,
          ":date" as unknown as number
        )
      ),
      async ({ request }) => {
        await delay(config.delay);
        const url = new URL(request.url);
        const searchTerm = url.searchParams.get("searchTerm");
        return HttpResponse.json(
          filterArrayBySearchTerm(
            mockRoutesSummaryData.default,
            ["title"],
            searchTerm || ""
          )
        );
      }
    ),

    // * Get traps
    http.get(formatUrl(apiUrls.getTraps), async ({ request }) => {
      await delay(config.delay);
      const url = new URL(request.url);
      const searchTerm = url.searchParams.get("searchTerm");
      return HttpResponse.json(
        filterArrayBySearchTerm(
          mockTrapSummaryData.default,
          ["title", "trailerNumber"],
          searchTerm || ""
        )
      );
    }),

    http.get(
      formatUrl(apiUrls.getInboundTraps(":sicId", ":date")),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockPlannedTraps.default);
      }
    ),

    // * Get appointment buckets
    http.get(
      formatUrl(apiUrls.getAppointmentBucket(":sicId", "*")),
      async ({ request }) => {
        const url = new URL(request.url);
        const searchTerm = url.searchParams.get("searchTerm");
        await delay(config.delay);
        return HttpResponse.json(
          filterArrayBySearchTerm(
            mockAppointmentBucketTableData.default,
            ["title"],
            searchTerm || ""
          )
        );
      }
    ),

    // * Get sections
    http.get(formatUrl(apiUrls.getSectionsTableData), async ({ request }) => {
      await delay(config.delay);
      const url = new URL(request.url);
      const searchTerm = url.searchParams.get("searchTerm");
      const destinationSicId = url.searchParams.get("destinationSicId");
      if (!destinationSicId) {
        throw new Error("Missing destinationSicId parameter");
      }
      return HttpResponse.json(
        filterArrayBySearchTerm(
          mockSectionsData.default,
          ["title"],
          searchTerm || ""
        ),
        {
          status: destinationSicId ? 200 : 400,
        }
      );
    }),

    // * Merge Routes Traps
    http.post(formatUrl(apiUrls.mergeRoutesAndTraps), async () => {
      await delay(config.delay);
      return new HttpResponse(null, { status: 204 });
    }),

    // * Merge Routes
    http.patch(
      formatUrl(apiUrls.mergeRoutes(":routeId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return new HttpResponse(null, { status: 204 });
      }
    ),

    // * Delete Routes
    http.delete(
      formatUrl(apiUrls.deleteRouteAssignments(":idsToDelete")),
      async () => {
        await delay(config.delay);
        return new HttpResponse(null, { status: 200 });
      }
    ),

    /** Global */
    // * Get service centers
    http.get(formatUrl(apiUrls.getServiceCenters), async () => {
      await delay(config.delay);
      return HttpResponse.json(companyOwnedServiceCenters);
    }),

    http.get(
      formatUrl(
        apiUrls.getServiceCenterGeocodeByIds([":sicIds" as unknown as number])
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockServiceCenterGeocode.default);
      }
    ),

    // * Create Trap
    http.post(
      formatUrl(apiUrls.createTrap(":sicId", ":unixDate")),
      async ({ request }) => {
        const responseSchema = z.object({
          name: z.string(),
        });

        const data = responseSchema.parse(await request.json());

        // Simulate a server-side auto-incrementing id by getting the current max id and adding 1
        const newAutoIncTrapId = getNextAutoIncValue(
          mockTrapSummaryData.default.map((item) => item.id)
        );
        const title = getTrapNameFromId(newAutoIncTrapId);

        const newTrap: Trap = trapSchema.parse({
          id: newAutoIncTrapId,
          name: data.name,
          title,
          doorId: null,
          notes: null,
          shipmentsTags: [],
          trailerNumber: null,
          shipmentsCount: 0,
          shipmentsPallets: 0,
          shipmentsPieces: 0,
          shipmentsWeight: 0,
          shipmentsWeightUnit: "lbs",
          shipmentIds: [],
          appointmentDate: new Date(2023, 2, 1),
          serviceDueDate: new Date(2023, 2, 1),
        });

        mockTrapSummaryData.default.push(newTrap);

        await delay(config.delay);
        return HttpResponse.json(newTrap, { status: 201 });
      }
    ),

    // * Get Load templates
    http.get(
      formatUrl(apiUrls.getStaticLoadTemplates(":sicCode")),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockStaticLoadTemplatesList.default);
      }
    ),

    // * Get Doors
    http.get(formatUrl(apiUrls.getDoorLayouts(":sicCode")), async () => {
      await delay(config.delay);
      return HttpResponse.json(doorLayoutList.default);
    }),

    // * Get preset lane
    http.get(formatUrl(apiUrls.getPresetLanes(":sicCode")), () => {
      delay(config.delay);
      return HttpResponse.json(mockLanesList.default);
    }),

    // * Get Outbound linehaul Trailers
    http.get(
      formatUrl(
        apiUrls.getOutboundLinehaulTrailers({
          sicCode: ":sicCode",
          eta: ":eta",
        })
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(linehaulTrailersResponse.default);
      }
    ),

    // * Get Outbound linehaul Trips
    http.get(
      formatUrl(apiUrls.getLinehaulTrips(":sicCode", ":destinationSicCode")),
      () => {
        delay(config.delay);
        return HttpResponse.json(linehaulTripsResponse.default);
      }
    ),

    // * Get Outbound load Trailers
    http.get(formatUrl(apiUrls.getOutboundLoadTrailers(":sicCode")), () => {
      delay(config.delay);
      return HttpResponse.json(loadTrailersResponse.trailersForNotes);
    }),

    // * Get Outbound pnd Trailers
    http.get(formatUrl(apiUrls.getPndTrailers(":sicCode")), () => {
      delay(config.delay);
      return HttpResponse.json(pndTrailersResponse.default);
    }),

    // * Get Outbound trap Trailers
    http.get(formatUrl(apiUrls.getTrapTrailers(":sicCode")), () => {
      delay(config.delay);
      return HttpResponse.json(trapTrailersResponse.default);
    }),

    // * Get Outbound carriers
    http.get(formatUrl(apiUrls.getOutboundCarriers), () => {
      delay(config.delay);
      return HttpResponse.json(carriersList.default);
    }),

    // * Get Outbound equipment
    http.get(formatUrl(apiUrls.getEquipments(":sicCode")), () => {
      delay(config.delay);
      return HttpResponse.json(equipmentList.default);
    }),

    // * Get Outbound laneDetails
    http.get(formatUrl(apiUrls.getLaneDetailsTonnage(":sicCode", [])), () => {
      delay(config.delay);
      if (config.scenario === "error") {
        return HttpResponse.json(
          {
            success: false,
            response: {
              errorMessage: "Plan not started today",
            },
          },
          {
            status: 400,
          }
        );
      } else {
        return HttpResponse.json(laneDetails.default);
      }
    }),

    // * Outbound assign assets
    http.put(
      formatUrl(
        apiUrls.assignAsset(":sicCode", ":doorNumber" as unknown as number)
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(null, { status: 201 });
      }
    ),
    // * Outbound assign traps
    http.put(
      formatUrl(
        apiUrls.assignTrap(":sicCode", ":doorNumber" as unknown as number)
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(null, { status: 201 });
      }
    ),

    // * Outbound edit assign traps
    http.put(
      formatUrl(
        apiUrls.editAssignTrap(
          ":sicCode",
          ":plannedPullId" as unknown as number
        )
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(null, { status: 201 });
      }
    ),

    // * Get Outbound linehaul Trailer shipments
    http.get(
      formatUrl(
        apiUrls.getOutboundLinehaulTrailerShipments(
          ":sicCode",
          ":tripNumber" as unknown as number
        )
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(linehaulTrailerShipmentsResponse.default);
      }
    ),

    // * Get Outbound loads Trailer shipments
    http.get(
      formatUrl(
        apiUrls.getOutboundLoadTrailerShipments(
          ":sicCode",
          ":plannedPullId" as unknown as number
        )
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(loadTrailerShipmentsResponse.default);
      }
    ),

    // * Get Outbound Shipments search
    http.get(
      formatUrl(
        apiUrls.getOutboundShipmentsSearch(
          ":sicCode",
          ":plannedPullId" as unknown as number,
          ":proNumber"
        )
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(shipmentSearchResponse.default);
      }
    ),

    // * Get Door Shipments
    http.get(
      formatUrl(
        apiUrls.getDoorShipments(":sicCode", ":doorNumber" as unknown as number)
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(doorShipmentsResponse.default);
      }
    ),

    // * Get Lane shipments
    http.get(
      formatUrl(
        apiUrls.getDestinationShipmentsByLane(
          ":sicCode",
          ":destinationType" as unknown as number,
          ":loadToSicId" as unknown as number,
          ":sicId" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        if (params.sicId) {
          let responseData;
          switch (Number(params.sicId)) {
            case 7:
              responseData = dfwLaneShipments.default;
              break;
            case 114:
              responseData = dalLaneShipments.default;
              break;
            default:
              responseData = laneShipments.default;
              break;
          }
          return HttpResponse.json(responseData);
        } else {
          return HttpResponse.json(laneShipments.default);
        }
      }
    ),

    http.get(
      formatUrl(
        apiUrls.getOutboundShipmentDetailsV2(
          ":sicCode",
          ":laneType" as unknown as number,
          ":destinationSicId" as unknown as number
        )
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(shipmentDetails.default);
      }
    ),

    http.post(
      formatUrl(
        apiUrls.splitLane(":sicCode", ":destinationSicId" as unknown as number)
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(null, { status: 201 });
      }
    ),

    // * Get Door Assets
    http.get(
      formatUrl(
        apiUrls.getDoorAssets(
          ":sicCode",
          ":laneType" as unknown as number,
          ":destinationSicId" as unknown as number
        )
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(doorAssets.default);
      }
    ),

    // * Get Outbound Load Trailer Notes
    http.get(
      formatUrl(
        apiUrls.getLoadTrailerNotes(
          ":sicCode",
          ":plannedPullId" as unknown as number
        )
      ),
      () => {
        delay(config.delay);
        return HttpResponse.json(loadTrailerNotesResponse.default);
      }
    ),

    http.post(
      formatUrl(
        apiUrls.createLoadTrailerNote(
          ":sicCode",
          ":plannedPullId" as unknown as number
        )
      ),
      async ({ request }) => {
        const requestSchema = z.object({
          content: z.string(),
        });

        const data = requestSchema.parse(await request.json());
        const newAutoIncNoteId = getNextAutoIncValue(
          loadTrailerNotesResponse.default.map((item) => item.id)
        );

        const newNote: Note = {
          id: newAutoIncNoteId,
          content: data.content,
          userName: "Current User",
          timeStamp: new Date(),
          isEdited: false,
          canEdit: true,
        };

        loadTrailerNotesResponse.default.push(newNote);

        await delay(config.delay);

        return HttpResponse.json(newNote, { status: 201 });
      }
    ),

    http.put(
      formatUrl(
        apiUrls.updateLoadTrailerNote(
          ":sicCode",
          ":plannedPullId" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.text(null, { status: 200 });
      }
    ),

    http.delete(
      formatUrl(
        apiUrls.deleteLoadTrailerNote(
          ":sicCode",
          ":plannedPullId" as unknown as number,
          ":noteId" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.text(null, { status: 200 });
      }
    ),

    // * Get loadboard schedule pairs
    http.get(
      formatUrl(apiUrls.loadBoard.getSchedulePair(":pairId")),
      async ({ params }) => {
        await delay(config.delay);
        const { pairId } = params;
        if (!pairId) throw new Error("Missing pairId parameter");
        return HttpResponse.json(mockLoadBoardSchedulePair.multipleLoad);
      }
    ),

    // * Get loadboard change requests
    http.get(
      formatUrl(apiUrls.loadBoard.getChangeRequestLogs(":scheduleId")),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockChangeRequestLogs.default);
      }
    ),

    /** Inbound Planning */
    // * Put Delivery Route Status
    http.put(
      formatUrl(apiUrls.putDeliveryRouteStatus(":id" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.text(null, { status: 204 });
      }
    ),

    // * Unassign Shipment
    http.post(apiUrls.reassignShipments, async () => {
      await delay(config.delay);
      return HttpResponse.text(null, { status: 204 });
    }),

    // * Get Unassigned Shipments
    http.get(
      formatUrl(
        apiUrls.getUnassignedShipments(
          ":sicId" as unknown as number,
          ":date" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockUnaasignedShipmentIds);
      }
    ),

    // * Get Route Assigments
    http.get(
      formatUrl(
        apiUrls.getRouteAssignments(
          ":date" as unknown as number,
          ":sicId" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockPlannedRoutesTraps.default);
      }
    ),

    http.get(
      formatUrl(apiUrls.getDeliveryRoutesTrapsStatus([], [])), // passing empty arrays since the search params are ignored by msw's matching algorithm, and thus, stripped by formatUrl anyway.
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockPlannedRouteTrapStatuses.default);
      }
    ),

    http.put(formatUrl(apiUrls.putDeliveryRoutesTrapsStatus), async () => {
      await delay(config.delay);
      return HttpResponse.text(null, { status: 204 });
    }),

    // * Get Preset Routes
    http.get(formatUrl(apiUrls.getRoutesAndTraps), async ({ request }) => {
      await delay(config.delay);
      const url = new URL(request.url);
      const sicId = url.searchParams.get("sicId");
      if (!sicId) throw new Error("Missing sicId parameter");
      return HttpResponse.json(mockPresetRoutesAndTraps.default);
    }),
    // * Put Preset Routes
    http.put(
      formatUrl(apiUrls.putRoutes(":routeId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.text(null, { status: 200 });
      }
    ),

    // * Get Planning Shipment Details
    http.post<any, number[]>(
      formatUrl(apiUrls.getShipmentDetails),
      async ({ request }) => {
        const shipmentIds = await request.json();
        await delay(config.delay);

        if (
          JSON.stringify(mockShipmentIds.sort()) ===
          JSON.stringify(shipmentIds.sort())
        ) {
          return HttpResponse.json(mockPlannedShipmentsDetails.default);
        }

        if (
          JSON.stringify(mockIncompleteShipmentIds[1]) ===
          JSON.stringify(shipmentIds[1])
        ) {
          return HttpResponse.json(mockIncompleteShipmentsDetails.default);
        }

        return HttpResponse.json(
          mockShipmentData.default.filter((s) => shipmentIds.includes(s.id))
        );
      }
    ),

    http.get(
      formatUrl(apiUrls.getShipmentsByRoute(":trailerId")),
      async ({ request }) => {
        await delay(config.delay);
        const url = new URL(request.url);
        const routeId = url.searchParams.get("trailerId");
        if (routeId) {
          const route = mockPlannedRoutesTraps.default.find(
            (r) => r.id === parseInt(routeId, 10)
          );
          const shipmentIds = route ? route.shipmentIds : [];
          const shipments = mockPlannedShipmentsDetails.default.filter(
            (shipment) => shipmentIds.includes(shipment.id)
          );
          return HttpResponse.json(shipments);
        } else {
          return HttpResponse.json(null, { status: 404 });
        }
      }
    ),

    http.get(
      formatUrl(apiUrls.getShipmentsByTrap(":trailerId")),
      async ({ request }) => {
        await delay(config.delay);
        const url = new URL(request.url);
        const trapId = url.searchParams.get("trailerId");
        if (trapId) {
          const route = mockPlannedRoutesTraps.default.find(
            (r) => r.id === parseInt(trapId, 10)
          );
          const shipmentIds = route ? route.shipmentIds : [];
          const shipments = mockPlannedShipmentsDetails.default.filter(
            (shipment) => shipmentIds.includes(shipment.id)
          );
          return HttpResponse.json(shipments);
        } else {
          return HttpResponse.json(null, { status: 404 });
        }
      }
    ),

    // * Get Planned Route statuses
    http.get(
      formatUrl(apiUrls.getDeliveryRouteStatus(":id" as unknown as number)),
      async () => {
        await delay(config.delay);
        const scenario = getUrlParam("deliveryRouteStatusScenario");
        if (scenario === "allTransitionsAllowed") {
          return HttpResponse.json(mockRouteStatuses.allTransitionsAllowed);
        }
        return HttpResponse.json(mockRouteStatuses.default);
      }
    ),
    //* Get Planned Trap statuses
    http.get(
      formatUrl(apiUrls.getDeliveryTrapStatus(":id" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockTrapStatuses.default);
      }
    ),

    /** Inbound Unplanned Freight */
    // * Get linehaul trips
    http.get(formatUrl(apiUrls.getTripLH), async ({ request }) => {
      await delay(config.delay);
      const url = new URL(request.url);
      const searchTerm = url.searchParams.get("searchTerm");
      const destinationSicId = url.searchParams.get("destinationSicId");
      const planDate = url.searchParams.get("planDate");
      if (!destinationSicId) {
        throw new Error("Missing destinationSicId parameter");
      }
      if (!planDate) throw new Error("Missing planDate parameter");
      return HttpResponse.json(
        filterArrayBySearchTerm(
          mockLinehaulTrips.default,
          ["tripNumber", "trailerNumber"],
          searchTerm || ""
        )
      );
    }),

    // * Get linehaul stops
    http.get(formatUrl(apiUrls.getTripLHStops), async ({ request }) => {
      await delay(config.delay);
      const url = new URL(request.url);
      const destinationSicId = url.searchParams.get("destinationSicId");
      const planDate = url.searchParams.get("planDate");
      const searchTerm = url.searchParams.get("searchTerm");
      if (!destinationSicId) {
        throw new Error("Missing destinationSicId parameter");
      }
      if (!planDate) throw new Error("Missing planDate parameter");
      return HttpResponse.json(
        filterArrayBySearchTerm(
          mockStops.default,
          ["tripNumber", "trailerNumber"],
          searchTerm || ""
        )
      );
    }),

    // * Get freights
    http.get(formatUrl(apiUrls.getAdditionalFreight), async ({ request }) => {
      await delay(config.delay);
      const url = new URL(request.url);
      const searchTerm = url.searchParams.get("searchTerm");
      const destinationSicId = url.searchParams.get("destinationSicId");
      const planDate = url.searchParams.get("planDate");
      if (!destinationSicId) {
        throw new Error("Missing destinationSicId parameter");
      }
      if (!planDate) throw new Error("Missing planDate parameter");
      return HttpResponse.json(
        filterArrayBySearchTerm(
          mockFodShipments.default,
          ["proNumber"],
          searchTerm || ""
        )
      );
    }),

    // * Get shuttle trips
    http.get(formatUrl(apiUrls.getTripShuttle), async ({ request }) => {
      await delay(config.delay);
      const url = new URL(request.url);
      const searchTerm = url.searchParams.get("searchTerm");
      const destinationSicId = url.searchParams.get("destinationSicId");
      const planDate = url.searchParams.get("planDate");
      if (!destinationSicId) {
        throw new Error("Missing destinationSicId parameter");
      }
      if (!planDate) throw new Error("Missing planDate parameter");
      return HttpResponse.json(
        filterArrayBySearchTerm(
          mockShuttleTrips.default,
          ["tripNumber", "trailerNumber"],
          searchTerm || ""
        )
      );
    }),

    // * Get Incomplete Trailers
    http.get(
      formatUrl(
        apiUrls.getIncompleteTrailer(
          ":date" as unknown as number,
          ":sicId" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockIncompleteTrailers);
      }
    ),

    http.post(
      formatUrl(
        apiUrls.reinstateToPlan(
          ":date" as unknown as number,
          ":sicId" as unknown as number
        )
      ),

      async () => {
        await delay(config.delay);
        return HttpResponse.json(null, {
          status: 200,
        });
      }
    ),

    // * Add to plan
    http.post<any, { shipmentIds: number[] }>(
      formatUrl(
        apiUrls.addToPlan(
          ":sicId" as unknown as number,
          ":date" as unknown as number
        )
      ),
      async ({ request }) => {
        await delay(config.delay);
        const { shipmentIds } = await request.json();
        const data: AddToPlanResponse = {
          successfullyProcessedShipmentCount: shipmentIds.length,
          failedProcessedShipmentCount: 0,
          failedShipmentIds: [],
          failedShipments: [],
          processedShipmentCount: shipmentIds.length,
          processedShipmentIds: shipmentIds,
          shipmentCountSubmitted: shipmentIds.length,
          shipmentIdsWithMissingConsigneeInfo: [],
          unprocessedShipmentCount: 0,
          unprocessedShipmentSearchResults: [],
        };
        return HttpResponse.json(data);
      }
    ),

    // * Put Preset Routes
    http.put(
      formatUrl(apiUrls.putRoutes(":routeId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.text(null, { status: 200 });
      }
    ),

    http.get(
      formatUrl(
        apiUrls.getDoorNumbersBySicIdOld(":sicId" as unknown as number)
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(serviceCenterDoorNumbers);
      }
    ),

    http.get(
      formatUrl(
        apiUrls.getEquipmentDetailsBySicId(":sicId" as unknown as number)
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockEquipmentData.default);
      }
    ),

    http.get(
      formatUrl(
        apiUrls.getEquipmentById(":equipmentIdParam" as unknown as number)
      ),
      async ({ params }) => {
        await delay(config.delay);
        const { equipmentIdParam } = params;
        const equipmentId = Number(equipmentIdParam);

        const mockEquipments = mockEquipmentData.getById;
        const mockEquipment = mockEquipments.find(
          (equipment) => equipment.id === equipmentId
        );
        return HttpResponse.json(mockEquipment);
      }
    ),

    http.get(formatUrl(apiUrls.getCurrentUser), async () => {
      await delay(config.delay);
      const mockUser: User = {
        id: "7fd75d50-622e-4258-9027-606eb0f11809",
        firstName: "Mock",
        lastName: "User",
        email: "mock@user.com",
        isActive: true,
        sicId: 1,
        groups: [],
        loginId: null,
      };
      return HttpResponse.json(mockUser);
    }),

    http.get(
      formatUrl(apiUrls.getGroup(":groupId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.json({
          data: {
            id: 999,
            name: "Mock Group",
            description: "Mock Group Description",
          },
        });
      }
    ),

    http.post(
      formatUrl(apiUrls.autoSequenceRoutes([])),
      async ({ request }) => {
        await delay(config.delay);
        const scenario = getUrlParam("autoSequenceRoutesScenario");
        if (scenario === "error") {
          return new HttpResponse(null, {
            status: 500,
            statusText: "Auto sequence routes error",
          });
        }
        const url = new URL(request.url);
        const routeIds = url.searchParams.getAll("routeIds");
        return HttpResponse.json(routeIds.map(Number));
      }
    ),

    http.get(
      formatUrl(apiUrls.getDeliveryRoutesSequenceStatus(":routeIds")),
      async ({ request }) => {
        // Delay to show Auto Sequence feedback
        await delay(300);
        const url = new URL(request.url);
        const routeIds = url.searchParams.getAll("routeIds");
        const result: DeliveryRouteSequenceStatus[] = routeIds.map(
          (routeId) => ({ id: Number(routeId), sequenceStatus: "processed" })
        );
        return HttpResponse.json(result);
      }
    ),

    http.post(formatUrl(apiUrls.lockRoutes), async () => {
      await delay(config.delay);
      return new HttpResponse(null, { status: 204 });
    }),

    http.get(
      formatUrl(apiUrls.getDoorNumbersBySicId(":sicId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockServiceCenterDoors.default);
      }
    ),

    http.get(
      formatUrl(
        apiUrls.getEquipmentsBySicIdAndTypeId(
          ":sicIdParam" as unknown as number,
          ":typeParam" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        const { typeParam } = params;
        if (!typeParam) {
          return HttpResponse.json([]);
        }

        const allowedEquipmentTypes = Object.values(equipmentTypeMap);
        if (
          !allowedEquipmentTypes.includes(Number(typeParam) as EquipmentTypeId)
        ) {
          throw new Error("Invalid equipment type");
        }

        const equipmentType = Object.keys(equipmentTypeMap).find(
          (key) =>
            equipmentTypeMap[key as keyof typeof equipmentTypeMap] ===
            Number(typeParam)
        ) as EquipmentType | undefined;

        if (equipmentType && equipmentType in equipmentTypeMap) {
          return HttpResponse.json(
            mockEquipmentsBySicIdAndTypeData.default[equipmentType]
          );
        } else {
          return new HttpResponse(null, {
            status: 400,
            statusText: "Invalid equipment type",
          });
        }
      }
    ),

    http.get(
      formatUrl(
        apiUrls.getEquipmentsUsageBySicId(":sicIdParam" as unknown as number)
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockEquipmentsUsageBySicIdData.default, {
          status: 200,
        });
      }
    ),

    http.put(
      formatUrl(apiUrls.putRouteAssignment(":routeId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockRouteAssignment.default, { status: 200 });
      }
    ),

    // * Drivers

    http.get(
      formatUrl(apiUrls.getDrivers(":sicId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDrivers.default);
      }
    ),

    http.get(
      formatUrl(apiUrls.getDriverById(":driverId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDrivers.default[0]);
      }
    ),

    http.put(
      formatUrl(apiUrls.putDriver(":driverId" as unknown as number)),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDrivers.default[0]);
      }
    ),

    http.get(
      formatUrl(
        apiUrls.getDriversUsageBySicIdAndPlanDate(
          ":sicIdParam" as unknown as number,
          ":planDateParam" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDriversUsage.default, {
          status: 200,
        });
      }
    ),

    // * Reports
    http.get(
      formatUrl(apiUrls.getOutboundLinehaulTrailerShipmentsPdf(":sicCode", [])),
      getMockReport
    ),
    http.get(
      formatUrl(apiUrls.getOutboundPndTrailerShipmentsPdf(":sicCode", [])),
      getMockReport
    ),
    http.get(
      formatUrl(apiUrls.getOutboundTrapTrailerShipmentsPdf(":sicCode", [])),
      getMockReport
    ),
    http.get(
      formatUrl(apiUrls.getOutboundLoadTrailerShipmentsPdf(":sicCode", [])),
      getMockReport
    ),
    http.post(formatUrl(apiUrls.getBulkReport), async () => {
      await delay(config.delay);
      return HttpResponse.json(mockPrintResult.default, {
        status: 200,
      });
    }),

    // * Dispatch Routes
    http.get(
      formatUrl(
        apiUrls.dispatch.getLatestDriverLocations(
          ":sicCode" as unknown as number,
          "*"
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDriverLatestLocations.default, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getRoutes(":sicCode" as unknown as number, ":planDate")
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDispatchRoutes.default, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getRoute(
          ":sicId" as unknown as number,
          ":id" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        const { id } = params;
        const routeId = Number(id);
        if (!routeId) {
          return new HttpResponse(null, { status: 404 });
        }
        const route = mockDispatchRouteDetail.default.find(
          (route) => route.id === routeId
        );
        if (!route) {
          return new HttpResponse(null, { status: 404 });
        }
        return HttpResponse.json(route, { status: 200 });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getSummary(
          ":sicId" as unknown as number,
          ":planDate" as unknown as string
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockSummary.default, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getStops(
          ":sicId" as unknown as number,
          ":planDate" as unknown as string
        )
      ),
      async () => {
        await delay(config.delay);
        const scenario = getUrlParam("stopsScenario");
        type scenarios = keyof typeof mockDispatchStops;
        if (scenario) {
          if (!Object.keys(mockDispatchStops).includes(scenario)) {
            throw new Error("Invalid stops scenario");
          }
          return HttpResponse.json(mockDispatchStops[scenario as scenarios], {
            status: 200,
          });
        }

        return HttpResponse.json(mockDispatchStops.default, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getStop(
          ":sicId" as unknown as number,
          ":id" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        const stop = mockDispatchStops.default.find(
          (stop) => stop.id === Number(params.id)
        );

        if (!stop) {
          return new HttpResponse(null, { status: 404 });
        }

        return HttpResponse.json(stop, {
          status: 200,
        });
      }
    ),
    http.post(
      formatUrl(
        apiUrls.dispatch.postUnassignStopFromRoute(
          ":sicId" as unknown as number,
          ":id" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        const scenario = getUrlParam("unassignStopScenario");
        if (scenario === "error") {
          return new HttpResponse(null, {
            status: 500,
          });
        }
        const stop = mockDispatchStops.default.find(
          (stop) => stop.id === Number(params.id)
        );
        if (!stop) {
          return new HttpResponse(null, { status: 404 });
        }

        return new HttpResponse(null, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getSuggestedRoutes(
          ":sicCode" as unknown as number,
          ":stopId" as unknown as number,
          ":planDate" as unknown as string
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDispatchSuggestedRoutes.default, {
          status: 200,
        });
      }
    ),
    http.post(
      formatUrl(
        apiUrls.dispatch.postAssignStopToRoute(
          ":sicCode" as unknown as number,
          ":stopId" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return new HttpResponse(null, { status: 200 });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getStopShipmentNotes(
          ":sicId" as unknown as number,
          ":id" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        const { id } = params;
        const shipmentId = Number(id);
        const scenario = getUrlParam("stopShipmentScenario");

        const size = mockDispatchStopShipmentNotes.default.length + 1;
        const index = shipmentId % size;

        const mockDispatchStopShipmentNote =
          mockDispatchStopShipmentNotes.default[index];

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (scenario === "noShipmentNotes" || !mockDispatchStopShipmentNote) {
          return new HttpResponse(null, { status: 204 });
        }

        return HttpResponse.json(mockDispatchStopShipmentNote, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getStopSuggestedSics(
          ":sicCode" as unknown as number,
          ":id" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockDispatchStopSuggestedSics.default, {
          status: 200,
        });
      }
    ),
    http.patch(
      formatUrl(
        apiUrls.dispatch.patchStopSic(
          ":sicCode" as unknown as number,
          ":id" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        return new HttpResponse(null, { status: 200 });
      }
    ),
    http.post(
      formatUrl(
        apiUrls.dispatch.postUpdateRouteStatus(
          ":sicId" as unknown as number,
          ":id" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        const scenario = getUrlParam("updateRouteStatusScenario");
        if (scenario === "error") {
          return new HttpResponse(null, {
            status: 500,
          });
        }
        const stop = mockDispatchStops.default.find(
          (stop) => stop.id === Number(params.id)
        );
        if (!stop) {
          return new HttpResponse(null, { status: 404 });
        }

        return new HttpResponse(null, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getAvailableRouteStatuses(
          ":sicId" as unknown as number,
          ":routeId" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);

        const route = mockDispatchRoutes.default.find(
          (route) => Number(params.routeId) === route.id
        );

        const routeStatuses = ((routeStatus: RouteStatus | undefined) => {
          switch (routeStatus) {
            case "DISP":
              return mockDispatchAvailableRouteStatuses.routeWithStatusDisp;
            case "CLDV":
              return mockDispatchAvailableRouteStatuses.routeWithStatusCldv;
            case "CLDK":
              return mockDispatchAvailableRouteStatuses.routeWithStatusCldk;
            default:
              return null;
          }
        })(route?.status);

        if (!routeStatuses) {
          return new HttpResponse(null, { status: 404 });
        }
        return HttpResponse.json(routeStatuses, {
          status: 200,
        });
      }
    ),
    http.post(
      formatUrl(
        apiUrls.dispatch.postRescheduleStop(
          ":sicCode" as unknown as number,
          ":stopId" as unknown as number
        )
      ),
      async () => {
        await delay(config.delay);
        const scenario = getUrlParam("rescheduleStopScenario");
        if (scenario === "error") {
          return new HttpResponse(null, {
            status: 500,
          });
        }
        return new HttpResponse(null, { status: 200 });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getNotifications(
          ":sicCode" as unknown as number,
          ":planDate"
        )
      ),
      async () => {
        await delay(config.delay);
        return HttpResponse.json(mockNotifications.default, {
          status: 200,
        });
      }
    ),
    http.get(
      formatUrl(
        apiUrls.dispatch.getShipmentDetails(
          ":sicCode" as unknown as number,
          ":shipmentId" as unknown as number
        )
      ),
      async ({ params }) => {
        await delay(config.delay);
        const { shipmentId } = params;
        const validIds = Object.keys(mockShipmentDetails);

        if (!shipmentId || validIds.indexOf(shipmentId as string) === -1) {
          return new HttpResponse(null, { status: 404 });
        }
        const shipment = mockShipmentDetails[shipmentId as string];
        return HttpResponse.json(shipment, { status: 200 });
      }
    ),

    // * Outbound Routes
    http.get(
      formatUrl(apiUrls.getFreightDetails(":sicCode" as unknown as string, [])),
      async () => {
        await delay(config.delay);

        if (config.scenario === "error") {
          return HttpResponse.json(
            {
              success: false,
              response: {
                errorMessage: "Plan not started today",
              },
            },
            {
              status: 400,
            }
          );
        } else {
          return HttpResponse.json(freightDetails.default, {
            status: 200,
          });
        }
      }
    ),

    // Loadboard V2 Routes

    http.get(formatUrl(apiUrls.loadBoard.daySummary), async () => {
      await delay(config.delay);
      return HttpResponse.json(mockDaySummary.default, { status: 200 });
    }),

    http.get(formatUrl(apiUrls.loadBoard.daySummaryV2(":date")), () =>
      HttpResponse.json(mockDaySummaryV2.default, { status: 200 })
    ),

    http.get(formatUrl(apiUrls.getRegionsWithSics), async () => {
      await delay(config.delay);
      return HttpResponse.json(mockRegions.default, { status: 200 });
    }),

    http.get(formatUrl(apiUrls.getEmployeeDrivers), async () => {
      await delay(config.delay);
      return HttpResponse.json(mockEmployeeDrivers.default, { status: 200 });
    }),

    http.get(formatUrl(apiUrls.loadBoard.tripsForReassign), () =>
      HttpResponse.json(mockTripsForReassign.default, { status: 200 })
    ),

    http.post(formatUrl(apiUrls.loadBoard.reassignShipments), () =>
      HttpResponse.json([12345], { status: 200 })
    ),

    http.post(formatUrl(apiUrls.loadBoard.createSchedulePair), () =>
      HttpResponse.json(null, { status: 200 })
    ),

    http.post(formatUrl(apiUrls.loadBoard.batchPublishSchedules), () =>
      HttpResponse.json([], { status: 200 })
    ),

    http.get(formatUrl(apiUrls.loadBoard.ucccPlanning(":day")), () =>
      HttpResponse.json(mockUcccPlanningShipments, { status: 200 })
    ),

    http.post(
      formatUrl(
        apiUrls.loadBoard.closeTrip(":tripNumber" as unknown as number)
      ),
      () => HttpResponse.json(null, { status: 200 })
    ),

    http.post(formatUrl(apiUrls.loadBoard.reinstateSchedulePair), () =>
      HttpResponse.json(null, { status: 200 })
    ),

    http.get(
      formatUrl(
        apiUrls.loadBoard.closedTripDetails(":tripNumber" as unknown as number)
      ),
      () => HttpResponse.json(mockedClosedTripDetails.default, { status: 200 })
    ),

    // Trip List
    // Linehaul Trips
    http.get(formatUrl(apiUrls.loadBoard.tripList(":day")), () =>
      HttpResponse.json(loadTrips, { status: 200 })
    ),

    http.get(formatUrl(apiUrls.getLinehaulTrailers), () =>
      HttpResponse.json(mockEquipment, { status: 200 })
    ),

    http.get(formatUrl(apiUrls.getLinehaulTrailers), () =>
      HttpResponse.json(trailers, { status: 200 })
    ),

    http.get(formatUrl(apiUrls.loadBoard.ucccNotifications), () =>
      HttpResponse.json(notifications)
    ),

    http.get(
      formatUrl(apiUrls.loadBoard.tripDetails(":tripId" as unknown as number)),
      () => HttpResponse.json(mockTripDetails.default)
    ),

    http.post(
      formatUrl(apiUrls.loadBoard.enrouteTrip(":tripId" as unknown as number)),
      () => HttpResponse.json(mockTripDetails.default)
    ),

    http.get(formatUrl(apiUrls.getCarrierScacCodes), () =>
      HttpResponse.json(mockScacCodes.default)
    ),

    http.get(formatUrl(apiUrls.loadBoard.delayReasons), () =>
      HttpResponse.json(mockDelayReasons.default)
    ),

    http.put(
      formatUrl(
        apiUrls.loadBoard.editTripClosedDetails(":tripId" as unknown as number)
      ),
      () => HttpResponse.json(mockTripDetails.default)
    ),

    http.patch(
      formatUrl(
        apiUrls.loadBoard.changeTripStatus(":tripId" as unknown as number)
      ),
      () => HttpResponse.json(mockTripDetails.default)
    ),

    http.get(formatUrl(apiUrls.getSicByCode(":sicCode")), () =>
      HttpResponse.json(mockSicDetails.default)
    ),

    http.post(formatUrl(apiUrls.addSicContact(":sicCode")), () =>
      HttpResponse.json(mockSicDetails.default)
    ),

    http.get(
      formatUrl(
        apiUrls.getContactBySicCodeAndId(
          ":sicCode",
          ":contactId" as unknown as number
        )
      ),
      () => HttpResponse.json(mockSicContact.default)
    ),

    http.delete(
      formatUrl(
        apiUrls.getContactBySicCodeAndId(
          ":sicCode",
          ":contactId" as unknown as number
        )
      ),
      () => HttpResponse.json(mockSicContact.default)
    ),
  ];
}

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { colors, TablePagination, Typography } from "@mui/material";
import { Shipment } from "../../../services/prePlanningService.types";
import { ShipmentWithSequence } from "../../../types/planning/shipmentSeq.type";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import { Checkbox } from "../Checkbox";
import Loading from "../layout/Loading";
import FeedbackIndicator from "../FeedbackIndicator";
import { FlexColumn } from "../layout/Flex";
import { pageSizes } from "../../../constants/table-page-size";
import TableCaption from "../TableCaption";
import { RowSelectionMode, SelectableProps } from "./Selectable.types";
import BaseTableHeader from "./BaseTableHeader";
import {
  SelectedTableRows,
  ShipmentWithId,
  useTableSelectionContext,
} from "./TableSelectionContext";
import BaseTableBody from "./BaseTableBody";

export type TableShipment = Shipment | ShipmentWithSequence;

type CommonProps<TData> = {
  data?: TableShipment[];
  columns: ColumnDef<TableShipment, any>[];
  isLoading?: boolean;
  parentRow: Row<TData>;
  isParentSelected: boolean;
  numberOfBills: number;
  showPlannedShipments?: boolean;
};

type DroppableProps = {
  isDraggable?: boolean;
};

type ContextMenuProps = {
  contextMenuActions?: (
    shipment?: SelectedTableRows<any>
  ) => ContextMenuAction[];
};

type SortableProps = {
  defaultSort?: SortingState;
};

type Props<TData> = CommonProps<TData> &
  ContextMenuProps &
  DroppableProps &
  SelectableProps<TableShipment> &
  SortableProps;

const renderNumShipmentsVisible = (
  numberOfVisible: number,
  numberOfBills: number
) => (
  <Typography
    marginBottom={1}
    marginTop={1}
    color={colors.grey[600]}
  >{`${numberOfVisible} of ${numberOfBills} shipments visible`}</Typography>
);

/** Abstracts all table business logic. */
export const ShipmentsTableBase = <TData,>({
  /** table shipment data */
  data,
  /** columns array */
  columns,
  /** parent row */
  parentRow,
  /** is parent selected? */
  isParentSelected,
  /** is row draggable? create a draggable row if true */
  isDraggable = false,
  /** define the mode of selection */
  rowSelectionMode = "unselectable",
  /** indicate if the data is loading */
  isLoading = false,
  /** context menu actions items */
  contextMenuActions,
  /** function to get the row id */
  getRowId,
  /** the number of bills (shipments) on this table */
  numberOfBills,
  /** default sort array */
  defaultSort = [],
  /** show planned shipments on the table when true */
  showPlannedShipments = true,
}: Props<TData>) => {
  const { selectedRows, handleSelect } =
    useTableSelectionContext<TableShipment>();
  const [sortBy, setSortBy] = useState<SortingState>(defaultSort);

  const caption = `Route ${parentRow.id} shipments`;

  const assembleColumns = useCallback(
    (
      columns: ColumnDef<TableShipment, any>[],
      rowSelectionMode: RowSelectionMode = "unselectable"
    ) => {
      let newColumns: ColumnDef<TableShipment, any>[] = [...columns];

      if (
        rowSelectionMode === "checkbox" ||
        rowSelectionMode === "checkbox-and-row-click"
      ) {
        newColumns = [
          {
            id: "select",
            header: ({ table }) =>
              (() => {
                const {
                  getIsAllRowsSelected,
                  getIsSomeRowsSelected,
                  getToggleAllRowsSelectedHandler,
                } = table;

                return Checkbox({
                  name: "selectAllRows",
                  checked: getIsAllRowsSelected(),
                  disabled: false,
                  indeterminate: getIsSomeRowsSelected(),
                  onChange:
                    table.getRowModel().rows.length > 0
                      ? getToggleAllRowsSelectedHandler()
                      : () => {},
                });
              })(),
            cell: ({ row }) =>
              (() => {
                const {
                  getIsSelected,
                  getCanSelect,
                  getIsSomeSelected,
                  getToggleSelectedHandler,
                } = row;

                return Checkbox({
                  name: "selectRow",
                  ariaLabel: `Shipment ${row.id}`,
                  checked: getIsSelected(),
                  disabled: !getCanSelect(),
                  indeterminate: getIsSomeSelected(),
                  onChange: getToggleSelectedHandler(),
                });
              })(),
          },
          ...newColumns,
        ];
      }

      return newColumns;
    },
    []
  );

  const selectedRowIdMap: RowSelectionState = {};
  for (const row of selectedRows.shipments) {
    if (row.selected && row.parentId === parentRow.id) {
      selectedRowIdMap[row.rowId] = true;
    }
  }

  const table = useReactTable<TableShipment>({
    data: data || [],
    columns: useMemo(
      () => assembleColumns(columns, rowSelectionMode),
      [assembleColumns, columns, rowSelectionMode]
    ),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId,
    state: {
      sorting: sortBy,
      rowSelection: selectedRowIdMap,
    },
    onSortingChange: setSortBy,
    enableRowSelection: rowSelectionMode !== "unselectable",
    enableMultiRowSelection: rowSelectionMode !== "unselectable",
    onRowSelectionChange: (updater) => {
      // This updater prop can be a value or a function, in this case we will use it as a function to return the new set of selected rows based on the old state.
      // Known troubleshoot https://github.com/TanStack/table/issues/4364#issuecomment-1240257555
      if (typeof updater === "function") {
        handleRowSelection(updater(selectedRowIdMap));
      }
    },
  });

  const tableRowModel = table.getRowModel();

  const handleChangePage = (_: unknown, newPage: number) =>
    newPage > table.getState().pagination.pageIndex
      ? table.nextPage()
      : table.previousPage();

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    table.setPageSize(Number(event.target.value));
    table.setPageIndex(0);
  };

  const handleRowSelection = useCallback(
    (selection: RowSelectionState) => {
      const rowIds: string[] = [];
      for (const row in tableRowModel.rowsById) {
        rowIds.push(row);
      }

      // If all rows are selected, select the parent.
      if (isParentSelected && Object.keys(selection).length === rowIds.length) {
        parentRow.toggleSelected(true);
      } else {
        parentRow.toggleSelected(false);
      }
      // If selected rows are different from the number of bills, select the parent
      if (Object.keys(selection).length === numberOfBills) {
        parentRow.toggleSelected(true);
      }

      const selectedRows: ShipmentWithId[] = [];
      Object.keys(selection).forEach((rowId) => {
        const row = tableRowModel.rows.find(
          (row) => row.id === rowId
        )?.original;
        if (row) {
          selectedRows.push({
            ...row,
            rowId,
            selected: true,
            parentId: parentRow.id,
          });
        }
      });

      handleSelect({
        selectedRows,
        type: "shipment",
        rowIds,
      });
    },
    [
      handleSelect,
      isParentSelected,
      numberOfBills,
      parentRow,
      tableRowModel.rows,
      tableRowModel.rowsById,
    ]
  );

  useEffect(
    function selectChildRowsOnInitialRenderIfParentIsSelected() {
      if (
        !isLoading &&
        tableRowModel.rows.length > 0 &&
        rowSelectionMode !== "unselectable"
      ) {
        if (isParentSelected || !table.getIsSomeRowsSelected()) {
          table.toggleAllRowsSelected(isParentSelected);
        }
      }
    },
    [
      isLoading,
      isParentSelected,
      rowSelectionMode,
      table,
      tableRowModel.rows.length,
    ]
  );

  useEffect(
    function defaultPaginationToAllRows() {
      if (data && data.length > 0) table.setPageSize(data.length);
    },
    [data, table]
  );

  if (isLoading) {
    return <Loading label={caption} />;
  }

  if (data && data.length === 0) {
    return (
      <>
        {!showPlannedShipments && renderNumShipmentsVisible(0, numberOfBills)}
        <TableContainer component={Paper}>
          <Table stickyHeader size="small">
            <TableCaption>{caption}</TableCaption>
            <BaseTableHeader table={table} />
          </Table>
        </TableContainer>
        <FeedbackIndicator />
      </>
    );
  }

  return (
    <FlexColumn>
      {!showPlannedShipments &&
        renderNumShipmentsVisible(data?.length ?? 0, numberOfBills)}
      <TableContainer component={Paper} sx={{ flexGrow: 1, maxHeight: "50vh" }}>
        <Table stickyHeader size="small">
          <TableCaption>{caption}</TableCaption>
          <BaseTableHeader table={table} />
          <BaseTableBody
            table={table}
            tableType="shipment"
            parentId={parentRow.id}
            isDraggable={isDraggable}
            rowSelectionMode={rowSelectionMode}
            contextMenuActions={contextMenuActions}
          />
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ width: "100%", overflow: "visible" }}
        rowsPerPageOptions={[
          ...pageSizes,
          { value: data?.length ?? 0, label: "All" },
        ]}
        component="div"
        count={data?.length ?? 0}
        rowsPerPage={table.getState().pagination.pageSize}
        page={table.getState().pagination.pageIndex}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </FlexColumn>
  );
};

// CSpell: ignore TPLN, CNCL
import { z } from "zod";

const inboundTrapSchema = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string(),
  type: z.enum(["Planned", "Physical"]),
  status: z.enum(["TPLN", "CLDK", "TRAP", "CNCL"]),
  bills: z.number(),
  pallets: z.number(),
  pieces: z.number(),
  weight: z.number(),
});

export type Trap = z.infer<typeof inboundTrapSchema>;

export const inboundTrapsSchema = inboundTrapSchema.array();

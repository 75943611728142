import { Box } from "@mui/material";
import { ContextMenuAction } from "../../../../types/contextMenuAction";
import StyledMenuButton from "./StyledMenuButton";

interface AssignActionButtonProps {
  mergeAction: ContextMenuAction;
}

const MergeActionButton = ({ mergeAction }: AssignActionButtonProps) => (
  <Box display="flex">
    <StyledMenuButton
      aria-label={mergeAction.label}
      startIcon={mergeAction.icon}
      onClick={mergeAction.handleClick}
    >
      Merge
    </StyledMenuButton>
  </Box>
);

export default MergeActionButton;

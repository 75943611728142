import { Box, capitalize, IconButton, styled, Typography } from "@mui/material";
import { Close, DoorSliding, Info, InfoOutlined } from "@mui/icons-material";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";
import { Drawer } from "../../../../components/shared/Drawer";
import { InboundTrailerIcon } from "../../shared/InboundTrailerIcon";
import SectionTitle from "../../prePlanning/tables/SectionTitle";
import StatusTag from "../../../../components/shared/StatusTag/StatusTag";
import { formatRouteStartEndTimeTableField } from "../../../../utils/tableFormat";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { dashIfEmpty } from "../../../../constants/utils";

const TopSidebar = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1.5rem 1rem",
  flexDirection: "column",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "0.5rem",
});

const HeaderTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  color: theme.palette.grey[600],
  fontSize: "1rem",
}));

const Title = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "0.5rem",
});

const Statuses = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  color: theme.palette.grey[600],
}));

const Content = styled(Box)({
  display: "flex",
  padding: "1.5rem 1rem",
  flexDirection: "column",
});

const Information = styled(Box)(({ theme }) => ({
  padding: "1rem",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "4px",
}));

const InformationHeader = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginBottom: "0.750rem",
}));

const InformationContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: "0.5rem",
  paddingBottom: "0.750rem",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const InformationItem = styled(Box)({
  width: "100%",
});

const InformationTitle = styled(Box)(({ theme }) => ({
  fontSize: "0.750rem",
  color: theme.palette.grey[600],
}));

const InformationDescription = styled(Box)({
  fontSize: "0.875rem",
  fontWeight: 700,
  marginTop: "0.250rem",
});

type RouteOrTrapInfoBarProps = {
  routeOrTrap: PlanTableFormat;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export const RouteOrTrapInfoBar = ({
  routeOrTrap,
  isOpen,
  setIsOpen,
}: RouteOrTrapInfoBarProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const type: "route" | "trap" =
    routeOrTrap.type === "delivery_route" ? "route" : "trap";
  return (
    <Drawer anchor="right" open={isOpen} width={420}>
      <TopSidebar>
        <Header>
          <HeaderTitle>
            <InfoOutlined sx={{ marginRight: "0.5rem" }} />
            <Typography>{capitalize(type)} Details</Typography>
          </HeaderTitle>
          <IconButton
            aria-label={`Close ${type} information`}
            title={`Close ${type} information`}
            onClick={() => setIsOpen(false)}
            size="small"
          >
            <Close fontSize="small" />
          </IconButton>
        </Header>
        <Title>
          <Box display="flex" alignItems="center">
            <InboundTrailerIcon
              isLockedRoute={Boolean(routeOrTrap.isCommitted)}
              icon={type}
              sx={{ marginRight: "0.5rem" }}
            />
            <SectionTitle
              page={routeOrTrap.name}
              showToggles={false}
              containerStyles={{ margin: 0 }}
            />
          </Box>
          <StatusTag label={routeOrTrap.status} isPlannedFor="today" />
        </Title>
        {(routeOrTrap.startTime || routeOrTrap.door > 0) && (
          <Statuses>
            {routeOrTrap.startTime && (
              <Box display="flex" alignItems="center">
                <Info />
                <Typography marginLeft="0.5rem">
                  {formatRouteStartEndTimeTableField(
                    routeOrTrap.startTime,
                    serviceCenter.timeZone
                  )}
                </Typography>
              </Box>
            )}
            {routeOrTrap.door > 0 && (
              <Box display="flex" alignItems="center">
                <DoorSliding />
                <Typography marginLeft="0.5rem">{routeOrTrap.door}</Typography>
              </Box>
            )}
          </Statuses>
        )}
      </TopSidebar>
      <Content>
        <Information>
          <InformationHeader>{capitalize(type)} Information</InformationHeader>
          <InformationContent>
            <InformationItem>
              <InformationTitle>Zone</InformationTitle>
              <InformationDescription>
                {dashIfEmpty(routeOrTrap.zones.join(","))}
              </InformationDescription>
            </InformationItem>
            <InformationItem>
              <InformationTitle>Driver</InformationTitle>
              <InformationDescription>
                {dashIfEmpty(routeOrTrap.driver?.name)}
              </InformationDescription>
            </InformationItem>
          </InformationContent>
          <InformationHeader sx={{ marginTop: "0.5rem" }}>
            Trailer
          </InformationHeader>
          <InformationContent sx={{ border: "none", padding: 0 }}>
            <InformationItem>
              <InformationTitle>Trailer/ST #</InformationTitle>
              <InformationDescription>
                {dashIfEmpty(routeOrTrap.trailer?.name)}
              </InformationDescription>
            </InformationItem>
            <InformationItem>
              <InformationTitle>Tractor #</InformationTitle>
              <InformationDescription>
                {dashIfEmpty(routeOrTrap.tractor?.name)}
              </InformationDescription>
            </InformationItem>
          </InformationContent>
        </Information>
      </Content>
    </Drawer>
  );
};

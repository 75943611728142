import { Box, Button, Grid } from "@mui/material";
import { AppTheme } from "../../theme";
import { Tag as TagType } from "../../types/freight/tag.type";
import { tagDefaultColor } from "../../constants/TagDefaultColor";
import { sortShipmentTags } from "../../utils/shipmentTags";
import { defaultMaxOverlappedItems } from "./tagsGridConstants";
import { Tag } from "./Tag";
import { Tooltip } from "./Tooltip";

type OverlappedTagsPopupProps = {
  tags: TagType[];
};

const OverlappedTagsPopup = ({ tags }: OverlappedTagsPopupProps) => (
  <Box sx={{ cursor: "pointer", marginLeft: "0.25rem" }}>
    <Tooltip
      content={
        <Box paddingBlock={0.5}>
          {tags
            .filter((tag) => tag.name)
            .map((tag) => (
              <Box key={tag.name} sx={{ display: "inline-block", margin: 0.5 }}>
                <Tag
                  color="white"
                  label={tag.name}
                  backgroundColor={tag.colorHex || tagDefaultColor}
                  borderRadius={4}
                />
              </Box>
            ))}
        </Box>
      }
    >
      <Button
        aria-label="Show additional tags"
        sx={{
          minWidth: "unset",
          padding: 0,
          color: AppTheme.palette.primary.main,
          textDecoration: "underline",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        +{tags.length}
      </Button>
    </Tooltip>
  </Box>
);

type TagsGridProps = { tags?: TagType[]; maxOverlappedItems?: number };

export const TagsGrid = ({
  tags = [],
  maxOverlappedItems = defaultMaxOverlappedItems,
}: TagsGridProps) => {
  const sortedTags = sortShipmentTags(tags);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Grid
        sx={{
          display: "flex",
        }}
      >
        {sortedTags
          .slice(0, maxOverlappedItems)
          .filter((tag) => tag.name)
          .map((tag) => (
            <Box key={tag.name} paddingInlineEnd={0.5}>
              <Tag
                color="white"
                label={tag.name}
                backgroundColor={tag.colorHex || tagDefaultColor}
                borderRadius={4}
              />
            </Box>
          ))}
        {sortedTags.length > maxOverlappedItems && (
          <OverlappedTagsPopup
            tags={sortedTags.slice(maxOverlappedItems, sortedTags.length)}
          />
        )}
      </Grid>
    </Box>
  );
};

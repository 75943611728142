import dayjs from "dayjs";
import { LinehaulTrailers } from "../../hooks/react-query/outbound/linehaulTrailers/useLinehaulTrailers";

export const linehaulTrailersResponse = {
  empty: {
    summary: {
      bills: 0,
      pieces: 0,
      pallets: 0,
      weight: 0,
      trailers: 0,
    },
    trailers: [],
  },
  default: {
    summary: {
      bills: 7,
      pieces: 89,
      pallets: 14,
      weight: 8001,
      trailers: 1,
    },
    trailers: [
      {
        tripNumber: 3001249,
        trailerNumber: "DYLT161615",
        etaDate: dayjs("2024-03-18"),
        etaTime: "00:52",
        etaTimeStamp: dayjs("2024-03-01T00:00:00"),
        serviceDue: new Date("2024-03-01T00:00:00"),
        originSic: "ONT",
        destinationSic: "DAL",
        bills: 7,
        pieces: 89,
        pallets: 14,
        weight: 8001,
        shipmentTags: [
          {
            id: 81,
            name: "VC",
            color: "#AA7EA0",
            description: "Vendor Compliance",
          },
          {
            id: 5,
            name: "APTM",
            color: "#69ADA8",
            description: "Appointment Made",
          },
          {
            id: 9,
            name: "DTCL",
            color: "#5E9FE0",
            description: "Delivery to Connecting Line",
          },
        ],
        status: "CPLN",
        doorNumber: null,
        workerName: null,
        isPlanned: true,
      },
    ],
  },
} as const satisfies Record<string, LinehaulTrailers>;

import {
  Box,
  Button,
  DialogTitle,
  DialogActions,
  IconButton,
  FormControl,
  SelectChangeEvent,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  FormHelperText,
  Autocomplete,
  DialogContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { ChangeEvent, useState } from "react";
import { Close, DoorSliding } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { TimePicker } from "../../../components/shared/TimePicker";
import { useDrivers } from "../../../hooks/react-query/useDrivers";
import { useEquipmentBySicIdAndPlanDateOld } from "../../../hooks/react-query/useEquipments";
import { useDoorsBySicIdAndPlanDate } from "../../../hooks/react-query/useDoors";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { SelectedEquipmentType } from "../../../types/equipment.type";
import { routeTrapType } from "../../../constants/RouteTrapTypes";
import { usePlanStartDate } from "../../../hooks/usePlanStartDate";
import {
  doorAvailability,
  getDoorOptionsFromDoors,
} from "../shared/doorNumberUtilsOld";
import { AutocompleteAvailabilityOptionOld } from "../../../components/shared/AutocompleteAvailabilityOptionOld";
import { getDriverOptionsOld, driverAvailability } from "../shared/driverUtils";
import {
  getStraightTruckOptions,
  getTractorOptions,
  getTrailerOptions,
  tractorAvailability,
  trailerAvailability,
  straightTruckAvailability,
} from "../shared/equipmentUtilsOld";
import Loading from "../../../components/shared/layout/Loading";
import { planningStatus } from "../../../types/status.type";
import { PlanTableFormat } from "../../../types/planning/plan.type";

type ValidationErrors = {
  presetRoute?: string;
  routeName?: string;
  startTime?: string;
};

type EditedPlanOld = Pick<
  PlanTableFormat,
  "id" | "routeEnd" | "name" | "startTime" | "endTime"
> & {
  driverId: number | null;
  doorId: number | null;
  trailerEquipmentId: number | null;
  tractorEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
};

type FormHelperText = Record<keyof EditedPlanOld, string>;

interface EditRouteConfirmDialogOldProps {
  plan: PlanTableFormat;
  open: boolean;
  savePlan: (editedPlanOld: EditedPlanOld) => void;
  cancelHandler: () => void;
}

function mapEditedPlanOld(plan: PlanTableFormat): EditedPlanOld {
  return {
    driverId: plan.driver?.id ?? 0,
    endTime: plan.endTime,
    id: plan.id,
    name: plan.name,
    routeEnd: plan.routeEnd ?? "",
    startTime: plan.startTime,
    doorId: plan.door,
    trailerEquipmentId: plan.trailer?.id ?? null,
    tractorEquipmentId: plan.tractor?.id ?? null,
    straightTruckEquipmentId: plan.straightTruck?.id ?? null,
  };
}

type PlanningStatusArray = Array<keyof typeof planningStatus>;

const blockSelectingAssignedTrailerOrStraightTruckStatuses: string[] = [
  planningStatus.CLDK,
  planningStatus.CLDV,
  planningStatus.TRAP,
  planningStatus.DISP,
] satisfies PlanningStatusArray;

const blockSelectingAssignedTractorStatuses: string[] = [
  planningStatus.CLDK,
  planningStatus.CLDV,
  planningStatus.DISP,
] satisfies PlanningStatusArray;

const blockSelectingAssignedDoorStatuses: string[] = [
  planningStatus.CLDK,
  planningStatus.CLDV,
  planningStatus.DISP,
] satisfies PlanningStatusArray;

const blockSelectingAssignedDriverStatuses: string[] = [
  planningStatus.DISP,
] satisfies PlanningStatusArray;

export const EditRouteConfirmDialogOld = ({
  plan,
  open,
  savePlan,
  cancelHandler,
}: EditRouteConfirmDialogOldProps) => {
  const initialSelectedEquipmentType = (): SelectedEquipmentType | null => {
    if (plan.tractor || plan.trailer) {
      return "Trailer";
    } else if (plan.straightTruck) {
      return "Truck";
    } else {
      return null;
    }
  };
  const [selectedEquipmentType, setSelectedEquipmentType] =
    useState<SelectedEquipmentType | null>(initialSelectedEquipmentType);
  const [formValidationErrors, setFormValidationErrors] =
    useState<ValidationErrors>({});
  const [formHelperText, setFormHelperText] = useState<Partial<FormHelperText>>(
    {}
  );

  const [serviceCenter] = useSelectedServiceCenter();
  const { data: doors = [], isLoading: isLoadingDoors } =
    useDoorsBySicIdAndPlanDate(serviceCenter.id, plan.date);
  const doorOptions = getDoorOptionsFromDoors(doors);

  const { data: availableDrivers = [], isLoading: isLoadingDrivers } =
    useDrivers({
      isAvailable: true,
      serviceCenterId: serviceCenter.id,
    });
  const driverOptions = getDriverOptionsOld(availableDrivers);

  const [editedPlanOld, setEditedPlanOld] = useState<EditedPlanOld>(
    mapEditedPlanOld(plan)
  );
  const originalPlan: EditedPlanOld = mapEditedPlanOld(plan);

  const { data: equipments, isLoading: isLoadingEquipments } =
    useEquipmentBySicIdAndPlanDateOld(serviceCenter.id, plan.date);
  const trailerOptions = getTrailerOptions(equipments?.trailers ?? []);
  const tractorOptions = getTractorOptions(equipments?.tractors ?? []);
  const straightTruckOptions = getStraightTruckOptions(
    equipments?.straightTrucks ?? []
  );

  const handleHelperText = (
    value: string | number,
    fieldName: keyof EditedPlanOld
  ) => {
    const updateHelperText = () =>
      setFormHelperText((prev) => ({
        ...prev,
        [fieldName]: `Editing this ${routeTrapType[plan.type]} will change ${plan.name} status from ${plan.status} to CLDK`,
      }));

    const clearHelperText = () =>
      setFormHelperText((prev) => ({
        ...prev,
        [fieldName]: "",
      }));

    const fieldNotAvailableForMassageChanged =
      !plan.isAvailableForMassage &&
      value.toString() !== originalPlan[fieldName]?.toString() &&
      fieldName !== "name";

    /**
     * Validate if the field name is different from name this field won't change the route/trap status.
     */
    if (fieldNotAvailableForMassageChanged) {
      updateHelperText();
    } else {
      clearHelperText();
    }
  };

  function handleChange(
    e:
      | SelectChangeEvent<string>
      | SelectChangeEvent<unknown>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { value, name } = e.target;

    setEditedPlanOld((prev) => ({
      ...prev,
      [name]: value,
    }));

    handleHelperText(value as string, name as keyof EditedPlanOld);

    /// Validate route name or start time
    setFormValidationErrors((prev) => ({
      ...prev,
      routeName:
        name === "name" && !value ? "Route Name is required" : undefined,
      startTime:
        name === "startTime" && !value ? "Start Time is required" : undefined,
    }));
  }

  // If the user selects the active toggle button again, the active toggle button will be deselected
  const handleSelectEquipmentType = (type: SelectedEquipmentType | null) => {
    setSelectedEquipmentType(type === selectedEquipmentType ? null : type);
  };

  const validateForm = () => {
    const validationErrors: ValidationErrors = {};
    if (editedPlanOld.name === "") {
      validationErrors.routeName = "Route Name is required";
    }
    if (editedPlanOld.startTime === null && plan.type === "delivery_route") {
      validationErrors.startTime = "Start Time is required";
    }
    setFormValidationErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const planStartDate = usePlanStartDate({
    startTime: editedPlanOld.startTime?.toISOString() || null,
    planDate: plan.date,
    timeZone: serviceCenter.timeZone,
    // Need to display the same value regardless of the timezone
    // ex. Sic: DAL Time: 8:00-5:00. We want to show it 8AM even if the user is in LA (in LA 1pm UTC is 6am)
    keepLocalTime: false,
  });

  if (isLoadingEquipments || isLoadingDrivers || isLoadingDoors) {
    return (
      <Dialog open>
        <DialogContent>
          <DialogTitle>Loading...</DialogTitle>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={cancelHandler} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", padding: "20px 0px 10px 20px" }}>
        {`Edit "${plan.name}" ${
          plan.type === "delivery_route" ? "Route" : "Trap"
        }`}
      </DialogTitle>
      <Box position="absolute" top={10} right={10}>
        <IconButton onClick={cancelHandler}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ margin: "1px 1px 1px 20px" }}>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          {`${plan.type === "delivery_route" ? "Route" : "Trap"} Information`}
        </Typography>
      </Box>
      <Box sx={{ margin: "10px 20px 20px 20px" }}>
        <TextField
          id="outlined-basic"
          name="name"
          defaultValue={editedPlanOld.name}
          variant="outlined"
          onChange={handleChange}
          sx={{ width: "100%" }}
          label={
            plan.type === "delivery_route" ? "Route Name *" : "Trap Name *"
          }
          error={!!formValidationErrors.routeName}
          helperText={formValidationErrors.routeName ?? formHelperText.name}
        />
      </Box>
      <Box sx={{ margin: "1px 1px 1px 20px" }}>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          {`${plan.type === "delivery_route" ? "Route" : "Trap"} Details`}
        </Typography>
      </Box>
      {plan.type === "delivery_route" && (
        <Box
          sx={{ margin: "1px 20px 20px 20px" }}
          display="flex"
          flexDirection="row"
        >
          <TimePicker
            label="Start Time *"
            value={editedPlanOld.startTime ? planStartDate : null}
            onChange={(val) => {
              if (!plan.canEditStartTime) {
                handleHelperText(val?.toDate().toString() ?? "", "startTime");
              }
              setEditedPlanOld((prev) => ({
                ...prev,
                startTime: val?.toDate() ?? null,
              }));
            }}
            slotProps={{
              textField: {
                sx: { width: "100%" },
                helperText:
                  formValidationErrors.startTime ?? formHelperText.startTime,
                error: !!formValidationErrors.startTime,
              },
            }}
          />
        </Box>
      )}
      <Box sx={{ margin: "1px 20px 20px 20px" }}>
        <FormControl fullWidth>
          <Autocomplete
            value={
              doorOptions.find(
                (d) => Number(d.value) === editedPlanOld.doorId
              ) ?? null
            }
            options={doorOptions}
            onChange={(_, newValue) => {
              setEditedPlanOld((prev) => ({
                ...prev,
                doorId: newValue?.value ?? null,
              }));
            }}
            getOptionLabel={(door) => door.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Door"
                label="Door Assignment"
              />
            )}
            groupBy={(option) => option.group}
            getOptionDisabled={(option) =>
              blockSelectingAssignedDoorStatuses.includes(plan.status) &&
              option.group === doorAvailability.ASSIGNED
            }
            renderOption={(props, option) => (
              <AutocompleteAvailabilityOptionOld
                key={option.name}
                listItemProps={props}
                name={option.name}
                inUseByName={option.inUseBy?.name}
                inUseByType={option.inUseBy?.type}
                leftIcon={<DoorSliding />}
              />
            )}
          />
          <FormHelperText>{formHelperText.doorId}</FormHelperText>
        </FormControl>
      </Box>
      {plan.type === "delivery_route" && (
        <Box sx={{ margin: "1px 20px 20px 20px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                driverOptions.find(
                  (d) => Number(d.value) === editedPlanOld.driverId
                ) ?? null
              }
              options={driverOptions}
              onChange={(_, newValue) => {
                setEditedPlanOld((prev) => ({
                  ...prev,
                  driverId: newValue?.value ?? null,
                }));
              }}
              getOptionLabel={(driver) => driver.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Driver"
                  label="Driver"
                />
              )}
              groupBy={(option) => option.group}
              getOptionDisabled={(option) =>
                blockSelectingAssignedDriverStatuses.includes(plan.status) &&
                option.group === driverAvailability.ASSIGNED
              }
              renderOption={(props, option) => (
                <AutocompleteAvailabilityOptionOld
                  key={`${option.name}-${option.value}`}
                  listItemProps={props}
                  name={option.name}
                />
              )}
            />
            <FormHelperText>{formHelperText.driverId}</FormHelperText>
          </FormControl>
        </Box>
      )}
      <Box sx={{ margin: "1px 10px 1px 20px" }}>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          Select an equipment type
        </Typography>
      </Box>
      <Box sx={{ margin: "10px 20px 20px 20px" }}>
        <ToggleButtonGroup
          color="primary"
          value={selectedEquipmentType}
          exclusive
          aria-label="Platform"
          size="small"
        >
          <ToggleButton
            value="Trailer"
            onChange={() => handleSelectEquipmentType("Trailer")}
          >
            Trailer
          </ToggleButton>
          <ToggleButton
            value="Truck"
            onChange={() => handleSelectEquipmentType("Truck")}
          >
            Straight Truck
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <div role="tabpanel" hidden={selectedEquipmentType !== "Trailer"}>
        <Box sx={{ margin: "10px 20px 20px 20px" }}>
          <FormControl sx={{ width: "100%" }} size="small">
            <Autocomplete
              value={
                trailerOptions.find(
                  (d) => Number(d.value) === editedPlanOld.trailerEquipmentId
                ) ?? null
              }
              options={trailerOptions}
              onChange={(_, newValue) => {
                setEditedPlanOld((prev) => ({
                  ...prev,
                  trailerEquipmentId: newValue?.value ?? null,
                }));
              }}
              getOptionLabel={(trailer) => trailer.name}
              renderInput={(params) => (
                <TextField {...params} label="Trailer #" />
              )}
              groupBy={(option) => option.group}
              getOptionDisabled={(option) =>
                blockSelectingAssignedTrailerOrStraightTruckStatuses.includes(
                  plan.status
                ) && option.group === trailerAvailability.ASSIGNED
              }
              renderOption={(props, option) => (
                <AutocompleteAvailabilityOptionOld
                  key={option.name}
                  listItemProps={props}
                  name={option.name}
                  inUseByName={option.inUseBy?.name}
                  inUseByType={option.inUseBy?.type}
                />
              )}
            />
            <FormHelperText>{formHelperText.trailerEquipmentId}</FormHelperText>
          </FormControl>
          &nbsp;
          {plan.type === "delivery_route" && (
            <FormControl sx={{ width: "100%" }} size="small">
              <Autocomplete
                value={
                  tractorOptions.find(
                    (d) => Number(d.value) === editedPlanOld.tractorEquipmentId
                  ) ?? null
                }
                options={tractorOptions}
                onChange={(_, newValue) => {
                  setEditedPlanOld((prev) => ({
                    ...prev,
                    tractorEquipmentId: newValue?.value ?? null,
                  }));
                }}
                getOptionLabel={(tractor) => tractor.name}
                renderInput={(params) => (
                  <TextField {...params} label="Tractor #" />
                )}
                groupBy={(option) => option.group}
                getOptionDisabled={(option) =>
                  blockSelectingAssignedTractorStatuses.includes(plan.status) &&
                  option.group === tractorAvailability.ASSIGNED
                }
                renderOption={(props, option) => (
                  <AutocompleteAvailabilityOptionOld
                    key={option.name}
                    listItemProps={props}
                    name={option.name}
                    inUseByName={option.inUseBy?.name}
                    inUseByType={option.inUseBy?.type}
                  />
                )}
              />
              <FormHelperText>
                {formHelperText.tractorEquipmentId}
              </FormHelperText>
            </FormControl>
          )}
        </Box>
      </div>
      <div role="tabpanel" hidden={selectedEquipmentType !== "Truck"}>
        <Box sx={{ margin: "10px 20px 20px 20px" }}>
          <FormControl sx={{ width: "100%" }} size="small">
            <Autocomplete
              value={
                straightTruckOptions.find(
                  (d) =>
                    Number(d.value) === editedPlanOld.straightTruckEquipmentId
                ) ?? null
              }
              options={straightTruckOptions}
              onChange={(_, newValue) => {
                setEditedPlanOld((prev) => ({
                  ...prev,
                  straightTruckEquipmentId: newValue?.value ?? null,
                }));
              }}
              getOptionLabel={(straightTruck) => straightTruck.name}
              renderInput={(params) => <TextField {...params} label="ST #" />}
              groupBy={(option) => option.group}
              getOptionDisabled={(option) =>
                blockSelectingAssignedTrailerOrStraightTruckStatuses.includes(
                  plan.status
                ) && option.group === straightTruckAvailability.ASSIGNED
              }
              renderOption={(props, option) => (
                <AutocompleteAvailabilityOptionOld
                  key={option.name}
                  listItemProps={props}
                  name={option.name}
                  inUseByName={option.inUseBy?.name}
                  inUseByType={option.inUseBy?.type}
                />
              )}
            />
            <FormHelperText>
              {formHelperText.straightTruckEquipmentId}
            </FormHelperText>
          </FormControl>
        </Box>
      </div>
      <DialogActions sx={{ margin: "5px 20px 20px 20px" }}>
        <Button onClick={cancelHandler} variant="text">
          Cancel
        </Button>
        <Button
          onClick={() => {
            // Per our business rules, when Trailer and Truck toggle button are not selected, we will send trailerEquipmentId = null,
            // straightTruckEquipmentId = null, tractorEquipmentId = null to backend API to clear them in DB.
            if (validateForm()) {
              if (selectedEquipmentType === null) {
                setEditedPlanOld({
                  ...editedPlanOld,
                  straightTruckEquipmentId: null,
                  tractorEquipmentId: null,
                  trailerEquipmentId: null,
                });
              } else if (selectedEquipmentType === "Truck") {
                setEditedPlanOld({
                  ...editedPlanOld,
                  tractorEquipmentId: null,
                  trailerEquipmentId: null,
                });
              } else {
                setEditedPlanOld({
                  ...editedPlanOld,
                  straightTruckEquipmentId: null,
                });
              }
              savePlan(editedPlanOld);
            }
          }}
          color="secondary"
          variant="contained"
          sx={{ width: "80px" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import dayjs from "dayjs";
import { Trap } from "../../../types/planning/trap.type";
import { Trip } from "../../../types/trip.type";

import { Shipment as FreightShipment } from "../../../types/freight/shipment.type";
import {
  Shipment as PrePlanningShipment,
  Shipment,
} from "../../../services/prePlanningService.types";
import { SelectedTableRows } from "../../../components/shared/old-table/TableSelectionContext";

export const mockSelectedTrips: SelectedTableRows<Trip> = {
  shipments: [],
  rows: [
    {
      rowId: "1294581",
      selected: true,
      shipmentIds: [
        129926270, 129927129, 129927142, 129928148, 129929103, 129929447,
        129929959, 129930942, 129931114, 129931612, 129931886, 129934022,
        129934092, 129935437, 129935781, 129935956, 129936939, 129937044,
        129937069, 129937347, 129938126, 129938429, 129939791, 129940234,
        129940237, 129940240,
      ],
      shipmentsCount: 26,
      shipmentsPallets: 34,
      shipmentsPieces: 29,
      shipmentsWeight: 25677,
      tripNumber: 1294581,
      trailerNumber: "DYLT170397",
      originSic: "EWR",
      destinationSic: "LAX",
      status: "UNLD",
      etaTimestampUtc: new Date("2023-10-09T08:55:00.000Z"),
      actualArrivalTime: new Date("2023-10-09T01:17:21.000Z"),
      dueServiceTimestampUtc: new Date("2023-10-10T00:00:00.000Z"),
      shipmentsTags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 75,
          name: "OVL",
          description: "Overlength",
          colorHex: "#C77563",
          position: 70,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 26,
      pallets: 34,
      pieces: 29,
      weight: 25677,
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 75,
          name: "OVL",
          description: "Overlength",
          colorHex: "#C77563",
          position: 70,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      serviceDueDate: new Date("2023-10-10T00:00:00.000Z"),
      eta: new Date("2023-10-09T08:55:00.000Z"),
      originSicCode: "EWR",
      destinationSicCode: "LAX",
      allFuturePlanDates: [20240417, 20240418, 20240419],
    },
  ],
};

export const mockSelectedTraps: SelectedTableRows<Trap> = {
  shipments: [],
  rows: [
    {
      rowId: "1000078",
      selected: true,
      shipmentIds: [
        129859253, 129893778, 129904300, 129910931, 129910934, 129911270,
        129913021, 129913319, 129917258, 129918820, 129918879, 129920023,
        129922116, 129922119, 129922401, 129924001, 129927279, 129929767,
        129934400, 129936366, 129938960, 129940369,
      ],
      shipmentsCount: 22,
      shipmentsPallets: 24,
      shipmentsPieces: 20,
      shipmentsWeight: 10721,
      shipmentsWeightUnit: "lbs",
      id: 1000078,
      title: "TRAP-1000078",
      notes: "APPT/TRAP - STR13",
      name: "R-LAXSTR13",
      doorId: 74,
      trailerNumber: "APPT/TRAP - STR13",
      appointmentDate: new Date("2023-10-12T17:00:00.000Z"),
      serviceDueDate: new Date("2023-09-29T00:00:00.000Z"),
      allFuturePlanDates: [20240417, 20240418, 20240419],
      shipmentsTags: [
        {
          id: 81,
          name: "VC",
          description: "Vendor Compliance",
          colorHex: "#AA7EA0",
          position: 30,
        },
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 75,
          name: "OVL",
          description: "Overlength",
          colorHex: "#C77563",
          position: 70,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 5,
          name: "APTM",
          description: "Appointment Made",
          colorHex: "#69ADA8",
          position: 100,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 78,
          name: "$$$",
          description: "Driver Collect",
          colorHex: "#91A786",
          position: 130,
        },
      ],
      status: "TRAP",
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 22,
      pallets: 24,
      pieces: 20,
      weight: 10721,
      tags: [
        {
          id: 81,
          name: "VC",
          description: "Vendor Compliance",
          colorHex: "#AA7EA0",
          position: 30,
        },
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 75,
          name: "OVL",
          description: "Overlength",
          colorHex: "#C77563",
          position: 70,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 5,
          name: "APTM",
          description: "Appointment Made",
          colorHex: "#69ADA8",
          position: 100,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 78,
          name: "$$$",
          description: "Driver Collect",
          colorHex: "#91A786",
          position: 130,
        },
      ],
      straightTruck: null,
      trailer: null,
    },
  ],
};

export const mockSelectedShuttleTrips: SelectedTableRows<Trip> = {
  shipments: [],
  rows: [
    {
      rowId: "1295726",
      selected: true,
      shipmentIds: [
        129926711, 129927402, 129934156, 129934681, 129935169, 129938162,
        129938482, 129940884, 129940940, 129941583, 129943209, 129943382,
        129943876, 129944338, 129944937, 129945009, 129945905, 129948683,
        129949121,
      ],
      shipmentsCount: 19,
      shipmentsPallets: 26,
      shipmentsPieces: 21,
      shipmentsWeight: 17951,
      tripNumber: 1295726,
      trailerNumber: "DYLT195047",
      originSic: "ONT",
      destinationSic: "LAX",
      status: "ARIV",
      etaTimestampUtc: new Date("2023-10-09T21:42:00.000Z"),
      actualArrivalTime: new Date("0001-01-01T00:00:00.000Z"),
      dueServiceTimestampUtc: new Date("2023-10-10T00:00:00.000Z"),
      allFuturePlanDates: [20240417, 20240418, 20240419],
      shipmentsTags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 75,
          name: "OVL",
          description: "Overlength",
          colorHex: "#C77563",
          position: 70,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 19,
      pallets: 26,
      pieces: 21,
      weight: 17951,
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 75,
          name: "OVL",
          description: "Overlength",
          colorHex: "#C77563",
          position: 70,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      serviceDueDate: new Date("2023-10-10T00:00:00.000Z"),
      eta: new Date("2023-10-09T21:42:00.000Z"),
      originSicCode: "ONT",
      destinationSicCode: "LAX",
    },
  ],
};

type FodShipment = FreightShipment | PrePlanningShipment;
export const mockFodShipments = {
  empty: [],
  default: [
    {
      id: 1,
      tripNumber: null,
      pallets: 2,
      pieces: 16,
      handlingUnits: "2",
      weight: 1294,
      weightUnit: "lbs",
      originSicId: 168,
      destinationSicId: 111,
      appointmentRequired: false,
      appointmentMade: false,
      appointmentStartTimestampUTC: new Date(),
      appointmentEndTimestampUTC: new Date(),
      dueServiceTimestampUTC: new Date(),
      proNumber: "151881968",
      destinationSicCode: "ONT",
      status: "UNLD",
      presetRouteId: 200,
      presetRouteName: "ALL FREIGHT",
      shipper: {
        id: 3983060,
        name: "KROWNE METAL MFG",
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      consignee: {
        id: 7080040,
        name: "RESTAURANT DEPOT",
        address: "6655 CRESCENT DR",
        city: "PHELAN",
        state: "CA",
        zipCode: "92371",
      },
      isPlanned: false,
      futurePlanDates: [],
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
      ],
      appointmentDate: new Date(),
      appointmentEndDate: new Date(),
      destinationServiceCenterCode: "ONT",
      destinationSic: { code: "ONT", id: 111 },
      etaDate: new Date(),
      endAppointmentTimestampUTC: "2024-03-26T21:00:00-07:00",
      etaTimestampUtc: new Date(),
      finalDestination: "",
      isManualAssign: false,
      loadToDestination: "",
      location: "",
      originServiceCenterCode: "ONT",
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      shipmentLoadType: "",
      sic: "ONT",
      statusDetail: "",
      statusId: 1,
      statusShipmentHistoryList: null,
      appointmentTime: 0,
      assignment: "",
      equipment: null,
      lat: 0,
      lng: 0,
      routeId: null,
      stopSeq: null,
      isAvailableToReconcile: true,
    },
    {
      id: 2,
      tripNumber: null,
      pallets: 2,
      pieces: 16,
      handlingUnits: "2",
      weight: 1294,
      weightUnit: "lbs",
      originSicId: 168,
      destinationSicId: 111,
      appointmentRequired: false,
      appointmentMade: false,
      appointmentStartTimestampUTC: new Date(),
      appointmentEndTimestampUTC: new Date(),
      dueServiceTimestampUTC: new Date(),
      proNumber: "151881967",
      destinationSicCode: "ONT",
      status: "UNLD",
      presetRouteId: 200,
      presetRouteName: "ALL FREIGHT",
      shipper: {
        id: 3983060,
        name: "ENLIGHTEN INC",
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      consignee: {
        id: 7080040,
        name: "DAYLIGHT ATM",
        address: "3185 FAYETTEVILLE ROAD SE",
        city: "PHELAN",
        state: "CA",
        zipCode: "92371",
      },
      isPlanned: false,
      futurePlanDates: [],
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
      ],
      appointmentDate: new Date(),
      appointmentEndDate: new Date(),
      destinationServiceCenterCode: "ONT",
      destinationSic: { code: "ONT", id: 111 },
      etaDate: new Date(),
      endAppointmentTimestampUTC: "2024-03-26T21:00:00-07:00",
      etaTimestampUtc: new Date(),
      finalDestination: "",
      isManualAssign: false,
      loadToDestination: "",
      location: "",
      originServiceCenterCode: "ONT",
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      shipmentLoadType: "",
      sic: "ONT",
      statusDetail: "",
      statusId: 1,
      statusShipmentHistoryList: null,
      appointmentTime: 0,
      assignment: "",
      equipment: null,
      lat: 0,
      lng: 0,
      routeId: null,
      stopSeq: null,
      isAvailableToReconcile: true,
    },
    {
      id: 3,
      tripNumber: null,
      pallets: 2,
      pieces: 16,
      handlingUnits: "2",
      weight: 1294,
      weightUnit: "lbs",
      originSicId: 168,
      destinationSicId: 111,
      appointmentRequired: false,
      appointmentMade: false,
      appointmentStartTimestampUTC: new Date(),
      appointmentEndTimestampUTC: new Date(),
      dueServiceTimestampUTC: new Date(),
      proNumber: "151881966",
      destinationSicCode: "ONT",
      status: "UNLD",
      presetRouteId: 200,
      presetRouteName: "ALL FREIGHT",
      shipper: {
        id: 3983060,
        name: "DOMINION VOTING",
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      consignee: {
        id: 7080040,
        name: "DOUGLAS COUNTY BOARD OF ELECTIONS",
        address: "8700 HOSPITAL DR",
        city: "PHELAN",
        state: "CA",
        zipCode: "92371",
      },
      isPlanned: false,
      futurePlanDates: [],
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
      ],
      appointmentDate: new Date(),
      appointmentEndDate: new Date(),
      destinationServiceCenterCode: "ONT",
      destinationSic: { code: "ONT", id: 111 },
      etaDate: new Date(),
      endAppointmentTimestampUTC: "2024-03-26T21:00:00-07:00",
      etaTimestampUtc: new Date(),
      finalDestination: "",
      isManualAssign: false,
      loadToDestination: "",
      location: "",
      originServiceCenterCode: "ONT",
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      shipmentLoadType: "",
      sic: "ONT",
      statusDetail: "",
      statusId: 1,
      statusShipmentHistoryList: null,
      appointmentTime: 0,
      assignment: "",
      equipment: null,
      lat: 0,
      lng: 0,
      routeId: null,
      stopSeq: null,
      isAvailableToReconcile: true,
    },
    {
      id: 4,
      tripNumber: null,
      pallets: 2,
      pieces: 16,
      handlingUnits: "2",
      weight: 1294,
      weightUnit: "lbs",
      originSicId: 168,
      destinationSicId: 111,
      appointmentRequired: false,
      appointmentMade: false,
      appointmentStartTimestampUTC: null,
      appointmentEndTimestampUTC: null,
      dueServiceTimestampUTC: null,
      proNumber: "151881966",
      destinationSicCode: null,
      status: "UNLD",
      presetRouteId: 200,
      presetRouteName: "ALL FREIGHT",
      shipper: {
        id: 3983060,
        name: "DOMINION",
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      consignee: {
        id: 7080040,
        name: "DOUGLAS COUNTY BOARD OF ELECTIONS",
        address: "8700 HOSPITAL DR",
        city: "PHELAN",
        state: "CA",
        zipCode: "92371",
      },
      isPlanned: false,
      futurePlanDates: [],
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
      ],
      appointmentDate: new Date(),
      appointmentEndDate: new Date(),
      destinationServiceCenterCode: "ONT",
      destinationSic: { code: "ONT", id: 111 },
      etaDate: new Date(),
      endAppointmentTimestampUTC: "2024-03-26T21:00:00-07:00",
      etaTimestampUtc: new Date(),
      finalDestination: "",
      isManualAssign: false,
      loadToDestination: "",
      location: "",
      originServiceCenterCode: "ONT",
      serviceDue: new Date(),
      serviceDueDate: new Date(),
      shipmentLoadType: "",
      sic: "ONT",
      statusDetail: "",
      statusId: 1,
      statusShipmentHistoryList: null,
      appointmentTime: 0,
      assignment: "",
      equipment: null,
      lat: 0,
      lng: 0,
      routeId: null,
      stopSeq: null,
      isAvailableToReconcile: true,
    },
  ],
} as const satisfies Record<string, FodShipment[]>;

export const mockLinehaulTrips = {
  empty: [],
  default: [
    {
      shipmentIds: [1, 2, 3, 4],
      shipmentsCount: 4,
      shipmentsPallets: 10,
      shipmentsPieces: 10,
      shipmentsWeight: 1750,
      tripNumber: 1354386,
      trailerNumber: "530-1001",
      originSic: "SFO",
      destinationSic: "ONT",
      status: "ENRT",
      etaTimestampUtc: new Date(),
      actualArrivalTime: new Date(),
      dueServiceTimestampUtc: new Date(),
      allFuturePlanDates: [
        Number(dayjs().format("YYYYMMDD")),
        20240417,
        20240418,
        20240419,
      ],
      shipmentsTags: [
        {
          id: 81,
          name: "VC",
          description: "Vendor Compliance",
          colorHex: "#AA7EA0",
          position: 30,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      allShipmentsPlanned: true,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 4,
      pallets: 10,
      pieces: 10,
      weight: 1750,
      tags: [
        {
          id: 81,
          name: "VC",
          description: "Vendor Compliance",
          colorHex: "#AA7EA0",
          position: 30,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      serviceDueDate: new Date(),
      eta: new Date(),
      originSicCode: "SFO",
      destinationSicCode: "ONT",
    },
    {
      shipmentIds: [5, 6],
      shipmentsCount: 2,
      shipmentsPallets: 7,
      shipmentsPieces: 5,
      shipmentsWeight: 820,
      tripNumber: 1353457,
      trailerNumber: "DYLT242209",
      originSic: "WSH-PITD",
      destinationSic: "ONT",
      status: "ENRT",
      etaTimestampUtc: new Date(),
      actualArrivalTime: new Date(),
      dueServiceTimestampUtc: new Date(),
      allFuturePlanDates: [20240417, 20240418, 20240419],
      shipmentsTags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 2,
      pallets: 7,
      pieces: 5,
      weight: 820,
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      serviceDueDate: new Date(),
      eta: new Date(),
      originSicCode: "WSH-PITD",
      destinationSicCode: "ONT",
    },
  ],
} as const satisfies Record<string, Trip[]>;

export const mockShuttleTrips = {
  empty: [],
  default: [
    {
      shipmentIds: [1, 2, 3, 4],
      shipmentsCount: 4,
      shipmentsPallets: 10,
      shipmentsPieces: 10,
      shipmentsWeight: 1750,
      tripNumber: 1354386,
      trailerNumber: "530-1001",
      originSic: "SFO",
      destinationSic: "ONT",
      status: "ENRT",
      etaTimestampUtc: new Date(),
      actualArrivalTime: new Date(),
      dueServiceTimestampUtc: new Date(),
      allFuturePlanDates: [20240417, 20240418, 20240419],
      shipmentsTags: [
        {
          id: 81,
          name: "VC",
          description: "Vendor Compliance",
          colorHex: "#AA7EA0",
          position: 30,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 4,
      pallets: 10,
      pieces: 10,
      weight: 1750,
      tags: [
        {
          id: 81,
          name: "VC",
          description: "Vendor Compliance",
          colorHex: "#AA7EA0",
          position: 30,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      serviceDueDate: new Date(),
      eta: new Date(),
      originSicCode: "SFO",
      destinationSicCode: "ONT",
    },
    {
      shipmentIds: [5, 6],
      shipmentsCount: 2,
      shipmentsPallets: 7,
      shipmentsPieces: 5,
      shipmentsWeight: 820,
      tripNumber: 1353457,
      trailerNumber: "DYLT242209",
      originSic: "WSH-PITD",
      destinationSic: "ONT",
      status: "ENRT",
      etaTimestampUtc: new Date(),
      actualArrivalTime: new Date(),
      dueServiceTimestampUtc: new Date(),
      allFuturePlanDates: [20240417, 20240418, 20240419],
      shipmentsTags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 2,
      pallets: 7,
      pieces: 5,
      weight: 820,
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      serviceDueDate: new Date(),
      eta: new Date(),
      originSicCode: "WSH-PITD",
      destinationSicCode: "ONT",
    },
  ],
} as const satisfies Record<string, Trip[]>;

export const mockIncompleteShipmentIds: number[] = [131365190];

export const mockIncompleteShipmentsDetails = {
  default: [
    {
      id: 1313651933,
      proNumber: "987654329",
      tripNumber: 1353104,
      shipper: { id: 1, name: "Shipper 1" },
      consignee: {
        id: 1,
        name: "Heavy Co. 1",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 2,
      pieces: 3,
      weight: 400,
      weightUnit: "lbs",
      tags: [],
      appointmentDate: new Date(2023, 2, 1),
      appointmentEndDate: new Date(2023, 2, 2),
      appointmentTime: 1000,
      serviceDue: new Date(2023, 2, 6),
      location: "On Dock",
      sic: "ETA 2 hours, ONT",
      status: "Trap",
      assignment: "Unassigned",
      etaDate: null,
      isPlanned: false,
      presetRouteId: 1,
      presetRouteName: "Route 1",
      destinationSicCode: "ONT",
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 6),
      futurePlanDates: [],
      isAvailableToReconcile: true,
    },
    {
      id: 13136748531,
      proNumber: "987654324",
      tripNumber: 4948849,
      shipper: { id: 2, name: "Shipper 2" },
      consignee: {
        id: 1,
        name: "Heavy Co. 21",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 3,
      pieces: 2,
      weight: 450,
      weightUnit: "lbs",
      tags: [{ name: "APPT", colorHex: "#20e0aa", description: "Appointment" }],
      appointmentDate: new Date(2023, 2, 2),
      appointmentEndDate: new Date(2023, 2, 3),
      appointmentTime: 1100,
      serviceDue: new Date(2023, 2, 5),
      location: "On Dock",
      sic: null,
      status: "Enrt",
      assignment: "Unassigned",
      etaDate: null,
      isPlanned: false,
      presetRouteId: 2,
      presetRouteName: "Route 2",
      destinationSicCode: null,
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 5),
      futurePlanDates: [20240417],
      isAvailableToReconcile: true,
    },
  ],
} as const satisfies Record<string, Shipment[]>;

import { Lock } from "@mui/icons-material";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { useLockRoutes } from "../../../../hooks/react-query/useDeliveryRoutes";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import Loading from "../../../../components/shared/layout/Loading";
import LockOpenIcon from "../../../../components/shared/icons/LockOpenIcon";

type LockRouteProps = {
  route: PlanTableFormat;
  clearSelection: () => void;
};

export const LockRouteIconButton = ({
  route,
  clearSelection,
}: LockRouteProps) => {
  const theme = useTheme();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const dateInt = dateToInt(selectedDate);

  const lockRouteMutation = useLockRoutes({
    date: dateInt,
    sicId: serviceCenter.id,
    onSuccess: () => {
      toast(
        toastMessage.shared.lockRoutes.success(1, route.isCommitted || false),
        {
          variant: "success",
          preventDuplicate: true,
        }
      );
      clearSelection();
    },
  });

  if (lockRouteMutation.isPending) {
    return <Loading containerWidth={32} size={32} />;
  }

  return (
    <Box>
      <Tooltip
        title={`${route.isCommitted ? "Unlock" : "Lock"} route ${route.name}`}
      >
        <IconButton
          onClick={() =>
            lockRouteMutation.mutate([
              { id: route.id, isLocked: !route.isCommitted || false },
            ])
          }
        >
          {route.isCommitted ? (
            <Lock style={{ color: theme.palette.grey[600] }} />
          ) : (
            <LockOpenIcon fillColor={theme.palette.grey[600]} />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

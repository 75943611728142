// Cspell:ignore shipmentsbyid
const base = {
  routes: "operations/routes/routes-api/routes",
  zones: "operations/routes/routes-api/zones",
  driver: "operations/driver",
  equipment: "operations/equipment-api/equipments",
  accessControl: "/access-control",
  roles: "users-api/roles",
  shipment: "operations/shipment-api",
  inboundPlanning: "operations/inbound-planning/inbound-planning-api",
  serviceCenter: "operations/service-center",
  outbound: "operations/outbound",
  masterScheduleBaseUrl: "linehaul/api/v1/master-schedule",
  masterScheduleTemplateBaseUrl: "linehaul/api/v1/master-schedule-template",
  loadboardBaseUrl: "linehaul/api/v1/loadboard",
  loadboardV2BaseUrl: "linehaul/api/v2/loadboard",
  lane: "lane-api",
  splitIoApi: "https://sdk.split.io/api",
  linehaul: "linehaul/api/v1",
  linehaulV2: "linehaul/api/v2",
  reports: "operations/reports-api",
  dispatch: "dispatch/api",
};

export const apiUrls = {
  // Route
  getRoutesAndTraps: base.routes,
  getRouteById: base.routes,
  putRoutes: (routeId: number) => `${base.routes}/${routeId}`,
  postRoutes: base.routes,
  deleteRoute: (routeId: number) => `${base.routes}/${routeId}`,
  patchRoute: (routeId: number) => `${base.routes}/${routeId}`,
  getZipCodesGeolocationByRouteIds: (routeIds: number[]) =>
    `${base.routes}/zipcodes?routeIds=${routeIds.join(",")}`,
  validateZipCodes: `${base.routes}/zipcodes/validate`,
  moveZipCodes: `${base.routes}/zipcodes/move`,

  // Route zones
  getZones: base.zones,
  getZoneById: (zoneId: number) => `${base.zones}/${zoneId}`,
  putZone: (zoneId: number) => `${base.zones}/${zoneId}`,
  patchZone: (zoneId: number) => `${base.zones}/${zoneId}`,
  deleteZone: (zoneId: number) => `${base.zones}/${zoneId}`,
  postZones: base.zones,
  postZone: base.zones,

  // Driver
  putDriver: (driverId: number) => `${base.driver}/${driverId}`,
  postDrivers: base.driver,
  getEmployeeDrivers: `${base.driver}/type/Employee`,
  getDriverById: (driverId: number) => `${base.driver}/${driverId}`,
  getDrivers: (serviceCenterCode: number) =>
    `${base.driver}/service-centers/${serviceCenterCode}/drivers`,
  getDriversUsageBySicIdAndPlanDate: (sicId: number, planDate: number) =>
    `${base.driver}/service-centers/${sicId}/plan-date/${planDate}/drivers-usage-info`,
  deleteDriver: (driverId: number) => `${base.driver}/${driverId}`,

  // Equipment
  putEquipment: (equipmentId: number) => `${base.equipment}/${equipmentId}`,
  postEquipments: base.equipment,
  deleteEquipment: (equipmentId: number) => `${base.equipment}/${equipmentId}`,
  getEquipmentById: (equipmentId: number) => `${base.equipment}/${equipmentId}`,
  getEquipmentDetailsBySicId: (sicId: number) =>
    `${base.equipment}/service-center/${sicId}/equipments-details`,
  getEquipmentsBySicIdAndTypeId: (sicId: number, typeId: number) =>
    `${base.equipment}/service-center/${sicId}/equipments/${typeId}`,
  getEquipmentsUsageBySicId: (sicId: number) =>
    `${base.equipment}/service-center/${sicId}/equipments-use-info`,
  getEquipmentsBySicIdAndPlanDateOld: (sicId: number, typeId: number) =>
    `${base.equipment}/v2/service-center/${sicId}/equipments/${typeId}`,
  getLinehaulTrailers: `${base.linehaul}/equipment`,

  // User
  getUser: (userId: string, include: Readonly<string[]>) => {
    const params = new URLSearchParams();
    include.forEach((element) => {
      params.append("includes", encodeURIComponent(element));
    });
    const paramsString = params.toString() ? `?${params.toString()}` : "";
    return `${base.accessControl}/user/${userId}${paramsString}`;
  },
  getCurrentUser: `${base.accessControl}/user`,

  getUsers: (include: Readonly<string[]>) => {
    const params = new URLSearchParams();
    include.forEach((element) =>
      params.append("includes", encodeURIComponent(element))
    );
    const paramsString = params.toString() ? `?${params.toString()}` : "";
    return `${base.accessControl}/users${paramsString}`;
  },
  getUserGroups: (userId: string) =>
    `${base.accessControl}/user/${userId}/groups`,
  updateUser: `${base.accessControl}/user`,

  // User roles
  getRoles: base.roles,
  putRole: (roleId: number) => `${base.roles}/${roleId}`,
  postRole: base.roles,
  deleteRole: (roleId: number) => `${base.roles}/${roleId}`,

  // Permissions
  getPermissions: "users-api/permissions",
  getPermissionsV2: `${base.accessControl}/permissions`,
  createPermission: `${base.accessControl}/permission`,
  deletePermission: (permissionId: number) =>
    `${base.accessControl}/permission/${permissionId}`,
  updatePermission: (permissionId: number) =>
    `${base.accessControl}/permission/${permissionId}`,

  // Modules
  getModules: `${base.accessControl}/modules`,
  getModule: (moduleId: number) => `${base.accessControl}/module/${moduleId}`,
  deleteModule: (moduleId: number) =>
    `${base.accessControl}/module/${moduleId}`,
  updateModule: (moduleId: number) =>
    `${base.accessControl}/module/${moduleId}`,
  createModule: `${base.accessControl}/module`,

  // Groups
  getGroups: `${base.accessControl}/groups`,
  getGroup: (groupId: number) => `${base.accessControl}/group/${groupId}`,
  createGroup: `${base.accessControl}/group`,
  deleteGroup: (groupId: number) => `${base.accessControl}/group/${groupId}`,
  updateGroup: (groupId: number) => `${base.accessControl}/group/${groupId}`,
  updateGroupPermissions: (groupId: number) =>
    `${base.accessControl}/group/${groupId}/permissions`,
  updateGroupUsers: (groupId: number) =>
    `${base.accessControl}/group/${groupId}/users`,

  getGroupUsers: (groupId: number) =>
    `${base.accessControl}/group/${groupId}/users`,

  // Shipment
  getUnplannedFreightOD: `${base.shipment}/freight/additional-freight`,
  getShipmentsById: "operations/shipment-api/freight/shipmentsbyid",
  getShipmentsAppointments: (serviceCenterId: number) =>
    `operations/shipment-api/freight/shipments/appointments?destinationSicId=${serviceCenterId}`,
  getAppointmentBucket: (sicId: number | string, searchTerm = "") =>
    `/operations/shipment-api/operations/pre-planning/${sicId}/appointments?searchTerm=${searchTerm}`,
  getAppointmentBucketDetails: (sicId: number, bucketName: string) =>
    `operations/shipment-api/operations/pre-planning/${sicId}/appointments/${bucketName}/shipments`,
  getSections: (destinationSicId: number) =>
    `/operations/shipment-api/sections/summary?destinationSicId=${destinationSicId}`,
  getSectionsTableData: "/operations/shipment-api/sections/summary",
  getShipmentDetails: `/${base.shipment}/shipments/details`,
  getShipmentsByRoute: (trailerId: number | string) =>
    `/${base.inboundPlanning}/delivery-routes/${trailerId}/shipments`,
  getShipmentsByTrap: (trailerId: number | string) =>
    `/${base.inboundPlanning}/delivery-traps/${trailerId}/shipments`,
  // TODO: Note that traps are handled in multiple endpoints. We should resolve.
  getTraps: "/operations/shipment-api/trap-summary",
  getAdditionalFreight: "/operations/shipment-api/freight/additional-freight",
  getIncompleteTrailer: (date: number, sicId: number) =>
    `/${base.inboundPlanning}/service-centers/${sicId}/dates/${date}/incomplete-trailers`,
  reinstateToPlan: (date: number, sicId: number) =>
    `/${base.inboundPlanning}/service-centers/${sicId}/dates/${date}/incomplete-trailers/move-to-plan`,
  // Maps
  getDataFromMapAttributes: (proximity: string) =>
    `maps/attributes?in=${proximity}`,

  // Outbound Planning
  getLane: (date: number, sicId: number) =>
    `outbound-planning-api/plan/date/${date}/service-center/${sicId}`,

  // Inbound Planning
  addToPlan: (sicId: number, date: number) =>
    `${base.inboundPlanning}/v2/service-centers/${sicId}/dates/${date}/add-to-plan`,
  getDeliveryRoutes: (sicId: number, date: number) =>
    `${base.inboundPlanning}/service-centers/${sicId}/dates/${date}/delivery-routes`,
  getDeliveryRouteStatus: (id: number) =>
    `${base.inboundPlanning}/v2/delivery-routes/${id}/available-statuses`,
  getDeliveryTrapStatus: (id: number) =>
    `${base.inboundPlanning}/v2/delivery-traps/${id}/available-statuses`,
  getDeliveryRoutesTrapsStatusOld: (routeIds: number[], trapIds: number[]) => {
    const params = new URLSearchParams();
    routeIds.forEach((id) => params.append("routeIds", id.toString()));
    trapIds.forEach((id) => params.append("trapIds", id.toString()));
    return `${
      base.inboundPlanning
    }/delivery-routes-traps/available-statuses?${params.toString()}`;
  },
  getDeliveryRoutesTrapsStatus: (routeIds: number[], trapIds: number[]) => {
    const params = new URLSearchParams();
    routeIds.forEach((id) => params.append("routeIds", id.toString()));
    trapIds.forEach((id) => params.append("trapIds", id.toString()));
    return `${
      base.inboundPlanning
    }/v2/delivery-routes-traps/available-statuses?${params.toString()}`;
  },
  putDeliveryRouteStatus: (id: number) =>
    `${base.inboundPlanning}/delivery-routes/${id}/status`,
  putDeliveryTrapStatus: (id: number) =>
    `${base.inboundPlanning}/delivery-traps/${id}/status`,
  putDeliveryRoutesTrapsStatus: `${base.inboundPlanning}/delivery-routes-traps/status`,
  createRoute: (unixTime: number, sicId: number) =>
    `${base.inboundPlanning}/routes/date/${unixTime}/service-center/${sicId}`,
  getRouteAssignments: (date: number, sicId: number) =>
    `${base.inboundPlanning}/service-centers/${sicId}/dates/${date}/delivery-routes-traps/summary`,
  deleteRouteAssignments: (idsToDelete: string) =>
    `${base.inboundPlanning}/routes/${idsToDelete}`,
  putRouteAssignment: (routeId: number) =>
    `${base.inboundPlanning}/routes/${routeId}`,
  putRouteAssignments: `${base.inboundPlanning}/routes`,
  lockRoutes: `${base.inboundPlanning}/delivery-routes/lock-status`,
  putTrapAssignment: (trapId: number) =>
    `${base.inboundPlanning}/traps/${trapId}`,
  mergeRoutes: (routeId: number) =>
    `${base.inboundPlanning}/routes/${routeId}/merge`,
  unassignShipmentById: `${base.inboundPlanning}/shipments/unassign`,
  getUnassignedShipments: (sicId: number, planDate: number) =>
    `${base.inboundPlanning}/shipments/unassigned/service-center/${sicId}/date/${planDate}`,
  reorderShipments: (routeId: number) =>
    `${base.inboundPlanning}/shipments/order/${routeId}`,
  assignShipmentByRouteId: (routeType: string, routeId: number) =>
    routeType === "delivery_trap"
      ? `${base.inboundPlanning}/shipments/assign-to-trap/${routeId}`
      : `${base.inboundPlanning}/shipments/assign-to-route/${routeId}`,
  removeFromPlan: (sicId: number, date: number) =>
    `${base.inboundPlanning}/remove-from-plan/date/${date}/service-center/${sicId}`,
  // TODO: Unify these?
  getDeliveryRoutesSummary: (sicId: number, date: number) =>
    `${base.inboundPlanning}/service-centers/${sicId}/dates/${date}/delivery-routes/summary`,
  getServiceCenterGeocodeByIds: (sicIds: number[]) =>
    `${base.inboundPlanning}/geocode/sic/${sicIds.join(",")}`,
  createTrap: (sicId: number | string, unixDate: number | string) =>
    `${base.inboundPlanning}/service-centers/${sicId}/dates/${unixDate}/traps`,
  deleteTraps: (trapIds: number[], sicId: number) =>
    `${base.inboundPlanning}/traps?trapIds=${trapIds.join(",")}&sicId=${sicId}`,
  deleteDeliveryTraps: (trapIds: number[]) =>
    `${base.inboundPlanning}/traps/${trapIds.join(",")}`,
  getInboundTraps: (sicId: number | string, date: number | string) =>
    `${base.inboundPlanning}/service-centers/${sicId}/dates/${date}/traps`,
  mergeRoutesAndTraps: `${base.inboundPlanning}/routes-and-traps/merge`,
  postMergeTrap: `${base.inboundPlanning}/traps/merge`,
  reassignShipments: `${base.inboundPlanning}/shipments/re-assign`,
  autoSequenceRoutes: (routeIds: number[]) =>
    `${base.inboundPlanning}/delivery-routes/auto-sequencer?${routeIds
      .map((routeId) => `routeIds=${routeId}`)
      .join("&")}`,
  getDeliveryRoutesSequenceStatus: (routeIds: number[] | string) =>
    `${base.inboundPlanning}/delivery-routes/sequence-status?${
      typeof routeIds === "string"
        ? `routeIds=${routeIds}`
        : routeIds.map((routeId) => `routeIds=${routeId}`).join("&")
    }`,

  // Operations
  // TODO: This name has "FromOperations" on the end to avoid a naming conflict.
  getAppointmentBucketFromOperations: (date: string) =>
    `/api/operations/pre-planning/${date}/appointment-bucket`,
  getAppointmentBucketShipments: (date: string, id: number | string) =>
    `/api/operations/pre-planning/${date}/appointment-bucket/${id}/shipments`,
  getCarrierContacts: (carrierCode: string) =>
    `/linehaul/api/v1/carrier/${carrierCode}/contacts`,
  getCarrierScacCodes: `${base.linehaul}/carrier/scac-codes`,

  masterSchedule: {
    createWithTemplate: `${base.masterScheduleBaseUrl}/create-from-template`,
    createWithClone: `${base.masterScheduleBaseUrl}/create-from-clone`,
    createSchedulePair: `${base.masterScheduleBaseUrl}/entry`,
    createTemplateWithTemplate: `${base.masterScheduleTemplateBaseUrl}/clone-template`,
    createTemplateWithClone: `${base.masterScheduleTemplateBaseUrl}/clone-previous-week`,
    createTemplateNew: `${base.masterScheduleTemplateBaseUrl}/create-from-blank`,
    updateTemplate: (id: number) =>
      `${base.masterScheduleTemplateBaseUrl}/${id}`,
    delete: (weekOf: string) => `${base.masterScheduleBaseUrl}/${weekOf}`,
    deleteSchedulePair: (id: number) =>
      `${base.masterScheduleBaseUrl}/entry/${id}`,
    expireSchedulePair: (loadPairScheduleId: number) =>
      `${base.masterScheduleBaseUrl}/entry/expire/${loadPairScheduleId}`,
    getCarriers: "/linehaul/api/v1/carrier",
    get: (week: string) => `${base.masterScheduleBaseUrl}?weekOf=${week}`,
    getSchedulePair: (loadPairId: number | string) =>
      `${base.masterScheduleBaseUrl}/entry/${loadPairId}`,
    getTemplates: base.masterScheduleTemplateBaseUrl,
    getTemplate: (id: number) => `${base.masterScheduleTemplateBaseUrl}/${id}`,
    publish: (weekOf: string) =>
      `${base.masterScheduleBaseUrl}/${weekOf}/publish`,
    batchPublish: `${base.masterScheduleBaseUrl}/entry/publish`,
    getServiceTypes: "/linehaul/api/v1/service-types",
    updateCarrierContacts: (carrierCode: string) =>
      `/linehaul/api/v1/carrier/${carrierCode}/contacts`,
    updateSchedulePair: `${base.masterScheduleBaseUrl}/entry`,
    bulkUpdateSchedules: `${base.masterScheduleBaseUrl}/entries`,
    deleteTemplate: (id: number) =>
      `${base.masterScheduleTemplateBaseUrl}/${id}`,
    deleteTemplateSchedulePair: (templateId: number, loadPairId: number) =>
      `${base.masterScheduleTemplateBaseUrl}/${templateId}/entry/${loadPairId}`,
    getTemplateSchedulePair: (templateId: number, loadPairId: number) =>
      `${base.masterScheduleTemplateBaseUrl}/${templateId}/entry/${loadPairId}`,
    createTemplateSchedulePair: (templateId: number) =>
      `${base.masterScheduleTemplateBaseUrl}/${templateId}/entry`,
    updateTemplateSchedulePair: (templateId: number) =>
      `${base.masterScheduleTemplateBaseUrl}/${templateId}/entry`,
    bulkUpdateTemplateSchedules: `${base.masterScheduleTemplateBaseUrl}/entries`,
  },

  loadBoard: {
    getSchedule: (scheduleId: number) =>
      `${base.loadboardBaseUrl}/schedule/${scheduleId}`,
    getSchedules: (date: string) =>
      `${base.loadboardBaseUrl}/schedules?day=${date}`,
    getShipments: (date: string) =>
      `${base.loadboardBaseUrl}/shipments?day=${date}`,
    getTrips: `${base.loadboardBaseUrl}/trips`,

    getNotes: (scheduleId: number) =>
      `${base.loadboardBaseUrl}/schedule/${scheduleId}/notes`,
    createNote: (scheduleId: number) =>
      `${base.loadboardBaseUrl}/schedule/${scheduleId}/note`,
    updateNote: (noteId: number) => `${base.loadboardBaseUrl}/note/${noteId}`,
    deleteNote: (noteId: number) => `${base.loadboardBaseUrl}/note/${noteId}`,
    getShipmentNotes: (shipmentId: number | string) =>
      `${base.loadboardV2BaseUrl}/shipments/${shipmentId}/notes`,
    getShipmentHistory: (sicCode: string, shipmentId: number | string) =>
      `${base.outbound}/${sicCode}/shipment/${shipmentId}/unplanned-details`,
    createShipmentNote: (shipmentId: number) =>
      `${base.loadboardV2BaseUrl}/shipments/${shipmentId}/notes`,
    updateShipmentNote: (shipmentId: number, noteId: number) =>
      `${base.loadboardV2BaseUrl}/shipments/${shipmentId}/notes/${noteId}`,
    deleteShipmentNote: (shipmentId: number, noteId: number) =>
      `${base.loadboardV2BaseUrl}/shipments/${shipmentId}/notes/${noteId}`,
    publishSchedule: (scheduleId: number) =>
      `${base.loadboardBaseUrl}/schedule/${scheduleId}/publish`,
    unpublishSchedule: (scheduleId: number) =>
      `${base.loadboardBaseUrl}/schedule/${scheduleId}/unpublish`,
    batchPublishSchedules: `${base.loadboardBaseUrl}/schedule/bulk-publish`,
    cancelSchedulePair: `${base.loadboardBaseUrl}/schedule/cancel`,
    reinstateSchedulePair: `${base.loadboardBaseUrl}/schedule/reinstate`,
    reorderTripsForLane: `${base.loadboardBaseUrl}/trip/reorder`,
    getSchedulePair: (loadPairId: string) =>
      `${base.loadboardBaseUrl}/schedule/pair/${loadPairId}`,
    createSchedulePair: `${base.loadboardBaseUrl}/schedule`,
    updateSchedule: (scheduleId: number) =>
      `${base.loadboardBaseUrl}/schedule/${scheduleId}`,
    getChangeRequestLogs: (scheduleId: string) =>
      `${base.loadboardBaseUrl}/schedule/${scheduleId}/change-request-logs`,

    // V2
    getShipmentDaySummary: (date: string) =>
      `${base.loadboardBaseUrl}/shipment/${date}`,
    lanesSummary: `${base.loadboardBaseUrl}/lane-summary`,
    daySummary: `${base.loadboardBaseUrl}/day-summary`,
    daySummaryV2: (date: string) =>
      `${base.loadboardV2BaseUrl}/day-summary/${date}`,
    tripShipments: (tripNumber: number) =>
      `/${base.loadboardV2BaseUrl}/trips/${tripNumber}/shipments`,
    tripList: (date: string) => `${base.linehaulV2}/tripList?day=${date}`,
    closedTripDetails: (tripNumber?: number) =>
      `${base.linehaul}/trip/${tripNumber}/closed-details`,
    closeTrip: (tripNumber: number) =>
      `${base.linehaulV2}/loadboard/trip/${tripNumber}/close`,
    editTripClosedDetails: (tripNumber: number) =>
      `${base.linehaulV2}/loadboard/trip/${tripNumber}/update-closed-trip-details`,
    enrouteTrip: (tripNumber: number) =>
      `${base.linehaulV2}/loadboard/trip/${tripNumber}/enroute`,
    changeTripStatus: (tripNumber: number) =>
      `${base.linehaul}/trip/${tripNumber}/change-status`,
    tripDetails: (tripNumber: number) => `${base.linehaul}/trip/${tripNumber}`,
    tripsForReassign: `/${base.loadboardV2BaseUrl}/shipments/trips-for-reassign`,
    reassignShipments: `/${base.loadboardV2BaseUrl}/shipments/reassign`,
    unassignShipments: `/${base.loadboardV2BaseUrl}/shipments/unassign`,
    delayReasons: `${base.linehaulV2}/tripList/delay-reasons`,
    ucccNotifications: `${base.linehaul}/loadboard/uccc-notifications`,
    ucccPlanning: (date: string) => `${base.linehaulV2}/uc-cc-planning/${date}`,
    tripsForShipmentDesignation: (sicCode: string) =>
      `${base.linehaul}/trip/trips-for-shipment-designation/${sicCode}`,
  },

  dispatch: {
    getLatestDriverLocations: (sicId: number, planDate: string) =>
      `${base.dispatch}/${sicId}/latest-drivers-location?planDate=${planDate}`,
    getRoute: (sicId: number, routeId: number) =>
      `${base.dispatch}/v1/${sicId}/routes/${routeId}`,
    getRoutes: (sicId: number, planDate: string) =>
      `${base.dispatch}/v1/${sicId}/routes?planDate=${planDate}`,
    getStops: (sicId: number, planDate: string) =>
      `${base.dispatch}/${sicId}/stops?planDate=${planDate}`,
    getStop: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/stops/${id}`,
    getStopLocationPhotos: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/stops/${id}/misc`,
    getSummary: (sicId: number, planDate: string) =>
      `${base.dispatch}/${sicId}/summary?planDate=${planDate}`,
    putStopAddress: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/stops/${id}/address`,
    postUnassignStopFromRoute: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/stops/${id}/unassign`,
    getSuggestedRoutes: (sicId: number, stopId: number, planDate: string) =>
      `${base.dispatch}/${sicId}/suggest-routes/${stopId}?planDate=${planDate}`,
    postAssignStopToRoute: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/stops/${id}/assign`,
    getStopShipmentNotes: (sicId: number, shipmentId: number) =>
      `${base.dispatch}/${sicId}/shipment/${shipmentId}/notes`,
    patchStopSic: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/stops/${id}/sic`,
    getStopSuggestedSics: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/stops/${id}/suggested-sics`,
    getStopShipmentPhotos: (sicId: number, shipmentId: number) =>
      `${base.dispatch}/${sicId}/shipment/${shipmentId}/misc`,
    postUpdateRouteStatus: (sicId: number, id: number) =>
      `${base.dispatch}/${sicId}/routes/${id}/status`,
    getAvailableRouteStatuses: (sicId: number, routeId: number) =>
      `${base.dispatch}/${sicId}/routes/${routeId}/available-statuses`,
    postRescheduleStop: (sicId: number, stopId: number) =>
      `${base.dispatch}/${sicId}/stops/${stopId}/reschedule`,
    getShipmentDetails: (sicId: number, shipmentId: number) =>
      `${base.dispatch}/${sicId}/shipment/${shipmentId}/details`,
    getNotifications: (sicId: number, planDate: string) =>
      `${base.dispatch}/${sicId}/notifications?planDate=${planDate}`,
  },

  getPresetLanes: (sicCode: string) =>
    `operations/outbound/${sicCode}/PresetLane`,
  getTrailerTypes: "operations/outbound/TrailerType",

  // Outbound
  createOrUpdatePresetLanes: (sicCode: string) =>
    `${base.outbound}/${sicCode}/PresetLane`,
  getOrDeletePresetLane: (sicCode: string, laneId: number) =>
    `${base.outbound}/${sicCode}/PresetLane/${laneId}`,
  getLanes: (sicCode: string) => `operations/outbound/${sicCode}/Lane/`,
  createOrUpdateStaticLoadTemplate: (sicCode: string) =>
    `${base.outbound}/${sicCode}/StaticLoadTemplate`,
  getOrDeleteStaticLoadTemplate: (sicCode: string, laneId: number) =>
    `${base.outbound}/${sicCode}/StaticLoadTemplate/${laneId}`,
  getStaticLoadTemplates: (sicCode: string) =>
    `${base.outbound}/${sicCode}/StaticLoadTemplate/`,
  duplicateStaticLoadTemplate: (sicCode: string, templateId: number) =>
    `${base.outbound}/${sicCode}/StaticLoadTemplate/${templateId}/Duplicate`,
  getDefaultLoadTemplates: (sicCode: string) =>
    `${base.outbound}/${sicCode}/DefaultTemplate/`,
  updateDefaultLoadTemplate: (sicCode: string) =>
    `${base.outbound}/${sicCode}/DefaultTemplate`,
  getDoorTypes: `${base.outbound}/DoorType`,
  getDoorLayouts: (sicCode: string) =>
    `${base.outbound}/${sicCode}/layout/doors`,
  getSectors: (sicCode: string) => `${base.outbound}/${sicCode}/layout/sectors`,
  getDockLayout: (sicCode: string) => `${base.outbound}/${sicCode}/layout`,
  getFreightDetails: (sicCode: string, sectors: string[]) =>
    `${base.outbound}/${sicCode}/Freight/v2/tonnage${
      sectors.length > 0 ? `?sectors=${sectors.join(",")}` : ""
    }`,
  getLaneDetailsTonnage: (sicCode: string, sectors: string[]) =>
    `${base.outbound}/${sicCode}/Freight/v3/tonnage${
      sectors.length > 0 ? `?sectors=${sectors.join(",")}` : ""
    }`,
  getFreightDetailsByDoor: (sicCode: string, sectors: string[]) =>
    `${base.outbound}/${sicCode}/Freight/v2/tonnage-by-door${
      sectors.length > 0 ? `?sectors=${sectors.join(",")}` : ""
    }`,
  getDestinationShipmentsByLane: (
    sicCode: string,
    destinationType: number,
    loadToSicId: number,
    sicId: number
  ) =>
    `${base.outbound}/${sicCode}/Freight/${destinationType}/lanes/${loadToSicId}/destinations/${sicId}/shipments`,
  getDoorShipments: (sicCode: string, doorNumber: number) =>
    `${base.outbound}/${sicCode}/Freight/v2/doors/${doorNumber}/shipments`,
  moveShipmentsByDoor: (sicCode: string, doorNumber: number) =>
    `${base.outbound}/${sicCode}/Shipment/move-shipments-to-door/${doorNumber}`,
  moveShipmentsByLane: (sicCode: string, laneSicId: number) =>
    `${base.outbound}/${sicCode}/Shipment/divert-shipments/${laneSicId}`,
  sicsSuggestion: (sicCode: string, destinationSicId: number) =>
    `${base.outbound}/${sicCode}/load-plan/lanes/${destinationSicId}/sics-suggestion`,
  splitLane: (sicCode: string, destinationSicId: number) =>
    `${base.outbound}/${sicCode}/load-plan/lanes/${destinationSicId}/split-lane`,
  editAssignAsset: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/load-plan/planned-pulls/${plannedPullId}/v2/associate-linehaul-assets`,
  editAssignTrap: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/load-plan/planned-pulls/${plannedPullId}/v2/associate-trap-assets`,
  removeAsset: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/load-plan/planned-pulls/${plannedPullId}`,
  assignAsset: (sicCode: string, doorNumber: number) =>
    `${base.outbound}/${sicCode}/load-plan/doors/${doorNumber}/associate-linehaul-assets`,
  assignTrap: (sicCode: string, doorNumber: number) =>
    `${base.outbound}/${sicCode}/load-plan/doors/${doorNumber}/associate-trap-assets`,
  addDoorToLane: (sicCode: string) =>
    `${base.outbound}/${sicCode}/load-plan/v2/doors`,
  unassignDoorFromLane: (sicCode: string, laneId: number, doorNumber: number) =>
    `${base.outbound}/${sicCode}/load-plan/lanes/${laneId}/unassign-door/${doorNumber}`,
  getEstimateShipmentTransit: (
    sicCode: string,
    laneSicCode: string,
    shipmentIds: Array<number>
  ) =>
    `${
      base.outbound
    }/${sicCode}/transit-days/${laneSicCode}/estimate-shipment-transit?ShipmentIds=${shipmentIds.join(
      ","
    )}`,
  getAlternateLanes: (
    sicCode: string,
    laneSicCode: string,
    alternateLaneSicCode: string
  ) =>
    `${base.outbound}/${sicCode}/transit-days/${laneSicCode}/recommend-alternate-lanes/${alternateLaneSicCode}`,
  getOutboundShipmentDetails: (sicCode: string, shipment: number) =>
    `${base.outbound}/${sicCode}/Shipment/${shipment}/Details`,
  getOutboundShipmentDetailsV2: (
    sicCode: string,
    laneType: number,
    destinationSicId: number
  ) =>
    `${base.outbound}/${sicCode}/freight/v2/lanes/${laneType}/${destinationSicId}/shipments`,
  getLaneDetails: (sicCode: string, laneId: number) =>
    `${base.outbound}/${sicCode}/load-plan/lanes/${laneId}`,
  getDoorAssets: (
    sicCode: string,
    laneType: number,
    destinationSicId: number
  ) =>
    `${base.outbound}/${sicCode}/load-plan/v3/lanes/${laneType}/${destinationSicId}`,
  getOrEditDoorDetails: (sicCode: string, doorNumber: number) =>
    `${base.outbound}/${sicCode}/load-plan/doors/${doorNumber}`,
  reassignDoorDetails: (sicCode: string, doorNumber: number) =>
    `${base.outbound}/${sicCode}/load-plan/door/${doorNumber}/reassign`,
  mergeLanes: (sicCode: string, destinationSicId: number) =>
    `${base.outbound}/${sicCode}/load-plan/lanes/${destinationSicId}/merged-lanes`,
  getUnassignedDoors: (sicCode: string) =>
    `${base.outbound}/${sicCode}/load-plan/unassigned-doors`,
  shipmentTags: `${base.outbound}/ShipmentTags`,
  getLinehaulTrips: (sicCode: string, destinationSicCode: string) =>
    `${base.outbound}/${sicCode}/load-plan/linehaul-trips/${destinationSicCode}`,
  getEquipments: (sicCode: string) => `${base.outbound}/${sicCode}/equipment`,
  getOutboundCarriers: `${base.outbound}/carrier`,
  getMultipleShipmentDetails: (
    sicCode: string,
    shipmentIds: Array<number>,
    laneId: number
  ) =>
    `${base.outbound}/${sicCode}/Shipment/?ShipmentIds=${shipmentIds.join(
      ","
    )}&LaneId=${laneId}`,
  getMultipleLanesDetails: (sicCode: string, lanesIds: Array<number>) =>
    `${base.outbound}/${sicCode}/load-plan/lanes?laneIds=${lanesIds.join(",")}`,
  getMultipleDoorDetails: (sicCode: string, doorNumbers: Array<number>) =>
    `${
      base.outbound
    }/${sicCode}/load-plan/doors-details?doors=${doorNumbers.join(",")}`,
  getUrgentAndCriticalCare: (sicCode: string) =>
    `${base.outbound}/${sicCode}/urgent-care-critical-care/`,
  getOutboundLinehaulTrailers: ({
    sicCode,
    eta,
    isPlanned,
  }: {
    sicCode: string;
    eta: string;
    isPlanned?: boolean;
  }) =>
    `${base.outbound}/${sicCode}/trailers-hub/linehaul?eta=${eta}${
      isPlanned !== undefined ? `&planned=${isPlanned}` : ""
    }`,
  getOutboundLoadTrailers: (sicCode: string) =>
    `${base.outbound}/${sicCode}/trailers-hub/load`,
  changeStatusOutboundLoads: (
    sicCode: string,
    plannedPullId: number,
    newStatus: string
  ) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/status/${newStatus}`,
  getPndTrailers: (sicCode: string) =>
    `${base.outbound}/${sicCode}/trailers-hub/pickup-delivery/`,
  getTrapTrailers: (sicCode: string) =>
    `${base.outbound}/${sicCode}/trailers-hub/trap/`,
  getOutboundLinehaulTrailerShipments: (sicCode: string, tripNumber: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/linehaul/${tripNumber}/shipments`,
  getOutboundLoadTrailerShipments: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/shipments`,
  getFreightOnDock: (sicCode: string) =>
    `${base.outbound}/${sicCode}/trailers-hub/freight-on-dock/`,
  getOutboundPndTrailerShipments: (sicCode: string, tripNumber: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/pickup-delivery/${tripNumber}/shipments`,
  getOutboundTrapTrailerShipments: (sicCode: string, trapId: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/trap/${trapId}/shipments`,
  addLinehaulTrailersToPlan: (sicCode: string) =>
    `${base.outbound}/${sicCode}/load-plan/linehaul-trips/add`,
  removeLinehaulTrailersFromPlan: (sicCode: string) =>
    `${base.outbound}/${sicCode}/load-plan/linehaul-trips/remove`,
  reconcileShipmentsOutboundLoads: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/add-shipments`,
  removeShipmentsOutboundLoads: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/remove-shipments`,
  getOutboundShipmentsSearch: (
    sicCode: string,
    plannedPullId: number,
    proNumber: string
  ) =>
    `${base.outbound}/${sicCode}/transit-days/pulls/${plannedPullId}/estimate-shipment-transit/${proNumber}`,
  getOutboundLinehaulTrailerShipmentsPdf: (
    sicCode: string,
    tripNumbers: Array<number>
  ) =>
    `${base.outbound}/${sicCode}/trailers-hub/linehaul/unload-manifest?tripNumbers=${tripNumbers.join(",")}&returnType=json`,
  getOutboundPndTrailerShipmentsPdf: (
    sicCode: string,
    tripNumbers: Array<number>
  ) =>
    `${base.outbound}/${sicCode}/trailers-hub/pickup-delivery/strip-manifest?tripNumbers=${tripNumbers.join(",")}&returnType=json`,
  getOutboundTrapTrailerShipmentsPdf: (
    sicCode: string,
    trapIds: Array<number>
  ) =>
    `${base.outbound}/${sicCode}/trailers-hub/trap/unload-manifest?trapIds=${trapIds.join(",")}&returnType=json`,
  getOutboundLoadTrailerShipmentsPdf: (
    sicCode: string,
    tripNumbers: Array<number>
  ) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/carrier-bill-of-lading?tripNumbers=${tripNumbers.join(",")}&returnType=json`,
  getLoadTrailerNotes: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/notes`,
  createLoadTrailerNote: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/notes`,
  updateLoadTrailerNote: (sicCode: string, plannedPullId: number) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/notes/`,
  deleteLoadTrailerNote: (
    sicCode: string,
    plannedPullId: number,
    noteId: number
  ) =>
    `${base.outbound}/${sicCode}/trailers-hub/load/${plannedPullId}/notes/${noteId}`,

  // Trip
  getTripShuttle: "operations/trip/base-trips-shuttle-v2",
  getTripLH: "operations/trip/base-trips-lh-v2",
  getTripLHStops: "operations/trip/stops",

  // Lane
  editLane: (laneId: string) => `${base.lane}/lanes/${laneId}`,
  createLoadTemplate: `${base.lane}/load-templates`,
  getLoadTemplate: (id: string) => `${base.lane}/load-templates/${id}`,
  getLoadTemplates: `${base.lane}/load-templates`,
  editLoadTemplate: (templateId: string) =>
    `${base.lane}/load-templates/${templateId}`,

  // Service Center
  getServiceCenterLayoutByCode: (serviceCenterCode: string) =>
    `${base.serviceCenter}/service-center-layouts/${serviceCenterCode}`,
  getServiceCenterByCode: (code: string) =>
    `${base.serviceCenter}/service-center/${code}`,
  editServiceCenter: (sicCode: string) =>
    `${base.serviceCenter}/service-center/${sicCode}`,
  getServiceCenters: `${base.serviceCenter}/service-centers`,
  putServiceCenterLayout: `${base.serviceCenter}/service-center-layouts`,
  getDoorNumbersBySicIdOld: (sidId: number) =>
    `${base.serviceCenter}/GetDoorNumbersBySicId/${sidId}`,
  getDoorNumbersBySicId: (sidId: number) =>
    `${base.inboundPlanning}/service-center/${sidId}/doors-usage-info`,
  getRegionsWithSics: `${base.linehaul}/region/service-centers`,
  createRegion: `${base.linehaul}/region`,
  updateRegion: (id: number) => `${base.linehaul}/region/${id}`,
  deleteRegion: (id: number) => `${base.linehaul}/region/${id}`,
  getSics: `${base.linehaul}/lh-service-centers`,
  getSicsWithRegion: `${base.linehaul}/service-center`,
  getSicByCode: (code: string) => `${base.linehaul}/lh-service-center/${code}`,
  getContactBySicCodeAndId: (code: string, contactId?: number) =>
    `${base.linehaul}/lh-service-center/${code}/contact/${contactId}`,
  addSicContact: (code: string) =>
    `${base.linehaul}/lh-service-center/${code}/contact`,
  unassignSic: (code: string) =>
    `${base.linehaul}/lh-service-center/${code}/unassign`,
  // Status
  getStatuses: "status-api/status",

  // Split IO API
  getMySegments: (userId: string) => `${base.splitIoApi}/mySegments/${userId}`,

  // Reports
  getBulkReport: `${base.reports}/bulk`,
} as const;

// TODO: Use this type to model our API endpoints via a consistent config to enforce consistency,
// streamline code, and support custom devtools.
// export type Endpoint<TResponse> = {
//   baseUrl: "old" | "new";
//   pathname: string;
//   search?: string;
//   method: "GET" | "POST" | "PUT" | "DELETE";
//   responseSchema: unknown;
//   mockResponses: MockResponse<TResponse>[];
// };

// type MockResponse<T> = {
//   status: number;
//   response: T;
// };

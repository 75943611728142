import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
  TextField as MuiTextField,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { Close, DoorSliding } from "@mui/icons-material";
import {
  FlexColumn,
  GappedFlexColumn,
} from "../../../components/shared/layout/Flex";
import { TextField } from "../../../components/shared/layout/TextInput";
import { useRoutes } from "../../../hooks/react-query/useRoutes";
import { useDrivers } from "../../../hooks/react-query/useDrivers";
import { useDoorsBySicIdAndPlanDate } from "../../../hooks/react-query/useDoors";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { useCreateRoute } from "../../../hooks/react-query/useDeliveryRoutes";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { toast } from "../../../utils/snackbarHelper";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { MAX_ROUTE_NAME_LENGTH } from "../../../constants/fieldNameLengthConstants";
import { toastMessage } from "../../../constants/strings";
import { mostFrequent } from "../../../utils/mostFrequent";
import { useShipmentDetailSearch } from "../../../hooks/react-query/preplanning/useShipmentDetails";
import Loading from "../../../components/shared/layout/Loading";
import UniqueShipmentIdCountMessage from "../../../components/shared/UniqueShipmentIdCountMessage";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { Route } from "../../../types/route.type";
import { TimePicker } from "../../../components/shared/TimePicker";
import { usePlanStartDate } from "../../../hooks/usePlanStartDate";
import { AutocompleteAvailabilityOptionOld } from "../../../components/shared/AutocompleteAvailabilityOptionOld";
import { getDoorOptionsFromDoors } from "./doorNumberUtilsOld";
import { SummaryContext, useActionsMenuContext } from "./ActionsMenuContext";
import CreateRouteSelect from "./CreateRouteSelect";
import ClosedStatusWarning from "./ClosedStatusWarning";
import { EquipmentSelectionOld } from "./EquipmentSelectionOld";
import { getDriverOptionsOld } from "./driverUtils";

const LOADING_BOX_HEIGHT = 407;
const DEFAULT_ROUTE_START_TIME = "08:00:00";
type ActiveStep = "First" | "Last";
type ValidationErrors = {
  presetRoute?: string;
  routeName?: string;
  startTime?: string;
};

interface DialogState {
  isPresetRouteSelected: boolean;
  presetRouteId: number;
  routeName: string;
  driverId: number;
  startTime: string | null;
  doorId: number;
  tractorEquipmentId: number | null;
  trailerEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
}

const initialState: DialogState = {
  startTime: null,
  presetRouteId: 0,
  doorId: 0,
  driverId: 0,
  routeName: "",
  isPresetRouteSelected: false,
  tractorEquipmentId: null,
  trailerEquipmentId: null,
  straightTruckEquipmentId: null,
};

interface AddRouteDialogOldProps {
  title: string;
  routeIntDate: number;
  presetRouteSelected?: number;
  routeNameSelected?: string;
  driverSelected?: number;
  tractorSelected: number | null;
  trailerSelected: number | null;
  straightTruckSelected: number | null;
  startTimeSelected?: string | null;
  selectedShipments: SummaryContext[];
  onCreatedRoute?: () => void;
  clearTableSelection?: () => void;
  onCancel: () => void;
}

const AddRouteDialogOld = ({
  title,
  routeIntDate,
  presetRouteSelected,
  routeNameSelected,
  driverSelected,
  tractorSelected,
  trailerSelected,
  straightTruckSelected,
  startTimeSelected,
  selectedShipments: assignmentsSelected,
  onCreatedRoute,
  clearTableSelection,
  onCancel,
}: AddRouteDialogOldProps) => {
  const [dialogState, setDialogState] = useState<DialogState>({
    ...initialState,
    driverId: driverSelected ?? initialState.driverId,
    startTime: startTimeSelected ?? initialState.startTime,
    tractorEquipmentId: tractorSelected ?? initialState.tractorEquipmentId,
    trailerEquipmentId: trailerSelected ?? initialState.trailerEquipmentId,
    straightTruckEquipmentId:
      straightTruckSelected ?? initialState.straightTruckEquipmentId,
    routeName: routeNameSelected ?? initialState.routeName,
    presetRouteId: presetRouteSelected ?? initialState.presetRouteId,
  });

  const {
    doorId,
    driverId,
    routeName,
    isPresetRouteSelected,
    startTime,
    presetRouteId,
    tractorEquipmentId,
    trailerEquipmentId,
    straightTruckEquipmentId,
  } = dialogState;

  const { planSummaries } = useActionsMenuContext();

  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const { data: doors = [] } = useDoorsBySicIdAndPlanDate(
    serviceCenter.id,
    planDate
  );
  const doorOptions = getDoorOptionsFromDoors(doors);

  const { data: routes = [], isLoading: isLoadingRoutes } = useRoutes(
    serviceCenter.id
  );

  const { data: drivers = [] } = useDrivers({
    isAvailable: true,
    serviceCenterId: serviceCenter.id,
  });
  const driverOptions = getDriverOptionsOld(drivers);

  const [preferredRoutes, setPreferredRoutes] = useState<Route[]>([]);

  const showClosedStatusWarning = planSummaries.some((plan) =>
    assignmentsSelected.some(
      (s) =>
        Number(s.id) === plan.id &&
        plan.type.includes(s.type) &&
        !plan.isAvailableForMassage
    )
  );

  const shipmentIds = assignmentsSelected.map((s) => s.shipmentIds).flat();

  const { data: shipments = [] } = useShipmentDetailSearch({
    search: {
      shipmentIds,
      sicId: serviceCenter.id,
      planDate,
    },
  });

  const getDefaultRouteStartTime = useCallback(
    (routeId: number) => {
      const date = routes.find((route) => route.id === routeId)?.startTime;

      return date && dayjs(date, "HH:mm:ss").isValid()
        ? dayjs(date, "HH:mm:ss").toISOString()
        : dayjs(DEFAULT_ROUTE_START_TIME, "HH:mm:ss").toISOString();
    },
    [routes]
  );

  useEffect(() => {
    if (!presetRouteId && shipments.length > 0 && routes.length > 0) {
      // The most probable preset route should be pre selected
      // First we get all the preset routes from each shipments, so that we have the preferred routes
      // Then, we select the most frequent route to act as the pre-selected route
      const shipmentsRoutesIds: number[] = shipments
        .map((shipment) => shipment.presetRouteId)
        .filter((id): id is number => id !== null);
      const mostFrequentPresetRouteId = mostFrequent(shipmentsRoutesIds);

      if (mostFrequentPresetRouteId) {
        const uniqueShipmentsRoutesIds = [...new Set(shipmentsRoutesIds)];
        setPreferredRoutes(
          routes.filter((route) => uniqueShipmentsRoutesIds.includes(route.id))
        );
        setDialogState((prevState) => ({
          ...prevState,
          isPresetRouteSelected: true,
          presetRouteId: mostFrequentPresetRouteId,
          startTime: getDefaultRouteStartTime(mostFrequentPresetRouteId),
        }));
      }
    }
  }, [
    getDefaultRouteStartTime,
    presetRouteId,
    routes,
    routes.length,
    shipments,
  ]);

  const [activeStep, setActiveStep] = useState<ActiveStep>("First");
  const [formValidationErrors, setFormValidationErrors] =
    useState<ValidationErrors>({});

  const validateForm = () => {
    const validationErrors: ValidationErrors = {};
    if (!isPresetRouteSelected) {
      validationErrors.presetRoute = "Preset Route is required";
    }
    if (routeName === "") {
      validationErrors.routeName = "Route Name is required";
    }
    if (startTime === null) {
      validationErrors.startTime = "Start Time is required";
    }
    setFormValidationErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;
    await createRoute.mutateAsync();
  };

  const onSuccess = () => {
    if (onCreatedRoute) onCreatedRoute();
    clearTableSelection?.();
    closeDialog();
    createRoute.reset();
  };

  const closeDialog = () => {
    onCancel();
    setDialogState(initialState);
  };

  const handleSelectChangeRoute = (event: SelectChangeEvent) => {
    setDialogState({
      ...dialogState,
      isPresetRouteSelected: true,
      presetRouteId: parseInt(event.target.value),
      startTime: getDefaultRouteStartTime(parseInt(event.target.value)),
    });
  };

  const planStartDate = usePlanStartDate({
    timeZone: serviceCenter.timeZone,
    planDate: routeIntDate,
    startTime,
    // Need to display the same value regardless of the timezone
    // ex. User in LA selects 8am for DAL. This means we want to store UTC 1pm (DAL) instead of UTC 3pm (LA)
    keepLocalTime: true,
  });

  const createRoute = useCreateRoute({
    createRouteRequest: {
      presetRouteId,
      name: routeName,
      driverId,
      tractorEquipmentId,
      trailerEquipmentId,
      straightTruckEquipmentId,
      startTimeTimestampUTC: planStartDate,
      unixTime: routeIntDate,
      sicId: serviceCenter.id,
      doorId,
      assignments: assignmentsSelected.map((assignment) => ({
        id: assignment.id,
        shipmentIds: assignment.shipmentIds,
        type: assignment.type,
        date: planDate,
        sicId: serviceCenter.id,
      })),
      isManual: true,
    },
    onSuccess: (shipmentsCount, routeName) => {
      toast(
        toastMessage.inbound.createRoute.success(routeName, shipmentsCount),
        { variant: "success" }
      );
      onSuccess();
    },
    onError: (e) => {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 400) {
          toast(toastMessage.generics.error, { variant: "error" });
          return;
        }

        if (e.response?.data === "ROUTE_ALREADY_EXISTS") {
          toast(toastMessage.inbound.createRoute.routeNameExists, {
            variant: "warning",
          });
          return;
        }

        if (e.response?.data === "EQUIPMENT_ALREADY_USED_IN_TODAYS_PLAN") {
          toast(toastMessage.inbound.createRoute.trailerAlreadyInPlan, {
            variant: "error",
          });
          return;
        }
      }
      toast(toastMessage.generics.error, { variant: "error" });
    },
  });

  return (
    <Dialog open>
      <form onSubmit={onSubmit}>
        <FlexColumn style={{ width: "60vw", maxWidth: "100%" }}>
          <DialogTitle
            sx={{ fontWeight: "bold", padding: "20px 0px 10px 20px" }}
          >
            {title}
          </DialogTitle>
          {showClosedStatusWarning && <ClosedStatusWarning />}
          <Box position="absolute" top={10} right={10}>
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            {activeStep === "Last" ? (
              <GappedFlexColumn gap={1}>
                <Typography fontSize={14} fontWeight={600} mb={1}>
                  Route Details
                </Typography>
                <TimePicker
                  label="Start Time *"
                  value={startTime ? dayjs(startTime) : null}
                  onChange={(date) => {
                    const isValidDate = date && date.isValid();
                    setDialogState({
                      ...dialogState,
                      startTime: isValidDate ? date.toISOString() : null,
                    });
                  }}
                  slotProps={{
                    textField: {
                      sx: { width: "100%" },
                      helperText: formValidationErrors.startTime,
                      error: !!formValidationErrors.startTime,
                    },
                  }}
                />
                <Autocomplete
                  disabled={!isPresetRouteSelected}
                  value={
                    doorOptions.find(
                      (d) => Number(d.value) === dialogState.doorId
                    ) ?? null
                  }
                  options={doorOptions}
                  onChange={(_, newValue) =>
                    setDialogState({
                      ...dialogState,
                      doorId: newValue?.value || initialState.doorId,
                    })
                  }
                  getOptionLabel={(driver) => driver.name}
                  renderInput={(params) => (
                    <MuiTextField {...params} label="Door Assignment" />
                  )}
                  groupBy={(option) => option.group}
                  renderOption={(props, option) => (
                    <AutocompleteAvailabilityOptionOld
                      key={option.name}
                      listItemProps={props}
                      name={option.name}
                      inUseByName={option.inUseBy?.name}
                      inUseByType={option.inUseBy?.type}
                      leftIcon={<DoorSliding />}
                    />
                  )}
                />
                <Autocomplete
                  disabled={!isPresetRouteSelected}
                  value={
                    driverOptions.find(
                      (d) => Number(d.value) === dialogState.driverId
                    ) ?? null
                  }
                  options={driverOptions}
                  onChange={(_, newValue) => {
                    setDialogState({
                      ...dialogState,
                      driverId: newValue?.value || initialState.driverId,
                    });
                  }}
                  getOptionLabel={(driver) => driver.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Driver"
                      label="Driver"
                    />
                  )}
                  groupBy={(option) => option.group}
                  renderOption={(props, option) => (
                    <AutocompleteAvailabilityOptionOld
                      key={`${option.name}-${option.value}`}
                      listItemProps={props}
                      name={option.name}
                    />
                  )}
                />
                <EquipmentSelectionOld
                  trailerId={trailerEquipmentId}
                  tractorId={tractorEquipmentId}
                  straightTruckId={straightTruckEquipmentId}
                  setTrailerValue={(newValue) =>
                    setDialogState((prev) => ({
                      ...prev,
                      trailerEquipmentId: newValue?.id || null,
                    }))
                  }
                  setStraightTruckValue={(newValue) =>
                    setDialogState((prev) => ({
                      ...prev,
                      straightTruckEquipmentId: newValue?.id || null,
                    }))
                  }
                  setTractorValue={(newValue) =>
                    setDialogState((prev) => ({
                      ...prev,
                      tractorEquipmentId: newValue?.id || null,
                    }))
                  }
                />
              </GappedFlexColumn>
            ) : (
              <>
                <UniqueShipmentIdCountMessage
                  shipments={assignmentsSelected.map((assignment) => ({
                    id: assignment.id,
                    shipmentIds: assignment.shipmentIds,
                    type: assignment.type,
                  }))}
                />
                {isLoadingRoutes ? (
                  <Box
                    height={LOADING_BOX_HEIGHT}
                    display="flex"
                    justifyContent="center"
                  >
                    <Loading />
                  </Box>
                ) : (
                  <GappedFlexColumn gap={1}>
                    <CreateRouteSelect
                      title="Route Information"
                      value={presetRouteId > 0 ? presetRouteId.toString() : ""}
                      relevantSelectOptions={preferredRoutes.map((route) => ({
                        name: route.name,
                        value: route.id,
                      }))}
                      allSelectOptions={routes
                        .filter(
                          (route) =>
                            !preferredRoutes.some((r) => r.id === route.id)
                        )
                        .map((route) => ({
                          name: route.name,
                          value: route.id,
                        }))}
                      enable
                      required
                      error={
                        formValidationErrors.presetRoute
                          ? formValidationErrors.presetRoute.length > 0
                          : false
                      }
                      helperText={formValidationErrors.presetRoute}
                      defaultText="Preset Route"
                      onChange={handleSelectChangeRoute}
                    />
                    <TextField
                      sx={{ width: "100%" }}
                      value={routeName}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setDialogState({
                          ...dialogState,
                          routeName: event.target.value,
                        })
                      }
                      id="route-name"
                      placeholder="Route Name"
                      required
                      inputProps={{ maxLength: MAX_ROUTE_NAME_LENGTH }}
                      error={
                        formValidationErrors.routeName
                          ? formValidationErrors.routeName.length > 0
                          : false
                      }
                      helperText={formValidationErrors.routeName}
                    />
                  </GappedFlexColumn>
                )}
              </>
            )}
          </DialogContent>
        </FlexColumn>
        <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
          {activeStep === "Last" ? (
            <>
              <Button variant="text" onClick={() => setActiveStep("First")}>
                Back
              </Button>
              <LoadingButton
                isLoading={createRoute.isPending}
                loadingLabel="Creating Route..."
              >
                Create Route
              </LoadingButton>
            </>
          ) : (
            <>
              <Button variant="text" onClick={closeDialog}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  if (validateForm()) {
                    setActiveStep("Last");
                  }
                }}
              >
                Next
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddRouteDialogOld;

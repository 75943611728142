import { CellContext } from "@tanstack/react-table";
import { Tag } from "../../types/freight/tag.type";
import { TagsGrid } from "../../components/shared/TagsGrid";

export function shipmentTagsColumn<TData>(accessorKey = "shipmentsTags") {
  return {
    accessorKey,
    header: "Shipment Tags",
    enableSorting: false,
    cell: ({ getValue }: CellContext<TData, unknown>) =>
      (getValue() as Tag[]).length > 0 ? (
        <TagsGrid tags={getValue() as Tag[]} />
      ) : (
        "-"
      ),
  };
}

import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { ArrowDropDown, Cancel, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  InputBase,
  Popper,
  Typography,
  colors,
  styled,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

// this component was copied from https://mui.com/material-ui/react-autocomplete/#system-GitHubLabel.tsx

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledFilterAutocompletePopper = styled("div")(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    marginRight: -2,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function FilterPopperComponent({
  disablePortal: _ignore,
  anchorEl: _ignore2,
  open: _ignore3,
  ...rest
}: PopperComponentProps) {
  return <StyledFilterAutocompletePopper {...rest} />;
}

const StyledFilterPopper = styled(Popper)(({ theme }) => ({
  border: "1px solid #e1e4e8",
  boxShadow: "0 8px 24px rgba(149, 157, 165, 0.2)",
  borderRadius: 6,
  width: 400,
  zIndex: theme.zIndex.modal,
}));

const StyledFilterInput = styled(InputBase)(() => ({
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: "4px",
  border:
    "1px solid var(--light-other-outlined-border-23-p, rgba(0, 0, 0, 0.23))",
  padding: "6px 8px",
}));

export type FilterProps = {
  initialOpen?: boolean;
  onRemove: (value: string) => void;
};

type FilterMultiselectProps<T> = FilterProps & {
  id: string;
  label: string;
  options: T[];
  value: T[];
  getDisplayValue: (value: T) => string;
  getSelectedDisplayValue: (selected: T[]) => string;
  onChange: (value: readonly T[]) => void;
};

type PropsWithKey = React.HTMLAttributes<HTMLLIElement> & { key?: React.Key };

export default function FilterMultiselect<T>({
  id,
  label,
  options,
  value,
  initialOpen = false,
  getDisplayValue,
  getSelectedDisplayValue,
  onChange,
  onRemove,
}: FilterMultiselectProps<T>) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();

  // this is necessary to make the popper open on first render
  useEffect(() => {
    if (initialOpen) setAnchorEl(anchorRef.current);
  }, [anchorRef, initialOpen]);

  const handleClose = () => {
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const filterId = `filter-${id}`;

  return (
    <React.Fragment>
      <Box display="flex" position="relative">
        <Button
          ref={anchorRef}
          color="inherit"
          aria-label={`Filter by ${label}`}
          aria-describedby={filterId}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            textTransform: "none",
            backgroundColor: open ? colors.blue[100] : undefined,
            ":hover": {
              backgroundColor: open ? colors.blue[100] : undefined,
            },
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Box display="flex" gap="1px">
              <Typography fontFamily="Roboto" fontSize={13} fontWeight={500}>
                {label}
              </Typography>
              <Typography fontFamily="Roboto" fontSize={13} fontWeight={500}>
                :
              </Typography>
              <Typography fontFamily="Roboto" fontSize={13} fontWeight={700}>
                {getSelectedDisplayValue(value)}
              </Typography>
            </Box>
            <ArrowDropDown fontSize="small" />
            <Box width="1rem" />
          </Box>
        </Button>
        <IconButton
          sx={{
            p: 0,
            position: "absolute",
            alignSelf: "center",
            right: 10,
          }}
          size="small"
          aria-label={`Remove ${label} filter`}
          title={`Remove ${label} filter`}
          onClick={(e) => {
            e.stopPropagation();
            if (label !== "Type") {
              onRemove(id);
            }
          }}
        >
          <Cancel color="action" fontSize="small" />
        </IconButton>
      </Box>
      <StyledFilterPopper
        id={filterId}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={(_, reason) => {
                if (reason === "escape") handleClose();
              }}
              value={value}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  "key" in event &&
                  event.key === "Backspace" &&
                  reason === "removeOption"
                ) {
                  return;
                }
                onChange(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={FilterPopperComponent}
              renderTags={() => null}
              noOptionsText="No results found"
              renderOption={(
                { key, ...props }: PropsWithKey,
                option,
                { selected }
              ) => (
                <li key={key} {...props}>
                  <Checkbox
                    size="small"
                    sx={{ mr: 1 }}
                    checked={selected}
                    content={getDisplayValue(option)}
                  />
                  {getDisplayValue(option)}
                </li>
              )}
              options={options}
              getOptionLabel={(option) => getDisplayValue(option)}
              renderInput={(params) => (
                <StyledFilterInput
                  ref={params.InputProps.ref}
                  endAdornment={
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  }
                  inputProps={params.inputProps}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  placeholder="Search"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledFilterPopper>
    </React.Fragment>
  );
}

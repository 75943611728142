import {
  TimePicker as MuiTimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { DaylightDateFormat } from "../../constants/DaylightDateFormat";

export const TimePicker = (props: TimePickerProps<Dayjs>) => (
  <MuiTimePicker
    timeSteps={{ hours: 1, minutes: 30 }}
    format={DaylightDateFormat.TIME_12}
    {...props}
  />
);

import dayjs from "dayjs";
import { env } from "../utils/env";

export const getNextAutoIncValue = (list: number[]) =>
  Math.max(...list.map((t) => t)) + 1;

// Trap names should be in the format TRAP-000001
export const getTrapNameFromId = (trapId: number) => {
  const trapNumberPadLength = 6;
  return `TRAP-${String(trapId).padStart(trapNumberPadLength, "0")}`;
};

/** msw throws a warning if the URL contains a queryParam, so this strips it and replaces it with a wildcard. */
export function stripQuery(url: string) {
  return url.split("?")[0];
}

/** HACK: Remove this once all URLs in apiUrls.ts consistently specify a leading slash.
 * Axios currently masks the bug of missing leading slashes in apiUrls.ts because it automatically adds a slash after the base URL.
 * But msw doesn't have that behavior, so we have to add missing slashes manually here for now.
 */
function addLeadingSlashIfMissing(url: string) {
  return url.startsWith("/") ? url : `/${url}`;
}

/** Strip query params and add a slash after the base URL if the provided URL lacks a leading slash. */
export function formatUrl(url: string) {
  return stripQuery(env.VITE_API_URL + addLeadingSlashIfMissing(url));
}

/** Returns the associated URL param */
export function getUrlParam(param: string) {
  return new URLSearchParams(window.location.search).get(param);
}

/** Utility function to get a business day start time */
export function getBusinessDayStart() {
  return dayjs()
    .set("hour", 8)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
}

/** Utility function to create a mock UseQueryResult */
export function createMockUseQueryResult<T>(data: T) {
  return {
    data,
    error: null,
    isLoading: false,
    isError: false,
    isSuccess: true,
    refetch: () => {},
    remove: () => {},
    status: "success",
    fetchStatus: "idle",
    isFetched: true,
    isFetchedAfterMount: true,
    isFetching: false,
    isIdle: false,
    isLoadingError: false,
    isPaused: false,
    isPlaceholderData: false,
    isPreviousData: false,
    isRefetchError: false,
    isRefetching: false,
    isStale: false,
    failureCount: 0,
    dataUpdatedAt: Date.now(),
    errorUpdatedAt: Date.now(),
    failureReason: null,
  };
}

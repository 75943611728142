import { GetPresetLanesResponse } from "../../hooks/react-query/outbound/usePresetLanes";

export const mockLanesList = {
  empty: [],
  default: [
    {
      laneId: 1,
      name: "LAX",
      sics: ["LAX", "SFO", "ONT"],
      cutTime: "09:00:00",
      carriers: ["ABC Transport"],
      destinationSic: "LAX",
      trailerType: "53' Trailer",
      isActive: true,
    },
    {
      laneId: 2,
      name: "SFO",
      sics: ["LAX", "SFO", "ONT"],
      cutTime: "09:00:00",
      carriers: ["BCD Transport"],
      destinationSic: "SFO",
      trailerType: "53' Trailer",
      isActive: true,
    },
  ],
} as const satisfies Record<string, GetPresetLanesResponse>;

import { useState } from "react";
import { DraggableLocation, DropResult } from "@hello-pangea/dnd";
import {
  AssignShipmentFn,
  AssignShipmentType,
  UnassignShipmentFn,
} from "../../../types/assignShipment.type";
import { useSelectionContext } from "../../../context/SelectionContext";
import {
  DragSourceOrDestination,
  parseDroppableId,
} from "../../../utils/dragging";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";

type Destination = DraggableLocation | null | undefined;

const validateDraggedLocation = (
  source: DraggableLocation,
  destination: Destination,
  id: DragSourceOrDestination,
  destinationId?: DragSourceOrDestination
) =>
  source.droppableId.split("-")[0] === id &&
  destination?.droppableId.split("-")[0] === (destinationId ?? id);

const itemDraggedInPlansTable = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "mainTable");

const itemDraggedInShipmentsTable = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "shipmentTable");

const itemDraggedInPlansGrid = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "mainGrid");

const itemDraggedFromDropzoneToTable = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "unassigned", "mainTable");

const itemDraggedFromTableToDropzone = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "mainTable", "unassigned");

const itemDraggedFromDropzoneToShipmentsTable = (
  source: DraggableLocation,
  destination: Destination
) =>
  validateDraggedLocation(source, destination, "unassigned", "shipmentTable");

const itemDraggedFromShipmentsTableToDropzone = (
  source: DraggableLocation,
  destination: Destination
) =>
  validateDraggedLocation(source, destination, "shipmentTable", "unassigned");

const itemDraggedFromGridToDropzone = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "mainGrid", "unassigned");

const itemDraggedFromSidebarToDropzone = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "sidebar", "unassigned");

const itemDraggedFromDropzoneToGrid = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "unassigned", "mainGrid");

const itemDraggedFromDropzoneToSidebar = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "unassigned", "sidebar");

const itemDraggedFromTableOrGridToSidebar = (
  source: DraggableLocation,
  destination: Destination
) =>
  validateDraggedLocation(source, destination, "mainGrid", "sidebar") ||
  validateDraggedLocation(source, destination, "mainTable", "sidebar");

const itemDraggedFromSidebarToTableOrGrid = (
  source: DraggableLocation,
  destination: Destination
) =>
  validateDraggedLocation(source, destination, "sidebar", "mainTable") ||
  validateDraggedLocation(source, destination, "sidebar", "mainGrid");

const itemDraggedFromSidebarToSidebar = (
  source: DraggableLocation,
  destination: Destination
) => validateDraggedLocation(source, destination, "sidebar", "sidebar");

type UseDragAndDropArgs = {
  handleAssignShipments?: AssignShipmentFn;
  handleUnassignShipments: UnassignShipmentFn;
  routes: PlanTableFormat[];
};

export const useInboundDragAndDrop = ({
  handleAssignShipments,
  handleUnassignShipments,
  routes,
}: UseDragAndDropArgs) => {
  const { selected } = useSelectionContext();
  const { selection } = useTableSelectionParam();
  const [dragAndDropErrorMessage, setDragAndDropErrorMessage] = useState("");

  const allDragAndDropAreAvailableForMassage = (
    destinationId: string,
    destinationType: AssignShipmentType
  ) => {
    if (destinationType === "route" || destinationType === "trap") {
      const route = routes.find(
        (route) => route.id === parseInt(destinationId)
      );
      if (!route) {
        throw new Error(
          `Route/trap with id ${destinationId} not found while drag and dropping.`
        );
      }
      return true;
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    const singleShipment = Number(draggableId.split("-")[0]);

    if (!destination || result.reason === "CANCEL") return;

    const destinationIsSameAsSource =
      destination.droppableId === source.droppableId;

    if (destinationIsSameAsSource) {
      return;
    }

    let shipmentIds =
      selected.length > 0
        ? selected.flatMap((trailer) => trailer.shipmentIds)
        : [singleShipment];
    if (selection.length > 0) {
      shipmentIds = [...selection.map(Number), singleShipment];
    }
    // Remove duplicates
    shipmentIds = shipmentIds.filter(
      (id, index, array) => array.indexOf(id) === index
    );

    if (
      itemDraggedInPlansTable(source, destination) ||
      itemDraggedInShipmentsTable(source, destination) ||
      itemDraggedInPlansGrid(source, destination)
    ) {
      const { type: destinationType, id: destinationId } = parseDroppableId(
        destination.droppableId
      );

      const { type: sourceType, id: sourceId } = parseDroppableId(
        source.droppableId
      );

      if (
        handleAssignShipments &&
        allDragAndDropAreAvailableForMassage(destinationId, destinationType)
      ) {
        handleAssignShipments({
          destinationType,
          destinationId,
          sourceId,
          sourceType,
          shipmentIds,
        });
      }
    }

    if (
      itemDraggedFromDropzoneToTable(source, destination) ||
      itemDraggedFromDropzoneToShipmentsTable(source, destination) ||
      itemDraggedFromDropzoneToGrid(source, destination) ||
      itemDraggedFromDropzoneToSidebar(source, destination)
    ) {
      const { type: destinationType, id: destinationId } = parseDroppableId(
        destination.droppableId
      );

      const { type: sourceType, id: sourceId } = parseDroppableId(draggableId);

      if (
        handleAssignShipments &&
        allDragAndDropAreAvailableForMassage(destinationId, destinationType)
      ) {
        handleAssignShipments({
          destinationType,
          destinationId,
          sourceId,
          sourceType,
          shipmentIds:
            shipmentIds.length > 0 ? shipmentIds : [parseInt(sourceId)],
        });
      }
    }

    if (
      itemDraggedFromTableToDropzone(source, destination) ||
      itemDraggedFromShipmentsTableToDropzone(source, destination) ||
      itemDraggedFromGridToDropzone(source, destination) ||
      itemDraggedFromSidebarToDropzone(source, destination)
    ) {
      const { type: sourceType, id: sourceId } = parseDroppableId(
        source.droppableId
      );

      handleUnassignShipments({
        sourceId,
        sourceType,
        shipmentIds,
      });
    }

    if (
      itemDraggedFromTableOrGridToSidebar(source, destination) ||
      itemDraggedFromSidebarToTableOrGrid(source, destination) ||
      itemDraggedFromSidebarToSidebar(source, destination)
    ) {
      const { type: destinationType, id: destinationId } = parseDroppableId(
        destination.droppableId
      );
      const { type: sourceType, id: sourceId } = parseDroppableId(
        source.droppableId
      );

      if (
        handleAssignShipments &&
        allDragAndDropAreAvailableForMassage(destinationId, destinationType)
      ) {
        handleAssignShipments({
          destinationType,
          destinationId,
          sourceId,
          sourceType,
          shipmentIds,
        });
      }
    }
  };

  return {
    onDragEnd,
    dragAndDropErrorMessage,
    resetDragAndDropError: () => setDragAndDropErrorMessage(""),
  };
};

import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IconButton, Stack, useTheme } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Filter } from "../../../../types/filter";
import { SearchBox } from "../../../../components/shared/SearchBox";
import { getActiveAndInactiveFilters } from "../../../../utils/filters";
import { FlexRow } from "../../../../components/shared/layout/Flex";
import FilterMultiselect from "../../../../components/shared/FilterMultiselect";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { AddFilterMenu } from "./AddFilterMenu";

import QuickFilter from "./QuickFilter";
import { ETAFilter } from "./ETAFilter";

type FiltersProps = {
  filters: Filter[];
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  placeholder?: string;
  showEta?: boolean;
  filterSectionId?: string;
  hasQuickTypeFilter?: boolean;
};

export const getTypeFilter = (filters: Filter[]): Filter => {
  const typeFilter = filters.find((filter) => filter.label === "Type");
  if (!typeFilter) {
    throw new Error(
      "Type filter not found. Please ensure the filter is provided."
    );
  }
  return typeFilter;
};

const getETAFilter = (filters: Filter[]): Filter => {
  const etaFilter = filters.find((filter) => filter.label === "ETA");
  if (!etaFilter) {
    throw new Error(
      "ETA filter not found. Please ensure the filter is provided."
    );
  }
  return etaFilter;
};

const Filters = ({
  filters,
  searchTerm,
  setSearchTerm,
  placeholder = "Search",
  showEta = false,
  filterSectionId,
  hasQuickTypeFilter = false,
}: FiltersProps) => {
  const isNewTableDesignEnabled = useIsFeatureFlagEnabled(
    "inbound-new-table-design-client"
  );
  const filtersWrapper = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { activeFilters, inactiveFilters } = getActiveAndInactiveFilters(
    filters.filter((filter) =>
      hasQuickTypeFilter
        ? filter.label !== "Type"
        : showEta
          ? filter.label !== "ETA"
          : true
    ),
    searchParams
  );

  const [lastFilterAdded, setLastFilterAdded] = useState<Filter>();

  const scrollStepInPixels = 10;
  const minFiltersToShowScroll = 4;
  const [showScrollButtons, setShowScrollButtons] = useState({
    left: false,
    right: false,
  });

  useEffect(
    function setScrollButtonVisibilityWhenActiveFiltersChange() {
      if (activeFilters.length >= minFiltersToShowScroll) {
        setShowScrollButtons({ left: true, right: true });
      } else {
        setShowScrollButtons({ left: false, right: false });
      }
    },
    [activeFilters.length]
  );

  const sideScroll = (step: number) => {
    const element = filtersWrapper.current;
    if (element === null) return;
    const speed = 10;
    const distance = 200;

    const maxPosition = element.scrollWidth - element.clientWidth;
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
        setShowScrollButtons({
          left: element.scrollLeft !== 0,
          right: element.scrollLeft !== maxPosition,
        });
      }
    }, speed);
  };

  const filteredActiveFilters = hasQuickTypeFilter
    ? activeFilters.filter((filter) => filter.label !== "Type")
    : activeFilters;

  return (
    <FlexRow
      style={{ gap: "1.5rem", alignItems: "flex-end", padding: "0.5rem 0" }}
    >
      <SearchBox
        searchTerm={searchTerm}
        onValueChange={setSearchTerm}
        placeholder={placeholder}
      />
      {showEta && <ETAFilter filter={getETAFilter(filters)} />}
      {!isNewTableDesignEnabled && hasQuickTypeFilter && (
        <QuickFilter filter={getTypeFilter(filters)} />
      )}

      <Stack
        direction="row"
        sx={{
          overflow: "hidden",
          gap: "1.5rem",
        }}
        ref={filtersWrapper}
      >
        {showScrollButtons.left && (
          <ScrollButton
            onClick={() => {
              sideScroll(-scrollStepInPixels);
            }}
            direction="left"
          />
        )}
        {filteredActiveFilters.map((filter) => {
          const activeOptions = searchParams.getAll(filter.queryParam);
          const selectedOptions = filter.options.filter((option) =>
            activeOptions.includes(option.value.toString())
          );
          return (
            <FilterMultiselect
              key={filter.label}
              id={filter.label}
              label={filter.label}
              options={filter.options}
              value={selectedOptions}
              initialOpen={lastFilterAdded?.label === filter.label}
              getDisplayValue={(option) => `${option.label || "All"}`}
              getSelectedDisplayValue={(selected) =>
                selected.length === 0
                  ? "All"
                  : selected.length === 1
                    ? selected[0].label.toString()
                    : `${selected[0].label} (${selected.length})`
              }
              onChange={(options) => {
                setSearchParams((old) => {
                  // Extract the existing parameters into an ordered array
                  const prevParams = Array.from(old.entries());

                  // Capture the original order of parameter keys
                  const paramOrder: string[] = [];
                  prevParams.forEach(([key]) => {
                    if (!paramOrder.includes(key)) {
                      paramOrder.push(key);
                    }
                  });

                  // Map of parameters to their values
                  const paramValues = new Map();
                  prevParams.forEach(([key, value]) => {
                    if (!paramValues.has(key)) {
                      paramValues.set(key, []);
                    }
                    paramValues.get(key).push(value);
                  });

                  // Update the values for the filter parameter
                  if (options.length === 0) {
                    paramValues.set(filter.queryParam, ["All"]);
                  } else {
                    const newValues = options.map((o) => o.value.toString());
                    paramValues.set(filter.queryParam, newValues);
                  }

                  // Reconstruct the URLSearchParams while maintaining the original order
                  const newParams = new URLSearchParams();
                  const processedKeys = new Set();

                  // Add parameters back in their original order
                  paramOrder.forEach((key) => {
                    const values = paramValues.get(key);
                    if (values) {
                      values.forEach((value: string) => {
                        newParams.append(key, value);
                      });
                      processedKeys.add(key);
                    }
                  });

                  // Append any new parameters that were not in the original URL
                  paramValues.forEach((values, key) => {
                    if (!processedKeys.has(key)) {
                      values.forEach((v: string) => {
                        newParams.append(key, v);
                      });
                    }
                  });

                  return newParams;
                });
              }}
              onRemove={() => {
                setSearchParams((searchParams) => {
                  searchParams.delete(filter.queryParam);
                  return searchParams;
                });
              }}
            />
          );
        })}
        <AddFilterMenu
          onAddFilter={(filter: Filter) => {
            setSearchParams((params) => {
              params.set(filter.queryParam, "All");
              return params;
            });
            setLastFilterAdded(filter);
          }}
          filters={inactiveFilters}
          filterSectionId={filterSectionId}
        />
        {showScrollButtons.right && (
          <ScrollButton
            onClick={() => {
              sideScroll(scrollStepInPixels);
            }}
            direction="right"
          />
        )}
      </Stack>
    </FlexRow>
  );
};

type ScrollButtonProps = {
  onClick: () => void;
  direction: "left" | "right";
};

function ScrollButton({ onClick, direction }: ScrollButtonProps) {
  const theme = useTheme();

  return (
    <IconButton
      aria-label={`Scroll filter list ${direction}`}
      sx={{
        height: 34,
        width: 34,
        borderRadius: 16,
        boxShadow: 3,
        position: "sticky",
        marginX: "-17px",
        [direction]: 5,
        top: 5,
        zIndex: 2,
        backgroundColor: theme.palette.grey[200],
        ":hover": {
          backgroundColor: theme.palette.grey[300],
        },
      }}
      onClick={onClick}
    >
      {direction === "left" ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  );
}

export default Filters;

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Table } from "@tanstack/react-table";
import { useTheme } from "@mui/material";
import BaseTableSortLabel, { getSortDirection } from "./BaseTableSortLabel";

type Props<TData> = {
  table: Table<TData>;
};

const BaseTableHeader = <TData,>({ table }: Props<TData>) => {
  const theme = useTheme();

  return (
    <TableHead
      sx={{
        "& .MuiTableCell-head": {
          color: "white",
          backgroundColor: theme.palette.primary.main,
          fontWeight: 600,
        },
        "& .MuiButtonBase-root": {
          color: "white !important",
        },
        "& .MuiTableSortLabel-icon": {
          color: "white !important",
        },
      }}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              colSpan={header.colSpan}
              sortDirection={getSortDirection(header.column.getIsSorted())}
            >
              <BaseTableSortLabel header={header} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default BaseTableHeader;

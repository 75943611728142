// Cspell:ignore plts appt
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { shipmentTagsColumn } from "../../../utils/tableColumns/commons";

import {
  sortDatesAsc,
  sortNumbersAsc,
  sortRowByPresetRoute,
  sortServiceDue,
  sortShipmentsByAssignment,
  sortTimesAsc,
} from "../../../utils/sortComparator";
import {
  formatAppointmentDateTableField,
  formatAppointmentTimeTableField,
  formatEtaTableField,
  formatServiceDueTableField,
} from "../../../utils/tableFormat";
import { dashIfEmpty, formatNumber } from "../../../constants/utils";
import AssignmentText from "../../../pages/inbound/prePlanning/AssignmentText";
import { TableShipment } from "../old-table/ShipmentsTableBase";
import PlannedShipmentStatusTag from "../StatusTag/PlannedShipmentStatusTag";

type ShipmentColumnsArgs = {
  timeZone: string;
  showPresetRouteColumn: boolean;
  selectedDate: string;
};

export const shipmentColumns = ({
  timeZone,
  showPresetRouteColumn,
  selectedDate,
}: ShipmentColumnsArgs): ColumnDef<TableShipment, any>[] => [
  {
    accessorKey: "proNumber",
    header: "PRO",
  },
  ...(showPresetRouteColumn
    ? [
        {
          accessorKey: "presetRouteName",
          header: "Pre-Set Route",
          accessorFn: ({ presetRouteName }: { [key: string]: any }) =>
            dashIfEmpty(presetRouteName),
          sortingFn: sortRowByPresetRoute,
        },
      ]
    : []),
  {
    accessorKey: "destCity",
    header: "Dest City, Zip",
    accessorFn: ({ consignee }) =>
      `${dashIfEmpty(consignee?.city)}, ${dashIfEmpty(consignee?.zipCode)}`,
  },
  {
    accessorKey: "serviceDue",
    header: "Service Due",
    accessorFn: ({ serviceDue }) => formatServiceDueTableField(serviceDue),
    sortingFn: sortServiceDue,
  },
  {
    accessorKey: "appointmentDate",
    header: "Appt Date",
    accessorFn: ({ appointmentDate }) =>
      formatAppointmentDateTableField(appointmentDate, timeZone),
    sortingFn: (rowA, rowB) =>
      sortDatesAsc(
        rowA.original.appointmentDate,
        rowB.original.appointmentDate
      ),
  },
  {
    accessorKey: "appointmentTime",
    header: "Appt Time",
    accessorFn: ({
      appointmentDate: appointmentStartDate,
      appointmentEndDate,
    }) =>
      formatAppointmentTimeTableField(
        appointmentStartDate,
        appointmentEndDate,
        timeZone
      ),
    sortingFn: (rowA, rowB) =>
      sortTimesAsc(
        rowA.original.appointmentDate,
        rowB.original.appointmentDate
      ),
  },
  {
    accessorKey: "consignee",
    header: "Consignee",
    accessorFn: ({ consignee }) => dashIfEmpty(consignee?.name),
  },
  {
    accessorKey: "shipper",
    header: "Shipper",
    accessorFn: ({ shipper }) => dashIfEmpty(shipper?.name),
  },
  {
    accessorKey: "pltsPcs",
    header: "Plts | Pcs",
    accessorFn: ({ pallets, pieces }) =>
      `${formatNumber(pallets)} | ${formatNumber(pieces)}`,
    cell: ({ getValue }) => (
      <div style={{ minWidth: "90px" }}>{getValue()}</div>
    ),
    sortingFn: (rowA, rowB) =>
      sortNumbersAsc(rowA.original.pallets, rowB.original.pallets),
    sortDescFirst: false,
  },
  {
    accessorKey: "weight",
    header: "Weight",
    accessorFn: ({ weight, weightUnit }) =>
      `${formatNumber(weight)} ${weightUnit}`,
    sortingFn: (rowA, rowB) =>
      sortNumbersAsc(rowA.original.weight, rowB.original.weight),
  },
  shipmentTagsColumn("tags"),
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ getValue, row }: CellContext<TableShipment, string>) =>
      getValue() ? (
        <PlannedShipmentStatusTag
          label={getValue<string>()}
          isPlannedForToday={row.original.isPlanned}
          futurePlanDates={row.original.futurePlanDates}
          selectedDate={selectedDate}
        />
      ) : null,
  },
  {
    accessorKey: "location",
    header: "Trailer #",
    accessorFn: ({ location }) => dashIfEmpty(location),
  },
  {
    accessorKey: "sic",
    header: "Dest SIC",
    sortDescFirst: false,
  },
  {
    accessorKey: "etaDate",
    header: "ETA",
    accessorFn: ({ etaDate }) => formatEtaTableField(etaDate, timeZone),
    sortingFn: (rowA, rowB) =>
      sortDatesAsc(rowA.original.etaDate, rowB.original.etaDate),
  },
  {
    accessorKey: "assignment",
    header: "Assignment",
    sortingFn: (rowA, rowB) =>
      sortShipmentsByAssignment(
        rowA.original.assignment,
        rowB.original.assignment
      ),
    cell: ({ getValue }: CellContext<TableShipment, string>) => (
      <AssignmentText text={getValue()} />
    ),
  },
];

import { useCallback } from "react";
import { SummaryContext } from "../pages/inbound/shared/ActionsMenuContext";
import { Trap } from "../types/planning/trap.type";
import { RouteSummary } from "../services/prePlanningService.types";
import { PlanTableFormat } from "../types/planning/plan.type";

export type ParentRecords = Trap[] | RouteSummary[] | PlanTableFormat[];

type ValidateMergeRequestArgs = {
  selectedRecords: SummaryContext[];
  parentRecords: ParentRecords;
};

type ParentRecordIdShipmentCountMap = Record<string, number>;

export type ValidateMergeRequestResult =
  | ""
  | "noIndividualShipmentsAllowed"
  | "selectAtLeastTwo";

export const validateMergeRequest: (
  args: ValidateMergeRequestArgs
) => ValidateMergeRequestResult = ({
  selectedRecords,
  parentRecords,
}): ValidateMergeRequestResult => {
  const selectedShipmentsCountByParentRecord =
    selectedRecords.reduce<ParentRecordIdShipmentCountMap>(
      (acc, selectedParent) => {
        acc[selectedParent.id] = selectedParent.shipmentIds.length;
        return acc;
      },
      {}
    );

  const allShipmentsCountByParentRecord =
    parentRecords.reduce<ParentRecordIdShipmentCountMap>((acc, parent) => {
      acc[parent.id] = parent.shipmentIds.length;
      return acc;
    }, {});

  const individualShipmentsAreSelected = Object.keys(
    allShipmentsCountByParentRecord
  ).some(
    (id) =>
      allShipmentsCountByParentRecord[id] >
      selectedShipmentsCountByParentRecord[id]
  );
  if (individualShipmentsAreSelected) {
    return "noIndividualShipmentsAllowed";
  }

  const minimumAmountOfRecordsAreSelected = selectedRecords.length >= 2;
  if (!minimumAmountOfRecordsAreSelected) {
    return "selectAtLeastTwo";
  }

  return "";
};

export function useValidateMergeRequest(
  selectedRecords: SummaryContext[],
  parentRecords: ParentRecords
) {
  const validateMergeRequestCallback = useCallback(
    (): ValidateMergeRequestResult =>
      validateMergeRequest({
        selectedRecords,
        parentRecords,
      }),
    [selectedRecords, parentRecords]
  );

  return { validateMergeRequest: validateMergeRequestCallback };
}

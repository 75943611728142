import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import pluralize from "pluralize";
import { Trap } from "../../../types/planning/trap.type";
import { FlexRow } from "../../../components/shared/layout/Flex";
import {
  useDeleteTraps,
  useInvalidateTraps,
} from "../../../hooks/react-query/preplanning/useTraps";
import { toast } from "../../../utils/snackbarHelper";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { toastMessage } from "../../../constants/strings";
import { useActionsMenuContext } from "../shared/ActionsMenuContext";
import { Route } from "../../../services/prePlanningService.types";
import { PrePlanningSectionCanDoDelete } from "../../../types/inbound/prePlanning/sections.type";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { useDeleteRouteAssignment } from "../../../hooks/react-query/useRouteAssignment";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";

type DeleteDialogProps = {
  activeTab: PrePlanningSectionCanDoDelete;
  selectedTraps: Trap[];
  selectedRoutes: Route[];
  clearSelection: () => void;
};
const DeleteDialog = ({
  activeTab,
  selectedTraps,
  selectedRoutes,
  clearSelection,
}: DeleteDialogProps) => {
  const { activeDialog, closeDialog } = useActionsMenuContext();
  const invalidateTraps = useInvalidateTraps();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const dateInt = dateToInt(selectedDate);
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("searchTerm") ?? "";
  const debouncedSearch = useDebouncedSearch(searchTerm);

  const selectedTrapNames = `"${selectedTraps
    .map((trap) => trap.name)
    .join('", "')}"`;
  const selectedRouteNames = `"${selectedRoutes
    .map((route) => route.name)
    .join('", "')}"`;

  const dialogTitle = () =>
    activeTab === "routes"
      ? `Delete ${pluralize(
          "Route",
          selectedRoutes.length
        )} ${selectedRouteNames}`
      : `Delete ${pluralize(
          "Trap",
          selectedTraps.length
        )} ${selectedTrapNames}`;

  const dialogContent = () =>
    activeTab === "routes"
      ? `Are you sure you want to delete ${pluralize(
          "route",
          selectedRoutes.length
        )}? This cannot be undone.`
      : `Are you sure you want to delete ${pluralize(
          "trap",
          selectedTraps.length
        )}? This cannot be undone.`;

  const deleteButtonLabel = (actionVerb: string) =>
    activeTab === "routes"
      ? `${actionVerb} ${pluralize("Route", selectedRoutes.length)}`
      : `${actionVerb} ${pluralize("Trap", selectedTraps.length)}`;

  const onDeleteRouteSuccess = () => {
    closeDialog();
    clearSelection();
    toast(
      toastMessage.inbound.deleteRoute.success(
        selectedRouteNames,
        selectedRoutes.length
      ),
      {
        variant: "success",
      }
    );
  };

  const { mutate: deleteRoute, isPending: isDeleteRoutePending } =
    useDeleteRouteAssignment({
      date: dateInt,
      sicId: serviceCenter.id,
      searchTerm: debouncedSearch,
      onSuccess: onDeleteRouteSuccess,
    });

  const deleteRouteAssignmentFromTable = (planIds: number[]) => {
    deleteRoute({ planIds });
  };

  const { mutate: deleteTrap, isPending: isDeleteTrapPending } = useDeleteTraps(
    () => {
      closeDialog();
      clearSelection();
      invalidateTraps();
      toast(
        toastMessage.inbound.deleteTrap.success(
          selectedTrapNames,
          selectedTraps.length
        ),
        {
          variant: "success",
        }
      );
    }
  );

  const handleOnDelete = () => {
    switch (activeTab) {
      case "traps":
        deleteTrap(selectedTraps);
        break;
      case "routes":
        if (selectedRoutes.length > 0) {
          deleteRouteAssignmentFromTable(selectedRoutes.map((x) => x.id));
        }
        break;
    }
  };

  return (
    <Dialog
      open={activeDialog === "delete-route" || activeDialog === "delete-trap"}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        <FlexRow>{dialogTitle()}</FlexRow>
      </DialogTitle>
      <DialogContent sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
        <FlexRow>{dialogContent()}</FlexRow>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            width: "117px",
            height: "36px",
            marginY: "8px",
            borderRadius: "4px",
          }}
          variant="outlined"
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={{
            width: "149px",
            height: "36px",
            marginY: "8px",
            borderRadius: "4px",
          }}
          color="error"
          isLoading={
            activeTab === "routes" ? isDeleteRoutePending : isDeleteTrapPending
          }
          loadingLabel={deleteButtonLabel("Deleting")}
          onClick={handleOnDelete}
        >
          {deleteButtonLabel("Delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;

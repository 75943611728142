import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { ArrowForwardIos } from "@mui/icons-material";
import StatusTag from "../../../../components/shared/StatusTag/StatusTag";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { DaylightDateFormat } from "../../../../constants/DaylightDateFormat";
import { formatDateTimeTableField } from "../../../../utils/tableFormat";
import { equipmentTypeMap } from "../../../../types/equipment.type";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import {
  MergeIntoType,
  MergeRoutesTrapsDialogAction,
  MergeRoutesTrapsDialogState,
} from "./MergeRoutesTrapsDialogReducer";

type MergeRoutesTrapsStepOneOldProps = {
  dialogState: MergeRoutesTrapsDialogState;
  dispatch: (value: MergeRoutesTrapsDialogAction) => void;
  selectedTraps: RouteOrTrapOld[];
  selectedRoutes: RouteOrTrapOld[];
  plannedRoutes: PlanTableFormat[];
  handleNext: (shouldBypassValidation: boolean) => void;
};

type RouteOrTrapOld = {
  id: number;
  name: string;
  status: string;
  type: MergeIntoType;
};

export const MergeRoutesTrapsStepOneOld = ({
  dialogState,
  dispatch,
  selectedTraps,
  selectedRoutes,
  plannedRoutes,
  handleNext,
}: MergeRoutesTrapsStepOneOldProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const { mergeIntoType } = dialogState;

  const mergeIntoOptions =
    mergeIntoType === "trap" ? selectedTraps : selectedRoutes;

  const handleSelectRouteOrTrap = (id: number) => {
    const destination = plannedRoutes.find((r) => r.id === id);

    if (!destination) throw new Error("Destination id not found: " + id);

    dispatch({
      type: "set_mergeDestination",
      mergeDestination: {
        id: destination.id,
        doorId: destination.door,
        driverId: destination.driver?.id ?? null,
        planDate: destination.date,
        status: destination.status,
        startTime: destination.startTime
          ? dayjs(
              formatDateTimeTableField(
                destination.startTime,
                DaylightDateFormat.DATE_TIME_12,
                serviceCenter.timeZone
              )
            ).toISOString()
          : null,
        trailerId: destination.trailer?.id ?? null,
        straightTruckId: destination.straightTruck?.id ?? null,
        tractorId: destination.straightTruck?.id ?? null,
        customName: destination.name,
        originalName: destination.name,
      },
      equipmentTypeId: destination.trailer?.id
        ? equipmentTypeMap.trailer
        : destination.straightTruck?.id
          ? equipmentTypeMap.straightTruck
          : null,
    });
  };

  const isMergingBothRoutesAndTraps =
    selectedRoutes.length > 0 && selectedTraps.length > 0;

  return (
    <Box>
      <Typography ml="24px" mb="4px">
        Choose where to merge the selected routes/traps
      </Typography>
      {!isMergingBothRoutesAndTraps ? (
        <Box sx={{ borderTop: 1, borderColor: "divider" }}>
          <Typography
            mt="4px"
            ml="24px"
            fontSize="small"
            color={(theme) => theme.palette.grey[600]}
          >
            {selectedTraps.length > 0 && selectedRoutes.length === 0
              ? "Traps"
              : "Routes"}
          </Typography>
        </Box>
      ) : (
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={mergeIntoType}
          onChange={(_, value) =>
            dispatch({ type: "set_mergeIntoType", mergeIntoType: value })
          }
        >
          <Tab label="Routes" value="route" sx={{ width: "40%" }} />
          <Tab label="Traps" value="trap" sx={{ width: "40%" }} />
        </Tabs>
      )}
      <List
        component="nav"
        sx={{
          overflowY: "auto",
          height: isMergingBothRoutesAndTraps ? 208 : 236,
        }}
      >
        {mergeIntoOptions.map((option) => (
          <Box
            display="flex"
            flexDirection="row"
            key={option.id}
            sx={{
              ".arrow-icon-button": {
                visibility: "hidden",
              },
              ":hover": {
                ".arrow-icon-button": {
                  visibility: "visible",
                },
              },
            }}
          >
            <ListItemButton
              selected={option.id === dialogState.mergeDestination.id}
              onClick={(_) => {
                handleSelectRouteOrTrap(option.id);
              }}
            >
              <ListItemText
                sx={{
                  marginLeft: isMergingBothRoutesAndTraps ? "16px" : "24px",
                }}
                primary={option.name}
              />
              <Box mr={8}>
                <StatusTag label={option.status} isPlannedFor="today" />
              </Box>
            </ListItemButton>
            <Box position="absolute" right="15px" mt={1}>
              <IconButton
                className="arrow-icon-button"
                aria-label={`Merge into ${option.name} ${option.type}`}
                onClick={() => {
                  handleSelectRouteOrTrap(option.id);
                  handleNext(true);
                }}
              >
                <ArrowForwardIos fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </List>
    </Box>
  );
};

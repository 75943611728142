import TableBody, { TableBodyProps } from "@mui/material/TableBody";
import { Row, Table } from "@tanstack/react-table";
import { ForwardedRef, forwardRef, Fragment, ReactNode } from "react";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import { ErrorMap } from "../table/table.types";
import { SelectedTableRows } from "./TableSelectionContext";
import BaseTableRowDefinition from "./BaseTableRowDefinition";
import { RowSelectionMode } from "./Selectable.types";

export type TableType =
  | {
      /** type of the table */
      tableType: "trip";
      parentId?: never;
    }
  | {
      /** type of the table */
      tableType: "shipment";
      /** The parent row record `row.id`, if `tableType` is `shipment`. */
      parentId: string;
    };

type Props<TData> = {
  table: Table<TData>;
  collapsibleChild?: (row: Row<TData>) => ReactNode;
  isDraggable?: boolean;
  droppableId?: (row: Row<TData>) => string;
  rowSelectionMode: RowSelectionMode;
  contextMenuActions?: (row?: SelectedTableRows<TData>) => ContextMenuAction[];
  /** Map of row id to row error. */
  rowErrors?: ErrorMap;
} & TableType &
  Omit<TableBodyProps, "placeholder"> & {
    placeholder?: ReactNode | null;
  };

const BaseTableBody = forwardRef(
  <TData,>(
    {
      table,
      tableType,
      parentId,
      collapsibleChild,
      isDraggable = false,
      droppableId,
      rowSelectionMode = "unselectable",
      contextMenuActions,
      rowErrors,
      ...props
    }: Props<TData>,
    ref: ForwardedRef<HTMLTableSectionElement>
  ) => (
    <TableBody {...props} ref={ref}>
      {table.getRowModel().rows.map((row, index) => (
        <Fragment key={row.id}>
          {tableType === "trip" ? (
            <BaseTableRowDefinition
              table={table}
              tableType={tableType}
              row={row}
              index={index}
              rowSelectionMode={rowSelectionMode}
              isDraggable={isDraggable}
              droppableId={droppableId}
              collapsibleChild={collapsibleChild}
              contextMenuActions={contextMenuActions}
              rowError={rowErrors?.get(Number(row.id))}
            />
          ) : (
            <BaseTableRowDefinition
              table={table}
              tableType={tableType}
              parentId={parentId}
              row={row}
              index={index}
              rowSelectionMode={rowSelectionMode}
              isDraggable={isDraggable}
              droppableId={droppableId}
              collapsibleChild={collapsibleChild}
              contextMenuActions={contextMenuActions}
              rowError={rowErrors?.get(Number(row.id))}
            />
          )}
        </Fragment>
      ))}
    </TableBody>
  )
);

BaseTableBody.displayName = "BaseTableBody";

export default BaseTableBody as <TData>(
  props: Props<TData> & { ref?: ForwardedRef<HTMLTableSectionElement> }
) => ReturnType<typeof BaseTableBody>;

import { forwardRef, Ref, useImperativeHandle, useMemo, useState } from "react";
import {
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import pluralize from "pluralize";
import { AssignShipmentType } from "../../../types/assignShipment.type";
import { LoadingButton } from "../../../components/shared/LoadingButton";

export type ShipmentToMove = {
  destination: {
    name: string;
    status?: string;
    type: AssignShipmentType;
  };
  source: {
    name: string;
    status: string;
    type: AssignShipmentType;
  };
  shipmentIds: number[];
  isDroppedOnHeader?: boolean;
  rollbackMessage?: {
    toDestination: boolean;
    toSource: boolean;
  };
  onConfirm: () => void;
};

export type ConfirmShipmentMoveDialogRef = {
  open: (shipmentToMove: ShipmentToMove | null) => void;
  close: () => void;
};

type ConfirmShipmentMoveDialogProps = {
  isLoading?: boolean;
};

export const ConfirmShipmentMoveDialog = forwardRef(
  (
    { isLoading = false }: ConfirmShipmentMoveDialogProps,
    ref?: Ref<ConfirmShipmentMoveDialogRef>
  ) => {
    const [shipmentToMove, setShipmentToMove] =
      useState<ShipmentToMove | null>();

    const onClose = (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      event.preventDefault();
      setShipmentToMove(null);
    };

    useImperativeHandle(ref, () => ({
      open: (shipment) => {
        if (shipment?.rollbackMessage) {
          setShipmentToMove(shipment);
        }
      },
      close: () => {
        setShipmentToMove(null);
      },
    }));

    const confirmationMessage = useMemo(() => {
      if (shipmentToMove?.rollbackMessage === undefined) return;

      const {
        rollbackMessage: { toSource, toDestination },
        shipmentIds,
        source,
        destination,
      } = shipmentToMove;
      const shipmentText =
        shipmentIds.length > 0
          ? pluralize("shipment", shipmentIds.length, true)
          : "";
      let message = `Moving ${shipmentText}`;
      const sourceStatusLabel = !source.status ? "" : `(${source.status})`;

      if (toSource && toDestination) {
        message += ` from the "${source.name}" ${source.type} ${sourceStatusLabel} 
          to the "${destination.name}" ${destination.type} (${destination.status}) will change both statuses`;
      } else if (toDestination) {
        message += ` to the "${destination.name}" ${destination.type} (${destination.status}) will change the destination status`;
      } else {
        message += ` from the "${source.name}" ${source.type} (${source.status}) will change the source status`;
      }

      message += " to CLDK";

      return message;
    }, [shipmentToMove]);

    return (
      <Dialog open={!!shipmentToMove} maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitle variant="h5">
          ⚠️ Confirm Shipment Move
          <Box position="absolute" top={10} right={10}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography fontWeight={500} color={colors.grey[800]}>
            {confirmationMessage}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
          <Button
            variant="outlined"
            onClick={onClose}
            color="inherit"
            sx={{
              border: "none",
              color: colors.blue[700],
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              shipmentToMove?.onConfirm();
            }}
            color="secondary"
            sx={{
              ml: 1,
              textTransform: "none",
            }}
            isLoading={isLoading}
            loadingLabel="Moving..."
          >
            Confirm Move
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);

ConfirmShipmentMoveDialog.displayName = "InboundConfirmShipmentMoveDialog";

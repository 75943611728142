import { AppBar, MenuItem, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { useCurrentUser } from "../hooks/react-query/settings/security/useUsers";
import {
  HOME_URL_KEY,
  useDepartments,
} from "../components/shared/nav/useDepartments";
import Loading from "../components/shared/layout/Loading";
import { useSelectedServiceCenter } from "../hooks/useSelectedServiceCenter";

export function Home() {
  const departments = useDepartments();
  const { data: user, isLoading: isCurrentUserLoading } = useCurrentUser();
  const [serviceCenter] = useSelectedServiceCenter();

  if (isCurrentUserLoading) return <Loading />;

  // The user should only be able to see this page when logged in,
  // and the route for this page only exists in the authenticated list of routes.
  // So if the user isn't logged in at this point, that's a bug.
  if (!user) throw new Error("User not authenticated");

  return (
    <Stack alignItems="center" py={5} sx={{ overflowY: "scroll" }}>
      <Box>
        <Typography
          variant="h4"
          component="h1"
          fontSize={34}
          fontWeight={400}
          lineHeight="normal"
          alignSelf="flex-start"
          pb={15}
        >
          <Typography fontSize={20} fontWeight={500} pb={1} color={grey[500]}>
            Welcome,
          </Typography>
          {user.firstName} {user.lastName}
        </Typography>
        <Typography
          variant="h6"
          component="h2"
          fontSize={20}
          fontWeight={500}
          lineHeight="normal"
          alignSelf="flex-start"
          pb={1}
          color={grey[500]}
        >
          Switch to
        </Typography>
        <AppBar
          position="static"
          component="nav"
          color="inherit"
          elevation={3}
          sx={{ borderRadius: 2, padding: 3 }}
          aria-label="Select a department"
        >
          <Stack direction="row" gap={8} justifyContent="space-between">
            {departments
              .filter(
                (department) =>
                  department.urlKey !== HOME_URL_KEY && department.enabled
              )
              .map((department) => (
                <MenuItem
                  component={Link}
                  to={
                    // If the department shows the service center select, the URL should include the service center code.
                    `${HOME_URL_KEY}${department.urlKey}/${department.showServiceCenterSelect ? serviceCenter.code : ""}`
                  }
                  key={department.urlKey}
                  sx={{ padding: 0 }}
                >
                  <Stack
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                    spacing={2}
                    minWidth="134px"
                  >
                    <Box>
                      <Stack
                        padding={1.5}
                        fontSize={38}
                        sx={{
                          borderRadius: "50%",
                          background: department.accentColor,
                        }}
                      >
                        {department.icon}
                      </Stack>
                    </Box>
                    <Typography
                      fontSize={20}
                      fontWeight={700}
                      letterSpacing={0.8}
                      textTransform="uppercase"
                      lineHeight="normal"
                      sx={{ color: department.accentColor }}
                    >
                      {department.label}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
          </Stack>
        </AppBar>
      </Box>
    </Stack>
  );
}

import { DoorLayout } from "../../hooks/react-query/useDoors";

export const doorLayoutList = {
  empty: [],
  default: [
    {
      doorNumber: 1,
      sector: "Sector 1",
    },
    {
      doorNumber: 2,
      sector: "Sector 1",
    },
    {
      doorNumber: 3,
      sector: "Sector 1",
    },
    {
      doorNumber: 4,
      sector: "Sector 1",
    },
  ],
} as const satisfies Record<string, DoorLayout[]>;

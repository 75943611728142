import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";
import { env } from "../../../utils/env";
import { apiUrls } from "../../../utils/apiUrls";

const redirectToSegments = [
  "operations_inbound_users",
  "linehaul_users",
] as const;

type RedirectToSegment = (typeof redirectToSegments)[number];

export function isRedirectToSegment(
  segment: string
): segment is RedirectToSegment {
  return redirectToSegments.includes(segment as RedirectToSegment);
}

const mySegmentSchema = z.object({
  name: z.string(),
  id: z.string(),
});

export type MySegment = z.infer<typeof mySegmentSchema>;

export const useMySegments = (userId: string) =>
  useQuery({
    enabled: !!userId,
    queryKey: ["splitIoMySegments", userId],
    queryFn: async () => {
      const url = apiUrls.getMySegments(userId);
      const { data: mySegmentsResponse } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${env.VITE_SPLIT_CLIENT_API_KEY}`,
          Accept: "application/json",
        },
      });

      return z.array(mySegmentSchema).parse(mySegmentsResponse.mySegments);
    },
  });

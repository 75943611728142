import { ServiceType } from "../../hooks/react-query/linehaul/useServiceTypes";

export const mockServiceTypes = {
  empty: [],
  default: [
    { id: 1, name: "Wild" },
    { id: 2, name: "Dedicated" },
    { id: 3, name: "Shuttle" },
    { id: 4, name: "1-way" },
    { id: 5, name: "Solo" },
    { id: 6, name: "Fleet" },
    { id: 7, name: "Broker" },
    { id: 7, name: "Company" },
  ],
} as const satisfies Record<string, ServiceType[]>;

import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import AddRouteDefaultDialog from "../shared/AddRouteDefaultDialog";
import MergeRoutesDefaultDialogOld from "../shared/MergeRoutesDefaultDialogOld";
import { AddTrapDialog } from "../prePlanning/tables/traps/AddTrapDialog";
import { AddTrapDialogOld } from "../prePlanning/tables/traps/AddTrapDialogOld";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import MergeTrapsDefaultDialogOld from "./MergeTrapDefaultDialogOld";
import { MergeRoutesTrapsDialog } from "./MergeRoutesTrapsDialog/MergeRoutesTrapsDialog";
import { MergeTrapsDialog } from "./MergeTrapsDialog";
import { MergeRoutesDialog } from "./MergeRoutesDialog";

export type SummaryContextType =
  | "bucket"
  | "trap"
  | "route"
  | "section"
  | "shipment";

export type SummaryContext = {
  /** Id of the parent (Route/Trap/Bucket/Section) */
  id: number | string;
  /** Shipment ids of the parent */
  shipmentIds: number[];
  /** Type of the parent `route | trap | bucket | section | shipment` */
  type: SummaryContextType;
};

type ActionDialogName =
  | "create-new-trap"
  | "create-new-route"
  | "assign-to"
  | "merge-routes"
  | "merge-traps"
  | "delete-trap"
  | "delete-route"
  | "merge-routes-traps";

export interface OpenDialogParams {
  name: Exclude<ActionDialogName, "merge-routes-traps">;
  shipmentContext?: SummaryContext[];
  planSummaries?: PlanTableFormat[];
  clearSelection?: () => void;
}

export interface OpenDialogWithMergeRouteParams {
  name: "merge-routes-traps";
  shipmentContext: SummaryContext[];
  planSummaries: PlanTableFormat[];
  clearSelection?: () => void;
}

interface ActionsMenuContextValue {
  activeDialog: ActionDialogName | null;
  openDialog: (
    dialogParams: OpenDialogParams | OpenDialogWithMergeRouteParams
  ) => void;
  closeDialog: () => void;
  shipments: SummaryContext[];
  setShipments: (shipmentContext: SummaryContext[]) => void;
  clearSelection?: () => void;
  planSummaries: PlanTableFormat[];
  isUpdatingClosedRouteOrTrap: (id?: number | string | null) => boolean;
}

const ActionsMenuContext = createContext<ActionsMenuContextValue | null>(null);

type ActionsMenuProviderProps = {
  children: ReactNode;
};

export const ActionsMenuProvider = ({ children }: ActionsMenuProviderProps) => {
  const editGuardrailsPhase2Enabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-2-client"
  );
  const mergeTrapsDialogV2Enabled = useIsFeatureFlagEnabled(
    "inbound-merge-trap-dialog-v2-client"
  );
  const mergeRoutesDialogV2Enabled = useIsFeatureFlagEnabled(
    "inbound-merge-route-dialog-v2-client"
  );

  const [activeDialog, setActiveDialog] = useState<ActionDialogName | null>(
    null
  );
  const [shipments, setShipments] = useState<SummaryContext[]>([]);
  const [clearSelection, setClearSelection] = useState<() => void>();
  const [planSummaries, setPlanSummaries] = useState<PlanTableFormat[]>([]);

  const isUpdatingClosedRouteOrTrap = useCallback(
    (planId?: number | string | null) =>
      planSummaries.some(
        (plan) =>
          shipments.some(
            (s) => Number(s.id) === plan.id && !plan.isAvailableForMassage
          ) ||
          (planId && Number(planId) === plan.id && !plan.isAvailableForMassage)
      ),
    [planSummaries, shipments]
  );

  const openDialog = (
    params: OpenDialogParams | OpenDialogWithMergeRouteParams
  ) => {
    params.shipmentContext && setShipments(params.shipmentContext);
    params.clearSelection && setClearSelection(() => params.clearSelection);
    setPlanSummaries(params.planSummaries ?? []);
    setActiveDialog(params.name);
  };

  function renderActiveDialog() {
    switch (activeDialog) {
      case "create-new-trap":
        return editGuardrailsPhase2Enabled ? (
          <AddTrapDialog />
        ) : (
          <AddTrapDialogOld />
        );
      case "create-new-route":
        return <AddRouteDefaultDialog />;
      case "merge-routes":
        return mergeRoutesDialogV2Enabled ? (
          <MergeRoutesDialog
            clearSelection={clearSelection}
            handleClose={() => setActiveDialog(null)}
            selectedRoutes={shipments}
          />
        ) : (
          <MergeRoutesDefaultDialogOld />
        );
      case "merge-traps":
        return mergeTrapsDialogV2Enabled ? (
          <MergeTrapsDialog
            clearSelection={clearSelection}
            handleClose={() => setActiveDialog(null)}
            selectedTraps={shipments}
          />
        ) : (
          <MergeTrapsDefaultDialogOld />
        );
      case "merge-routes-traps":
        return (
          <MergeRoutesTrapsDialog
            selectedRouteIds={shipments
              .filter((shipment) => shipment.type === "route")
              .map((shipment) => Number(shipment.id))}
            selectedTrapIds={shipments
              .filter((shipment) => shipment.type === "trap")
              .map((shipment) => Number(shipment.id))}
            onCancel={() => setActiveDialog(null)}
          />
        );
      default:
        return null;
    }
  }

  const value = useMemo(
    () => ({
      activeDialog,
      openDialog,
      closeDialog: () => setActiveDialog(null),
      shipments,
      setShipments,
      clearSelection,
      planSummaries,
      isUpdatingClosedRouteOrTrap,
    }),
    [
      activeDialog,
      clearSelection,
      isUpdatingClosedRouteOrTrap,
      planSummaries,
      shipments,
    ]
  );

  return (
    <ActionsMenuContext.Provider value={value}>
      {children}
      {renderActiveDialog()}
    </ActionsMenuContext.Provider>
  );
};

export function useActionsMenuContext(): ActionsMenuContextValue {
  const actionsContext = useContext(ActionsMenuContext);
  if (!actionsContext) {
    throw new Error(
      "useActionsMenuContext must be used within ActionsMenuProvider"
    );
  }
  return actionsContext;
}

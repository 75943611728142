import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import toObject from "dayjs/plugin/toObject";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import weekOfYear from "dayjs/plugin/weekOfYear"; // dependent on utc plugin
import isoWeek from "dayjs/plugin/isoWeek";

const executeDayJsPlugins = () => {
  dayjs.extend(objectSupport);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(toObject);
  dayjs.extend(weekOfYear);
  dayjs.extend(isoWeek);
};

export const dayjsConfig = {
  executeDayJsPlugins,
};

import { z } from "zod";

export const transitionErrorCodesSchema = z.enum([
  "NO_TRAILER_ASSIGNED",
  "TRAILER_CANT_BE_LOADED",
  "NO_DOOR_ASSIGNED",
  "NO_TRACTOR_ASSIGNED",
  "NO_DRIVER_ASSIGNED",
  "NO_SHIPMENTS_ASSIGNED",
  "SHIPMENTS_NOT_READY_TO_LOAD",
  "DOOR_IS_NOT_AVAILABLE",
  "TRUCK_NOT_AVAILABLE",
  "NON_TRANSITIONABLE_STATUS",
  "DRIVER_NOT_AVAILABLE",
  "SHIPMENTS_ARE_LOADED",
  "DRIVER_IS_NOT_AVAILABLE", // TODO: temporary PROD fix
]);

export const shipmentsNotReadyToLoadSchema = z.array(
  z.object({
    proNumber: z.string(),
    sicCode: z.string().nullable(),
    statusName: z.string(),
  })
);

export type RouteTrapStatusConstraint = z.infer<
  typeof transitionErrorCodesSchema
>;

// TODO: use "const errorDetailDataSchema = z.union(shipmentsNotReadyToLoadSchema, newSchema)" to handle new errors with additional data
const errorDetailDataSchema = shipmentsNotReadyToLoadSchema;
export type ErrorDetailData = z.infer<typeof errorDetailDataSchema>;

export const errorDetailSchema = z.object({
  subject: z.string(),
  message: z.string(),
  code: transitionErrorCodesSchema,
  data: errorDetailDataSchema.nullish(),
});

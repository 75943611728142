import dayjs from "dayjs";
import { DaylightDateFormat } from "../constants/DaylightDateFormat";
import { DASH_PLACEHOLDER } from "../constants/dashPlaceholder";
import { isValidDate } from "../constants/filters/utils";

export const formatDateTimeTableField = (
  value: Date | null | undefined,
  format: DaylightDateFormat,
  timeZone: string
) => {
  if (!value || !isValidDate(value)) return DASH_PLACEHOLDER;
  const formattedDate = dayjs(value).utc().tz(timeZone).format(format);
  return formattedDate;
};

export const formatServiceDueTableField = (value: Date | null | undefined) => {
  if (!value || !isValidDate(value)) return DASH_PLACEHOLDER;
  const formattedDate = dayjs(value).utc().format(DaylightDateFormat.DATE);
  return formattedDate;
};

export const formatEtaTableField = (
  value: Date | null | undefined,
  timeZone: string
) => formatDateTimeTableField(value, DaylightDateFormat.DATE_TIME_12, timeZone);

export const formatAppointmentDateTableField = (
  value: Date | null | undefined,
  timeZone: string
) => formatDateTimeTableField(value, DaylightDateFormat.DATE, timeZone);

export const formatAppointmentTimeTableField = (
  appointmentStartDate: Date | null | undefined,
  appointmentEndDate: Date | null | undefined,
  timeZone: string
) => {
  if (!isValidDate(appointmentStartDate)) {
    return DASH_PLACEHOLDER;
  }
  return (
    formatDateTimeTableField(
      appointmentStartDate,
      DaylightDateFormat.TIME_12,
      timeZone
    ) +
    "-" +
    formatDateTimeTableField(
      appointmentEndDate,
      DaylightDateFormat.TIME_12,
      timeZone
    )
  );
};

export const formatRouteStartEndTimeTableField = (
  value: Date | null | undefined,
  timeZone: string
) => formatDateTimeTableField(value, DaylightDateFormat.TIME_12, timeZone);

// eslint-disable-next-line no-restricted-imports
import { Backdrop, Portal } from "@mui/material";
import Loading from "./layout/Loading";

/** Full screen backdrop with a loading spinner */
export const LoadingBackdrop = () => (
  <Portal container={document.body}>
    <Backdrop
      sx={{
        margin: "0 !important",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open
    >
      <Loading />
    </Backdrop>
  </Portal>
);

import { Route } from "../../types/route.type";
import { defaultMockServiceCenter } from "../serviceCenter/serviceCenters.mocks";

export const mockPresetRoutesAndTraps = {
  empty: [],
  default: [
    {
      id: 200,
      sicId: defaultMockServiceCenter.id,
      zones: [],
      zoneIds: [],
      zipCodes: ["93599", "93591", "93561", "93555", "93551"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: "00:00:00",
      trailerId: 884,
      tractorId: 1651,
      equipmentType: 2,
      straightTruckId: null,
      doorId: 1,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "ALL FREIGHT",
      hereFeatureId: null,
    },
    {
      id: 594,
      sicId: defaultMockServiceCenter.id,
      zones: [],
      zoneIds: [],
      zipCodes: ["HI", "96898", "96863", "96861", "96860"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      equipmentType: 3,
      straightTruckId: 3,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "ALOHA",
      hereFeatureId: null,
    },
    {
      id: 783,
      sicId: defaultMockServiceCenter.id,
      zones: ["OC Inland"],
      zoneIds: [],
      zipCodes: ["92806", "92899", "92850"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "ANAHEIM",
      hereFeatureId: null,
    },
    {
      id: 177,
      sicId: defaultMockServiceCenter.id,
      zones: ["IE"],
      zoneIds: [],
      zipCodes: ["91793", "91791", "91790"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "AZUSA",
      hereFeatureId: null,
    },
    {
      id: 546,
      sicId: defaultMockServiceCenter.id,
      zones: [],
      zoneIds: [],
      zipCodes: ["91987", "91980"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "BENCHPRO",
      hereFeatureId: null,
    },
    {
      id: 189,
      sicId: defaultMockServiceCenter.id,
      zones: ["South SD County"],
      zoneIds: [],
      zipCodes: ["92179", "92173", "92158", "92154", "92153", "92143"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "BORDER",
      hereFeatureId: null,
    },
    {
      id: 172,
      sicId: defaultMockServiceCenter.id,
      zones: ["OC Inland"],
      zoneIds: [],
      zipCodes: ["92887", "92886", "92885", "92871", "92870"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "BREA",
      hereFeatureId: null,
    },
    {
      id: 192,
      sicId: defaultMockServiceCenter.id,
      zones: ["South LA County Coast"],
      zoneIds: [],
      zipCodes: ["90895", "90749", "90748", "90747", "90746", "90745", "90744"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "CARSON",
      hereFeatureId: null,
    },
    {
      id: 169,
      sicId: defaultMockServiceCenter.id,
      zones: ["South LA County Coast"],
      zoneIds: [],
      zipCodes: ["90704", "90703", "90702", "90701", "90650"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "CERRITOS",
      hereFeatureId: null,
    },
    {
      id: 190,
      sicId: defaultMockServiceCenter.id,
      zones: ["South SD County"],
      zoneIds: [],
      zipCodes: ["91951", "91950", "91947", "91913", "91911", "91910", "91909"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: null,
      tractorId: null,
      straightTruckId: null,
      doorId: 0,
      driverId: 0,
      isRoute: true,
      isActive: true,
      name: "CHULA VISTA",
      hereFeatureId: null,
    },
    {
      id: 191,
      sicId: defaultMockServiceCenter.id,
      zones: ["South SD County"],
      zoneIds: [],
      zipCodes: ["12345"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: 1,
      tractorId: 2,
      straightTruckId: null,
      doorId: 0,
      driverId: 3,
      isRoute: true,
      isActive: true,
      name: "DEACTIVATED EQUIPMENT DRIVER",
      hereFeatureId: null,
    },
    {
      id: 192,
      sicId: defaultMockServiceCenter.id,
      zones: ["South SD County"],
      zoneIds: [],
      zipCodes: ["12345"],
      startTime: "08:00:00",
      endPoint: null,
      endTime: null,
      trailerId: 1234,
      tractorId: 5234,
      straightTruckId: null,
      doorId: 0,
      driverId: 555,
      isRoute: true,
      isActive: true,
      name: "UNAVAILABLE EQUIPMENT DRIVER",
      hereFeatureId: null,
    },
  ],
} as const satisfies Record<string, Route[]>;

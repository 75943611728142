import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import dayjs from "dayjs";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";
import {
  Route,
  RouteSummary,
  routesSummaryResponse,
  allRouteTableSchema,
} from "../../services/prePlanningService.types";
import { MergeRoutesRequest } from "../../services/planningService.types";
import { useDateSearchParamOrFallbackToToday } from "../useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../utils/dateTimeHelper";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";
import { useSelectedServiceCenter } from "../useSelectedServiceCenter";
import { PlanTableFormat } from "../../types/planning/plan.type";
import { CreateRouteRequest, LockRoute } from "./useDeliveryRoutes.types";
import { plannedRoutesQueryKey } from "./usePlannedRoutes";

export const deliveryRoutesKeys = {
  deliveryRoutes: (sicId: number, date: number) =>
    ["delivery-routes", sicId, date] as const,
  deliveryRoutesSummary: (
    sicId: number,
    date: number,
    searchTerm: string | undefined = undefined
  ) => {
    let queryKey: unknown[] = ["delivery-routes-summary", date, sicId];

    if (searchTerm !== undefined) {
      queryKey = [...queryKey, searchTerm];
    }

    return queryKey;
  },
  allRoutes: (sicId: number, date: number, searchTerm?: string) =>
    deliveryRoutesKeys.deliveryRoutesSummary(sicId, date, searchTerm),
};

export function useDeliveryRoutesSummary(date: number, sicId: number) {
  async function getDeliveryRoutesSummary(
    sicId: number,
    date: number
  ): Promise<RouteSummary[]> {
    const { data, status } = await http.get(
      apiUrls.getDeliveryRoutesSummary(sicId, date)
    );
    if (status === 204) return [];
    return routesSummaryResponse.parse(data);
  }

  return useQuery({
    queryKey: deliveryRoutesKeys.deliveryRoutesSummary(sicId, date),
    queryFn: () => getDeliveryRoutesSummary(sicId, date),
  });
}

export const useMergeRoutesOld = (
  routeId: number,
  request: MergeRoutesRequest,
  targetRouteName: string,
  onSuccess?: () => void,
  searchTerm?: string
) => {
  const queryClient = useQueryClient();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  async function mergeRoutes(mainRouteId: number, request: MergeRoutesRequest) {
    const url = apiUrls.mergeRoutes(mainRouteId);
    const { status } = await http.patch(url, request);
    return status;
  }

  return useMutation({
    mutationFn: () => mergeRoutes(routeId, request),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: deliveryRoutesKeys.deliveryRoutesSummary(
          serviceCenter.id,
          dateToInt(selectedDate)
        ),
      });
      queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(
          dateToInt(selectedDate),
          serviceCenter.id,
          searchTerm
        ),
      });

      toast(
        toastMessage.inbound.mergeRoutes.success(
          request.routes.length,
          targetRouteName
        ),
        {
          variant: "success",
        }
      );

      if (onSuccess) onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};

export type MergeRoutesMutation = {
  routeId: number;
  request: MergeRoutesRequest;
};

export const useMergeRoutes = (
  successHandler: (
    data: number,
    mergeRoutesMutation: MergeRoutesMutation
  ) => void,
  searchTerm?: string
) => {
  const queryClient = useQueryClient();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  const mergeRoutes = async (mergeRoutesMutation: MergeRoutesMutation) => {
    const { status } = await http.patch(
      apiUrls.mergeRoutes(mergeRoutesMutation.routeId),
      mergeRoutesMutation.request
    );
    return status;
  };

  return useMutation({
    mutationFn: async (mergeRoutesMutation: MergeRoutesMutation) =>
      await mergeRoutes(mergeRoutesMutation),
    onSuccess: successHandler,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: deliveryRoutesKeys.deliveryRoutesSummary(
          serviceCenter.id,
          dateToInt(selectedDate)
        ),
      });
      queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(
          dateToInt(selectedDate),
          serviceCenter.id,
          searchTerm
        ),
      });
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};

type UseCreateRouteArgs = {
  createRouteRequest: CreateRouteRequest;
  onSuccess: (shipmentsCount: number, routeName: string) => void;
  onError?: (error: unknown) => void;
};

export const useCreateRoute = ({
  createRouteRequest,
  onSuccess,
  onError,
}: UseCreateRouteArgs) => {
  const queryClient = useQueryClient();

  const { sicId } = createRouteRequest;
  const [serviceCenter] = useSelectedServiceCenter();

  return useMutation({
    mutationFn: () =>
      http.post(apiUrls.createRoute(createRouteRequest.unixTime, sicId), {
        ...createRouteRequest,
        startTimeTimestampUTC: dayjs(createRouteRequest.startTimeTimestampUTC, {
          utc: false,
        })
          .tz(serviceCenter.timeZone, true)
          .toISOString(),
        sicId: serviceCenter.id,
        doorId: createRouteRequest.doorId || null,
        tractorEquipmentId: createRouteRequest.tractorEquipmentId,
        trailerEquipmentId: createRouteRequest.trailerEquipmentId,
        straightTruckEquipmentId: createRouteRequest.straightTruckEquipmentId,
        driverId: createRouteRequest.driverId || "0",
        assignments: createRouteRequest.assignments || [],
      }),
    onSuccess: () => {
      const selectedShipments = createRouteRequest.assignments?.flatMap(
        (assignment) => assignment.shipmentIds.length
      );

      const numSelectedShipments = selectedShipments?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      onSuccess(numSelectedShipments ?? 0, createRouteRequest.name);
    },
    onError,
    onSettled: () => {
      // Invalidating all queries because the list was long and complex: https://github.com/daylighttransport/operations-iol-webapp/pull/1370/files
      queryClient.invalidateQueries();
    },
  });
};

interface AllRoutesTableProps {
  date: number;
  sicId: number;
  searchTerm?: string;
  isNewTableEnabled?: boolean;
}

export function useAllRoutesTable({
  date,
  sicId,
  searchTerm,
  isNewTableEnabled = false,
}: AllRoutesTableProps) {
  const allRoutesTableResponse = z.array(allRouteTableSchema);

  const getAllRouteData = async (
    sicId: number,
    date: number,
    searchTerm?: string
  ): Promise<Route[]> => {
    const { data, status } = await http.get(
      apiUrls.getDeliveryRoutesSummary(sicId, date),
      {
        params: { searchTerm },
      }
    );

    if (status === 204) return [];
    return allRoutesTableResponse.parse(data);
  };

  return useQuery({
    refetchOnMount: isNewTableEnabled ? false : true,
    queryKey: deliveryRoutesKeys.allRoutes(sicId, date, searchTerm),
    queryFn: () => getAllRouteData(sicId, date, searchTerm),
  });
}

interface LockRouteProps {
  date: number;
  sicId: number;
  searchTerm?: string;
  onSuccess: () => Promise<void> | void;
}

export const useLockRoutes = ({
  date,
  sicId,
  searchTerm = "",
  onSuccess,
}: LockRouteProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (routes: LockRoute[]) => {
      await http.post(apiUrls.lockRoutes, routes);
    },
    onSuccess: (_, lockedRoutes) => {
      queryClient.setQueryData<PlanTableFormat[]>(
        plannedRoutesQueryKey(date, sicId, searchTerm),
        (previousStateOfPlans) => {
          if (!previousStateOfPlans) return [];
          return previousStateOfPlans.map((plan) => {
            const match = lockedRoutes.find(
              (lockedRoute) => lockedRoute.id === plan.id
            );
            if (match) {
              return { ...plan, isCommitted: match.isLocked };
            }
            return plan;
          });
        }
      );
      onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};

export const useOldLockRoutes = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
}: LockRouteProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (routes: LockRoute[]) => {
      await http.post(apiUrls.lockRoutes, routes);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
      onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};

export function useInvalidateQueryRoutes() {
  const queryClient = useQueryClient();
  const invalidateQueryRoutes = (date: number, sicId: number) => {
    queryClient.invalidateQueries({
      queryKey: plannedRoutesQueryKey(date, sicId),
    });
    queryClient.invalidateQueries({
      queryKey: deliveryRoutesKeys.deliveryRoutesSummary(sicId, date),
    });
    queryClient.invalidateQueries({
      queryKey: deliveryRoutesKeys.deliveryRoutes(sicId, date),
    });
  };
  return invalidateQueryRoutes;
}

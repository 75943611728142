import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSessionStorage } from "@uidotdev/usehooks";
import { ONTServiceCenter, ServiceCenter } from "../types/serviceCenter.type";
import { useCurrentUser } from "./react-query/settings/security/useUsers";
import { useServiceCenters } from "./react-query/useServiceCenters";

export const sessionStorageKeys = {
  currentTabServiceCenter: "currentTabServiceCenter",
};

type GetServiceCenterSearchParams = {
  sicId?: number;
  code?: string;
};

type GetServiceCenterWithDefaultFallback = (
  serviceCenters: ServiceCenter[],
  searchParams: GetServiceCenterSearchParams,
  fallbackServiceCenter?: ServiceCenter
) => ServiceCenter;

const getServiceCenterWithDefaultFallback: GetServiceCenterWithDefaultFallback =
  (serviceCenters, { code, sicId }, fallbackServiceCenter = ONTServiceCenter) =>
    serviceCenters.find((sc) => sc.code === code || sc.id === sicId) ||
    fallbackServiceCenter;

export function useSelectedServiceCenter() {
  const { data: userData } = useCurrentUser();
  const { data: serviceCenters = [], isLoading: isLoadingServiceCenters } =
    useServiceCenters("CompanyOwned");
  const { serviceCenterCode: serviceCenterCodeParam = "" } = useParams();
  /*
  * If a console.log statement is included in the init function for currentTabServiceCenter and we use useState instead of useSessionStorage, 
  * we will see that it's being called several times (despite changing it to use lazy state initialization or memoizing the init value) which causes 
  * the exported currentTabServiceCenter to be updated too often with different values due to the useEffect within the hook. This causes unexpected 
  * behaviors wherever the hook is used.

  * Switching to useSessionStorage from useState can resolve this issue because useSessionStorage typically preserves the state beyond the lifecycle 
  * of the component in the session storage of the browser. When useSessionStorage is used, the state initialization occurs only once per session or
  * until the session storage is manually cleared. This means even if the component re-renders, the initial state is not recalculated but retrieved 
  * from the session storage, thereby avoiding multiple console logs and stabilizing the state.
 */
  const [currentTabServiceCenter, setCurrentTabServiceCenter] =
    useSessionStorage(
      sessionStorageKeys.currentTabServiceCenter,
      getServiceCenterWithDefaultFallback(
        serviceCenters,
        {
          sicId: Number(userData?.sicId),
        },
        ONTServiceCenter
      )
    );

  useEffect(
    function setCurrentTabServiceCenterIfUrlParamDoesNotMatchCurrentTabServiceCenter() {
      if (
        !isLoadingServiceCenters &&
        serviceCenterCodeParam &&
        currentTabServiceCenter.code !== serviceCenterCodeParam
      ) {
        const urlParamServiceCenter = getServiceCenterWithDefaultFallback(
          serviceCenters,
          { code: serviceCenterCodeParam }
        );
        setCurrentTabServiceCenter(urlParamServiceCenter);
      }
    },
    [
      currentTabServiceCenter.code,
      serviceCenterCodeParam,
      serviceCenters,
      isLoadingServiceCenters,
      setCurrentTabServiceCenter,
    ]
  );

  return [currentTabServiceCenter, setCurrentTabServiceCenter] as const;
}

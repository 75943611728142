export const mockDelayReasons = {
  default: [
    {
      id: 34,
      name: "Late OB Close",
      description: null,
      typeId: 1,
      typeName: "Linehaul",
      typeDescription: "Represents the Linehaul workstream",
    },
  ],
};

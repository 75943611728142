import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useRef } from "react";
import { actionsMap, ShipmentActionTypeId } from "../ShipmentActionDialog";
import { toast } from "../../../../utils/snackbarHelper";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { InboundPlanningOutletContext } from "../InboundPlanningLayout";
import {
  RouteAssignmentResponse,
  TrapAssignmentResponse,
} from "../../../../types/planning/routeAssignmentResponse.type";
import { TRAILER_TITLE_PARAM } from "../../../../components/shared/table/TableLink";
import EditRouteOrTrapDialog, {
  type EditRouteOrTrapDialogRef,
} from "../EditRouteOrTrapDialog";

type EditIconButtonProps = {
  routeOrTrap: PlanTableFormat;
};

export const EditIconButton = ({ routeOrTrap }: EditIconButtonProps) => {
  const editRouteOrTrapRef = useRef<EditRouteOrTrapDialogRef>(null);
  const { sidebarRef } = useOutletContext<InboundPlanningOutletContext>();
  const [searchParams, setSearchParams] = useSearchParams();

  const onSuccessfulAssignment = async (
    actionType: ShipmentActionTypeId,
    response?: RouteAssignmentResponse | TrapAssignmentResponse
  ) => {
    const { description } = actionsMap[actionType];

    toast(description, {
      variant: "success",
    });

    await sidebarRef.current?.refresh();

    if (response) {
      searchParams.set(TRAILER_TITLE_PARAM, response.name);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <Tooltip title={`Edit ${routeOrTrap.name}`}>
        <IconButton onClick={() => editRouteOrTrapRef.current?.handleOpen()}>
          <Edit />
        </IconButton>
      </Tooltip>
      <EditRouteOrTrapDialog
        routeOrTrap={routeOrTrap}
        ref={editRouteOrTrapRef}
        onSuccess={onSuccessfulAssignment}
      />
    </>
  );
};

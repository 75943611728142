import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Typography,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import dayjs from "dayjs";
import { z } from "zod";
import { useSearchParams } from "react-router-dom";
import { Title } from "../../../components/shared/Title";
import {
  FlexRow,
  GappedFlexColumn,
} from "../../../components/shared/layout/Flex";
import { DropdownOption } from "../../../types/dropdownOption.type";
import { useDoorNumbersBySicIdOld } from "../../../hooks/react-query/useDoors";
import { getDeliveryDate } from "../../../utils/timeFormat";
import {
  useAllRoutesTable,
  useMergeRoutesOld,
} from "../../../hooks/react-query/useDeliveryRoutes";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import Loading from "../../../components/shared/layout/Loading";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import UniqueShipmentIdCountMessage from "../../../components/shared/UniqueShipmentIdCountMessage";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import DropdownSelect from "../../../components/shared/DropdownSelect";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { getDoorOptionsFromDoorNumbersOld } from "./doorNumberUtils";
import { useActionsMenuContext } from "./ActionsMenuContext";
import StartTimeDatePicker from "./StartTimeDatePicker";
import {
  MergeRoutesDialogOldReducer,
  initialState,
} from "./MergeRoutesDialogOldReducer";
import { EquipmentSelection } from "./EquipmentSelection";
import { EquipmentSelectionOld } from "./EquipmentSelectionOld";

interface MergeRoutesDialogOldProps {
  selectedRouteIds?: string[];
  selectedDate?: string;
  onCancel: () => void;
  onSuccess?: () => void;
  clearSelection?: () => void;
}

export const MergeRoutesDialogOld = ({
  selectedRouteIds = initialState.fromRouteIds,
  onCancel,
  selectedDate,
  onSuccess,
  clearSelection,
}: MergeRoutesDialogOldProps) => {
  const editGuardrailsPhase2Enabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-2-client"
  );

  const [dateSearchParam] = useDateSearchParamOrFallbackToToday();
  const [searchParams] = useSearchParams();

  const deliveryDate = getDeliveryDate(selectedDate, dateSearchParam);

  const planDate = dateToInt(deliveryDate);

  const [serviceCenter] = useSelectedServiceCenter();
  const { shipments } = useActionsMenuContext();

  const { data: doorNumbers = [] } = useDoorNumbersBySicIdOld(serviceCenter.id);

  // Add "" to the array so that there are at least two dropdowns presented at the top of the form.
  if (selectedRouteIds.length === 1) selectedRouteIds.push("");

  const [dialogState, dispatch] = useReducer(MergeRoutesDialogOldReducer, {
    ...initialState,
    fromRouteIds: selectedRouteIds,
  });

  const {
    fromRouteIds,
    toRouteId,
    doorId,
    startTime,
    tractorEquipmentId,
    trailerEquipmentId,
    straightTruckEquipmentId,
  } = dialogState;

  const deliveryRoutes = useAllRoutesTable({
    date: planDate,
    sicId: serviceCenter.id,
  });

  const deliveryRouteOptions: DropdownOption[] = useMemo(
    () =>
      deliveryRoutes.isSuccess
        ? deliveryRoutes.data.map((route) => ({
            name: route.name || "",
            value: route.id,
          }))
        : [],
    [deliveryRoutes.data, deliveryRoutes.isSuccess]
  );

  const mergeToRouteOptions: DropdownOption[] = [
    ...deliveryRouteOptions,
  ].filter((route) =>
    fromRouteIds
      .map((routeId) => routeId.toString())
      .includes(route.value.toString())
  );

  useEffect(() => {
    const defaultRouteIds = deliveryRouteOptions
      .filter((route) => selectedRouteIds.some((id) => route.name === id))
      .map((route) => String(route.value));
    if (defaultRouteIds.length === 0) return;
    dispatch({ type: "load_routes", routeIds: defaultRouteIds });
  }, [deliveryRouteOptions, selectedRouteIds]);

  const toRoute = deliveryRoutes.data?.find(
    (route) => route.id === Number(toRouteId)
  );

  const selectedRoutesIds = fromRouteIds
    .filter((routeId) => routeId !== toRouteId?.toString() && routeId)
    .map((id) => Number(id));

  const mutation = useMergeRoutesOld(
    toRoute?.id ?? 0,
    {
      doorId: doorId ? +doorId : null,
      startTime: startTime
        ? dayjs(startTime, { utc: false })
            .tz(serviceCenter.timeZone, true)
            .toISOString()
        : dayjs().utc().toISOString(),
      date: planDate,
      sicId: serviceCenter.id,
      routes: selectedRoutesIds,
      tractorEquipmentId,
      trailerEquipmentId,
      straightTruckEquipmentId,
      customName: null,
    },
    toRoute?.name ?? "",
    () => {
      closeDialog();
      clearSelection?.();
      if (onSuccess) onSuccess();
    },
    searchParams.get("searchTerm") ?? undefined
  );

  const addRoute = useCallback(() => dispatch({ type: "add_route" }), []);
  const removeRoute = useCallback((index: number) => {
    dispatch({ type: "remove_route", fieldIndex: index });
  }, []);
  const mergeFromRoute = useCallback((routeId: string, index: number) => {
    dispatch({ type: "merge_from_route_field", fieldIndex: index, routeId });
  }, []);
  const mergeToRoute = useCallback(
    (routeId: number) => dispatch({ type: "merge_to_route_field", routeId }),
    []
  );
  const assignDoor = useCallback(
    (doorId: number) => dispatch({ type: "assign_door_id", doorId }),
    []
  );
  const closeDialog = useCallback(() => {
    onCancel();
    mutation.reset();
    dispatch({
      type: "clear",
    });
  }, [onCancel, mutation]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (startTime === null) {
      toast(toastMessage.generics.failedValidation, {
        variant: "error",
      });
      return;
    }
    mutation.mutate();
  };

  if (deliveryRoutes.isLoading) {
    return (
      <Dialog open>
        <DialogTitle sx={{ fontWeight: "bold" }}>Merge Routes</DialogTitle>
        <DialogContent
          sx={{ mt: 3, display: "flex", justifyContent: "center" }}
        >
          <Loading />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open>
      <form onSubmit={onSubmit}>
        <DialogTitle sx={{ fontWeight: "bold" }}> Merge Routes</DialogTitle>
        <DialogContent sx={{ width: "60vw", maxWidth: "500px" }}>
          <UniqueShipmentIdCountMessage shipments={shipments} />
          <GappedFlexColumn gap={1}>
            <Title weight="bold">Select Routes to Merge</Title>
            {fromRouteIds.map((routeId, index) => {
              const hasDeleteField = index > 1;
              return (
                <FlexRow key={routeId}>
                  <DropdownSelect
                    sx={{
                      width: fromRouteIds.length > 2 ? "450px" : "100%",
                      marginTop: "1rem",
                    }}
                    disabled={!deliveryRoutes.isSuccess}
                    label="Select a Route"
                    options={deliveryRouteOptions}
                    value={routeId}
                    onChange={(event) => {
                      mergeFromRoute(event.target.value as string, index);
                    }}
                    required
                  />
                  {hasDeleteField && (
                    <IconButton
                      sx={{
                        width: "36px",
                        height: "36px",
                        alignSelf: "end",
                        marginLeft: "1rem",
                      }}
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      onClick={() => removeRoute(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </FlexRow>
              );
            })}
            <FlexRow>
              <AddRouteButton onClick={addRoute} />
            </FlexRow>
            <Divider sx={{ marginTop: "10px" }} />
            <Title weight="bold">Merge Routes into:</Title>
            <DropdownSelect
              disabled={mergeToRouteOptions.length === 0}
              title="Route"
              label="Select a Route"
              options={mergeToRouteOptions}
              value={toRouteId ?? ""}
              onChange={(event) => {
                mergeToRoute(z.number().parse(event.target.value));
              }}
              required
            />
            <DropdownSelect
              disabled={doorNumbers.length === 0}
              title="Door Assignment"
              label="Unassigned"
              options={getDoorOptionsFromDoorNumbersOld(doorNumbers)}
              value={doorId ?? ""}
              onChange={(event) =>
                assignDoor(z.number().parse(event.target.value))
              }
            />
            <StartTimeDatePicker
              label="Start Time"
              value={startTime ? dayjs(startTime) : null}
              onChange={(date) => {
                const isValidDate = date && date.isValid();
                dispatch({
                  type: "set_start_time",
                  startTime: isValidDate ? date.toISOString() : null,
                });
              }}
              width="100%"
            />
            {editGuardrailsPhase2Enabled ? (
              <EquipmentSelection
                trailerId={trailerEquipmentId}
                tractorId={tractorEquipmentId}
                straightTruckId={straightTruckEquipmentId}
                setTrailerValue={(newValue) =>
                  dispatch({
                    type: "set_trailer_id",
                    trailerEquipmentId:
                      newValue?.id || initialState.trailerEquipmentId,
                  })
                }
                setStraightTruckValue={(newValue) =>
                  dispatch({
                    type: "set_straight_truck_id",
                    straightTruckEquipmentId:
                      newValue?.id || initialState.straightTruckEquipmentId,
                  })
                }
                setTractorValue={(newValue) =>
                  dispatch({
                    type: "set_tractor_id",
                    tractorEquipmentId:
                      newValue?.id || initialState.tractorEquipmentId,
                  })
                }
              />
            ) : (
              <EquipmentSelectionOld
                trailerId={trailerEquipmentId}
                tractorId={tractorEquipmentId}
                straightTruckId={straightTruckEquipmentId}
                setTrailerValue={(newValue) =>
                  dispatch({
                    type: "set_trailer_id",
                    trailerEquipmentId:
                      newValue?.id || initialState.trailerEquipmentId,
                  })
                }
                setStraightTruckValue={(newValue) =>
                  dispatch({
                    type: "set_straight_truck_id",
                    straightTruckEquipmentId:
                      newValue?.id || initialState.straightTruckEquipmentId,
                  })
                }
                setTractorValue={(newValue) =>
                  dispatch({
                    type: "set_tractor_id",
                    tractorEquipmentId:
                      newValue?.id || initialState.tractorEquipmentId,
                  })
                }
              />
            )}
          </GappedFlexColumn>
        </DialogContent>
        <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
          <Button
            color="primary"
            variant="text"
            sx={{ color: "secondary" }}
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <LoadingButton
            loadingLabel="Merging..."
            isLoading={mutation.isPending}
          >
            Merge
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const AddRouteButton = styled(({ onClick }: { onClick: () => void }) => (
  <Link variant="body2" onClick={onClick} sx={{ cursor: "pointer" }}>
    <Typography>+ Add Route</Typography>
  </Link>
))(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

import { RouterProvider, createBrowserRouter } from "react-router-dom";

export default function UnprotectedRoutesProvider() {
  return (
    <RouterProvider
      router={createBrowserRouter([
        // When not logged in, all requests should be redirected to login
        { path: "*", lazy: () => import("../pages/login/Login") },
      ])}
    />
  );
}

import { z } from "zod";

export type SelectedEquipmentType = "Trailer" | "Truck";

export const equipmentTypeNames = [
  "Straight Truck",
  "Tractor",
  "Trailer",
] as const;
export const equipmentTypeNameSchema = z.enum(equipmentTypeNames);
export type EquipmentTypeName = z.infer<typeof equipmentTypeNameSchema>;

export type EquipmentType = "tractor" | "trailer" | "straightTruck";
export const equipmentTypeIds = [1, 2, 3] as const;
export type EquipmentTypeId = (typeof equipmentTypeIds)[number];
export const equipmentTypeMap: Record<EquipmentType, EquipmentTypeId> = {
  tractor: 1,
  trailer: 2,
  straightTruck: 3,
} as const;

export const equipmentSchema = z.object({
  id: z.number().min(0),
  typeName: z.enum(equipmentTypeNames).default("Trailer"),
  typeId: z.number().default(2),
  active: z.boolean(),
  maintenanceStatus: z.string(),
  homeSicId: z.number().nullable(),
  refTrailerNumber: z.string().nullish(),
  capacityLbs: z.number().optional(),
  lengthFt: z.number().optional(),
  maxHU: z.number().optional(),
  hasLiftGate: z.boolean().optional(),
  driverId: z.number().nullable(),
  driverName: z.string().nullish(),
  inspectionChecklistId: z.coerce.string().nullish(),
  jboxSerial: z.string().default(""),
  forkliftType: z.string().default(""),
  tabletIpAddress: z.string().default(""),
});

export type Equipment = z.infer<typeof equipmentSchema>;
export const equipmentsSchema = z.array(equipmentSchema);

import { z } from "zod";
import { tagsSchema } from "../freight/tag.type";
import { summarySchema, summaryTransformation } from "../summary.type";
import { sortShipmentTags } from "../../utils/shipmentTags";

const equipmentTrapSchema = z.object({
  id: z.number(),
  name: z.string(),
  // isActive: z.boolean(), // TODO BE team will update the endpoint to return this param
});

export const trapSchema = summarySchema
  .extend({
    id: z.number(),
    title: z.string(),
    notes: z.string().nullable(),
    name: z.string(),
    doorId: z.number().nullable(),
    trailerNumber: z.string().nullable(),
    appointmentDate: z.date(),
    serviceDueDate: z.date().nullable(),
    shipmentsTags: tagsSchema,
    status: z.string().default("TRAP"),
    allShipmentsPlanned: z.boolean(),
    allShipmentsPlannedForFuture: z.boolean(),
    allFuturePlanDates: z.array(z.number()),
    someShipmentsPlanned: z.boolean(),
    trailer: equipmentTrapSchema.nullable(),
    straightTruck: equipmentTrapSchema.nullable(),
  })
  .transform(summaryTransformation)
  .transform((input) => ({
    ...input,
    tags: sortShipmentTags(input.shipmentsTags),
    shipmentsTags: sortShipmentTags(input.shipmentsTags),
  }));

export const trapsSchema = z.array(trapSchema);

export type Trap = z.infer<typeof trapSchema>;

import { IconProps, iconFillColors } from "./Icon.types";

export default function PiecesIcon({
  fill = iconFillColors.primaryDark,
  size = 16,
}: Readonly<IconProps>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10188_389045)">
        <path
          d="M21.1875 13.125H18.375V16.5L17.25 15.7512L16.125 16.5V13.125H13.3125C13.0031 13.125 12.75 13.3781 12.75 13.6875V20.4375C12.75 20.7469 13.0031 21 13.3125 21H21.1875C21.4969 21 21.75 20.7469 21.75 20.4375V13.6875C21.75 13.3781 21.4969 13.125 21.1875 13.125ZM7.6875 10.875H15.5625C15.8719 10.875 16.125 10.6219 16.125 10.3125V3.5625C16.125 3.25312 15.8719 3 15.5625 3H12.75V6.375L11.625 5.62617L10.5 6.375V3H7.6875C7.37813 3 7.125 3.25312 7.125 3.5625V10.3125C7.125 10.6219 7.37813 10.875 7.6875 10.875ZM9.9375 13.125H7.125V16.5L6 15.7512L4.875 16.5V13.125H2.0625C1.75312 13.125 1.5 13.3781 1.5 13.6875V20.4375C1.5 20.7469 1.75312 21 2.0625 21H9.9375C10.2469 21 10.5 20.7469 10.5 20.4375V13.6875C10.5 13.3781 10.2469 13.125 9.9375 13.125Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_10188_389045">
          <rect
            width="20.25"
            height="18"
            fill="white"
            transform="translate(1.5 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

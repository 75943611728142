import { ServiceCenterGeocodeResponse } from "../../types/serviceCenter.type";

export const mockServiceCenterGeocode = {
  default: [
    {
      sicId: 111,
      lat: 34.1122,
      lng: -117.7437,
    },
  ],
} as const satisfies Record<string, ServiceCenterGeocodeResponse>;

import Box from "@mui/material/Box";
import { TableSortLabel as MuiTableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { flexRender, Header, SortDirection } from "@tanstack/react-table";

export const getSortDirection = (
  value: false | SortDirection
): SortDirection | undefined =>
  typeof value === "boolean" ? undefined : value;

type Props<TData> = {
  header: Header<TData, unknown>;
};

const TableSortLabel = <TData,>({ header }: Props<TData>) => {
  if (header.isPlaceholder) return null;

  return !header.column.getCanSort() ? (
    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
  ) : (
    <MuiTableSortLabel
      active={!!header.column.getIsSorted()}
      direction={getSortDirection(header.column.getIsSorted())}
      onClick={header.column.getToggleSortingHandler()}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
      {header.column.getIsSorted() ? (
        <Box component="span" sx={visuallyHidden}>
          {header.column.getIsSorted() === "desc"
            ? "sorted descending"
            : "sorted ascending"}
        </Box>
      ) : null}
    </MuiTableSortLabel>
  );
};

export default TableSortLabel;

import { Filter, Option } from "../../../types/filter";
import {
  Prefix,
  getUniqueOptions,
  handlePrefix,
  isActiveFilter,
} from "../utils";

export type PiecesQueryParam = "pieces";
export type SomethingWithPieces = Record<PiecesQueryParam, number>;

const getPieceOptions = (data: SomethingWithPieces[]): Option[] =>
  getUniqueOptions(
    data
      .map(({ pieces }) => ({
        label: pieces.toLocaleString(),
        value: pieces,
      }))
      .sort((a, b) => a.value - b.value)
  );

export const getPiecesFilter = (
  data: SomethingWithPieces[],
  prefix?: Prefix
): Filter => ({
  label: "Pieces",
  queryParam: handlePrefix("pieces", prefix),
  options: getPieceOptions(data),
});

export const filterPieces = <T extends SomethingWithPieces>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter((entry) => filters.includes(entry.pieces.toString()))
    : data;

import { Box, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { formatNumber } from "../../../constants/utils";
import { TotalizerContext } from "./TotalizerRoot";

type TotalizerContentProps<T> = {
  property: keyof T | "length";
  name: string;
};

export const TotalizerContent = <T,>({
  name,
  property,
}: TotalizerContentProps<T>) => {
  const context = useContext(TotalizerContext);
  const theme = useTheme();

  if (!context) {
    throw new Error("Totalizer.Content must be used within a Totalizer.Root");
  }

  const total: number =
    property === "length"
      ? context.data.length || 0
      : context.data.reduce(
          (total, item) =>
            total +
            (Number.isInteger(item[property]) ? Number(item[property]) : 0),
          0
        );

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", gap: "4px" }}>
        <Typography
          sx={{
            fontSize: "34px",
            lineHeight: "38px",
            fontWeight: 400,
            color: theme.palette.grey[900],
          }}
        >
          {formatNumber(total)}
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 400,
            color: theme.palette.grey[600],
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

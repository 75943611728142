import { z } from "zod";

export const shiftSchema = z.object({
  day: z.string(),
  shiftStartTime: z.string(),
  shiftEndTime: z.string(),
  lunchBreakLength: z.string(),
  lunchBreakStartTime: z.string(),
});

export type Shift = z.infer<typeof shiftSchema>;

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import { Block } from "@mui/icons-material";
import { colors, Typography } from "@mui/material";

export type UnauthorizedErrorDialogProps = {
  message: string;
  close: () => void;
};

export const UnauthorizedErrorDialog = ({
  message,
  close,
}: UnauthorizedErrorDialogProps) => (
  <Dialog open onClose={() => close()} maxWidth="sm" fullWidth>
    <DialogTitle sx={{ fontWeight: 500, pt: 3, pb: 1 }}>
      <Box component="span" display="flex" alignItems="center">
        <Block sx={{ fill: colors.red[700] }} />
        <Typography variant="h6" component="span" sx={{ ml: 1 }}>
          Access Denied
        </Typography>
      </Box>
    </DialogTitle>
    <Box position="absolute" top={10} right={10}>
      <IconButton aria-label="Close" onClick={() => close()}>
        <Close />
      </IconButton>
    </Box>
    <DialogContent sx={{ py: 2 }}>
      <Typography color={colors.grey[800]}>{message}</Typography>
    </DialogContent>
    <DialogActions sx={{ fontWeight: 500, pt: 1, pb: 3, px: 3 }}>
      <Button variant="contained" onClick={() => close()}>
        Okay
      </Button>
    </DialogActions>
  </Dialog>
);

import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { TimePickerProps, TimeValidationError } from "@mui/x-date-pickers";
import { useMemo, useState } from "react";
import { TimePicker } from "../../../components/shared/TimePicker";

interface StartTimeDatePickerProps extends TimePickerProps<Dayjs> {
  width: string;
}

export default function StartTimeDatePicker({
  width,
  ...rest
}: StartTimeDatePickerProps) {
  const [error, setError] = useState<TimeValidationError | null>(null);
  const errorMessage = useMemo(() => {
    switch (error) {
      case "maxTime":
      case "minTime": {
        return "Please select a valid time";
      }
      case "invalidDate": {
        return "Start Time is not valid";
      }
      default: {
        return "";
      }
    }
  }, [error]);

  return (
    <Box>
      <TimePicker
        {...rest}
        onError={setError}
        slotProps={{
          textField: {
            required: true,
            sx: { width: { width } },
            helperText: errorMessage,
          },
        }}
      />
    </Box>
  );
}

import dayjs from "dayjs";
import { TrapTrailers } from "../../hooks/react-query/outbound/linehaulTrailers/useTrapTrailers";

export const trapTrailersResponse = {
  default: {
    summary: {
      bills: 523,
      pieces: 1488,
      pallets: 852,
      weight: 619912,
      trailers: 64,
    },
    trailers: [
      {
        trapId: 84509,
        trapNumber: "TRAP-084509",
        trapName: "R-DAL010",
        trailerNumber: "DAL010",
        serviceDue: dayjs("2024-09-20T00:00:00"),
        bills: 4,
        pieces: 1,
        pallets: 5,
        weight: 2577,
        shipmentTags: [],
        status: "TRAP",
        doorNumber: null,
        workerName: "",
      },
      {
        trapId: 84154,
        trapNumber: "TRAP-084154",
        trapName: "R-DAL010",
        trailerNumber: "DAL010",
        serviceDue: null,
        bills: 0,
        pieces: 0,
        pallets: 0,
        weight: 0,
        shipmentTags: [],
        status: "TRAP",
        doorNumber: null,
        workerName: "",
      },
    ],
  },
} as const satisfies Record<string, TrapTrailers>;

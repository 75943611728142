import { z } from "zod";
import { SummaryContextType } from "../pages/inbound/shared/ActionsMenuContext";

export const assignShipmentTypeSchema = z.enum([
  "trap",
  "section",
  "route",
  "bucket",
  "shipment",
]);

export type AssignShipmentType = z.infer<typeof assignShipmentTypeSchema>;

type AssignShipment = {
  destinationType: AssignShipmentType;
  destinationId: string;
  shipmentIds: Array<number>;
  sourceType: AssignShipmentType;
  sourceId: string;
  prePlanningType?: SummaryContextType;
  isDroppedOnHeader?: boolean;
};

type UnassignShipment = {
  shipmentIds: Array<number>;
  sourceType: AssignShipmentType;
  sourceId: string;
};

type ReorderShipment = {
  routeId: number;
  shipmentIds: number[];
  sourceStopSeq: number;
  destinationStopSeq: number;
};

export type ReorderShipmentsFn = (data: ReorderShipment) => void;
export type AssignShipmentFn = (data: AssignShipment) => void;
export type UnassignShipmentFn = (data: UnassignShipment) => void;

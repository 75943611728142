import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { Link } from "@mui/icons-material";
import { useLocation, useSearchParams } from "react-router-dom";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";

type CopyLinkMenuItemProps = {
  routesOrTraps: PlanTableFormat[];
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
};

export const CopyLinkMenuItem = ({
  routesOrTraps,
  handleClose,
}: CopyLinkMenuItemProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const copyRoutesOrTrapsLink = () => {
    searchParams.delete("selection");
    const newSearch = new URLSearchParams(searchParams);
    let search = newSearch.toString();
    if (routesOrTraps.length >= 1) {
      search += `&selection=${routesOrTraps.map((routeOrTrap) => routeOrTrap.id).join(",")}`;
    }
    const fullUrl = `${window.location.protocol}//${window.location.host}${location.pathname}${search ? `?${search}` : ""}`;

    handleClose?.();

    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        toast(toastMessage.inbound.copyLink.success, {
          variant: "success",
        });
      })
      .catch(() => {
        toast(toastMessage.inbound.copyLink.error, {
          variant: "error",
        });
      });
  };

  return (
    <MenuItem divider onClick={copyRoutesOrTrapsLink}>
      <ListItemIcon>
        <Link />
      </ListItemIcon>
      <Button sx={labelButtonStyle} disableRipple>
        Copy Link
      </Button>
    </MenuItem>
  );
};

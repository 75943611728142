import {
  Drawer as MuiDrawer,
  DrawerProps,
  styled as muiStyled,
} from "@mui/material";

type Props = DrawerProps & {
  width?: number | string;
};

export const Drawer = muiStyled((props: Props) => (
  <MuiDrawer variant="persistent" {...props} />
))(({ width = "20%", open }) => ({
  overflow: "scroll",
  width: open ? width : "0",
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    borderTop: "1px solid lightgray",
    position: "absolute",
    width: open ? width : "0",
  },
}));

import { SIC, SicContact } from "../../hooks/react-query/linehaul/useSics";

export const mockSicDetails = {
  default: {
    id: 174,
    code: "ABE",
    ownership: "V",
    address: "100 Hilton Street Easton PA",
    phone: null,
    openDay: null,
    closedDay: null,
    openTime: null,
    closedTime: null,
    guardOnsite: false,
    securityPhone: null,
    allDayDrop: false,
    gateCode: null,
    contacts: [
      {
        id: 236,
        firstName: "Joe",
        lastName: "Doe",
        email: "mail@server.com",
        role: "Manager",
      },
    ],
    companyName: null,
  },
} satisfies Record<string, SIC>;

export const mockSicContact = {
  default: {
    id: 236,
    firstName: "Joe",
    lastName: "Doe",
    email: "mail@server.com",
    role: "Manager",
  },
} satisfies Record<string, SicContact>;

import { Filter } from "../../../types/filter";

import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
} from "../utils";

export type ConsigneeNameQueryParam = "consigneeName";
export type SomethingWithConsigneeName = {
  consignee?: {
    name?: string;
  };
};

export const getConsigneeNameFilter = (
  data: SomethingWithConsigneeName[],
  prefix?: Prefix
): Filter => ({
  label: "Consignee Name",
  queryParam: handlePrefix("consigneeName", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.consignee?.name))
  ),
});

export const filterConsigneeName = <T extends SomethingWithConsigneeName>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ consignee }) => filters.includes(consignee?.name || ""))
    : data;

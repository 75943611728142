import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { AccountCircleRounded, Person } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { signOut } from "aws-amplify/auth";
import ApexLogo from "../assets/apex-logo.svg";
import { useCurrentUser } from "../hooks/react-query/settings/security/useUsers";

export default function UserMenu() {
  const user = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        id="user-menu-button"
        variant="outlined"
        color="inherit"
        size="medium"
        sx={{ borderColor: "divider", px: 1, py: 0.5 }}
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label={`${open ? "Close" : "Open"} user menu`}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Stack direction="row" gap={1}>
          <img src={ApexLogo} alt="Apex Logo" height={32} />
          <Stack
            height={32}
            width={32}
            borderRadius={16}
            bgcolor="grey.300"
            justifyContent="center"
            alignItems="center"
          >
            <Person sx={{ color: "action.active" }} />
          </Stack>
        </Stack>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "user-menu-button",
        }}
      >
        {user.data && (
          <Box>
            <MenuItem onClick={handleClose} sx={{ minWidth: 320 }}>
              <Stack direction="row" alignItems="center" gap={2}>
                <AccountCircleRounded
                  fontSize="large"
                  sx={{ color: grey[400] }}
                />
                <Stack direction="column">
                  <Typography variant="body1">
                    {user.data.firstName} {user.data.lastName}
                  </Typography>
                  <Typography variant="body2">{user.data.email}</Typography>
                </Stack>
              </Stack>
            </MenuItem>

            <Divider />
          </Box>
        )}

        <MenuItem disabled onClick={handleClose}>
          Profile Settings
        </MenuItem>
        <MenuItem onClick={() => signOut()}>Log Out</MenuItem>
      </Menu>
    </>
  );
}

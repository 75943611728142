import {
  BaseSelectProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  colors,
  styled,
} from "@mui/material";
import { ReorderShipmentsFn } from "../../../types/assignShipment.type";

type SelectOption = { stopSeq: number; id: number };

interface OldStopSequenceSelectProps extends BaseSelectProps {
  /** The id of the route the shipments are in */
  routeId: number;
  /** The stop sequence value of the shipment */
  stopSeq: number;
  /** Array of stop sequence options */
  options: SelectOption[];
  /** Function to be called when a reorder target is selected */
  onSelectReorderTarget: ReorderShipmentsFn;
}

const StyledSelect = styled(Select)(() => ({
  minWidth: "60px",
  backgroundColor: colors.grey[100],
  ":hover": {
    backgroundColor: colors.grey[200],
  },
  "&.Mui-focused": {
    backgroundColor: colors.grey[300],
  },
  fieldset: {
    border: "none",
  },
}));

const OldStopSequenceSelect = ({
  routeId,
  stopSeq,
  options,
  onSelectReorderTarget,
}: OldStopSequenceSelectProps) => {
  const handleClickSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    // Select all shipments that have the origin stop sequence and
    // put them into the selected stop sequence
    onSelectReorderTarget({
      shipmentIds: options
        .filter((option) => option.stopSeq === stopSeq)
        .map((option) => option.id),
      destinationStopSeq: Number(e.target.value),
      sourceStopSeq: stopSeq,
      routeId,
    });
  };

  // Shipments with the same address have the same stop sequence
  // So populate the stop sequence dropdown with a unique list of stop sequences
  const uniqueStopSeqOptions: SelectOption[] = options.filter(
    (option, index, options) =>
      index === options.findIndex((x) => x.stopSeq === option.stopSeq)
  );

  return (
    <StyledSelect
      size="small"
      onClick={handleClickSelect}
      value={stopSeq}
      onChange={handleChange}
    >
      {uniqueStopSeqOptions.map((option) => (
        <MenuItem
          disabled={option.stopSeq === stopSeq}
          key={option.stopSeq}
          value={option.stopSeq}
          style={{ display: option.stopSeq === stopSeq ? "none" : "block" }}
        >
          {option.stopSeq}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default OldStopSequenceSelect;

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";
import {
  PrintResult,
  usePrintBulkReport,
} from "../../../hooks/react-query/usePrint";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { RowData } from "./types";

type PrintTableContextValue = {
  rows: RowData[];
  setRows: Dispatch<React.SetStateAction<RowData[]>>;
  toggleCheckbox: (id: number, checkbox: keyof RowData) => void;
  handlePrint: () => void;
  isLoadingPrintIds: number[];
  toggleCheckboxForAll: (dataIndex: keyof RowData) => void;
};

type BulkPrintPayload = {
  sicId: number;
  planDate: string;
  cityLoadCard?: { routes: number[] };
  deliveryManifest?: { routes: number[] };
  deliveryReceipt?: { routes: number[] };
  trapLoadCard?: { trapIds: number[] };
};

type PrintTableProviderTypes = {
  children: ReactNode;
  initialRows: RowData[];
  setIsPrintDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const PrintTableContext = createContext<PrintTableContextValue | null>(null);

export const usePrintTableContext = (): PrintTableContextValue => {
  const context = useContext(PrintTableContext);
  if (!context) {
    throw new Error(
      "usePrintTableContext must be used within a PrintTableProvider"
    );
  }
  return context;
};

export const PrintTableProvider = ({
  children,
  initialRows,
  setIsPrintDialogOpen,
}: PrintTableProviderTypes) => {
  const [rows, setRows] = useState<RowData[]>(initialRows);
  const [isLoadingPrintIds, setIsLoadingPrintIds] = useState<number[]>([]);
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  const [serviceCenter] = useSelectedServiceCenter();
  const { mutate: printBulkReport } = usePrintBulkReport(
    (result: PrintResult) => {
      if (result.state === "Processed") {
        window.open(result.url, "_blank");
      }
    },
    (printIds: number[]) => {
      setIsLoadingPrintIds(
        isLoadingPrintIds.filter((id) => !printIds.includes(id))
      );

      setIsPrintDialogOpen(false);
    }
  );

  const toggleCheckbox = useCallback((id: number, checkbox: keyof RowData) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, [checkbox]: !row[checkbox] } : row
      )
    );
  }, []);

  const toggleCheckboxForAll = useCallback(
    (dataIndex: keyof RowData) => {
      const allSelected = rows.every((row) => row[dataIndex]);

      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          [dataIndex]: !allSelected,
        }))
      );
    },
    [rows]
  );

  const handlePrint = useCallback(() => {
    const selectedRows = rows.filter(
      (row) =>
        row.isInboundLoadCard ||
        row.isDeliveryManifest ||
        row.isDeliveryReceipts ||
        row.isTrapLoadCard
    );
    setIsLoadingPrintIds([
      ...isLoadingPrintIds,
      ...selectedRows.map((r) => r.id),
    ]);

    const bulkPrintPayload: BulkPrintPayload = {
      sicId: serviceCenter.id,
      planDate: selectedDate,
    };

    selectedRows.forEach((row) => {
      if (row.type === "delivery_route") {
        if (row.isInboundLoadCard) {
          if (!bulkPrintPayload.cityLoadCard) {
            bulkPrintPayload.cityLoadCard = { routes: [] };
          }
          bulkPrintPayload.cityLoadCard.routes.push(row.id);
        }
        if (row.isDeliveryManifest) {
          if (!bulkPrintPayload.deliveryManifest) {
            bulkPrintPayload.deliveryManifest = { routes: [] };
          }
          bulkPrintPayload.deliveryManifest.routes.push(row.id);
        }
        if (row.isDeliveryReceipts) {
          if (!bulkPrintPayload.deliveryReceipt) {
            bulkPrintPayload.deliveryReceipt = { routes: [] };
          }
          bulkPrintPayload.deliveryReceipt.routes.push(row.id);
        }
      }
      if (row.type === "delivery_trap") {
        if (!bulkPrintPayload.trapLoadCard) {
          bulkPrintPayload.trapLoadCard = { trapIds: [] };
        }
        bulkPrintPayload.trapLoadCard.trapIds.push(row.id);
      }
    });

    printBulkReport(bulkPrintPayload);
  }, [rows, printBulkReport, isLoadingPrintIds, serviceCenter, selectedDate]);

  const printTableContextValue = useMemo(
    () => ({
      rows,
      setRows,
      toggleCheckbox,
      handlePrint,
      isLoadingPrintIds,
      toggleCheckboxForAll,
    }),
    [rows, toggleCheckbox, handlePrint, isLoadingPrintIds, toggleCheckboxForAll]
  );

  return (
    <PrintTableContext.Provider value={printTableContextValue}>
      {children}
    </PrintTableContext.Provider>
  );
};

import { z } from "zod";

export const tagSchema = z.object({
  id: z.number().optional(),
  name: z.string(),
  description: z.string(),
  colorHex: z.string().nullable(),
  position: z.number().or(z.string()).nullable().optional(),
});

export const tagsSchema = z.array(tagSchema);
export type Tag = z.infer<typeof tagSchema>;

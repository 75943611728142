import dayjs from "dayjs";
import { TripForReassign } from "../../hooks/react-query/linehaul/useLoadBoard";

export const mockTripsForReassign = {
  empty: [],
  default: [
    {
      tripNumber: 3100766,
      origin: {
        id: 103,
        code: "ATB-SEFL",
        ownership: "V",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      destination: {
        id: 2,
        code: "LAX",
        ownership: "C",
        lhRegionId: 5,
        timeZone: "America/Los_Angeles",
      },
      stops: [],
      carrierName: "SCHNEIDER NATIONAL CARRIERS INC.",
      carrierCode: "L021",
      pickupDate: dayjs("2024-09-23T00:00:00"),
      loadingDate: dayjs("2024-09-23T00:00:00"),
      gateCutDay: 2,
      gateCutTime: "16:30:00",
      bookingNumber: "TEST_BOOKING",
      employeeDrivers: [],
    },
    {
      tripNumber: 31007696,
      origin: {
        id: 103,
        code: "ATB-SEFL",
        ownership: "V",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      destination: {
        id: 2,
        code: "LAX",
        ownership: "C",
        lhRegionId: 5,
        timeZone: "America/Los_Angeles",
      },
      stops: [],
      carrierName: "TEST NATIONAL CARRIERS INC.",
      carrierCode: "L021",
      pickupDate: dayjs("2024-09-23T00:00:00"),
      loadingDate: dayjs("2024-09-23T00:00:00"),
      gateCutDay: 2,
      gateCutTime: "16:30:00",
      bookingNumber: "NEW_BOOKING",
      employeeDrivers: [],
    },
  ],
} as const satisfies Record<string, TripForReassign[]>;

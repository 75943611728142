import { z } from "zod";

const assignmentType = z.enum(["Trap", "Route"]);
export const assignmentSchema = z.object({
  id: z.number().positive(),
  type: assignmentType,
  name: z.string(),
  status: z.string(),
});
export const planningAssignmentsSchema = z.array(assignmentSchema);
type AssignmentType = z.infer<typeof assignmentType>;
type Assignment = z.infer<typeof assignmentSchema>;
type PlanningAssignments = z.infer<typeof planningAssignmentsSchema>;

export type AssignmentDetails = {
  name: string;
  type: AssignmentType;
  extraAssignmentsCount: number;
  status: string;
};

export const getAssignment = (
  planningAssignment: PlanningAssignments,
  inUseByInfo?: Assignment | null
): AssignmentDetails | null => {
  if (inUseByInfo) {
    return {
      name: inUseByInfo.name,
      type: inUseByInfo.type,
      status: inUseByInfo.status,
      extraAssignmentsCount: 0,
    };
  } else if (planningAssignment.length > 0) {
    return {
      name: planningAssignment[0].name,
      type: planningAssignment[0].type,
      status: planningAssignment[0].status,
      extraAssignmentsCount: planningAssignment.length - 1,
    };
  } else {
    return null;
  }
};

// CSpell:ignore Geocodes
import { useQuery } from "@tanstack/react-query";
import {
  ServiceCenter,
  ServiceCenterGeocodeResponse,
  serviceCenterGeocodeResponseSchema,
  serviceCenterResponseSchema,
} from "../../types/serviceCenter.type";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";

const byCode = (a: ServiceCenter, b: ServiceCenter) =>
  a.code.localeCompare(b.code);

const byNameAndOwnership = (serviceCenter: ServiceCenter) =>
  serviceCenter.name && serviceCenter.ownership === "C";

export const serviceCentersQueryKey = (includeInactive = false) => [
  "getServiceCenters",
  includeInactive ? "all" : "active",
];

export const getServiceCenters = async (includeInactive = false) => {
  const { data } = await http.get<ServiceCenter[]>(apiUrls.getServiceCenters, {
    // TODO: diagnostic with BE Team if we can change these params to camelCase
    // eslint-disable-next-line camelcase
    params: { show_inactive: includeInactive },
  });
  return serviceCenterResponseSchema.parse(data);
};

export const useServiceCenters = (
  type: "All" | "CompanyOwned",
  includeInactive = false
) =>
  useQuery({
    staleTime: Infinity,
    queryKey: serviceCentersQueryKey(includeInactive),
    queryFn: () => getServiceCenters(includeInactive),
    select: (data) =>
      type === "CompanyOwned"
        ? data.filter(byNameAndOwnership).sort(byCode)
        : data.sort(byCode),
  });

export const useServiceCenterGeocodes = (sicIds: number[]) =>
  useQuery({
    queryKey: ["getServiceCentersGeocode", sicIds],
    queryFn: async () => {
      const { data, status } = await http.get<ServiceCenterGeocodeResponse[]>(
        apiUrls.getServiceCenterGeocodeByIds(sicIds)
      );
      if (status === 204) return [];
      return serviceCenterGeocodeResponseSchema.parse(data);
    },
  });

import { Box } from "@mui/material";
import { memo } from "react";
import { formatDate, intToDate } from "../../../utils/dateTimeHelper";
import { LightTooltip } from "../LightTooltip";
import StatusTag from "./StatusTag";

type PlannedStatusTagProps = {
  /** Label displayed in the tag. */
  label: string;

  /** When `today`, the tag is green with white text.
   * When `future` the tag is white with green outline and black text.
   * When `undefined`, the tag is gray with black text.
   */
  isPlannedFor?: "today" | "future";

  /** Array of numbers that represents the future plan dates, YYYYMMDD format. When provided, the dates are displayed via a tooltip on hover. */
  futurePlanDates?: number[];

  /** Selected plan date in YYYY-MM-DD format. */
  selectedDate: string;
};

const PlannedStatusTag = ({
  label,
  isPlannedFor,
  futurePlanDates = [],
  selectedDate,
}: PlannedStatusTagProps) => {
  const statusTag = <StatusTag label={label} isPlannedFor={isPlannedFor} />;

  const commaDelimitedFuturePlanDates = futurePlanDates
    .map((date) => formatDate(intToDate(date)))
    .join(", ");

  const isPlannedForToday = isPlannedFor === "today";
  return isPlannedForToday || futurePlanDates.length > 0 ? (
    <LightTooltip
      title={
        isPlannedForToday
          ? `Plan for ${formatDate(selectedDate)}${
              futurePlanDates.length > 0
                ? ` and future planned for ${commaDelimitedFuturePlanDates}`
                : ""
            }`
          : `Future planned for ${commaDelimitedFuturePlanDates}`
      }
    >
      <Box>{statusTag}</Box>
    </LightTooltip>
  ) : (
    statusTag
  );
};

export default memo(PlannedStatusTag);

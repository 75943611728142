export const mostFrequent = (arr: number[]): number | null => {
  if (arr.length === 0) return null;
  const hashmap = arr.reduce((acc: { [key: number]: number }, val: number) => {
    if (!val) return acc;
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {});
  return Object.keys(hashmap)
    .map(Number)
    .reduce((a: number, b: number) => (hashmap[a] > hashmap[b] ? a : b));
};

import { SummaryContext } from "../pages/inbound/shared/ActionsMenuContext";

// This function unifies the array of shipments if multiple shipments with same id are present
export const unifyShipmentContext = (shipments: SummaryContext[]) =>
  shipments.reduce(
    (accumulator: SummaryContext[], currentItem: SummaryContext) => {
      const existing = accumulator.find((item) => item.id === currentItem.id);
      if (existing) {
        existing.shipmentIds = existing.shipmentIds.concat(
          currentItem.shipmentIds
        );
        existing.shipmentIds = [...new Set(existing.shipmentIds)];
      } else {
        accumulator.push(currentItem);
      }
      return accumulator;
    },
    []
  );

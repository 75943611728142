import { Box, Typography, type SxProps } from "@mui/material";
import { prePlanningViewOptions } from "../PrePlanning";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { PrePlanningToggleViewGroup } from "./PrePlanningToggleViewGroup";

interface SectionTitleProps {
  page: string;
  count?: number;
  showToggles?: boolean;
  align?: "center" | "flex-end" | "flex-start";
  containerStyles?: SxProps;
}

const SectionTitle = ({
  page,
  count,
  showToggles = true,
  align = "flex-end",
  containerStyles,
}: SectionTitleProps) => {
  const isRoutesAndSectionsEnabled = useIsFeatureFlagEnabled(
    "inbound-routes-and-section-tabs"
  );
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="space-between"
      marginBottom="1.5rem"
      sx={containerStyles}
    >
      <Box display="flex" alignItems={align}>
        <Typography
          marginRight="0.5rem"
          fontSize={32}
          fontWeight="400"
          lineHeight="42px"
        >
          {page}
        </Typography>
        {count && (
          <Typography
            fontSize="24px"
            fontWeight="500"
            lineHeight="38px"
            sx={{ opacity: 0.6 }}
          >
            ({count})
          </Typography>
        )}
      </Box>
      {showToggles && (
        <PrePlanningToggleViewGroup
          options={prePlanningViewOptions(isRoutesAndSectionsEnabled)}
        />
      )}
    </Box>
  );
};

export default SectionTitle;

import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Typography,
  capitalize,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo, useRef } from "react";
import { CellContext, ColumnDef, SortingState } from "@tanstack/react-table";
import { Business, MenuBook, Route, ViewModule } from "@mui/icons-material";
import pluralize from "pluralize";
import {
  SELECTED_DATE_PARAM_KEY,
  useDateSearchParamOrFallbackToToday,
} from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import SectionTitle from "../prePlanning/tables/SectionTitle";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { useShipmentDetailSearch } from "../../../hooks/react-query/preplanning/useShipmentDetails";
import { shipmentColumns } from "../../../components/shared/table/shipmentsColumnDef";
import Table, { TableRef } from "../../../components/shared/table/Table";
import { RowSelectionMode } from "../../../components/shared/table/Selectable.types";
import { useSearchTermParam } from "../../../hooks/useSearchTermParam";
import { useIsSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { Totalizer } from "../../../components/shared/totalizer";
import {
  filterShipmentData,
  getShipmentTableFilters,
} from "../../../constants/filters/shipmentTableFilters";
import { PageNotFound } from "../../PageNotFound";
import { Shipment } from "../../../services/prePlanningService.types";
import { contextMenuActions } from "../prePlanning/tables/contextMenuActions";
import { getDroppableId } from "../../../utils/dragging";
import { TableShipment } from "../../../components/shared/old-table/ShipmentsTableBase";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { InboundView } from "../../../types/inbound/inboundView.type";
import { useFilterSearchParams } from "../../../hooks/useFilterSearchParams";
import { keepSpecificParams } from "../../../utils/searchParamHelper";
import { UNPLANNED_SELECTED_DATE } from "../../../hooks/useUnplannedDatetimeSearchParam";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";
import { unifyShipmentContext } from "../../../utils/unifyShipmentContext";
import { DragMultipleShipments } from "../../../components/shared/DragMultipleShipments";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import Filters from "./Filter/Filters";
import { SummaryContext, useActionsMenuContext } from "./ActionsMenuContext";
import InboundActionBar from "./InboundActionBar";
import TableActionBar, {
  TableSection,
  tableSectionSchema,
} from "./TableActionBar";

const VIEW_TABLE_OPTIONS = [
  {
    tab: "route",
    icon: <Route />,
  },
  {
    tab: "section",
    icon: <Business />,
  },
  {
    tab: "trap",
    icon: <ViewModule />,
  },
  {
    tab: "bucket",
    icon: <MenuBook />,
  },
] as const;

type ShipmentTableProps = {
  view: InboundView;
  rowSelectionMode: RowSelectionMode;
  showPresetRouteColumn?: boolean;
  isDraggable?: boolean;
  haveContextMenu?: boolean;
  defaultSort?: SortingState;
};

const ShipmentsTable = ({
  /** inbound page */
  view,
  /** define the mode of selection */
  rowSelectionMode = "unselectable",
  /** default sort array */
  defaultSort = [],
  isDraggable = false,
  haveContextMenu,
  showPresetRouteColumn = false,
}: ShipmentTableProps) => {
  const { selection, setSelection } = useTableSelectionParam();
  const isNewTableEnabled = useIsFeatureFlagEnabled(
    "inbound-new-table-design-client"
  );
  const isEditingEnabled = useIsSelectedDateTodayOrInTheFutureForCurrentSic();
  const theme = useTheme();
  const { id = "" } = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const tableRef = useRef<TableRef>(null);
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);
  const { openDialog } = useActionsMenuContext();
  const [searchTerm, setSearchTerm] = useSearchTermParam();
  const debouncedSearch = useDebouncedSearch(searchTerm);
  const [filters] = useFilterSearchParams({
    presetRouteName: [],
    trailerNumber: [],
    serviceDueDate: [],
    appointmentDate: [],
    shipperName: [],
    consigneeName: [],
    consigneeAddress: [],
    consigneeCity: [],
    consigneeZip: [],
    destinationSicCode: [],
    status: [],
    tags: [],
    pieces: [],
    pallets: [],
    weight: [],
    eta: [],
  });

  const { trailerTitle, shipmentIds } = useMemo(
    function getSearchParams() {
      const searchParams = new URLSearchParams(search);
      const trailerTitle = searchParams.get("trailerTitle");
      const shipmentIdsParam = searchParams.get("shipmentIds");
      const shipmentIds = shipmentIdsParam
        ? shipmentIdsParam.split(",").map((id) => Number(id))
        : [];
      return { trailerTitle, shipmentIds } as const;
    },
    [search]
  );

  const { data: shipments = [], isLoading } = useShipmentDetailSearch({
    search: {
      shipmentIds,
      searchTerm: debouncedSearch,
      sicId: serviceCenter.id,
      planDate,
    },
  });

  const filteredShipments = filterShipmentData(
    shipments,
    filters,
    serviceCenter.timeZone
  );

  const shipmentFilters = useMemo(
    () =>
      getShipmentTableFilters(shipments, serviceCenter.timeZone, searchParams),
    [searchParams, serviceCenter.timeZone, shipments]
  );

  const pathToMainTable = useMemo(
    function createPathToMainTable() {
      let pathSegments = pathname.split("/").filter(Boolean);
      if (pathSegments.length > 1) {
        pathSegments = pathSegments.slice(0, pathSegments.length - 2);
        return "/" + pathSegments.join("/");
      }
    },
    [pathname]
  );

  const mainTableSection: TableSection = useMemo(() => {
    const fragments = pathToMainTable?.split("/") || "";
    const sectionValidation = tableSectionSchema.safeParse(
      pluralize.singular(fragments[fragments.length - 1])
    );
    if (!sectionValidation.success) {
      throw new Error("Table section not defined");
    }
    return sectionValidation.data;
  }, [pathToMainTable]);

  const selectionToContext = useCallback(
    (shipment?: Shipment): SummaryContext[] => {
      if (shipment) {
        return [
          {
            id: id || "",
            shipmentIds: [shipment.id],
            type: "shipment",
          },
        ];
      }
      return unifyShipmentContext(
        selection.map((shipmentId) => {
          const shipment = filteredShipments.find(
            (shipment) => shipment.id.toString() === shipmentId
          );
          return {
            id,
            shipmentIds: shipment ? [shipment.id] : [],
            type: "shipment",
          };
        })
      );
    },
    [filteredShipments, id, selection]
  );

  const clearSelection = () => {
    tableRef.current?.clearSelection();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preColumns: ColumnDef<TableShipment, any>[] = [
    {
      id: "drag",
      header: "",
      enableSorting: false,
      cell: useCallback(
        ({ cell, row }: CellContext<TableShipment, any>) =>
          isDraggable && <DragMultipleShipments row={row} cell={cell} />,
        [isDraggable]
      ),
    },
  ];

  const columns = useMemo(
    () =>
      shipmentColumns({
        timeZone: serviceCenter.timeZone,
        selectedDate,
        showPresetRouteColumn,
      }),
    [selectedDate, serviceCenter.timeZone, showPresetRouteColumn]
  );

  const commonProps = useMemo(
    () => ({
      ref: tableRef,
      caption: `${id} shipments`,
      isLoading,
      columns,
      preColumns,
      data: filteredShipments,
      defaultSort,
      droppableTableId: getDroppableId("shipmentTable", "shipment", id),
    }),
    [columns, defaultSort, filteredShipments, id, isLoading, preColumns]
  );

  if (!isNewTableEnabled) {
    return <PageNotFound />;
  }

  return (
    <>
      <Breadcrumbs sx={{ marginBottom: 2 }} aria-label="shipment-breadcrumbs">
        <Link
          to={{
            pathname: pathToMainTable ?? "/",
            search: keepSpecificParams(new URLSearchParams(search), [
              SELECTED_DATE_PARAM_KEY,
              UNPLANNED_SELECTED_DATE,
            ]).toString(),
          }}
        >
          {capitalize(view)}
        </Link>
        <Box display="flex" alignItems="center" gap={1} color="text.primary">
          {VIEW_TABLE_OPTIONS.map(
            (view) =>
              view.tab === mainTableSection && (
                <Box
                  key={view.tab}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 24,
                    height: 24,
                    backgroundColor: theme.palette.grey[200],
                    borderRadius: 1,
                    svg: {
                      fontSize: 18,
                      color: theme.palette.grey[600],
                    },
                  }}
                >
                  {view.icon}
                </Box>
              )
          )}
          <Typography>{trailerTitle}</Typography>
        </Box>
      </Breadcrumbs>
      <SectionTitle
        page={capitalize(trailerTitle ?? "Not defined")}
        count={shipments.length}
        showToggles={false}
      />
      <Totalizer.Root data={shipments}>
        <Totalizer.Content name="Bills" property="length" />
        <Totalizer.Content name="Plts" property="pallets" />
        <Totalizer.Content name="Pcs" property="pieces" />
        <Totalizer.Content name="lbs" property="weight" />
      </Totalizer.Root>

      <TableActionBar
        isShipmentTable
        section={mainTableSection}
        selectedRows={shipments.filter((shipment) =>
          selection.includes(shipment.id.toString())
        )}
        clearSelection={tableRef.current?.clearSelection}
        actions={
          isEditingEnabled && (
            <InboundActionBar
              selectedRowsContext={selectionToContext()}
              activeTab={view}
              contextMenuActions={contextMenuActions({
                shipmentContext: selectionToContext(),
                section: view,
                openDialog,
                clearSelection,
              })}
            />
          )
        }
      >
        <Filters
          filters={shipmentFilters}
          showEta
          placeholder="Search PRO Number, Shipper or Consignee Name"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </TableActionBar>

      {rowSelectionMode !== "unselectable" && isEditingEnabled ? (
        <Table
          {...commonProps}
          getRowId={(row) => `${row.id}`}
          rowSelectionMode={rowSelectionMode}
          onRowSelection={setSelection}
          contextMenuActions={
            haveContextMenu
              ? (shipment) =>
                  contextMenuActions({
                    shipmentContext: selectionToContext(shipment),
                    section: view,
                    openDialog,
                    clearSelection,
                  })
              : undefined
          }
        />
      ) : (
        <Table {...commonProps} />
      )}
    </>
  );
};

export default ShipmentsTable;

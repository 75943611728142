import { EmployeeDriver } from "../../hooks/react-query/linehaul/useLinehaulCarriers";

export const mockEmployeeDrivers = {
  default: [
    {
      id: 9770,
      code: "A02760",
      name: "DIAZ, MARIO ANTONIO",
      ratePerMile: 0,
      order: 0,
      loadBoardScheduleId: 0,
      driverId: 0,
    },
    {
      id: 10010,
      code: "A02832",
      name: "CORTEZ, ANGEL ",
      ratePerMile: 0,
      order: 0,
      loadBoardScheduleId: 0,
      driverId: 0,
    },
    {
      id: 10085,
      code: "B02809",
      name: "COVARRUBIAS, GUILLERMO CAMPOS",
      ratePerMile: 0,
      order: 0,
      loadBoardScheduleId: 0,
      driverId: 0,
    },
    {
      id: 10255,
      code: "C02762",
      name: "HEREDIA, EDGAR ",
      ratePerMile: 0,
      order: 0,
      loadBoardScheduleId: 0,
      driverId: 0,
    },
    {
      id: 9982,
      code: "D02811",
      name: "CHICAS JR., JOSE ERIC",
      ratePerMile: 0,
      order: 0,
      loadBoardScheduleId: 0,
      driverId: 0,
    },
    {
      id: 10247,
      code: "E02740",
      name: "KALER, MAHINDER ",
      ratePerMile: 0,
      order: 0,
      loadBoardScheduleId: 0,
      driverId: 0,
    },
    {
      id: 9980,
      code: "E02788",
      name: "CABRAL, DANIEL ",
      ratePerMile: 0,
      order: 0,
      loadBoardScheduleId: 0,
      driverId: 0,
    },
  ],
} as const satisfies Record<string, EmployeeDriver[]>;

import { Button, ListItemIcon, MenuItem } from "@mui/material";
import MergeTypeRoundedIcon from "@mui/icons-material/MergeTypeRounded";
import {
  useActionsMenuContext,
  type SummaryContext,
} from "../../shared/ActionsMenuContext";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import type { ValidateMergeRequestResult } from "../../../../hooks/useValidateMergeRequest";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";

interface MergeMenuItemProps {
  clearSelection: () => void;
  shipmentContext: SummaryContext[];
  planSummaries: PlanTableFormat[];
  validateMergeRequest: () => ValidateMergeRequestResult;
}

const MergeMenuItem = ({
  clearSelection,
  shipmentContext,
  planSummaries,
  validateMergeRequest,
}: MergeMenuItemProps) => {
  const { openDialog } = useActionsMenuContext();

  return (
    <MenuItem
      onClick={() => {
        const warningMessage = validateMergeRequest();
        if (warningMessage) {
          toast(toastMessage.inbound.mergeRoutesAndTraps[warningMessage], {
            variant: "warning",
          });
          return;
        }
        openDialog({
          name: "merge-routes-traps",
          shipmentContext,
          clearSelection,
          planSummaries,
        });
      }}
    >
      <ListItemIcon>
        <MergeTypeRoundedIcon />
      </ListItemIcon>
      <Button sx={labelButtonStyle} disableRipple>
        Merge
      </Button>
    </MenuItem>
  );
};

export default MergeMenuItem;

import { Summary } from "../../hooks/react-query/dispatch/useSummary";

export const mockSummary = {
  default: {
    routes: {
      total: 68,
      pending: 8,
      dispatched: 18,
      returning: 12,
      completed: 30,
    },
    stops: {
      total: 144,
      pending: 28,
      unassigned: 7,
      completed: 89,
      incomplete: 20,
    },
    deliveries: {
      total: 99,
      pending: 14,
      completed: 62,
      incomplete: 23,
    },
    pickups: {
      total: 75,
      pending: 21,
      unassigned: 11,
      completed: 33,
      incomplete: 10,
    },
  },
} as const satisfies Record<string, Summary>;

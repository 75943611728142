import { Row } from "@tanstack/react-table";
import { useCallback, useState, Ref } from "react";
import dayjs from "dayjs";
import { EditedRoute } from "../../../../hooks/react-query/useDeliveryRoutes.types";
import { routeTrapType } from "../../../../constants/RouteTrapTypes";
import {
  ReorderShipmentsFn,
  UnassignShipmentFn,
} from "../../../../types/assignShipment.type";
import BaseTable, {
  TableBaseRef,
} from "../../../../components/shared/old-table/BaseTable";
import {
  SelectedTableRows,
  TableSelectionContextProvider,
} from "../../../../components/shared/old-table/TableSelectionContext";
import { SummaryContext } from "../../shared/ActionsMenuContext";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { EditRouteConfirmDialog, EditedPlan } from "../EditRouteConfirmDialog";
import { EditRouteConfirmDialogOld } from "../EditRouteConfirmDialogOld";
import { getDroppableId } from "../../../../utils/dragging";
import { ContextMenuAction } from "../../../../types/contextMenuAction";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { planningStatus } from "../../../../types/status.type";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import { ErrorMap } from "../../../../components/shared/table/table.types";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import oldPlanColumns from "../oldPlanColumnsDef";
import OldPlanShipmentsTable from "./OldPlanShipmentsTable";

type PlanTableProps = {
  tableRef: Ref<TableBaseRef>;
  refetch: () => void;
  searchTerm: string;
  planSummaries: PlanTableFormat[];
  isLoading: boolean;
  onUnassignShipments: UnassignShipmentFn;
  onEditRoute: (route: EditedRoute) => void;
  onRowSelection: (
    selectedRouteOrTrap: SelectedTableRows<PlanTableFormat>
  ) => void;
  autoSequencingRouteIds: number[];
  setIsEmptySelected: (isEmptySelected: boolean) => void;
  selectedShipments: SummaryContext[];
  onScrollChanged: (value: number) => number;
  onReorderShipments: ReorderShipmentsFn;
  /** Map of row id to row error. */
  rowErrors: ErrorMap;
  contextMenuActions: () => ContextMenuAction[];
  handlePrint: (row: PlanTableFormat) => void;
  isLoadingPrintIds: number[];
};

// isEquipmentRestricted.ts
type EquipmentRestrictionParams = {
  type: "delivery_trap" | "delivery_route";
  status: string;
  doorId?: number | null;
  trailerId?: number | null;
  straightTruckId?: number | null;
};

export const isEquipmentRestricted = ({
  type,
  status,
  doorId,
  trailerId,
  straightTruckId,
}: EquipmentRestrictionParams): {
  restricted: boolean;
  missingEquipment?: string;
} => {
  const isRestrictedStatus = ["CLDK", "CLDV", "DISP", "TRAP"].includes(status);

  const equipment =
    type === "delivery_trap"
      ? [
          { key: "Door", value: !doorId },
          {
            key: "Trailer / Straight Truck",
            value: !trailerId && !straightTruckId,
          },
        ]
      : [{ key: "Door", value: !doorId }];

  const missingEquipment = equipment.find((equip) => equip.value)?.key;

  if (isRestrictedStatus && missingEquipment) {
    return { restricted: true, missingEquipment };
  }

  return { restricted: false };
};

const PlanTable = ({
  tableRef,
  refetch,
  searchTerm,
  planSummaries,
  isLoading,
  onReorderShipments,
  onUnassignShipments,
  onEditRoute,
  onRowSelection,
  autoSequencingRouteIds,
  setIsEmptySelected,
  selectedShipments,
  onScrollChanged,
  rowErrors,
  contextMenuActions = () => [],
  handlePrint,
  isLoadingPrintIds,
}: PlanTableProps) => {
  const editGuardrailsPhase2Enabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-2-client"
  );

  const isRemoveDoorNotAllowed = useIsFeatureFlagEnabled(
    "inbound-remove-door-not-allowed-client"
  );

  const [plan, setPlan] = useState<PlanTableFormat>();
  const [serviceCenter] = useSelectedServiceCenter();

  const shipmentsTable = (row: Row<PlanTableFormat>) => (
    <OldPlanShipmentsTable
      refetch={refetch}
      shipments={row.original.shipments}
      parentRow={row}
      parentId={row.original.id}
      parentType={routeTrapType[row.original.type]}
      isParentSelected={row.getIsSelected()}
      onUnassignShipments={onUnassignShipments}
      selectedShipments={selectedShipments}
      onReorderShipments={onReorderShipments}
      contextMenuActions={contextMenuActions}
      numberOfBills={row.original.bills}
    />
  );

  const handleRowSelection = useCallback(
    (selectedRows: SelectedTableRows<PlanTableFormat>) => {
      const isEmptySelected = selectedRows.rows.some(
        (s) => s.shipmentIds.length === 0 && !s.isCommitted
      );
      setIsEmptySelected(isEmptySelected);
      onRowSelection(selectedRows);
    },
    [setIsEmptySelected, onRowSelection]
  );

  const handleEditPlan = (row: PlanTableFormat) => {
    if (row.status in planningStatus) {
      setPlan(row);
    } else {
      toast(toastMessage.inbound.editNotAllowedStatus, {
        variant: "warning",
      });
    }
  };

  const editHandler = (editedPlan: EditedPlan) => {
    if (!plan) return;
    const editedRoute: EditedRoute = {
      id: editedPlan.id,
      date: plan.date,
      sicId: plan.sicId,
      name: editedPlan.name,
      presetRouteId: plan.presetRouteId,
      driverId: editedPlan.driverId,
      isCommitted: Boolean(plan.isCommitted),
      endPoint: editedPlan.routeEnd ?? "",
      type: plan.type,
      trailerEquipmentId: editedPlan.trailerEquipmentId,
      tractorEquipmentId: editedPlan.tractorEquipmentId,
      straightTruckEquipmentId: editedPlan.straightTruckEquipmentId,
    };

    if (editedPlan.startTime) {
      editedRoute.startTime = dayjs(editedPlan.startTime, {
        utc: false,
      }).toISOString();
    }

    if (editedPlan.endTime) {
      editedRoute.endTime = dayjs(editedPlan.endTime).utc().toISOString();
    }
    if (editedPlan.doorId) {
      editedRoute.doorId = editedPlan.doorId;
    }
    if (isRemoveDoorNotAllowed) {
      const { restricted, missingEquipment } = isEquipmentRestricted({
        type: plan.type,
        status: plan.status,
        doorId: editedRoute.doorId,
        trailerId: editedRoute.trailerEquipmentId,
        straightTruckId: editedRoute.straightTruckEquipmentId,
      });

      if (restricted && missingEquipment) {
        const errorMessage =
          toastMessage.inbound.removeEquipmentNotAllowedFromTrapOrRoute(
            missingEquipment,
            plan.type === "delivery_trap" ? "Trap" : "Route"
          );
        toast(errorMessage, { variant: "warning" });
        return;
      }
    }

    onEditRoute(editedRoute);
    cancelHandler();
  };

  const cancelHandler = () => {
    setPlan(undefined);
  };

  const columns = oldPlanColumns({
    isEditable: true,
    onEditRow: handleEditPlan,
    onPrintRow: handlePrint,
    timeZone: serviceCenter.timeZone,
    autoSequencingRouteIds,
    rowErrors,
    isLoadingPrintIds,
    printCityCardLoadEnabled: useIsFeatureFlagEnabled(
      "inbound-print-city-card-load-manifest-client"
    ),
  });

  return (
    <TableSelectionContextProvider>
      <BaseTable
        ref={tableRef}
        expandAllRows={Boolean(searchTerm)}
        caption="planning"
        columns={columns}
        isLoading={isLoading}
        data={planSummaries}
        rowSelectionMode="checkbox"
        getRowId={(row) => String(row.id)}
        isCollapsible
        collapsibleChild={shipmentsTable}
        droppableId={(row) =>
          getDroppableId("mainTable", row.original.type, row.original.id)
        }
        onRowSelection={handleRowSelection}
        onContainerScroll={onScrollChanged}
        contextMenuActions={contextMenuActions}
        rowErrors={rowErrors}
        defaultSort={[
          {
            id: "name",
            desc: false,
          },
        ]}
      />
      {plan &&
        (editGuardrailsPhase2Enabled ? (
          <EditRouteConfirmDialog
            plan={plan}
            open
            savePlan={editHandler}
            cancelHandler={cancelHandler}
          />
        ) : (
          <EditRouteConfirmDialogOld
            plan={plan}
            open
            savePlan={editHandler}
            cancelHandler={cancelHandler}
          />
        ))}
    </TableSelectionContextProvider>
  );
};

export default PlanTable;

import { Doors } from "../../../hooks/react-query/useDoors";
import { getAssignment } from "../../../types/assignment.type";

export const doorAvailability = {
  ASSIGNED: "Assigned Doors",
  AVAILABLE: "Available Doors",
} as const;

export const getDoorOptionsFromDoorNumbersOld = (doorNumbers: number[]) =>
  doorNumbers.map((doorNumber) => ({
    name: `Door ${doorNumber}`,
    value: doorNumber,
  }));

export const getDoorOptionsFromDoors = (doors: Doors) =>
  doors
    .map((door) => ({
      name: door.id.toString().padStart(3, "0"),
      value: door.id,
      group: door.inUseByInfo
        ? doorAvailability.ASSIGNED
        : doorAvailability.AVAILABLE,
      assignment: getAssignment(door.planningAssignmentsInfo, door.inUseByInfo),
    }))
    .sort((a, b) => b.group.localeCompare(a.group));

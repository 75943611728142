import { Amplify } from "aws-amplify";
import { ConsoleLogger } from "aws-amplify/utils";
import { env } from "../utils/env";

export const configureAmplify = () => {
  ConsoleLogger.LOG_LEVEL = "INFO"; // We write INFO level logs throughout app

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: env.VITE_COGNITO_USER_POOL_ID,
        userPoolClientId: env.VITE_COGNITO_USER_POOL_CLIENT_ID,
        loginWith: {
          oauth: {
            domain: env.VITE_COGNITO_DOMAIN,
            redirectSignIn: [env.VITE_COGNITO_SIGN_IN_URL],
            redirectSignOut: [env.VITE_COGNITO_SIGN_OUT_URL],
            scopes: ["phone", "email", "openid"],
            responseType: "code",
          },
        },
      },
    },
  });
};

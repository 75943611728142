/** Used to control table row selection */
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { TableShipment } from "./ShipmentsTableBase";

export type SelectedRowIdMap = Record<string, boolean>;

export type RowWithIdAndShipmentIds<T> = T & {
  rowId: string;
  selected: boolean;
  shipmentIds?: number[];
};

export type ShipmentWithId = TableShipment & {
  rowId: string;
  selected: boolean;
  parentId: string;
};

export type SelectedTableRows<T> = {
  rows: RowWithIdAndShipmentIds<T>[];
  shipments: ShipmentWithId[];
};

type HandleSelectionArgs<T> =
  | {
      type: "trip";
      selectedRows: RowWithIdAndShipmentIds<T>[];
      filter?: boolean;
    }
  | {
      type: "shipment";
      selectedRows: ShipmentWithId[];
      rowIds: string[];
    };

type TableSelectionContextValue<T> = {
  /** Selection data state */
  selectedRows: SelectedTableRows<T>;
  /** Handle the selection and its logic, removing or adding new items */
  handleSelect: ({ selectedRows, type }: HandleSelectionArgs<T>) => void;
  /** Clear all selection state */
  clearSelection: () => void;
  /** Clear parent shipments, filtering by parent id */
  deselectAllParentShipments: (parentId: string) => void;
  /** Return true if there are shipments selected in a given parentId  */
  someShipmentsSelected: (parentId: string) => boolean;
  /** The total count of selected shipments */
  selectedShipmentsCount: number;
};

const TableSelectionContext =
  createContext<TableSelectionContextValue<any> | null>(null);

type SelectionContextProviderProps = {
  children: ReactNode;
};

const defaultSelection = {
  rows: [],
  shipments: [],
};

export function TableSelectionContextProvider<T>({
  children,
}: SelectionContextProviderProps) {
  const [selectedRows, setSelectedRows] =
    useState<SelectedTableRows<T>>(defaultSelection);

  const handleSelect = (args: HandleSelectionArgs<T>) => {
    if (args.type === "trip") {
      setSelectedRows((prev) => ({
        rows: args.selectedRows,
        shipments: args.filter
          ? prev.shipments.filter((shipment) =>
              args.selectedRows.some((row) => row.rowId === shipment.parentId)
            )
          : prev.shipments,
      }));
    } else {
      const toRemove: string[] = [];
      for (const rowId of args.rowIds) {
        if (!args.selectedRows.some((r) => r.rowId === rowId)) {
          toRemove.push(rowId);
        }
      }
      const filtered = selectedRows.shipments
        .filter((row) => !toRemove.includes(row.rowId))
        .concat(args.selectedRows);

      const uniqueShipments = filtered.reduce(
        (acc: ShipmentWithId[], shipment) => {
          if (
            !acc.find((item: ShipmentWithId) => item.rowId === shipment.rowId)
          ) {
            acc.push(shipment);
          }
          return acc;
        },
        []
      );

      setSelectedRows((prev) => ({
        rows: prev.rows,
        shipments: uniqueShipments,
      }));
    }
  };

  const selectedShipmentsCount = useMemo(() => {
    const unifiedShipments = [
      ...new Set([
        ...selectedRows.rows.flatMap((row) => row.shipmentIds),
        ...selectedRows.shipments.map((shipment) => shipment.id),
      ]),
    ];
    return unifiedShipments.length;
  }, [selectedRows.rows, selectedRows.shipments]);

  const clearSelection = useCallback(() => {
    setTimeout(() => {
      setSelectedRows(defaultSelection);
    }, 0);
  }, []);

  const someShipmentsSelected = (parentId: string) =>
    selectedRows.shipments.some((shipment) => shipment.parentId === parentId);

  const deselectAllParentShipments = (parentId: string) => {
    setSelectedRows((prev) => ({
      rows: prev.rows,
      shipments: selectedRows.shipments.filter(
        (ship) => ship.parentId !== parentId
      ),
    }));
  };

  return (
    <TableSelectionContext.Provider
      value={{
        selectedRows,
        handleSelect,
        clearSelection,
        deselectAllParentShipments,
        someShipmentsSelected,
        selectedShipmentsCount,
      }}
    >
      {children}
    </TableSelectionContext.Provider>
  );
}

export function useTableSelectionContext<T>(): TableSelectionContextValue<T> {
  const tableSelectionContext = useContext(TableSelectionContext);
  if (!tableSelectionContext) {
    throw new Error(
      "useTableSelectionContext must be used within TableSelectionContextProvider"
    );
  }
  return tableSelectionContext;
}

import { Box, IconButton, Tooltip } from "@mui/material";
import type { Dispatch, SetStateAction } from "react";
import { Info } from "@mui/icons-material";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { LockRouteIconButton } from "./LockRouteIconButton";
import { CopyLinkIconButton } from "./CopyLinkIconButton";
import { PrintIconButton } from "./PrintIconButton";
import { EditIconButton } from "./EditIconButton";

type ShipmentTableIconActionsProps = {
  routeOrTrap: PlanTableFormat;
  clearSelection: () => void;
  isInfoBarOpen: boolean;
  setIsInfoBarOpen: Dispatch<SetStateAction<boolean>>;
};

export const ShipmentTableIconActions = ({
  routeOrTrap,
  clearSelection,
  isInfoBarOpen = false,
  setIsInfoBarOpen,
}: ShipmentTableIconActionsProps) => (
  <Box display="flex" alignItems="center">
    {routeOrTrap.type === "delivery_route" && (
      <LockRouteIconButton
        route={routeOrTrap}
        clearSelection={clearSelection}
      />
    )}
    <CopyLinkIconButton />
    <PrintIconButton routeOrTrap={routeOrTrap} />
    <EditIconButton routeOrTrap={routeOrTrap} />
    <Tooltip
      title={`${routeOrTrap.type === "delivery_route" ? "Route" : "Trap"} information`}
    >
      <IconButton onClick={() => setIsInfoBarOpen(!isInfoBarOpen)}>
        <Info />
      </IconButton>
    </Tooltip>
  </Box>
);

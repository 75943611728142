import { Doors } from "../../../hooks/react-query/useDoors";

export const doorAvailability = {
  ASSIGNED: "Assigned Doors",
  AVAILABLE: "Available Doors",
} as const;

export const getDoorOptionsFromDoors = (doors: Doors) =>
  doors
    .map((door) => ({
      name: door.id.toString().padStart(3, "0"),
      value: door.id,
      group: door.inUseByInfo
        ? doorAvailability.ASSIGNED
        : doorAvailability.AVAILABLE,
      inUseBy: door.inUseByInfo,
    }))
    .sort((a, b) => b.group.localeCompare(a.group));

import { Box } from "@mui/material";
import { useMemo } from "react";
import { isDeepEqual } from "remeda";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { LoadingButton } from "../../../../components/shared/LoadingButton";
import { useAddToPlan } from "../../../../hooks/react-query/useAddToPlan";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { SummaryContext } from "../../shared/ActionsMenuContext";
import { Appointment } from "../../../../services/prePlanningService.types";
import { Trap } from "../../../../types/planning/trap.type";
import { loadingButtonStyle } from "./StyledMenuButton";

interface AddToPlanActionButtonProps {
  selectedTrapOrBucket: Array<Trap | Appointment>;
  selectedRowsContext: SummaryContext[];
  clearSelection?: () => void;
}

const AddToPlanActionButton = ({
  selectedTrapOrBucket,
  selectedRowsContext,
  clearSelection,
}: AddToPlanActionButtonProps) => {
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter] = useSelectedServiceCenter();

  const selectedShipmentIds = selectedRowsContext.flatMap(
    (trapOrBucket) => trapOrBucket.shipmentIds
  );

  const isEnabledWhileSearching = useMemo(
    function enableWhileSearching() {
      return isDeepEqual(
        selectedRowsContext.flatMap((row) => row.shipmentIds),
        selectedShipmentIds
      );
    },
    [selectedRowsContext, selectedShipmentIds]
  );

  const addToPlan = useAddToPlan({
    date: dateToInt(selectedDate),
    sicId: serviceCenter.id,
    shipmentIds: selectedShipmentIds,
    onSuccess: (affectedShipmentsCount: number) => {
      clearSelection?.();
      toast(
        toastMessage.inbound.addToPlan.successAdding(affectedShipmentsCount),
        {
          variant: "success",
        }
      );
    },
  });

  const handleAddToPlan = () => {
    if (
      selectedRowsContext.every((r) => r.type === "bucket") ||
      selectedRowsContext.every((r) => r.type === "shipment")
    ) {
      return addToPlan.mutate();
    }

    if (selectedTrapOrBucket.length === 0 || !isEnabledWhileSearching) {
      toast(toastMessage.inbound.addToPlan.selectOne, {
        variant: "warning",
      });
    } else if (
      selectedTrapOrBucket.every(
        (trapOrBucket) => trapOrBucket.shipmentsCount === 0
      )
    ) {
      toast(toastMessage.inbound.addToPlan.emptyTrapSelected, {
        variant: "warning",
      });
    } else if (
      selectedTrapOrBucket.every(
        (trapOrBucket) => trapOrBucket.allShipmentsPlanned === true
      )
    ) {
      toast(toastMessage.inbound.addToPlan.plannedTrapSelected, {
        variant: "warning",
      });
    } else {
      addToPlan.mutate();
    }
  };

  return (
    <Box display="flex">
      <LoadingButton
        isDisabled={!isEnabledWhileSearching}
        isLoading={addToPlan.isPending}
        sx={loadingButtonStyle}
        color="secondary"
        onClick={handleAddToPlan}
        loadingLabel="ADDING..."
      >
        ADD TO PLAN
      </LoadingButton>
    </Box>
  );
};

export default AddToPlanActionButton;

import { EquipmentsResponse } from "../../hooks/react-query/outbound/freightDetails/useGetEquipments";

export const equipmentList = {
  default: {
    available: [
      {
        equipmentId: 23878,
        trailerNumber: "ONTGHOST13",
        doorNumber: null,
        status: "EMTY",
      },
      {
        equipmentId: 23894,
        trailerNumber: "DYLT242201",
        doorNumber: null,
        status: "EMTY",
      },
    ],
    assigned: [
      {
        equipmentId: 23935,
        trailerNumber: "DYLT195042",
        doorNumber: null,
        status: "ENRT",
      },
      {
        equipmentId: 23961,
        trailerNumber: "DYLT205016",
        doorNumber: null,
        status: "CLOS",
      },
    ],
    loadAndHold: [
      {
        tripNumber: 869430,
        equipmentId: 1,
        trailerNumber: "ABC-123",
        status: "CLOS",
        sic: "ONT",
      },
      {
        tripNumber: 746589,
        equipmentId: 3,
        trailerNumber: "ZWE-123",
        status: "CLOS",
        sic: "",
      },
    ],
  },
} as const satisfies Record<string, EquipmentsResponse>;

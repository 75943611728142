import dayjs from "dayjs";
import { DriverLatestLocation } from "../../hooks/react-query/dispatch/useDrivers";

export const mockDriverLatestLocations = {
  default: [
    {
      routeId: 1,
      driverId: 123,
      sicId: 1001,
      timestamp: dayjs().subtract(15, "minutes").unix(),
      lastDriverPosition: {
        latitude: 34.018906,
        longitude: -117.47837,
      },
      heading: 95,
    },
    {
      routeId: 2,
      driverId: 102,
      sicId: 1002,
      timestamp: dayjs().subtract(30, "minutes").unix(),
      lastDriverPosition: {
        latitude: 34.0222,
        longitude: -117.2477,
      },
      heading: 110,
    },
    {
      routeId: 3,
      driverId: 103,
      sicId: 1003,
      timestamp: dayjs().subtract(45, "minutes").unix(),
      lastDriverPosition: {
        latitude: 34.0322,
        longitude: -117.1437,
      },
      heading: 220,
    },
    {
      routeId: 4,
      driverId: 104,
      sicId: 1004,
      timestamp: dayjs().subtract(60, "minutes").unix(),
      lastDriverPosition: {
        latitude: 33.8522,
        longitude: -117.9037,
      },
      heading: 360,
    },
    {
      routeId: 5,
      driverId: 105,
      sicId: 1005,
      timestamp: dayjs().subtract(10, "minutes").unix(),
      lastDriverPosition: {
        latitude: 34.063747,
        longitude: -117.273466,
      },
      heading: 90,
    },
    {
      routeId: 6,
      driverId: 106,
      sicId: 1006,
      timestamp: dayjs().subtract(20, "minutes").unix(),
      lastDriverPosition: {
        latitude: 34.0422,
        longitude: -117.1287,
      },
      heading: 180,
    },
    {
      routeId: 7,
      driverId: 107,
      sicId: 1007,
      timestamp: dayjs().subtract(35, "minutes").unix(),
      lastDriverPosition: {
        latitude: 34.0822,
        longitude: -117.2237,
      },
      heading: 270,
    },
  ],
  empty: [],
} as const satisfies Record<string, DriverLatestLocation[]>;

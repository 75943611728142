import { Box, MenuItem } from "@mui/material";
import { useState } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ContextMenuAction } from "../../../../types/contextMenuAction";
import StyledMenuButton from "./StyledMenuButton";
import { ActionBarStyledMenu } from "./ActionBarStyledMenu";

interface AssignActionButtonProps {
  assignActions: ContextMenuAction[];
}

export default function AssignActionButton({
  assignActions,
}: AssignActionButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const individualAssignAction = assignActions[0];

  return (
    <Box display="flex">
      {assignActions.length === 1 ? (
        <StyledMenuButton
          startIcon={individualAssignAction.icon}
          onClick={individualAssignAction.handleClick}
        >
          {individualAssignAction.label}
        </StyledMenuButton>
      ) : (
        <>
          <StyledMenuButton
            // eslint-disable-next-line no-type-assertion/no-type-assertion
            onClick={(e) => setAnchorEl(e.currentTarget as HTMLElement)}
            startIcon={<AssignmentIcon />}
            showExpandIcon
          >
            Assign
          </StyledMenuButton>
          <ActionBarStyledMenu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
          >
            {assignActions.map((action) => (
              <MenuItem key={action.barLabel} onClick={action.handleClick}>
                {action.icon} {action.barLabel}
              </MenuItem>
            ))}
          </ActionBarStyledMenu>
        </>
      )}
    </Box>
  );
}

import { Route } from "../../types/route.type";

export const EMPTY_ROUTE_OBJECT: Route = {
  id: 0,
  name: "",
  sicId: 0,
  zones: [],
  zipCodes: [],
  startPoint: "",
  startTime: "",
  endPoint: "",
  endTime: "",
  trailerId: null,
  tractorId: null,
  straightTruckId: null,
  doorId: 0,
  driverId: 0,
  isActive: false,
  hereFeatureId: null,
  zoneIds: [],
  isRoute: true,
};

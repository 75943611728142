export const loadTrailerNotesResponse = {
  default: [
    {
      id: 1,
      content: "Initial draft of the pull plan",
      userName: "John Doe",
      timeStamp: new Date("2023-09-01T10:00:00Z"),
      isEdited: false,
      canEdit: false,
    },
    {
      id: 2,
      content: "Updated the pull plan with new details",
      userName: "Jane Smith",
      timeStamp: new Date("2023-09-05T15:30:00Z"),
      isEdited: true,
      canEdit: false,
    },
    {
      id: 3,
      content: "Reviewed the plan and added comments",
      userName: "Emily Davis",
      timeStamp: new Date("2023-09-10T09:45:00Z"),
      isEdited: true,
      canEdit: true,
    },
  ],
};

import { Box, Chip, useTheme } from "@mui/material";
import { memo } from "react";

interface StatusTagProps {
  /** Label displayed in the tag. */
  label: string;

  /** When `today`, the tag is green with white text.
   * When `future` the tag is white with green outline and black text.
   * When `undefined`, the tag is gray with black text.
   */
  isPlannedFor?: "today" | "future";

  /** Display the tag as a pill or plain text. */
  type?: "pill" | "plain";
}

const StatusTag = ({ label, isPlannedFor, type = "pill" }: StatusTagProps) => {
  const theme = useTheme();

  const tagStyles = {
    isPlannedForToday: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
      borderColor: undefined,
      border: "none",
    },
    isPlannedForFuture: {
      color: theme.palette.success.main,
      backgroundColor: theme.palette.common.white,
      border: "2px solid",
      borderColor: theme.palette.success.main,
    },
    unplanned: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[300],
      border: "none",
      borderColor: undefined,
    },
  };

  const tagStyle =
    isPlannedFor === "today"
      ? tagStyles.isPlannedForToday
      : isPlannedFor === "future"
        ? tagStyles.isPlannedForFuture
        : tagStyles.unplanned;

  const style: React.CSSProperties = {
    textTransform: "uppercase",
    fontWeight: 550,
    ...tagStyle,
  };

  const tagType =
    type === "pill" ? (
      <Chip
        component="span"
        label={label}
        size="small"
        variant="outlined"
        sx={style}
      />
    ) : (
      <Box component="span" sx={style}>
        {label}
      </Box>
    );

  return tagType;
};
export default memo(StatusTag) as (props: StatusTagProps) => React.JSX.Element;

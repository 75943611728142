import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { ChevronLeft, Menu, Settings } from "@mui/icons-material";
import Stack from "@mui/system/Stack";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import UserMenu from "../../../pages/UserMenu";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { LinehaulMenu } from "../../../pages/linehaul/LinehaulMenu";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { DepartmentChildLink, DepartmentSelect } from "./DepartmentSelect";
import { ServiceCenterSelect } from "./ServiceCenterSelect";
import { useDepartments } from "./useDepartments";

const GLOBAL_NAV_MENU_PARAM = "global_menu_open";

const useGlobalNavMenu = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const menuOpen = searchParams.get(GLOBAL_NAV_MENU_PARAM) === "true";
  const toggleMenu = () => {
    if (menuOpen) {
      searchParams.delete(GLOBAL_NAV_MENU_PARAM);
    } else {
      searchParams.set(GLOBAL_NAV_MENU_PARAM, "true");
    }
    setSearchParams(searchParams);
  };

  return { menuOpen, toggleMenu };
};

const GlobalMenu = () => {
  const { pathname } = useLocation();
  if (pathname.startsWith("/linehaul")) return <LinehaulMenu />;

  return null;
};

const GlobalNavMenu = () => {
  const { menuOpen, toggleMenu } = useGlobalNavMenu();
  const { pathname } = useLocation();
  const isLinehaulEnabled = useIsFeatureFlagEnabled(
    "linehaul-client-global-menu"
  );

  const departments = [
    {
      name: "Linehaul",
      active: isLinehaulEnabled,
      route: "/linehaul",
    },
  ];

  if (!departments.find((d) => pathname.startsWith(d.route))?.active) {
    return null;
  }

  return (
    <Drawer
      anchor="left"
      open={menuOpen}
      onClose={toggleMenu}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Paper
        elevation={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
          width: 420,
          borderRadius: 0,
        }}
      >
        <Stack p={2} height="100%">
          <Box flex={1}>
            <GlobalMenu />
          </Box>
          <Stack direction="row" gap={4} alignItems="center">
            <IconButton aria-label="Collapse Global Menu" onClick={toggleMenu}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="body1" fontWeight={400}>
              Collapse
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Drawer>
  );
};

export const GlobalNav = () => {
  const { toggleMenu } = useGlobalNavMenu();
  const activeDepartment = useDepartments().find((d) => d.active);
  const [serviceCenter] = useSelectedServiceCenter();

  return (
    <>
      <GlobalNavMenu />
      <AppBar
        position="static"
        variant="outlined"
        color="inherit"
        elevation={0}
        sx={{ height: 64, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Stack direction="row" pl={2} height="100%" alignItems="center">
          <IconButton
            onClick={toggleMenu}
            sx={{ mr: 1 }}
            aria-label="Toggle Global Menu"
          >
            <Menu />
          </IconButton>
          {activeDepartment && (
            <>
              <DepartmentSelect value={activeDepartment} />
              <Divider flexItem sx={{ width: "1px", bgcolor: "divider" }} />
            </>
          )}
          {activeDepartment?.showServiceCenterSelect && (
            <>
              <ServiceCenterSelect />
              <Divider flexItem sx={{ width: "1px", bgcolor: "divider" }} />
            </>
          )}
          {activeDepartment && (
            <Stack direction="row" gap={3} ml={4} alignItems="center">
              {activeDepartment.childRoutes
                .filter((r) => r.enabled)
                .map((tab) => (
                  <DepartmentChildLink key={tab.route} tab={tab} />
                ))}
            </Stack>
          )}
          <Stack flex={1} />
          <Stack direction="row" gap={1} pr={2} alignItems="center">
            {/* commented out until implemented */}
            {/* <TextField
            placeholder="Search"
            sx={{ width: 200, mr: 1 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          /> */}

            <IconButton
              component={RouterLink}
              to={`settings/${serviceCenter.code}`}
              aria-label="Settings"
            >
              <Settings />
            </IconButton>

            <UserMenu />
          </Stack>
        </Stack>
      </AppBar>
    </>
  );
};

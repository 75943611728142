import { z } from "zod";

export const rowErrorSchema = z.object({
  message: z.string(),
  onRetry: z.function().args().returns(z.void()),
  isRetryLoading: z.boolean(),
});

export type RowError = z.infer<typeof rowErrorSchema>;

const errorMapSchema = z.map(z.number(), rowErrorSchema);

export type ErrorMap = z.infer<typeof errorMapSchema>;

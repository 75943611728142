import { IndeterminateCheckBox } from "@mui/icons-material";
import { Checkbox as MuiCheckbox } from "@mui/material";
import { ChangeEvent, MouseEventHandler } from "react";

interface CheckboxProps {
  checked?: boolean;
  name: string;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?:
    | ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  ariaLabel?: string;
}
export function Checkbox({
  checked,
  indeterminate,
  disabled,
  onChange,
  onClick,
  name,
  ariaLabel,
}: CheckboxProps) {
  return (
    <MuiCheckbox
      {...{
        checked,
        indeterminate,
        onChange,
        onClick,
        disabled,
        indeterminateIcon: <IndeterminateCheckBox />,
        sx: { position: "relative", zIndex: 1 },
        name,
        inputProps: { "aria-label": ariaLabel },
      }}
    />
  );
}

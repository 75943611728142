import { MoreHoriz } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { MouseEvent, useState } from "react";
import type { Shipment } from "../../../../services/prePlanningService.types";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";
import PlanShipmentsContextMenu from "./PlanShipmentsContextMenu";

type PlanShipmentsMenuIconButtonProps = {
  routeOrTrap: PlanTableFormat;
  shipment: Shipment;
};

export const PlanShipmentsMenuIconButton = ({
  routeOrTrap,
  shipment,
}: PlanShipmentsMenuIconButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        aria-label={`${open ? "Close" : "Open"} ${shipment.proNumber} menu`}
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <PlanShipmentsContextMenu
        anchor={anchorEl}
        open={open}
        handleClose={handleClose}
        routeOrTrap={routeOrTrap}
        shipment={shipment}
      />
    </>
  );
};

import { Button, DialogActions, useTheme } from "@mui/material";
import { LoadingButton } from "../LoadingButton";
import { buttonStyle } from "../../../pages/inbound/prePlanning/actionbar/StyledMenuButton";
import { usePrintTableContext } from "./PrintTableContext";

interface PrintDialogActions {
  onCancel: () => void;
}

const PrintDialogActions = ({ onCancel }: PrintDialogActions) => {
  const { handlePrint, isLoadingPrintIds, rows } = usePrintTableContext();
  const theme = useTheme();

  return (
    <DialogActions sx={{ marginTop: "16px" }}>
      <Button
        onClick={onCancel}
        sx={{ color: theme.palette.primary.main, ...buttonStyle }}
        variant="text"
        aria-label="cancel-button"
      >
        Cancel
      </Button>
      <LoadingButton
        onClick={handlePrint}
        sx={buttonStyle}
        variant="contained"
        loadingLabel="Generating..."
        isLoading={isLoadingPrintIds.includes(rows[0].id)}
      >
        Print
      </LoadingButton>
    </DialogActions>
  );
};

export default PrintDialogActions;

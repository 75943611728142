import { Filter } from "../../../types/filter";

import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  createDestinationSicOption,
  sortNoneFirst,
} from "../utils";

export type DestinationSICQueryParam = "destinationSicCode";
type SomethingWithDestinationSIC = {
  destinationSicCode: string | null;
};

export const getDestinationSicFilter = (
  data: SomethingWithDestinationSIC[],
  prefix?: Prefix
): Filter => {
  const options = getNonEmptyUniqueSortedOptions(
    data.map((d) => createDestinationSicOption(d.destinationSicCode))
  );

  const sortedOptions = sortNoneFirst(options);
  return {
    label: "Destination SIC Code",
    queryParam: handlePrefix("destinationSicCode", prefix),
    options: sortedOptions,
  };
};

export const filterDestinationSIC = <T extends SomethingWithDestinationSIC>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ destinationSicCode }) =>
        filters.includes("None")
          ? destinationSicCode === null
          : filters.includes(destinationSicCode || "")
      )
    : data;

import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

export const DraggableSelectionCounter = ({ children }: PropsWithChildren) => (
  <Box
    sx={{
      position: "absolute",
      left: "-0.8rem",
      top: "-0.6rem",
      backgroundColor: (theme) => theme.palette.primary.main,
      color: "white",
      width: "1.6rem",
      height: "1.6rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: "bold",
      borderRadius: "50%",
    }}
  >
    {children}
  </Box>
);

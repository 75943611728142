import { PlanSummary } from "../../../types/planning/plan.type";
import { defaultMockServiceCenter } from "../../serviceCenter/serviceCenters.mocks";
import { DeliveryRouteTrapStatus } from "../../../hooks/react-query/useDeliveryRoutesTrapsStatus";
import { RouteAssignmentResponse } from "../../../types/planning/routeAssignmentResponse.type";

export const mockPlannedRoutesTraps: Record<
  "empty" | "default",
  PlanSummary[]
> = {
  empty: [],
  default: [
    {
      type: "delivery_route",
      id: 30055,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "AZUSA-1",
      title: "AZUSA-1",
      status: "CPLN",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 970,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [131365190],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 30058,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "CERRITOS-1",
      title: "CERRITOS-1",
      status: "CPLN",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 490,
      shipmentsWeightUnit: "lbs",
      zone: "South LA County Coast",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [131367485],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 169,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 30053,
      sic: defaultMockServiceCenter.code,
      door: 12,
      routeType: "Static",
      name: "CHULA VISTA-1",
      title: "CHULA VISTA-1",
      status: "CPLN",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 625,
      shipmentsWeightUnit: "lbs",
      zone: "South SD County",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [131364476],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 190,
      isCommitted: true,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_trap",
      id: 3000707,
      sic: defaultMockServiceCenter.code,
      door: 11,
      routeType: "Static",
      name: "SFO",
      title: "SFO",
      status: "CLDK",
      shipmentsPieces: 2,
      shipmentsCount: 4,
      shipmentsPallets: 7,
      shipmentsWeight: 3936,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: { name: "Trailer #2", id: 2, isActive: true },
      straightTruck: null,
      driver: null,
      shipmentIds: [131363799, 131364100, 131364941, 131365260],
      shipmentsTags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: null,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_trap",
      id: 3000705,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "Trap-APPT-APTR",
      title: "Trap-APPT-APTR",
      status: "TPLN",
      shipmentsPieces: 0,
      shipmentsCount: 3,
      shipmentsPallets: 3,
      shipmentsWeight: 1463,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [131356018, 131363858, 131363911],
      shipmentsTags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: null,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_trap",
      id: 3000708,
      sic: defaultMockServiceCenter.code,
      door: 146,
      routeType: "Static",
      name: "VIS",
      title: "VIS",
      status: "TPLN",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 242,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [131364465],
      shipmentsTags: [
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: null,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 3000709,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "DELETABLE_ROUTE_CLDK",
      title: "DELETABLE_ROUTE_CLDK",
      status: "CLDK",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: null,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 3000611,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "DELETABLE_ROUTE_CPLN",
      title: "DELETABLE_ROUTE_CPLN",
      status: "CPLN",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: null,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 3000898,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "EMPTY_ROUTE_CLDV",
      title: "EMPTY_ROUTE_CLDV",
      status: "CLDV",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: null,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_trap",
      id: 30007112,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "EMPTY_TRAP",
      title: "EMPTY_TRAP",
      status: "TPLN",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: null,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_trap",
      id: 3000710,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "Manually sequenced route",
      title: "Manually sequenced route",
      status: "TPLN",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "",
      date: new Date(),
      endPoint: null,
      startTime: null,
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 0,
      isCommitted: true,
      location: "",
      isManuallySequenced: true,
      isAvailableForMassage: true,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 30001,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "BURBANK-1",
      title: "BURBANK-1",
      status: "LTRB",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 970,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [131365190],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: true,
    },
    {
      type: "delivery_route",
      id: 300012,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "BURBANK-2",
      title: "BURBANK-2",
      status: "DEPT",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 970,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: {
        id: 2372,
        name: "EWR406B",
        isActive: true,
      },
      driver: {
        id: 3396,
        name: "DOE, JANE",
        isAvailable: true,
      },
      shipmentIds: [131365190],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 300013,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "MIRA LOMA-1",
      title: "MIRA LOMA-1",
      status: "CLDK",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 970,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: {
        id: 2371,
        name: "EWR406A",
        isActive: false,
      },
      driver: {
        id: 3395,
        name: "DOE, JOHN",
        isAvailable: false,
      },
      shipmentIds: [131365190],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 312313,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "DEACTIVATED DRIVER EQUIPMENT",
      title: "DEACTIVATED DRIVER EQUIPMENT",
      status: "CLDK",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 970,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: {
        id: 4587,
        name: "DEACTIVATED",
        isActive: false,
      },
      driver: {
        id: 4569,
        name: "DEACTIVATED, DEACTIVATED",
        isAvailable: false,
      },
      shipmentIds: [131365190],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 312314,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "AZUSA-2",
      title: "AZUSA-2",
      status: "CLDK",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 312315,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "AZUSA-3",
      title: "AZUSA-3",
      status: "DEPT",
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 970,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [131365190],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 3366697,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "NO BILLS DEPT",
      title: "NO BILLS DEPT",
      status: "DEPT",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 3366698,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "NO BILLS CLDK",
      title: "NO BILLS CLDK",
      status: "CLDK",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
    {
      type: "delivery_route",
      id: 3366699,
      sic: defaultMockServiceCenter.code,
      door: 0,
      routeType: "Static",
      name: "NO BILLS CPLN",
      title: "NO BILLS CPLN",
      status: "CPLN",
      shipmentsPieces: 0,
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      zone: "IE",
      date: new Date(),
      endPoint: null,
      startTime: new Date(),
      endTime: null,
      tractor: null,
      trailer: null,
      straightTruck: null,
      driver: null,
      shipmentIds: [],
      shipmentsTags: [],
      hasSearchTerm: false,
      presetRouteId: 177,
      isCommitted: false,
      location: "",
      isManuallySequenced: false,
      isAvailableForMassage: false,
      sequenceStatus: null,
      canEditStartTime: false,
      isBlockedFromTransition: false,
    },
  ],
};

export const mockRouteStatuses = {
  empty: [],
  default: [
    {
      id: 29,
      name: "CLDK",
      isAvailableForTransition: false,
      transitionConstraints: ["NO_TRAILER_ASSIGNED", "NO_DOOR_ASSIGNED"],
      errorDetails: [
        {
          code: "NO_TRAILER_ASSIGNED",
          subject: "Trailer",
          message: "Please assign a trailer",
        },
      ],
    },
    {
      id: 30,
      name: "CLDV",
      isAvailableForTransition: false,
      transitionConstraints: ["NO_TRAILER_ASSIGNED", "DOOR_IS_NOT_AVAILABLE"],
      errorDetails: [
        {
          code: "NO_TRAILER_ASSIGNED",
          subject: "Trailer",
          message: "Please assign a trailer",
        },
        {
          code: "DOOR_IS_NOT_AVAILABLE",
          subject: "Door",
          message:
            "Door not available Door 12 in use by CHULA VISTA-1. Select another door or move the occupied trailer to change status.",
        },
        {
          code: "SHIPMENTS_NOT_READY_TO_LOAD",
          subject: "Shipment",
          message: "Not all shipments at SIC",
          data: [
            {
              proNumber: "146462148",
              sicCode: null,
              statusName: "DLVR",
            },
            {
              proNumber: "146049374",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "146504733",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "145329363",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "163074677",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "128477221",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "142613439",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "146494638",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "146494604",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "163074958",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "146504691",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "146464235",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "130084874",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "146506209",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "145362257",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "147849913",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "142876622",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "146851258",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "131878100",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "146640263",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "147295349",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "146195383",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "130084924",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "145360871",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "145309118",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "146995964",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "146505466",
              sicCode: "SFO",
              statusName: "ENRT",
            },
            {
              proNumber: "140301029",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "140301060",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "140301052",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "140301128",
              sicCode: "ONT",
              statusName: "DLAY",
            },
            {
              proNumber: "140301110",
              sicCode: "ONT",
              statusName: "DLAY",
            },
          ],
        },
      ],
    },
    {
      id: 32,
      name: "DISP",
      isAvailableForTransition: false,
      transitionConstraints: [
        "NO_TRAILER_ASSIGNED",
        "NO_DOOR_ASSIGNED",
        "NO_DRIVER_ASSIGNED",
        "NO_TRACTOR_ASSIGNED",
      ],
      errorDetails: [
        {
          code: "NO_TRAILER_ASSIGNED",
          subject: "Trailer",
          message: "Please assign a trailer",
        },
        {
          code: "NO_DOOR_ASSIGNED",
          subject: "Door",
          message: "Please assign a door",
        },
        {
          code: "NO_DRIVER_ASSIGNED",
          subject: "Driver",
          message: "Please assign a driver",
        },
        {
          code: "NO_TRACTOR_ASSIGNED",
          subject: "Tractor",
          message: "Please assign a tractor",
        },
      ],
    },
    {
      id: 33,
      name: "DEPT",
      isAvailableForTransition: false,
      transitionConstraints: ["NON_TRANSITIONABLE_STATUS"],
      errorDetails: [
        {
          code: "NON_TRANSITIONABLE_STATUS",
          subject: "Status",
          message: "Can't transition to non-planning status",
        },
      ],
    },
  ],
  allTransitionsAllowed: [
    {
      id: 29,
      name: "CLDK",
      isAvailableForTransition: true,
      transitionConstraints: [],
      errorDetails: [],
    },
    {
      id: 30,
      name: "CLDV",
      isAvailableForTransition: true,
      transitionConstraints: [],
      errorDetails: [],
    },
    {
      id: 32,
      name: "DISP",
      isAvailableForTransition: true,
      transitionConstraints: [],
      errorDetails: [],
    },
  ],
} as const satisfies Record<string, DeliveryRouteTrapStatus[]>;

export const mockTrapStatuses = {
  empty: [],
  default: [
    {
      id: 29,
      name: "CLDK",
      isAvailableForTransition: false,
      transitionConstraints: ["DOOR_IS_NOT_AVAILABLE"],
      errorDetails: [
        {
          code: "DOOR_IS_NOT_AVAILABLE",
          subject: "Door",
          message:
            "Door 146 in use by VIS. Select another door or move the occupied trailer to change status.",
        },
      ],
    },
    {
      id: 31,
      name: "TRAP",
      isAvailableForTransition: true,
      transitionConstraints: [],
      errorDetails: [],
    },
  ],
} as const satisfies Record<string, DeliveryRouteTrapStatus[]>;

export const mockRouteAssignment = {
  default: {
    id: 306008,
    date: 20240510,
    sicId: 1,
    location: null,
    serviceCenterCode: null,
    name: "Atlantic Cty/PA-1",
    startTime: new Date(2024, 5, 10),
    endTime: new Date(1, 1, 1),
    presetRouteId: 289,
    driverId: 4466,
    trailerEquipmentId: 1225,
    doorId: 3,
    isCommitted: true,
    endPoint: "",
    shipmentIds: [],
    shipments: [],
    status: null,
    shipmentsGeocode: [],
  },
} as const satisfies Record<string, RouteAssignmentResponse>;

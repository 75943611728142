import styled from "@emotion/styled";
import { Typography, IconButton, styled as muiStyled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TitleContainer = styled.div`
  display: flex;
`;

const Title = muiStyled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  fontFamily: '"Lato", sans-serif',
  textTransform: "uppercase",
  fontWeight: "700",
  fontSize: "12px",
  lineHeight: "14px",
  letterSpacing: "0.25px",
  color: "#606060",
}));

const Header = styled.div`
  padding: 23px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
`;

export type SidebarHeaderProps = {
  title?: string;
  onClose?: () => void;
  fixed?: boolean;
  closeIconTitle?: string;
  closeButtonAriaLabel?: string;
};

export const SidebarHeader = ({
  title = "",
  onClose = () => {},
  fixed,
  closeIconTitle = "Close",
  closeButtonAriaLabel = "Close",
}: SidebarHeaderProps) => (
  <Header>
    <TitleContainer>
      <Title>{title}</Title>
      {fixed ? null : (
        <IconButton
          aria-label={closeButtonAriaLabel}
          title={closeIconTitle}
          onClick={onClose}
          size="small"
          sx={{ padding: 0 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </TitleContainer>
  </Header>
);

import { TableCell as MuiTableCell } from "@mui/material";
import { Cell, flexRender } from "@tanstack/react-table";

const TableCell = <TData,>({ cell }: { cell: Cell<TData, unknown> }) => (
  <MuiTableCell
    sx={{
      fontWeight: 700,
      padding: "4px 11px",
      userSelect: "text",
    }}
  >
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </MuiTableCell>
);
export default TableCell;

import { z } from "zod";
import { Prefix } from "../constants/filters/utils";

export const baseQueryParamsSchema = z.enum([
  "bills",
  "pieces",
  "pallets",
  "weight",
  "tags",
  "doorId",
  "status",
  "serviceDueDate",
  "shipperName",
  "consigneeName",
  "consigneeAddress",
  "consigneeCity",
  "consigneeZip",
  "appointmentDate",
  "destinationSicCode",
  "originSicCode",
  "driverName",
  "name",
  "title",
  "trailerName",
  "tractorName",
  "eta",
  "startTime",
  "location",
  "trailerNumber",
  "endTime",
  "routeEnd",
  "zone",
  "shipments",
  "type",
  "presetRouteName",
]);

export type BaseQueryParams = z.infer<typeof baseQueryParamsSchema>;

export type QueryParams = BaseQueryParams | `${Prefix}${BaseQueryParams}`;

export type Option = {
  label: string;
  value: string | number;
};

export type Filter = {
  label: string;
  queryParam: QueryParams;
  options: Option[];
};

// CSpell:Disla,Aldas,Indhira
import dayjs from "dayjs";
import { Doors } from "../../hooks/react-query/useDoors";
import { Driver } from "../../types/driver.type";
import { ServiceCenter } from "../../types/serviceCenter.type";
import { DriverUsage } from "../../hooks/react-query/useDrivers";

export const companyOwnedServiceCenters: ServiceCenter[] = [
  {
    id: 1,
    code: "EWR",
    name: "EWR - DAYLIGHT - EWR",
    address1: "611 TONNELE AVENUE",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/New_York",
  },
  {
    id: 2,
    code: "LAX",
    name: "LAX - DAYLIGHT - LAX",
    address1: "3200 Hooper Avenue",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/Los_Angeles",
  },
  {
    id: 3,
    code: "SFO",
    name: "SFO - DAYLIGHT - SFO",
    address1: "2340 Industrial Parkway West",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/Los_Angeles",
  },
  {
    id: 111,
    code: "ONT",
    name: "ONT - DAYLIGHT - ONT",
    address1: "11160 Elm Avenue",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/Los_Angeles",
  },
  {
    id: 114,
    code: "DAL",
    name: "DAL-DYLT-GRAND PRAIRIE",
    address1: "3409 High Prairie Road",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/Chicago",
  },
  {
    id: 115,
    code: "HTX",
    name: "HTX-DYLT-HOUSTON",
    address1: "200 Brisbane Street",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/Chicago",
  },
  {
    id: 76,
    code: "VIS",
    name: "VIS - DAYLIGHT - VIS",
    address1: "30199 Bradham Drive",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/Los_Angeles",
  },
  {
    id: 155,
    code: "TEB",
    name: "TEB-DYLT-CARLSTADT",
    address1: "50 KERO ROAD",
    address2: "",
    ownership: "C",
    lhBoardRegion: null,
    timeZone: "America/New_York",
  },
];

export const defaultMockServiceCenter =
  companyOwnedServiceCenters.find((sic) => sic.code === "ONT") ||
  companyOwnedServiceCenters[0];

export const serviceCenterDoorNumbers = [1, 2, 3];

export const mockServiceCenterDoors = {
  default: [
    {
      id: 1,
      inUseByInfo: null,
      planningAssignmentsInfo: [
        {
          id: 15,
          type: "Route",
          name: "BURBANK",
          status: "CPLN",
        },
      ],
    },
    {
      id: 2,
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
    {
      id: 3,
      inUseByInfo: {
        id: 55,
        type: "Trap",
        name: "ONT-523",
        status: "DISP",
      },
      planningAssignmentsInfo: [],
    },
    {
      id: 4,
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
    {
      id: 5,
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
    {
      id: 13,
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
  ],
} as const satisfies Record<string, Doors>;

export const mockDrivers = {
  default: [
    {
      id: 3395,
      code: "EWR599",
      name: "DOE, JOHN",
      inService: true,
      phoneNumber: null,
      driversLicense: null,
      driversLicenseCategory: "Class A",
      driversLicenseExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      driversLicenseExpiringInDays: -739006,
      driverType: "Employee",
      inspectionDate: dayjs("0001-01-01T00:00:00").toDate(),
      equipmentId: null,
      medCard: null,
      medCardExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      medCardExpiringInDays: -739006,
      notes: null,
      shifts: [],
      isAvailable: true,
      routeReady: true,
      routeChange: false,
      homeSicId: 1,
      currentSicId: 1,
    },
    {
      id: 1234,
      code: "EMPTY1NAME2",
      name: "",
      inService: true,
      phoneNumber: null,
      driversLicense: null,
      driversLicenseCategory: "Class A",
      driversLicenseExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      driversLicenseExpiringInDays: -739006,
      driverType: "Employee",
      inspectionDate: dayjs("0001-01-01T00:00:00").toDate(),
      equipmentId: null,
      medCard: null,
      medCardExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      medCardExpiringInDays: -739006,
      notes: null,
      shifts: [],
      isAvailable: true,
      routeReady: true,
      routeChange: false,
      homeSicId: 1,
      currentSicId: 1,
    },
    {
      id: 3658,
      code: "EWR614A",
      name: "ALDAS DISLA, INDHIRA ",
      inService: false,
      phoneNumber: "732-766-4500",
      driversLicense: null,
      driversLicenseCategory: "Class A",
      driversLicenseExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      driversLicenseExpiringInDays: -739006,
      driverType: "Employee",
      inspectionDate: dayjs("0001-01-01T00:00:00").toDate(),
      equipmentId: null,
      medCard: null,
      medCardExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      medCardExpiringInDays: -739006,
      notes: null,
      shifts: [
        {
          day: "Monday",
          shiftEndTime: "00:00",
          shiftStartTime: "16:00",
          lunchBreakLength: "00:30",
          lunchBreakStartTime: "18:00",
        },
        {
          day: "Tuesday",
          shiftEndTime: "00:00",
          shiftStartTime: "16:00",
          lunchBreakLength: "00:30",
          lunchBreakStartTime: "18:00",
        },
        {
          day: "Wednesday",
          shiftEndTime: "00:00",
          shiftStartTime: "16:00",
          lunchBreakLength: "00:30",
          lunchBreakStartTime: "18:00",
        },
        {
          day: "Thursday",
          shiftEndTime: "00:00",
          shiftStartTime: "16:00",
          lunchBreakLength: "00:30",
          lunchBreakStartTime: "18:00",
        },
        {
          day: "Friday",
          shiftEndTime: "00:00",
          shiftStartTime: "16:00",
          lunchBreakLength: "00:30",
          lunchBreakStartTime: "18:00",
        },
      ],
      isAvailable: true,
      routeReady: true,
      routeChange: false,
      homeSicId: 1,
      currentSicId: 1,
    },
    {
      id: 3,
      code: "DEACTIVATED",
      name: "DEACTIVATED",
      inService: true,
      phoneNumber: "000000000000",
      driversLicense: null,
      driversLicenseCategory: "Class A",
      driversLicenseExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      driversLicenseExpiringInDays: -739006,
      driverType: "Employee",
      inspectionDate: dayjs("0001-01-01T00:00:00").toDate(),
      equipmentId: null,
      medCard: null,
      medCardExpirationDate: dayjs("0001-01-01T00:00:00").toDate(),
      medCardExpiringInDays: -739006,
      notes: null,
      shifts: [],
      isAvailable: false,
      routeReady: true,
      routeChange: false,
      homeSicId: 1,
      currentSicId: 1,
    },
  ],
} as const satisfies Record<string, Driver[]>;

export const mockDriversUsage = {
  empty: [],
  default: [
    {
      id: 3395,
      name: "DOE, JOHN",
      inUseByInfo: null,
      planningAssignmentsInfo: [
        {
          id: 5556,
          name: "SALT LAKE",
          status: "CPLN",
          type: "Route",
        },
      ],
    },
    {
      id: 3658,
      name: "ALDAS DISLA, INDHIRA ",
      inUseByInfo: {
        id: 5555,
        name: "RENO",
        status: "DISP",
        type: "Route",
      },
      planningAssignmentsInfo: [],
    },
    {
      id: 3659,
      name: "WALDROPH, JOSE ",
      inUseByInfo: null,
      planningAssignmentsInfo: [],
    },
  ],
} as const satisfies Record<string, DriverUsage[]>;

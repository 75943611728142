import { Box, BoxProps, Stack, StackProps } from "@mui/material";

type LoadingProps = {
  label?: string;
  size?: BoxProps["width"];
  containerWidth?: StackProps["width"];
};

/** Loading spinner */
const Loading = ({
  label = "",
  size = "4rem",
  containerWidth = "100%",
}: LoadingProps) => (
  <Stack
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: containerWidth,
    }}
  >
    <Box
      component="img"
      src="/loading.gif"
      alt={`Loading indicator${label ? ` for ${label}` : ""}`}
      sx={{ width: size }}
    />
  </Stack>
);
export default Loading;

import { Filter } from "../../../types/filter";
import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
} from "../utils";

export type PresetRouteNameQueryParam = "presetRouteName";
type SomethingWithPresetRoute = {
  presetRouteName?: string | null;
};

export const getPresetRouteNameFilter = (
  data: SomethingWithPresetRoute[],
  label?: string,
  prefix?: Prefix
): Filter => ({
  label: label ?? "Preset Route Name",
  queryParam: handlePrefix("presetRouteName", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.presetRouteName))
  ),
});

export const filterPresetRouteName = <T extends SomethingWithPresetRoute>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ presetRouteName }) =>
        filters.includes(presetRouteName ?? "")
      )
    : data;

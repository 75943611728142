import { Tag } from "../types/freight/tag.type";

export const tags: Tag[] = [
  {
    id: 1,
    name: "CC",
    description: "Critical Care",
    colorHex: "#DB6561",
    position: "1",
  },
  {
    id: 2,
    name: "UC",
    description: "Urgent Care",
    colorHex: "#F2CB6B",
    position: "2",
  },
  {
    id: 3,
    name: "LG",
    description: "Liftgate",
    colorHex: "#7F9673",
    position: "3",
  },
  {
    id: 4,
    name: "LM",
    description: "Large Mark - 6 plts or greater/6000 lbs or greater",
    colorHex: "#F465DA",
    position: "4",
  },
  {
    id: 5,
    name: "APTR",
    description: "Appointment Required ",
    colorHex: "#777DE9",
    position: "10",
  },
  {
    id: 6,
    name: "WC",
    description: "Will Call",
    colorHex: "#FFA500",
    position: "11",
  },
  {
    id: 7,
    name: "OS&D",
    description: "Overage, Shortage or Damaged",
    colorHex: "#5271C6",
    position: "13",
  },
];

import { AddOutlined } from "@mui/icons-material";
import { PropsWithChildren, useState } from "react";
import AddRouteDialog from "../../../shared/AddRouteDialog";
import { Button } from "../../../../../components/shared/Button";
import AddRouteDialogOld from "../../AddRouteDialogOld";
import { useIsFeatureFlagEnabled } from "../../../../../featureFlags/useIsFeatureFlagEnabled";

interface AddRoutesButtonProps {
  date: string;
}

export const AddRoutesButton = ({
  date,
  children,
}: PropsWithChildren<AddRoutesButtonProps>) => {
  const editGuardrailsPhase2Enabled = useIsFeatureFlagEnabled(
    "inbound-edit-route-trap-guardrails-phase-2-client"
  );

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const formatDate = (date: string) => Number(date.replaceAll("-", ""));

  const toggleDialog = () => setDialogIsOpen(!dialogIsOpen);

  return (
    <>
      <Button startIcon={<AddOutlined />} onClick={toggleDialog}>
        {children}
      </Button>
      {dialogIsOpen &&
        (editGuardrailsPhase2Enabled ? (
          <AddRouteDialog
            title="Create New Route"
            routeIntDate={formatDate(date)}
            onCancel={() => setDialogIsOpen(false)}
            straightTruckSelected={null}
            tractorSelected={null}
            trailerSelected={null}
            selectedShipments={[]}
          />
        ) : (
          <AddRouteDialogOld
            title="Create New Route"
            routeIntDate={formatDate(date)}
            onCancel={() => setDialogIsOpen(false)}
            straightTruckSelected={null}
            tractorSelected={null}
            trailerSelected={null}
            selectedShipments={[]}
          />
        ))}
    </>
  );
};

import {
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Box,
  useTheme,
} from "@mui/material";
import { Tag } from "../Tag";

import { usePrintTableContext } from "./PrintTableContext";
import { RenderType, RowData } from "./types";

interface Column {
  header: string;
  renderAs: RenderType;
  dataIndex: keyof RowData;
}

interface PrintTableBodyProps {
  columns: ReadonlyArray<Column>;
  isBulkPrinting: boolean;
}

const cellStyle = {
  fontWeight: 700,
  fontSize: 14,
};

const booleanColumnKeys = [
  "isInboundLoadCard",
  "isDeliveryManifest",
  "isDeliveryReceipts",
  "isTrapLoadCard",
] as const;

type BooleanColumnKeys = (typeof booleanColumnKeys)[number];

const isBooleanColumn = (key: string): key is BooleanColumnKeys =>
  booleanColumnKeys.includes(key as BooleanColumnKeys);

const PrintTableBody = ({ columns }: PrintTableBodyProps) => {
  const { rows, toggleCheckbox } = usePrintTableContext();

  const theme = useTheme();
  const renderColumn = (column: Column, row: RowData) => {
    switch (column.renderAs) {
      case "text":
        if (column.dataIndex === "type") {
          return (
            <Box sx={cellStyle}>
              {row.type === "delivery_trap" ? "TRAP" : "ROUTE"}
            </Box>
          );
        }
        return <Box sx={cellStyle}>{row.route}</Box>;

      case "number":
        return <Box sx={cellStyle}>{row.bills}</Box>;

      case "tag":
        return (
          <Tag
            label={row.status ? row.status : ""}
            backgroundColor={theme.palette.success.main}
            color={theme.palette.common.white}
            arialLabel={`status-${row.status}-${row.id}`}
          />
        );

      case "checkbox":
        if (isBooleanColumn(column.dataIndex)) {
          if (
            row.status === "CPLN" ||
            (row.status === "CLDK" && row.type !== "delivery_trap")
          ) {
            switch (column.dataIndex) {
              case "isInboundLoadCard":
                return (
                  <>
                    <Checkbox
                      checked={row.isInboundLoadCard}
                      onChange={() => toggleCheckbox(row.id, column.dataIndex)}
                      inputProps={{ "aria-label": `${column.header}` }}
                    />
                    <Box component="span">Print</Box>
                  </>
                );
              case "isDeliveryManifest":
                return (
                  <>
                    <Checkbox
                      checked={row.isDeliveryManifest}
                      onChange={() => toggleCheckbox(row.id, column.dataIndex)}
                      inputProps={{ "aria-label": `${column.header}` }}
                    />
                    <Box component="span">Print</Box>
                  </>
                );
              case "isDeliveryReceipts":
                return (
                  <>
                    <Checkbox
                      checked={row.isDeliveryReceipts}
                      onChange={() => toggleCheckbox(row.id, column.dataIndex)}
                      inputProps={{ "aria-label": `${column.header}` }}
                    />
                    <Box component="span">Print</Box>
                  </>
                );
              case "isTrapLoadCard":
                return;
            }
          } else if (
            row.status === "TPLN" ||
            row.status === "TRAP" ||
            (row.status === "CLDK" && row.type === "delivery_trap")
          ) {
            switch (column.dataIndex) {
              case "isInboundLoadCard":
              case "isDeliveryManifest":
              case "isDeliveryReceipts":
                return;
              case "isTrapLoadCard":
                return (
                  <>
                    <Checkbox
                      checked={row.isTrapLoadCard}
                      onChange={() => toggleCheckbox(row.id, column.dataIndex)}
                      inputProps={{ "aria-label": `${column.header}` }}
                    />
                    <Box component="span">Print</Box>
                  </>
                );
            }
          } else {
            if (column.dataIndex === "isTrapLoadCard") {
              return;
            }

            return (
              <>
                <Checkbox
                  checked={row[column.dataIndex]}
                  onChange={() => toggleCheckbox(row.id, column.dataIndex)}
                  inputProps={{ "aria-label": `${column.header}` }}
                />
                <Box component="span">Print</Box>
              </>
            );
          }
        }
        break;

      default:
        return null;
    }
  };

  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          {columns.map((column) => (
            <TableCell key={column.dataIndex}>
              {renderColumn(column, row)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default PrintTableBody;

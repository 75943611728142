// CSpell:ignore EMTY,CLDK,CLDV
import { useCallback, useState } from "react";
import {
  KeyboardArrowDown,
  WarningOutlined,
  WarningRounded,
} from "@mui/icons-material";
import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import {
  DeliveryRouteTrapStatus,
  useDeliveryRouteTrapStatus,
  useDeliveryRouteTrapStatusChange,
} from "../../../hooks/react-query/useDeliveryRoutesTrapsStatus";
import { toast } from "../../../utils/snackbarHelper";
import { displayMessage, toastMessage } from "../../../constants/strings";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { FlexColumn, FlexRow } from "../../../components/shared/layout/Flex";
import Loading from "../../../components/shared/layout/Loading";
import { PlanType } from "../../../types/planning/plan.type";
import { LightTooltip } from "../../../components/shared/LightTooltip";
import { finalStatuses } from "../../../types/status.type";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import { StatusTagChip } from "../../../components/shared/StatusTag/StatusTagChip";

import ErrorList from "../shared/ErrorList";

interface ChangeableStatusTagProps {
  /** Type of the plan (route or trap) */
  type: PlanType;

  /** Status displayed in the tag. */
  status: string;

  /** Delivery route id */
  deliveryRouteId: number;

  /** Delivery route name */
  deliveryRouteName: string;

  /** The user is allowed to change the route status */
  isAvailableForTransition: boolean;
}

const ConfirmDialogTitle = () => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <WarningOutlined
        sx={{ color: theme.palette.warning.main, marginRight: 0.8 }}
      />
      Confirm Status Change
    </Box>
  );
};

export const ChangeableStatusTag = ({
  type,
  status,
  deliveryRouteId,
  deliveryRouteName,
  isAvailableForTransition,
}: ChangeableStatusTagProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [
    finalStatusTransitionConfirmationStep,
    setFinalStatusTransitionConfirmationStep,
  ] = useState<null | 1 | 2>(null);
  const [targetStatus, setTargetStatus] =
    useState<null | DeliveryRouteTrapStatus>(null);

  const { data: statuses = [], isLoading } = useDeliveryRouteTrapStatus(
    type,
    deliveryRouteId,
    Boolean(anchorEl)
  );

  const onSuccess = (newStatus: DeliveryRouteTrapStatus) => {
    toast(
      displayMessage.inbound.planning.statusChanged(
        deliveryRouteName,
        type,
        newStatus.name
      ),
      {
        variant: "success",
      }
    );
  };

  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  const { mutate: changeDeliveryRouteStatus, isPending } =
    useDeliveryRouteTrapStatusChange({
      sicId: serviceCenter.id,
      date: dateToInt(selectedDate),
      type,
      routeId: deliveryRouteId,
      onSuccess,
    });

  const handleSetAnchorEl = (currentTarget: HTMLDivElement) => {
    if (isAvailableForTransition) {
      setAnchorEl(currentTarget);
    } else {
      toast(toastMessage.inbound.routeStatusChange.routeTrapNotInPlanStatus, {
        variant: "warning",
      });
    }
  };

  const changeStatus = (newStatus: DeliveryRouteTrapStatus) => {
    if (finalStatuses.includes(status)) {
      setFinalStatusTransitionConfirmationStep(1);
      setTargetStatus(newStatus);
    } else {
      changeDeliveryRouteStatus(newStatus);
    }
    setAnchorEl(null);
  };

  const onCloseConfirmDialog = useCallback(() => {
    setFinalStatusTransitionConfirmationStep(null);
    setTargetStatus(null);
  }, []);

  const chip = (
    <StatusTagChip
      isPlanned
      status={status}
      onClick={(e) => handleSetAnchorEl(e.currentTarget)}
      chipIcon={
        isAvailableForTransition ? (
          <KeyboardArrowDown
            style={{ color: theme.palette.primary.contrastText }}
          />
        ) : undefined
      }
    />
  );

  return (
    <>
      {finalStatusTransitionConfirmationStep === 1 && (
        <ConfirmDialog
          open
          onClose={onCloseConfirmDialog}
          onConfirm={() => setFinalStatusTransitionConfirmationStep(2)}
          title={<ConfirmDialogTitle />}
          message={`Changing status will return its status back to ${targetStatus?.name}.`}
          variant="secondary"
          confirmButtonLabel="Accept"
          confirmButtonLoadingLabel="Saving..."
          cancelButtonText="Cancel"
        />
      )}
      {finalStatusTransitionConfirmationStep === 2 && (
        <ConfirmDialog
          open
          onClose={onCloseConfirmDialog}
          onConfirm={() => {
            if (targetStatus) {
              changeDeliveryRouteStatus(targetStatus);
            }
            setFinalStatusTransitionConfirmationStep(null);
          }}
          title={<ConfirmDialogTitle />}
          message="Are you really sure you want to change this status?"
          variant="secondary"
          confirmButtonLabel="Yes"
          confirmButtonLoadingLabel="Saving..."
          cancelButtonText="No"
        />
      )}
      <FlexRow style={{ alignItems: "center", gap: "6px" }}>
        {chip}
        <Box>{(isPending || isLoading) && <Loading size={24} />}</Box>
      </FlexRow>
      {!isLoading && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {statuses.map((status) => (
            <MenuItem
              key={status.id}
              disableRipple={!status.isAvailableForTransition}
              sx={{
                cursor: "auto",
              }}
              onClick={() => {
                if (status.isAvailableForTransition) {
                  changeStatus(status);
                }
              }}
            >
              <FlexColumn>
                <Typography
                  sx={{ opacity: status.isAvailableForTransition ? 1 : 0.38 }}
                >
                  {status.name}
                </Typography>
                {!status.isAvailableForTransition &&
                  status.errorDetails.length > 0 && (
                    <FlexColumn>
                      {status.errorDetails.map((error) => (
                        <FlexRow key={error.code}>
                          <LightTooltip
                            title={
                              <Box
                                sx={{
                                  overflowY: "auto",
                                  maxHeight: 300,
                                  paddingRight: 1,
                                }}
                              >
                                {error.message}
                                {error.data && (
                                  <ErrorList
                                    code={error.code}
                                    errorDetail={error.data}
                                    serviceCenterCode={serviceCenter.code}
                                  />
                                )}
                              </Box>
                            }
                            aria-label={error.message}
                            placement="left"
                          >
                            <WarningRounded color="warning" />
                          </LightTooltip>
                          <Typography
                            ml={1}
                            fontSize={16}
                            sx={{ opacity: 0.38 }}
                          >
                            {error.subject}
                          </Typography>
                        </FlexRow>
                      ))}
                    </FlexColumn>
                  )}
              </FlexColumn>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

import { z } from "zod";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

type Params<T> = {
  [key in keyof T]: string[];
};
const schema = z.record(z.string(), z.array(z.string()));

export const useFilterSearchParams = <T extends Params<T>>(params: T) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = () => {
    const result: Params<T> = params;

    for (const key in params) {
      const values = searchParams.getAll(key);
      result[key] = values;
    }

    const validation = schema.safeParse(result);
    if (!validation.success) {
      throw new Error("Invalid search params:", validation.error);
    }

    return result;
  };

  const updateParams = useCallback(
    (params: Params<T>) => {
      const newSearchParams = new URLSearchParams(searchParams);

      for (const key in params) {
        newSearchParams.delete(key);
        const value = params[key];
        if (Array.isArray(value)) {
          value.forEach((val) => newSearchParams.append(key, val));
        } else {
          newSearchParams.append(key, value);
        }
      }

      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return [getParams(), updateParams] as const;
};

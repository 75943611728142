import { MergeOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useValidateMergeRequest } from "../../../hooks/useValidateMergeRequest";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";

type MergeButtonProps = {
  selected: any;
  parentRecords: any[];
  openDialog: () => void;
  children: React.ReactNode;
};

export function MergeButton({
  selected,
  parentRecords,
  openDialog,
  children,
}: MergeButtonProps) {
  const { validateMergeRequest } = useValidateMergeRequest(
    selected,
    parentRecords
  );

  return (
    <Button
      variant="outlined"
      startIcon={<MergeOutlined />}
      sx={{ color: "#581FE4", borderColor: "#581FE4" }}
      key={2}
      onClick={() => {
        const warningMessage = validateMergeRequest();
        if (warningMessage) {
          toast(toastMessage.inbound.mergeRoutesAndTraps[warningMessage], {
            variant: "warning",
          });
          return;
        }
        openDialog();
      }}
    >
      {children}
    </Button>
  );
}
